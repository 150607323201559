import {useEffect, useMemo} from "react";
import {createValueRef, Ref} from "common/ref";

export function useValueSignal<T>(value: T, fn: (a: T, b: T) => boolean = (a, b) => a === b): Ref<T> {
  const signal = useMemo(() => createValueRef(value), []);
  useEffect(() => {
    if (!fn(signal.value, value)) signal.apply(_ => value);
  }, [signal, value])
  return signal as Ref<T>;
}
