import {GenIcon} from "react-icons";
import {IconBaseProps} from "react-icons";

export const HexagonVerticalGridTypeIcon = (props: IconBaseProps) => GenIcon({
  tag: "svg",
  attr: {
    viewBox: "0 0 1500 1500",
    width: "1500",
    height: "1500"
  },
  child: [{
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "96px"},
      d: "M 750,250 1125,500 1125,1000 750,1250 375,1000 375,500 Z"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 750,0 750,250 M 750,1250 750,1500"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,0 0,250 375,500 M 375,1000 0,1250 0,1500"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 1500,0 1500,250 1125,500 M 1125,1000 1500,1250 1500,1500"
    },
    child: []
  }]
})(props);