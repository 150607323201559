import {Node, NodeId} from "common/legends/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {PreVisitResult, VisitResult, walkTree} from "common/types/index.ts";

export function isNodeVisible(node: Node[], nodeID: NodeId, visibilityMask: AccessMask, visibilityNodeIDs: NodeId[]) {
  let found = false;
  walkTree(node, {
    preVisit(node) {
      if (!AccessMaskFn.canSee(visibilityMask, node.data.visibilityLayer) && !visibilityNodeIDs.includes(node.data.id)) {
        return PreVisitResult.SKIP_VISIT;
      }
    },
    visit(node) {
      if (node.data.id === nodeID) {
        found = true;
        return VisitResult.TERMINATE;
      }
    }
  });
  return found;
}
