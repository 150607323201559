import {Matrix4f} from "#lib/math/index.ts";
import {Node, NodeId} from "common/legends/index.ts";
import {TokenNodeView} from "./token-node-view.tsx";
import {Transform} from "common/types/index.ts";
import {AccessMask} from "common/legends/visibility/index.ts";
import {ImageNodeView} from "./image-node-view.tsx";
import {GroupNodeView} from "./group-node-view.tsx";
import {GridNodeView} from "./grid-view/index.ts";
import {WallNodeView} from "./wall-node-view.tsx";
import {AreaNodeView} from "./area-node-view.tsx";
import {TextNodeView} from "./text-node-view.tsx";
import {ShapeNodeView} from "./shape-node-view.tsx";
import {ParallaxNodeView} from "./parallax-node-view.tsx";

export type NodeViewProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;
  value: Node;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  opacity: number;
};

export function NodeView({value, ...props}: NodeViewProps) {
  if (value.type === "area") {
    return <AreaNodeView {...props} value={value.data}/>
  } else if (value.type === "grid") {
    return <GridNodeView {...props} value={value.data}/>
  } else if (value.type === "group") {
    return <GroupNodeView {...props} value={value.data} />
  } else if (value.type === "image") {
    return <ImageNodeView {...props} value={value.data} />
  } else if (value.type === "shape") {
    return <ShapeNodeView {...props} value={value.data}/>
  } else if (value.type === "text") {
    return <TextNodeView {...props} value={value.data}/>
  } else if (value.type === "token") {
    return <TokenNodeView {...props} value={value.data}/>
  } else if (value.type === "wall") {
    return <WallNodeView {...props} value={value.data}/>
  } else if (value.type === "parallax") {
    return <ParallaxNodeView {...props} value={value.data}/>
  } else {
    return <></>
  }
}
