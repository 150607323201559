import {Apply, Point, PointOperation} from "common/types/index.ts";
import {useCallback} from "react";
import {SceneViewportProperties, SceneViewportPropertiesOperation} from "../scene-viewport-properties.ts";
import {useUpdateViewPosition} from "./use-update-view-position.ts";

export function useResetViewPosition(apply: Apply<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  const updateViewPosition = useUpdateViewPosition(apply);
  return useCallback(() => {
    updateViewPosition((prevValue: Point): PointOperation[] => [{
      type: "update-x",
      operations: [{type: "set", prevValue: prevValue[0], nextValue: 0}]
    }, {
      type: "update-y",
      operations: [{type: "set", prevValue: prevValue[1], nextValue: 0}]
    }]);
  }, [updateViewPosition])
}