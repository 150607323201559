import {FaTimes} from "react-icons/fa";
import {IconButton} from "#lib/components/index.ts";
import React, {useCallback, useMemo} from "react";
import {useGame} from "../../../../routes/game/index.ts";
import {GameSignals} from "common/legends/index.ts";
import {useIsGameMaster} from "../../../common/game/use-is-game-master.ts";
import {twMerge} from "tailwind-merge";
import {useRefValue} from "#lib/signal/index.ts";

export function XCardButton() {
  const gameEntity = useGame();
  const {xCard} = useMemo(() => GameSignals(gameEntity), [gameEntity]);
  const isGameMaster = useIsGameMaster();

  const xCardActivated = useRefValue(xCard);
  const activateXCard = useCallback(() => {
    xCard.apply(value => !value ? [{type: "set", prevValue: value, nextValue: true}] : []);
  }, [xCard.apply]);

  const toggleXCard = useCallback(() => xCard.apply(_ => [{type: "toggle"}]), [xCard.apply]);

  return (
    <IconButton disabled={!isGameMaster && xCardActivated} className={twMerge("rounded-md", xCardActivated && "opacity-50")} variant="destructive" title="X-Card" onClick={isGameMaster ? toggleXCard : activateXCard}>
      <FaTimes size={16} />
    </IconButton>
  );
}