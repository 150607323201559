import {QLabServiceObserver} from "../service/index.ts";
import {
  QLabMessage,
  QLabMessageChangeset,
  QLabMessageID,
  qlabMessageType,
  QLabResource,
  QLabResourceChangeset,
  QLabResourceID,
  qlabResourceType,
  QLabStore,
  QLabStoreChangeset,
  qlabStoreType
} from "common/qlab/index.ts";
import {applyAll, transformAll, Type} from "common/types/index.ts";
import {QLabClientListener} from "./q-lab-client-listener.ts";
import {RequestMessagesParams} from "./q-lab-client.ts";
import {Unsubscribe} from "common/subscription";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";
import {UserID} from "common/legends/index.ts";
import {ConnectionID} from "common/qlab/api/connection-id.ts";

const DEFAULT_STORE: TypeState<QLabStore, QLabStoreChangeset> = reduceInitialized({value: undefined, revision: 0});
const DEFAULT_RESOURCE: TypeState<QLabResource, QLabResourceChangeset> = reduceInitialized({value: undefined, revision: 0});
const DEFAULT_MESSAGE: TypeState<QLabMessage, QLabMessageChangeset> = reduceInitialized({value: undefined, revision: 0});

type TypeState<Value, Changeset> = {
  service: Value;
  client: Value;
  outstandingClientChangesets: Changeset[];
};
type ClientState = {
  connected: boolean;
  store: TypeState<QLabStore, QLabStoreChangeset>;
  resources: {[resourceId: QLabResourceID]: TypeState<QLabResource, QLabResourceChangeset>};
  messages: {[messageId: QLabMessageID]: TypeState<QLabMessage, QLabMessageChangeset>};
  connections: {[connectionId: ConnectionID]: UserID};
};

function reduceInitialized<Value, Changeset>(value: Value): TypeState<Value, Changeset> {
  return {
    service: value,
    client: value,
    outstandingClientChangesets: []
  };
}
function reduceServiceChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, {service, client, outstandingClientChangesets}: TypeState<Value, Changeset>, changesets: Changeset[]): TypeState<Value, Changeset> {
  service = applyAll(type, service, changesets);
  try {
    [, outstandingClientChangesets] = transformAll(type, changesets, outstandingClientChangesets, true);
    client = applyAll(type, service, outstandingClientChangesets);
    return {service, client, outstandingClientChangesets};
  } catch (e) {
    console.error(e);
    return {service, client: service, outstandingClientChangesets: []}
  }
}
function reduceClientChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, {service, client, outstandingClientChangesets}: TypeState<Value, Changeset>, changesets: Changeset[]): TypeState<Value, Changeset> {
  try {
    [, changesets] = transformAll(type, outstandingClientChangesets.filter(changeset => changeset.revision > changesets[0].revision), changesets, true);
    client = applyAll(type, client, changesets);
    outstandingClientChangesets = [...outstandingClientChangesets, ...changesets];
    return {service, client, outstandingClientChangesets};
  } catch (e) {
    console.error(e);
    return {service, client: service, outstandingClientChangesets: []};
  }
}

export class QLabClientObserver {
  constructor(private readonly serviceStoreObserver: QLabServiceObserver) {}

  clientState: ClientState | undefined;
  close: Unsubscribe | undefined;

  listeners: QLabClientListener[] = [];
  subscribe(listener: QLabClientListener): Unsubscribe {
    if (this.listeners.length === 0) {
      this.close = this.serviceStoreObserver.subscribe({
        onInitial: (event) => {
          this.clientState = {
            connected: event.connected,
            store: {
              service: event.store,
              client: event.store,
              outstandingClientChangesets: []
            },
            resources: Object.fromEntries(Object.entries(event.resources).map(([resourceId, resource]) => [
              resourceId,
              {
                service: resource,
                client: resource,
                outstandingClientChangesets: []
              }
            ])),
            messages: {},
            connections: {}
          };
          for (const listener of this.listeners) {
            listener.onInitial(this.clientState);
          }
        },
        onUpdate: (event) => {
          if (!this.clientState) return;
          switch (event.type) {
            case "CONNECTED": {
              this.clientState.connected = true;
              this.clientState.connections = {};
              this.serviceStoreObserver.applyToStore(this.clientState.store.outstandingClientChangesets).catch(console.error);
              for (const [resourceId, resource] of Object.entries(this.clientState.resources)) {
                this.serviceStoreObserver.applyToResource(resourceId as QLabResourceID, resource.outstandingClientChangesets).catch(console.error);
              }
              for (const [messageId, message] of Object.entries(this.clientState.messages)) {
                this.serviceStoreObserver.applyToMessage(messageId as QLabMessageID, message.outstandingClientChangesets).catch(console.error);
              }

              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-CONNECTED"});
              }
              break;
            }
            case "DISCONNECTED": {
              this.clientState.connected = false;
              this.clientState.connections = {};
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-DISCONNECTED"});
              }
              break;
            }
            case "STORE-INITIALIZED": {
              this.clientState.store =  reduceInitialized(event.store);
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-STORE-INITIALIZED", store: event.store});
              }
              break;
            }
            case "STORE-CHANGESET-APPLIED": {
              let store = this.clientState.store || DEFAULT_STORE;
              store = reduceServiceChangesetApplied(qlabStoreType, store, event.changesets);
              this.clientState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-STORE-CHANGESET-APPLIED", changesets: event.changesets});
              }
              break;
            }
            case "STORE-CHANGESET-REJECTED": {
              let store = this.clientState.store || DEFAULT_RESOURCE;
              store.client = store.service;
              store.outstandingClientChangesets = [];
              this.clientState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "STORE-CHANGESET-REJECTED"});
              }
              break;
            }
            case "RESOURCE-INITIALIZED": {
              this.clientState.resources[event.resourceId] = reduceInitialized(event.resource);
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-RESOURCE-INITIALIZED", resourceId: event.resourceId, resource: event.resource});
              }
              break;
            }
            case "RESOURCE-CHANGESET-APPLIED": {
              let resource = this.clientState.resources[event.resourceId] || DEFAULT_RESOURCE;
              resource = reduceServiceChangesetApplied(qlabResourceType, resource, event.changesets);
              this.clientState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-RESOURCE-CHANGESET-APPLIED", resourceId: event.resourceId, changesets: event.changesets});
              }
              break;
            }
            case "RESOURCE-CHANGESET-REJECTED": {
              let resource = this.clientState.resources[event.resourceId] || DEFAULT_RESOURCE;
              resource.client = resource.service;
              resource.outstandingClientChangesets = [];
              this.clientState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "RESOURCE-CHANGESET-REJECTED", resourceId: event.resourceId});
              }
              break;
            }
            case "MESSAGE-INITIALIZED": {
              const message: TypeState<QLabMessage, QLabMessageChangeset> = reduceInitialized(event.message);
              if (!this.clientState.messages) this.clientState.messages = {};
              this.clientState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-MESSAGE-INITIALIZED", messageId: event.messageId, message: event.message});
              }
              break;
            }
            case "MESSAGE-CHANGESET-APPLIED": {
              const channel = this.clientState.messages || {};
              let message = channel[event.messageId] || DEFAULT_MESSAGE;
              message = reduceServiceChangesetApplied(qlabMessageType, message, event.changesets);
              if (!this.clientState.messages) this.clientState.messages = {};
              this.clientState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "SERVICE-MESSAGE-CHANGESET-APPLIED", messageId: event.messageId, changesets: event.changesets});
              }
              break;
            }
            case "MESSAGE-CHANGESET-REJECTED": {
              const channel = this.clientState.messages || {};
              let message = channel[event.messageId] || DEFAULT_MESSAGE;
              message.client = message.service;
              message.outstandingClientChangesets = [];
              if (!this.clientState.messages) this.clientState.messages = {};
              this.clientState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "MESSAGE-CHANGESET-REJECTED", messageId: event.messageId});
              }
              break;
            }
            case "STREAM": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "STREAM", stream: event.stream});
              }
              break;
            }
            case "CONNECTION-ESTABLISHED": {
              this.clientState.connections[event.connectionId] = event.userId;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "CONNECTION-ESTABLISHED", connectionId: event.connectionId, userId: event.userId});
              }
              break;
            }
            case "CONNECTION-CLOSED": {
              delete this.clientState.connections[event.connectionId];
              for (const listener of this.listeners) {
                listener.onUpdate({type: "CONNECTION-CLOSED", connectionId: event.connectionId});
              }
            }
          }
        },
        onError: (error) => {
          for (const listener of this.listeners) {
            listener.onError(error);
          }
        },
        onClose: () => {
          for (const listener of this.listeners) {
            listener.onClose();
          }
        }
      });
    }

    this.listeners.push(listener);
    if (this.clientState) {
      listener.onInitial(this.clientState);
    }

    return () => {
      const index = this.listeners.indexOf(listener);
      if (index !== -1) {
        this.listeners.splice(index, 1);
      }

      if (this.listeners.length === 0) {
        this.clientState = undefined;
        if (this.close) this.close();
      }
    };
  }

  applyToStore(changesets: QLabStoreChangeset[]): Promise<void> {
    if (this.clientState) {
      let store = this.clientState.store || DEFAULT_STORE;
      store = reduceClientChangesetApplied(qlabStoreType, store, changesets);
      this.clientState.store = store;
      for (const listener of this.listeners) {
        listener.onUpdate({type: "CLIENT-STORE-CHANGESET-APPLIED", changesets});
      }
      return this.serviceStoreObserver.applyToStore(store.outstandingClientChangesets);
    } else {
      return this.serviceStoreObserver.applyToStore(changesets);
    }
  }
  applyToResource(resourceId: QLabResourceID, changesets: QLabResourceChangeset[]) {
    if (this.clientState) {
      let resource = this.clientState.resources[resourceId] || DEFAULT_RESOURCE;
      resource = reduceClientChangesetApplied(qlabResourceType, resource, changesets);
      this.clientState.resources[resourceId] = resource;
      for (const listener of this.listeners) {
        listener.onUpdate({type: "CLIENT-RESOURCE-CHANGESET-APPLIED", resourceId, changesets});
      }
      return this.serviceStoreObserver.applyToResource(resourceId, resource.outstandingClientChangesets);
    } else {
      return this.serviceStoreObserver.applyToResource(resourceId, changesets);
    }
  }
  applyToMessage(messageId: QLabMessageID, changesets: QLabMessageChangeset[]) {
    if (this.clientState) {
      const channel = this.clientState.messages || {};
      let message = channel[messageId] || DEFAULT_MESSAGE;
      message = reduceClientChangesetApplied(qlabMessageType, message, changesets);
      if (!this.clientState.messages) this.clientState.messages = {};
      this.clientState.messages[messageId] = message;
      for (const listener of this.listeners) {
        listener.onUpdate({type: "CLIENT-MESSAGE-CHANGESET-APPLIED", messageId, changesets});
      }
      return this.serviceStoreObserver.applyToMessage(messageId, message.outstandingClientChangesets);
    } else {
      return this.serviceStoreObserver.applyToMessage(messageId, changesets);
    }
  }

  stream(stream: QLabStream) {
    return this.serviceStoreObserver.stream(stream);
  }

  requestMessages(params: RequestMessagesParams) {
    return this.serviceStoreObserver.requestMessages(params);
  }
}
