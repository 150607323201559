import {ButtonBar, Checkbox, ExpandableLabel, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {Game, Player, UserID} from "common/legends/index.ts";
import {Owner} from "common/legends/game/owner/index.ts";
import {SetEntity} from "common/types/index.ts";
import {twMerge} from "tailwind-merge";
import {useGame} from "../../../routes/game";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";

export type EditorCharacterOwnersInputProps = {
  value: SetEntity<UserID>;
};

export function OwnersInput({value}: EditorCharacterOwnersInputProps) {
  const gameRef = useGame();
  const players: {[userId: UserID]: Player | Owner} = useComputedValue(gameRef, (game: Game) => ({
    [game.owner.id]: game.owner,
    ...game.players
  }));

  const ownerIDs = useRefValue(value);
  const [expanded, toggleExpanded] = useToggle(false);

  const options: UserID[] = [
    "GLOBAL" as UserID,
    ...ownerIDs.filter(ownerID => ownerID !== "GLOBAL"),
    ...(Object.keys(players).filter(playerID => !ownerIDs.includes(playerID as UserID)) as UserID[])
  ];

  return (<div className="shrink-0 flex flex-col">
    <ButtonBar className={twMerge(expanded && "rounded-b-none")}>
      <ExpandableLabel size="small" expanded={expanded} toggleExpand={toggleExpanded}>
        <span className="flex-1 px-4 bg-zinc-900/40 h-10 flex items-center cursor-pointer font-bold" onClick={toggleExpanded}>Owners</span>
      </ExpandableLabel>
    </ButtonBar>
    {expanded && <Fieldset className="gap-1 divide-y divide-zinc-900/80">
      {options.map((playerID) => {
        return (<InputGroup key={playerID}>
          <Checkbox checked={ownerIDs.includes(playerID)} onChange={(ev) => {
            const checked = ev.target.checked;
            value.apply(_ => checked
              ? [{type: "insert", item: playerID as UserID}]
              : [{type: "delete", item: playerID as UserID}]
            );
          }} />
          <InputGroupLabel>{playerID === "GLOBAL" ? "Global" : (players[playerID]?.name ?? "Unknown")}</InputGroupLabel>
        </InputGroup>);
      })}
    </Fieldset>}
  </div>);
}