import {SizeField} from "../size-field.tsx";
import {AssetSignals, TokenSignals} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../name-field.tsx";
import {SheetField} from "./sheet-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {AssetTokenSelectionRef, SelectionRef} from "../../../container/editor/state/selection-ref.ts";
import {useAsset, useAssetToken} from "../../../common/character/use-asset.ts";
import {OriginField} from "../node/origin-field.tsx";
import {MountableField} from "../mountable-field.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {useSuspendPresent} from "../../../common/use-optional-signal.ts";
import {PivotField} from "../node/pivot-field.tsx";
import {MutableRef} from "common/ref";
import {OwnersInput} from "../../asset-navigator/owners-input.tsx";
import {AccessMaskField} from "../access-mask-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";

export function AssetTokenPropertiesView({reference, pinned}: {
  reference: AssetTokenSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const assetRef = useSuspendPresent(useAsset(reference.resourceId));
  const {ownerIDs, visibilityLayer, selectionMask, accessMask} = useMemo(() => AssetSignals(assetRef), [assetRef]);
  const tokenRef = useSuspendPresent(useAssetToken(assetRef, reference.tokenId));
  const {sheets} = useMemo(() => AssetSignals(assetRef), [assetRef]);

  const {nameRef, icon, size, origin, pivotRef, mountable, attachable, sheetId, sheetPolicy} = useMemo(() => TokenSignals(tokenRef), [tokenRef]);
  return (<div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent>
      <SectionHeader>Asset Properties</SectionHeader>
      <OwnersInput value={ownerIDs} />
      <AccessMaskField value={accessMask} />
      <SelectionMaskField value={selectionMask} />
      <VisibilityLayerField value={visibilityLayer} />
    </BaseComponent>
    <BaseComponent>
      <SectionHeader>Token Properties</SectionHeader>
      <NameField value={nameRef} reference={reference} pinned={pinned} />
      <InputFile placeholder="Token Icon" value={icon} />
      <SheetField sheets={sheets} sheetPolicy={sheetPolicy} sheetID={sheetId} />
      <OriginField valueRef={origin} />
      <PivotField valueRef={pivotRef} />
      <SizeField value={size} />
      <MountableField value={mountable} />
      <AttachableField value={attachable} />
    </BaseComponent>
  </div>);
}
