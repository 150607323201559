import {ExpandableLabel, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {GameSystems, GameSystemsOperation, SystemSignals} from "common/legends/game/system/game-systems.ts";
import {useMemo} from "react";
import {Dnd5eSystemSignals} from "common/legends/game/system/dnd-5e-system.ts";
import {InputDnd5eFeatures} from "../sheet/editor/dnd-5e/dnd-5e-feature/input-dnd-5e-features.tsx";
import {MutableRef} from "common/ref";

export type SystemsViewProps = {
  value: MutableRef<GameSystems, GameSystemsOperation[]>;
};

export function SystemsView({value}: SystemsViewProps) {
  const {dND5e} = useMemo(() => SystemSignals(value), [value]);
  const {globalFeatures} = useMemo(() => Dnd5eSystemSignals(dND5e), [dND5e]);
  return <div className="flex flex-col gap-1">
    <div className="flex flex-col">
      <ExpandableLabel expanded={true}>
        <InputGroup className="flex-1">
          <InputGroupLabel>DND 5e</InputGroupLabel>
        </InputGroup>
      </ExpandableLabel>
      <Fieldset>
        <InputDnd5eFeatures label="Global Features" value={globalFeatures} />
      </Fieldset>
    </div>
  </div>;
}