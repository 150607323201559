import {DependencyList, RefObject, useEffect, useRef, useState} from "react";
import {map, Observable, subscribe} from "common/observable";
import {pipe} from "common/pipe";
import {Loader} from "common/loader";

export function useObservable<T>(observe: Observable<T>, initialValue: T | (() => T), deps: DependencyList | undefined): T {
  const [state, setState] = useState<T>(initialValue);
  useEffect(() => {
    setState(initialValue);
    return pipe(
      observe,
      subscribe(value => setState(typeof value === "function" ? () => value : value))
    )
  }, [setState, ...(deps ? deps : [])]);
  return state;
}

export function useObservableRef<T>(observe: Observable<T>, dependencies: DependencyList | undefined): RefObject<Loader<T>> {
  const ref = useRef<Loader<T>>(Loader.loading<T>());
  useEffect(() => {
    ref.current = Loader.loading();
    return pipe(
      observe,
      subscribe(value => ref.current = Loader.loaded(value))
    );
  }, dependencies);
  return ref;
}

export function useObservableLoader<T>(observe: Observable<T>): Loader<T> {
  return useObservable<Loader<T>>(
    pipe(observe, map(Loader.loaded)),
    Loader.loading,
    [observe]
  );
}
