import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {Dnd5eSavingThrowMessage, QLabMessageID, RollResults} from "common/qlab/index.ts";
import {useDecryptValue, useOptionalDecryptValue} from "../use-decrypt-value.ts";
import {RollRequestView} from "../roll-request-view.tsx";
import {UserNodeIcon} from "../user-node-icon.tsx";

export function Dnd5eSavingThrowMessageView({message}: {
  messageId: QLabMessageID;
  message: Dnd5eSavingThrowMessage;
}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const savingThrowType = message.savingThrowType;
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);

  return <div className="relative mt-7 mb-2 mx-2">
    <div className="w-full flex-1 bg-zinc-800/50 divide-y divide-slate-900/20 rounded-lg shadow-lg backdrop-blur-sm">
      <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />
      <div className="pointer-events-auto">
        <div className="font-bold italic text-center pb-2 pt-5 bg-zinc-800/50 rounded-t-lg">{savingThrowType.toUpperCase()}</div>
        <div className="py-2 flex flex-col gap-1 items-center justify-center">
          <span className="text-white/80 text-sm italic font-bold">Saving Throw</span>
          <RollRequestView rollType="saving-throw" rollRequest={rollRequests.isSuccess ? rollRequests.data[message.savingThrowRollID] : undefined} rollResult={rollResults.isSuccess && rollResults.data ? rollResults.data[message.savingThrowRollID] : undefined} />
        </div>
      </div>
    </div>
  </div>;
}
