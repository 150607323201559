import {MutableRef} from "common/ref";
import {TokenNode, TokenNodeOperation} from "common/legends/node/index.ts";
import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {AssetRef} from "../legends/asset-ref.ts";
import {Embeddable, MapFn} from "common/types/generic/index.ts";
import {Sheet, SheetID} from "common/legends/asset/index.ts";

export function resetTokenSheet(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, nodeRef: MutableRef<TokenNode, TokenNodeOperation[]>) {
  return nodeRef.apply((prev: TokenNode): TokenNodeOperation[] => {
    const {assetID, tokenID} = prev.tokenReference;
    const nodeSheet = prev.tokenSheets[tokenID];
    const asset = AssetRef(databaseRef, assetID).value;
    if (asset === undefined) return [];
    const token = asset.tokens.find(token => token.tokenID === tokenID);
    const sheet = token?.sheetId !== undefined ? asset.sheets[token.sheetId] : undefined;

    if (token?.sheetPolicy === "copy") {
      return [{type: "update-token-sheets", operations: [
          ...(nodeSheet === undefined ? [] : MapFn.delete(tokenID, nodeSheet)),
          ...(sheet === undefined ? [] : MapFn.put(tokenID, {type: "copy", data: sheet} satisfies Embeddable<SheetID, Sheet>))
        ]}];
    } else if (token?.sheetPolicy === "link") {
      return [{type: "update-token-sheets", operations: [
          ...(nodeSheet === undefined ? [] : MapFn.delete(tokenID, nodeSheet)),
          ...(token?.sheetId === undefined ? [] : MapFn.put(tokenID, {type: "link", data: token.sheetId} satisfies Embeddable<SheetID, Sheet>))
        ]}];
    } else if (nodeSheet !== undefined) {
      return [{type: "update-token-sheets", operations: [
        ...MapFn.delete(tokenID, nodeSheet)
      ]}];
    } else return [];
  });
}
