import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, Type} from "../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {InteractionActionID} from "./interaction-action-i-d.ts";

export const InteractionActionDismount = z.object({
  interactionActionID: InteractionActionID
});
export type InteractionActionDismount = z.infer<typeof InteractionActionDismount>;

export const InteractionActionDismountOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-action-i-d"), operations: z.array(ConstantOperation)})
]);
export type InteractionActionDismountOperation = z.infer<typeof InteractionActionDismountOperation>;

export const interactionActionDismountType: Type<InteractionActionDismount, InteractionActionDismountOperation> = new ObjectType({
  interactionActionID: constantType
});

export const InteractionActionDismountFn = {
  expand: (value: MutableRef<InteractionActionDismount, InteractionActionDismountOperation[]>) => ({
  })
};
