import {Checkbox, InputGroup, InputProps} from "#lib/components/index.ts";
import {BooleanOperation} from "common/types/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useCallback} from "react";
import {MutableRef} from "common/ref";

export type InputCheckboxProps = {
  value: MutableRef<boolean, BooleanOperation[]>;
} & Omit<InputProps, "value" | "onChange">;

export function InputCheckbox({value, className, ...props}: InputCheckboxProps) {
  const checked = useObservable(value.observe, false, [value.observe]);
  const toggleExpand = useCallback(() => {
    value.apply(_ => [{type: "toggle"}]);
  }, [value.apply]);

  return <InputGroup className="px-4 flex items-center justify-center">
    <Checkbox checked={checked} {...props} onChange={props.readOnly ? undefined : toggleExpand} />
  </InputGroup>;
}
