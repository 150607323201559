import {ListOperation} from "#common/types/index.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {MutableRef} from "#common/ref";

export function ListPropertyRef<AV, AO, BV, BO>(
  valueFn: (value: AV) => BV[],
  updateFn: (operations: ListOperation<BV, BO>[]) => AO[]
): (signal: MutableRef<AV, AO[]>) => MutableRef<BV[], ListOperation<BV, BO>[]> {
  return (signal: MutableRef<AV, AO[]>): MutableRef<BV[], ListOperation<BV, BO>[]> =>
    PropertyRef<AV, AO, BV[], ListOperation<BV, BO>>(valueFn, updateFn)(signal);
}
