import {DND_5E_SKILLS} from "common/legends/index.ts";
import React from "react";
import {SkillField} from "../dnd-5e-character/skill-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";

export type SkillProficienciesViewProps = {};
export function SkillProficienciesView({}: SkillProficienciesViewProps) {
  const [expanded, toggleExpanded] = useToggle(false);
  return <BaseComponent>
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <span className="flex-1 px-4 py-2 font-bold">Skills</span>
    </ExpandableLabel>
    {expanded && <Fieldset>
      {DND_5E_SKILLS.map(skill => <SkillField key={skill} skill={skill} />)}
    </Fieldset>}
  </BaseComponent>
}