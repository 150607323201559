import {Button} from "#lib/components/index.ts";
import {FaSpinner} from "react-icons/fa";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {RollRequestID, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {useSelectedSheet} from "../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useApplyDamageToSelectedCharacter, useApplyHealingToSelectedCharacter, useApplyTempHPToSelectedCharacter} from "./use-apply-to-selected-character.ts";
import {MouseEvent, useState} from "react";
import {useIsAltDown} from "#lib/is-alt-down.ts";
import {useIsShiftDown} from "#lib/components/use-is-shift-down.ts";
import {useIsCtrlDown} from "#lib/components/use-is-ctrl-down.ts";
import {Loader} from "common/loader";
import {useComputedValue} from "#lib/signal/index.ts";

export function Dnd5eApplyDamageButton({damageRolls, criticalDamage, results}: {
  damageRolls: {
    damageType?: Dnd5eDamageType | undefined,
    hitDamageRollID: RollRequestID,
    critDamageRollID?: RollRequestID
  }[],
  criticalDamage?: boolean,
  results: Loader<Optional<RollResults>>
}) {
  const selectedSheet = useSelectedSheet();
  const isSheetSelected = useComputedValue(selectedSheet, selectedSheet => selectedSheet !== undefined);

  const applyDamage = useApplyDamageToSelectedCharacter(selectedSheet);
  const applyTempHP = useApplyTempHPToSelectedCharacter(selectedSheet);
  const applyHealing = useApplyHealingToSelectedCharacter(selectedSheet);

  const [isProcessing, setIsProcessing] = useState(false);

  const altDown = useIsAltDown();
  const shiftDown = useIsShiftDown();
  const ctrlDown = useIsCtrlDown();

  const hasHealing = altDown || damageRolls.some(damage => damage.damageType === "healing");
  const hasTempHP = damageRolls.some(damage => damage.damageType === "temp hp");

  const applyNormalDamage = (ev: MouseEvent) => {
    if (hasHealing) {
      setIsProcessing(true);
      applyHealing(damageRolls.map(dr => (results.data ? results.data[dr.hitDamageRollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else if (hasTempHP) {
      setIsProcessing(true);
      applyTempHP(damageRolls.map(dr => (results.data ? results.data[dr.hitDamageRollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else {
      setIsProcessing(true);
      applyDamage(
        damageRolls.map(dr => ({rollResult: results.data ? results.data[dr.hitDamageRollID] : undefined, damageType: dr.damageType})),
        ev.ctrlKey, ev.shiftKey
      ).finally(() => setTimeout(() => setIsProcessing(false), 500));
    }
  };

  const applyCritDamage = (ev: MouseEvent) => {
    if (hasHealing) {
      setIsProcessing(true);
      applyHealing(damageRolls.map(dr => dr.critDamageRollID ? (results.data ? results.data[dr.critDamageRollID] : undefined) : undefined))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else if (hasTempHP) {
      setIsProcessing(true);
      applyTempHP(damageRolls.map(dr => dr.critDamageRollID ? (results.data ? results.data[dr.critDamageRollID] : undefined) : undefined))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else {
      setIsProcessing(true);
      applyDamage(
        damageRolls.map(dr => ({rollResult: dr.critDamageRollID ? (results.data ? results.data[dr.critDamageRollID] : undefined) : undefined, damageType: dr.damageType})),
        ev.ctrlKey, ev.shiftKey
      ).finally(() => setTimeout(() => setIsProcessing(false), 500));
    }
  };

  return (<>
    {damageRolls.length > 0 && <tr>
        <td colSpan={2}>
            <Button variant="primary" disabled={isProcessing || !isSheetSelected} className="w-full" onClick={!criticalDamage ? applyNormalDamage : applyCritDamage}>
              {isProcessing && <FaSpinner className="animate-spin"/>}
              {altDown
                ? "Apply Healing"
                : (hasTempHP
                  ? "Apply Temp HP"
                  : `Apply ${shiftDown && !ctrlDown ? "Double " : ""}${!shiftDown && ctrlDown ? "Half " : ""}Damage`)
              }
            </Button>
        </td>
    </tr>}
  </>);
}