import {ConstantOperation, constantType, ListOperation, ListPropertyRef, ObjectType, PropertyRef, StringOperation, stringType, Tag, tagListType, Type} from "../../../types/index.ts";
import {SceneTreeItem} from "./scene-tree-type.ts";
import {SceneTreeId} from "./scene-tree-id.ts";
import {MutableRef} from "#common/ref";

export type SceneTreeFolder = {
  id: SceneTreeId;
  name: string;
  tags: Tag[];
  children: SceneTreeItem[];
};
export type SceneTreeFolderOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  | {type: "update-children", operations: ConstantOperation[]}
  ;

export const sceneFolderType: Type<SceneTreeFolder, SceneTreeFolderOperation> = new ObjectType({
  id: constantType,
  name: stringType,
  tags: tagListType,
  children: constantType
});


const getName = (value: SceneTreeFolder): string => value.name;
const updateName = (operations: StringOperation[]): SceneTreeFolderOperation[] => [{type: "update-name", operations}];
const getTags = (value: SceneTreeFolder): Tag[] => value.tags;
const updateTags = (operations: ListOperation<Tag, ConstantOperation>[]): SceneTreeFolderOperation[] => [{type: "update-tags", operations}];

export function SceneTreeFolderFn(value: MutableRef<SceneTreeFolder, SceneTreeFolderOperation[]>) {
  return {
    name: PropertyRef(getName, updateName)(value),
    tags: ListPropertyRef(getTags, updateTags)(value)
  };
}