import {DialogID} from "../../../modal/index.ts";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";
import {Point} from "common/types/index.ts";
import {PropsWithChildren, useRef} from "react";

export type DialogMoveHandleProps = PropsWithChildren<{
  dialogId: DialogID;
  position: Point;
  onToggleMinimized: () => void;
}>;

export function DialogMoveHandle({dialogId, position, children, onToggleMinimized}: DialogMoveHandleProps) {
  const positionRef = useRef(position);
  positionRef.current = position;
  const [, drag, dragPreviewRef] = useDrag(() => ({
    type: `legends/dialog-resize`,
    item: () => ({dialogId, direction: "move", initialPosition: positionRef.current}),
    options: {dropEffect: "move"}
  }), [dialogId, positionRef]);
  dragPreviewRef(EMPTY_IMAGE);
  return (<div ref={drag} className="dialog-move-handle" onDoubleClick={onToggleMinimized}>
    {children}
  </div>);
}
