import {Observable, Observer, Subscription} from "common/observable";
import {useCallback, useEffect, useRef} from "react";

export function useFromObservable<T>(value: T): Observable<T> {
  const observersRef = useRef<Observer<T>[]>([]);
  useEffect(() => {
    for (const observer of observersRef.current) {
      observer.next(value);
    }
  }, [observersRef, value]);
  const valueRef = useRef(value);
  valueRef.current = value;
  return useCallback((observer: Observer<T>): Subscription => {
    observersRef.current.push(observer);
    observer.next(valueRef.current);
    return () => {
      observersRef.current.splice(observersRef.current.indexOf(observer), 1);
    };
  }, [observersRef]);
}
