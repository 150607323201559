import {z} from "zod";
import {NodeID} from "common/legends/index.ts";
import {AreaToolMode, AreaToolModeOperation, areaToolModeType} from "./area-tool-mode.ts";
import {ConstantOperation, constantType, ObjectType, ValueOperation, ValueType} from "common/types/index.ts";
import {AreaToolCreateFn} from "./area-tool-create-mode.ts";

export const AreaToolData = z.object({
  nodeID: NodeID,
  mode: z.optional(AreaToolMode)
});

export type AreaToolData = z.infer<typeof AreaToolData>;
export const AreaToolDataOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-mode"), operations: z.array(ValueOperation(z.optional(AreaToolMode), AreaToolModeOperation))})
])
export type AreaToolDataOperation = z.infer<typeof AreaToolDataOperation>;

export const areaToolType = new ObjectType({
  nodeID: constantType,
  mode: new ValueType(areaToolModeType)
});

export const AreaToolFn = {
  DEFAULT: {
    nodeID: "",
    mode: AreaToolCreateFn.DEFAULT
  } satisfies AreaToolData
};
