import {z} from "zod";
import {RollRequestID} from "../roll-request/roll-request.ts";

export const TextMessageToken = z.discriminatedUnion("type", [
  z.object({type: z.literal("string"), value: z.string()}),
  z.object({type: z.literal("roll"), value: RollRequestID})
]);
export type TextMessageToken = z.infer<typeof TextMessageToken>;

export const TextMessageContent = z.array(TextMessageToken);
export type TextMessageContent = z.infer<typeof TextMessageContent>;
