import {ConstantOperation, ConstantType, ObjectType, StringOperation, stringType, Type} from "../../../types/index.ts";

export type Invitation = {
  label: string;
  digest: string;
};
export type InvitationOperation =
  | {type: "update-label", operations: StringOperation[]}
  | {type: "update-digest", operations: ConstantOperation[]}
  ;
export const invitationType: Type<Invitation, InvitationOperation> = new ObjectType({
  label: stringType,
  digest: new ConstantType<string>()
});

export const Invitation = {
  generate(): string {
    return 'xxxxxx'.replace(/[x]/g, () =>
      (Math.random() * 16 | 0).toString(16)
    );
  }
}
