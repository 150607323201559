import {Background} from "../../background.tsx";
import {Modal} from "#lib/components/modal/index.ts";
import {FaSpinner} from "react-icons/fa";

export function LoadingScreen() {
  return (
    <Background>
      <Modal className="max-w-screen-sm">
        <div className="flex items-center justify-center h-40 text-2xl">
          <FaSpinner className="animate-spin"/>
        </div>
      </Modal>
    </Background>
  );
}