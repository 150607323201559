import {SetEntity, SetFn} from "common/types/index.ts";
import {Dnd5eCondition, DND_5E_CONDITIONS} from "common/legends/index.ts";
import {ButtonBar, ExpandableLabel, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";

import blinded from "./blinded.png";
import grappled from "./grappled.png";
import charmed from "./charmed.png";
import deafened from "./deafened.png";
import frightened from "./frightened.png";
import incapacitated from "./incapacitated.png";
import invisible from "./invisible.png";
import paralyzed from "./paralyzed.png";
import petrified from "./petrified.png";
import poisoned from "./poisoned.png";
import prone from "./prone.png";
import restrained from "./restrained.png";
import stunned from "./stunned.png";
import unconscious from "./unconscious.png";
import React, {useCallback} from "react";
import {twMerge} from "tailwind-merge";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";


export type Dnd5eConditionsViewProps = {
  value: SetEntity<Dnd5eCondition>;
}

export const CONDITION_IMAGE: {[condition in Dnd5eCondition]: string} = {
  blinded,
  grappled,
  charmed,
  deafened,
  frightened,
  incapacitated,
  invisible,
  paralyzed,
  petrified,
  poisoned,
  prone,
  restrained,
  stunned,
  unconscious
} as const;

export function Dnd5eConditionsView({value}: Dnd5eConditionsViewProps) {
  const conditions = useObservable(value.observe, [], [value.observe]);
  const toggleCondition = useCallback((condition: Dnd5eCondition) => value.apply(prev => prev.includes(condition)
    ? SetFn.delete(condition)
    : SetFn.insert(condition)
  ), [value.apply]);

  const [expanded, toggleExpanded] = useToggle(false);

  return (<div>
    <ButtonBar>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <span className="px-4 font-bold flex items-center">Conditions</span>
        <div className="shrink-0 basis-0 flex flex-row gap-2 px-2 py-2 flex-1 overflow-hidden">
          {conditions.map(condition => <div
            className="shrink-0 basis-6 inline-flex flex-row items-center h-8 gap-1"
            key={condition} title={condition}>
            <img className="w-6 h-6" alt={condition} src={CONDITION_IMAGE[condition]} />
          </div>)}
        </div>
      </ExpandableLabel>
    </ButtonBar>
    {expanded && <Fieldset>
      {DND_5E_CONDITIONS.map(condition => <ButtonBar key={condition}>
        <InputGroup className="flex-1 pl-0 rounded-l-full ml-2" onClick={() => toggleCondition(condition)}>
          <InputGroupLabel className="flex-1 flex items-center gap-2">
            <img className={twMerge(
              "w-8 h-8",
              !conditions.includes(condition) ? "opacity-50" : ""
            )} alt={condition} src={CONDITION_IMAGE[condition]} />
            <span className="flex-1">{condition.toUpperCase()}</span>
          </InputGroupLabel>
        </InputGroup>
      </ButtonBar>)}
    </Fieldset>}
  </div>);
}