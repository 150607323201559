import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaSignOutAlt, FaTag} from "react-icons/fa";
import {Player, PlayerOperation, PlayerRef} from "common/legends/index.ts";
import {useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputIcon} from "#lib/components/input/input-icon.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MutableRef} from "common/ref";

export type GamePlayerViewProps = {
  value: MutableRef<Player, PlayerOperation[]>;
  remove?: () => void;
};

export function GamePlayerView({value, remove}: GamePlayerViewProps) {
  const {name, icon, color, gameMaster} = useMemo(() => PlayerRef(value), [value]);
  const [expanded, toggleExpand] = useToggle(false);

  return (<div className="flex flex-col">
    <ButtonBar>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpand}>
        <InputGroup className="flex-1">
          <InputString readOnly value={name} />
        </InputGroup>
        <IconButton disabled={!remove} variant="destructive" onClick={remove}><FaSignOutAlt /></IconButton>
      </ExpandableLabel>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup>
        <InputCheckbox value={gameMaster} />
        <InputGroupLabel>Game Master</InputGroupLabel>
      </InputGroup>
      <InputGroup title="Player Name">
        <InputIcon><FaTag /></InputIcon>
        <InputString value={name} />
      </InputGroup>
      <InputFile placeholder="Player Icon" value={icon} />
      <ColorField title="Player Color" value={color} />
    </Fieldset>}
  </div>);
}
