import {OutlinerView} from "../../common/outliner/outliner-view.tsx";
import {Asset, AssetOperation, Token, TokenOperation, TokenReference, TokenSignals} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useMemo} from "react";
import {Outliner, OutlinerOperation, OutlinerSignals} from "../../common/outliner/outliner.ts";
import {useAssetViewportProperties} from "../../viewport/character/use-asset-viewport-properties.ts";
import {AssetTokenSelectionRef} from "../../container/editor/state/selection-ref.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {AssetViewportPropertiesSignals, useAssetToken, useEditorSelectedNodeIDs} from "../../viewport/character";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {usePresent, useSuspendPresent} from "../../common/use-optional-signal.ts";
import {MutableRef, Ref} from "common/ref";

export type EditorAssetOutlinerViewProps = {
  valueRef: MutableRef<Outliner, OutlinerOperation[]>;
};

export function EditorAssetOutlinerView({valueRef}: EditorAssetOutlinerViewProps) {
  const assetViewportPropertiesRef = useSuspendPresent(useAssetViewportProperties());
  const {tokenReference} = useMemo(() => AssetViewportPropertiesSignals(assetViewportPropertiesRef), [assetViewportPropertiesRef]);
  const {assetID} = useRefValue(tokenReference);
  const asset = useSuspendPresent(useAsset(assetID));
  return <LoadedEditorAssetOutlinerView outliner={valueRef} tokenRef={tokenReference}  asset={asset}/>
}

type LoadedEditorAssetOutlinerViewProps = {
  tokenRef: Ref<TokenReference>;
  outliner: MutableRef<Outliner, OutlinerOperation[]>;
  asset: MutableRef<Asset, AssetOperation[]>;
};
function LoadedEditorAssetOutlinerView({tokenRef, outliner, asset}: LoadedEditorAssetOutlinerViewProps) {
  const token = usePresent(useAssetToken(asset, useComputedValue(tokenRef, ref => ref.tokenID)));
  return (<div className="flex flex-col gap-2">
    {token && <EditorAssetTokenOutlinerView outliner={outliner} token={token} tokenRef={tokenRef} />}
  </div>);
}

function EditorAssetTokenOutlinerView({outliner, token, tokenRef}: {
  outliner: MutableRef<Outliner, OutlinerOperation[]>,
  token: MutableRef<Token, TokenOperation[]>,
  tokenRef: Ref<TokenReference>;
}) {
  const {expanded} = useMemo(() => OutlinerSignals(outliner), [outliner]);
  const selectedNodeIDs = useEditorSelectedNodeIDs();
  const {children} = useMemo(() => TokenSignals(token), [token]);
  const nodes = useObservable(children.observe, [], [children.observe]);
  const selectedNodeIDsValue = useObservable(selectedNodeIDs.observe, [], [selectedNodeIDs.observe]);
  const rootSelectionRef = useComputedValue(tokenRef, (ref): AssetTokenSelectionRef => ({
    type: "asset-token",
    storeId: ref.gameID,
    resourceId: ref.assetID,
    tokenId: ref.tokenID
  }));

  return <OutlinerView rootSelectionRef={rootSelectionRef} value={nodes} apply={children.apply} expanded={expanded} selectedNodeIds={selectedNodeIDsValue} applyToSelectedNodeIds={selectedNodeIDs.apply} />;
}