import {Observable, Observer, Subscription} from "common/observable";
import {QLabFileId} from "common/qlab/index.ts";
import {authClient} from "#lib/auth/auth-client.ts";
import {FileReference} from "common/types/index.ts";
import {fileServiceUrl} from "#env";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";

class AssetManager {
  async asset(fileID: QLabFileId) {
    const token = authClient.getToken();
    if (token === null) throw new Error("Unauthenticated.");
    const userID = userIDRef.value!;
    const response = await fetch(`https://${fileServiceUrl}/${userID}/${fileID}`, {
      mode: "cors",
      headers: {"authorization": `Bearer ${token}`}
    });
    const {get, put} = await response.json();
    return {
      url: get as FileReference,
      upload(file: File): Observable<number> {
        const xhr = new XMLHttpRequest();
        const observers: Observer<number>[] = [];
        let lastProgress = 0;
        const readyStateChangeListener = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            file.arrayBuffer().then(async buffer => {
              // Put it in local cache first.
              if ('caches' in self) {
                let cache = await caches.open("legends-app-assets-cache");
                await cache.put(get, new Response(buffer, {
                  headers: {
                    "content-type": file.type,
                    "content-length": `${buffer.byteLength}`
                  },
                  status: 200,
                  statusText: "OK"
                }));
              }
            }).finally(() => {
              observers.forEach(observer => {
                try {observer.complete()} catch (e) {console.error(e)}
              })
            });
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            observers.forEach(observer => {
              const error = new Error("Could not upload file.");
              try {observer.error(error)} catch (e) {console.error(e)}
            });
          }
        };
        const progressListener = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
          lastProgress = (e.loaded / e.total) || 1;
          observers.forEach(observer => {
            try {observer.next(lastProgress)} catch (e) {console.error(e)}
          });
        };
        xhr.upload.addEventListener("progress", progressListener);
        xhr.addEventListener("readystatechange", readyStateChangeListener);
        xhr.withCredentials = true;
        xhr.open("PUT", put, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.send(file);
        return (observer): Subscription => {
          observers.push(observer);
          observer.next(lastProgress);
          return () => {
            observers.splice(observers.indexOf(observer), 1);
          };
        };
      }
    };
  }
}

const uploadManager = new AssetManager();

export default uploadManager;
