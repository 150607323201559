import {Observable} from "../observable.ts";

function identity<T>(a: T, b: T): boolean {
  return a === b;
}
export function listIdentity<T>(a: T[], b: T[], fn: (a: T, b: T) => boolean = identity): boolean {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i ++) {
    if (!fn(a[i], b[i])) return false;
  }
  return true;
}

export function mapIdentity<K extends string | number, T>(a: {[key in K]: T}, b: {[key in K]: T}) {
  const aKeys: K[] = Object.keys(a) as K[];
  const bKeys: K[] = Object.keys(b) as K[];
  if (aKeys.length !== bKeys.length) return false;
  for (let i = 0; i < aKeys.length; i ++) {
    if (aKeys[i] !== bKeys[i]) return false;
    if (a[aKeys[i]] !== b[aKeys[i]]) return false;
  }
  return true;
}

export function distinct<V>(fn: (a: V, b: V) => boolean = identity): (observe: Observable<V>) => Observable<V> {
  return (observe) => (observer) => {
    let first = true;
    let lastValue: V;
    return observe({
      next: (value) => {
        if (first || !fn(lastValue, value)) {
          first = false;
          lastValue = value;
          observer.next(value);
        }
      },
      error: observer.error,
      complete: observer.complete
    });
  };
}
