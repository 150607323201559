import {ColorOperation, HSLA} from "common/types/index.ts";
import {InputGroup} from "./input-group.tsx";
import {InputGroupColorButton} from "../color-button/index.ts";
import {forwardRef, HTMLAttributes, Ref} from "react";
import {InputGroupLabel} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";

type ColorFieldProps = HTMLAttributes<HTMLLabelElement> & {
  label?: string;
  value: MutableRef<HSLA, ColorOperation[]>;
  readOnly?: boolean;
  disabled?: boolean;
};
export const ColorField = forwardRef(function ColorField({value, readOnly, disabled, label, ...props}: ColorFieldProps, ref: Ref<HTMLLabelElement>) {
  return <InputGroup ref={ref} {...props}>
    {label && <InputGroupLabel>{label}</InputGroupLabel>}
    <InputGroupColorButton readOnly={readOnly} disabled={disabled} valueRef={value} />
  </InputGroup>
});