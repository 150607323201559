import {
  ConstantOperation,
  constantType,
  ObjectType,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Transform,
  TransformOperation,
  transformType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "common/types/index.ts";
import {TokenReference} from "common/legends/index.ts";
import {AccessMask, AccessMaskOperation, accessMaskType} from "common/legends/visibility/index.ts";
import {Tool, ToolOperation, toolType} from "../../common/tool-mode/tool.ts";
import {NodeSelectionRef, SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {ViewConfiguration, ViewConfigurationOperation, viewConfigurationType} from "../common/view-configuration.ts";
import {MutableRef} from "common/ref";

export type AssetViewportProperties = {
  tokenReference: TokenReference;
  toolMode: Tool;
  visibilityMask: AccessMask;
  selectedNodeIds: NodeSelectionRef[];
  view: Transform;
  viewConfiguration: ViewConfiguration;
};

export type AssetViewportPropertiesOperation =
  | {type: "update-token-reference", operations: ValueOperation<TokenReference, ConstantOperation>[]}
  | {type: "update-tool-mode", operations: ToolOperation[]}
  | {type: "update-visibility-mask", operations: AccessMaskOperation[]}
  | {type: "update-selected-node-ids", operations: SetOperation<NodeSelectionRef>[]}
  | {type: "update-view", operations: TransformOperation[]}
  | {type: "update-view-configuration", operations: ViewConfigurationOperation[]}
  ;

export const assetViewportPropertiesType: Type<AssetViewportProperties, AssetViewportPropertiesOperation> = new ObjectType({
  tokenReference: new ValueType(constantType),
  toolMode: toolType,
  visibilityMask: accessMaskType,
  selectedNodeIds: new SetType<NodeSelectionRef>(SelectionRef.equals),
  view: transformType,
  viewConfiguration: viewConfigurationType
});

export function AssetViewportPropertiesSignals(value: MutableRef<AssetViewportProperties, AssetViewportPropertiesOperation[]>) {
  return {
    tokenReference: ValuePropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, TokenReference, ConstantOperation>(
      value => value.tokenReference,
      operations => [{type: "update-token-reference", operations}]
    )(value),
    toolMode: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, Tool, ToolOperation>(
      value => value.toolMode,
      operations => [{type: "update-tool-mode", operations}]
    )(value),
    view: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, Transform, TransformOperation>(
      value => value.view,
      operations => [{type: "update-view", operations}]
    )(value),
    selectedNodeIds: SetPropertySignal<AssetViewportProperties, AssetViewportPropertiesOperation, NodeSelectionRef>(
      value => value.selectedNodeIds,
      operations => [{type: "update-selected-node-ids", operations}]
    )(value),
    visibilityMask: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, AccessMask, AccessMaskOperation>(
      value => value.visibilityMask,
      operations => [{type: "update-visibility-mask", operations}]
    )(value),
    viewConfiguration: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, ViewConfiguration, ViewConfigurationOperation>(
      value => value.viewConfiguration,
      operations => [{type: "update-view-configuration", operations}]
    )(value)
  };
}