import React, {useContext} from "react";
import {RollVariables} from "common/qlab/index.ts";
import {createRef, Ref} from "common/ref";

const EMPTY_VARIABLES_SIGNAL = createRef<RollVariables>({});
export const VariablesContext = React.createContext<Ref<RollVariables>>(EMPTY_VARIABLES_SIGNAL);
export const VariablesProvider = VariablesContext.Provider;

export function useVariables() {
  return useContext(VariablesContext);
}
