import {useCallback} from "react";
import {SceneID} from "common/legends/index.ts";
import {Transform, ValueFn} from "common/types/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {ViewConfigurationFn} from "../../common/view-configuration.ts";
import {useEditor} from "../../../container/editor/editor-context.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {useStoreID} from "../../../../routes/game/use-store-id.ts";
import {ToolFn} from "../../../common/tool-mode/tool.ts";

export function useNavigateToSceneEditor() {
  const databaseRef = useDatabase();
  const editor = useEditor();
  const gameID = useStoreID();
  return useCallback(async (sceneID: SceneID) => {
    const scene = databaseRef.value.resources[sceneID];
    if (scene?.type !== "scene") return;
    editor.state.apply(state => ValueFn.set(state, {
      type: "scene",
      data: {
        sceneReference: {gameID, sceneID},
        toolMode: state?.data.toolMode || ToolFn.DEFAULT,
        selectedNodeIds: [],
        visibilityMask: state?.data.visibilityMask || AccessMaskFn.DEFAULT,
        view: Transform.DEFAULT,
        viewConfiguration: state?.data.viewConfiguration || ViewConfigurationFn.DEFAULT
      }
    }));
  }, [editor, databaseRef, gameID])
}
