import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {
  ARMOR_CLASS_TYPE_LABELS,
  ARMOR_CLASS_TYPES,
  Dnd5eArmorClassModifier,
  Dnd5eArmorClassModifierOperation,
  Dnd5eArmorClassModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-armor-class-modifier.ts";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";
import {InputSelect} from "#lib/components/input/input-select.tsx";

export type Dnd5eArmorClassModifierViewProps = {
  value: MutableRef<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eArmorClassModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eArmorClassModifierViewProps) {
  const {modifierTypeRef, expressionRef} = useMemo(() => Dnd5eArmorClassModifierSignals(value), [value]);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row gap-0.5">
    <InputGroup className="px-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Armor Class </InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Type</InputGroupLabel>
      <InputSelect value={modifierTypeRef} values={ARMOR_CLASS_TYPES} labels={ARMOR_CLASS_TYPE_LABELS} />
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Expression</InputGroupLabel>
      <InputMathExpression value={expressionRef} />
    </InputGroup>

    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
