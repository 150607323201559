import {z} from "zod";
import {ObjectType, PropertyRef} from "../object/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {MutableRef} from "#common/ref";
import {Type} from "../../type/index.ts";
import {Point} from "../point/index.ts";

export const Arc = z.object({
  radius: z.number(),
  startAngle: z.number(),
  endAngle: z.number()
});
export type Arc = z.infer<typeof Arc>;
export const ArcOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-radius"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-start-angle"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-end-angle"), operations: z.array(NumberOperation)})
])
export type ArcOperation = z.infer<typeof ArcOperation>;
export const arcType: Type<Arc, ArcOperation> = new ObjectType({
  radius: numberType,
  startAngle: numberType,
  endAngle: numberType
});

export function ArcRef(ref: MutableRef<Arc, ArcOperation[]>) {
  return ({
    radiusRef: PropertyRef<Arc, ArcOperation, number, NumberOperation>(value => value.radius, operations => [{type: "update-radius", operations}])(ref),
    startAngleRef: PropertyRef<Arc, ArcOperation, number, NumberOperation>(value => value.startAngle, operations => [{type: "update-start-angle", operations}])(ref),
    endAngleRef: PropertyRef<Arc, ArcOperation, number, NumberOperation>(value => value.endAngle, operations => [{type: "update-end-angle", operations}])(ref)
  })
}

export const ArcFn = {
  toPoints: (arc: Arc): Point[] => {
    const s = (arc.startAngle + 360) % 360;
    const e = (arc.endAngle + 360) % 360;

    const points: Point[] = [];
    points.push([0, 0]);
    const segments = Math.ceil((arc.radius / 8) * (s < e ? e - s : 360 + e - s) / 360 * 2 * Math.PI);
    for (let i = 0; i < segments + 1; i ++) {
      const angle = s + (s < e ? e - s : 360 + e - s) * (i / segments);
      points.push([
        Math.cos(angle * 2 * Math.PI / 360) * arc.radius,
        Math.sin(angle * 2 * Math.PI / 360) * arc.radius
      ]);
    }
    return points;
  }
}
