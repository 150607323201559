import {UUID} from "common/utils";
import {Layout} from "./layout.ts";

export type SplitterId = string;
export const SplitterId = {
  generate: (): SplitterId => {
    return UUID.generate();
  }
};

export type SplitterItem<Value> = {
  id: SplitterId;
  weight: number;
  content: Layout<Value>;
};
export type Splitter<Value> = {
  type: "row" | "column";
  items: SplitterItem<Value>[];
};
