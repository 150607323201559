import {CSSProperties} from "react";
import "./empty-view.css";

export type EmptyProps = {
  style?: CSSProperties;
};

export function EmptyView({style}: EmptyProps) {
  return <div className="empty-view" style={style}>
  </div>
}