import {useCallback} from "react";
import {Apply, ApplyAction, MultiTypeValue} from "common/types/index.ts";

export function useApplyCallback<Root, RootOperation, Item, ItemOperation>(
  apply: Apply<Root, RootOperation[]>,
  valueFn: (value: Root) => Item,
  operationFn: (operations: ItemOperation[]) => RootOperation[]
): Apply<Item, ItemOperation[]> {
  return useCallback((action: ApplyAction<Item, ItemOperation[]>) => apply((prevValue) => {
    const operations = action(valueFn(prevValue));
    return operations.length > 0 ? operationFn(operations) : [];
  }).then(valueFn), [apply, valueFn, operationFn]);
}

export function useMultiTypeApplyCallback<Root extends MultiTypeValue<any>, RootOperation, Item, ItemOperation>(
  apply: Apply<Root, RootOperation[]>
): Apply<Item, ItemOperation[]> {
  return useCallback((action: ApplyAction<Item, ItemOperation[]>) => apply((prevValue: Root): RootOperation[] => {
    const operations: ItemOperation[] = action(prevValue.data as unknown as Item);
    if (operations.length === 0) return [];
    return [{type: prevValue.type, operations} as unknown as RootOperation];
  }).then(response => response!.data as unknown as Item), [apply]);
}
