import {Game, GameID} from "common/legends/index.ts";
import {HTMLAttributes, useMemo} from "react";
import {useHref, useNavigate} from "react-router";
import {useStore} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";

export type GameCardProps = HTMLAttributes<HTMLButtonElement> & {
  gameId: GameID;
};

export function GameCard({gameId, className, style, ...props}: GameCardProps) {
  const gameRef = useMemo(() => ({storeId: gameId, accessTokens: []}), [gameId]);
  const game = useStore("game", gameRef.storeId);

  if (game.isLoading) {
    return <button
      className={twMerge(
        "relative p-0 outline-none cursor-pointer color-white w-[240px] h-[140px] rounded-lg border-slate-800 border-2 drop-shadow-md hover:border-slate-400 focus:border-slate-400",
        className
      )}
      style={{
        background: "linear-gradient(rgba(255,255,255,0) 92px, rgba(0,0,0,0.52) 108px), linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)",
        ...style
      }}
      {...props}>Loading</button>
  } else {
    return (<LoadedGameCard gameId={gameId} value={game.data} {...props} />);
  }
}

export type LoadedGameCardProps = HTMLAttributes<HTMLButtonElement> & {
  gameId: GameID;
  value: Game;
};
function LoadedGameCard({gameId, value, className, style, ...props}: LoadedGameCardProps) {
  const href = useHref(`/game/${gameId}`);
  const navigate = useNavigate();

  return <button
    className={twMerge(
    "relative p-0 outline-none cursor-pointer color-white w-[240px] h-[140px] rounded-lg border-slate-800 border-2 drop-shadow-md hover:border-slate-400 focus:border-slate-400",
    className
    )}
    style={{
      background: "linear-gradient(rgba(255,255,255,0) 92px, rgba(0,0,0,0.52) 108px), linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)",
      ...style
    }}
    {...props}
    onClick={() => navigate(href)}>
    <span className={"absolute bottom-0 p-1 text-shadow drop-shadow-lg text-h300"} style={{textShadow: "0px 2px 8px rgba(26, 26, 26, 0.48), 0px 2px 2px rgba(26, 26, 26, 0.48)"}}>{value.name}</span>
  </button>
}