import {z} from "zod";
import {MultiType, Type} from "#common/types/index.ts";
import {EffectNodeCondition, EffectNodeConditionOperation, effectNodeConditionType} from "./effect-node-condition.ts";
import {generateNodeConditionID, NodeConditionID} from "./node-condition-i-d.ts";

export const NodeCondition = z.discriminatedUnion("type", [
  z.object({type: z.literal("effect"), data: EffectNodeCondition})
]);
export type NodeCondition = z.infer<typeof NodeCondition>;

export const NodeConditionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("effect"), operations: z.array(EffectNodeConditionOperation)})
]);
export type NodeConditionOperation = z.infer<typeof NodeConditionOperation>;

export type NodeConditionTypes = {
  effect: Type<EffectNodeCondition, EffectNodeConditionOperation>
};
export const nodeConditionType: Type<NodeCondition, NodeConditionOperation> = new MultiType<NodeConditionTypes>({
  effect: effectNodeConditionType
});

export const NodeConditionFn = {
  getNodeConditionID: (condition: NodeCondition): NodeConditionID => condition.data.conditionID,
  copyNodeCondition: (condition: NodeCondition): NodeCondition => ({
    ...condition,
    data: {
      ...condition.data,
      conditionID: generateNodeConditionID()
    }
  })
}
