import {IconButton, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {ValueFn} from "common/types/index.ts";
import {DND_5E_DAMAGE_TYPE_TITLES, DND_5E_DAMAGE_TYPES, isDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import React, {Ref, useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {
  Dnd5eDamageRollModifier,
  Dnd5eDamageRollModifierEntity,
  Dnd5eDamageRollModifierOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-roll-modifier.ts";
import {DAMAGE_TYPE_IMAGES, DAMAGE_TYPE_UNKNOWN} from "../../../../../common/systems/dnd5e/damage-types/index.ts";
import {InputDiceExpression} from "../../dice-expression/input-dice-expression.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eDamageRollModifierViewProps = {
  value: MutableRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eDamageRollModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eDamageRollModifierViewProps) {
  const {damageType, hitExpression, critExpression} = useMemo(() => Dnd5eDamageRollModifierEntity(value), [value]);
  const damageTypeValue = useRefValue(damageType);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Damage</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupIcon>
        <img alt={damageTypeValue?.toUpperCase() || "Unknown"} className={twMerge(
          "w-8 h-8"
        )} title={"Damage"}
             src={damageTypeValue ? DAMAGE_TYPE_IMAGES[damageTypeValue] : DAMAGE_TYPE_UNKNOWN}/>
      </InputGroupIcon>
      <Select disabled={readOnly} value={damageTypeValue || ""} onChange={readOnly ? undefined : (ev) => {
        const next = ev.target.value;
        damageType.apply(prev => ValueFn.set(prev, isDamageType(next) ? next : undefined));
      }}>
        <option value={""}>N/A</option>
        {DND_5E_DAMAGE_TYPES.map(damageType => <option key={damageType} value={damageType}>{DND_5E_DAMAGE_TYPE_TITLES[damageType]}</option>)}
      </Select>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Regular</InputGroupLabel>
      <InputDiceExpression readOnly={readOnly} value={hitExpression} />
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Critical</InputGroupLabel>
      <InputDiceExpression readOnly={readOnly} value={critExpression} />
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove Damage" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
