import {ConstantOperation, Optional, ValueOperation} from "common/types/index.ts";
import {GMSheet, GMSheetFn, GMSheetOperation} from "./g-m-sheet-type.ts";
import {useEditorSheetReference} from "./use-editor-sheet-reference.ts";
import React, {Suspense, useMemo} from "react";
import {Dnd5eCharacterSheetView} from "./dnd-5e-character/dnd-5e-character-view.tsx";
import {from} from "common/observable";
import {SheetReferenceProvider} from "../../../common/sheet/sheet-reference-context.ts";
import {SheetProvider} from "../../../common/sheet/sheet-context.ts";
import {Dnd5eStatBlockView} from "./dnd-5e-stat-block/dnd-5e-stat-block-view.tsx";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {VariablesProvider} from "./dnd-5e/dnd-5e-action/dnd-5e-variables.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {MutableRef} from "common/ref";

function NoSheet() {
  return <div className="text-small text-center italic py-2 px-4 backdrop-blur-sm pointer-events-auto h-full">
    No sheet available...
  </div>;
}

export function EditorSheetView({valueRef}: {
  valueRef: MutableRef<GMSheet, GMSheetOperation[]>;
}) {
  const {reference} = useRefValue(valueRef);
  const pinnedSheetReference = useMemo((): MutableRef<Optional<SheetReference>, ValueOperation<Optional<SheetReference>, ConstantOperation>[]> => {
    return new MutableRef({
      value(): Optional<SheetReference> {
        return reference
      },
      observe: from(reference),
      apply: (fn) => valueRef.apply(prev => {
        const operations = fn(prev.reference)
        return operations.length > 0 ? GMSheetFn.updateReference(operations) : [];
      }).then(value => value.reference)
    });
  }, [reference, valueRef.apply]);

  const sheetReference = useEditorSheetReference(pinnedSheetReference);
  const sheet = useSheetSignal(sheetReference);
  const type = useComputedValue(sheet, sheet => sheet?.type);
  const variables = useMemo(() => sheetVariablesSignal(sheet), [sheet]);

  if (!type || !sheetReference) return <NoSheet />;
  return <Suspense fallback={<NoSheet />}>
    <SheetReferenceProvider value={sheetReference}><SheetProvider value={sheet}>
      <VariablesProvider value={variables}>
        {type === "dnd-5e-character" && <Dnd5eCharacterSheetView reference={sheetReference} pinned={pinnedSheetReference} />}
        {type === "dnd-5e-stat-block" && <Dnd5eStatBlockView reference={sheetReference} pinned={pinnedSheetReference} />}
      </VariablesProvider>
    </SheetProvider></SheetReferenceProvider>
  </Suspense>
}
