import {useMemo} from "react";
import {GameSignals, NodeId} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {TurnTrackerFn} from "common/legends/game/turn-tracker/index.ts";
import {useGame} from "../../../routes/game/index.ts";
import {useNode} from "../../viewport/token/use-node.ts";
import {Optional} from "common/types/index.ts";

export function useIsNodeTurn(nodeID: NodeId) {
  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  return useMemo(() => pipe(
    turnTracker.observe,
    map((tracker): boolean => {
      if (tracker.currentTurnID === undefined) return false;
      const currentTurn = tracker.turns[tracker.currentTurnID];
      if (currentTurn?.type !== "node") return false;
      return currentTurn.data.nodeID === nodeID;
    }),
    distinct()
  ), [turnTracker]);
}

export function useIsCurrentTurn() {
  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const nodeIDObservable = useMemo(() => pipe(
    turnTracker.observe,
    map((tracker): Optional<NodeId> => {
      if (tracker.currentTurnID === undefined) return undefined;
      const currentTurn = tracker.turns[tracker.currentTurnID];
      if (currentTurn?.type !== "node") return undefined;
      return currentTurn.data.nodeID;
    }),
    distinct()
  ), [turnTracker]);
  const node = useNode(useObservable(nodeIDObservable, undefined, [nodeIDObservable]));

  const userID = useUserID()!;
  return useObservable(pipe(
    node.observe,
    map(node => {
      if (node?.type !== "token") return false;
      return node.data.ownerIDs.includes(userID);
    }),
    distinct()
  ), false, [userID, node.observe]);
}

export function useIsNextTurn() {
  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const nodeIDObservable = useMemo(() => pipe(
    turnTracker.observe,
    map((tracker): Optional<NodeId> => {
      if (tracker.currentTurnID === undefined) return undefined;
      const orderedTurns = TurnTrackerFn.orderedTurnIDs(tracker.turns);
      if (orderedTurns.length === 0) return undefined;
      const nextTurnID = orderedTurns[(
        orderedTurns.indexOf(tracker.currentTurnID) + 1
      ) % orderedTurns.length];
      const currentTurn = tracker.turns[nextTurnID];
      if (currentTurn?.type !== "node") return undefined;
      return currentTurn.data.nodeID;
    }),
    distinct()
  ), [turnTracker.observe]);
  const node = useNode(useObservable(nodeIDObservable, undefined, [nodeIDObservable]));

  const userID = useUserID()!;
  return useObservable(pipe(
    node.observe,
    map(node => {
      if (node?.type !== "token") return false;
      return node.data.ownerIDs.includes(userID);
    }),
    distinct()
  ), false, [userID, node.observe]);
}