import React, {useMemo} from "react";
import {PlayerContainer} from "./player-container.tsx";
import {PlayerControllerProvider} from "./player-controller-provider.ts";
import {PlayerController} from "./player-controller.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {GameSignals, Grid, Scene, SceneID, SceneOperation} from "common/legends/index.ts";
import {useGame, useGameReference} from "../../../routes/game/index.ts";
import {TokenViewport} from "../../viewport/token/token-viewport.tsx";
import {useCharactersNodeSceneIDs} from "../../viewport/token/use-controller-node-ids.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useScene} from "../../viewport/token/use-scene.ts";
import {usePresent} from "../../common/use-optional-signal.ts";
import {HSLA} from "common/types/generic/index.ts";
import {from} from "common/observable";
import {MutableRef} from "common/ref";

const DEFAULT_SCENE: Scene = {
  name: "DEFAULT",
  grid: Grid.DEFAULT,
  children: [],
  backgroundColor: [0, 0, 0, 0.5] as HSLA
};
const DEFAULT_SCENE_REF: MutableRef<Scene, SceneOperation[]> = new MutableRef<Scene, SceneOperation[]>({
  value(): Scene {
    return DEFAULT_SCENE;
  },
  observe: from(DEFAULT_SCENE),
  apply: async () => DEFAULT_SCENE
});

export function PlayerGameView() {
  const gameRef = useGameReference();
  const game = useGame();
  const {xCard, defaultSceneID} = useMemo(() => GameSignals(game), [game]);
  const xCardActivated = useRefValue(xCard);

  const userID = useUserID()!;
  const nodeScenesRef = useCharactersNodeSceneIDs(userID);

  const playerController = useMemo(() => new PlayerController(gameRef.storeId), [gameRef.storeId]);
  const controllerNodeID = useComputedValue(playerController.state, state => state.activeController?.controllerNodeID);
  const currentDefaultSceneID = useRefValue(defaultSceneID);
  const controllerSceneID = useComputedValue(nodeScenesRef, nodeScenes => controllerNodeID ? nodeScenes[controllerNodeID] : undefined, [controllerNodeID]);
  const sceneID = controllerSceneID ?? currentDefaultSceneID;
  const scene = usePresent(useScene(sceneID));
  return <PlayerControllerProvider value={playerController}>
    <PlayerContainer>
      {!xCardActivated && <TokenViewport storeId={gameRef.storeId} sceneID={sceneID ?? "" as SceneID} scene={scene ?? DEFAULT_SCENE_REF} />}
      {xCardActivated && <span className="flex-1 text-red-600 font-bold text-[80vh] flex items-center justify-center text-center select-none">
        X
      </span>}
    </PlayerContainer>
  </PlayerControllerProvider>;
}
