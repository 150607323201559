import {Tree} from "./tree.ts";
import {PostVisitResult, PreVisitResult, TreeVisitor, VisitResult} from "./tree-visitor.ts";

export enum WalkResult {
  CONTINUE,
  SKIP_SIBLINGS,
  TERMINATE
}

export function walkTree<Value extends Tree<Value>>(values: Value[], visitor: TreeVisitor<Value>, path: number[] = []): WalkResult {
  for (let i = 0; i < values.length; i ++) {
    const value = values[i];
    const preVisitResult: PreVisitResult = visitor.preVisit ? visitor.preVisit(value) || PreVisitResult.CONTINUE : PreVisitResult.CONTINUE;
    if (preVisitResult === PreVisitResult.TERMINATE) return WalkResult.TERMINATE;
    if (preVisitResult === PreVisitResult.SKIP_VISIT) continue;

    const visitResult: VisitResult = visitor.visit ? visitor.visit(value, [...path, i]) || VisitResult.CONTINUE : VisitResult.CONTINUE;
    if (visitResult === VisitResult.TERMINATE) return WalkResult.TERMINATE;

    if (preVisitResult !== PreVisitResult.SKIP_SUBTREE && visitResult !== VisitResult.SKIP_SUBTREE) {
      if (Tree.isParentNode(value)) {
        const walkResult: WalkResult = walkTree(value.data.children, visitor, [...path, i]);
        if (walkResult === WalkResult.SKIP_SIBLINGS) break;
        if (walkResult === WalkResult.TERMINATE) return WalkResult.TERMINATE;
      }
    }

    const postVisitResult: PostVisitResult = visitor.postVisit ? visitor.postVisit(value) || PostVisitResult.CONTINUE : PostVisitResult.CONTINUE;
    if (postVisitResult === PostVisitResult.TERMINATE) return WalkResult.TERMINATE;

    if (preVisitResult === PreVisitResult.SKIP_SIBLINGS || visitResult === VisitResult.SKIP_SIBLINGS || postVisitResult === PostVisitResult.SKIP_SIBLINGS) {
      break;
    }
  }
  return WalkResult.CONTINUE;
}
