import {PropsWithChildren} from "react";
import {twMerge} from "tailwind-merge";
import {ButtonSize, Checkbox} from "#lib/components/index.ts";

export type ExpandableCheckboxProps = PropsWithChildren<{
  className?: string;
  expanded: boolean;
  size?: ButtonSize;
  readOnly?: boolean;
  disabled?: boolean;
  toggleExpand?: () => void;
}>;

export function ExpandableCheckbox({expanded, size = "small", toggleExpand, readOnly, disabled, className, children}: ExpandableCheckboxProps) {
  return (
    <div className={twMerge(
      "text-white/80 p-0 outline-none",
      "flex flex-row h-10",
      size === "small" && "h-8",
      className
    )}>
      <label className={twMerge(
        "px-2 bg-zinc-900/80 w-10 h-10 shrink-0 flex items-center justify-center pointer-events-auto backdrop-blur-sm",
        size === "small" && "w-8 h-8",
        toggleExpand === undefined ? "cursor-default text-white/25" : "cursor-pointer"
      )}>
        <Checkbox checked={expanded} readOnly={readOnly} disabled={disabled} onChange={readOnly ? undefined : toggleExpand} />
      </label>
      <span className="flex-1 flex items-center h-full bg-zinc-900/40 border-y border-zinc-800/40">
        {children}
      </span>
    </div>
  );
}
