import {applyAll, invertAll, Optional, OptionalOperation, transformAll, Type, ValueOperation} from "../../types/index.ts";
import {MessageOperation, messageType, MessageValue} from "./message.ts";
import {UserID} from "../../legends/index.ts";
import {QLabMessageChangesetID} from "#common/qlab/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export type QLabMessage = {
  revision: number;
  value: Optional<MessageValue>;
};
export type QLabMessageChangeset = {
  changesetId: QLabMessageChangesetID;
  revision: number;
  userId: UserID;
  operations: ValueOperation<Optional<MessageValue>, OptionalOperation<MessageOperation>>[];
};

export const qlabMessageType: Type<QLabMessage, QLabMessageChangeset> = {
  apply: (value: QLabMessage, changeset: QLabMessageChangeset): QLabMessage => {
    if (value.revision !== changeset.revision) {
      throw new Error("Mismatch between message and changeset. Message: " + JSON.stringify(value) + " Changeset: " + JSON.stringify(changeset));
    }
    return ({
      value: applyAll(messageType, value.value, changeset.operations),
      revision: value.revision + 1
    });
  },
  invert: (operation: QLabMessageChangeset): QLabMessageChangeset[] => {
    return [{
      ...operation,
      operations: invertAll(messageType, operation.operations)
    }];
  },
  transform: (leftOperation: QLabMessageChangeset, topOperation: QLabMessageChangeset, tieBreaker: boolean): QLabMessageChangeset[] => {
    if (leftOperation.revision !== topOperation.revision) throw new Error("Mismatch revision. " + JSON.stringify(leftOperation) + " -- " + JSON.stringify(topOperation));
    if (leftOperation.changesetId === topOperation.changesetId) return [];
    return [{
      ...leftOperation,
      operations: transformAll(messageType, leftOperation.operations, topOperation.operations, tieBreaker)[0],
      revision: tieBreaker ? leftOperation.revision : leftOperation.revision + 1
    }];
  },
  migrateValue(value: any): QLabMessage {
    return ({
      revision: value.revision,
      value: messageType.migrateValue(value.value)
    });
  },
  migrateOperation(operation: any): QLabMessageChangeset[] {
    return [{
      changesetId: operation.changesetId,
      revision: operation.revision,
      userId: operation.userId,
      operations: operation.operations.flatMap(messageType.migrateOperation)
    }];
  },
  validate: (value: any): ValidationError[] => {
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type. Expected QLabMessage.", value}}];
    if (typeof value.revision !== "number")  return [{path: [], data: {message: "Invalid type. Expected QLabMessage.", value}}]
    return messageType.validate(value.value);
  }
};