import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {Dnd5eDCModifier, Dnd5eDCModifierOperation, Dnd5eDCModifierSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-d-c-modifier.ts";
import {InputDiceExpression} from "../../dice-expression/input-dice-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eDCModifierViewProps = {
  value: MutableRef<Dnd5eDCModifier, Dnd5eDCModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eDCModifierView({value, readOnly, dragHandlerRef, dragRefPreview, remove}: Dnd5eDCModifierViewProps) {
  const {expression} = useMemo(() => Dnd5eDCModifierSignals(value), [value]);
  return <div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Difficulty Class</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputDiceExpression readOnly={readOnly} value={expression} />
    </InputGroup>
    {!readOnly && <IconButton title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>;
}
