import {Button, Field, Input, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {SquareGridTypeIcon} from "./square-grid-type-icon.tsx";
import {HexagonHorizontalGridTypeIcon} from "./hexagon-horizontal-grid-type-icon.tsx";
import {HexagonVerticalGridTypeIcon} from "./hexagon-vertical-grid-type-icon.tsx";
import {IsometricGridTypeIcon} from "./isometric-grid-type-icon.tsx";
import {NumberOperation} from "common/types/index.ts";
import {Grid, GridOperation, GridRuler, GridRulerOperation, GridShape, GridUnitOperation} from "common/legends/index.ts";
import {useCallback} from "react";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useApplyCallback, useObservableLoader, useSetNumberCallback, useSetValueCallback} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";

export type GridOptionsProps = {
  value: MutableRef<Grid, GridOperation[]>;
};

const defaultDimensions: {[shape: string]: {width: number, height: number}} = {
  "square": {width: 64, height: 64},
  "hexagon-vertical": {width: 56, height: 64},
  "hexagon-horizontal": {width: 64, height: 56},
  "isometric": {width: 128, height: 64}
};

const getWidth = (value: Grid) => value.width;
const updateWidth = (operations: NumberOperation[]): GridOperation[] => [{type: "update-width", operations}];

const getHeight = (value: Grid) => value.height;
const updateHeight = (operations: NumberOperation[]): GridOperation[] => [{type: "update-height", operations}];

const getSubdivisions = (value: Grid) => value.subdivisions;
const updateSubdivisions = (operations: NumberOperation[]): GridOperation[] => [{type: "update-subdivisions", operations}];

const getGridUnit = (value: Grid) => value.unit;
const updateGridUnit = (operations: GridUnitOperation[]): GridOperation[] => [{type: "update-unit", operations}];

const getGridRuler = (value: Grid) => value.ruler;
const updateGridRuler = (operations: GridRulerOperation[]): GridOperation[] => [{type: "update-ruler", operations}];

export function GridOptions({value}: GridOptionsProps) {
  const setGridShape = useCallback((shape: GridShape) => {
    value.apply(prevValue => [{
      type: "update-shape",
      operations: [{type: "set", prevValue: prevValue.shape, nextValue: shape}]
    }, {
      type: "update-width",
      operations: [{type: "set", prevValue: prevValue.width, nextValue: defaultDimensions[shape].width}]
    }, {
      type: "update-height",
      operations: [{type: "set", prevValue: prevValue.height, nextValue: defaultDimensions[shape].height}]
    }]);
  }, [value.apply]);
  const setGridWidth = useSetNumberCallback(useApplyCallback(value.apply, getWidth, updateWidth));
  const setGridHeight = useSetNumberCallback(useApplyCallback(value.apply, getHeight, updateHeight));
  const setGridSubdivisions = useSetNumberCallback(useApplyCallback(value.apply, getSubdivisions, updateSubdivisions));
  const setGridUnit = useSetValueCallback(useApplyCallback(value.apply, getGridUnit, updateGridUnit));
  const setGridRuler = useSetValueCallback(useApplyCallback(value.apply, getGridRuler, updateGridRuler));

  const loader = useObservableLoader(value.observe);

  if (loader.isLoading) return <></>

  return (
    <Fieldset>
      <Field>
        <div className="flex justify-between flex-wrap">
          <Button variant={loader.data.shape === "square" ? "primary" : "tertiary"} size={"large"} onClick={() => setGridShape("square")}>
            <SquareGridTypeIcon size={"40px"} />
          </Button>
          <Button variant={loader.data.shape === "hexagon-horizontal" ? "primary" : "tertiary"} size={"large"} onClick={() => setGridShape("hexagon-horizontal")}>
            <HexagonHorizontalGridTypeIcon size={"40px"} />
          </Button>
          <Button variant={loader.data.shape === "hexagon-vertical" ? "primary" : "tertiary"} size={"large"} onClick={() => setGridShape("hexagon-vertical")}>
            <HexagonVerticalGridTypeIcon size={"40px"} />
          </Button>
          <Button variant={loader.data.shape === "isometric" ? "primary" : "tertiary"} size={"large"} onClick={() => setGridShape("isometric")}>
            <IsometricGridTypeIcon size={"40px"} />
          </Button>
        </div>
      </Field>

      <div className={"flex w-full"}>
        <InputGroup size="small" className="flex-1">
          <InputGroupLabel>W</InputGroupLabel>
          <Input size="small" type="number" min={1} max={300} step={1} value={loader.data.width} onChange={ev => setGridWidth(parseInt(ev.target.value))} />
        </InputGroup>
        <InputGroup size="small" className="flex-1">
          <InputGroupLabel>H</InputGroupLabel>
          <Input size="small" type="number" min={1} max={300} step={1} value={loader.data.height} onChange={ev => setGridHeight(parseInt(ev.target.value))} />
        </InputGroup>
      </div>

      <InputGroup size="small">
        <InputGroupLabel>Subdivisions</InputGroupLabel>
        <Input size="small" type="number" value={loader.data.subdivisions} onChange={ev => setGridSubdivisions(Math.min(Math.max(0, parseInt(ev.target.value)), 4))} />
      </InputGroup>

      <InputGroup size="small">
        <InputGroupLabel>Units</InputGroupLabel>
        <Input size="small" type="number" value={loader.data.unit[0]} onChange={ev => setGridUnit([parseInt(ev.target.value), loader.data.unit[1]])} />
        <Input size="small" type="text" value={loader.data.unit[1]} onChange={ev => setGridUnit([loader.data.unit[0], ev.target.value])} />
      </InputGroup>

      <InputGroup size="small" className="pr-0">
        <InputGroupLabel>Rulers</InputGroupLabel>
        <Select value={loader.data.ruler} onChange={ev => setGridRuler(ev.target.value as GridRuler)}>
          <option value="manhattan">Manhattan</option>
          <option value="euclidean">Euclidean</option>
          <option value="dnd-standard">D&D Standard</option>
          <option value="dnd-variant">D&D Variant</option>
        </Select>
      </InputGroup>
    </Fieldset>
  )
}