import {IconButton, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {ValueFn} from "common/types/index.ts";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eTrait,
  Dnd5eTraitModifier,
  Dnd5eTraitModifierOperation,
  Dnd5eTraitModifierRef,
  DND_5E_TRAITS
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-trait-modifier.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

const TRAIT_LABELS: {[trait in Dnd5eTrait]: string} = {
  "elven-accuracy": "Elven Accuracy",
  "halfling-luck": "Halfling Luck",
  "jack-of-all-trades": "Jack of All Trades",
  "reliable-talent": "Reliable Talent",
  "silver-tongue": "Silver Tongue",
  "glibness": "Glibness"
};

export type Dnd5eTraitModifierViewProps = {
  value: MutableRef<Dnd5eTraitModifier, Dnd5eTraitModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLLabelElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eTraitModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eTraitModifierViewProps) {
  const query = useRefValue(value);
  const {trait} = useMemo(() => Dnd5eTraitModifierRef(value), [value]);

  return (<div role="list-item" className="flex flex-row">
    <InputGroup ref={dragRefPreview} className="flex-1 px-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel className="px-1 w-10" title="Trait">Trait</InputGroupLabel>
      <Select disabled={readOnly} value={query.trait || ""} onChange={readOnly ? undefined : (ev) => {
        const next = ev.target.value;
        trait.apply(prev => ValueFn.set(prev, next !== "" ? next as Dnd5eTrait : undefined));
      }}>
        <option value="">N/A</option>
        {DND_5E_TRAITS.map(trait => <option key={trait} value={trait}>{TRAIT_LABELS[trait]}</option>)}
      </Select>
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove Damage" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
