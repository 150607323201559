import React, {Ref, useContext} from "react";
import ReactDOM from "react-dom";

const ExternalPortalContext = React.createContext<HTMLElement>(document.body);
export const PortalProvider = ExternalPortalContext.Provider;
export function usePortal() {
  return useContext(ExternalPortalContext);
}


interface ExternalPortalProps<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
}

export const ExternalPortal = React.forwardRef(function ExternalPortal<T extends React.ElementType = "div">({as, ...props}: ExternalPortalProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ExternalPortalProps<T>>, ref: Ref<HTMLDivElement>) {
  const Component = as || "div";
  return ReactDOM.createPortal(<Component ref={ref} {...props}>
    {props.children}
  </Component>, usePortal());
});
