import {EditorProperties} from "./index.ts";
import {useSelectionRef, useSelectionRefName} from "./use-properties.ts";
import {StackId} from "#lib/container/index.ts";
import {EditorStacks} from "../../container/editor/editor-stacks.ts";
import {StackItemTab, StackItemTabProps} from "#lib/container/react/stack/stack-item-tab.tsx";
import {forwardRef, Ref} from "react";

export type PropertiesTabViewProps = Omit<StackItemTabProps, "Icon" | "label" | "content"> & {
  id: StackId;
  content: EditorProperties;
};

export const EditorPropertiesTabView = forwardRef(function EditorPropertiesTabView({id, content, ...props}: PropertiesTabViewProps, ref: Ref<any>) {
  const selectionRef = useSelectionRef(content.reference);
  const selectionName = useSelectionRefName(selectionRef);
  const name = selectionName || EditorStacks["properties"].label;

  if (content.reference === undefined) {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={EditorStacks["properties"].Icon}
      label={EditorStacks["properties"].label}
    />);
  } else {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={EditorStacks["properties"].Icon}
      label={`${EditorStacks["properties"].label} / ${name}`}
    />);
  }
});

