import {Dialog} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";

export type ConfirmDeleteProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmDeleteModal({open, onClose, onConfirm}: ConfirmDeleteProps) {
  return <Dialog open={open} onClose={onClose} className={"fixed inset-0"}>
    <div className={"fixed inset-0 bg-slate-900/75 backdrop-blur"} />

    <div className="flex min-h-full items-start justify-center p-4 pt-[10vh] gap-2">
      <Dialog.Panel className={"w-full max-w-lg relative bg-slate-800/50 text-white rounded-lg backdrop-blur shadow-md gap-2"}>
        <Dialog.Title className="text-xl font-bold p-4">Confirm Deletion</Dialog.Title>
        <Dialog.Description className={"px-4"}>
          <p>
            Are you sure you want to delete this game? All of the game data will be permanently removed. This action cannot be undone.
          </p>
        </Dialog.Description>

        <div className="flex gap-2 p-4">
          <Button className="flex-1 rounded" onClick={onConfirm} variant="destructive">Delete</Button>
          <Button className="flex-0 rounded" onClick={onClose} variant="tertiary">Cancel</Button>
        </div>
      </Dialog.Panel>
    </div>
  </Dialog>
}