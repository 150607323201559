import {Dnd5eFeature, Sheet} from "#common/legends/index.ts";
import {Dnd5eAction} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {Dnd5eEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {getSheetVariables} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {MathExpressionFn} from "#common/math/index.ts";
import {Dnd5eActionModifier, isActionModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {Dnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {Dnd5eActionEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect.ts";
import {RollVariables} from "#common/qlab/index.ts";
import {Optional} from "#common/types/index.ts";
import {Dice, DiceExpression} from "#common/dice/index.ts";
import {Dnd5eAttackRollModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-attack-roll-modifier.ts";
import {Dnd5eDCModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-d-c-modifier.ts";
import {Dnd5eSheetFn} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-sheet-helper.ts";
import {overrideFeatures} from "#common/legends/game/system/dnd-5e-feature-override.ts";

export function getActiveEffects(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eEffect[] {
  if (sheet === undefined) return [];
  return [
    ...Dnd5eSheetFn.getEffects(sheet, globalFeatures),
  ].filter(e => e.enabled) || [];
}

export function getActiveActionEffects(sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): (Dnd5eEffect | Dnd5eActionEffect)[] {
  return [
    ...getActiveEffects(sheet, globalFeatures),
    ...action.actionEffects.filter(m => m.enabled)
  ];
}

export function getActiveActionLabel(sheetValue: Sheet, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]) {
  const actionEffects = getActiveActionEffects(sheetValue, action, globalFeatures)
    .filter(effect => effect.modifiers.some(modifier => isActionModifierType(modifier.type)));
  return action.label.substring(action.label.lastIndexOf("/") + 1) + (actionEffects.length > 0 ? ` (${actionEffects
    .map(m => m.name.substring(m.name.indexOf(":") + 1).trim()).join(", ")})` : "")
}

export function getActiveFeatures(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eFeature[] {
  if (sheet?.type === "dnd-5e-character") {
    return [
      ...overrideFeatures(globalFeatures, sheet.data.globalFeatures),
      ...sheet.data.classes
        .flatMap(c => c.features.filter(f => f.level <= c.level).sort((a, b) => a.level - b.level).map(f => f.feature)),
      ...sheet.data.background.features,
      ...sheet.data.race.features,
      ...sheet.data.features
    ].filter(f => f.enabled);
  } else if (sheet?.type === "dnd-5e-stat-block") {
    return [
      ...overrideFeatures(globalFeatures, sheet.data.globalFeatures),
      ...sheet.data.features
    ].filter(f => f.enabled);
  } else {
    return [];
  }
}

export function getActiveModifiers(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eModifier[] {
  if (sheet === undefined) {
    return getActiveEffects(sheet, globalFeatures).flatMap((e) => e.modifiers);
  } else {
    const activeItems = (sheet.type === "dnd-5e-character" ? sheet.data.inventory : []).filter(i => i.equipped);
    const activeFeatures = getActiveFeatures(sheet, globalFeatures);
    return [
      ...activeFeatures.flatMap(f => f.modifiers),
      ...activeItems.flatMap(i => {
        return [
          ...i.modifiers,
          ...i.effects.filter(e => e.enabled)
            .flatMap(e => e.modifiers)
        ];
      }),
      ...getActiveEffects(sheet, globalFeatures).flatMap((e) => e.modifiers)
    ];
  }
}

export function getActiveActionModifiers(sheet: Optional<Sheet>, action: Dnd5eAction, features: Dnd5eFeature[]): (Dnd5eModifier | Dnd5eActionModifier)[] {
  return [
    ...getActiveModifiers(sheet, features),
    ...action.modifiers,
    ...action.actionEffects.filter(m => m.enabled).flatMap((effect): (Dnd5eModifier | Dnd5eActionModifier)[] => effect.modifiers)
  ];
}

export function getActiveVariables(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): RollVariables {
  const variables = getSheetVariables(sheet);
  const effects = getActiveEffects(sheet, globalFeatures);
  for (const effect of effects) {
    for (const modifier of effect.modifiers) {
      if (modifier.type !== "variable-override") continue;
      variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
    }
  }
  return variables;
}

export function getActiveActionVariables(sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): RollVariables {
  if (sheet === undefined) return ({});
  const variables = getSheetVariables(sheet);
  const modifiers = getActiveActionModifiers(sheet, action, globalFeatures);
  for (const modifier of modifiers) {
    if (modifier.type !== "variable-override") continue;
    variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
  }
  return variables;
}

export function getActiveActionBaseRoll(sheet: Sheet, action: Dnd5eAction, globalFeatures: Dnd5eFeature[], hasAdvantage: boolean, hasDisadvantage: boolean) {
  const modifiers = getActiveActionModifiers(sheet, action, globalFeatures);
  const hasElvenAccuracy = modifiers.some(m => m.type === "trait" && m.data.trait === "elven-accuracy");
  const hasHalflingLuck = modifiers.some(m => m.type === "trait" && m.data.trait === "halfling-luck");

  let baseRoll = `1d20${hasHalflingLuck ? "ro1" : ""}`;
  if (hasAdvantage && !hasDisadvantage) baseRoll = `${hasElvenAccuracy && action.offense !== undefined && ["dex", "int", "wis", "cha"].includes(action.offense) ? "3" : "2"}d20kh1${hasHalflingLuck ? "ro1" : ""}`;
  else if (!hasAdvantage && hasDisadvantage) baseRoll = `2d20kl1${hasHalflingLuck ? "ro1" : ""}`;

  const variables = getActiveActionVariables(sheet, action, globalFeatures);
  const criticalRange = MathExpressionFn.executeMathExpression(action.criticalRange, variables);
  return `${baseRoll}cs>=${criticalRange}cf=1`;
}

export function getActiveActionAttackModifier(sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): DiceExpression {
  const attackRollModifiers = getActiveActionModifiers(sheet, action, globalFeatures)
    .filter(m => m.type === "attack-roll")
    .map(m => m.data) as Dnd5eAttackRollModifier[];
  const modifiers = [
    ...action.offense ? [`{${action.offense.toUpperCase()}}`] : [],
    ...action.proficient ? [`{PB}`] : [],
    ...attackRollModifiers.map(a => a.expression)
  ];
  if (modifiers.length === 0) return Dice.assertDiceExpression("0");
  return Dice.assertDiceExpression(modifiers.join("+").replaceAll("+-", "-"));
}

export function getActiveActionDifficultyClass(sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): DiceExpression {
  let baseRoll = "8";
  const dcModifiers = getActiveActionModifiers(sheet, action, globalFeatures)
    .filter(m => m.type === "difficulty-class")
    .map(m => m.data) as Dnd5eDCModifier[];
  const modifiers = [
    baseRoll,
    ...action.offense ? [`{${action.offense.toUpperCase()}}`] : [],
    ...action.proficient ? [`{PB}`] : [],
    ...dcModifiers.map(a => a.expression)
  ];
  if (modifiers.length === 0) return Dice.assertDiceExpression(baseRoll);
  return Dice.assertDiceExpression(modifiers.join("+").replaceAll("+-", "-"));
}
