import {MessageOperation, MessageValue, QLabMessageID, QLabStoreID} from "common/qlab/index.ts";
import {ApplyAction, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {useEffect, useState} from "react";
import {useInstance} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map, subscribe} from "common/observable";
import {useUserID} from "#lib/auth/use-get-user-id.ts";

export type ChannelSession = {
  messages: {[messageId: QLabMessageID]: Optional<MessageValue>},
  createMessage: (messageId: QLabMessageID, message: MessageValue) => Promise<void>,
  applyToMessage: (messageId: QLabMessageID, fn: ApplyAction<Optional<MessageValue>, ValueOperation<Optional<MessageValue>, MessageOperation>[]>) => Promise<void>,
  requestMore: (limit: number) => Promise<void>
};

export function useMessages(storeId: QLabStoreID): ChannelSession {
  const instance = useInstance();
  const [loader, setLoader] = useState<ChannelSession>({
    messages: {},
    createMessage: async () => {},
    applyToMessage: Promise.resolve,
    requestMore: Promise.resolve
  });
  const userId = useUserID();
  useEffect(() => {
    let messages: {[messageId: QLabMessageID]: Optional<MessageValue>} = {};
    const createMessage = (messageId: QLabMessageID, value: MessageValue): Promise<void> => {
      return instance.applyToMessage(storeId, messageId, _ => ValueFn.set(undefined, value));
    };
    const applyToMessage = (messageId: QLabMessageID, fn: ApplyAction<Optional<MessageValue>, ValueOperation<Optional<MessageValue>, MessageOperation>[]>): Promise<void> => {
      return instance.applyToMessage(storeId, messageId, fn);
    };
    const requestMore = (limit: number): Promise<void> => {
      const messageIds: QLabMessageID[] = Object.keys(messages).sort() as unknown as QLabMessageID[];
      return instance.requestMessages(storeId, {
        lastMessage: messageIds.length > 0 ? messageIds[0]: undefined,
        limit
      });
    };

    return pipe(
      instance.observe(storeId),
      map(store => store.messages),
      distinct(),
      subscribe((m) => {
        messages = m;
        setLoader({messages: m, applyToMessage, createMessage, requestMore})
      })
    );
  }, [instance, setLoader, storeId, userId]);

  return loader;
}
