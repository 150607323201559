import {NodeId, TextNode} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Color, Transform} from "common/types/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {useIsSelected} from "./use-is-selected.ts";
import {NodeView} from "./node-view.tsx";
import {useSheetEffects} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-effects.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {getTextHeight, getTextOrigin, getTextWidth} from "#lib/gl-react/component/font-helper.ts";
import {useRefValue} from "#lib/signal/index.ts";

export type TextNodeViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
  value: TextNode;
};
export function TextNodeView({projection, view, visibilityMask, accessNodeIDs, model, opacity, value: {id, children, origin, transform, pivot, conditions, visibilityLayer, text, size, hTextAlign, vTextAlign, fillColor, outlineColor}}: TextNodeViewProps) {
  const valueModel = Transform.divide(transform, model);
  const finalOpacity = opacity;
  const isSelected = useIsSelected(id);

  const sheetEffects = useSheetEffects(useSheetSignal(useSheetReference()), useGlobalFeatures());
  const conditionsMet = useRefValue(useMemo(() => sheetEffects.map(effects => {
    return conditions.length === 0 || conditions
      .every(condition => condition.type !== "effect" ||
        effects
          .filter(effect => effect.effectID === condition.data.effectID && effect.enabled)
          .length > 0
      )
    }).distinct(), [conditions, sheetEffects]));

  const rgbFillColor = useMemo(() => Color.toRGB(fillColor), [fillColor]);
  const rgbOutlineColor = useMemo(() => Color.toRGB(outlineColor), [outlineColor]);

  const width = getTextWidth(text, size);
  const height = getTextHeight(text, size);
  const textOrigin = getTextOrigin(text, size, hTextAlign, vTextAlign);

  if ((!conditionsMet || !AccessMaskFn.canSee(visibilityMask, visibilityLayer)) && !accessNodeIDs.includes(id)) return <></>;
  return (<binder>
    <TextShader projection={projection} view={view} model={valueModel} value={text} size={size} opacity={finalOpacity} hTextAlign={hTextAlign} vTextAlign={vTextAlign} color={rgbFillColor} borderColor={rgbOutlineColor} />
    {children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} model={valueModel} value={child} opacity={finalOpacity} />
    })}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={textOrigin} size={[width, height]} color={Color.WHITE} repeatX={1} repeatY={1} />}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[origin[0] - pivot[0] + 2, origin[1] - pivot[1] + 2]} size={[4, 4]} color={Color.GREEN} repeatX={1} repeatY={1} />}
  </binder>);
}