import {useEffect, useMemo} from "react";
import {useRenderingContext} from "./rendering-context.ts";

export function useTexture2D(): WebGLTexture {
  const context = useRenderingContext();
  const texture = useMemo(() => {
    const texture = context.createTexture();
    if (texture === null) throw new Error("Cannot create texture.");
    return texture;
  }, [context]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteTexture(texture);
    }
  }, [context, texture]);

  return texture;
}
