import {z} from "zod";
import {NumberOperation, numberType, ObjectType, PropertyRef, Type} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eCharacterClassHitDice = z.object({
  current: z.number(),
  diceFace: z.number()
});
export type Dnd5eCharacterClassHitDice = z.infer<typeof Dnd5eCharacterClassHitDice>;

export const Dnd5eCharacterClassHitDiceOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-current"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-dice-face"), operations: z.array(NumberOperation)})
]);
export type Dnd5eCharacterClassHitDiceOperation = z.infer<typeof Dnd5eCharacterClassHitDiceOperation>;

export const dnd5eCharacterClassHitDiceType: Type<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation> = new ObjectType({
  current: numberType,
  diceFace: numberType
});

export function Dnd5eCharacterClassHitDiceSignals(value: MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>) {
  return ({
    current: PropertyRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation, number, NumberOperation>(
      value => value.current,
      operations => [{type: "update-current", operations}]
    )(value),
    hitDice: PropertyRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation, number, NumberOperation>(
      value => value.diceFace,
      operations => [{type: "update-dice-face", operations}]
    )(value)
  });
}