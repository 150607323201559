import {MultiType, Type} from "common/types/index.ts";
import {AssetNavigator, AssetNavigatorOperation, characterNavigatorType} from "../../panel/asset-navigator/index.ts";
import {Settings, SettingsOperation, settingsType} from "../../panel/game-settings/index.ts";
import {SceneNavigator, SceneNavigatorOperation, sceneNavigatorType} from "../../panel/scene-navigator/index.ts";
import {EditorProperties, EditorPropertiesOperation, editorPropertiesType} from "../../panel/properties/index.ts";
import {GMSheet, GMSheetOperation, gMSheetType} from "../../panel/sheet/editor/g-m-sheet-type.ts";
import {EditorTurnTracker, EditorTurnTrackerOperation, editorTurnTrackerType} from "../../panel/turn-tracker/editor/editor-turn-tracker-type.ts";
import {StackType} from "#lib/container/index.ts";
import {Chat, ChatOperation, chatType} from "../../panel/chat";

export type EditorStackValue =
  | {type: "settings", data: Settings}
  | {type: "scene-navigator", data: SceneNavigator}
  | {type: "character-navigator", data: AssetNavigator}
  | {type: "properties", data: EditorProperties}
  | {type: "chat", data: Chat}
  | {type: "sheet", data: GMSheet}
  | {type: "turn-tracker", data: EditorTurnTracker}
  ;
export type EditorStackOperation =
  | {type: "settings", operations: SettingsOperation[]}
  | {type: "scene-navigator", operations: SceneNavigatorOperation[]}
  | {type: "character-navigator", operations: AssetNavigatorOperation[]}
  | {type: "properties", operations: EditorPropertiesOperation[]}
  | {type: "chat", operations: ChatOperation[]}
  | {type: "sheet", operations: GMSheetOperation[]}
  | {type: "turn-tracker", operations: EditorTurnTrackerOperation[]}
  ;
export type EditorStackTypes = {
  settings: Type<Settings, SettingsOperation>;
  sceneNavigator: Type<SceneNavigator, SceneNavigatorOperation>;
  characterNavigator: Type<AssetNavigator, AssetNavigatorOperation>;
  properties: Type<EditorProperties, EditorPropertiesOperation>;
  chat: Type<Chat, ChatOperation>;
  sheet: Type<GMSheet, GMSheetOperation>;
  turnTracker: Type<EditorTurnTracker, EditorTurnTrackerOperation>;
};
export type EditorStackType = keyof EditorStackTypes;

export const editorStackType: StackType<EditorStackValue, EditorStackOperation> = new StackType(new MultiType<EditorStackTypes>({
  settings: settingsType,
  sceneNavigator: sceneNavigatorType,
  characterNavigator: characterNavigatorType,
  properties: editorPropertiesType,
  chat: chatType,
  sheet: gMSheetType,
  turnTracker: editorTurnTrackerType
}, (v) => {
  if (v?.type === "outliner") {
    return {type: "scene-navigator", data: {
      expanded: {},
      searchTerm: ""
    }} satisfies EditorStackValue;
  }
  return v;
}));
