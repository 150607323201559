import {
  BooleanOperation,
  booleanType,
  ConstantType,
  FileReference,
  FileReferenceOperation,
  NumberOperation,
  numberType,
  ObjectType,
  PointOperation,
  PropertyRef,
  Size,
  SizeOperation,
  sizeType,
  StringOperation,
  TransformOperation,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {MaskOperation} from "#common/types/generic/mask/mask.ts";
import {Nullable, NullableType} from "#common/types/generic/nullable/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type ImageNode = LocalNode & {
  file: FileReference;
  opacity: number;
  size: Size;
  mountable: boolean;
  attachable: boolean;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
};
export type ImageNodeOperation =
  | LocalNodeOperation
  | {type: "update-file", operations: FileReferenceOperation[]}
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  | {type: "update-repeat-x", operations: ValueOperation<number | null, NumberOperation>[]}
  | {type: "update-repeat-y", operations: ValueOperation<number | null, NumberOperation>[]}
  ;
export const imageNodeType: Type<ImageNode, ImageNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  file: new ValueType(new ConstantType<FileReference>()),
  opacity: numberType,
  size: sizeType,
  mountable: booleanType,
  attachable: booleanType,
  repeatX: new ValueType(new NullableType(numberType)),
  repeatY: new ValueType(new NullableType(numberType))
}), (value) => {
  value = localNodeUpdater(value);
  if (!value.conditions) value.conditions = [];
  if (value.selectable) {
    value.selectionMask = value.selectable ? 1 : 0;
    delete value["selectable"];
  }
  if (!value.selectionMask) value.selectionMask = 0;
  if (value["repeatX"] === undefined) value.repeatX = 1;
  if (value["repeatY"] === undefined) value.repeatY = 1;
  if (value["mountable"] === undefined || value["mountable"] === null) value.mountable = false;
  if (value["asset"] !== undefined) {
    value["file"] = value["asset"];
    delete value["asset"]
  }
  if (value["attachable"] === undefined) value["attachable"] = value["mountable"];
  return value;
});

export const ImageNode = {
  getName: (node: ImageNode) => node.name,
  updateName: (operations: StringOperation[]): ImageNodeOperation[] => [{type: "update-name", operations}],
  getFile: (node: ImageNode) => node.file,
  updateFile: (operations: FileReferenceOperation[]): ImageNodeOperation[] => [{type: "update-file", operations}],
  getOpacity: (node: ImageNode) => node.opacity,
  updateOpacity: (operations: NumberOperation[]): ImageNodeOperation[] => [{type: "update-opacity", operations}],
  getSelectionMask: (node: ImageNode) => node.selectionMask,
  updateSelectionMask: (operations: MaskOperation[]): ImageNodeOperation[] => [{type: "update-selection-mask", operations}],
  getTransform: (node: ImageNode) => node.transform,
  updateTransform: (operations: TransformOperation[]): ImageNodeOperation[] => [{type: "update-transform", operations}],
  getOrigin: (node: ImageNode) => node.origin,
  updateOrigin: (operations: PointOperation[]): ImageNodeOperation[] => [{type: "update-origin", operations}],
  getSize: (node: ImageNode) => node.size,
  updateSize: (operations: SizeOperation[]): ImageNodeOperation[] => [{type: "update-size", operations}],
  getVisibilityLayer: (node: ImageNode) => node.visibilityLayer,
  updateVisibilityLayer: (operations: VisibilityLayerOperation[]): ImageNodeOperation[] => [{type: "update-visibility-layer", operations}]
}

export function ImageNodeSignals(value: MutableRef<ImageNode, ImageNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    fileRef: PropertyRef<ImageNode, ImageNodeOperation, FileReference, FileReferenceOperation>(ImageNode.getFile, ImageNode.updateFile)(value),
    sizeRef: PropertyRef<ImageNode, ImageNodeOperation, Size, SizeOperation>(ImageNode.getSize, ImageNode.updateSize)(value),
    opacityRef: PropertyRef<ImageNode, ImageNodeOperation, number, NumberOperation>(ImageNode.getOpacity, ImageNode.updateOpacity)(value),
    mountableRef: PropertyRef<ImageNode, ImageNodeOperation, boolean, BooleanOperation>(value => value.mountable, operations => [{type: "update-mountable", operations}])(value),
    attachableRef: PropertyRef<ImageNode, ImageNodeOperation, boolean, BooleanOperation>(value => value.attachable, operations => [{type: "update-attachable", operations}])(value),
    repeatXRef: PropertyRef<ImageNode, ImageNodeOperation, Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>>(value => value.repeatX, operations => [{type: "update-repeat-x", operations}])(value),
    repeatYRef: PropertyRef<ImageNode, ImageNodeOperation, Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>>(value => value.repeatY, operations => [{type: "update-repeat-y", operations}])(value),
  };
}