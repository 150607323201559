import {Dnd5eCharacter} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-character.ts";
import {Sheet} from "#common/legends/asset/sheet/sheet.ts";
import {Ref} from "#common/ref";
import {MathExpressionFn} from "#common/math/index.ts";
import {Optional} from "#common/types/index.ts";
import {Dnd5eAttributeFn} from "#common/legends/index.ts";
import {RollVariables} from "#common/qlab/index.ts";
import {Dnd5eStatBlock} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {getPactSlotLevel} from "#common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {mapIdentity} from "#common/observable";

export function getDnd5eCharacterVariables(value: Dnd5eCharacter): RollVariables {
  const variables: RollVariables = {};
  variables["STR_SCORE"] = value.attributes.str;
  variables["DEX_SCORE"] = value.attributes.dex;
  variables["CON_SCORE"] = value.attributes.con;
  variables["INT_SCORE"] = value.attributes.int;
  variables["WIS_SCORE"] = value.attributes.wis;
  variables["CHA_SCORE"] = value.attributes.cha;

  variables["STR"] = Dnd5eAttributeFn.modifier(value.attributes.str);
  variables["DEX"] = Dnd5eAttributeFn.modifier(value.attributes.dex);
  variables["CON"] = Dnd5eAttributeFn.modifier(value.attributes.con);
  variables["INT"] = Dnd5eAttributeFn.modifier(value.attributes.int);
  variables["WIS"] = Dnd5eAttributeFn.modifier(value.attributes.wis);
  variables["CHA"] = Dnd5eAttributeFn.modifier(value.attributes.cha);

  variables["LEVEL"] = value.classes.reduce((a, b) => a + b.level, 0);
  variables["PB"] = Dnd5eCharacter.getProficiencyBonus(value);
  for (const feature of value.race.features) {
    if (!feature.enabled) continue;
    for (const modifier of feature.modifiers) {
      if (modifier.type !== "variable-override") continue;
      variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
    }
  }
  for (const feature of value.background.features) {
    if (!feature.enabled) continue;
    for (const modifier of feature.modifiers) {
      if (modifier.type !== "variable-override") continue;
      variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
    }
  }
  for (const clazz of value.classes) {
    variables[`${clazz.class}-LEVEL`.toUpperCase()] = clazz.level;
    for (const feature of clazz.features) {
      if (feature.level > clazz.level) continue;
      if (!feature.feature.enabled) continue;
      for (const modifier of feature.feature.modifiers) {
        if (modifier.type !== "variable-override") continue;
        variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
      }
    }
  }
  variables[`PACTLEVEL`.toUpperCase()] = Number.parseInt(getPactSlotLevel(value.classes));

  for (const item of value.inventory) {
    if (!item.equipped) continue;
    for (const modifier of item.modifiers) {
      if (modifier.type !== "variable-override") continue;
      variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
    }
    for (const effect of item.effects) {
      if (!effect.enabled) continue;
      for (const modifier of effect.modifiers) {
        if (modifier.type !== "variable-override") continue;
        variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
      }
    }
  }
  return variables;
}

export function getDnd5eStatBlockVariables(value: Dnd5eStatBlock): RollVariables {
  const variables: RollVariables = {};
  if (value.attributes) {
    variables["STR_SCORE"] = value.attributes.str || 10;
    variables["DEX_SCORE"] = value.attributes.dex || 10;
    variables["CON_SCORE"] = value.attributes.con || 10;
    variables["INT_SCORE"] = value.attributes.int || 10;
    variables["WIS_SCORE"] = value.attributes.wis || 10;
    variables["CHA_SCORE"] = value.attributes.cha || 10;

    variables["STR"] = Dnd5eAttributeFn.modifier(value.attributes.str || 10);
    variables["DEX"] = Dnd5eAttributeFn.modifier(value.attributes.dex || 10);
    variables["CON"] = Dnd5eAttributeFn.modifier(value.attributes.con || 10);
    variables["INT"] = Dnd5eAttributeFn.modifier(value.attributes.int || 10);
    variables["WIS"] = Dnd5eAttributeFn.modifier(value.attributes.wis || 10);
    variables["CHA"] = Dnd5eAttributeFn.modifier(value.attributes.cha || 10);
  }
  variables["PB"] = value.proficiencyBonus;
  for (const feature of value.features) {
    if (!feature.enabled) continue;
    for (const modifier of feature.modifiers) {
      if (modifier.type !== "variable-override") continue;
      variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
    }
  }
  return variables;
}

export function getSheetVariables(value: Optional<Sheet>): RollVariables {
  if (value?.type === "dnd-5e-character") {
    return getDnd5eCharacterVariables(value.data);
  } else if (value?.type === "dnd-5e-stat-block") {
    return getDnd5eStatBlockVariables(value.data);
  } else {
    return {};
  }
}

export function sheetVariablesSignal(sheet: Ref<Optional<Sheet>>): Ref<{ [p: string]: number }> {
  return sheet.map(getSheetVariables).distinct(mapIdentity);
}