import {useCallback, useState} from "react";
import {ApplyAction} from "common/types/index.ts";

export function useToggle(defaultValue: boolean): [boolean, () => void, (value: boolean) => void] {
  const [value, setValue] = useState(defaultValue);
  const toggleValue = useCallback(() => setValue(prev => !prev), [setValue]);
  return [value, toggleValue, setValue];
}

export function useStoredToggle(key: string, defaultValue: boolean) {
  const [value, setValue] = useState(() => {
    const value = localStorage.getItem(key);
    if (value === null) return defaultValue;
    return value === "true";
  });
  const updateValue = (fn: ApplyAction<boolean, boolean>) => {
    setValue(prev => {
      const newValue = typeof fn === "function" ? fn(prev) : fn;
      localStorage.setItem(key, `${newValue}`);
      return newValue;
    });
  };

  const toggleValue = useCallback(() => {
    updateValue(prev => !prev);
  }, [updateValue]);

  return [value, toggleValue, updateValue];
}
