import {NumberOperation, numberType, ObjectType, Type} from "#common/types/index.ts";

export type Dnd5eCharacterDeathSaves = {
  failures: number;
  successes: number;
};

export const Dnd5eCharacterDeathSaves = {
  getSuccesses: (value: Dnd5eCharacterDeathSaves) => value.successes,
  getFailures: (value: Dnd5eCharacterDeathSaves) => value.failures
};

export type Dnd5eCharacterDeathSavesOperation =
  | {type: "update-failures", operations: NumberOperation[]}
  | {type: "update-successes", operations: NumberOperation[]}
  ;

export const Dnd5eCharacterDeathSavesOperation = {
  updateFailures: (operations: NumberOperation[]): Dnd5eCharacterDeathSavesOperation[] => [{type: "update-failures", operations}],
  updateSuccesses: (operations: NumberOperation[]): Dnd5eCharacterDeathSavesOperation[] => [{type: "update-successes", operations}]
};

export const dnd5eCharacterDeathSavesType: Type<Dnd5eCharacterDeathSaves, Dnd5eCharacterDeathSavesOperation> = new ObjectType({
  failures: numberType,
  successes: numberType
});
