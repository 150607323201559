import {MutableRef} from "common/ref";
import {ListOperation, Optional, Tree, TreeOperation} from "common/types/generic/index.ts";
import {Node, NodeId, NodeOperation} from "common/legends/node/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {distinct} from "common/observable";
import {AssetID, TokenID} from "common/legends/asset/index.ts";

export function useAssetNode(assetID: AssetID, tokenID: TokenID, nodeID: NodeId): MutableRef<Optional<Node>, NodeOperation[]> {
  const database = useDatabase();
  return useMemo((): MutableRef<Optional<Node>, NodeOperation[]> => {
    const valueFn = (database: QLabDatabase): Optional<Node> => {
      const resource = database.resources[assetID];
      if (resource?.type !== "asset") return undefined;
      const token = resource.data.tokens.find(token => token.tokenID === tokenID);
      if (token === undefined) return undefined;
      return Tree.getItemById(token.children, nodeID);
    };

    return new MutableRef<Optional<Node>, NodeOperation[]>({
      value(): Optional<Node> {
        return valueFn(database.value);
      },
      observe: distinct<Optional<Node>>()((observer) => {
        return database.observe({
          next(v) {observer.next(valueFn(v))},
          error: observer.error,
          complete: observer.complete
        });
      }),
      apply: (fn) => database.apply(prev => {
        const resource = prev.resources[assetID];
        if (resource?.type !== "asset") return [];
        const tokenIndex = resource.data.tokens.findIndex(token => token.tokenID === tokenID);
        if (tokenIndex === -1) return [];
        const token = resource.data.tokens[tokenIndex];
        const path = Tree.getPath(token.children, node => node.data.id === nodeID);
        if (path === undefined) return [];
        const operations = fn(valueFn(prev));
        return [{
          type: "resource",
          resourceID: assetID,
          operations: [{type: "apply", operations: [{type: "asset", operations: [{
            type: "update-tokens", operations: ListOperation.apply(tokenIndex, [{
              type: "update-children", operations: TreeOperation.apply(path, operations)
            }])
          }]}]}]
        }];
      }).then(v => valueFn(v))
    })
  }, [database, assetID, tokenID, nodeID]);
}