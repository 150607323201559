import {z} from "zod";
import {constantType, Type} from "#common/types/index.ts";
import {InteractionTriggerID} from "./interaction-trigger-i-d.ts";
import {ConstantOperation, ObjectType} from "../../../../types/index.ts";

export const InteractionTriggerEnter = z.object({
  interactionTriggerID: InteractionTriggerID
});
export type InteractionTriggerEnter = z.infer<typeof InteractionTriggerEnter>;
export const InteractionTriggerEnterOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-trigger-i-d"), operations: z.array(ConstantOperation)})
]);
export type InteractionTriggerEnterOperation = z.infer<typeof InteractionTriggerEnterOperation>;
export const interactionTriggerEnterType: Type<InteractionTriggerEnter, InteractionTriggerEnterOperation> = new ObjectType({
  interactionTriggerID: constantType
});
