import {useCallback} from "react";
import {TurnTracker, TurnTrackerFn, TurnTrackerOperation} from "common/legends/game/turn-tracker/index.ts";
import {NumberFn, ValueFn, ValueOperation} from "common/types/index.ts";
import {MutableRef} from "common/ref";

export function useEndTurn(turnTracker: MutableRef<TurnTracker, ValueOperation<TurnTracker, TurnTrackerOperation>[]>) {
  return useCallback(() => {
    turnTracker.apply(prev => {
      if (prev === undefined) return [];
      const orderedTurns = TurnTrackerFn.orderedTurnIDs(prev.turns);
      const nextIndex = prev.currentTurnID ? (orderedTurns.indexOf(prev.currentTurnID) + 1) % orderedTurns.length : 0
      if (nextIndex === 0) {
        return ValueFn.apply([{
          type: "update-round",
          operations: NumberFn.increment(1)
        },{
          type: "update-current-turn-i-d",
          operations: ValueFn.set(prev.currentTurnID, orderedTurns[nextIndex])
        }]);
      } else {
        return ValueFn.apply([{
          type: "update-current-turn-i-d",
          operations: ValueFn.set(prev.currentTurnID, orderedTurns[nextIndex])
        }]);
      }
    });
  }, [turnTracker])
}