import {z} from "zod";

export const AreaToolVertexSelection = z.object({type: z.literal("vertex"), areaIndex: z.number(), vertexIndex: z.number()});
export type AreaToolVertexSelection = z.infer<typeof AreaToolVertexSelection>;

export const AreaToolEdgeSelection = z.object({type: z.literal("edge"), areaIndex: z.number(), edgeIndex: z.number()});
export type AreaToolEdgeSelection = z.infer<typeof AreaToolEdgeSelection>;

export const AreaToolControlPointSelection = z.object({type: z.literal("control-point"), areaIndex: z.number(), edgeIndex: z.number(), controlPointIndex: z.union([z.literal(0), z.literal(1)])});
export type AreaToolControlPointSelection = z.infer<typeof AreaToolControlPointSelection>;

export const AreaToolSelection = z.discriminatedUnion("type", [
  z.object({type: z.literal("area"), areaIndex: z.number()}),
  AreaToolVertexSelection,
  AreaToolEdgeSelection,
  AreaToolControlPointSelection
]);
export type AreaToolSelection = z.infer<typeof AreaToolSelection>;

export const AreaToolSelectMode = z.optional(AreaToolSelection);
export type AreaToolSelectMode = z.infer<typeof AreaToolSelectMode>;

