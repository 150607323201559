import {Matrix4f} from "#lib/math/index.ts";
import {Color, HSLA, Point, Size, Transform} from "common/types/index.ts";
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import {useMemo} from "react";
import {Nullable} from "common/types/generic/nullable/index.ts";

const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec2 u_screen;
uniform vec4 u_color;

in vec2 v_tex_coord;

out vec4 outColor;

void main() {
  vec2 d = vec2(
    min(v_tex_coord.x, u_screen.x - v_tex_coord.x),
    min(v_tex_coord.y, u_screen.y - v_tex_coord.y)
  );
  
  // corners
  if (
    (d.x < 4. && d.y < 16.) ||
    (d.x < 16. && d.y < 4.)
  ) {
    if (
      (d.x > 1. && d.x < 16. && d.y > 1. && d.y < 3.) ||
      (d.x > 1. && d.x < 3. && d.y > 1. && d.y < 16.)
    ) {
      vec4 c = u_color;
      outColor = c.rgba;
    } else {
      vec4 c = vec4(0., 0., 0., 0.8);
      outColor = c.rgba;
    }
  }
  // edges
  else if ((d.y > 1. && d.y < 3.) || (d.x > 1. && d.x < 3.)) {
    vec4 c = u_color;
    outColor = c.rgba;
  } else if ((d.y > 0. && d.y < 4.) || (d.x > 0. && d.x < 4.)) {
    vec4 c = vec4(0., 0., 0., 0.8);
    outColor = c.rgba;
  } else {
    vec4 c = vec4(0.);
    outColor = c.rgba;
  }
}
`;

export type SelectionIndicatorProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  origin: Point;
  size: Size;
  color: HSLA;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
}

export function SelectionIndicator({projection, view, model, origin, size, color, repeatX, repeatY}: SelectionIndicatorProps) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const screenLocation = useUniformLocation(program, "u_screen");
  const colorLocation = useUniformLocation(program, "u_color");

  const vbo = useArrayBuffer(useMemo(() => {
    const [x, y] = origin;
    const [w, h] = size;
    const xInf = Number.isFinite(repeatX);
    const rx = repeatX === null ? Number.POSITIVE_INFINITY : repeatX;
    const ry = repeatY === null ? Number.POSITIVE_INFINITY : repeatY;


    return new Float32Array([
      xInf ?         0 - x : Number.NEGATIVE_INFINITY,          0 - y,         0,         0,
      xInf ?      w*rx - x : Number.POSITIVE_INFINITY,          0 - y,      w*rx,         0,
      xInf ?      w*rx - x : Number.POSITIVE_INFINITY,       h*ry - y,      w*rx,      h*ry,
      xInf ?         0 - x : Number.NEGATIVE_INFINITY,       h*ry - y,         0,      h*ry
    ])
  }, [size, origin, repeatX, repeatY]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);
  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);

  const screen2f = useMemo(() => new Float32Array([Number.isFinite(repeatX) ? size[0]*(repeatX === null ? Number.POSITIVE_INFINITY : repeatX): 10, size[1]*(repeatY === null ? Number.POSITIVE_INFINITY : repeatY)]), [size, repeatX, repeatY]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color]);

  return (<program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
    <uniform2fv location={screenLocation} data={screen2f} />
    <uniform4fv location={colorLocation} data={color4f} />

    <vertexArray value={vao}>
      <elementArrayBuffer value={ebo}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
      </elementArrayBuffer>
    </vertexArray>
  </program>);
}
