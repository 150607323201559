import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {AccessMask, AccessMaskOperation} from "common/legends/visibility/index.ts";
import {InputMask} from "#lib/components/input-mask/input-mask.tsx";
import {MutableRef} from "common/ref";

export type VisibilityMaskFieldProps = {
  value: MutableRef<AccessMask, AccessMaskOperation[]>;
};

export function AccessMaskField({value}: VisibilityMaskFieldProps) {
  return (
    <InputGroup>
      <InputGroupLabel>Access Mask</InputGroupLabel>
      <InputMask value={value} />
    </InputGroup>
  )
}