import {Dnd5eAction, Dnd5eActionID, Dnd5eActionOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {ListOperation, ListSignal, Optional} from "common/types/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MutableRef} from "common/ref";

const valueFn = (actions: Dnd5eAction[], actionID: Optional<Dnd5eActionID>): Optional<Dnd5eAction> => {
  return actionID ? actions.find(action => action.actionID === actionID) : undefined;
};

export function useActionByID(items: ListSignal<Dnd5eAction, Dnd5eActionOperation>, actionID: Dnd5eActionID | undefined): MutableRef<Optional<Dnd5eAction>, Dnd5eActionOperation[]> {
  return useMemo((): MutableRef<Optional<Dnd5eAction>, Dnd5eActionOperation[]> => {
    return new MutableRef({
      value(): Optional<Dnd5eAction> {
        return valueFn(items.value, actionID);
      },
      observe: pipe(
        items.observe,
        map(v => valueFn(v, actionID)),
        distinct()
      ),
      apply: fn => items.apply(prev => {
        const index = prev.findIndex(action => action.actionID === actionID);
        if (index === -1) return [];
        return ListOperation.apply(index, fn(prev[index]));
      }).then(value => valueFn(value, actionID))
    });
  }, [items, actionID]);
}
