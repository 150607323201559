import {Dialog, Popover} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {StoreReference} from "common/types/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";

export type InvitationModalProps = {
  onClose: () => void;
  gameRef: StoreReference;
  code: string;
};

export function InvitationModal({onClose, gameRef, code}: InvitationModalProps) {
  const invitationLink = `${window.location.origin}/game/${gameRef.storeId}/join/${code}`;
  return <Modal onClose={onClose}>
    <ModalBody>
      <ModalTitle>Invitation Link</ModalTitle>
      <Dialog.Description className="px-4">
        <p>Send this link to the players you would like to invite to your game.</p>
      </Dialog.Description>
      <Popover className="relative flex">
        <Popover.Button className={"flex-1 overflow-hidden bg-slate-900/50 rounded flex-1 font-mono p-2"} onClick={() => {
          navigator.clipboard.writeText(invitationLink).then(() => {
            // Copied!
          })
        }}>
          {invitationLink}
        </Popover.Button>
        <Popover.Panel className="absolute top-1 -bottom-1 right-1 max-w-sm">
          <div className="w-full bg-slate-400/50 backdrop-blur px-2 py-1 text-white rounded shadow-md">
            Copied
          </div>
        </Popover.Panel>
      </Popover>
      <Button onClick={onClose}>Close</Button>
    </ModalBody>
  </Modal>
}