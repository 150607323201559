import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {Node, NodeId, NodeOperation} from "common/legends/node/index.ts";
import {ListOperation, Optional, Tree, TreeOperation, ValueFn, VisitResult, walkTree} from "common/types/generic/index.ts";
import {QLabResourceID} from "common/qlab/resource/index.ts";
import {Loader} from "../loader.ts";

class NodeLoader extends Loader<NodeId, MutableRef<Optional<Node>, NodeOperation[]>> {
  constructor(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
    super(
      key => key,
      nodeID => databaseRef.map(
        (database: QLabDatabase): Optional<Node> => {
          if (nodeID === undefined) return undefined;
          let foundNode: Optional<Node> = undefined;
          for (const resourceID of Object.keys(database.resources) as QLabResourceID[]) {
            if (foundNode !== undefined) break;
            const resource = database.resources[resourceID];
            if (resource?.type === "scene") {
              walkTree(resource.data.children, {
                visit(value: Node): VisitResult | void {
                  if (value.data.id === nodeID) {
                    foundNode = value;
                    return VisitResult.TERMINATE;
                  }
                }
              });
            } else if (resource?.type === "asset") {
              for (const [_, token] of Object.entries(resource.data.tokens)) {
                walkTree(token.children, {
                  visit(value: Node): VisitResult | void {
                    if (value.data.id === nodeID) {
                      foundNode = value;
                      return VisitResult.TERMINATE;
                    }
                  }
                });
              }
            }
          }
          return foundNode;
        },
        (database: QLabDatabase, operations: NodeOperation[]): QLabDatabaseOperation[] => {
          for (const [resourceID, resource] of Object.entries(database.resources)) {
            if (resource?.type === "scene") {
              const path = Tree.getPath(resource.data.children, node => node.data.id === nodeID);
              if (path === undefined) continue;
              return [{type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                type: "scene", operations: [{
                  type: "update-children", operations: TreeOperation.apply(path, operations)
                }]
              }])}];
            } else if (resource?.type === "asset") {
              for (let tokenIndex = 0; tokenIndex < resource.data.tokens.length; tokenIndex ++) {
                const token = resource.data.tokens[tokenIndex];
                const path = Tree.getPath(token.children, node => node.data.id === nodeID);
                if (path === undefined) continue;
                return [{type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                  type: "asset", operations: [{
                    type: "update-tokens", operations: ListOperation.apply(tokenIndex, [{
                      type: "update-children", operations: TreeOperation.apply(path, operations)
                    }])
                  }]
                }])}];
              }
            }
          }
          return [];
        }
      ).distinct()
    );
  }
}


const loader = new WeakMap<MutableRef<QLabDatabase, QLabDatabaseOperation[]>, Loader<NodeId, MutableRef<Optional<Node>, NodeOperation[]>>>();
export function NodeRef(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, nodeID: Optional<NodeId>): MutableRef<Optional<Node>, NodeOperation[]> {
  if (!nodeID) return NOOP_SIGNAL;
  if (!loader.has(databaseRef)) loader.set(databaseRef, new NodeLoader(databaseRef));
  return loader.get(databaseRef)!.get(nodeID);
}
