import {ImageNode, NodeId} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Color, Transform} from "common/types/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {useIsSelected} from "./use-is-selected.ts";
import {ImageView} from "./image-view.tsx";
import {NodeView} from "./node-view.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {useSheetEffects} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-effects.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useRefValue} from "#lib/signal/index.ts";

export type ImageNodeViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
  value: ImageNode;
};
export function ImageNodeView({projection, view, visibilityMask, accessNodeIDs, model, opacity, value: {id, file, transform, origin, pivot, size, repeatX, repeatY, visibilityLayer, opacity: valueOpacity, children, conditions}}: ImageNodeViewProps) {
  const valueModel = Transform.divide(transform, model);
  const finalOpacity = valueOpacity * opacity;
  const isSelected = useIsSelected(id);

  const sheetEffects = useSheetEffects(useSheetSignal(useSheetReference()), useGlobalFeatures());
  const conditionsMet = useRefValue(useMemo(() => sheetEffects.map(effects => {
    return conditions.length === 0 || conditions
      .every(condition => condition.type !== "effect" ||
        effects
          .filter(effect => effect.effectID === condition.data.effectID && effect.enabled)
          .length > 0
      )
  }).distinct(), [conditions, sheetEffects]));

  if ((!conditionsMet || !AccessMaskFn.canSee(visibilityMask, visibilityLayer)) && !accessNodeIDs.includes(id)) return <></>;
  return (<binder>
    <ImageView projection={projection} view={view} model={valueModel} url={file} size={size} origin={origin} pivot={pivot} opacity={finalOpacity} repeatX={repeatX} repeatY={repeatY} />
    {children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} model={valueModel} value={child} opacity={valueOpacity} />
    })}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={origin} size={size} color={Color.WHITE} repeatX={repeatX} repeatY={repeatY} />}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[origin[0] - pivot[0]+2, origin[1] - pivot[1]+2]} size={[4,4]} color={Color.GREEN} repeatX={repeatX} repeatY={repeatY} />}
  </binder>);
}