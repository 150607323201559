import {pipe} from "#common/pipe";
import {Node, NodeId, Scene} from "#common/legends/index.ts";
import {Tree} from "#common/types/index.ts";
import {combine, distinct, map, Observable} from "#common/observable";

export function sceneNodeObservable(
  scene: Observable<Scene | undefined>,
  nodeId: Observable<NodeId | undefined>
): Observable<Node | undefined> {
  return pipe(
    combine(scene, nodeId),
    map(([scene, nodeId]) => scene && nodeId ? Tree.getItemById(scene!.children, nodeId) : undefined),
    distinct()
  );
}
