import {MarkerTurn, MarkerTurnOperation} from "common/legends/game/turn-tracker/index.ts";
import {twMerge} from "tailwind-merge";
import {NodeIcon} from "../editor/turn/node-icon.tsx";
import {useObservable} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";

export type EditorMarkerTurnViewProps = {
  value: MutableRef<MarkerTurn, MarkerTurnOperation[]>;
  isCurrentTurn: boolean;
};
export function PlayerMarkerTurnView({value, isCurrentTurn}: EditorMarkerTurnViewProps) {
  const {label, order} = useObservable(value.observe, undefined, [value]) ?? {label: "???", order: 20};
  return <div className={twMerge(
    "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
    isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50",
  )}>
    <NodeIcon nodeID={undefined} />
    <div className="shrink overflow-hidden basis-6 h-8 flex items-center justify-center text-white/40 text-xs italic">{order}</div>
    <span className="shrink-[1000] basis-6 flex-1 inline-flex items-center overflow-hidden">{label}</span>
    <div className="inline-flex shrink-[9999] overflow-hidden gap-0.5"></div>
  </div>
}
