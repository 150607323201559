import {Game, Player, PlayerFn, PlayerOperation, UserID} from "common/legends/index.ts";
import {ApplyAction} from "#lib/qlab/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MapFn} from "common/types/index.ts";
import {useGame} from "./model/index.ts";
import {MutableRef} from "common/ref";

export function usePlayer(userID: UserID): MutableRef<Player, PlayerOperation[]> {
  const game = useGame();
  return useMemo((): MutableRef<Player, PlayerOperation[]> => {
    const valueFn = (game: Game) => {
      if (game.players[userID]) return game.players[userID];
      if (game.owner.id === userID) return {
        ...game.owner,
        gameMaster: true
      };
      return PlayerFn.NOOP;
    };

    return new MutableRef<Player, PlayerOperation[]>({
      value() {
        return valueFn(game.value)
      },
      observe: pipe(game.observe, map(valueFn), distinct()),
      apply: (fn: ApplyAction<Player, PlayerOperation[]>) => game.apply(prev => prev.players[userID]
        ? [{type: "update-players", operations: MapFn.apply(userID, fn(prev.players[userID]!))}]
        : []).then(prev => prev.players[userID])
    })
  }, [game, userID]);
}
