import {ApplyAction, FileReference, FileReferenceOperation, StringOperation, stringType, ValueFn} from "common/types/index.ts";
import {InputGroup, InputGroupLabel} from "../input/index.ts";
import {ButtonBar, IconButton} from "../button/index.ts";
import {FaImage} from "react-icons/fa6";
import {InputString} from "../input/input-string.tsx";
import {FaFileImport, FaSpinner, FaTimes} from "react-icons/fa";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useCallback, useMemo, useRef, useState} from "react";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {QLabFileId} from "common/qlab/index.ts";
import uploadManager from "#lib/qlab/asset/asset-manager.ts";
import {ProgressBar} from "../progress-bar.tsx";
import {twMerge} from "tailwind-merge";
import {MutableRef} from "common/ref";

export type InputFileProps = {
  value: MutableRef<FileReference, FileReferenceOperation[]>;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
};

export function InputFile({value, className, readOnly, placeholder}: InputFileProps) {
  const valueAsString = useMemo(() => new MutableRef({
    value() {
      return value.value || "";
    },
    observe: pipe(value.observe, map(value => value || ""), distinct()),
    apply: (fn: ApplyAction<string, StringOperation[]>) => value.apply((prev: FileReference): FileReferenceOperation[] => {
      const operations: StringOperation[] = fn(prev || "");
      const finalValue = operations.reduce((value: string, operation: StringOperation) => stringType.apply(value, operation), prev || "");
      return ValueFn.set(prev, (finalValue === "" ? undefined : finalValue) as FileReference);
    }).then(prev => prev || "")
  }), [value]);

  const isEmptyLoader = useObservableLoader(useMemo(() => pipe(value.observe, map(v => v === undefined), distinct()), [value]));
  const clearImage = useCallback(() => {
    value.apply(prev => [{type: "set", prevValue: prev, nextValue: undefined}])
  }, [value]);

  const [progress, setProgress] = useState<number | undefined>(undefined);
  const importRef = useRef<HTMLInputElement>(null);
  const importImage = async (file: File) => {
    setProgress(0);
    const fileID = QLabFileId.generate();
    const {url, upload} = await uploadManager.asset(fileID)
    upload(file)({
      next: (progress) => {
        setProgress(progress);
      },
      error: (error) => {
        setProgress(undefined);
        console.error(error)
      },
      complete() {
        setProgress(undefined);
        value.apply(prev => ValueFn.set(prev, url))
      }
    });
  }

  if (isEmptyLoader.isLoading) {
    return (<InputGroup>
      <FaSpinner className="animate-spin" />
    </InputGroup>);
  }

  return <ButtonBar className={twMerge("shrink-0 px-0 pointer-events-auto backdrop-blur-sm", className)}>
    {isEmptyLoader.data && <>
      <input className="hidden" type="file" ref={importRef} accept="image/*" onChange={(ev) => {
        const image = ev.target.files?.item(0);
        if (image) importImage(image);
      }} />

      {progress !== undefined
        ? <ProgressBar value={progress} />
        : <InputGroup className="w-full px-0 gap-0">
            <InputGroupLabel title={placeholder || "Image"} className="w-8 flex items-center justify-center"><FaImage /></InputGroupLabel>
            <InputString readOnly={readOnly} placeholder={placeholder || "Image"} value={valueAsString}/>
            {!readOnly && <IconButton className="ml-2" title="Import Image" onClick={(ev) => {
              importRef.current?.click();
              ev.preventDefault();
              return true;
            }}><FaFileImport /></IconButton>}
      </InputGroup>}
    </>}
    {!isEmptyLoader.data && <InputGroup className="w-full px-0 gap-0">
      <InputGroupLabel title={placeholder || "Image"} className="w-8 flex items-center justify-center"><FaImage /></InputGroupLabel>
      <InputString readOnly={readOnly} value={valueAsString} />
      {!readOnly && <IconButton className="ml-2"  variant="destructive" onClick={ev => {
        clearImage();
        ev.preventDefault();
        return true;
      }}><FaTimes /></IconButton>}
    </InputGroup>}
  </ButtonBar>
}