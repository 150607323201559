import {z} from "zod";
import {BooleanOperation, booleanType, ColorOperation, colorType, HSLA, ObjectType, PropertyRef} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export const MeasurementCircle = z.object({
  sticky: z.boolean(),
  fillColor: HSLA
});
export type MeasurementCircle = z.infer<typeof MeasurementCircle>;

export const MeasurementCircleOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-sticky"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-fill-color"), operations: z.array(ColorOperation)})
]);
export type MeasurementCircleOperation = z.infer<typeof MeasurementCircleOperation>;

export const measurementCircleType: Type<MeasurementCircle, MeasurementCircleOperation> = new ObjectType({
  sticky: booleanType,
  fillColor: colorType
});

export function MeasurementCircleRef(ref: MutableRef<MeasurementCircle, MeasurementCircleOperation[]>) {
  return {
    fillColorRef: PropertyRef<MeasurementCircle, MeasurementCircleOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(ref),
    stickyRef: PropertyRef<MeasurementCircle, MeasurementCircleOperation, boolean, BooleanOperation>(value => value.sticky, operations => [{type: "update-sticky", operations}])(ref)
  };
}
