import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValuePropertyRef, ValueType} from "#common/types/index.ts";
import {MathExpression} from "#common/math/index.ts";
import {MutableRef} from "#common/ref";
import {BooleanOperation, booleanType, PropertyRef} from "../../../../../types/index.ts";

export const Dnd5eArmorClassFormulaModifier = z.object({
  modifierID: Dnd5eModifierID,
  expression: MathExpression,
  shieldBonus: z.boolean()
});
export type Dnd5eArmorClassFormulaModifier = z.infer<typeof Dnd5eArmorClassFormulaModifier>;

export const Dnd5eArmorClassFormulaModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))}),
  z.object({type: z.literal("update-shield-bonus"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eArmorClassFormulaModifierOperation = z.infer<typeof Dnd5eArmorClassFormulaModifierOperation>;

export const dnd5eArmorClassFormulaModifierType: Type<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation> = new ObjectType({
  modifierID: constantType,
  expression: new ValueType(constantType),
  shieldBonus: booleanType
}, (v) => {
  if (v.shieldBonus === undefined) v.shieldBonus = true;
  if (v.miscBonus !== undefined) delete v["miscBonus"];
  return v;
});

export function Dnd5eArmorClassFormulaModifierSignals(value: MutableRef<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation[]>) {
  return {
    expressionRef: ValuePropertyRef<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation, MathExpression, ConstantOperation>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value),
    shieldBonusRef: PropertyRef<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation, boolean, BooleanOperation>(
      value => value.shieldBonus,
      operations => [{type: "update-shield-bonus", operations}]
    )(value)
  };
}

