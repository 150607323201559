import {useCallback} from "react";
import {ApplyAction, ExtractOperation, ExtractValue, ValueFn} from "common/types/index.ts";
import {QLabStoreID, StoreOperation, StoreType, StoreTypes} from "common/qlab/index.ts";
import {useInstance} from "../../q-lab-instance-context.ts";

export function useApplyToStore<
  Type extends StoreType,
  Value = ExtractValue<StoreTypes[Type]>,
  Operation = ExtractOperation<StoreTypes[Type]>
>(
  type: Type,
  storeID: QLabStoreID
) {
  const instance = useInstance();
  return useCallback((fn: ApplyAction<Value, Operation[]>) => instance.applyToStore(storeID, (prev) => {
    if (prev?.type !== type) throw new Error("Unexpected type.");
    const prevValue = prev.data as unknown as Value;
    const operations: Operation[] = typeof fn === "function" ? fn(prevValue) : fn;
    return ValueFn.apply([{type, operations}] as unknown as StoreOperation[]);
  }), [instance, type, storeID]);
}
