import {ConstantOperation, Optional, ValueOperation} from "common/types/index.ts";
import {Dnd5eCharacterSignals} from "common/legends/index.ts";
import React, {useCallback, useMemo, useState} from "react";
import {SheetNameField} from "./sheet-name-field.tsx";
import {ArmorProficiencies} from "./armor-proficiencies.tsx";
import {SkillProficiencies} from "./skill-proficiencies.tsx";
import {WeaponProficiencies} from "./weapon-proficiencies.tsx";
import {LanguageProficiencies} from "./language-proficiencies.tsx";
import {Alignment} from "./alignment.tsx";
import {Race} from "./race.tsx";
import {Background} from "./background.tsx";
import {Classes} from "./classes.tsx";
import {Experience} from "./experience.tsx";
import {ArmorClass} from "./armor-class.tsx";
import {HitPoints} from "./hit-points.tsx";
import {ExhaustionLevel} from "./exhaustion-level.tsx";
import {DeathSaves} from "./death-saves.tsx";
import {MovementSpeeds} from "./movement-speeds.tsx";
import {Inventory} from "./inventory.tsx";
import {Tab} from "@headlessui/react";
import {FaBookOpen, FaCog, FaGem, FaShieldAlt, FaUser} from "react-icons/fa";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {SheetReference} from "../../../../common/sheet/sheet-reference.ts";
import {Dnd5eCharacterActionsView} from "./dnd-5e-character-actions.tsx";
import {Dnd5eSheetResourcesView} from "../dnd-5e/dnd-5e-resource/dnd-5e-sheet-resources-view.tsx";
import {Dnd5eSheetEffectsView} from "./dnd-5e-character-effects.tsx";
import {ProficiencyBonusField} from "./proficiency-bonus-field.tsx";
import {useSheetSignal, useTypedSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Dnd5eDamageResistancesView} from "../dnd-5e-damage-resistances-view.tsx";
import {Dnd5eDamageImmunitiesView} from "../dnd-5e-damage-immunities-view.tsx";
import {Dnd5eDamageVulnerabilitiesView} from "../dnd-5e-damage-vulnerabilities-view.tsx";
import {useSuspendPresent} from "../../../../common/use-optional-signal.ts";
import {InputDnd5eCharacterSpells} from "./input-dnd-5e-character-spells.tsx";
import {InputDnd5eFeatures} from "../dnd-5e/dnd-5e-feature/input-dnd-5e-features.tsx";
import {MutableRef, Ref} from "common/ref";

export function Dnd5eCharacterSheetView({reference, pinned}: {
  reference: Ref<Optional<SheetReference>>;
  pinned?: MutableRef<Optional<SheetReference>, ValueOperation<Optional<SheetReference>, ConstantOperation>[]>;
}) {
  const sheetRef = useSheetSignal(reference);
  const sheet = useSuspendPresent(useTypedSheetSignal("dnd-5e-character", reference));
  const {alignment, experience, weaponProficiencies, languageProficiencies, exhaustionLevel, name, spells, race, deathSaves, armorProficiencies, spellSlots, inventory, background, features, attributes, movementSpeeds, classes, pactSlots} = useMemo(() => Dnd5eCharacterSignals(sheet), [sheet]);

  const [tabIndex, setTabIndex] = useState(() => {
    const value = localStorage.getItem("editor-character-sheet-tab-index");
    if (value === null) return 0;
    return Number.parseInt(value);
  });
  const updateSelectedTab = useCallback((value: number) => {
    setTabIndex(_ => {
      localStorage.setItem("editor-character-sheet-tab-index", `${value}`);
      return value;
    });
  }, [setTabIndex]);

  return (<Tab.Group selectedIndex={tabIndex} onChange={updateSelectedTab} as="div" className="tab-content flex flex-col backdrop-blur-sm pointer-events-auto py-2">
    <BaseComponent>
      <SheetNameField value={name} reference={reference} pinned={pinned} />
      <Tab.List className="flex flex-row">
        <Tab className={({selected}) => ("outline-none focus:bg-zinc-900/60 h-10 flex items-center justify-center px-3 gap-2" + (selected ? " bg-zinc-900/50" : ""))}><FaShieldAlt/> Overview </Tab>
        <Tab className={({selected}) => ("outline-none focus:bg-zinc-900/60 h-10 flex items-center justify-center px-3 gap-2" + (selected ? " bg-zinc-900/50" : ""))}><FaGem/> Inventory</Tab>
        <Tab className={({selected}) => ("outline-none focus:bg-zinc-900/60 h-10 flex items-center justify-center px-3 gap-2" + (selected ? " bg-zinc-900/50" : ""))}><FaBookOpen/> Spells</Tab>
        <Tab className={({selected}) => ("outline-none focus:bg-zinc-900/60 h-10 flex items-center justify-center px-3 gap-2" + (selected ? " bg-zinc-900/50" : ""))}><FaCog/> Features</Tab>
        <Tab className={({selected}) => ("outline-none focus:bg-zinc-900/60 h-10 flex items-center justify-center px-3 gap-2" + (selected ? " bg-zinc-900/50" : ""))}><FaUser /> Bio</Tab>
      </Tab.List>
    </BaseComponent>
    <div className="flex flex-1 flex-col gap-2 py-2">
      <Tab.Panels className="flex flex-col flex-1">
        <Tab.Panel className="flex flex-col gap-2 outline-none">
          <div className="flex flex-row flex-wrap gap-y-1">
            <div className="flex-1 flex flex-col gap-1">
              <SkillProficiencies attributes={attributes}/>
              <div className="mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
                <ProficiencyBonusField/>
              </div>
              <ArmorProficiencies value={armorProficiencies} />
              <WeaponProficiencies value={weaponProficiencies} />
              <LanguageProficiencies value={languageProficiencies} />
              <MovementSpeeds value={movementSpeeds}/>
            </div>
            <div className="flex-1 flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <ArmorClass />
                <HitPoints sheetRef={sheetRef} />
                <ExhaustionLevel value={exhaustionLevel} />
                <BaseComponent><DeathSaves value={deathSaves} /></BaseComponent>
              </div>

              <div className="flex flex-col gap-1">
                <Dnd5eDamageImmunitiesView/>
                <Dnd5eDamageResistancesView/>
                <Dnd5eDamageVulnerabilitiesView/>
              </div>

              <BaseComponent><Dnd5eSheetEffectsView /></BaseComponent>
              <BaseComponent><Dnd5eCharacterActionsView /></BaseComponent>
              <BaseComponent><Dnd5eSheetResourcesView value={sheet} /></BaseComponent>
            </div>
          </div>
        </Tab.Panel>
        <Tab.Panel className="flex flex-col gap-2 outline-none">
          <BaseComponent><Inventory value={inventory} /></BaseComponent>
        </Tab.Panel>
        <Tab.Panel className="flex flex-col gap-2 outline-none">
          <InputDnd5eCharacterSpells spellSlots={spellSlots} spells={spells} pactSlots={pactSlots} classes={classes} />
        </Tab.Panel>
        <Tab.Panel className="flex flex-col gap-4 outline-none">
          <BaseComponent><Race value={race} /></BaseComponent>
          <BaseComponent><Background value={background} /></BaseComponent>
          <Classes value={classes} />
          <BaseComponent><InputDnd5eFeatures label="Custom Features" value={features}/></BaseComponent>
        </Tab.Panel>
        <Tab.Panel className="flex flex-col gap-2 outline-none">
          <Alignment value={alignment} />
          <Experience value={experience} />
        </Tab.Panel>
      </Tab.Panels>
    </div>
  </Tab.Group>);
}
