import {Transform} from "common/types/index.ts";
import {Matrix4x4, Matrix4x4Fn} from "common/math/matrix/matrix4x4.ts";

export type Matrix4f = Matrix4x4;
export const Matrix4f = {
  MULTIPLICATIVE_IDENTITY: Matrix4x4Fn.MULTIPLICATIVE_IDENTITY,
  orthogonal: Matrix4x4Fn.orthogonal,
  adjoint: Matrix4x4Fn.adjoint,
  multiply: Matrix4x4Fn.multiply,
  multiplyMatrix: Matrix4x4Fn.multiplyMatrix,
  multiplyVector: Matrix4x4Fn.multiplyVector,
  determinant: Matrix4x4Fn.determinant,
  invert: Matrix4x4Fn.invert,
  transform: Transform.toMatrix4x4
};
