import {RollRequest} from "common/qlab/index.ts";
import {Dice, DiceResult} from "common/dice/index.ts";
import {useToggle} from "#lib/components/index.ts";
import {FaDiceD20} from "react-icons/fa6";
import {useSelectedSheet} from "../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {twMerge} from "tailwind-merge";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useGlobalFeatures} from "../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useIsShiftDown} from "#lib/components/use-is-shift-down.ts";
import {useIsCtrlDown} from "#lib/components/use-is-ctrl-down.ts";
import {useIsAltDown} from "#lib/is-alt-down.ts";
import {FaCaretRight} from "react-icons/fa";
import {DAMAGE_TYPE_IMAGES, DAMAGE_TYPE_UNKNOWN} from "../../common/systems/dnd5e/damage-types";

export type RollRequestType =
  | "damage"
  | "ability-check"
  | "saving-throw"
  | "saving-throw-dc"
  | "attack-roll";

export type RollRequestViewProps = {
  rollType: RollRequestType;
  damageType?: Dnd5eDamageType;
  rollRequest?: RollRequest;
  rollResult?: DiceResult;
  variables?: {[variable: string]: number}
};

function getDamage(amount: number, isImmune: boolean, isVulnerable: boolean, isResistant: boolean, isDouble: boolean, isHalf: boolean) {
  if (isImmune) return 0;
  let newAmount = amount;
  if (isVulnerable) newAmount = newAmount * 2;
  if (isResistant) newAmount = Math.floor(newAmount / 2);
  if (isDouble) newAmount = newAmount * 2;
  if (isHalf) newAmount = Math.floor(newAmount / 2);
  return newAmount;
}

export function RollRequestView({rollType, damageType, rollRequest, rollResult}: RollRequestViewProps) {
  const selectedCharacter = useSelectedSheet();
  const globalEffects = useGlobalFeatures();
  const isDamageImmune = useComputedValue(selectedCharacter, (character) => {
    if (damageType === undefined) return false;
    const modifiers = getActiveModifiers(character, globalEffects.value);
    const damageTypes = [];
    for (const modifier of modifiers) {
      if (modifier.type !== "damage-immunity") continue;
      damageTypes.push(...modifier.data.damageTypes);
    }
    return damageTypes.includes(damageType);
  }, [damageType, useRefValue(globalEffects)]);
  const isDamageVulnerable = useComputedValue(selectedCharacter, (character) => {
    if (damageType === undefined) return false;
    const modifiers = getActiveModifiers(character, globalEffects.value);
    const damageTypes = [];
    for (const modifier of modifiers) {
      if (modifier.type !== "damage-vulnerability") continue;
      damageTypes.push(...modifier.data.damageTypes);
    }
    return damageTypes.includes(damageType);
  }, [damageType, useRefValue(globalEffects)]);
  const isDamageResistant = useComputedValue(selectedCharacter, (character) => {
    if (damageType === undefined) return false;
    const modifiers = getActiveModifiers(character, globalEffects.value);
    const damageTypes = [];
    for (const modifier of modifiers) {
      if (modifier.type !== "damage-resistance") continue;
      damageTypes.push(...modifier.data.damageTypes);
    }
    return damageTypes.includes(damageType);
  }, [damageType, useRefValue(globalEffects)]);

  const shiftDown = useIsShiftDown();
  const ctrlDown = useIsCtrlDown();
  const altDown = useIsAltDown();

  const isDouble = shiftDown && !ctrlDown && !altDown && rollType === "damage";
  const isHalf = ctrlDown && !shiftDown && !altDown && rollType === "damage";

  const [expanded, toggleExpanded] = useToggle(false);
  if (rollResult) {
    const expression = Dice.toDiceExpression(rollResult, rollRequest?.variables || {});
    const diceRolls = Dice.getDiceRolls(rollResult);
    const isCrit = Dice.getSuccesses(rollResult);
    const isMiss = Dice.getFailures(rollResult);
    return <div className={twMerge(
      "outline-none align-baseline bg-zinc-900/90 rounded inline-flex flex-row items-center px-4 gap-2 h-12 pointer-events-auto text-white text-lg",
      isCrit && "bg-green-100/90 text-green-700",
      isMiss && "bg-red-200/90 text-red-600",
      isCrit && isMiss && "bg-yellow-200/90 text-yellow-600"
    )}>
      <FaDiceD20 size={20} onClick={toggleExpanded}/>
      <span className={"select-all flex flex-row items-center gap-0.5"}>{rollResult.value === getDamage(rollResult.value, isDamageImmune, isDamageVulnerable, isDamageResistant, isDouble, isHalf)
        ? <span className="font-bold text-xl">{rollResult.value}</span>
        : <><s className="text-white/50">{rollResult.value}</s> <span className="text-white/50"><FaCaretRight /></span> <span className="font-bold">{getDamage(rollResult.value, isDamageImmune, isDamageVulnerable, isDamageResistant, isDouble, isHalf)}</span></>
      }</span>
      {expanded && <span className="flex flex-col text-sm items-start">
        <span className="select-none whitespace-nowrap">{expression}</span>
        <span
            className="select-none flex flex-1 flex-row gap-2 items-center whitespace-nowrap">{diceRolls.length > 0 ? diceRolls.join(" ") : ""}</span>
      </span>}
      {rollType === "damage" && <>
        <img className="w-6 h-6" src={damageType ? DAMAGE_TYPE_IMAGES[damageType] : DAMAGE_TYPE_UNKNOWN}
             title={damageType ? damageType.toUpperCase() : "Unknown"}
             alt={damageType ? damageType.toUpperCase() : "Unknown"}/>
      </>}
    </div>
  } else if (rollRequest) {
    return (
      <button
        className="outline-none align-baseline bg-zinc-900/50 rounded inline-flex flex-row items-center px-4 py-0.5 gap-1 h-12 text-xl">
        {expanded && <>
          <FaDiceD20 className="text-zinc-400" />
          <span>??</span>
        </>}
        {!expanded && <>
          <FaDiceD20 className="text-white animate-bounce" />
          <span className="text-xs">{rollRequest.expression}</span>
        </>}
      </button>
    );
  } else {
    return (
      <button className="outline-none inline-flex align-baseline bg-zinc-900/50 px-4 py-0.5 rounded h-12  text-xl">
        <FaDiceD20 className="text-white animate-pulse" />
        <span>??</span>
      </button>
    );
  }
}