import {combine, distinct, map, Observable} from "common/observable";
import {Optional} from "common/types/index.ts";
import {Dnd5eAttribute, Dnd5eFeature, DND_5E_SKILL_ATTRIBUTE, isDnd5eAttribute, isDnd5eSkill, Sheet} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Dice, DiceExpression} from "common/dice/index.ts";
import {getDnd5eAbilityCheckProficiency} from "./get-dnd5e-ability-check-proficiency.ts";
import {Ref} from "common/ref";

export function getDnd5eSheetAbilityCheckModifiers(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], type: Dnd5eAbilityCheckType) {
  const activeModifiers = getDnd5eSheetActiveModifiers(sheet, globalFeatures);
  const hasJackOfAllTrades = activeModifiers.some(m => m.type === "trait" && m.data.trait === "jack-of-all-trades");
  const proficiency = getDnd5eAbilityCheckProficiency(sheet, globalFeatures, type);
  let isProficient = proficiency === "proficient";
  let isExpertise = proficiency === "expertise";

  let attribute: Dnd5eAttribute = "str";
  if (type === "initiative") attribute = "dex";
  if (isDnd5eAttribute(type)) attribute = type;
  else if (isDnd5eSkill(type)) attribute = DND_5E_SKILL_ATTRIBUTE[type];

  // Attribute
  let expressions = [];
  expressions.push(`{${attribute.toUpperCase()}}`);

  // Proficiency Bonus
  if (isProficient) expressions.push(`{PB}`);
  else if (isExpertise) expressions.push(`(2*{PB})`);
  else if (hasJackOfAllTrades) expressions.push(`({PB}/2)[JoAT]`);

  // Misc.
  expressions.push(...(
    activeModifiers
      .filter(m => m.type === "ability-check")
      .map(m => m.data as Dnd5eAbilityCheckModifier)
      .filter(m =>
        m.abilityChecks.includes(type)
        || (isDnd5eSkill(type) && m.abilityChecks.includes(DND_5E_SKILL_ATTRIBUTE[type]))
        || (type === "initiative" && m.abilityChecks.includes("dex"))
      )
      .map(m => m.expression)
  ));
  const expression = expressions.join("+");
  return Dice.isDiceExpression(expression) ? expression : undefined;
}

export function dnd5eSheetAbilityCheckModifiers(sheet: Ref<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, type: Dnd5eAbilityCheckType): Observable<Optional<DiceExpression>> {
  return pipe(
    combine(sheet.observe, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetAbilityCheckModifiers(sheet, globalFeatures, type)),
    distinct()
  );
}
