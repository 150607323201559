import {z} from "zod";
import {Dnd5eResourceID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {
  ConstantOperation,
  constantType,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {Dnd5eResourceType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-type.ts";
import {MathExpression, MathExpressionFn} from "#common/math/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eResource = z.object({
  resourceID: Dnd5eResourceID,
  resourceType: Dnd5eResourceType,
  name: z.string(),
  current: z.number(),
  max: MathExpression
});
export type Dnd5eResource = z.infer<typeof Dnd5eResource>;

export const Dnd5eResourceOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-resource-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-resource-type"), operations: z.array(ValueOperation(Dnd5eResourceType, ConstantOperation))}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-current"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-max"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))}),
]);
export type Dnd5eResourceOperation = z.infer<typeof Dnd5eResourceOperation>;
export const dnd5eResourceType: Type<Dnd5eResource, Dnd5eResourceOperation> = new ObjectType({
  resourceID: constantType,
  resourceType: new ValueType(constantType),
  name: stringType,
  current: numberType,
  max: new ValueType(constantType)
}, (value: any) => {
  if (typeof value.max === "number") {
    value.max = MathExpressionFn.assertMathExpression(`${value.max}`);
  }

  return value;
});

export function Dnd5eResourceSignals(value: MutableRef<Dnd5eResource, Dnd5eResourceOperation[]>) {
  return {
    resourceType: ValuePropertyRef<Dnd5eResource, Dnd5eResourceOperation, Dnd5eResourceType, ConstantOperation>(
      value => value.resourceType,
      operations => [{type: "update-resource-type", operations}]
    )(value),
    name: PropertyRef<Dnd5eResource, Dnd5eResourceOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    current: PropertyRef<Dnd5eResource, Dnd5eResourceOperation, number, NumberOperation>(
      value => value.current,
      operations => [{type: "update-current", operations}]
    )(value),
    max: ValuePropertyRef<Dnd5eResource, Dnd5eResourceOperation, MathExpression, ConstantOperation>(
      value => value.max,
      operations => [{type: "update-max", operations}]
    )(value)
  };
}

export function getResourceID(value: Dnd5eResource): Dnd5eResourceID {
  return value.resourceID;
}
