import {useRenderingContext} from "./rendering-context.ts";
import {useEffect, useMemo} from "react";

export function useShader(type: GLenum, source: string): WebGLShader {
  const context = useRenderingContext();
  const shader = useMemo(() => {
    const shader = context.createShader(type);
    if (shader === null) {
      throw new Error("Cannot create shader.");
    }

    context.shaderSource(shader, source);
    context.compileShader(shader);
    const shaderError = context.getShaderInfoLog(shader);
    if (shaderError) {
      throw new Error(shaderError);
    }
    return shader;
  }, [context, type, source]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteShader(shader);
    }
  }, [context, shader])

  return shader;
}