import {useCallback} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {Optional, Point, Transform, Tree} from "common/types/index.ts";
import {Ref} from "common/ref";
import {Vector2} from "common/math/vector/vector2.ts";

export function getNodePositionFromWorldPosition(nodes: Node[], nodeID: Optional<NodeId>, worldPos: Vector2) {
  const path = Tree.getPath(nodes, node => node.data.id === nodeID);
  if (path === undefined || path.length === 0) return worldPos;

  let model: Transform = {position: worldPos, scale: 1, rotation: 0};
  let node = nodes[path[0]];
  let ancestors = [node];
  for (let i = 1; i < path.length; i++) {
    node = node.data.children[path[i]];
    ancestors.push(node);
  }
  for (let i = 0; i < ancestors.length; i ++) {
    if (ancestors[i].type !== "parallax") {
      model = Transform.multiply(model, ancestors[i].data.transform);
    }
  }
  return model.position;
}

export function useWorldPosToNodePos(nodesRef: Ref<Node[]>) {
  return useCallback((nodeID: NodeId | undefined, worldPos: Point) => {
    return getNodePositionFromWorldPosition(nodesRef.value, nodeID, worldPos);
  }, [nodesRef])
}

