import {forwardRef, HTMLAttributes, MouseEvent, Ref} from "react";
import {IconType} from "react-icons/lib";
import "./stack-item-tab.css";

export type StackItemTabProps = HTMLAttributes<HTMLDivElement> & {
  Icon: IconType;
  active: boolean;
  onClose?: (ev: MouseEvent<HTMLDivElement>) => void;
  label: string;
};

export const StackItemTab = forwardRef(function StackItemTab({Icon, active, label, onClose, onClick, ...props}: StackItemTabProps, ref: Ref<HTMLDivElement>) {
  return <div role="stack-item" ref={ref} className={`tab ${active ? "active" : ""}`} onClick={onClick} {...props}>
    <span className="w-[20px] h-[20px]" title={label}><Icon size={20} className="mw-[20px] h-[20px] pointer-events-none" /></span>
    <label>{label}</label>
  </div>
});

