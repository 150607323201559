import {Layout} from "../layout/index.ts";
import {StackType} from "../stack/index.ts";
import {DialogItem, DialogItemOperation} from "./dialog-item.ts";

export const DialogType = {
  isEmpty: (value: DialogItem<any>): boolean => {
    return value.value.type === "layout" && Layout.isEmpty(value.value.layout);
  },
  applyToValue: <Value, Operation>(
    stackType: StackType<Value, Operation>,
    value: DialogItem<Value>,
    operation: DialogItemOperation<Value, Operation>
  ): DialogItem<Value> => {
    switch (operation.type) {
      case "move": return ({
        ...value,
        position: operation.position,
        size: operation.size
      });
      case "externalize": return ({
        ...value,
        external: true
      });
      case "apply-to-layout": {
        if (value.value.type !== "layout") return value;
        return ({
          ...value,
          value: {
            ...value.value,
            layout: operation.operations.reduce((value, operation) => stackType.apply(value, operation), value.value.layout)
          }
        });
      }
      case "set-minimized": return ({
        ...value,
        minimized: operation.value
      });
    }
  }
}