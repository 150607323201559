export const DND_5E_ALIGNMENTS = [
  "lawful good",
  "neutral good",
  "chaotic good",
  "lawful neutral",
  "neutral",
  "chaotic neutral",
  "lawful evil",
  "neutral evil",
  "chaotic evil",
  "unaligned"
];
export type Dnd5eAlignment = typeof DND_5E_ALIGNMENTS[number];
