import {Vector2} from "common/math/vector/vector2.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {getLines} from "../../../../../viewport/common/node/layer-view/walls.ts";

export function isPointInArea(point: Vector2, area: Spline): boolean {
  const areaLines = getLines(area);
  let intersections = 0;
  for (let edgeIndex = 0; edgeIndex < areaLines.length; edgeIndex ++) {
    const line = areaLines[edgeIndex];
    const a = Vector2.lineIntersect(point, Vector2.add(point, [32, 0]), line.start, line.end);
    const b = Vector2.lineIntersect(line.start, line.end, point, Vector2.add(point, [32, 0]));
    if (a <= 0 && b >= 0 && b < 1) {
      intersections++;
    }
  }
  return (intersections % 2 === 1);
}
