import {applyAll, Type} from "common/types/index.ts";
import {useMemo} from "react";
import {createValueRef, MutableRef} from "common/ref";

export function useTempRef<Value, Operation>(type: Type<Value, Operation>, initialValue: Value | (() => Value)): MutableRef<Value, Operation[]> {
  return useMemo(() => {
    // @ts-ignore
    const value = createValueRef(typeof initialValue === "function" ? initialValue() : initialValue);
    return new MutableRef({
      value(): Value {
        return value.value
      },
      observe: value.observe,
      apply: (fn) => value.apply(prev => {
        const operations = fn(prev);
        return applyAll(type, prev, operations);
      })
    });
  }, [type]);
}
