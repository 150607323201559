import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eArmorClassFormulaModifier,
  Dnd5eArmorClassFormulaModifierOperation,
  Dnd5eArmorClassFormulaModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-armor-class-formula-modifier.ts";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";

export type Dnd5eArmorClassFormulaModifierViewProps = {
  value: MutableRef<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eArmorClassFormulaModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eArmorClassFormulaModifierViewProps) {
  const {expressionRef, shieldBonusRef} = useMemo(() => Dnd5eArmorClassFormulaModifierSignals(value), [value]);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row gap-0.5">
    <InputGroup className="px-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Armor Class Formula</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputMathExpression value={expressionRef} />
    </InputGroup>

    <InputGroup className="pl-0">
      <InputCheckbox value={shieldBonusRef} />
      <InputGroupLabel>Use with Shields</InputGroupLabel>
    </InputGroup>

    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
