import {Dnd5eInventoryItem, Dnd5eInventoryItemFn, Dnd5eInventoryItemOperation, Dnd5eInventoryItemSignals, dnd5eInventoryItemType} from "common/legends/index.ts";
import {BooleanFn, ListOperation, Optional, RichTextFn} from "common/types/index.ts";
import {Button, ButtonBar, Checkbox, IconButton, InputGroup, InputGroupLabel, InputNumber, Select, useToggle} from "#lib/components/index.ts";
import {FaPaperPlane, FaPlus} from "react-icons/fa";
import React, {ForwardedRef, forwardRef, PropsWithChildren, useCallback, useMemo, useRef, useState} from "react";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {Dnd5eItemEditor} from "../dnd-5e/dnd-5e-item/dnd-5e-item-editor.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {Dnd5eItemID, generateDnd5eItemID} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-item-i-d.ts";
import {pipe} from "common/pipe";
import {distinct, filter, map, toPromise} from "common/observable";
import {DefaultPlaceholder, GenericInputList, getPlaceholderIndex, ListItemProps, PlaceholderProps} from "#lib/components/list/generic-input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useDrag, useDragLayer, useDrop} from "react-dnd";
import {twMerge} from "tailwind-merge";
import ReactDOM from "react-dom";
import {Dnd5eItemEffect, Dnd5eItemEffectFn, Dnd5eItemEffectOperation} from "common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect.ts";
import {Dnd5eItemEffectID} from "common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect-id.ts";
import {copyDnd5eItem} from "common/legends/asset/sheet/dnd-5e/character/copy-dnd-5e-item.ts";
import {MutableRef} from "common/ref";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useSendFeatureMessage} from "./dnd-5e-action/use-send-feature-message.ts";

function getItem(value: MutableRef<Dnd5eInventoryItem[], ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[]>, itemID: Dnd5eItemID): MutableRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation[]> {
  return new MutableRef({
    value(): Dnd5eInventoryItem {
      return value.value.find(item => item.itemID === itemID)!;
    },
    observe: pipe(
      value.observe,
      map((items: Dnd5eInventoryItem[]): Dnd5eInventoryItem | undefined => items.find(s => s.itemID === itemID)),
      filter(item => item !== undefined),
      map(item => item as Dnd5eInventoryItem),
      distinct()
    ),
    apply: fn => value.apply(prev => {
      const i = prev.findIndex(s => s.itemID === itemID);
      if (i === -1) return [];
      return ListOperation.apply(i, fn(prev[i]))
    }).then(items => items.find(s => s.itemID === itemID)!)
  });
}

export type InventoryItemProps = ListItemProps<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>;

export function InventoryListItem({item: value, remove}: InventoryItemProps) {
  const [open, toggleOpen] = useToggle(false);
  const {item, description, qty, equipped} = useMemo(() => Dnd5eInventoryItemSignals(value), [value]);
  const itemNameValue = useRefValue(item);
  const itemValue = useRefValue(value);
  const [, dragHandlerRef, dragRefPreview] = useDrag<Dnd5eInventoryItem, {dropEffect: "copy" | "move", action: Promise<"copy" | "move">}>({
    type: "legends/item",
    item: () => value.value,
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        const result = monitor.getDropResult();
        result?.action.then(response => {
          if (response === "copy" && result?.dropEffect !== "copy") {
            remove();
          }
        });
      }
    }
  }, [value, remove]);

  const isDescriptionEmpty = useComputedValue(description, description => !description || RichTextFn.isEmpty(description));
  const selectedNodeId = useSelectedNodeID();
  const sendMessage = useSendFeatureMessage();
  const onSend = async () => {
    const nodeID = await toPromise(selectedNodeId);
    const f = await toPromise(value.observe);
    await sendMessage(nodeID!, f.item, [], f.description);
  };

  const effectGroups = new Set(itemValue.effects.map(Dnd5eItemEffectFn.getGroup).filter(g => g !== undefined) as string[]);
  const groupValues = new Map([...effectGroups.values()].map(group => {
    const enabledEffects = itemValue.effects.filter(effect => Dnd5eItemEffectFn.getGroup(effect) === group)
      .filter(effect => effect.enabled);
    return enabledEffects.length > 0 ? [group!, enabledEffects[0].effectID] : [group!, undefined];
  }));

  const setActive = useCallback((effectID: Dnd5eItemEffectID, active: boolean) => {
    value.apply(prev => {
      const i = prev.effects.findIndex(m => m.effectID === effectID);
      if (i === -1) return [];
      return [{type: "update-effects", operations: ListOperation.apply(i, [{type: "update-enabled", operations: BooleanFn.set(prev.effects[i].enabled, active)}])}];
    });
  }, [value.apply]);

  const setGroupEffect = (group: string, effectID: Optional<Dnd5eItemEffectID>) => {
    value.apply(prev => {
      const groupEffects = prev.effects.filter(e => Dnd5eItemEffectFn.getGroup(e) === group);
      const disableEffects = groupEffects.filter(e => e.effectID !== effectID).filter(e => e.enabled);
      const enableEffects = groupEffects.filter(e => e.effectID === effectID).filter(e => !e.enabled);
      return [{
        type: "update-effects",
        operations: [
          ...disableEffects.flatMap(e => ListOperation.apply(prev.effects.indexOf(e), [{
            type: "update-enabled",
            operations: BooleanFn.set(e.enabled, false)
          }])) satisfies ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[],
          ...enableEffects.flatMap(e => ListOperation.apply(prev.effects.indexOf(e), [{
            type: "update-enabled",
            operations: BooleanFn.set(e.enabled, true)
          }])) satisfies ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[]
        ]
      }] satisfies Dnd5eInventoryItemOperation[];
    });
  }

  const equippedValue = useRefValue(equipped);
  return (<tr ref={dragRefPreview} role="list-item">
    <td>
      <IconButton ref={dragHandlerRef}><DragIndicator /></IconButton>
    </td>
    <td>
      <InputGroup size="small" className="basis-12 shrink-0 px-1 gap-1 items-center justify-center">
        <Checkbox checked={equippedValue} onChange={ev => {
          const checked = ev.target.checked;
          value.apply(prev => Dnd5eInventoryItemOperation.updateEquipped(BooleanFn.set(prev.equipped, checked)));
        }} />
      </InputGroup>
    </td>
    <td>
      <InputGroup size="small" className="basis-12 shrink-0 px-1 gap-1">
        <InputNumber size="small" type="number" placeholder="QTY" className="text-right" value={qty} min={0} />
        <span className="text-white/30">x</span>
      </InputGroup>
    </td>
    <td>
      <div className="flex flex-row gap-0.5">
        <InputGroup className="pr-0 flex-1">
          <InputGroupLabel className="cursor-pointer flex-1" onClick={toggleOpen}>
            {itemNameValue}
          </InputGroupLabel>
        </InputGroup>
        <ButtonBar className="px-0">
          {[...effectGroups.values()].map(group => <InputGroup key={group} className="gap-0.5 pl-2 pr-0">
            <InputGroupLabel className="px-0">
              {group}
              <Select value={groupValues.get(group) || ""} onChange={e => {
                const effectID = e.target.value;
                setGroupEffect(group, effectID === "" ? undefined : effectID as Dnd5eItemEffectID);
              }}>
                <option value={""}></option>
                {itemValue.effects.filter(a => Dnd5eItemEffectFn.getGroup(a) === group).map(effect => <option key={effect.effectID} value={effect.effectID}>{effect.name.substring(effect.name.indexOf(":") + 1).trim()}</option>)}
              </Select>
            </InputGroupLabel>
          </InputGroup>)}
          <div className="flex flex-row gap-0.5">
            {itemValue.effects.filter(e => Dnd5eItemEffectFn.getGroup(e) === undefined).map(effect => <Button key={effect.effectID} className="px-2" variant={effect.enabled ? "primary" : "tertiary"} onClick={() => setActive(effect.effectID, !effect.enabled)}>
              {effect.name}
            </Button>)}
          </div>
          <IconButton variant="primary" disabled={isDescriptionEmpty} title="Send to Chat" onClick={onSend}><FaPaperPlane/></IconButton>
        </ButtonBar>
      </div>
    </td>
    {open && <Dnd5eItemEditor value={value} remove={remove} onClose={toggleOpen} />}
  </tr>);
}

const InventoryList = forwardRef(function InventoryList(props: PropsWithChildren<object>, ref: ForwardedRef<HTMLTableSectionElement>) {
  const isDragging = useDragLayer(monitor => monitor.isDragging() && monitor.getItemType() === "legends/item");
  const [isOver, dropRef] = useDrop({accept: "legends/item", collect: monitor => monitor.isOver({shallow: false})});

  return (<table className={twMerge(
    "table-auto border-separate border-spacing-y-0.5 border-spacing-x-0.5 border-0",
    isDragging && "border-spacing-y-0 border-t-2 border-transparent"
  )}>
    <thead className="h-10">
    <tr>
      <th className="bg-zinc-900/50 w-11"></th>
      <th className="bg-zinc-900/50 text-h300 w-0 px-4">Equipped</th>
      <th className="bg-zinc-900/50 text-h300 w-0 px-4">QTY</th>
      <th className="bg-zinc-900/50 text-h400 text-left px-4">Name</th>
    </tr>
    </thead>
    <tbody
      ref={instance => {
        if (typeof ref === "function") ref(instance);
        else if (ref) ref.current = instance;
        dropRef(instance);
      }}
      role={"list"}
      className={twMerge(isDragging && !isOver && "relative")}>
      {props.children}
      {isDragging && !isOver && <tr className="bg-gray-400/30 absolute pointer-events-none flex items-stretch justify-stretch top-0 bottom-0 left-0 right-0">
        <td colSpan={4} className="m-1.5 border-dashed border-4 border-white text-black font-bold flex items-center justify-center flex-1 rounded-xl text-h400" />
      </tr>}
    </tbody>
  </table>);
});

export type InventoryProps = {
  value: MutableRef<Dnd5eInventoryItem[], ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[]>;
};
export function Inventory({value}: InventoryProps) {
  const deleteItemByItemID = useCallback((itemID: Dnd5eItemID) => {
    value.apply(prev => {
      const index = prev.findIndex(item => item.itemID === itemID);
      if (index === -1) return [];
      return ListOperation.delete(index, prev[index]);
    });
  }, [value]);

  const ref = useRef<HTMLDivElement>(null);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [{isOver}, dropRef] = useDrop<Dnd5eInventoryItem, unknown, {isOver: boolean}>(() => ({
    accept: "legends/item",
    canDrop: () => true,
    hover: (_, monitor) => setHoverIndex(getPlaceholderIndex(monitor, ref.current!)),
    drop: (item, monitor) => {
      const index = getPlaceholderIndex(monitor, ref.current!);
      if (index === -1) return;
      let action = "move";
      return {
        action: value.apply(prev => {
          const featureIndex = prev.indexOf(item);
          if (featureIndex !== -1) {
            if (featureIndex === index || featureIndex + 1 === index) return [];
          }
          action = featureIndex === -1 ? "copy" : "move";
          return featureIndex === -1
            ? ListOperation.insert(index, {
              ...item,
              itemID: generateDnd5eItemID()
            })
            : ListOperation.move(featureIndex, featureIndex < index ? index - 1 : index);
        }).then(() => action)
      };
    },
    collect: (monitor) => {
      return ({
        isOver: monitor.isOver({shallow: false})
      });
    }
  }), [ref, value, setHoverIndex]);

  const isDragging = useDragLayer(monitor => monitor.isDragging() && monitor.getItemType() === "legends/item");
  dropRef(ref);

  const [itemEditor, setItemEditor] = useState<Dnd5eItemID | undefined>(undefined);
  const item = useMemo(() => itemEditor ? getItem(value, itemEditor) : undefined, [value, itemEditor]);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Inventory</SectionHeader>
      <ButtonBar>
        <Button onClick={() => {
          const newItem = Dnd5eInventoryItemFn.DEFAULT();
          value.apply(prev => ListOperation.insert(prev.length, newItem));
          setItemEditor(newItem.itemID);
        }}><FaPlus /> New Item</Button>
        <ExpandOptions>
          <ImportButton type={dnd5eInventoryItemType} onImport={(next) => {
            value.apply(prev => ListOperation.insert(prev.length, copyDnd5eItem(next)));
          }}>Import Item</ImportButton>
        </ExpandOptions>
      </ButtonBar>
    </div>

    <GenericInputList
      items={value}
      placeholders={isDragging}
      activePlaceholderIndex={isOver ? hoverIndex : -1}
      itemKey={useCallback((item: Dnd5eInventoryItem) => item.itemID, [])}
      Component={useCallback((props) => <InventoryList {...props} ref={ref} />, [ref])}
      Placeholder={InventoryTablePlaceholder}
      Empty={InventoryTableEmpty}
      ListItem={InventoryListItem} />

    {item && ReactDOM.createPortal(<Dnd5eItemEditor value={item} onClose={() => setItemEditor(undefined)} remove={() => itemEditor && deleteItemByItemID(itemEditor)} />, document.body)}

    <ButtonBar>
      <Button className="flex-1" onClick={() => {
        const item = Dnd5eInventoryItemFn.DEFAULT();
        value.apply(prev => [{type: "insert", index: prev.length, item}])
          .then(_ => setItemEditor(item.itemID));
      }}><FaPlus /> New Item</Button>
      <ImportButton type={dnd5eInventoryItemType} onImport={(next) => {
        value.apply(prev => ListOperation.insert(prev.length, copyDnd5eItem(next)));
      }}>Import Item</ImportButton>
    </ButtonBar>
  </div>);
}

function InventoryTablePlaceholder({active}: PlaceholderProps) {
  return <tr className="h-0">
    <td colSpan={4}>
      <div className="pt-0.5">
        <DefaultPlaceholder active={active} />
      </div>
    </td>
  </tr>
}


function InventoryTableEmpty() {
  return <tr>
    <td colSpan={4} className="h-10" />
  </tr>
}