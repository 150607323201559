import {Dice, DiceExpression} from "common/dice/index.ts";
import {generateRollRequestID, RollRequestID, RollRequests} from "common/qlab/index.ts";
import {Dnd5eDamageType, DND_5E_DAMAGE_TYPES, isDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Dnd5eAction, Dnd5eActionOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {Observable, toPromise} from "common/observable";
import {useCallback} from "react";
import {Dnd5eAttribute, Dnd5eCharacterOperation, Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eSpellLevel, NodeId, Sheet, SheetOperation} from "common/legends/index.ts";
import {RollVariables} from "common/qlab/message/roll-request/index.ts";
import {ApplyAction} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {encryptValue, ListOperation, MapFn, NumberFn, Optional, RichText, RichTextFn, ValueFn} from "common/types/index.ts";
import {Dnd5eDamageRollModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-roll-modifier.ts";
import {Dnd5eSpellSlotConsumptionModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-spell-slot-consumption-modifier.ts";
import {Dnd5eResourceConsumptionModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-resource-consumption-modifier.ts";
import {Dnd5eResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {Dnd5eResource, Dnd5eResourceOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eItemConsumptionModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-item-consumption-modifier.ts";
import {useSendFeatureMessage} from "./use-send-feature-message.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {Dnd5eStatBlockOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {getMaxPactSlots, getPactSlotLevel} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {
  getActiveActionAttackModifier,
  getActiveActionBaseRoll,
  getActiveActionDifficultyClass,
  getActiveActionLabel,
  getActiveActionModifiers,
  getActiveActionVariables
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MutableRef} from "common/ref";
import {useSendDnd5eAction} from "../../dnd-5e/use-send-dnd-5e-action.ts";
import {Dnd5eActionDamage, Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";

export function useSendAttackMessage() {
  const userID = useUserID()!;
  const getNodeIcon = useGetNodeIcon();
  const sendDnd5eAction = useSendDnd5eAction();

  return useCallback(async (
    nodeID: Optional<NodeId>,
    title: string,
    description: RichText,
    attackRoll: DiceExpression,
    damageExpressions: {
      damageType: Optional<Dnd5eDamageType>,
      hitExpression: DiceExpression,
      critExpression: DiceExpression
    }[],
    onHitMessage?: string,
    onCritMessage?: string,
    variables?: RollVariables
  ): Promise<void> => {
    const attackRollID = generateRollRequestID();

    const hitActionDamage: Dnd5eActionDamage[] = [];
    const critActionDamage: Dnd5eActionDamage[] = [];
    const rollRequests: {[rollID: string]: DiceExpression} = {};
    for (const damageExpression of damageExpressions) {
      const hitRollID = generateRollRequestID();
      const critRollID = generateRollRequestID();
      hitActionDamage.push({damageType: damageExpression.damageType, damageRollID: hitRollID});
      critActionDamage.push({damageType: damageExpression.damageType, damageRollID: critRollID});
      rollRequests[hitRollID] = damageExpression.hitExpression;
      rollRequests[critRollID] = damageExpression.critExpression;
    }

    const encryptedRollRequests = await encryptValue({}, () => Object.entries({
      [attackRollID]: attackRoll,
      ...rollRequests
    } as {[rollRequestID in RollRequestID]: DiceExpression}).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {canViewExpression: true, canViewResult: true}
        }
      };
      return rollRequests;
    }, {} as RollRequests));

    const onCriticalHit: Dnd5eActionSegment[] = [];
    onCriticalHit.push({type: "damage", data: critActionDamage});
    if (onCritMessage) onCriticalHit.push({type: "text", data: [{children: [{text: onCritMessage}]}]});

    const onHit: Dnd5eActionSegment[] = [];
    onHit.push({type: "damage", data: hitActionDamage});
    if (onHitMessage) onHit.push({type: "text", data: [{children: [{text: onHitMessage}]}]});

    const actionSegments: Dnd5eActionSegment[] = [];
    if (!RichTextFn.isEmpty(description)) actionSegments.push({type: "text", data: description});
    actionSegments.push({type: "attack-roll", data: {attackRollID, onCriticalHit, onHit, onMiss: []}});

    await sendDnd5eAction({
      title: title,
      userID, nodeID, icon: await getNodeIcon(nodeID),
      referenceMessageID: undefined,
      rollRequests: encryptedRollRequests,
      segments: actionSegments
    });
  }, [userID, getNodeIcon, sendDnd5eAction]);
}

export function useSendSavingThrowMessage() {
  const userID = useUserID()!;
  const getNodeIcon = useGetNodeIcon();
  const sendDnd5eAction = useSendDnd5eAction();
  return async (
    nodeID: Optional<NodeId>,
    title: string,
    description: RichText,
    defense: Dnd5eAttribute,
    difficultyClass: DiceExpression,
    damageExpressions: {
      damageType: Optional<Dnd5eDamageType>,
      hitExpression: DiceExpression
    }[],
    onSuccessMessage?: string,
    onMissMessage?: string,
    variables?: RollVariables
  ): Promise<void> => {
    const difficultyClassRollID = generateRollRequestID();

    const damageRollRequests: {[rollID: string]: DiceExpression} = {};
    const damageRolls: Dnd5eActionDamage[] = [];
    for (const damageExpression of damageExpressions) {
      const rollID = generateRollRequestID();
      damageRolls.push({damageType: damageExpression.damageType, damageRollID: rollID});
      damageRollRequests[rollID] = damageExpression.hitExpression;
    }

    const encryptedRollRequests = await encryptValue({}, () => Object.entries({
      ...damageRollRequests,
      [difficultyClassRollID]: difficultyClass
    }).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
      return rollRequests;
    }, {} as RollRequests));

    const onSuccess: Dnd5eActionSegment[] = [{type: "damage", data: damageRolls}];
    if (onSuccessMessage) onSuccess.push({type: "text", data: [{children: [{text: onSuccessMessage}]}]});

    const onMiss: Dnd5eActionSegment[] = [];
    if (onMissMessage) onMiss.push({type: "text", data: [{children: [{text: onMissMessage}]}]});

    const actionSegments: Dnd5eActionSegment[] = [];
    if (!RichTextFn.isEmpty(description)) actionSegments.push({type: "text", data: description});
    actionSegments.push({type: "saving-throw", data: {difficultyClassRollID, savingThrowTypes: [defense], onSuccess, onMiss}});

    await sendDnd5eAction({
      title: title,
      userID, nodeID, icon: await getNodeIcon(nodeID),
      referenceMessageID: undefined,
      segments: actionSegments,
      rollRequests: encryptedRollRequests
    });
  };
}


export function useRollAction(nodeID: Observable<NodeId | undefined>, sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>, actionSignal: MutableRef<Dnd5eAction, Dnd5eActionOperation[]> | undefined) {
  const sendSavingThrowMessage = useSendSavingThrowMessage();
  const sendAttackMessage = useSendAttackMessage();
  const sendFeatureMessage = useSendFeatureMessage();
  const globalFeaturesSignal = useGlobalFeatures();


  return useCallback(async (hasAdvantage: boolean, hasDisadvantage: boolean) => {
    if (sheetRef === undefined) return;
    if (actionSignal === undefined) return;
    const action = actionSignal.value;
    const resolvedNodeID = await toPromise(nodeID);
    const sheetValue = await toPromise(sheetRef.observe);
    if (sheetValue === undefined) return[];

    const globalFeatures = globalFeaturesSignal.value;
    const modifiers = getActiveActionModifiers(sheetValue, action, globalFeatures);
    const variables = getActiveActionVariables(sheetValue, action, globalFeatures);

    // spell consumptions
    const spellSlotConsumptions = modifiers
      .filter(modifier => modifier.type === "spell-slot-consumption" && modifier.data.level !== undefined)
      .map(modifier => modifier.data) as Dnd5eSpellSlotConsumptionModifier[];
    if (spellSlotConsumptions.length > 0) {
      sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
        if (prev?.type === "dnd-5e-character") {
          const operations: Dnd5eCharacterOperation[] = spellSlotConsumptions.flatMap((modifier): Dnd5eCharacterOperation[] => {
            const level: Dnd5eSpellLevel = modifier.level === "pact-slot" ? getPactSlotLevel(prev.data.classes) : modifier.level!;
            if (getMaxPactSlots(prev.data.classes, level) > 0 && prev.data.pactSlots > 0) {
              return [{type: "update-pact-slots", operations: NumberFn.decrement(1)}];
            } else {
              const spellSlots = prev.data.spellSlots[level];
              if (spellSlots !== undefined) {
                return [{type: "update-spell-slots", operations: MapFn.apply(level, ValueFn.set(spellSlots, spellSlots - 1))}];
              }
            }
            return [];
          });
          return [{type: "dnd-5e-character", operations}];
        } else if (prev?.type === "dnd-5e-stat-block") {
          const operations: Dnd5eStatBlockOperation[] = spellSlotConsumptions.flatMap((modifier): Dnd5eStatBlockOperation[] => {
            const level: Dnd5eSpellLevel = modifier.level === "pact-slot" ? "1" : modifier.level!;
            const spellSlots = prev.data.spellSlots[level];
            if (spellSlots !== undefined) {
              return [{type: "update-spell-slots", operations: MapFn.apply(level, ValueFn.set(spellSlots, spellSlots - 1))}];
            }
            return [];
          });
          return [{type: "dnd-5e-stat-block", operations}];
        } else {
          return [];
        }
      });
    }

    // item consumptions
    const itemConsumptions = modifiers
      .filter(modifier => modifier.type === "item-consumption" && modifier.data.itemName !== undefined && modifier.data.qty !== 0)
      .map(modifier => modifier.data) as Dnd5eItemConsumptionModifier[];
    if (itemConsumptions.length > 0) {
      sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
        if (prev?.type === "dnd-5e-character") {
          const operations: ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[] = itemConsumptions.flatMap(modifier => {
            const itemIndex = prev.data.inventory.findIndex(i => i.item === modifier.itemName && i.qty > 0);
            if (itemIndex !== -1) {
              return ListOperation.apply(itemIndex, [{
                type: "update-qty",
                operations: NumberFn.decrement(modifier.qty)
              }]);
            } else {
              return [];
            }
          });
          return [{type: "dnd-5e-character", operations: [{
              type: "update-inventory",
              operations
            }]}];
        } else {
          return [];
        }
      });
    }

    // resource consumptions
    const resourceConsumptions = modifiers
      .filter(modifier => modifier.type === "resource-consumption" && modifier.data.resourceID !== undefined && modifier.data.qty > 0)
      .map(modifier => modifier.data) as Dnd5eResourceConsumptionModifier[];
    if (resourceConsumptions.length > 0) {
      sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
        if (prev?.type === "dnd-5e-character") {
          const updateResourceID = (resourceId: Dnd5eResourceID, fn: ApplyAction<Dnd5eResource, Dnd5eResourceOperation[]>): Dnd5eCharacterOperation[] => {
            for (const [featureIndex, feature] of prev.data.race.features.entries()) {
              for (const [resourceIndex, resource] of feature.resources.entries()) {
                if (resource.resourceID === resourceId) {
                  const operations = typeof fn === "function" ? fn(resource) : fn;
                  return [{
                    type: "update-race", operations: ValueFn.apply([{
                      type: "update-features", operations: ListOperation.apply(featureIndex, [{
                          type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                        }]
                      )}])
                  }];
                }
              }
            }

            for (const [featureIndex, feature] of prev.data.background.features.entries()) {
              for (const [resourceIndex, resource] of feature.resources.entries()) {
                if (resource.resourceID === resourceId) {
                  const operations = typeof fn === "function" ? fn(resource) : fn;
                  return [{
                    type: "update-background", operations: ValueFn.apply([{
                      type: "update-features", operations: ListOperation.apply(featureIndex, [{
                        type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                      }]
                    )}])
                  }];
                }
              }
            }

            for (const [classIndex, clazz] of prev.data.classes.entries()) {
              for (const [featureIndex, feature] of clazz.features.entries()) {
                for (const [resourceIndex, resource] of feature.feature.resources.entries()) {
                  if (resource.resourceID === resourceId) {
                    const operations = typeof fn === "function" ? fn(resource) : fn;
                    return [{
                      type: "update-classes", operations: ListOperation.apply(classIndex, [{
                        type: "update-features", operations: ListOperation.apply(featureIndex, [{type: "update-feature", operations: [{
                            type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                          }]}]
                        )
                      }])
                    }];
                  }
                }
              }
            }

            for (const [itemIndex, item] of prev.data.inventory.entries()) {
              for (const [resourceIndex, resource] of item.resources.entries()) {
                if (resource.resourceID === resourceId) {
                  const operations = typeof fn === "function" ? fn(resource) : fn;
                  return [{
                    type: "update-inventory", operations: ListOperation.apply(itemIndex, [{
                        type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                      }]
                    )
                  }];
                }
              }
            }

            for (const [featureIndex, feature] of prev.data.features.entries()) {
              for (const [resourceIndex, resource] of feature.resources.entries()) {
                if (resource.resourceID === resourceId) {
                  const operations = typeof fn === "function" ? fn(resource) : fn;
                  return [{
                    type: "update-features", operations: ListOperation.apply(featureIndex, [{
                        type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                      }]
                    )
                  }];
                }
              }
            }

            return [];
          };

          const operations = resourceConsumptions.flatMap(modifier => updateResourceID(modifier.resourceID!, _ => [{type: "update-current", operations: NumberFn.decrement(modifier.qty)}]));
          return [{type: "dnd-5e-character", operations}];
        } else if (prev?.type === "dnd-5e-stat-block") {
          const updateResourceID = (resourceId: Dnd5eResourceID, fn: ApplyAction<Dnd5eResource, Dnd5eResourceOperation[]>): Dnd5eStatBlockOperation[] => {
            for (const [featureIndex, feature] of prev.data.features.entries()) {
              for (const [resourceIndex, resource] of feature.resources.entries()) {
                if (resource.resourceID === resourceId) {
                  return [{
                    type: "update-features", operations: ListOperation.apply(featureIndex, [{
                      type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
                    }])
                  }];
                }
              }
            }
            return [];
          };

          const operations = resourceConsumptions.flatMap(modifier => updateResourceID(modifier.resourceID!, _ => [{type: "update-current", operations: NumberFn.decrement(modifier.qty)}]));
          return [{type: "dnd-5e-stat-block", operations}];
        } else {
          return [];
        }
      });
    }

    const defense = action.defense;
    if (defense === undefined) {
      const damageRollModifiers = modifiers
          .filter(m => m.type === "damage-roll")
          .flatMap(m => m.data) as Dnd5eDamageRollModifier[];
      const damageEntries = Object.fromEntries([undefined, ...DND_5E_DAMAGE_TYPES].map((damageType): [string, {hitExpression: string, critExpression: string}] => {
        const hitExpression = damageRollModifiers
          .filter(d => d.damageType === damageType)
          .map(d => d.hitExpression).join("+");
        const critExpression = damageRollModifiers
          .filter(d => d.damageType === damageType)
          .map(d => d.critExpression).join("+");
        return [damageType || "none", {
          hitExpression: hitExpression,
          critExpression: critExpression
        }]
      }).filter(([_, damage]) => {
        return (damage.hitExpression !== "" && damage.critExpression !== "")
      }));

      const labelText = getActiveActionLabel(sheetValue, action, globalFeatures);

      const damageRolls = Object.keys(damageEntries).map(damageType => {
        if (!isDamageType(damageType)) {
          return ({
            damageType: undefined,
            hitExpression: Dice.assertDiceExpression(damageEntries[damageType].hitExpression)
          });
        } else {
          return ({
            damageType: damageType,
            hitExpression: Dice.assertDiceExpression(damageEntries[damageType].hitExpression)
          });
        }
      });
      await sendFeatureMessage(resolvedNodeID, labelText, damageRolls, action.description, variables);
    } else if (defense === "ac") {
      const baseRoll = getActiveActionBaseRoll(sheetValue, action, globalFeatures, hasAdvantage, hasDisadvantage);
      const attackRoll: DiceExpression = Dice.assertDiceExpression([
        baseRoll,
        getActiveActionAttackModifier(sheetValue, action, globalFeatures)
      ].join("+").replaceAll("+-", "-"));

      const damageRollModifiers = modifiers
        .filter(m => m.type === "damage-roll")
        .map(m => m.data) as Dnd5eDamageRollModifier[];

      const damageEntries = Object.fromEntries([undefined, ...DND_5E_DAMAGE_TYPES].map((damageType): [string, {hitExpression: string, critExpression: string}] => {
        const hitExpression = damageRollModifiers
          .filter(d => d.damageType === damageType)
          .map(d => d.hitExpression).join("+");
        const critExpression = damageRollModifiers
          .filter(d => d.damageType === damageType)
          .map(d => d.critExpression).join("+");
        return [damageType || "none", {
          hitExpression: hitExpression,
          critExpression: critExpression
        }]
      }).filter(([_, damage]) => {
        return (damage.hitExpression !== "" && damage.critExpression !== "")
      }));

      const damageExpressions = [];
      for (const damageType of Object.keys(damageEntries)) {
        damageExpressions.push({
          damageType: isDamageType(damageType) ? damageType : undefined,
          hitExpression: Dice.assertDiceExpression(damageEntries[damageType].hitExpression),
          critExpression: Dice.assertDiceExpression(damageEntries[damageType].critExpression)
        })
      }

      const labelText = getActiveActionLabel(sheetValue, action, globalFeatures);
      await sendAttackMessage(
        resolvedNodeID,
        labelText,
        action.description,
        attackRoll,
        damageExpressions,
        action.onHit,
        action.onCrit,
        variables
      );
    } else {
      const dcExpression = getActiveActionDifficultyClass(sheetValue, action, globalFeatures);

      const damageRollModifiers = modifiers
        .filter(m => m.type === "damage-roll")
        .map(m => m.data) as Dnd5eDamageRollModifier[];
      const damageEntries = Object.fromEntries([undefined, ...DND_5E_DAMAGE_TYPES].map((damageType): [string, {hitExpression: string}] => {
        const hitExpression = damageRollModifiers
          .filter(d => d.damageType === damageType)
          .map(d => d.hitExpression).join("+");
        return [damageType || "none", {
          hitExpression: hitExpression
        }]
      }).filter(([_, damage]) => (damage.hitExpression !== "")));

      const labelText = getActiveActionLabel(sheetValue, action, globalFeatures);
      const hitMessage = action.onHit ? action.onHit : "";
      const missMessage = action.onMiss ? action.onMiss : "";

      const damageExpressions = [];
      for (const damageType of Object.keys(damageEntries)) {
        damageExpressions.push({
          damageType: isDamageType(damageType) ? damageType : undefined,
          hitExpression: Dice.assertDiceExpression(damageEntries[damageType].hitExpression)
        });
      }
      await sendSavingThrowMessage(resolvedNodeID, labelText, action.description, defense as Dnd5eAttribute, dcExpression, damageExpressions, hitMessage, missMessage, variables);
    }
  }, [nodeID, actionSignal, sendSavingThrowMessage, sheetRef, globalFeaturesSignal]);
}
