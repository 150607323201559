import {listIdentity} from "common/observable";
import {Dnd5eCondition, Sheet, SheetOperation} from "common/legends/index.ts";
import {useMemo} from "react";
import {Optional, SetOperation} from "common/types/index.ts";
import {MutableRef} from "common/ref";

export function useSheetConditions(sheetRef: MutableRef<Sheet | undefined, SheetOperation[]>): MutableRef<Dnd5eCondition[], SetOperation<Dnd5eCondition>[]> {
  return useMemo(() => {
    return sheetRef.map(
      sheet => {
        if (sheet?.type === "dnd-5e-character") {
          return sheet.data.conditions;
        } else if (sheet?.type === "dnd-5e-stat-block") {
          return sheet.data.conditions;
        } else {
          return [];
        }
      },
      (sheet: Optional<Sheet>, operations: SetOperation<Dnd5eCondition>[]): SheetOperation[] => {
        if (sheet?.type === "dnd-5e-character") {
          return [{type: "dnd-5e-character", operations: [{
            type: "update-conditions", operations
          }]}];
        } else if (sheet?.type === "dnd-5e-stat-block") {
          return [{type: "dnd-5e-stat-block", operations: [{
            type: "update-conditions", operations
          }]}];
        } else {
          return [];
        }
      }
    ).distinct(listIdentity);
  }, [sheetRef]);
}
