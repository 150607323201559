import {DialogID, Layout, LayoutOperation, LayoutPath, Stack, StackId, StackItem, StackOperation, StackProxyRegion} from "#lib/container/index.ts";
import {StackTabView} from "#lib/container/react/stack/stack-tab-view.tsx";
import {useDrop} from "react-dnd";
import {useRef, useState} from "react";
import {useApplyToContainer} from "#lib/container/react/container-context.ts";
import {ApplyAction, ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {AddToStackButton} from "#lib/container/react/stack/add-to-stack-button.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";

export type StackTabProps<Value, Operation> = {
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  StackTabView: StackTabView<Value>;
  value: Stack<Value>;
  Stacks: Stacks<Value>;
  apply: (action: ApplyAction<Optional<Layout<Value>>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;
};

export function StackTabs<Value, Operation>({dialogId, value, layoutPath, apply, StackTabView, Stacks}: StackTabProps<Value, Operation>) {
  const items = value.items;
  const activeId = value.activeId;

  const setActiveStack = (stackId?: StackId) => {
    const operation: LayoutOperation<Value, Operation> = {type: "apply-to-stack", path: layoutPath, operations: [{
        type: "update-active-id",
        operations: [{
          type: "set",
          prevValue: activeId,
          nextValue: stackId
        }]
      }]};
    apply(_ => [operation]);
  };


  const addToStack = (stackItem: Value) => {
    apply(prev => {
      if (!prev) return [];
      const stack = Layout.getLayoutItem(prev, layoutPath);
      if (stack.type !== "stack") return [];
      const stackId = StackId.generate();
      const operations: LayoutOperation<Value, Operation>[] = [{type: "apply-to-stack", path: layoutPath, operations: [
        {type: "update-items", operations: ListOperation.insert(stack.items.length, {id: stackId, content: stackItem})},
        {type: "update-active-id", operations: ValueFn.set(stack.activeId, stackId)}
      ]}];
      return operations;
    });
  };
  const [proxyIndex, setProxyIndex] = useState<number | undefined>(undefined);
  const headerDivRef = useRef<HTMLDivElement>(null);
  const applyToContainer = useApplyToContainer();
  const [{isOver}, dropRef] = useDrop<{
    dialogId?: DialogID,
    layoutPath: LayoutPath,
    stackId: StackId,
    value: StackItem<Value>
  }, undefined, {isOver: boolean}>(() => ({
    accept: "legends/tab",
    collect: (monitor) => ({
      isOver: monitor.isOver({shallow: true})
    }),
    hover: ({stackId}, monitor) => {
      const {x} = monitor.getClientOffset()!;
      const header = headerDivRef.current;
      if (!header) return;
      let newProxyIndex = -1;
      let index = 0;
      for (let i = 0; i < header.children.length; i ++) {
        const node = header.children.item(i);
        if (!node) continue;
        // @ts-ignore
        if (node.offsetParent === null) continue;
        const {left, right} = node.getBoundingClientRect();
        if (x > left && x < right) newProxyIndex = index;
        index ++;
      }
      if (newProxyIndex === -1) newProxyIndex = index;
      const stackIndex = items.findIndex(item => item.id === stackId);
      if (stackIndex !== -1) {
        if (stackIndex <= newProxyIndex) newProxyIndex ++;
      }
      if (proxyIndex !== newProxyIndex) setProxyIndex(newProxyIndex);
    },
    drop: (data) => {
      if (proxyIndex === undefined) return;

      let dataPath = data.layoutPath.slice(0, -1);
      const sourceIndex = data.layoutPath[data.layoutPath.length - 1];
      const sameStack = (dialogId === data.dialogId) && LayoutPath.equals(layoutPath, dataPath);
      const transformedSourceIndex = (sameStack && proxyIndex <= sourceIndex) ? sourceIndex + 1 : sourceIndex;

      const insertStack: StackOperation<Value, Operation>[] = [{
        type: "update-items",
        operations: [{
          type: "insert",
          index: proxyIndex,
          item: data.value
        }]
      }];
      const insertOperation: LayoutOperation<Value, Operation> = {type: "apply-to-stack", path: layoutPath, operations: insertStack};
      const removeStack: StackOperation<Value, Operation>[] = [{
        type: "update-items",
        operations: [{
          type: "delete",
          index: transformedSourceIndex,
          item: data.value
        }]
      }];
      const removeOperation: LayoutOperation<Value, Operation> = {type: "apply-to-stack", path: dataPath, operations: removeStack};
      applyToContainer(_ => [
        dialogId === undefined
          ? {type: "apply-to-layout", operations: [insertOperation]}
          : {type: "apply-to-dialog", id: dialogId, operations: [{type: "apply-to-layout", operations: insertStack}]},
        data.dialogId === undefined
          ? {type: "apply-to-layout", operations: [removeOperation]}
          : {type: "apply-to-dialog", id: data.dialogId, operations: [{type: "apply-to-layout", operations: removeStack}]}
      ]);
      setProxyIndex(undefined);
    }
  }), [headerDivRef, proxyIndex, setProxyIndex]);

  const toggleActiveStack = (item: StackItem<Value>) => {
    if (activeId !== item.id) {
      setActiveStack(item.id)
    }
  };
  const closeStack = (index: number, value: StackItem<Value>) => {
    const removeOperation: LayoutOperation<Value, Operation> = {type: "apply-to-stack", path: layoutPath, operations: [{
        type: "update-items",
        operations: [{
          type: "delete",
          index,
          item: value
        }]
      }]};
    apply(_ => [removeOperation]);
  };

  function renderTab(item: StackItem<Value>, index: number) {
    const onToggleStack = toggleActiveStack.bind(undefined, item);
    return (<StackTabView key={item.id} active={item.id === activeId} dialogId={dialogId} layoutPath={[...layoutPath, index]} value={item} onToggle={onToggleStack} onClose={() => closeStack(index, item)} />);
  }

  dropRef(headerDivRef);
  return (
    <div ref={headerDivRef} className="tab-header">
      {isOver && proxyIndex !== undefined ? [
        ...items.slice(0, proxyIndex).map(renderTab),
        <StackProxyRegion key="proxy" />,
        ...items.slice(proxyIndex).map(renderTab)
      ] : items.map(renderTab)}
      <AddToStackButton Stacks={Stacks} onAddStackItem={addToStack} />
    </div>
  );
}