import {z} from "zod";
import {NodeID, UserID} from "#common/legends/index.ts";
import {ConstantOperation, constantType, Encrypted, FileReference, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {RollRequests, RollResults} from "#common/qlab/index.ts";
import {RollRequestID} from "#common/qlab/message/roll-request/index.ts";
import {Dnd5eSavingThrowType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {QLabMessageID} from "../q-lab-message-id.ts";

export const Dnd5eSavingThrowMessage = z.object({
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  referenceMessageID: z.optional(QLabMessageID),
  savingThrowType: Dnd5eSavingThrowType,
  savingThrowRollID: RollRequestID,
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
});
export type Dnd5eSavingThrowMessage = z.infer<typeof Dnd5eSavingThrowMessage>;

export type Dnd5eSavingThrowMessageOperation =
  | {type: "update-user-i-d", operations: ConstantOperation[]}
  | {type: "update-icon", operations: ConstantOperation[]}
  | {type: "update-node-i-d", operations: ConstantOperation[]}
  | {type: "update-saving-throw-type", operations: ConstantOperation[]}
  | {type: "update-saving-throw-roll-i-d", operations: ConstantOperation[]}
  | {type: "update-roll-requests", operations: ConstantOperation[]}
  | {type: "update-reference-message-i-d", operations: ConstantOperation[]}
  | {type: "update-roll-results", operations: ValueOperation<Optional<Encrypted<RollResults>>, ConstantOperation>[]}
  ;
export const dnd5eSavingThrowMessageType: Type<Dnd5eSavingThrowMessage, Dnd5eSavingThrowMessageOperation> = new ObjectType({
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  referenceMessageID: constantType,
  savingThrowType: constantType,
  savingThrowRollID: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType)
});
