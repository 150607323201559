import {z} from "zod";

export const InsertStringOperation = z.object({
  type: z.literal("insert"),
  offset: z.number(),
  text: z.string()
});
export type InsertStringOperation = z.infer<typeof InsertStringOperation>;

export const RemoveStringOperation = z.object({
  type: z.literal("remove"),
  offset: z.number(),
  text: z.string()
});
export type RemoveStringOperation = z.infer<typeof RemoveStringOperation>;

export const SetStringOperation = z.object({
  type: z.literal("set"),
  prevText: z.string(),
  nextText: z.string()
});
export type SetStringOperation = z.infer<typeof SetStringOperation>;

export const StringOperation = z.discriminatedUnion("type", [InsertStringOperation, RemoveStringOperation, SetStringOperation]);
export type StringOperation = z.infer<typeof StringOperation>;

export const StringFn = {
  set: (prevText: string, nextText: string): StringOperation[] => [{type: "set", prevText, nextText}]
};
