import {useTextureManager} from "#lib/qlab/react/hooks/asset/texture-manager.tsx";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {useMemo} from "react";
import {FileReference} from "common/types/index.ts";
import {Observer} from "common/observable";

export function useImageTexture(url: FileReference): [boolean, WebGLTexture] {
  const textureManager = useTextureManager();
  const loader = useObservableLoader(useMemo(() => {
    if (url === undefined) return (_: Observer<WebGLTexture>) => {
      return () => {};
    };
    return textureManager.textureObservable(url)
  }, [textureManager, url]));
  if (loader.isLoading) return [false, 0];
  return [true, loader.data];
}
