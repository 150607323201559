import {ListOperation} from "common/types/index.ts";
import {NodeCondition, NodeConditionFn, NodeConditionOperation, NodeConditionTypes} from "common/legends/node/condition/node-condition.ts";
import {Button, ButtonBar, ExpandableLabel, IconButton, useToggle} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import React, {useCallback, useState} from "react";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {Menu} from "@headlessui/react";
import ReactDOM from "react-dom";
import {usePopper} from "react-popper";
import {Popper} from "#lib/components/popper/popper.tsx";
import {ListControl} from "./list-control.ts";
import {EffectNodeConditionField} from "./effect-node-condition-field.tsx";
import {useListSignals} from "../../../../common/use-list-signals.ts";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef} from "common/ref";
import {generateNodeConditionID} from "common/legends/node/condition/node-condition-i-d.ts";
import {useTypedRef} from "../../../../common/use-typed-ref.ts";


type ConditionFieldProps = {
  value: MutableRef<NodeCondition, NodeConditionOperation[]>;
  controls: ListControl;
};
function ConditionField({value, controls}: ConditionFieldProps) {
  const [type, typedRef] = useTypedRef<NodeConditionTypes>(value);

  if (type === "effect") {
    return <EffectNodeConditionField value={typedRef} controls={controls} />
  } else {
    return <div className="h-8 text-red/40">Unknown Condition</div>
  }
}


export type ConditionsFieldProps = {
  value: MutableRef<NodeCondition[], ListOperation<NodeCondition, NodeConditionOperation>[]>;
}
export function ConditionsField({value}: ConditionsFieldProps) {
  const [expand, toggleExpand] = useToggle(false);
  const conditions = useListSignals(value, NodeConditionFn.getNodeConditionID, NodeConditionFn.copyNodeCondition);

  const addEffectCondition = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "effect",
    data: {
      conditionID: generateNodeConditionID(),
      effectID: undefined
    }
  })), [value]);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return <div className="flex flex-col gap-0">
    <ExpandableLabel expanded={expand} toggleExpand={toggleExpand}>
      <span className="flex-1 px-4 cursor-pointer" onClick={toggleExpand}>Conditions</span>
      <ButtonBar>
        <Menu>
          <Menu.Button as={IconButton} ref={ref => setReferenceElement(ref)} title="Add Condition"><FaPlus/></Menu.Button>
          {ReactDOM.createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper}>
            <Menu.Item as={Button} onClick={addEffectCondition}>Visible on Effect</Menu.Item>
          </Menu.Items>, usePortal())}
        </Menu>
      </ButtonBar>
    </ExpandableLabel>
    {expand && <Fieldset>
      {conditions.length === 0
        ? <span className="text-white/50 italic text-sm text-center px-4 h-8 flex items-center justify-center">There are no conditions.</span>
        : conditions.map(([key, props]) => <ConditionField key={key} value={props.itemRef} controls={{
          remove: props.remove
        }} />)}
    </Fieldset>}
  </div>
}