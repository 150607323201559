import {QLabResourceID, QLabStoreID, ResourceType, ResourceTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {useInstance} from "../../q-lab-instance-context.ts";
import {useCallback} from "react";
import {filter, map, toPromise} from "common/observable";
import {pipe} from "common/pipe";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {Asset, AssetID} from "common/legends/asset/index.ts";

export function useGetResource() {
  const instance = useInstance();
  return useCallback(<Type extends ResourceType, Value = ExtractValue<ResourceTypes[Type]>>(type: Type, storeId: QLabStoreID, resourceId: QLabResourceID): Promise<Value> => {
    return pipe(
      instance.observe(storeId),
      filter(store => {
        const resource = store.resources[resourceId];
        return resource?.type === type;
      }),
      map(store => store.resources[resourceId]!.data as unknown as Value),
      toPromise
    );
  }, [instance]);
}

export function useGetAsset() {
  const databaseRef = useDatabase();
  return useCallback((assetID: AssetID): Asset | undefined => {
    const database = databaseRef.value;
    const resource = database.resources[assetID];
    if (resource?.type === "asset") return resource.data;
    return undefined;
  }, [databaseRef]);
}
