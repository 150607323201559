import {Sheet} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {distinct, listIdentity, map, Observable} from "common/observable";
import {useMemo} from "react";
import {Optional} from "common/types/index.ts";
import {MutableRef, Ref} from "common/ref";

export function useSheetItems(value: Ref<Optional<Sheet>>): Ref<string[]> {
  return useMemo(() => {
    const getItems = (sheet: Optional<Sheet>): string[] => {
      if (sheet?.type !== "dnd-5e-character") return [];
      return sheet.data.inventory.map(item => item.item);
    };

    return new MutableRef({
      value(): string[] {
        return getItems(value.value);
      },
      observe: pipe(
        value.observe,
        map(value => getItems(value)),
        distinct(listIdentity)
      ),
      apply: () => {
        throw new Error("Not Supported")
      }
    });
  }, [value]);
}

export function useSheetQty(value: Observable<Sheet | undefined>, itemName: string): Observable<number> {
  return useMemo(() => pipe(
    value,
    map(sheet => {
      if (sheet?.type === "dnd-5e-character") {
        return sheet.data.inventory.filter(i => i.item === itemName).reduce((a, b) => a + b.qty, 0);
      } else {
        return 0;
      }
    }),
    distinct()
  ), [value]);
}

