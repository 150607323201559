export type TreePath = number[];

export const TreePath = {
  equals: (a: TreePath, b: TreePath): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i ++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  },
  isSibling: (a: TreePath, b: TreePath): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length - 1; i ++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  },
  isAncestor: (a: TreePath, b: TreePath): boolean => {
    if (a.length >= b.length) return false;
    return TreePath.equals(a, b.slice(0, a.length));
  },
  isDirectChild: (a: TreePath, b: TreePath): boolean => {
    if (a.length + 1 !== b.length) return false;
    return TreePath.equals(a, b.slice(0, a.length));
  },
  endsBefore: (a: TreePath, b: TreePath): boolean => {
    const as = a.slice(0, a.length - 1)
    const bs = b.slice(0, a.length - 1)
    return TreePath.equals(as, bs) && a[a.length - 1] < b[a.length - 1]
  },
  next: (path: TreePath): TreePath => {
    return path.slice(0, -1).concat(path[path.length - 1] + 1);
  },
  splitParent: (path: TreePath): [TreePath, number] => {
    return [
      path.slice(0, -1),
      path.slice(-1)[0]
    ];
  },
  sort: (a: TreePath, b: TreePath): number => {
    for (let i = 0; i < a.length; i ++ ) {
      if (b.length < i) return -1;
      if (a[i] < b[i]) return -1;
      if (a[i] > b[i]) return 1;
    }
    return 1;
  }
};
