import {Grid, NodeId} from "common/legends/index.ts";
import {useAttachNode} from "../../../common/node/use-attach-node.ts";
import {useDetachNode} from "../../../common/node/use-detach-node.ts";
import {useSendNodeToFront} from "../../../common/node/use-send-node-to-front.ts";
import {useSendNodeToBack} from "../../../common/node/use-send-node-to-back.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useNode} from "../../../viewport/token/use-node.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import React, {useCallback, useMemo} from "react";
import {pipe} from "common/pipe";
import {combine, distinct, map} from "common/observable";
import {getNodePath} from "../../../common/node/use-get-node-path.ts";
import {Optional, TreePath} from "common/types/index.ts";
import {Menu} from "@headlessui/react";
import {Button, ExpandableLabel} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/index.ts";
import {teleportToNode} from "../../../common/node/use-teleport-to-node.ts";
import {useIsGameMaster} from "../../../common/game/use-is-game-master.ts";
import {getPlayerCharacterTokens} from "../../../common/legends/get-player-characters.ts";
import {getGrid} from "../../../common/legends/get-grid.ts";

export function getAncestorNodeIDs(database: QLabDatabase, nodeID: Optional<NodeId>): NodeId[] {
  const nodePath = getNodePath(database, nodeID);
  if (nodePath?.type !== "scene") return [];
  const scene = database.resources[nodePath.sceneID];
  if (scene?.type !== "scene") return [];

  let nodes = scene.data.children;
  let ancestors = [];
  for (let i = 0; i < nodePath.path.length - 1; i ++) {
    const node = nodes[nodePath.path[i]];
    ancestors.push(node.data.id);
    nodes = node.data.children;
  }
  return ancestors;
}




export type TokenContextMenuProps = {
  selectedNodeID: Optional<NodeId>;
  targetNodeID: Optional<NodeId>;
};

export function TokenContextMenu({selectedNodeID, targetNodeID}: TokenContextMenuProps) {
  const attachNode = useAttachNode();
  const detachNode = useDetachNode();
  const sendToFront = useSendNodeToFront();
  const sendToBack = useSendNodeToBack();

  const targetNode = useNode(targetNodeID);
  const targetName = useComputedValue(targetNode, node => node?.data.name || "");
  const isTargetMountable = useComputedValue(targetNode, node => node?.type === "image" || node?.type === "token" ? node.data.mountable : false);
  const isTargetAttachable = useComputedValue(targetNode, node => node?.type === "image" || node?.type === "token" ? node.data.attachable : false);

  const databaseRef = useDatabase();
  const targetLocation = useMemo(() => pipe(databaseRef.observe, map((database) => getNodePath(database, targetNodeID)), distinct()), [databaseRef, targetNodeID]);
  const selectedLocation = useMemo(() => pipe(databaseRef.observe, map((database) => getNodePath(database, selectedNodeID)), distinct()), [databaseRef, selectedNodeID]);

  const teleportSelectedToNode = useCallback(async (targetNodeID: NodeId) => {
    const database = databaseRef.value;
    const characters = getPlayerCharacterTokens(database);
    const grid = getGrid(database, targetNodeID);
    const positions = Grid.spiral(grid, [0, 0]);
    for (const character of characters) {
      if (character.data.id === targetNodeID) continue;
      if (getAncestorNodeIDs(database, character.data.id).some(ancestor => characters.map(c => c.data.id).includes(ancestor))) continue;
      const position = positions.next().value;
      await teleportToNode(databaseRef, character.data.id, targetNodeID, {position, scale: 1, rotation: 0});
    }
  }, [databaseRef]);

  const isSelectedAncestor = useObservable(pipe(
    combine(targetLocation, selectedLocation),
    map(([targetLocation, selectedLocation]) => {
      if (targetLocation?.type === "scene" && selectedLocation?.type === "scene") {
        if (targetLocation.sceneID === selectedLocation.sceneID) {
          return TreePath.isAncestor(selectedLocation.path, targetLocation.path);
        }
      }
      return false;
    }),
    distinct()
  ), false, [targetLocation, selectedLocation]);

  const isSelectedChild = useObservable(pipe(
    combine(targetLocation, selectedLocation),
    map(([targetLocation, selectedLocation]) => {
      if (targetLocation?.type === "scene" && selectedLocation?.type === "scene") {
        if (targetLocation.sceneID === selectedLocation.sceneID) {
          return TreePath.isAncestor(targetLocation.path, selectedLocation.path);
        }
      }
      return false;
    }),
    distinct()
  ), false, [targetLocation, selectedLocation]);


  const isSelectedDirectChild = useObservable(pipe(
    combine(targetLocation, selectedLocation),
    map(([targetLocation, selectedLocation]) => {
      if (targetLocation?.type === "scene" && selectedLocation?.type === "scene") {
        if (targetLocation.sceneID === selectedLocation.sceneID) {
          return TreePath.isDirectChild(targetLocation.path, selectedLocation.path);
        }
      }
      return false;
    }),
    distinct()
  ), false, [targetLocation, selectedLocation]);

  const isGameMaster = useIsGameMaster();

  if (targetNodeID === undefined) {
    return <></>;
  } else if (selectedNodeID !== undefined) {
    return <Menu as="div" className="flex flex-col">
      <ExpandableLabel expanded={true}>
        <span className="flex-1 px-4 text-h200 flex items-center">{targetName}</span>
      </ExpandableLabel>
      <Menu.Items as={Fieldset} static className="flex flex-col gap-0.5 pt-0.5 w-[240px]">
        {isGameMaster && <Menu.Item as={Button} onClick={() => teleportSelectedToNode(targetNodeID)}>Teleport to Item</Menu.Item>}

        {targetNodeID !== selectedNodeID
          ? <>
            {!isSelectedChild && !isSelectedAncestor && isTargetAttachable && <Menu.Item as={Button} onClick={() => {
              attachNode(selectedNodeID, targetNodeID).then(close);
            }}>Attach</Menu.Item>}
            {isSelectedAncestor && isTargetAttachable && <Menu.Item as={Button} onClick={() => {
              detachNode(selectedNodeID, targetNodeID).then(close);
            }}>Detach</Menu.Item>}
            {!isSelectedAncestor && !isSelectedChild && isTargetMountable && <Menu.Item as={Button} onClick={() => {
              attachNode(targetNodeID, selectedNodeID).then(close);
            }}>Mount</Menu.Item>}
            {!isSelectedAncestor && isSelectedChild && !isSelectedDirectChild && isTargetMountable && <Menu.Item as={Button} onClick={() => {
              attachNode(targetNodeID, selectedNodeID).then(close);
            }}>Set as Parent</Menu.Item>}
            {isSelectedChild && isTargetMountable && <Menu.Item as={Button} onClick={() => {
              detachNode(targetNodeID, selectedNodeID).then(close);
            }}>Dismount</Menu.Item>}
          </>
          : <>
            <Menu.Item as={Button} onClick={() => {
              sendToBack(selectedNodeID).then(close);
            }}>Send to Back</Menu.Item>
            <Menu.Item as={Button} onClick={() => {
              sendToFront(selectedNodeID).then(close);
            }}>Send to Front</Menu.Item>
          </>}
      </Menu.Items>
    </Menu>;
  } else {
    if (!isGameMaster) return <></>;
    return <Menu as="div" className="flex flex-col">
      <ExpandableLabel expanded={true}>
        <span className="flex-1 px-4 text-h200 flex items-center">{targetName}</span>
      </ExpandableLabel>
      <Menu.Items as={Fieldset} static className="flex flex-col gap-0.5 pt-0.5 w-[240px]">
        {isGameMaster && <Menu.Item as={Button} onClick={() => teleportSelectedToNode(targetNodeID)}>Teleport to Item</Menu.Item>}
      </Menu.Items>
    </Menu>;
  }
}
