import {Node} from "common/legends/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";

export function nodeSheetReference(node: Optional<Node>): Optional<SheetReference> {
  if (node?.type !== "token") return undefined;
  const tokenID = node.data.tokenReference.tokenID;
  const tokenSheet = node.data.tokenSheets[tokenID];
  if (!tokenSheet) return undefined;
  if (tokenSheet.type === "copy") {
    return {type: "copy", nodeID: node.data.id, tokenID: tokenID};
  } else if (tokenSheet.type === "link") {
    return {type: "link", assetID: node.data.tokenReference.assetID, sheetID: tokenSheet.data};
  }
}
