import {Node, NodeId} from "common/legends/index.ts";
import {Transform, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {getNodePath} from "./use-get-node-path.ts";
import {QLabDatabase, QLabDatabaseOperation, qlabDatabaseType} from "common/qlab/index.ts";
import {MutableRef} from "common/ref";

export async function teleportToNode(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, nodeID: NodeId, targetNodeID: NodeId, offset: Transform) {
  return databaseRef.apply(database => {
    const nodePath = getNodePath(database, nodeID);
    if (nodePath?.type !== "scene") return [];
    const nodeSceneResource = database.resources[nodePath.sceneID];
    if (nodeSceneResource?.type !== "scene") return [];
    const node = Tree.getItemByPath(nodeSceneResource.data.children, nodePath.path);
    if (!node) return [];

    const removeOperation: QLabDatabaseOperation = {
      type: "resource",
      resourceID: nodePath.sceneID,
      operations: ValueFn.apply([{type: "scene", operations: [{
          type: "update-children",
          operations: TreeOperation.delete(nodePath.path, node)
        }]}])
    };

    database = qlabDatabaseType.apply(database, removeOperation);
    const targetNodePath = getNodePath(database, targetNodeID);
    if (targetNodePath?.type !== "scene") return [];

    let nodeWithOffset: Node = node;
    nodeWithOffset = {
      ...nodeWithOffset,
      data: {
        ...nodeWithOffset.data,
        transform: offset
      }
    } as Node;

    const targetNodeScene = database.resources[targetNodePath.sceneID];
    if (targetNodeScene?.type !== "scene") return [];
    const targetNode = Tree.getItemByPath(targetNodeScene.data.children, targetNodePath.path);
    if (!targetNode) return [];

    const insertOperation: QLabDatabaseOperation = {
      type: "resource",
      resourceID: targetNodePath.sceneID,
      operations: ValueFn.apply([{type: "scene", operations: [{
          type: "update-children",
          operations: TreeOperation.insert([...targetNodePath.path, targetNode.data.children.length], nodeWithOffset)
        }]}])
    };
    return [
      removeOperation,
      insertOperation
    ];
  })
}
