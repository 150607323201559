import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {z} from "zod";

export const PlayerSheetStack = z.object({
  reference: z.optional(SheetReference)
});
export type PlayerSheetStack = z.infer<typeof PlayerSheetStack>;

export const PlayerSheetStackOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-reference"), operations: z.array(ValueOperation(z.optional(SheetReference), ConstantOperation))})
]);
export type PlayerSheetStackOperation = z.infer<typeof PlayerSheetStackOperation>;

export const playerSheetStackType: Type<PlayerSheetStack, PlayerSheetStackOperation> = new ObjectType({
  reference: new ValueType(constantType)
});

export const PlayerSheetFn = {
  getReference: (value: PlayerSheetStack): Optional<SheetReference> => value.reference,
  updateReference: (operations: ValueOperation<Optional<SheetReference>, ConstantOperation>[]): PlayerSheetStackOperation[] => [{type: "update-reference", operations}]
};