import {Tag} from "common/types/index.ts";
import {FaTimes} from "react-icons/fa";
import "./tag-viewer.css";

export type TagViewerProps = {
  size?: TagSize;
  value: Tag;
  onClose?: () => void;
};

export type TagSize =
  | "small"
  | "medium"
  | "large"
  ;

export function TagViewer({value: {label, color}, onClose, size = "medium"}: TagViewerProps) {
  return <div className={`tag-viewer ${size}`} style={{backgroundColor: `hsla(${color[0]*360}, ${color[1]*100}%, ${color[2]*100}%, ${color[3]})`}}>
    <span>{label}</span>
    {onClose && <div onClick={onClose}><FaTimes size={12} /></div>}
  </div>
}