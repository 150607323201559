import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {QLabMessageID, TextMessage} from "common/qlab/index.ts";
import {useDecryptValue, useOptionalDecryptValue} from "./use-decrypt-value.ts";
import {TextMessageContentView} from "./text-message-content-view.tsx";
import {UserNodeIcon} from "./user-node-icon.tsx";
import {useIsPartyNode} from "../../common/node/use-is-party-node.ts";
import {useIsGameMaster} from "../../common/game/use-is-game-master.ts";

type TextMessageViewProps = {
  messageId: QLabMessageID;
  message: TextMessage;
};

export function TextMessageView({message}: TextMessageViewProps) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const messageContent = useDecryptValue(message.content, userId, user.data?.privateKey);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue(message.rollResults, userId, user.data?.privateKey);
  const isParty = useIsPartyNode(message.nodeID);
  const isGameMaster = useIsGameMaster();
  const isTokenReveal = isParty || isGameMaster;

  if (messageContent.isLoading) {
    return <div />
  } else {
    return <div className="relative mt-7 mb-2 mx-2">
      <div className="bg-zinc-800/50 rounded-lg shadow-lg backdrop-blur-sm">
        {isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />}
        {!isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={undefined} icon={undefined} />}
        <div className="pointer-events-auto break-words px-4 pt-5 pb-2">
          <TextMessageContentView messageContent={messageContent.data} rollRequests={rollRequests.data} rollResults={rollResults.data} />
        </div>
      </div>
    </div>
  }
}
