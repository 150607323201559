import {Matrix4f} from "#lib/math/index.ts";
import {Color, HSLA, Transform} from "common/types/index.ts";
import {AreaPolyShader} from "./area-poly-shader.tsx";
import {AreaLineShader} from "./area-line-shader.tsx";
import {getLines} from "../../../viewport/common/node/layer-view/walls.ts";
import React, {useCallback, useMemo} from "react";
import {Spline} from "common/types/generic/spline/index.ts";
import {useRenderingContext, useTexture2D} from "#lib/gl-react/index.ts";
import {useFramebuffer} from "#lib/gl-react/hooks/use-framebuffer.ts";
import {MaskShader} from "../../../viewport/common/node/node-view/mask-shader.tsx";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";

export type AreaShaderProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  spline: Spline;
  scale: number;
  color: HSLA;
};
export function AreaShader({projection, view, model, spline, color, scale}: AreaShaderProps) {
  const texture = useTexture2D();
  const context = useRenderingContext();
  const size = useResolution();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, texture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
    }
  }, [texture, size]);

  const framebuffer = useFramebuffer();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, framebuffer);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT0, WebGL2RenderingContext.TEXTURE_2D, texture, 0);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, null);
    }
  }, [framebuffer, texture, size]);

  const lines = useMemo(() => getLines(spline), [spline]);
  const clearFramebuffer = useCallback((context: WebGL2RenderingContext) => {
    context.clearColor(0, 0, 0, 0);
    context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
    context.enable(WebGL2RenderingContext.BLEND);
    context.disable(WebGL2RenderingContext.DEPTH_TEST);
    context.depthFunc(WebGL2RenderingContext.ALWAYS);
    context.blendFunc(WebGL2RenderingContext.SRC_ALPHA, WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA);
  }, []);

  if (size[0] === 0 || size[1] === 0) return <></>
  return <binder>
    <framebuffer value={framebuffer}>
      <viewport x={0} y={0} width={size[0]} height={size[1]}/>
      <action onAction={clearFramebuffer}/>
      <AreaPolyShader projection={projection} view={view} model={model} spline={spline} color={Color.WHITE}/>
      <AreaLineShader projection={projection} view={view} model={model} lines={lines} scale={scale} color={Color.WHITE}/>
    </framebuffer>
    <MaskShader texture={texture} color={color}/>
  </binder>;
}