import {SplitterView} from "./splitter/index.ts";
import {StackView} from "../stack/index.ts";
import {EmptyView} from "./empty-view.tsx";
import {DialogID, Layout, LayoutOperation, LayoutPath} from "../../modal/index.ts";
import {CSSProperties} from "react";
import {ApplyAction} from "#lib/qlab/index.ts";
import {StackContentView} from "../stack/stack-content-view.tsx";
import {StackTabView} from "../stack/stack-tab-view.tsx";
import {Optional} from "common/types/index.ts";
import {Stacks} from "#lib/container/react/stack/stacks.ts";

export type LayoutViewProps<Value, Operation> = {
  style?: CSSProperties;
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  value: Layout<Value>;
  apply: (action: ApplyAction<Optional<Layout<Value>>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;
  StackContentView: StackContentView<Value, Operation>;
  StackTabView: StackTabView<Value>;
  Stacks: Stacks<Value>;
};

export function LayoutView<Value, Operation>({dialogId, layoutPath, value, apply, style, StackContentView, StackTabView, Stacks}: LayoutViewProps<Value, Operation>) {
  switch (value.type) {
    case "row": case "column": return <SplitterView style={style} dialogId={dialogId} layoutPath={layoutPath} value={value} apply={apply} StackContentView={StackContentView} StackTabView={StackTabView} Stacks={Stacks} />;
    case "stack": return <StackView style={style} dialogId={dialogId} layoutPath={layoutPath} value={value} apply={apply} StackContentView={StackContentView} StackTabView={StackTabView} Stacks={Stacks} />;
    default: return <EmptyView style={style} />
  }
}
