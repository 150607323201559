import {MutableRef} from "common/ref";
import {
  INTERACTION_ACTION_TARGET_TITLES,
  INTERACTION_ACTION_TARGET_TYPES,
  InteractionActionTarget,
  InteractionActionTargetOperation
} from "common/legends/node/interaction/action/interaction-action-target.ts";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputSelect} from "#lib/components/input/input-select.tsx";

export function InteractionActionTargetField({valueRef}: {
  valueRef: MutableRef<InteractionActionTarget, InteractionActionTargetOperation[]>
}) {
  return <InputGroup>
    <InputGroupLabel>Target</InputGroupLabel>
    <InputSelect<InteractionActionTarget> value={valueRef} values={INTERACTION_ACTION_TARGET_TYPES} labels={INTERACTION_ACTION_TARGET_TITLES} />
  </InputGroup>
}