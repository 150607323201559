import {IconButton} from "../button/index.ts";
import {FaRegStar, FaStar} from "react-icons/fa6";
import {BooleanFn, BooleanOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type FavoriteButtonProps = {
  value: MutableRef<boolean, BooleanOperation[]>;
};

export function FavoriteButton({value}: FavoriteButtonProps) {
  const isFavorite = useRefValue(value);
  return <IconButton title={isFavorite ? "Mark as Not Favorite" : "Mark as Favorite"} onClick={() => {
    value.apply(prev => BooleanFn.set(prev, !prev));
  }}>
    {isFavorite ? <FaStar /> : <FaRegStar />}
  </IconButton>
}