import {useMemo} from "react";
import {AccessMask, AccessMaskFn, AccessMaskOperation} from "common/legends/visibility/index.ts";
import {ValueFn} from "common/types/index.ts";
import {useEditor} from "./editor-context.ts";
import {MutableRef} from "common/ref";

export function useEditorVisibilityMask(): MutableRef<AccessMask, AccessMaskOperation[]> {
  const editor = useEditor();
  return useMemo(() => editor.state.map<AccessMask, AccessMaskOperation[]>(
    value => value?.data.visibilityMask || AccessMaskFn.DEFAULT,
    (prev, operations) => {
      if (prev === undefined) return [];
      return ValueFn.apply([{type: prev.type, operations: [{
        type: "update-visibility-mask",
        operations
      }]}]);
    }
  ).distinct(), [editor.state]);
}
