import {Type} from "./type.ts";

export type TransformOperator<Operation> = (leftOperation: Operation, topOperation: Operation, tieBreaker: boolean) => Operation[];
export function transformAll<Value, Operation>(type: Type<Value, Operation>, leftOperations: Operation[], topOperations: Operation[], tieBreaker: boolean): [Operation[], Operation[]] {
  if (leftOperations.length === 0 || topOperations.length === 0) {
    return [leftOperations, topOperations];
  } else if (leftOperations.length === 1 && topOperations.length === 1) {
    const rightOperations = type.transform(leftOperations[0], topOperations[0], tieBreaker);
    const bottomOperations = type.transform(topOperations[0], leftOperations[0], !tieBreaker);
    return [rightOperations, bottomOperations];
  } else {
    let bottomOperations: Operation[] = [];
    let rightOperations: Operation[] = [];
    for (const left of leftOperations) {
      let leftOperation = [left];
      bottomOperations = [];
      for (const topOperation of topOperations) {
        const [right, bottom] = transformAll(type, leftOperation, [topOperation], tieBreaker);
        leftOperation = right;
        bottomOperations = bottomOperations.concat(bottom);
      }
      rightOperations = rightOperations.concat(leftOperation);
      topOperations = bottomOperations;
    }
    return [rightOperations, bottomOperations];
  }
}