import {Button, ButtonProps} from "../button/index.ts";
import {FaFileExport} from "react-icons/fa";
import {PropsWithChildren} from "react";

export type ExportButtonProps = PropsWithChildren<ButtonProps & {
  onExport: () => void;
}>;

export function ExportButton({onExport, children, ...props}: ExportButtonProps) {
  return <Button onClick={onExport} title="Export" {...props}><FaFileExport /> {children}</Button>;
}
