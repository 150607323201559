import {z} from "zod";
import {BooleanOperation, booleanType, ColorOperation, colorType, HSLA, NumberOperation, numberType, ObjectType, PropertyRef} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export const MeasurementBeam = z.object({
  sticky: z.boolean(),
  fillColor: HSLA,
  width: z.number()
});
export type MeasurementBeam = z.infer<typeof MeasurementBeam>;

export const MeasurementBeamOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-sticky"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-fill-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-width"), operations: z.array(NumberOperation)})
]);
export type MeasurementBeamOperation = z.infer<typeof MeasurementBeamOperation>;

export const measurementBeamType: Type<MeasurementBeam, MeasurementBeamOperation> = new ObjectType({
  sticky: booleanType,
  fillColor: colorType,
  width: numberType
});

export function MeasurementBeamRef(ref: MutableRef<MeasurementBeam, MeasurementBeamOperation[]>) {
  return {
    stickyRef: PropertyRef<MeasurementBeam, MeasurementBeamOperation, boolean, BooleanOperation>(value => value.sticky, operations => [{type: "update-sticky", operations}])(ref),
    fillColorRef: PropertyRef<MeasurementBeam, MeasurementBeamOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(ref),
    widthRef: PropertyRef<MeasurementBeam, MeasurementBeamOperation, number, NumberOperation>(value => value.width, operations => [{type: "update-width", operations}])(ref)
  };
}
