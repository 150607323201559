import {applyAll, invertAll, Optional, transformAll, Type, ValueOperation} from "../../types/index.ts";
import {ResourceOperation, resourceType, ResourceValue} from "./resource.ts";
import {UserID} from "../../legends/index.ts";
import {QLabResourceChangesetID, QLabResourceRevision} from "#common/qlab/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";

export type QLabResource = {
  value: Optional<ResourceValue>;
  revision: QLabResourceRevision;
}

export type QLabResourceChangeset = {
  changesetId: QLabResourceChangesetID;
  userId: UserID;
  revision: QLabResourceRevision;
  operations: ValueOperation<Optional<ResourceValue>, ResourceOperation>[];
};

export const qlabResourceType: Type<QLabResource, QLabResourceChangeset> = {
  apply: (value: QLabResource, changeset: QLabResourceChangeset): QLabResource => {
    if (value.revision !== changeset.revision) {
      throw new Error("Mismatch between resource and changeset. Resource: " + JSON.stringify(value) + " Changeset: " + JSON.stringify(changeset));
    }
    try {
      return ({
        value: applyAll(resourceType, value.value, changeset.operations),
        revision: value.revision + 1
      });
    } catch (e: any) {
      throw new QLabError(`Cannot apply changeset.\nCHANGESET:\n${JSON.stringify(changeset)}\n\n RESOURCE:\n${JSON.stringify(value)}\n\nERROR: ${e.message}`, e.path ? [...e.path] : []);
    }
  },
  invert: (operation: QLabResourceChangeset): QLabResourceChangeset[] => {
    return [{
      ...operation,
      operations: invertAll(resourceType, operation.operations)
    }];
  },
  transform: (leftOperation: QLabResourceChangeset, topOperation: QLabResourceChangeset, tieBreaker: boolean): QLabResourceChangeset[] => {
    if (leftOperation.revision !== topOperation.revision) throw new Error("Mismatch revision. " + JSON.stringify(leftOperation) + " -- " + JSON.stringify(topOperation));
    if (leftOperation.changesetId === topOperation.changesetId) return [];
    return [{
      ...leftOperation,
      operations: transformAll(resourceType, leftOperation.operations, topOperation.operations, tieBreaker)[0],
      revision: leftOperation.revision + 1
    }];
  },
  migrateValue(value: any): QLabResource {
    return ({
      revision: value.revision,
      value: resourceType.migrateValue(value.value)
    });
  },
  migrateOperation(operation: any): QLabResourceChangeset[] {
    return [{
      changesetId: operation.changesetId,
      revision: operation.revision,
      userId: operation.userId,
      operations: operation.operations.flatMap(resourceType.migrateOperation)
    }];
  },
  validate: (value: any): ValidationError[] => {
    if (typeof value !== "object")  return [{path: [], data: {message: "Invalid type. Expected QLabResource.", value}}]
    if (typeof value.revision !== "number") return [{path: [], data: {message: "Invalid type. Expected QLabResource.", value}}]
    return resourceType.validate(value.value);
  }
}
