import {InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {Dnd5eAlignment, DND_5E_ALIGNMENTS} from "common/legends/index.ts";
import React from "react";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type AlignmentProps = {
  value: MutableRef<Dnd5eAlignment, ValueOperation<Dnd5eAlignment, ConstantOperation>[]>;
};

export function Alignment({value}: AlignmentProps) {
  return (<InputGroup className="pr-0 mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <InputGroupLabel>Alignment</InputGroupLabel>
    <Select value={useRefValue(value)} onChange={ev => value.apply(prevValue => ValueFn.set(prevValue, ev.target.value as Dnd5eAlignment))}>
      {DND_5E_ALIGNMENTS.map(alignment => <option key={alignment} value={alignment}>{alignment}</option>)}
    </Select>
  </InputGroup>)
}