import {MarkerTurn, MarkerTurnOperation, markerTurnType} from "./marker-turn.ts";
import {NodeTurn, NodeTurnOperation, nodeTurnType} from "./node-turn.ts";
import {MultiType} from "#common/types/generic/multi-type/index.ts";
import {Type} from "#common/types/type/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const Turn = z.discriminatedUnion("type", [
  z.object({type: z.literal("marker"), data: MarkerTurn}),
  z.object({type: z.literal("node"), data: NodeTurn})
]);
export type Turn = z.infer<typeof Turn>;
export const TurnOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("marker"), operations: z.array(MarkerTurnOperation)}),
  z.object({type: z.literal("node"), operations: z.array(NodeTurnOperation)})
]);
export type TurnOperation = z.infer<typeof TurnOperation>;

export const turnType: Type<Turn, TurnOperation> = new MultiType({
  "marker": markerTurnType,
  "node": nodeTurnType
});

export function TurnSignals(value: MutableRef<Turn, TurnOperation[]>) {
  return {
    type: value.map(value => value.type),
    data: value.map(value => value.data, (prev, operations: (NodeTurnOperation | MarkerTurnOperation)[]): TurnOperation[] => {
      //@ts-ignore
      return [{type: prev.type as TurnOperation["type"], operations}];
    }).distinct(),
  };
}
