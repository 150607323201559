import {Comparator, ComparatorFn, DiceExpressionTree} from "./parse-dice-expression.ts";
import {DiceExpression} from "./dice-expression.ts";
import {z} from "zod";

export const DiceRoll = z.object({value: z.number(), rolls: z.array(z.number()), faces: z.number()});
export type DiceRoll = z.infer<typeof DiceRoll>;


export type DiceResult =
  | {op: "constant", value: number, label?: string}
  | {op: "add", value: number, source: {left: DiceResult, right: DiceResult}}
  | {op: "subtract", value: number, source: {left: DiceResult, right: DiceResult}}
  | {op: "multiply", value: number, source: {left: DiceResult, right: DiceResult}}
  | {op: "divide", value: number, source: {left: DiceResult, right: DiceResult}}
  | {op: "remainder", value: number, source: {left: DiceResult, right: DiceResult}}
  | {op: "variable", value: number, source: {variable: string}, label?: string}
  | {op: "min", value: number, source: {left: DiceResult, right: DiceResult}, label?: string}
  | {op: "max", value: number, source: {left: DiceResult, right: DiceResult}, label?: string}
  | {op: "parentheses", value: number, source: {expression: DiceResult}, label?: string}

  | {op: "dice", value: number, values: DiceRoll[], source: {count: DiceResult, faces: DiceResult}, label?: string}
  | {op: "reroll", value: number, values: DiceRoll[], source: {dice: DiceResult, comparator: Comparator, target: DiceResult}, label?: string}
  | {op: "reroll-once", value: number, values: DiceRoll[], source: {dice: DiceResult, comparator: Comparator, target: DiceResult}, label?: string}
  | {op: "keep-highest", value: number, values: DiceRoll[], source: {dice: DiceResult, count: DiceResult}, label?: string}
  | {op: "keep-lowest", value: number, values: DiceRoll[], source: {dice: DiceResult, count: DiceResult}, label?: string}
  | {op: "drop-highest", value: number, values: DiceRoll[], source: {dice: DiceResult, count: DiceResult}, label?: string}
  | {op: "drop-lowest", value: number, values: DiceRoll[], source: {dice: DiceResult, count: DiceResult}, label?: string}
  | {op: "explode", value: number, values: DiceRoll[], source: {dice: DiceResult, comparator: Comparator, target: DiceResult}, label?: string}

  | {op: "count-success", value: number, source: {dice: DiceResult, comparator: Comparator, target: DiceResult}, label?: string}
  | {op: "count-failure", value: number, source: {dice: DiceResult, comparator: Comparator, target: DiceResult}, label?: string}
  ;
export const DiceResult: z.ZodType<DiceResult> = z.discriminatedUnion("op", [
  z.object({op: z.literal("constant"), value: z.number(), label: z.optional(z.string())}),
  z.object({op: z.literal("add"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult}))}),
  z.object({op: z.literal("subtract"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult}))}),
  z.object({op: z.literal("multiply"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult}))}),
  z.object({op: z.literal("divide"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult}))}),
  z.object({op: z.literal("remainder"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult}))}),
  z.object({op: z.literal("variable"), value: z.number(), source: z.object({variable: z.string()}), label: z.optional(z.string())}),
  z.object({op: z.literal("min"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("max"), value: z.number(), source: z.lazy(() => z.object({left: DiceResult, right: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("parentheses"), value: z.number(), source: z.lazy(() => z.object({expression: DiceResult})), label: z.optional(z.string())}),

  z.object({op: z.literal("dice"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({count: DiceResult, faces: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("reroll"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, comparator: Comparator, target: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("reroll-once"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, comparator: Comparator, target: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("keep-highest"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, count: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("keep-lowest"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, count: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("drop-highest"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, count: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("drop-lowest"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, count: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("explode"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, comparator: Comparator, target: DiceResult})), label: z.optional(z.string())}),

  z.object({op: z.literal("count-success"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, comparator: Comparator, target: DiceResult})), label: z.optional(z.string())}),
  z.object({op: z.literal("count-failure"), value: z.number(), values: z.array(DiceRoll), source: z.lazy(() => z.object({dice: DiceResult, comparator: Comparator, target: DiceResult})), label: z.optional(z.string())})
]);

export function diceTreeToDiceExpression(result: DiceExpressionTree): DiceExpression {
  switch (result.op) {
    case "constant": return (result.value + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "variable": return (`{${result.variable}}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "dice": return (diceTreeToDiceExpression(result.count) + "d" + diceTreeToDiceExpression(result.faces) + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "explode": return (`${diceTreeToDiceExpression(result.dice)}!${ComparatorFn.toString(result.comparator)}${diceTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll": return (`${diceTreeToDiceExpression(result.dice)}r${ComparatorFn.toString(result.comparator)}${diceTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll-once": return (`${diceTreeToDiceExpression(result.dice)}ro${ComparatorFn.toString(result.comparator)}${diceTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-highest": return (`${diceTreeToDiceExpression(result.dice)}kh${diceTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-lowest": return (`${diceTreeToDiceExpression(result.dice)}kl${diceTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-highest": return (`${diceTreeToDiceExpression(result.dice)}dh${diceTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-lowest": return (`${diceTreeToDiceExpression(result.dice)}dl${diceTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "min": return (`min(${diceTreeToDiceExpression(result.left)}, ${diceTreeToDiceExpression(result.right)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "max": return (`max(${diceTreeToDiceExpression(result.left)}, ${diceTreeToDiceExpression(result.right)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "parentheses": return (`(${diceTreeToDiceExpression(result.expression)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "add": return (`${diceTreeToDiceExpression(result.left)}+${diceTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "subtract": return (`${diceTreeToDiceExpression(result.left)}-${diceTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "multiply": return (`${diceTreeToDiceExpression(result.left)}*${diceTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "divide": return (`${diceTreeToDiceExpression(result.left)}/${diceTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "remainder": return (`${diceTreeToDiceExpression(result.left)}%${diceTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "count-success": return (`${diceTreeToDiceExpression(result.dice)}cs${ComparatorFn.toString(result.comparator)}${diceTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "count-failure": return (`${diceTreeToDiceExpression(result.dice)}cf${ComparatorFn.toString(result.comparator)}${diceTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
  }
}

export function resultToDiceExpression(result: DiceResult, context: {[variable: string]: number}): DiceExpression {
  switch (result.op) {
    case "constant": return (result.value + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "variable": return (`${context[result.source.variable.toUpperCase()] || 0}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "dice": return (resultToDiceExpression(result.source.count, context) + "d" + resultToDiceExpression(result.source.faces, context) + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "explode": return (`${resultToDiceExpression(result.source.dice, context)}!${ComparatorFn.toString(result.source.comparator)}${resultToDiceExpression(result.source.target, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll": return (`${resultToDiceExpression(result.source.dice, context)}r${ComparatorFn.toString(result.source.comparator)}${resultToDiceExpression(result.source.target, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll-once": return (`${resultToDiceExpression(result.source.dice, context)}ro${ComparatorFn.toString(result.source.comparator)}${resultToDiceExpression(result.source.target, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-highest": return (`${resultToDiceExpression(result.source.dice, context)}kh${resultToDiceExpression(result.source.count, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-lowest": return (`${resultToDiceExpression(result.source.dice, context)}kl${resultToDiceExpression(result.source.count, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-highest": return (`${resultToDiceExpression(result.source.dice, context)}dh${resultToDiceExpression(result.source.count, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-lowest": return (`${resultToDiceExpression(result.source.dice, context)}dl${resultToDiceExpression(result.source.count, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "min": return (`min(${resultToDiceExpression(result.source.left, context)}, ${resultToDiceExpression(result.source.right, context)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "max": return (`max(${resultToDiceExpression(result.source.left, context)}, ${resultToDiceExpression(result.source.right, context)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "parentheses": return (`(${resultToDiceExpression(result.source.expression, context)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "add": return (`${resultToDiceExpression(result.source.left, context)}+${resultToDiceExpression(result.source.right, context)}`) as DiceExpression;
    case "subtract": return (`${resultToDiceExpression(result.source.left, context)}-${resultToDiceExpression(result.source.right, context)}`) as DiceExpression;
    case "multiply": return (`${resultToDiceExpression(result.source.left, context)}*${resultToDiceExpression(result.source.right, context)}`) as DiceExpression;
    case "divide": return (`${resultToDiceExpression(result.source.left, context)}/${resultToDiceExpression(result.source.right, context)}`) as DiceExpression;
    case "remainder": return (`${resultToDiceExpression(result.source.left, context)}%${resultToDiceExpression(result.source.right, context)}`) as DiceExpression;
    case "count-success": return (`${resultToDiceExpression(result.source.dice, context)}cs${ComparatorFn.toString(result.source.comparator)}${resultToDiceExpression(result.source.target, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "count-failure": return (`${resultToDiceExpression(result.source.dice, context)}cf${ComparatorFn.toString(result.source.comparator)}${resultToDiceExpression(result.source.target, context)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
  }
}

function compare(a: number, comparator: Comparator, b: number): boolean {
  switch (comparator) {
    case "less": return a < b;
    case "less-or-equal": return a <= b;
    case "equal": return a === b;
    case "greater-or-equal": return a >= b;
    case "greater": return a > b;
    default: throw new Error("Unknown comparator");
  }
}

function rollDice(faces: number): DiceRoll {
  const value = Math.floor(Math.random() * faces) + 1;
  return {
    value,
    rolls: [value],
    faces
  };
}

export function executeDiceFormula(diceFormula: DiceExpressionTree, context: {[variable: string]: number}): DiceResult {
  switch (diceFormula.op) {
    case "constant": return {op: "constant", value: diceFormula.value, label: diceFormula.label};
    case "variable": return {op: "variable", value: context[diceFormula.variable.toUpperCase()] || 0, source: {variable: diceFormula.variable}, label: diceFormula.label};
    case "dice": {
      const count = executeDiceFormula(diceFormula.count, context);
      const faces = executeDiceFormula(diceFormula.faces, context);
      const values = [];
      for (let i = 0; i < count.value; i ++) {
        values.push(rollDice(faces.value));
      }
      const value = values.reduce((sum, v) => sum + v.value, 0);
      return {op: "dice", value, values, source: {count, faces}, label: diceFormula.label};
    }
    case "reroll": {
      const target = executeDiceFormula(diceFormula.target, context);
      const comparator = diceFormula.comparator;
      const dice = executeDiceFormula(diceFormula.dice, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        const values: DiceRoll[] = [];
        for (let i = 0; i < dice.values.length; i ++) {
          const d = dice.values[i];
          let lastDiceRoll = d;
          const rolls: number[] = [d.value];
          while (compare(lastDiceRoll.value, comparator, target.value)) {
            lastDiceRoll = rollDice(d.faces);
            rolls.push(lastDiceRoll.value);
          }
          const diceRoll: DiceRoll = {value: lastDiceRoll.value, rolls, faces: d.faces};
          values.push(diceRoll);
        }

        const value = values.reduce((sum, v) => sum + v.value, 0);
        return {op: "reroll", value, values, source: {dice, comparator, target}, label: diceFormula.label}
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "reroll-once": {
      const target = executeDiceFormula(diceFormula.target, context);
      const comparator = diceFormula.comparator;
      const dice = executeDiceFormula(diceFormula.dice, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        const values: DiceRoll[] = [];
        for (let i = 0; i < dice.values.length; i ++) {
          const d = dice.values[i];
          let lastDiceRoll = d;
          const rolls: number[] = [d.value];
          if (compare(lastDiceRoll.value, comparator, target.value)) {
            lastDiceRoll = rollDice(d.faces);
            rolls.push(lastDiceRoll.value);
          }
          const diceRoll: DiceRoll = {value: lastDiceRoll.value, rolls, faces: d.faces};
          values.push(diceRoll);
        }

        const value = values.reduce((sum, v) => sum + v.value, 0);
        return {op: "reroll-once", value, values, source: {dice, comparator, target}, label: diceFormula.label}
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "keep-highest": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const count = executeDiceFormula(diceFormula.count, context);

      const values: DiceRoll[] = [];
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        let highestIndices: number[] = [];
        for (let i = 0; i < count.value; i ++) {
          let highestIndex = -1;
          let highestValue = 0;
          for (let j = 0; j < dice.values.length; j ++) {
            if (highestIndices.includes(j)) continue;
            if (dice.values[j].value > highestValue) {
              highestIndex = j;
              highestValue = dice.values[j].value;
            }
          }
          if (highestIndex === -1) break;
          highestIndices.push(highestIndex);
        }

        highestIndices = highestIndices.sort();
        for (let i = 0; i < highestIndices.length; i ++) {
          values.push(dice.values[highestIndices[i]]);
        }
      } else {
        throw new Error("Unsupported Dice Formula");
      }

      let value = values.reduce((sum, v) => sum + v.value, 0);
      return {op: "keep-highest", value, values, source: {dice, count}, label: diceFormula.label};
    }
    case "drop-highest": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const count = executeDiceFormula(diceFormula.count, context);

      const values: DiceRoll[] = [];
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        let lowestIndices: number[] = [];
        for (let i = 0; i < dice.values.length - count.value; i ++) {
          let lowestIndex = -1;
          let lowestValue = Number.POSITIVE_INFINITY;
          for (let j = 0; j < dice.values.length; j ++) {
            if (lowestIndices.includes(j)) continue;
            if (dice.values[j].value < lowestValue) {
              lowestIndex = j;
              lowestValue = dice.values[j].value;
            }
          }
          if (lowestIndex === -1) break;
          lowestIndices.push(lowestIndex);
        }

        lowestIndices = lowestIndices.sort();
        for (let i = 0; i < lowestIndices.length; i ++) {
          values.push(dice.values[lowestIndices[i]]);
        }
      } else {
        throw new Error("Unsupported Dice Formula");
      }

      let value = values.reduce((sum, v) => sum + v.value, 0);
      return {op: "drop-highest", value, values, source: {dice, count}, label: diceFormula.label};
    }
    case "keep-lowest": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const count = executeDiceFormula(diceFormula.count, context);

      const values: DiceRoll[] = [];
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        let lowestIndices: number[] = [];
        for (let i = 0; i < count.value; i ++) {
          let lowestIndex = -1;
          let lowestValue = Number.POSITIVE_INFINITY;
          for (let j = 0; j < dice.values.length; j ++) {
            if (lowestIndices.includes(j)) continue;
            if (dice.values[j].value < lowestValue) {
              lowestIndex = j;
              lowestValue = dice.values[j].value;
            }
          }
          if (lowestIndex === -1) break;
          lowestIndices.push(lowestIndex);
        }

        lowestIndices = lowestIndices.sort();
        for (let i = 0; i < lowestIndices.length; i ++) {
          values.push(dice.values[lowestIndices[i]]);
        }
      } else {
        throw new Error("Unsupported Dice Formula");
      }

      let value = values.reduce((sum, v) => sum + v.value, 0);
      return {op: "keep-lowest", value, values, source: {dice, count}, label: diceFormula.label};
    }
    case "drop-lowest": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const count = executeDiceFormula(diceFormula.count, context);

      const values: DiceRoll[] = [];
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        let highestIndices: number[] = [];
        for (let i = 0; i < values.length - count.value; i ++) {
          let highestIndex = -1;
          let highestValue = 0;
          for (let j = 0; j < dice.values.length; j ++) {
            if (highestIndices.includes(j)) continue;
            if (dice.values[j].value > highestValue) {
              highestIndex = j;
              highestValue = dice.values[j].value;
            }
          }
          if (highestIndex === -1) break;
          highestIndices.push(highestIndex);
        }

        highestIndices = highestIndices.sort();
        for (let i = 0; i < highestIndices.length; i ++) {
          values.push(dice.values[highestIndices[i]]);
        }
      } else {
        throw new Error("Unsupported Dice Formula");
      }

      let value = values.reduce((sum, v) => sum + v.value, 0);
      return {op: "drop-lowest", value, values, source: {dice, count}, label: diceFormula.label};
    }
    case "explode": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const comparator = diceFormula.comparator;
      const target = executeDiceFormula(diceFormula.target, context);

      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        const values: DiceRoll[] = dice.values.map(v => ({value: v.value, rolls: v.rolls, faces: v.faces}));
        for (let i = 0; i < values.length; i ++) {
          if (compare(values[i].value, comparator, target.value)) {
            values.push(rollDice(values[i].faces));
          }
        }
        const value = values.reduce((sum, v) => sum + v.value, 0);
        return {op: "explode", value, values, source: {dice, comparator, target}, label: diceFormula.label};
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "add": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = left.value + right.value;

      return {op: "add", value, source: {left, right}};
    }
    case "subtract": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = left.value - right.value;

      return {op: "subtract", value, source: {left, right}};
    }
    case "multiply": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = left.value * right.value;

      return {op: "multiply", value, source: {left, right}};
    }
    case "divide": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = Math.floor(left.value / right.value);

      return {op: "divide", value, source: {left, right}};
    }
    case "remainder": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = left.value % right.value;

      return {op: "remainder", value, source: {left, right}};
    }
    case "min": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = Math.min(left.value, right.value);

      return {op: "min", value, source: {left, right}, label: diceFormula.label};
    }
    case "max": {
      const left = executeDiceFormula(diceFormula.left, context);
      const right = executeDiceFormula(diceFormula.right, context);
      const value = Math.max(left.value, right.value);

      return {op: "max", value, source: {left, right}, label: diceFormula.label};
    }
    case "parentheses": {
      const expression = executeDiceFormula(diceFormula.expression, context);
      return {op: "parentheses", value: expression.value, source: {expression}, label: diceFormula.label};
    }
    case "count-success": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const target = executeDiceFormula(diceFormula.target, context);
      return {op: "count-success", value: dice.value, source: {dice, comparator: diceFormula.comparator, target}, label: diceFormula.label};
    }
    case "count-failure": {
      const dice = executeDiceFormula(diceFormula.dice, context);
      const target = executeDiceFormula(diceFormula.target, context);
      return {op: "count-failure", value: dice.value, source: {dice, comparator: diceFormula.comparator, target}, label: diceFormula.label};
    }
  }
  return {op: "constant", value: 0};
}
