import {InputGroup, InputGroupColorButton, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {MeasurementBeam, MeasurementBeamOperation, MeasurementBeamRef} from "./measurement-beam.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";

export function MeasurementBeamProperties({valueRef}: {valueRef: MutableRef<MeasurementBeam, MeasurementBeamOperation[]>}) {
  const {stickyRef, fillColorRef, widthRef} = useMemo(() => MeasurementBeamRef(valueRef), [valueRef]);

  return <>
    <InputGroup className="pl-0">
      <InputCheckbox value={stickyRef} />
      <InputGroupLabel>Sticky</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <InputGroupColorButton valueRef={fillColorRef} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Width</InputGroupLabel>
      <InputNumber value={widthRef} />
    </InputGroup>
  </>
}