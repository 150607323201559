import {Loader} from "common/loader";
import {useEffect, useState} from "react";
import {createValueRef, MutableRef, Ref} from "common/ref";
import {pipe} from "common/pipe";
import {Observable, subscribe} from "common/observable";

export function useObservableSignal<V, O>(observe: Observable<V>): Ref<V> | undefined {
  const [loader, setLoader] = useState<Loader<MutableRef<V, O[]>>>(Loader.loading());
  useEffect(() => {
    let signal: MutableRef<V, V> | undefined = undefined;
    setLoader(Loader.loading());
    return pipe(
      observe,
      subscribe(next => {
        if (signal === undefined) {
          signal = createValueRef(next);
          setLoader(Loader.loaded(new MutableRef({
            value() {return signal!.value},
            apply: _ => {throw new Error("Unsupported.")},
            observe: signal!.observe
          })));
        } else {
          signal.apply(_ => next);
        }
      })
    );
  }, [observe, setLoader]);

  return loader.isLoading ? undefined : loader.data;
}
