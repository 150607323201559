import {useCallback} from "react";
import {Node} from "common/legends/node/index.ts";
import {TreeOperation} from "common/types/generic/index.ts";
import {useDatabase} from "../../routes/game/model/store-context.tsx";
import {ScenePath} from "common/legends/reference/scene-path.ts";
import {AssetTokenPath} from "common/legends/reference/asset-token-path.ts";
import {SceneRef} from "./legends/scene-ref.ts";
import {AssetTokenRef} from "./legends/asset-token-ref.ts";
import {AssetRef} from "./legends/asset-ref.ts";

export function useAddElement() {
  const databaseRef = useDatabase();
  return useCallback((reference: ScenePath | AssetTokenPath, node: Node) => {
    if (reference.type === "scene") {
      SceneRef(databaseRef, reference.data.sceneID).apply(scene => {
        if (!scene) return [];
        return [{type: "update-children", operations: TreeOperation.insert([scene.children.length], node)}];
      });
    } else if (reference.type === "asset-token") {
      AssetTokenRef(AssetRef(databaseRef, reference.data.assetID), reference.data.tokenID).apply(token => {
        if (!token) return [];
        return [{type: "update-children", operations: TreeOperation.insert([token.children.length], node)}];
      });
    }
  }, [databaseRef])
}
