import {Editable, ReactEditor, RenderLeafProps, Slate, useSlate, withReact} from "slate-react";
import {BaseEditor, createEditor, Editor} from "slate";
import {useState} from "react";
import {twMerge} from "tailwind-merge";
import {IconButton} from "#lib/components/index.ts";
import {FaBold, FaItalic} from "react-icons/fa6";
import {RichText, RichTextOperation} from "common/types/index.ts";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";

type Text = {
  text: string;
  bold?: boolean;
  italic?: boolean;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Text: Text;
  }
}

export type InputRichTextProps = {
  value: MutableRef<RichText, RichTextOperation[]>;
  title?: string;
  placeholder?: string;
  readOnly?: boolean;
};

function renderLeaf(props: RenderLeafProps) {
  return <span {...props.attributes} style={{
    fontWeight: props.leaf.bold ? "bold" : undefined,
    fontStyle: props.leaf.italic ? "italic": undefined
  }}>
    {props.children}
  </span>
}

const EMPTY_RICH_TEXT: RichText = [{children: [{text: ""}]}];

export function InputRichText({value, title, placeholder, readOnly}: InputRichTextProps) {
  const [editor] = useState(() => withReact(createEditor()));

  const loader = useObservableLoader(value.observe);

  if (!loader.isSuccess) return <></>;

  return <div className="flex flex-col bg-zinc-900/50 border-zinc-900 border-y outline-0 hover:ring-blue-500/20 hover:ring-1 focus-within:ring-1 hover:focus-within:ring-blue-500/50 focus-within:ring-blue-500/50 ring-inset" onClick={() => {
    ReactEditor.focus(editor);
  }}>
    <Slate editor={editor} initialValue={loader.data || EMPTY_RICH_TEXT} onChange={() => {
      if (!editor.operations.some(op => op.type !== "set_selection")) return;
    }}>
      {readOnly !== true && <Toolbar />}
      <Editable readOnly={readOnly} title={title} placeholder={placeholder} className={twMerge(
        "outline-0 px-4 py-2",
        "disabled:hover:ring-blue-500/10 disabled:hover:focus-within:ring-blue-500/10 disabled:focus-within:ring-blue-500/10"
      )} renderLeaf={renderLeaf} onKeyDown={event => {
        if (!event.ctrlKey) return;
        switch (event.key) {
          case "i": {
            event.preventDefault();
            if (Editor.marks(editor)?.italic) Editor.removeMark(editor, "italic");
            else Editor.addMark(editor, "italic", true);
            break;
          }
          case "b": {
            event.preventDefault();
            if (Editor.marks(editor)?.bold) Editor.removeMark(editor, "bold");
            else Editor.addMark(editor, "bold", true);
            break;
          }
        }
      }} onBlur={() => {
        if (editor.children !== loader.data) {
          value.apply(prevValue => [{type: "set", prevValue, nextValue: editor.children}])
        }
      }} />
    </Slate>
  </div>
}

function Toolbar() {
  const editor = useSlate();
  const marks = Editor.marks(editor);

  return <div className="px-1 py-1 bg-zinc-900/70 flex flex-row gap-1 m-[1px]" onMouseDown={(event) => {
    event.stopPropagation();
    event.preventDefault();
  }}>
    <IconButton tabIndex={-1} size="small" variant={marks?.bold ? "primary" : "tertiary"} onClick={() => {
      if (marks?.bold) Editor.removeMark(editor, "bold");
      else Editor.addMark(editor, "bold", true);
    }}><FaBold/></IconButton>
    <IconButton tabIndex={-1} size="small" variant={marks?.italic ? "primary" : "tertiary"} onClick={() => {
      if (marks?.italic) Editor.removeMark(editor, "italic");
      else Editor.addMark(editor, "italic", true);
    }}><FaItalic /></IconButton>
  </div>;
}
