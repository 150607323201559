import {Dnd5eCharacterExperience, Dnd5eCharacterExperienceOperation} from "common/legends/index.ts";
import {Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useApplyCallback} from "#lib/qlab/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type ExperienceProps = {
  value: MutableRef<Dnd5eCharacterExperience, Dnd5eCharacterExperienceOperation[]>;
};
export function Experience({value}: ExperienceProps) {
  const applyToCurrent = useApplyCallback(value.apply, Dnd5eCharacterExperience.getCurrent, Dnd5eCharacterExperienceOperation.updateCurrent)
  const setCurrent = (nextValue: number) => {
    applyToCurrent(prev => [{type: "set", prevValue: prev, nextValue}]);
  };
  const applyToMax = useApplyCallback(value.apply, Dnd5eCharacterExperience.getMax, Dnd5eCharacterExperienceOperation.updateMax)
  const setMax = (nextValue: number) => {
    applyToMax(prev => [{type: "set", prevValue: prev, nextValue}]);
  };

  const {current, max} = useRefValue(value);

  return (<div className="flex flex-row mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <InputGroup>
      <InputGroupLabel>Experience</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <Input className="text-center" type="number" min={0} value={current} placeholder="Current" onChange={ev => setCurrent(Number.parseInt(ev.target.value) || 0)} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>/</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <Input className="text-center" type="number" min={0} value={max} placeholder="Max" onChange={ev => setMax(Number.parseInt(ev.target.value) || 0)} />
    </InputGroup>
  </div>);
}