import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {authClient} from "./auth-client.ts";

export function AuthCallback() {
  const {hash} = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(hash.substring(1));
    authClient.updateToken(params.get("access_token") ?? undefined);
    const previousLocation = localStorage.getItem("authRedirect") || "/";
    localStorage.removeItem("authRedirect");
    window.location.href = previousLocation;
  }, [hash]);

  return <div className="flex flex-1 justify-center pt-10 w-full">
    <div className="bg-zinc-800 py-12 rounded shadow-xl h-max w-full max-w-screen-sm text-center italic text-white/80">
      Authenticating...
    </div>
  </div>
}