import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {Ref, useMemo} from "react";
import {FaTrash} from "react-icons/fa";
import {
  InteractionActionSetToken,
  InteractionActionSetTokenOperation,
  InteractionActionSetTokenSignals
} from "common/legends/node/interaction/action/interaction-action-set-token.ts";
import {InputOptionalSelect} from "#lib/components/input/input-optional-select.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {useAsset} from "../../../../../common/character/use-asset.ts";
import {useAssetViewportProperties} from "../../../../../viewport/character/use-asset-viewport-properties.ts";
import {TokenID} from "common/legends/index.ts";
import {MutableRef} from "common/ref";

export function InputInteractionActionSetToken({dragHandlerRef, value, remove}: {
  value: MutableRef<InteractionActionSetToken, InteractionActionSetTokenOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  remove: () => void;
}) {
  const {tokenID} = useMemo(() => InteractionActionSetTokenSignals(value), [value]);
  const assetViewportPropertiesRef = useAssetViewportProperties();

  const assetID = useComputedValue(assetViewportPropertiesRef, assetViewportProperties => assetViewportProperties?.tokenReference?.assetID);
  const asset = useAsset(assetID)
  const tokenLabels = useComputedValue(asset, asset => Object.fromEntries(Object
    .values((asset?.tokens || {}))
    .map((token) => [token.tokenID, token.name])
  ));
  const tokenIDs = Object.keys(tokenLabels) as TokenID[];

  return <ButtonBar>
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <InputGroup>
      <InputGroupLabel>Set Token</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputOptionalSelect value={tokenID} values={tokenIDs} labels={tokenLabels} />
    </InputGroup>
    <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>
  </ButtonBar>
}