import React, {ForwardedRef} from "react";
import {WebGL2Canvas, WebGL2CanvasProps} from "#lib/gl-react/index.ts";
import {InstanceProvider, useInstance} from "#lib/qlab/index.ts";
import {TextureManagerProvider} from "#lib/qlab/react/hooks/asset/texture-manager.tsx";
import {DatabaseProvider, useDatabase} from "../../model/store-context.tsx";
import {useStoreID} from "../../use-store-id.ts";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {GameProvider, useGame} from "../../model/index.ts";

export const LegendsWebGL2Canvas = React.forwardRef(function LegendsWebGL2Canvas({children, ...props}: WebGL2CanvasProps, ref: ForwardedRef<HTMLCanvasElement>): JSX.Element {
  const instance = useInstance();
  const storeID = useStoreID();
  const database = useDatabase();
  return (<ErrorBoundary>
    <WebGL2Canvas ref={ref} {...props}>
      <InstanceProvider value={instance}>
        <TextureManagerProvider>
          <DatabaseProvider storeID={storeID} database={database}>
            <GameProvider value={useGame()}>
              {children}
            </GameProvider>
          </DatabaseProvider>
        </TextureManagerProvider>
      </InstanceProvider>
    </WebGL2Canvas>
  </ErrorBoundary>)
});
