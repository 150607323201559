import {z} from "zod";
import {ListPropertyRef, ListType, ZodListOperation} from "#common/types/generic/list/index.ts";
import {ObjectType} from "#common/types/generic/object/index.ts";
import {Type} from "#common/types/type/index.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, dnd5eFeatureType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/index.ts";
import {generateDnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {RichTextFn} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eSystem = z.object({
  globalFeatures: z.array(Dnd5eFeature)
});
export type Dnd5eSystem = z.infer<typeof Dnd5eSystem>;

export const Dnd5eSystemOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-global-features"), operations: z.array(ZodListOperation(Dnd5eFeature, Dnd5eFeatureOperation))})
])
export type Dnd5eSystemOperation = z.infer<typeof Dnd5eSystemOperation>;

export const dnd5eSystemType: Type<Dnd5eSystem, Dnd5eSystemOperation> = new ObjectType({
  globalFeatures: new ListType(dnd5eFeatureType)
}, (v: any): Dnd5eSystem => {
  if (v.globalEffects !== undefined) {
    v.globalFeatures = v.globalEffects.map((effect: any): Dnd5eFeature => ({
      title: effect.name,
      enabled: effect.enabled,
      featureID: generateDnd5eFeatureID(),
      effects: [effect],
      resources: [],
      actions: [],
      source: "",
      description: RichTextFn.EMPTY,
      modifiers: []
    }));
    delete v["globalEffects"];
  }
  return v;
}, (op) => {
  if (op.type === "update-global-effects") return [];
  else return [op];
});

export function Dnd5eSystemSignals(value: MutableRef<Dnd5eSystem, Dnd5eSystemOperation[]>) {
  return {
    globalFeatures: ListPropertyRef<Dnd5eSystem, Dnd5eSystemOperation, Dnd5eFeature, Dnd5eFeatureOperation>(
      value => value.globalFeatures,
      operations => [{type: "update-global-features", operations}]
    )(value)
  };
}
