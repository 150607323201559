import React, {useMemo} from "react";
import {Button, ButtonBar, InputGroup, InputGroupLabel, TagListInput} from "#lib/components/index.ts";
import {AssetTreeFile, AssetTreeFileOperation, AssetTreeFileSignals} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";

export type EditAssetModalProps = {
  onClose: () => void;
  value: MutableRef<AssetTreeFile, AssetTreeFileOperation[]>
};

export function EditAssetModal({onClose, value}: EditAssetModalProps) {
  const {name, tags} = useMemo(() => AssetTreeFileSignals(value), [value])

  return (<Modal onClose={onClose}>
    <ModalTitle>Edit Asset</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>
      <TagListInput value={tags} />
    </ModalBody>
    <ButtonBar>
      <Button type="button" className="grow" onClick={onClose}>Cancel</Button>
    </ButtonBar>
  </Modal>)
}