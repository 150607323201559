import {z} from "zod";
import {Dnd5eDefense} from "../dnd-5e-defense.ts";
import {Dnd5eAttribute} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-attribute.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListType,
  ObjectType,
  Optional,
  RichText,
  RichTextOperation,
  richTextType,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType,
  ZodListOperation
} from "#common/types/index.ts";
import {ulid} from "ulid";
import {Dnd5eActionEffect, Dnd5eActionEffectOperation, dnd5eActionEffectType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect.ts";
import {Dnd5eActionModifier, Dnd5eActionModifierOperation, dnd5eActionModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {DND_5E_DAMAGE_TYPES} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {MathExpression, MathExpressionFn} from "#common/math/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionType = z.union([
  z.literal("action"),
  z.literal("bonus action"),
  z.literal("free action"),
  z.literal("trait"),
  z.literal("reaction"),
  z.literal("lair"),
  z.literal("legendary")
]);
export type Dnd5eActionType = z.infer<typeof Dnd5eActionType>;
export const DND_5E_ACTION_TYPES = [
  "action",
  "bonus action",
  "reaction",
  "free action",
  "trait",
  "lair",
  "legendary"
] satisfies Dnd5eActionType[];

export const Dnd5eActionID = z.string().brand("Dnd5eActionID");
export type Dnd5eActionID = z.infer<typeof Dnd5eActionID>;
export function generateDnd5eActionID() {
  return ulid() as Dnd5eActionID;
}

export const Dnd5eAction = z.object({
  actionID: Dnd5eActionID,
  actionType: Dnd5eActionType,
  criticalRange: MathExpression,
  favorite: z.boolean(),
  label: z.string(),
  offense: z.optional(Dnd5eAttribute),
  proficient: z.boolean(),
  defense: z.optional(Dnd5eDefense),
  range: z.string(),
  description: RichText,
  onHit: z.string(),
  onCrit: z.string(),
  onMiss: z.string(),
  modifiers: z.array(Dnd5eActionModifier),
  actionEffects: z.array(Dnd5eActionEffect)
});


export type Dnd5eAction = z.infer<typeof Dnd5eAction>;

export const Dnd5eActionFn = {
  updateActionType: (operations: ValueOperation<Dnd5eActionType, ConstantOperation>[]): Dnd5eActionOperation[] => [{type: "update-action-type", operations}],
  updateLabel: (operations: StringOperation[]): Dnd5eActionOperation[] => [{type: "update-label", operations}],
  updateRange: (operations: StringOperation[]): Dnd5eActionOperation[] => [{type: "update-range", operations}],
  updateDescription: (operations: RichTextOperation[]): Dnd5eActionOperation[] => [{type: "update-description", operations}],
  updateOnHit: (operations: StringOperation[]): Dnd5eActionOperation[] => [{type: "update-on-hit", operations}],
  updateOnMiss: (operations: StringOperation[]): Dnd5eActionOperation[] => [{type: "update-on-miss", operations}],
  updateOffense: (operations: ValueOperation<Optional<Dnd5eAttribute>, ConstantOperation>[]): Dnd5eActionOperation[] => [{type: "update-offense", operations}],
  updateDefense: (operations: ValueOperation<Optional<Dnd5eDefense>, ConstantOperation>[]): Dnd5eActionOperation[] => [{type: "update-defense", operations}]
};

export const Dnd5eActionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-action-type"), operations: z.array(ValueOperation(Dnd5eActionType, ConstantOperation))}),
  z.object({type: z.literal("update-critical-range"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))}),
  z.object({type: z.literal("update-favorite"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-label"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-description"), operations: z.array(RichTextOperation)}),
  z.object({type: z.literal("update-offense"), operations: z.array(ValueOperation(z.optional(Dnd5eAttribute), ConstantOperation))}),
  z.object({type: z.literal("update-defense"), operations: z.array(ValueOperation(z.optional(Dnd5eDefense), ConstantOperation))}),
  z.object({type: z.literal("update-range"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-on-hit"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-on-crit"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-on-miss"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-proficient"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionModifier, Dnd5eActionModifierOperation))}),
  z.object({type: z.literal("update-action-effects"), operations: z.array(ZodListOperation(Dnd5eActionEffect, Dnd5eActionEffectOperation))}),
]);
export type Dnd5eActionOperation = z.infer<typeof Dnd5eActionOperation>;

export const dnd5eActionType: Type<Dnd5eAction, Dnd5eActionOperation> = new ObjectType({
  actionID: constantType,
  actionType: new ValueType(constantType),
  criticalRange: new ValueType(constantType),
  favorite: booleanType,
  label: stringType,
  description: richTextType,
  proficient: booleanType,
  offense: new ValueType(constantType),
  defense: new ValueType(constantType),
  range: stringType,
  onHit: stringType,
  onCrit: stringType,
  onMiss: stringType,
  modifiers: new ListType(dnd5eActionModifierType),
  actionEffects: new ListType(dnd5eActionEffectType)
}, value => {
  let v = {...value};
  if (v["modifiers"] === undefined) {
    v.modifiers = [];
    for (const modifier of v["damageRollModifiers"]) {
      v.modifiers.push({
        type: "damage-roll",
        data: {
          modifierID: modifier.modifierID,
          damageType: DND_5E_DAMAGE_TYPES.includes(modifier.damageType) ? modifier.damageType : undefined,
          hitExpression: modifier.hitExpression,
          critExpression: modifier.critExpression
        }
      } as Dnd5eActionModifier);
    }
    delete v["damageRollModifiers"];
    for (const modifier of v["attackRollModifiers"]) {
      v.modifiers.push({
        type: "attack-roll",
        data: {
          modifierID: modifier.modifierID,
          expression: modifier.expression
        }
      } satisfies Dnd5eActionModifier);
    }
    delete v["attackRollModifiers"];
    for (const modifier of v["dCModifiers"]) {
      v.modifiers.push({
        type: "difficulty-class",
        data: {
          modifierID: modifier.modifierID,
          expression: modifier.expression
        }
      } satisfies Dnd5eActionModifier);
    }
    delete v["dCModifiers"];
  }
  if (v["actionEffects"] === undefined) {
    v.actionEffects = [];
    for (const effect of v["actionModifiers"]) {
      let effectModifiers = [];
      for (const modifier of effect["attackRollModifiers"]) {
        effectModifiers.push({
          type: "attack-roll",
          data: {
            modifierID: modifier.modifierID,
            expression: modifier.expression
          }
        } satisfies Dnd5eActionModifier);
      }
      delete effect["attackRollModifiers"];
      for (const modifier of effect["dCModifiers"]) {
        effectModifiers.push({
          type: "difficulty-class",
          data: {
            modifierID: modifier.modifierID,
            expression: modifier.expression
          }
        } satisfies Dnd5eActionModifier);
      }
      delete effect["dCModifiers"];
      for (const modifier of effect["damageRollModifiers"]) {
        effectModifiers.push({
          type: "damage-roll",
          data: {
            modifierID: modifier.modifierID,
            damageType: DND_5E_DAMAGE_TYPES.includes(modifier.damageType) ? modifier.damageType : undefined,
            hitExpression: modifier.hitExpression,
            critExpression: modifier.critExpression
          }
        } as Dnd5eActionModifier);
      }
      delete effect["damageRollModifiers"];
      v.actionEffects.push({
        actionEffectID: effect.modifierID,
        name: effect.name,
        enabled: effect.enabled,
        modifiers: effectModifiers
      } as Dnd5eActionEffect);
    }
    delete v["actionModifiers"];
  }
  if (v.favorite === undefined) v.favorite = false;
  if (v.criticalRange === undefined || typeof v.criticalRange === "number") v.criticalRange = MathExpressionFn.assertMathExpression("20");
  return v;
});


export function Dnd5eActionSignals(value: MutableRef<Dnd5eAction, Dnd5eActionOperation[]>) {
  return {
    label: PropertyRef<Dnd5eAction, Dnd5eActionOperation, string, StringOperation>(
      value => value.label,
      operations => [{type: "update-label", operations}]
    )(value),
    criticalRange: PropertyRef<Dnd5eAction, Dnd5eActionOperation, MathExpression, ValueOperation<MathExpression, ConstantOperation>>(
      value => value.criticalRange,
      operations => [{type: "update-critical-range", operations}]
    )(value),
    favorite: PropertyRef<Dnd5eAction, Dnd5eActionOperation, boolean, BooleanOperation>(
      value => value.favorite,
      operations => [{type: "update-favorite", operations}]
    )(value),
    description: PropertyRef<Dnd5eAction, Dnd5eActionOperation, RichText, RichTextOperation>(
      value => value.description,
      operations => [{type: "update-description", operations}]
    )(value),
    range: PropertyRef<Dnd5eAction, Dnd5eActionOperation, string, StringOperation>(
      value => value.range,
      operations => [{type: "update-range", operations}]
    )(value),
    defense: PropertyRef<Dnd5eAction, Dnd5eActionOperation, Optional<Dnd5eDefense>, ValueOperation<Optional<Dnd5eDefense>, ConstantOperation>>(
      value => value.defense,
      operations => [{type: "update-defense", operations}]
    )(value),
    proficient: PropertyRef<Dnd5eAction, Dnd5eActionOperation, boolean, BooleanOperation>(
      value => value.proficient,
      operations => [{type: "update-proficient", operations}]
    )(value),
    modifiers: ListPropertyRef<Dnd5eAction, Dnd5eActionOperation, Dnd5eActionModifier, Dnd5eActionModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(value),
    actionEffects: ListPropertyRef<Dnd5eAction, Dnd5eActionOperation, Dnd5eActionEffect, Dnd5eActionEffectOperation>(
      value => value.actionEffects,
      operations => [{type: "update-action-effects", operations}]
    )(value),
    onHit: PropertyRef<Dnd5eAction, Dnd5eActionOperation, string, StringOperation>(
      value => value.onHit,
      operations => [{type: "update-on-hit", operations}]
    )(value),
    onMiss: PropertyRef<Dnd5eAction, Dnd5eActionOperation, string, StringOperation>(
      value => value.onMiss,
      operations => [{type: "update-on-miss", operations}]
    )(value),
    onCrit: PropertyRef<Dnd5eAction, Dnd5eActionOperation, string, StringOperation>(
      value => value.onCrit,
      operations => [{type: "update-on-crit", operations}]
    )(value),
  };
}

export function getActionID(value: Dnd5eAction): Dnd5eActionID {
  return value.actionID;
}
