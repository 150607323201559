import {AssetViewportPropertiesSignals, useAssetToken, useEditorSelectedNodeIDs} from "./index.ts";
import {NodeDropZone, useTempRef} from "#lib/components/index.ts";
import React, {Suspense, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Grid, Node, PlayerRef, TokenSignals} from "common/legends/index.ts";
import {Color, HSLA, Optional, Point, Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useInstance, useObservable} from "#lib/qlab/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {pipe} from "common/pipe";
import {distinct, map, toPromise} from "common/observable";
import {measurementType} from "common/legends/measurement/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useAssetViewportProperties} from "./use-asset-viewport-properties.ts";
import {EditorProvider, useEditor} from "../../container/editor/editor-context.ts";
import {AssetTokenSelectionRef, SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {contextMenuType} from "../../../routes/game/context-menu/context-menu.ts";
import {usePlayer} from "../../../routes/game/use-player.ts";
import {useToolHandlers} from "../../../routes/game/use-tool-handlers.ts";
import {SheetReference} from "../../common/sheet/sheet-reference.ts";
import {LegendsWebGL2Canvas} from "../../../routes/game/index.ts";
import {SheetReferenceProvider} from "../../common/sheet/sheet-reference-context.ts";
import {SelectedNodeIDsProvider} from "../common/node/selected-node-i-ds.ts";
import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {MeasurementView} from "../../common/measurement/measurement-view.tsx";
import {HealthIndicatorView} from "../common/node/node-view/health-indicator-view.tsx";
import {SelectionIndicator} from "../common/node/node-view/selection-indicator.tsx";
import {NodeView} from "../common/node/node-view/index.ts";
import {GridProvider} from "../common/node/node-view/grid-view/grid-context.ts";
import {useSheetSignal} from "../../common/sheet/use-sheet-signal.ts";
import {MutableRef, NOOP_SIGNAL, Ref} from "common/ref";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useActiveController} from "../token/use-active-controller-node-reference.ts";
import {useNode} from "../token/use-node.ts";
import {getNodesOrigin, useNodeWalls} from "../common/node/layer-view/scene-view.tsx";
import {WallShader} from "../../common/wall-shader.tsx";
import {ToolView} from "../common/tool/tool-view.tsx";
import {useEditorTool} from "../../container/editor/tool-mode/use-editor-tool.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";
import {EditorContextMenu} from "../../container/editor/editor-context-menu/editor-context-menu.tsx";

export function AssetViewport() {
  const assetViewportPropertiesRef = useSuspendPresent(useAssetViewportProperties());
  const {tokenReference, visibilityMask, view} = useMemo(() => AssetViewportPropertiesSignals(assetViewportPropertiesRef), [assetViewportPropertiesRef]);
  const {assetID} = useRefValue(tokenReference);
  const asset = useSuspendPresent(useAsset(assetID));
  const token = useSuspendPresent(useAssetToken(asset, useRefValue(tokenReference).tokenID));
  const {children} = useMemo(() => TokenSignals(token), [token]);

  const [size, setSize] = useState<[number, number]>([0, 0]);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = divRef?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setSize([element.clientWidth, element.clientHeight]);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [setSize]);


  const canvasRef = useRef<HTMLCanvasElement>(null);

  const editor = useEditor();
  const rootSelectionRef = useObservable<AssetTokenSelectionRef>(pipe(
    tokenReference.observe,
    map((reference): AssetTokenSelectionRef => ({
      type: "asset-token",
      storeId: reference.gameID,
      resourceId: reference.assetID,
      tokenId: reference.tokenID
    })),
    distinct<AssetTokenSelectionRef>(SelectionRef.equals)
  ), () => {
    if (editor.state.value?.type === "asset") {
      const reference = editor.state.value.data.tokenReference;
      return {type: "asset-token", storeId: reference.gameID, resourceId: reference.assetID, tokenId: reference.tokenID};
    } else throw new Error("Invalid Type.");
  }, [tokenReference]);

  const instance = useInstance();
  const ping = useCallback(async (p: Point, focus: boolean) => {
    const reference = await toPromise(tokenReference.observe);
    instance.stream(reference.gameID, {type: "ping", data: {
      type: "character",
      assetID: reference.assetID,
      tokenID: reference.tokenID,
      position: p,
      focus
    }}).catch(console.error);
  }, [instance, tokenReference]);

  const accessMaskValue = useObservable(visibilityMask.observe, AccessMaskFn.DEFAULT, [visibilityMask.observe]);
  const measurement = useTempRef(measurementType, undefined);

  const selectedNodeIDs = useEditorSelectedNodeIDs();
  const viewValue = useObservable(view.observe, Transform.DEFAULT, [view]);
  const tool = useEditorTool();
  const contextMenu = useTempRef(contextMenuType, undefined);

  const player = usePlayer(useUserID()!);
  const {color} = useMemo(() => PlayerRef(player), [player]);

  const isAccessible = useCallback((node: Node) => node.type === "wall" || node.type === "area" || (node.data.selectionMask & accessMaskValue) > 0, [accessMaskValue]);
  const isVisible = useCallback((node: Node) => AccessMaskFn.canSee(accessMaskValue, node.data.visibilityLayer), [accessMaskValue]);
  const {onDrop, onWheel, onTouchStart, onTouchMove, onTouchEnd, ...toolHandlers} = useToolHandlers(
    canvasRef,
    rootSelectionRef,
    view,
    measurement,
    Grid.DEFAULT,
    children,
    selectedNodeIDs,
    NOOP_SIGNAL,
    tool,
    ping,
    useObservable(color.observe, [0, 1, 1, 1] as HSLA, [color]),
    contextMenu,
    isAccessible,
    isVisible
);

  const projection = useMemo<Matrix4f>(() => Matrix4f.orthogonal(
    -size[0]/2, size[0]/2,
    size[1]/2, -size[1]/2,
    -100, 100
  ), [size]);

  const tokenOrigin = useComputedValue(token, token => token.origin);
  const tokenPivot = useComputedValue(token, token => token.pivot);
  const tokenSize = useComputedValue(token, token => token.size);

  const sheetID = useComputedValue(token, token => token.sheetId);
  const sheetReference = useMemo((): Ref<Optional<SheetReference>> => {
    return new MutableRef({
      value(): Optional<SheetReference> {
        return sheetID ? {type: "link", assetID: tokenReference.value.assetID, sheetID} : undefined;
      },
      observe: pipe(
        tokenReference.observe,
        map((tokenReference): SheetReference | undefined => sheetID
          ? ({type: "link", assetID: tokenReference.assetID, sheetID})
          : undefined
        ),
        distinct()
      ),
      apply: () => Promise.reject("Unsupported")
    });
  }, [tokenReference.observe, sheetID]);
  const sheet = useSheetSignal(sheetReference);

  useEffect(() => {
    const onBodyWheel = (ev: WheelEvent) => {
      if (ev.ctrlKey) return onWheel(ev);
      return true;
    };
    document.body.addEventListener("wheel", onBodyWheel, {passive: false});
    canvasRef.current?.addEventListener("wheel", onWheel, {passive: false});
    canvasRef.current?.addEventListener("touchstart", onTouchStart, {passive: false});
    canvasRef.current?.addEventListener("touchend", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchcancel", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchmove", onTouchMove, {passive: false});
    return () => {
      document.body.removeEventListener("wheel", onBodyWheel);
      canvasRef.current?.removeEventListener("wheel", onWheel);
      canvasRef.current?.removeEventListener("touchstart", onTouchStart);
      canvasRef.current?.removeEventListener("touchend", onTouchEnd);
      canvasRef.current?.removeEventListener("touchmove", onTouchMove);
      canvasRef.current?.removeEventListener("touchcancel", onTouchEnd);
    }
  }, [canvasRef, onWheel, onTouchStart, onTouchMove, onTouchEnd]);

  const childrenValue = useRefValue(children);
  const walls = useNodeWalls(childrenValue, accessMaskValue, []);
  const activeController = useActiveController();
  const activeNodeID = useComputedValue(activeController, activeController => activeController?.controllerNodeID);
  const isActiveControllerToken = useComputedValue(useNode(activeNodeID), node => node?.type === "token" && node.data.accessMask !== 0);
  const origin = (activeNodeID && isActiveControllerToken) ? getNodesOrigin(activeNodeID, childrenValue) : undefined;

  return (<div className="flex-1 overflow-hidden" ref={divRef}>
    <NodeDropZone storeId={rootSelectionRef.storeId} onDrop={onDrop} contextMenu={contextMenu}>
      <LegendsWebGL2Canvas ref={canvasRef} width={size[0]} height={size[1]} {...toolHandlers} tabIndex={0}>
        <Suspense fallback={<></>}>
          <EditorProvider value={useEditor()}>
            <SheetReferenceProvider value={sheetReference}>
              <SelectedNodeIDsProvider value={selectedNodeIDs}>
                <GridProvider value={Grid.DEFAULT}>
                  <ClearCanvas width={size[0]} height={size[1]} />
                  <HealthIndicatorView projection={projection} view={viewValue} model={Transform.DEFAULT} sheetRef={sheet} origin={tokenOrigin} size={tokenSize} displayNumber={true} />
                  {childrenValue.map((node) => {
                    return (<NodeView key={node.data.id} projection={projection} view={viewValue} visibilityMask={accessMaskValue} accessNodeIDs={[]} model={Transform.DEFAULT} value={node} opacity={1} />);
                  })}
                  <SelectionIndicator projection={projection} view={viewValue} model={Transform.DEFAULT} origin={[3, 3]} size={[6, 6]} color={Color.WHITE} repeatX={1} repeatY={1} />
                  <SelectionIndicator projection={projection} view={viewValue} model={Transform.DEFAULT} origin={tokenOrigin} size={tokenSize} color={Color.WHITE} repeatX={1} repeatY={1} />
                  <SelectionIndicator projection={projection} view={viewValue} model={Transform.DEFAULT} origin={[
                    tokenOrigin[0] - tokenPivot[0],
                    tokenOrigin[1] - tokenPivot[1]
                  ]} size={[4, 4]} color={Color.GREEN} repeatX={1} repeatY={1} />
                  <MeasurementView projection={projection} view={viewValue} measurementRef={measurement} />
                  <ToolView projection={projection} view={viewValue} value={tool} nodes={children} />
                  {origin && <WallShader projection={projection} view={viewValue} model={Transform.DEFAULT} lines={walls} origin={origin} />}
                </GridProvider>
              </SelectedNodeIDsProvider>
            </SheetReferenceProvider>
          </EditorProvider>
        </Suspense>
      </LegendsWebGL2Canvas>
    </NodeDropZone>
    <EditorContextMenu value={contextMenu} onDrop={onDrop} />
  </div>);
}
