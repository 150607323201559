import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2DrawElements extends WebGL2Node {
  public mode?: GLenum;
  public count?: GLsizei;
  public type?: GLenum;
  public offset?: GLintptr;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.mode === undefined) throw new Error("mode must be provided.");
    if (this.count === undefined) throw new Error("count must be provided.");
    if (this.type === undefined) throw new Error("type must be provided.");
    if (this.offset === undefined) throw new Error("offset must be provided.");

    this.context.drawElements(this.mode, this.count, this.type, this.offset);
  }
}
