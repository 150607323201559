import {useMemo} from "react";
import {useObservable, useObservableLoader} from "#lib/qlab/index.ts";
import {SceneFn} from "common/legends/index.ts";
import {Outliner, OutlinerOperation, OutlinerSignals} from "../../common/outliner/outliner.ts";
import {OutlinerView} from "../../common/outliner/outliner-view.tsx";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {SceneReference} from "common/legends/scene/scene-reference.ts";
import {SceneSelectionRef, SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {useEditorSelectedNodeIDs} from "../../viewport/character";
import {MutableRef} from "common/ref";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";
import {useSceneViewportProperties} from "../../viewport/scene/use-scene-viewport-properties.ts";
import {SceneViewportPropertiesSignals} from "../../viewport/scene";
import {useScene} from "../../viewport/token/use-scene.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function SceneOutlinerView({valueRef}: {
  valueRef: MutableRef<Outliner, OutlinerOperation[]>;
}) {
  const sceneViewportPropertiesRef = useSuspendPresent(useSceneViewportProperties());
  const {sceneReference} = useMemo(() => SceneViewportPropertiesSignals(sceneViewportPropertiesRef), [sceneViewportPropertiesRef]);
  const scene = useSuspendPresent(useScene(useRefValue(sceneReference).sceneID));

  const {expanded} = useMemo(() => OutlinerSignals(valueRef), [valueRef]);
  const {children} = useMemo(() => SceneFn.expand(scene), [scene]);

  const sceneReferenceLoader = useObservableLoader(useMemo(() => pipe(
    sceneReference.observe,
    map((sceneReference: SceneReference): SceneSelectionRef => ({type: "scene", storeId: sceneReference.gameID, resourceId: sceneReference.sceneID})),
    distinct<SceneSelectionRef>(SelectionRef.equals)
  ), [sceneReference]));
  const nodes = useObservable(children.observe, [], [children.observe]);

  const selectedNodeIDs = useEditorSelectedNodeIDs();
  const selectedNodeIDsLoader = useObservable(selectedNodeIDs.observe, [], [selectedNodeIDs.observe]);

  if (sceneReferenceLoader.isLoading) return <></>
  return (<OutlinerView
    rootSelectionRef={sceneReferenceLoader.data}
    value={nodes}
    apply={children.apply}
    expanded={expanded}
    selectedNodeIds={selectedNodeIDsLoader}
    applyToSelectedNodeIds={selectedNodeIDs.apply}
  />);
}
