import {decrypt, DecryptPrivateKey, encrypt, PublicKey} from "../../../crypto/index.ts";
import {Optional} from "../optional/index.ts";
import {z, ZodType, ZodTypeAny} from "zod";

const Audience = z.record(z.string(), z.string(), {});
type Audience = z.infer<typeof Audience>;
export type Encrypted<Value> = {
  audience: Audience,
  default: Optional<Value>
};
export function Encrypted<T extends ZodTypeAny>(schema: T): ZodType<Encrypted<z.infer<T>>> {
  return z.object({
    audience: Audience,
    default: z.optional(schema)
  }) as ZodType<Encrypted<z.infer<T>>>;
}

export async function encryptValue<Value>(audPublicKeys: {[aud: string]: PublicKey}, fn: (aud: string | undefined) => Optional<Value>): Promise<Encrypted<Value>> {
  const audience = (await Promise.all(
    Object.entries(audPublicKeys)
      .flatMap(([aud, publicKey]) => {
        const value = fn(aud);
        if (value === undefined) return [];
        return [
          encrypt(JSON.stringify(value), publicKey)
          .then(encryptedValue => [aud, encryptedValue])
        ];
      })
    ))
    .reduce((map, [name, encryptedValue]) => {
      map[name] = encryptedValue;
      return map;
    }, {} as {[aud: string]: string});
  return {
    audience: audience,
    default: fn(undefined)
  };
}

export async function decryptValue<Value>(encryptedValue: Encrypted<Value>, aud: string, privateKey: DecryptPrivateKey): Promise<Value> {
  if (encryptedValue.audience[aud]) {
    const userEncryptedValue = encryptedValue.audience[aud];
    if (userEncryptedValue === undefined) return Promise.reject();
    return JSON.parse(await decrypt(userEncryptedValue, privateKey));
  } else {
    const defaultValue = encryptedValue.default;
    if (!defaultValue) throw new Error("Cannot decrypt value.");
    return defaultValue;
  }
}

export function canDecrypt<Value>(aud: string, encryptedValue: Encrypted<Value>) {
  return (encryptedValue.audience[aud] || encryptedValue.default);
}
