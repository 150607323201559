import {useRenderingContext} from "./rendering-context.ts";
import {useMemo} from "react";

export function useBindVertexElementArrayBuffer(vao: WebGLVertexArrayObject, vbo: WebGLBuffer): void {
  const context = useRenderingContext();
  useMemo((): void => {
    context.bindVertexArray(vao);
    context.bindBuffer(WebGL2RenderingContext.ELEMENT_ARRAY_BUFFER, vbo);
    context.bindVertexArray(null);
  }, [context, vao, vbo]);
}
