import {Menu} from "@headlessui/react";
import {IconButton} from "#lib/components/button/index.ts";
import {FaEllipsis} from "react-icons/fa6";
import {Popper} from "#lib/components/popper/popper.tsx";
import React, {PropsWithChildren, useState} from "react";
import {usePopper} from "react-popper";
import * as PopperJS from "@popperjs/core";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";

export function ExpandOptions({children, options}: PropsWithChildren<{
  options?: Omit<Partial<PopperJS.Options>, 'modifiers'>
}>) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    ...options
  });

  return (<Menu>
    <Menu.Button as={IconButton} title={"More Options..."} ref={ref => setReferenceElement(ref)}><FaEllipsis /></Menu.Button>
    <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper}>
      <Popper className="m-0">
        {children}
      </Popper>
    </Menu.Items>
  </Menu>);
}
