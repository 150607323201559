import {NumberFn, NumberOperation, PropertyRef} from "#common/types/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const Point = z.tuple([z.number(), z.number()]);
export type Point = z.infer<typeof Point>;

export const PointOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-x"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-y"), operations: z.array(NumberOperation)})
]);
export type PointOperation = z.infer<typeof PointOperation>;


export const PointFn = {
  ZERO: [0, 0] as Point,
  equals: (a: Point, b: Point): boolean => a[0] === b[0] && a[1] === b[1],
  set: (prev: Point, next: Point): PointOperation[] => {
    const ops: PointOperation[] = [];
    if (prev[0] !== next[0]) ops.push({type: "update-x", operations: NumberFn.set(prev[0], next[0])})
    if (prev[1] !== next[1]) ops.push({type: "update-y", operations: NumberFn.set(prev[1], next[1])});
    return ops;
  }
} as const;

export function PointSignals(value: MutableRef<Point, PointOperation[]>) {
  return ({
    x: PropertyRef<Point, PointOperation, number, NumberOperation>(
      value => value[0],
      operations => [{type: "update-x", operations}]
    )(value),
    y: PropertyRef<Point, PointOperation, number, NumberOperation>(
      value => value[1],
      operations => [{type: "update-y", operations}]
    )(value)
  });
}
