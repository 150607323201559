import {DialogID} from "../../../modal/index.ts";
import "./resizer.css";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";

export type DialogTopRightResizerProps = {
  dialogId: DialogID;
  minimized: boolean;
};

export function DialogTopRightResizer({dialogId, minimized}: DialogTopRightResizerProps) {
  const [, drag, dragPreviewRef] = useDrag(() => ({
    type: `legends/dialog-resize`,
    item: {dialogId, direction: "right"},
    options: {dropEffect: "move"}
  }), [dialogId]);
  dragPreviewRef(EMPTY_IMAGE);
  return (<div ref={drag} className={`dialog-top-right-resizer ${minimized ? "minimized" : "expanded"}`} />);
}
