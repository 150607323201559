import {Color, FileReference, Point, Transform, Tree} from "common/types/index.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import React, {useMemo} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {WallToolSelectMode} from "../../../common/tool-mode/wall/wall-tool-select-mode.ts";
import {getLines} from "../node/layer-view/walls.ts";
import {WallLineShader} from "../../../common/measurement/wall-line-shader.tsx";
import {Tool, ToolOperation} from "../../../common/tool-mode/tool.ts";
import {VertexSelectionIndicator} from "../node/node-view/vertex-selection-indicator.tsx";
import {ImageShader} from "../node/node-view/image-shader.tsx";
import {useImageTexture} from "../node/node-view/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {cubicBezier, lerpBezier, perpendicularBezier} from "../node/layer-view/bezier.ts";
import {LineShader} from "../../../common/measurement/line-shader.tsx";
import {MutableRef, Ref} from "common/ref";

import faEyeSlash from "./eye-slash.png";
import faEyeSolid from "./eye-solid.png";

type WallGraphVertexNodeProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;
  nodeID: NodeId,
  selection: WallToolSelectMode;
  nodes: Ref<Node[]>;
};

function WallGraphVertexMode({nodeID, projection, view, model, nodes, selection}: WallGraphVertexNodeProps) {
  const point = useComputedValue(nodes, nodes => {
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "wall") return undefined;
    if (selection?.type === "vertex") {
      return node.data.graph.vertices[selection.vertexIndex];
    }
    return undefined;
  }, [selection?.type === "vertex" && selection.vertexIndex]);

  return <>
    {point && <VertexSelectionIndicator projection={projection} view={view} model={model} origin={point.coordinate} />}
  </>
}

type WallGraphEdgeNodeProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;
  nodeID: NodeId,
  selection: WallToolSelectMode;
  nodes: Ref<Node[]>;
};

function WallGraphEdgeMode({nodeID, projection, view, model, nodes, selection}: WallGraphEdgeNodeProps) {
  const graph = useComputedValue(nodes, nodes => {
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "wall") return undefined;
    return node.data.graph;
  });
  const edge = useMemo(() => {
    if (graph === undefined) return undefined;
    if (selection?.type === "edge" || selection?.type === "control-point") {
      return graph.edges[selection.edgeIndex];
    } else return undefined;
  }, [graph, selection?.type === "edge" && selection.edgeIndex])
  const [isInvisibleLoaded, invisibleImage] = useImageTexture(faEyeSlash as FileReference);
  const [isVisibleLoaded, visibleImage] = useImageTexture(faEyeSolid as FileReference);


  const lines = useMemo(() => {
    if (graph === undefined || edge === undefined) return undefined;
    return cubicBezier(
      graph.vertices[edge.start].coordinate,
      graph.vertices[edge.end].coordinate,
      edge.controlPoint1,
      edge.controlPoint2,
      edge.resolution,
      !edge.data.blockVisibilityLeft,
      !edge.data.blockVisibilityRight
    );
  }, [graph, edge]);

  const visibilityIndicators = useMemo((): [Point, Point] | undefined => {
    if (graph === undefined || edge === undefined) return undefined;
    const a = graph.vertices[edge.start].coordinate;
    const b = graph.vertices[edge.end].coordinate;
    const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    return [
      Vector2.add(mid, Vector2.multiply(p, -48)),
      Vector2.add(mid, Vector2.multiply(p, 48))
    ];
  }, [graph, edge]);


  const controlPoints = useMemo((): [Point, Point] | undefined => {
    if (graph === undefined || edge === undefined) return undefined;
    const start = graph.vertices[edge.start].coordinate;
    const end = graph.vertices[edge.end].coordinate;
    return [
      Vector2.add(start, edge.controlPoint1),
      Vector2.add(end, edge.controlPoint2),
    ];
  }, [graph, edge]);


  return <>
    {lines && lines.length > 0 && <>
      <WallLineShader projection={projection} view={view} model={model} lines={lines} scale={1} color={Color.WHITE} opacity={0.25} editMode/>
      {edge && visibilityIndicators && isVisibleLoaded && isInvisibleLoaded && <ImageShader projection={projection} model={model} view={view} imageTexture={edge.data.blockVisibilityLeft ? invisibleImage : visibleImage} size={[32, 32]} origin={
        Vector2.add(
          Vector2.subtract([0, 0], visibilityIndicators[0]),
          [16, 16]
        )} opacity={0.9} repeatX={1} repeatY={1} />}
      {edge && visibilityIndicators && isVisibleLoaded && isInvisibleLoaded && <ImageShader projection={projection} model={model} view={view} imageTexture={edge.data.blockVisibilityRight ? invisibleImage : visibleImage} size={[32, 32]} origin={
        Vector2.add(Vector2.subtract([0, 0], visibilityIndicators[1]), [16, 16])} opacity={0.9} repeatX={1} repeatY={1} />}
      {graph && edge && controlPoints && <>
        <LineShader projection={projection} view={view} model={model} start={graph.vertices[edge.start].coordinate} end={controlPoints[0]} scale={1} color={Color.BLACK75} />
        <LineShader projection={projection} view={view} model={model} start={graph.vertices[edge.end].coordinate} end={controlPoints[1]} scale={1} color={Color.BLACK75} />
        <VertexSelectionIndicator projection={projection} view={view} model={model} origin={controlPoints[0]} />
        <VertexSelectionIndicator projection={projection} view={view} model={model} origin={controlPoints[1]} />
      </>}
    </>}
  </>
}

export function WallToolView({projection, view, value, nodes}: {
  projection: Matrix4f;
  view: Transform;
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
}) {
  const wallTool = useComputedValue(value, value => value.type === "wall" ? value.data : undefined);
  if (wallTool === undefined) return <></>
  if (wallTool.mode?.type === "create") {
    return <>{wallTool.mode.data.spline && <WallLineShader projection={projection} view={view} model={Transform.DEFAULT} lines={getLines(wallTool.mode.data.spline, 8)} scale={1} color={Color.WHITE} opacity={0.75} />}</>;
  } else if (wallTool.mode?.type === "destroy") {
    return <>{wallTool.mode.data.spline && <WallLineShader projection={projection} view={view} model={Transform.DEFAULT} lines={getLines(wallTool.mode.data.spline, 8)} scale={1} color={Color.RED} opacity={0.75} />}</>;
  } else if (wallTool.mode?.type === "slicer") {
    return <>{wallTool.mode.data.spline && <WallLineShader projection={projection} view={view} model={Transform.DEFAULT} lines={getLines(wallTool.mode.data.spline, 8)} scale={1} color={Color.BLUE} opacity={0.75} />}</>;
  } else if (wallTool.mode?.type === "select" && wallTool.mode.data?.type === "vertex") {
    return <WallGraphVertexMode projection={projection} view={view} model={Transform.DEFAULT} nodeID={wallTool.nodeID} selection={wallTool.mode.data} nodes={nodes} />
  } else if (wallTool.mode?.type === "select" && (wallTool.mode.data?.type === "edge" || wallTool.mode.data?.type === "control-point")) {
    return <WallGraphEdgeMode projection={projection} view={view} model={Transform.DEFAULT} nodeID={wallTool.nodeID} selection={wallTool.mode.data} nodes={nodes} />
  } else {
    return <></>
  }
}
