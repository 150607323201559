import {Node, NodeId, UserID} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {useNode} from "../../viewport/token/use-node.ts";
import {useComputedValue} from "#lib/signal/index.ts";

export function isPartyNode(node: Optional<Node>) {
  if (node?.type !== "token") return false;
  return node.data.ownerIDs.length > 0;
}
export function isNodeOwner(node: Optional<Node>, userID: UserID) {
  if (node?.type !== "token") return false;
  if (node.data.ownerIDs.includes("GLOBAL" as UserID)) return true;
  return node.data.ownerIDs.includes(userID);
}

export function useIsPartyNode(nodeID: Optional<NodeId>): boolean {
  return useComputedValue(useNode(nodeID), isPartyNode);
}
