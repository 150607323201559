import {Observer, Subscription} from "#common/observable/observable.ts";
import {MutableRef, Ref} from "../ref.ts";

export function createRef<Value>(initialValue: Value): Ref<Value> {
  return new MutableRef<Value, never[]>({
    value() {return initialValue},
    observe: (observer: Observer<Value>): Subscription => {
      observer.next(initialValue);
      return () => {};
    },
    apply: async () => {
      throw new Error("Unsupported.");
    }
  });
}
