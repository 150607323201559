import {ExtractOperation, ExtractValue, MultiType, Type} from "common/types/index.ts";
import {PlayerSheetStack, PlayerSheetStackOperation, playerSheetStackType} from "../../panel/sheet/view/index.tsx";
import {PlayerTurnTracker, PlayerTurnTrackerOperation, playerTurnTrackerType} from "../../panel/turn-tracker/view/player-turn-tracker.ts";
import {StackType} from "#lib/container/index.ts";
import {CharacterPanel, CharacterPanelOperation, characterPanelType} from "../../panel/characters/character-panel.tsx";
import {Chat, ChatOperation, chatType} from "../../panel/chat";

export interface PlayerStackTypes {
  chat: Type<Chat, ChatOperation>;
  sheet: Type<PlayerSheetStack, PlayerSheetStackOperation>;
  "turn-tracker": Type<PlayerTurnTracker, PlayerTurnTrackerOperation>;
  "characters": Type<CharacterPanel, CharacterPanelOperation>
}
export type PlayerStackType = keyof PlayerStackTypes;

export type PlayerStackValue = {
  [Type in PlayerStackType]: {
    type: Type;
    data: ExtractValue<PlayerStackTypes[Type]>;
  }
}[PlayerStackType];
export type PlayerStackOperation = {
  [Type in PlayerStackType]: {
    type: Type;
    operations: ExtractOperation<PlayerStackTypes[Type]>[];
  }
}[PlayerStackType];

export const playerStackType: StackType<PlayerStackValue, PlayerStackOperation> = new StackType(new MultiType({
  chat: chatType,
  sheet: playerSheetStackType,
  turnTracker: playerTurnTrackerType,
  characters: characterPanelType
}));
