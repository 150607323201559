import {NumberOperation, numberType, ObjectType, Type} from "#common/types/index.ts";
import {z} from "zod";

export const Dnd5eHealth = z.object({
  current: z.number(),
  temp: z.number()
});
export type Dnd5eHealth = z.infer<typeof Dnd5eHealth>;

export const Dnd5eHealthOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-temp"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-current"), operations: z.array(NumberOperation)})
])
export type Dnd5eHealthOperation = z.infer<typeof Dnd5eHealthOperation>;

export const dnd5eCharacterHitPointsType: Type<Dnd5eHealth, Dnd5eHealthOperation> = new ObjectType({
  current: numberType,
  temp: numberType
}, (v: any) => {
  if (v.max) delete v["max"];
  if (v.current > 0) v.current = 0;
  return v;
});

