import {SetFn, SetOperation} from "common/types/index.ts";
import {Checkbox, ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import React from "react";
import {DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES, isDnd5eAttribute} from "common/legends/index.ts";
import {Dnd5eSavingThrowType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export function summarizeSavingThrows(savingThrows: Dnd5eSavingThrowType[]) {
  const allAttributes = DND_5E_ATTRIBUTES.every(attribute => savingThrows.includes(attribute));
  const summary = [];
  if (allAttributes) {
    if (savingThrows.includes("death")) summary.push("all");
    else summary.push("all attributes");
  } else {
    summary.push(...savingThrows.filter(isDnd5eAttribute).map(attribute => DND_5E_ATTRIBUTE_TITLE[attribute]));
    if (savingThrows.includes("death")) summary.push("death");
    if (!savingThrows.includes("con") && savingThrows.includes("concentration")) summary.push("concentration");
  }
  return summary.length > 0 ? summary.join(", ") : "none";
}

export type InputDnd5eSavingThrowsProps = {
  value: MutableRef<Dnd5eSavingThrowType[], SetOperation<Dnd5eSavingThrowType>[]>;
  readOnly?: boolean;
};
export function InputDnd5eSavingThrows({value}: InputDnd5eSavingThrowsProps) {
  const savingThrows = useRefValue(value);
  const [attributeExpanded, toggleAbilityExpanded] = useToggle(false);
  const allAttributes = DND_5E_ATTRIBUTES.every(attribute => savingThrows.includes(attribute));
  const someAttributes = DND_5E_ATTRIBUTES.some(attribute => savingThrows.includes(attribute));

  return <div className="flex flex-col gap-0.5">
    <div className="flex flex-col gap-0.5">
      <ExpandableLabel expanded={attributeExpanded} toggleExpand={toggleAbilityExpanded}>
        <Checkbox className="mx-4" checked={allAttributes} indeterminate={!allAttributes && someAttributes} onChange={() => {
          value.apply(prev => SetFn.set(prev, DND_5E_ATTRIBUTES.every(skill => prev.includes(skill))
            ? prev.filter(skill => !isDnd5eAttribute(skill))
            : [...prev.filter(skill => !isDnd5eAttribute(skill)), ...DND_5E_ATTRIBUTES]
          ));
        }}/>
        <span>All Attributes</span>
      </ExpandableLabel>
      {attributeExpanded && <Fieldset>
        {DND_5E_ATTRIBUTES.map(attribute => <label className="flex flex-row items-center bg-zinc-900/80 h-10 capitalize">
          <Checkbox className="mx-4" checked={savingThrows.includes(attribute)} onChange={() => {
            value.apply(prev => prev.includes(attribute) ? SetFn.delete(attribute) : SetFn.insert(attribute))
          }}/>
          <span>{DND_5E_ATTRIBUTE_TITLE[attribute]}</span>
        </label>)}
      </Fieldset>}
      <label className="flex flex-row items-center bg-zinc-900/80 h-10 capitalize">
        <Checkbox className="mx-4" checked={savingThrows.includes("concentration")} onChange={() => {
          value.apply(prev => prev.includes("concentration") ? SetFn.delete("concentration") : SetFn.insert("concentration"))
        }}/>
        <span>Concentration</span>
      </label>
      <label className="flex flex-row items-center bg-zinc-900/80 h-10 capitalize">
        <Checkbox className="mx-4" checked={savingThrows.includes("death")} onChange={() => {
          value.apply(prev => prev.includes("death") ? SetFn.delete("death") : SetFn.insert("death"))
        }}/>
        <span>Death</span>
      </label>
    </div>
  </div>;
}
