import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Texture2D extends WebGL2Node {
  public value?: WebGLTexture;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  bind(): void {
    if (this.value === undefined) throw new Error("value must be provided.");

    this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, this.value);
  }

  unbind(): void {
    this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
  }
}
