import {ConstantOperation, constantType, MapOperation, MapPropertyRef, MapType, ObjectType, Type} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {MutableRef} from "common/ref";

export type Outliner = {
  expanded: {[nodeId in NodeId]?: boolean};
};

export type OutlinerOperation =
  | {type: "update-expanded", operations: MapOperation<NodeId, boolean, ConstantOperation>[]}
  ;

export const outlinerType: Type<Outliner, OutlinerOperation> = new ObjectType({
  expanded: new MapType<NodeId, boolean, ConstantOperation>(constantType)
});

export function OutlinerSignals(value: MutableRef<Outliner, OutlinerOperation[]>) {
  return {
    expanded: MapPropertyRef<Outliner, OutlinerOperation, NodeId, boolean, ConstantOperation>(
      value => value.expanded,
      operations => [{type: "update-expanded", operations}]
    )(value)
  };
}