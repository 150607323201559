import {Dnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export function copyDnd5eModifier(value: Dnd5eModifier): Dnd5eModifier {
  return ({
    ...value,
    data: {
      ...value.data,
      modifierID: generateDnd5eModifierID()
    }
  }) as Dnd5eModifier;
}
