import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2DrawArrays extends WebGL2Node {
  public mode?: GLenum;
  public first?: GLint;
  public count?: GLsizei;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.mode === undefined) throw new Error("mode must be provided.");
    if (this.first === undefined) throw new Error("first must be provided.");
    if (this.count === undefined) throw new Error("count must be provided.");

    this.context.drawArrays(this.mode, this.first, this.count);
  }
}
