import {Apply} from "../../apply-action.ts";
import {NumberOperation} from "common/types/index.ts";
import {useCallback} from "react";

export function useSetNumberCallback(apply: Apply<number, NumberOperation[]>): (nextValue: number) => void {
  return useCallback((nextValue: number) =>
      apply((prevValue: number): NumberOperation[] =>
        [{type: "set", prevValue, nextValue}]
      ),
    [apply]
  );
}
