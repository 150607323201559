import {UserID} from "../../user/user-id.ts";
import {
  ColorOperation,
  colorType,
  ConstantOperation,
  ConstantType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  HSLA,
  ObjectType,
  StringOperation,
  stringType,
  Type,
  ValueFn,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {PublicKey} from "#common/crypto/index.ts";
import {MutableRef} from "#common/ref";
import {PropertyRef} from "../../../types/index.ts";

export type Owner = {
  id: UserID;
  name: string;
  color: HSLA;
  icon: FileReference;
  publicKey: PublicKey;
};

export type OwnerOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-icon", operations: FileReferenceOperation[]}
  | {type: "update-color", operations: ColorOperation[]}
  | {type: "update-public-key", operations: ValueOperation<PublicKey, ConstantOperation>[]}
  ;
export const OwnerOperation = {
  updateName: (operations: StringOperation[]): OwnerOperation[] => [{type: "update-name", operations}]
};

export const ownerType: Type<Owner, OwnerOperation> = new ObjectType({
  id: new ConstantType<UserID>(),
  name: stringType,
  icon: fileReferenceType,
  color: colorType,
  publicKey: new ValueType(new ConstantType<PublicKey>())
}, (value: any) => {
  if (!value.color) value.color = [0, 1, 1, 1];
  return value;
});

export function OwnerEntity(value: MutableRef<Owner, ValueOperation<Owner, OwnerOperation>[]>) {
  return {
    name: PropertyRef<Owner, ValueOperation<Owner, OwnerOperation>, string, StringOperation>(
      value => value.name,
      operations => ValueFn.apply([{type: "update-name", operations}])
    )(value),
    icon: PropertyRef<Owner, ValueOperation<Owner, OwnerOperation>, FileReference, FileReferenceOperation>(
      value => value.icon,
      operations => ValueFn.apply([{type: "update-icon", operations}])
    )(value),
    color: PropertyRef<Owner, ValueOperation<Owner, OwnerOperation>, HSLA, ColorOperation>(
      value => value.color,
      operations => ValueFn.apply([{type: "update-color", operations}])
    )(value)
  };
}