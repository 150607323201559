import {ObjectType, Transform, Type} from "#common/types/index.ts";
import {VisibilityLayerFn} from "#common/legends/visibility/index.ts";
import {NodeId} from "#common/legends/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type ParallaxNode = LocalNode & {};

export type ParallaxNodeOperation =
  | LocalNodeOperation
  ;


export const parallaxNodeType: Type<ParallaxNode, ParallaxNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes()
}), (value) => {
  value = localNodeUpdater(value);
  return value;
});

declare module "./node.ts" {
  export interface NodeTypes {
    "parallax": typeof parallaxNodeType
  }
}

export function defaultParallaxNode(): ParallaxNode {
  return {
    id: NodeId.generate(),
    visibilityLayer: VisibilityLayerFn.DEFAULT,
    transform: {
      ...Transform.DEFAULT,
      scale: 2
    },
    selectionMask: 1,
    origin: [0, 0],
    pivot: [0, 0],
    tags: [],
    children: [],
    name: "Parallax",
    conditions: []
  };
}

export function ParallaxNodeRef(value: MutableRef<ParallaxNode, ParallaxNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value)
  };
}
