import {AssetID, UserID} from "common/legends/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {QLabDatabase} from "common/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, listIdentity, map} from "common/observable";
import {walkTree} from "common/types/index.ts";
import {MutableRef, Ref} from "common/ref";

export function useAssetsByOwner(userID: UserID) {
  const database = useDatabase();
  return useMemo((): Ref<AssetID[]> => {
    const valueFn = (database: QLabDatabase) => {
      const assetIDs: AssetID[] = [];
      for (const [resourceID, resource] of Object.entries(database.resources)) {
        if (resource?.type !== "asset") continue;
        if (!resource.data.ownerIDs.includes(userID) && !resource.data.ownerIDs.includes("GLOBAL" as UserID)) continue;
        assetIDs.push(resourceID as AssetID);
      }

      if (database.store?.type === "game") {
        const orderedList: AssetID[] = [];
        walkTree(database.store.data.assets, {
          visit(value) {
            if (value.type === "asset") orderedList.push(value.data.id);
          }
        });
        assetIDs.sort((a, b) => orderedList.indexOf(a) - orderedList.indexOf(b));
      }

      return assetIDs;
    };

    return new MutableRef({
      value(): AssetID[] {
        return valueFn(database.value);
      },
      observe: pipe(database.observe, map(valueFn), distinct(listIdentity)),
      apply: _ => {throw new Error("Unsupported")}
    });
  }, [database, userID]);
}
