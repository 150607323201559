import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {ApplyAction, Optional} from "common/types/index.ts";
import {useEditor} from "../../container/editor/editor-context.ts";
import {SceneViewportProperties, SceneViewportPropertiesOperation} from "./scene-viewport-properties.ts";
import {EditorState} from "../../container/editor/state/index.ts";
import {MutableRef} from "common/ref";

export function useSceneViewportProperties() {
  const editor = useEditor();
  return useMemo((): MutableRef<Optional<SceneViewportProperties>, SceneViewportPropertiesOperation[]> => {
    const valueFn = (value: EditorState) => value?.type === "scene" ? value.data : undefined;
    return new MutableRef({
      value(): Optional<SceneViewportProperties> {return valueFn(editor.state.value)},
      observe: pipe(editor.state.observe, map(valueFn), distinct()),
      apply: (fn: ApplyAction<SceneViewportProperties, SceneViewportPropertiesOperation[]>) => editor.state.apply(prev => {
        if (prev?.type !== "scene") return [];
        const operations = fn(prev.data);
        if (operations.length === 0) return [];
        return [{type: "apply", operations: [{type: "scene", operations}]}];
      }).then(valueFn)
    })
  }, [editor.state]);
}
