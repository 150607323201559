import {IconButton, InputGroup, InputGroupLabel, InputNumber, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {ValueFn} from "common/types/index.ts";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eResourceConsumptionModifier,
  Dnd5eResourceConsumptionModifierOperation,
  Dnd5eResourceConsumptionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-resource-consumption-modifier.ts";
import {Dnd5eResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useSheetResources} from "../../dnd-5e-character/dnd-5e-action/use-sheet-resources.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export type Dnd5eResourceConsumptionModifierViewProps = {
  value: MutableRef<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eResourceConsumptionModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eResourceConsumptionModifierViewProps) {
  const query = useObservableLoader(value.observe);
  const {resourceID, qty} = useMemo(() => Dnd5eResourceConsumptionModifierSignals(value), [value]);

  const sheet = useSheetSignal(useSheetReference());
  const resources = useRefValue(useSheetResources(sheet));
  if (query.isLoading) return <></>;
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <InputGroup>
      <InputGroupLabel>Consume</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Resource</InputGroupLabel>
      <Select title="Resource" disabled={readOnly} value={query.data.resourceID || ""} onChange={readOnly ? undefined : (ev) => {
        const next = ev.target.value;
        resourceID.apply(prev => ValueFn.set(prev, next !== "" ? next as Dnd5eResourceID : undefined));
      }}>
        <option value={""}></option>
        {resources.map(resource => <option key={resource.resourceID} value={resource.resourceID}>{resource.name}</option>)}
      </Select>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Quantity</InputGroupLabel>
      <InputNumber title="Quantity" value={qty} />
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
