import {ListOperation, ListSignal, Optional} from "common/types/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {Interaction, InteractionOperation} from "common/legends/node/interaction/interaction.ts";
import {InteractionID} from "common/legends/node/interaction/interaction-i-d.ts";
import {MutableRef} from "common/ref";

const valueFn = (value: Interaction[], interactionID: Optional<InteractionID>): Optional<Interaction> => {
  return value.find(v => v.interactionID === interactionID);
};

export function useInteractionByID(value: ListSignal<Interaction, InteractionOperation>, interactionID: InteractionID | undefined): MutableRef<Optional<Interaction>, InteractionOperation[]> {
  return useMemo((): MutableRef<Optional<Interaction>, InteractionOperation[]> => {
    return new MutableRef({
      value(): Optional<Interaction> {
        return valueFn(value.value, interactionID);
      },
      observe: pipe(
        value.observe,
        map(value => valueFn(value, interactionID)),
        distinct()
      ),
      apply: fn => value.apply(prev => {
        const index = prev.findIndex(item => item.interactionID === interactionID);
        if (index === -1) return [];
        return ListOperation.apply(index, fn(prev[index]));
      }).then(value => valueFn(value, interactionID))
    });
  }, [value, interactionID]);
}