import {z} from "zod";
import {Type} from "#common/types/index.ts";
import {VISIBILITY_IDS} from "#common/legends/visibility/visibility-i-d.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const Mask = z.number();
export type Mask = z.infer<typeof Mask>;

export const MaskOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("set"), prev: Mask, op: Mask}),
  z.object({type: z.literal("and"), prev: Mask, op: Mask}),
  z.object({type: z.literal("or"), prev: Mask, op: Mask}),
  z.object({type: z.literal("xor"), op: Mask})
]);
export type MaskOperation = z.infer<typeof MaskOperation>;

class MaskType implements Type<Mask, MaskOperation> {
  apply(value: Mask, operation: MaskOperation): Mask {
    if (operation.type === "set") {
      return operation.op;
    } else if (operation.type === "and") {
      return value & operation.op;
    } else if (operation.type === "or") {
      return value | operation.op;
    } else if (operation.type === "xor") {
      return value ^ operation.op;
    }
    return value;
  }
  invert(operation: MaskOperation): MaskOperation[] {
    if (operation.type === "xor") return [operation];
    if (operation.type === "and") return [{type: "or", prev: operation.prev & operation.op, op: operation.prev}];
    if (operation.type === "or") return [{type: "and", prev: operation.prev | operation.op, op: operation.prev}];
    if (operation.type === "set") return [{type: "set", prev: operation.op, op: operation.prev}];
    throw new Error("Unsupported operation: " + JSON.stringify(operation));
  }
  transform(leftOperation: MaskOperation, topOperation: MaskOperation, tieBreaker: boolean): MaskOperation[] {
    switch (leftOperation.type) {
      case "and":
      case "or":
      case "xor":
        switch (topOperation.type) {
          case "and":
          case "or":
          case "xor": return tieBreaker
            ? [leftOperation]
            : [leftOperation];
          case "set": return tieBreaker
            ? [leftOperation]
            : [];
        }
        break;
      case "set":
        switch (topOperation.type) {
          case "and":
          case "or":
          case "xor": return tieBreaker
            ? [leftOperation]
            : [];
          case "set": return tieBreaker
            ? [leftOperation]
            : [];
        }
        break;
      default: throw new Error("Unrecognized Operation: " + JSON.stringify(leftOperation) + "; " + JSON.stringify(topOperation));
    }
  }
  migrateValue = (value: any): Mask => {
    if (typeof value === "number") return value;
    if (typeof value === "object") return Object.keys(value).reduce((a, b) => a + VISIBILITY_IDS[Number.parseInt(b)], 0);
    return 0;
  }
  migrateOperation = (operation: any): MaskOperation[] => {
    return [operation];
  }
  validate = (value: any): ValidationError[] => {
    if (typeof value === "number") return [];
    return [{path: [], data: {message: "Invalid type. Expected number.", value}}]
  }
}

export const maskType = new MaskType();
