import {RollRequests, RollResults, TextMessageContent} from "common/qlab/index.ts";
import {RollRequestView} from "./roll-request-view.tsx";

export type TextMessageContentViewProps = {
  messageContent: TextMessageContent;
  rollRequests?: RollRequests;
  rollResults?: RollResults;
};

export function TextMessageContentView({messageContent, rollRequests, rollResults}: TextMessageContentViewProps) {
  return (<>
    {messageContent.map((token, index) => {
      if (token.type === "string") {
        return <span className="" key={index}>{token.value}</span>
      } else if (token.type === "roll") {
        return <RollRequestView rollType="damage" key={index} rollRequest={rollRequests ? rollRequests[token.value] : undefined} rollResult={rollResults ? rollResults[token.value] : undefined} />
      } else {
        return <span/>
      }
    })}
  </>);
}