import fontDescriptor from "#lib/gl-react/component/fonts/honeyblot_caps-mtsdf.json";
import {HorizontalTextAlign, VerticalTextAlign} from "common/legends/node/index.ts";

export function getTextWidth(value: string, fontSize: number) {
  let width = 0;
  let lastChar = undefined;
  for (let i = 0; i < value.length; i ++) {
    const c = value.charCodeAt(i);
    const char = fontDescriptor.glyphs.find(char => char.unicode === c);
    if (char === undefined) continue;
    const kerning = (lastChar !== undefined
        ? fontDescriptor.kerning.find(kerning => kerning.unicode1 === lastChar!.unicode && kerning.unicode2 === char.unicode)?.advance
        : undefined
    ) || 0;
    width += kerning*fontSize;
    width += char.advance*fontSize;
    lastChar = char;
  }

  return width;
}

export function getTextHeight(value: string, fontSize: number) {
  return fontDescriptor.metrics.lineHeight * fontSize;
}

export function getTextOrigin(value: string, fontSize: number, hTextAlign: HorizontalTextAlign, vTextAlign: VerticalTextAlign): [number, number] {
  let [x, y] = [0, 0];
  const width = getTextWidth(value, fontSize);
  const height = getTextHeight(value, fontSize);
  switch (hTextAlign) {
    case "left": x = 0; break;
    case "center": x = width / 2; break;
    case "right": x = width; break;
  }
  switch (vTextAlign) {
    case "top": y = height; break;
    case "middle": y = height / 2; break;
    case "bottom": y = 0;
  }

  return [x, y];
}