import {NodeTurn, NodeTurnOperation, NodeTurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {FaForward, FaShieldAlt, FaTimes} from "react-icons/fa";
import {SheetInterface} from "common/legends/index.ts";
import {IconButton, InputNumber, useToggle} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {NodeIcon} from "./node-icon.tsx";
import {useNode, useNodeSheetReference} from "../../../../viewport/token/use-node.ts";
import {CONDITION_IMAGE} from "../../../sheet/editor/dnd-5e/dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {useGlobalFeatures} from "../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";

export type EditorNodeTurnViewProps = {
  value: MutableRef<NodeTurn, NodeTurnOperation[]>;
  isCurrentTurn: boolean;
  remove?: () => void;
  makeActiveTurn?: () => void;
};
export function EditorNodeTurnView({value, isCurrentTurn, makeActiveTurn, remove}: EditorNodeTurnViewProps) {
  const {order} = useMemo(() => NodeTurnSignals(value), [value]);
  const nodeTurn = useRefValue(value);
  const nodeRef = useNode(nodeTurn.nodeID);
  const sheetReferenceRef = useNodeSheetReference(nodeRef);
  const nodeName = useObservable(pipe(nodeRef.observe, map(node => node?.data.name), distinct()), undefined, [nodeRef.observe]);
  const nodeSheet = useRefValue(useSheetSignal(sheetReferenceRef));

  const globalFeatures = useRefValue(useGlobalFeatures());
  const [editOrder, toggleEditOrder, setEditOrder] = useToggle(false);
  return <div className={twMerge(
    "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
    isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50",
  )}>
    <NodeIcon nodeID={nodeTurn.nodeID} />
    {!editOrder && <div className="basis-6 h-8 shrink overflow-hidden flex items-center justify-center text-white/40 text-xs italic" onClick={toggleEditOrder}>{nodeTurn.order}</div>}
    {editOrder && <div className="w-20 px-2"><InputNumber value={order} autoFocus onLeaveEdit={() => setEditOrder(false)} /></div>}
    <span className="shrink-[1000] min-w-0 flex-1 inline-flex items-center overflow-hidden whitespace-nowrap">{nodeName || "??"}</span>
    <div className="inline-flex shrink-[9999] overflow-hidden gap-0.5">
      <div className="inline-flex shrink overflow-hidden gap-0.5">
        <div className="text-sm whitespace-nowrap shrink-[9999] overflow-hidden basis-10 flex flex-row items-center justify-center gap-0.5"><FaShieldAlt className="w-4 h-4 shrink-0" /><span>{nodeSheet ? SheetInterface.getAC(nodeSheet, globalFeatures) : "??"}</span></div>
        <div className="text-xs overflow-hidden shrink">{nodeSheet ? SheetInterface.getHealth(nodeSheet, globalFeatures) : ""}</div>
      </div>
      <div className="flex flex-row gap-1 shrink-[9999] overflow-hidden">{nodeSheet?.data.conditions.map((condition) => <img key={condition} alt={condition} title={condition} className="w-4 h-4" src={CONDITION_IMAGE[condition]} />)}</div>
    </div>
    {makeActiveTurn && <IconButton disabled={isCurrentTurn} size="small" title="Make Active Turn" onClick={makeActiveTurn}><FaForward /></IconButton>}
    {remove && <IconButton size="small" variant="destructive" onClick={remove}><FaTimes /></IconButton>}
  </div>;
}
