import {NumberOperation, numberType, ObjectType, Type} from "#common/types/index.ts";

export type Dnd5eCharacterExperience = {
  current: number;
  max: number;
};

export const Dnd5eCharacterExperience = {
  getCurrent: (value: Dnd5eCharacterExperience) => value.current,
  getMax: (value: Dnd5eCharacterExperience) => value.max
};

export type Dnd5eCharacterExperienceOperation =
  | {type: "update-current", operations: NumberOperation[]}
  | {type: "update-max", operations: NumberOperation[]}
  ;

export const Dnd5eCharacterExperienceOperation = {
  updateCurrent: (operations: NumberOperation[]): Dnd5eCharacterExperienceOperation[] => [{type: "update-current", operations}],
  updateMax: (operations: NumberOperation[]): Dnd5eCharacterExperienceOperation[] => [{type: "update-max", operations}]
};

export const dnd5eCharacterExperienceType: Type<Dnd5eCharacterExperience, Dnd5eCharacterExperienceOperation> = new ObjectType({
  current: numberType,
  max: numberType
});
