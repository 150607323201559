import {forwardRef, Ref, SelectHTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

export type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, "size"> & {
};
export const Select = forwardRef(function Select({className, ...props}: SelectProps, ref: Ref<HTMLSelectElement>): JSX.Element {
  return <select ref={ref} className={twMerge(
    "appearance-none box-border w-full border-none text-white outline-none focus:outline-none focus:ring-0",
    "disabled:opacity-50 placeholder:text-white/80 bg-transparent text-medium",
    "[&>*]:bg-zinc-900",
    className
  )} {...props} />
});
