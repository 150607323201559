import {Dnd5eSystem, Dnd5eSystemOperation, dnd5eSystemType} from "#common/legends/game/system/dnd-5e-system.ts";
import {z} from "zod";
import {ObjectType, PropertyRef} from "#common/types/generic/object/index.ts";
import {Type} from "#common/types/type/index.ts";
import {MutableRef} from "#common/ref";

export const GameSystems = z.object({
  dND5e: Dnd5eSystem
});
export type GameSystems = z.infer<typeof GameSystems>;

export const GameSystemsOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-d-n-d-5e"), operations: z.array(Dnd5eSystemOperation)})
]);
export type GameSystemsOperation = z.infer<typeof GameSystemsOperation>;

export const gameSystemsType: Type<GameSystems, GameSystemsOperation> = new ObjectType({
  dND5e: dnd5eSystemType
});

export function SystemSignals(signal: MutableRef<GameSystems, GameSystemsOperation[]>) {
  return ({
    dND5e: PropertyRef<GameSystems, GameSystemsOperation, Dnd5eSystem, Dnd5eSystemOperation>(
      value => value.dND5e,
      operations => [{type: "update-d-n-d-5e", operations}]
    )(signal)
  })
}