import {InputHTMLAttributes, useEffect, useRef} from "react";
import {twMerge} from "tailwind-merge";

const SIZE_VARIANTS = {
  "small": "w-[16px] h-[16px]",
  "medium": "w-[20px] h-[20px]",
  "large": "w-[24px] h-[24px]"
};

export type CheckboxSize = "small" | "medium" | "large";
export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & {
  size?: CheckboxSize;
  indeterminate?: boolean;
};
export function Checkbox({size, indeterminate, className, ...props}: CheckboxProps): JSX.Element {
  const checkboxRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (checkboxRef.current) checkboxRef.current.indeterminate = indeterminate === true
  }, [checkboxRef, indeterminate]);

  return <input ref={checkboxRef} type="checkbox" className={twMerge(
    "border-box",
    "bg-neutral-800 border-neutral-600",
    "focus:bg-blue-500 focus:border-blue-500",
    "checked:border-none checked:bg-blue-600",
    "checked:focus:bg-blue-500 checked:hover:bg-blue-500",
    "disabled:opacity-50",
    SIZE_VARIANTS[size || "small"],
    className
  )} {...props} />;
}
