import {z} from "zod";
import {Dnd5eAttribute, NodeID, UserID} from "#common/legends/index.ts";
import {
  FileReference,
  ConstantOperation,
  constantType,
  Encrypted,
  ObjectType,
  Optional,
  RichText,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {RollRequests, RollResults, TextMessageContent} from "#common/qlab/index.ts";
import {RollRequestID} from "#common/qlab/message/roll-request/index.ts";
import {Dnd5eDamageType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";

export const Dnd5eSavingThrowRequestMessage = z.object({
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  title: Encrypted(z.string()),
  description: z.optional(Encrypted(RichText)),
  defense: Dnd5eAttribute,
  difficultyClass: RollRequestID,
  damageRolls: z.array(z.object({
    damageType: z.optional(Dnd5eDamageType),
    hitDamageRollID: RollRequestID
  })),
  onHit: z.optional(Encrypted(TextMessageContent)),
  onMiss: z.optional(Encrypted(TextMessageContent)),
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
});
export type Dnd5eSavingThrowRequestMessage = z.infer<typeof Dnd5eSavingThrowRequestMessage>;

export type Dnd5eSavingThrowRequestMessageOperation =
  | {type: "update-user-i-d", operations: ConstantOperation[]}
  | {type: "update-node-i-d", operations: ConstantOperation[]}
  | {type: "update-icon", operations: ConstantOperation[]}
  | {type: "update-title", operations: ConstantOperation[]}
  | {type: "update-description", operations: ConstantOperation[]}
  | {type: "update-defense", operations: ConstantOperation[]}
  | {type: "update-difficulty-class", operations: ConstantOperation[]}
  | {type: "update-damage-rolls", operations: ConstantOperation[]}
  | {type: "update-on-hit", operations: ConstantOperation[]}
  | {type: "update-on-miss", operations: ConstantOperation[]}
  | {type: "update-roll-requests", operations: ConstantOperation[]}
  | {type: "update-roll-results", operations: ValueOperation<Optional<Encrypted<RollResults>>, ConstantOperation>[]}
  ;
export const dnd5eSavingThrowRequestMessageType: Type<Dnd5eSavingThrowRequestMessage, Dnd5eSavingThrowRequestMessageOperation> = new ObjectType({
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  title: constantType,
  description: constantType,
  defense: constantType,
  difficultyClass: constantType,
  damageRolls: constantType,
  onHit: constantType,
  onMiss: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType)
});
