import {QLabDatabase, QLabDatabaseOperation, QLabResourceID, ResourceOperation, ResourceType, TypedResourceOperation, TypedResourceValue} from "common/qlab/index.ts";
import {Optional, ValueFn} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {useMemo} from "react";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";

export function useTypedResource<T extends ResourceType>(type: T, resourceID: Optional<QLabResourceID>): MutableRef<Optional<TypedResourceValue<T>>, TypedResourceOperation<T>[]> {
  const database = useDatabase();
  return useMemo(() => {
    const valueFn = (value: QLabDatabase): Optional<TypedResourceValue<T>> => {
      if (resourceID === undefined) return undefined;
      const resource = value.resources[resourceID];
      if (resource?.type !== type) return undefined;
      return resource.data as TypedResourceValue<T>;
    };

    const mapFn = (prev: QLabDatabase, operations: TypedResourceOperation<T>[]): QLabDatabaseOperation[] => {
      if (resourceID === undefined) return [];
      if (prev.resources[resourceID]?.type !== type) return [];
      if (operations.length === 0) return [];
      return [{type: "resource", resourceID, operations: ValueFn.apply([{
        type: type,
        operations: operations as unknown as any
      } satisfies ResourceOperation])}];
    };

    return database.map(valueFn, mapFn);
  }, [database, resourceID]);
}
