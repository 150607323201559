import {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

export function Fieldset({className, ...props}: HTMLAttributes<HTMLDivElement>) {
  return (<div className="flex flex-row backdrop-blur-sm pointer-events-auto">
    <div className="w-10 shrink-0 bg-zinc-900/80" />
    <div className={twMerge(
      "flex-1 flex flex-col gap-0.5 pt-2 bg-zinc-800/50",
      className
    )} {...props} />
  </div>);
}
