import {Observable} from "../observable.ts";

export function map<U, V>(mapper: (value: U) => V): (observe: Observable<U>) => Observable<V> {
  return (observe) => {
    return observer => observe({
      next: (u) => observer.next(mapper(u)),
      error: observer.error,
      complete: observer.complete
    });
  };
}
