import {ArrayBufferToBase64Codec, Base64Codec} from "#common/codec";
import {DecryptPrivateKey} from "./private-key.ts";

export async function decrypt(body: string, privateKey: DecryptPrivateKey): Promise<string> {
  // @ts-ignore
  const cryptoKey = await crypto.subtle.importKey(
    "jwk",
    JSON.parse(Base64Codec.decode(privateKey)),
    {name: "RSA-OAEP", hash: {name: "SHA-256"}},
    false,
    ["decrypt"]
  );
  const encodedData = ArrayBufferToBase64Codec.decode(body);
  // @ts-ignore
  const decodedValue: ArrayBuffer = await crypto.subtle.decrypt(
    {name: "RSA-OAEP"},
    cryptoKey,
    encodedData
  );
  return new TextDecoder().decode(decodedValue);
}
