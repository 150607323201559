import {Dnd5eCharacterDeathSaves, Dnd5eCharacterDeathSavesOperation} from "common/legends/index.ts";
import {ButtonBar, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useApplyCallback, useObservable, useSetNumberCallback} from "#lib/qlab/index.ts";
import {FaCheck, FaSkull} from "react-icons/fa6";
import {FaTimes} from "react-icons/fa";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useRollSavingThrow} from "./dnd-5e-action/use-roll-saving-throw.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {MutableRef} from "common/ref";

export type DeathSavesProps = {
  value: MutableRef<Dnd5eCharacterDeathSaves, Dnd5eCharacterDeathSavesOperation[]>;
};
export function DeathSaves({value}: DeathSavesProps) {
  const {successes, failures} = useObservable(value.observe, {successes: 0, failures: 0}, [value]);

  const setSuccesses = useSetNumberCallback(useApplyCallback(value.apply, Dnd5eCharacterDeathSaves.getSuccesses, Dnd5eCharacterDeathSavesOperation.updateSuccesses));
  const setFailures = useSetNumberCallback(useApplyCallback(value.apply, Dnd5eCharacterDeathSaves.getFailures, Dnd5eCharacterDeathSavesOperation.updateFailures));
  const selectedNodeID = useSelectedNodeID();
  const rollSavingThrow = useRollSavingThrow(selectedNodeID, useSheetSignal(useSheetReference()));

  return (<ButtonBar>
    <InputGroup title="Death Saves">
      <InputGroupIcon><FaSkull /></InputGroupIcon>
      <InputGroupLabel>Death Saves</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1" title="Successes">
      <InputGroupLabel><FaCheck/></InputGroupLabel>
      <Input className="text-center" type="number" value={successes} min={0} max={3} onChange={ev => setSuccesses(Number.parseInt(ev.target.value) | 0)} />
    </InputGroup>
    <InputGroup className="flex-1" title="Failures">
      <InputGroupLabel><FaTimes/></InputGroupLabel>
      <Input className="text-center" type="number" value={failures} min={0} max={3} onChange={ev => setFailures(Number.parseInt(ev.target.value) | 0)} />
    </InputGroup>
    <DiceButton onClick={ev => rollSavingThrow("death", ev.shiftKey, ev.ctrlKey)} />
  </ButtonBar>)
}