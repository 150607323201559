import {Dnd5eCharacterClass, Dnd5eSpellLevel, DND_5E_SPELL_LEVEL} from "#common/legends/index.ts";

const SPELL_SLOTS: {[level: number]: number[]} = {
  [0]: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1]: [2, 0, 0, 0, 0, 0, 0, 0, 0],
  [2]: [3, 0, 0, 0, 0, 0, 0, 0, 0],
  [3]: [4, 2, 0, 0, 0, 0, 0, 0, 0],
  [4]: [4, 3, 0, 0, 0, 0, 0, 0, 0],
  [5]: [4, 3, 2, 0, 0, 0, 0, 0, 0],
  [6]: [4, 3, 3, 0, 0, 0, 0, 0, 0],
  [7]: [4, 3, 3, 1, 0, 0, 0, 0, 0],
  [8]: [4, 3, 3, 2, 0, 0, 0, 0, 0],
  [9]: [4, 3, 3, 3, 1, 0, 0, 0, 0],
  [10]: [4, 3, 3, 3, 2, 0, 0, 0, 0],
  [11]: [4, 3, 3, 3, 2, 1, 0, 0, 0],
  [12]: [4, 3, 3, 3, 2, 1, 0, 0, 0],
  [13]: [4, 3, 3, 3, 2, 1, 1, 0, 0],
  [14]: [4, 3, 3, 3, 2, 1, 1, 0, 0],
  [15]: [4, 3, 3, 3, 2, 1, 1, 1, 0],
  [16]: [4, 3, 3, 3, 2, 1, 1, 1, 0],
  [17]: [4, 3, 3, 3, 2, 1, 1, 1, 1],
  [18]: [4, 3, 3, 3, 3, 1, 1, 1, 1],
  [19]: [4, 3, 3, 3, 3, 2, 1, 1, 1],
  [20]: [4, 3, 3, 3, 3, 2, 2, 1, 1]
};

const PACT_SLOTS: {[level: number]: number[]} = {
  [0]: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [1]: [1, 0, 0, 0, 0, 0, 0, 0, 0],
  [2]: [2, 0, 0, 0, 0, 0, 0, 0, 0],
  [3]: [0, 2, 0, 0, 0, 0, 0, 0, 0],
  [4]: [0, 2, 0, 0, 0, 0, 0, 0, 0],
  [5]: [0, 0, 2, 0, 0, 0, 0, 0, 0],
  [6]: [0, 0, 2, 0, 0, 0, 0, 0, 0],
  [7]: [0, 0, 0, 2, 0, 0, 0, 0, 0],
  [8]: [0, 0, 0, 2, 0, 0, 0, 0, 0],
  [9]: [0, 0, 0, 0, 2, 0, 0, 0, 0],
  [10]: [0, 0, 0, 0, 2, 0, 0, 0, 0],
  [11]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [12]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [13]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [14]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [15]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [16]: [0, 0, 0, 0, 3, 0, 0, 0, 0],
  [17]: [0, 0, 0, 0, 4, 0, 0, 0, 0],
  [18]: [0, 0, 0, 0, 4, 0, 0, 0, 0],
  [19]: [0, 0, 0, 0, 4, 0, 0, 0, 0],
  [20]: [0, 0, 0, 0, 4, 0, 0, 0, 0]
};

export function getMaxPactSlots(classes: Dnd5eCharacterClass[], spellLevel: Dnd5eSpellLevel) {
  if (spellLevel === "cantrip") return 0;
  const spellLevelIndex = Number.parseInt(spellLevel) - 1;
  const pactLevels = classes.filter(clazz => clazz.spellSlotProgression === "PACT").reduce((a, b) => a + b.level, 0);
  return PACT_SLOTS[Math.min(pactLevels, 20)][spellLevelIndex];
}

export function getMaxSpellSlotsByClasses(classes: Dnd5eCharacterClass[], spellLevel: Dnd5eSpellLevel) {
  const fullLevels = classes.filter(clazz => clazz.spellSlotProgression === "FULL").reduce((a, b) => a + b.level, 0);
  const half1Levels = classes.filter(clazz => clazz.spellSlotProgression === "HALF1").reduce((a, b) => a + b.level, 0);
  const half2Levels = classes.filter(clazz => clazz.spellSlotProgression === "HALF2").reduce((a, b) => a + b.level, 0);
  const thirdLevels = classes.filter(clazz => clazz.spellSlotProgression === "THIRD").reduce((a, b) => a + b.level, 0);
  const isMulticlassing = classes.filter(clazz => clazz.spellSlotProgression !== undefined && clazz.spellSlotProgression !== "PACT").length > 1;
  if (isMulticlassing) {
    const spellCasterLevel = fullLevels
      + Math.ceil(half1Levels / 2)
      + Math.floor(half2Levels / 2)
      + Math.floor(thirdLevels / 3);
    return getMaxSpellSlotsBySpellCasterLevel(spellCasterLevel, spellLevel);
  } else {
    const spellCasterLevel = fullLevels
      + Math.ceil(half1Levels / 2)
      + Math.floor((half2Levels+1) / 2)
      + Math.floor((thirdLevels+2) / 3);
    return getMaxSpellSlotsBySpellCasterLevel(spellCasterLevel, spellLevel);
  }
}

export function getMaxSpellSlotsBySpellCasterLevel(level: number, spellLevel: Dnd5eSpellLevel) {
  if (spellLevel === "cantrip") return Number.POSITIVE_INFINITY;
  const spellLevelIndex = Number.parseInt(spellLevel) - 1;
  return SPELL_SLOTS[Math.max(0, Math.min(level, 20))][spellLevelIndex];
}

export function getPactSlotLevel(classes: Dnd5eCharacterClass[]): Dnd5eSpellLevel {
  return DND_5E_SPELL_LEVEL.filter(level => level !== "cantrip").find(level => getMaxPactSlots(classes, level) > 0) || "1";
}
