import {z} from "zod";
import {Spline, SplineOperation, splineType} from "common/types/generic/spline/index.ts";
import {ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {WallToolMode} from "./wall-tool-mode.ts";

export const WallToolDestroyMode = z.object({
  spline: z.optional(Spline)
});
export type WallToolDestroyMode = z.infer<typeof WallToolDestroyMode>
export const WallToolDestroyModeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-spline"), operations: z.array(ValueOperation(z.optional(Spline), SplineOperation))})
])
export type WallToolDestroyModeOperation = z.infer<typeof WallToolDestroyModeOperation>
export const wallToolDestroyModeType: Type<WallToolDestroyMode, WallToolDestroyModeOperation> = new ObjectType({
  spline: new ValueType(splineType)
});

export const WallToolDestroyModeFn = {
  DEFAULT: {
    type: "destroy",
    data: {
      spline: undefined
    } satisfies WallToolDestroyMode
  } satisfies WallToolMode
};
