import {Observable} from "../observable.ts";

export function throttle<T>(ms: number): (observe: Observable<T>) => Observable<T> {
  return (observe) => {
    return observer => {
      let throttleHandler: any | undefined = undefined;
      return observe({
        next: (value: T) => {
          if (throttleHandler) clearTimeout(throttleHandler);
          throttleHandler = setTimeout(() => {
            observer.next(value);
          }, ms);
        },
        error: observer.error,
        complete: observer.complete
      });
    }
  }
}