import {z} from "zod";
import {AssetID} from "#common/legends/asset/asset-i-d.ts";
import {GameID} from "#common/legends/game/game-i-d.ts";

export const AssetReference = z.object({
  gameID: GameID,
  assetID: AssetID
});

export type AssetReference = z.infer<typeof AssetReference>;
