import {useCallback} from "react";
import {QLabResourceID, ResourceType} from "common/qlab/index.ts";
import {ValueFn} from "common/types/index.ts";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";

export function useDeleteResource() {
  const databaseRef = useDatabase();
  return useCallback(async <Type extends ResourceType>(type: Type, resourceID: QLabResourceID) => {
    return databaseRef.apply(database => {
      const resource = database.resources[resourceID];
      if (resource === undefined) return [];
      if (resource.type !== type) throw new Error(`Unexpected type.\nExpected: ${type}\n Actual: ${resource?.type}`);
      return [{type: "resource", resourceID, operations: ValueFn.set(resource, undefined)}];
    });
  }, [databaseRef]);
}
