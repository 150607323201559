import {ApplyAction} from "#lib/qlab/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {map} from "common/observable";
import {
  GridNode,
  GridNodeOperation,
  GroupNode,
  GroupNodeOperation,
  ImageNode,
  ImageNodeOperation,
  Node,
  NodeOperation,
  ShapeNode,
  ShapeNodeOperation,
  TextNode,
  TextNodeOperation,
  TokenNode,
  TokenNodeOperation
} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {SelectionRef} from "../../../container/editor/state/selection-ref.ts";
import {ImageNodeProperties} from "./image-node-properties.tsx";
import {GroupNodeProperties} from "./group-node-properties.tsx";
import {TokenNodeProperties} from "./token-node-properties.tsx";
import {GridNodeProperties} from "./grid-node-properties.tsx";
import {WallNodeProperties} from "./wall-node-properties.tsx";
import {WallNode, WallNodeOperation} from "common/legends/node/wall-node.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {AreaNodeProperties} from "./area-node-properties.tsx";
import {AreaNode, AreaNodeOperation} from "common/legends/node/area-node.ts";
import {MutableRef} from "common/ref";
import {TextNodeProperties} from "./text-node-properties.tsx";
import {ShapeNodeProperties} from "./shape-node-properties.tsx";
import {ParallaxNode, ParallaxNodeOperation} from "common/legends/node/parallax-node.ts";
import {ParallaxNodeProperties} from "./parallax-node-properties.tsx";

export type NodePropertiesProps = {
  value: MutableRef<Node, NodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function NodeProperties({value, reference, pinned}: NodePropertiesProps) {
  const nodeType = useComputedValue(value, value => value?.type);
  const typedNode = useMemo(() => new MutableRef({
    value() {
      return value.value?.data;
    },
    observe: pipe(value.observe, map(node => node!.data)),
    apply: (fn: ApplyAction<any, any>): Promise<any> => value.apply(prev => {
      return [{type: prev!.type, operations: fn(prev!.data)}];
    }).then(response => response!.data)
  }), [value, nodeType]);

  if (nodeType === "image") {
    return <ImageNodeProperties value={typedNode as MutableRef<ImageNode, ImageNodeOperation[]>} reference={reference} pinned={pinned} />;
  } else if (nodeType === "group") {
    return <GroupNodeProperties value={typedNode as MutableRef<GroupNode, GroupNodeOperation[]>} reference={reference} pinned={pinned}  />;
  } else if (nodeType === "token") {
    return <TokenNodeProperties value={typedNode as MutableRef<TokenNode, TokenNodeOperation[]>} reference={reference} pinned={pinned} />;
  } else if (nodeType === "grid") {
    return <GridNodeProperties value={typedNode as MutableRef<GridNode, GridNodeOperation[]>} reference={reference} pinned={pinned} />;
  } else if (nodeType === "wall") {
    return <WallNodeProperties value={typedNode as MutableRef<WallNode, WallNodeOperation[]>} reference={reference} pinned={pinned} />
  } else if (nodeType === "area") {
    return <AreaNodeProperties value={typedNode as MutableRef<AreaNode, AreaNodeOperation[]>} reference={reference} pinned={pinned} />
  } else if (nodeType === "shape") {
    return <ShapeNodeProperties value={typedNode as MutableRef<ShapeNode, ShapeNodeOperation[]>} reference={reference} pinned={pinned} />
  } else if (nodeType === "text") {
    return <TextNodeProperties value={typedNode as MutableRef<TextNode, TextNodeOperation[]>} reference={reference} pinned={pinned} />
  } else if (nodeType === "parallax") {
    return <ParallaxNodeProperties value={typedNode as MutableRef<ParallaxNode, ParallaxNodeOperation[]>} reference={reference} pinned={pinned} />
  } else {
    return <></>
  }
}
