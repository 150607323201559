import {Optional} from "common/types/index.ts";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {useMemo} from "react";
import {SheetReference, SheetReferenceFn} from "../../common/sheet/sheet-reference.ts";
import {nodeSheetReference} from "../common/node/node-sheet-observable.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {MutableRef, Ref} from "common/ref";
import {NodeRef} from "../../common/legends/node-ref.ts";

export function useNode(nodeID: Optional<NodeId>): MutableRef<Optional<Node>, NodeOperation[]> {
  const databaseRef = useDatabase();
  return useMemo(() => NodeRef(databaseRef, nodeID), [databaseRef, nodeID]);
}

export function useNodeSheetReference(nodeRef: Ref<Optional<Node>>) {
  return useMemo((): Ref<Optional<SheetReference>> => {
    return nodeRef.map(nodeSheetReference).distinct(SheetReferenceFn.equals);
  }, [nodeRef]);
}
