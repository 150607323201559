import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Program extends WebGL2Node {
  public value?: WebGLProgram = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  bind(): void {
    if (this.value === undefined) throw new Error("value must be provided");

    this.context.useProgram(this.value);
  }

  unbind(): void {
    this.context.useProgram(null);
  }
}
