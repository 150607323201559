import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {ConstantOperation, constantType, NumberOperation, numberType, ObjectType, PropertyRef} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eDamageThresholdModifier = z.object({
  modifierID: Dnd5eModifierID,
  amount: z.number()
});
export type Dnd5eDamageThresholdModifier = z.infer<typeof Dnd5eDamageThresholdModifier>;

export const Dnd5eDamageThresholdModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-amount"), operations: z.array(NumberOperation)})
]);
export type Dnd5eDamageThresholdModifierOperation = z.infer<typeof Dnd5eDamageThresholdModifierOperation>;

export const dnd5eDamageThresholdModifierType = new ObjectType({
  modifierID: constantType,
  amount: numberType
});

export function Dnd5eDamageThresholdModifierSignals(value: MutableRef<Dnd5eDamageThresholdModifier, Dnd5eDamageThresholdModifierOperation[]>) {
  return ({
    amount: PropertyRef<Dnd5eDamageThresholdModifier, Dnd5eDamageThresholdModifierOperation, number, NumberOperation>(
      value => value.amount,
      operations => [{type: "update-amount", operations}]
    )(value)
  });
}
