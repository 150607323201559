import {QLabDatabase, QLabDatabaseOperation, QLabStoreID} from "common/qlab/index.ts";
import {Observer, subscribe} from "common/observable";
import {QLabInstance} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";

export function createQLabDatabase(qlabInstance: QLabInstance, storeId: QLabStoreID): Promise<MutableRef<QLabDatabase, QLabDatabaseOperation[]>> {
  return new Promise<MutableRef<QLabDatabase, QLabDatabaseOperation[]>>((resolve, reject) => {
    let observers: Observer<QLabDatabase>[] = [];
    let state: QLabDatabase | undefined = undefined;
    const signal = new MutableRef<QLabDatabase, QLabDatabaseOperation[]>({
      value(): QLabDatabase {return state!},
      observe: (observer: Observer<QLabDatabase>) => {
        observers.push(observer);
        observer.next(signal.value);
        return () => {
          const index = observers.indexOf(observer);
          if (index !== -1) observers.splice(index, 1);
        };
      },
      apply: async (fn: (prev: QLabDatabase) => QLabDatabaseOperation[]) => {
        const operations = fn(signal.value);
        for (const operation of operations) {
          // This should cause the observe to change on it's own
          if (operation.type === "store") {
            await qlabInstance.applyToStore(storeId, _ => operation.operations);
          } else if (operation.type === "message") {
            await qlabInstance.applyToMessage(storeId, operation.messageID, _ => operation.operations);
          } else if (operation.type === "resource") {
            await qlabInstance.applyToResource(storeId, operation.resourceID, _ => operation.operations);
          }
        }
        return state!;
      }
    });

    subscribe((value: QLabDatabase) => {
      if (state === undefined) {
        state = value;
        resolve(signal);
      } else {
        state = value;
        observers.forEach(observer => {
          try {observer.next(value)} catch (e) {console.error(e)}
        });
      }
    }, reject)(qlabInstance.observe(storeId));
  });
}
