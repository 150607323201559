import {Dnd5eAction, generateDnd5eActionID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {copyDnd5eActionEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action-effect.ts";
import {copyDnd5eActionModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action-modifier.ts";

export function copyDnd5eAction(value: Dnd5eAction): Dnd5eAction {
  return ({
    ...value,
    actionID: generateDnd5eActionID(),
    actionEffects: value.actionEffects.map(copyDnd5eActionEffect),
    modifiers: value.modifiers.map(copyDnd5eActionModifier)
  });
}