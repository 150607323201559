import {IconButton, InputGroup, InputGroupLabel, InputNumber, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {ValueFn} from "common/types/index.ts";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eItemConsumptionModifier,
  Dnd5eItemConsumptionModifierOperation,
  Dnd5eItemConsumptionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-item-consumption-modifier.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useSheetItems} from "../../dnd-5e-character/dnd-5e-action/use-sheet-items.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eItemConsumptionModifierViewProps = {
  value: MutableRef<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eItemConsumptionModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eItemConsumptionModifierViewProps) {
  const {itemName, qty} = useMemo(() => Dnd5eItemConsumptionModifierSignals(value), [value]);

  const itemNameValue = useRefValue(itemName);
  const sheet = useSheetSignal(useSheetReference());
  const items = useRefValue(useSheetItems(sheet));

  const allItems = useMemo((): string[] => {
    return [...new Set([
      ...items,
      ...(itemNameValue === undefined ? [] : [itemNameValue])
    ])].sort();
  }, [items, itemNameValue])

  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Consume</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1 pr-0">
      <InputGroupLabel>Item</InputGroupLabel>
      <Select title="Item" disabled={readOnly} value={itemNameValue || ""} onChange={readOnly ? undefined : (ev) => {
        const next = ev.target.value;
        itemName.apply(prev => ValueFn.set(prev, next !== "" ? next as string : undefined));
      }}>
        <option value={""}></option>
        {allItems.map(item => <option key={item} value={item}>{item}</option>)}
      </Select>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Quantity</InputGroupLabel>
      <InputNumber title="Quantity" value={qty} />
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
