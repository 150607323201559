import {UserID} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useGame} from "./model/index.ts";

export function usePlayerName(userID: UserID) {
  const game = useGame();
  return useObservable(pipe(
    game.observe,
    map(game => {
      if (game.players[userID]) return game.players[userID].name;
      if (game.owner.id === userID) return game.owner.name;
      return "Unknown";
    }),
    distinct()
  ), "Unknown", [game, userID]);
}