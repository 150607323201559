import {applyAll, invertAll, transformAll, Type} from "../../type/index.ts";
import {Point, PointOperation} from "./point.ts";
import {numberType} from "../number/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const pointType: Type<Point, PointOperation> = {
  apply: (value: Point, operation: PointOperation): Point => {
    switch (operation.type) {
      case "update-x": return [
        applyAll(numberType, value[0], operation.operations),
        value[1]
      ];
      case "update-y": return [
        value[0],
        applyAll(numberType, value[1], operation.operations)
      ];
    }
  },

  invert: (operation: PointOperation): PointOperation[] => {
    switch (operation.type) {
      case "update-x": return [{type: "update-x", operations: invertAll(numberType, operation.operations)}];
      case "update-y": return [{type: "update-y", operations: invertAll(numberType, operation.operations)}];
    }
  },

  transform: (leftOperation: PointOperation, topOperation: PointOperation, tieBreaker: boolean): PointOperation[] => {
    if (leftOperation.type === "update-x" && topOperation.type === "update-x") {
      return [{
        type: "update-x",
        operations: transformAll(numberType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
      }];
    } else if (leftOperation.type === "update-y" && topOperation.type === "update-y") {
      return [{
        type: "update-y",
        operations: transformAll(numberType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
      }];
    } else {
      return [leftOperation];
    }
  },
  migrateValue: (value: any): Point => {
    return value;
  },
  migrateOperation: (operation: any): PointOperation[] => {
    return [operation]
  },
  validate: (value: any): ValidationError[] => {
    if (!Array.isArray(value) || value.length !== 2) return [{path: [], data: {message: "Invalid type. Expected point.", value}}];
    return value.flatMap((value, index) => numberType.validate(value).map(error => ({path: [index, ...error.path], data: error.data})));
  }
}