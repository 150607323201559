import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, Point, Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Line} from "../viewport/common/node/layer-view/scene-view.tsx";
import {Vector2} from "common/math/vector/vector2.ts";

const vertexShader = `#version 300 es
precision highp float;
in vec2 a_position;
uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec4 u_color;

out vec4 outColor;

void main() {
  outColor = u_color;
}
`;

export type WallShaderProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  origin: Point;
  lines: Line[];
};

export function WallShader({projection, model, view, lines, origin}: WallShaderProps) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const colorLocation = useUniformLocation(program, "u_color");

  const vboArray = useMemo((): Float32Array => {
    const viewTransform = Matrix4f.invert(Matrix4f.transform(view));
    const matrixTransform = Matrix4f.multiplyMatrix(viewTransform, Matrix4f.invert(projection));
    const topLeft = Matrix4f.multiplyVector(matrixTransform, [-1, 1, 0, 1]).slice(0, 2) as Point;
    const topRight = Matrix4f.multiplyVector(matrixTransform, [1, 1, 0, 1]).slice(0, 2) as Point;
    const bottomLeft = Matrix4f.multiplyVector(matrixTransform, [-1, -1, 0, 1]).slice(0, 2) as Point;
    const bottomRight = Matrix4f.multiplyVector(matrixTransform, [1, -1, 0, 1]).slice(0, 2) as Point;

    const floats: number[] = [];
    for (const {start, end, viewLeft, viewRight} of lines) {
      const left = Vector2.cross(Vector2.subtract(end, origin), Vector2.subtract(start, origin)) < 0;
      if (left && viewLeft) continue;
      if (!left && viewRight) continue;

      const leftStartT = Vector2.lineIntersect(origin, start, bottomLeft, topLeft);
      const leftEndT = Vector2.lineIntersect(origin, end, bottomLeft, topLeft);
      const topStartT = Vector2.lineIntersect(origin, start, topLeft, topRight);
      const topEndT = Vector2.lineIntersect(origin, end, topLeft, topRight);
      const rightStartT = Vector2.lineIntersect(origin, start, topRight, bottomRight);
      const rightEndT = Vector2.lineIntersect(origin, end, topRight, bottomRight);
      const bottomStartT = Vector2.lineIntersect(origin, start, bottomLeft, bottomRight);
      const bottomEndT = Vector2.lineIntersect(origin, end, bottomLeft, bottomRight);

      if (leftStartT > rightStartT && leftEndT > rightEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, leftEndT));
        floats.push(...start, ...end, ...startIntersect);
        floats.push(...end, ...endIntersect, ...startIntersect);
        continue;
      }
      if (topStartT > bottomStartT && topEndT > bottomEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, topEndT));
        floats.push(...start, ...end, ...startIntersect);
        floats.push(...end, ...endIntersect, ...startIntersect);
        continue;
      }
      if (rightStartT > leftStartT && rightEndT > leftEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, rightEndT));
        floats.push(...start, ...end, ...startIntersect);
        floats.push(...end, ...endIntersect, ...startIntersect);
        continue;
      }
      if (bottomStartT > topStartT && bottomEndT > topEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, bottomEndT));
        floats.push(...start, ...end, ...startIntersect);
        floats.push(...end, ...endIntersect, ...startIntersect);
        continue;
      }

      if (leftStartT > rightStartT && bottomStartT > topStartT && rightEndT > leftEndT && topEndT > bottomEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, bottomStartT, leftStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, topEndT, rightEndT));
        const startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }
      if (leftEndT > rightEndT && bottomEndT > topEndT && rightStartT > leftStartT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT, rightStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, bottomEndT, leftEndT));
        const startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }
      if (rightEndT > leftEndT && bottomEndT > topEndT && leftStartT > rightStartT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT, leftStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, bottomEndT, rightEndT));
        const startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }
      if (leftEndT > rightEndT && topEndT > bottomEndT && rightStartT > leftStartT && bottomStartT > topStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, bottomStartT, rightStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, topEndT, leftEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }

      if (topEndT > bottomEndT && rightStartT < leftStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, topEndT));
        let startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (topEndT > bottomEndT && rightStartT > leftStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, topEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (topEndT < bottomEndT && rightStartT < leftStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, bottomEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (topEndT < bottomEndT && rightStartT > leftStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, bottomEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }

      if (leftEndT > rightEndT && topStartT < bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, leftEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (leftEndT > rightEndT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, leftEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (leftEndT < rightEndT && topStartT < bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (leftEndT < rightEndT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }

      if (topStartT > bottomStartT && rightEndT > leftEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      } else if (topStartT > bottomStartT && rightEndT < leftEndT) {
        const startIntersect = Vector2.lerp(origin, start, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(origin, end, Math.max(1, leftEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...start, ...end, ...startEndIntersect);
        floats.push(...start, ...startIntersect, ...startEndIntersect);
        floats.push(...end, ...endIntersect, ...startEndIntersect);
        continue;
      }
    }
    return new Float32Array(floats);
  }, [projection, view, origin, lines]);

  const vbo = useArrayBuffer(vboArray);
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 2 * 4, 0);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(Color.BLACK)), [])

  return (<>
      <program value={program}>
        <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f}/>
        <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f}/>
        <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f}/>
        <uniform4fv location={colorLocation} data={color4f}/>

        <vertexArray value={vao}>
          <drawArrays mode={WebGL2RenderingContext.TRIANGLES} first={0} count={vboArray.length / 2}/>
        </vertexArray>
      </program>
    </>
  );
}