import React, {PropsWithChildren, useContext, useMemo} from "react";
import {QLabDatabase, QLabDatabaseOperation, QLabStoreID} from "common/qlab/index.ts";
import {MutableRef} from "common/ref";

type DatabaseSignal = {
  storeID: QLabStoreID,
  database: MutableRef<QLabDatabase, QLabDatabaseOperation[]>
};

const DatabaseContext = React.createContext<DatabaseSignal | undefined>(undefined);
export type GameSignalProviderProps = PropsWithChildren<DatabaseSignal>;
export function DatabaseProvider({storeID, database, children}: GameSignalProviderProps) {
  const value = useMemo((): DatabaseSignal => ({storeID, database}), [storeID, database]);
  return (<DatabaseContext.Provider value={value}>
    {children}
  </DatabaseContext.Provider>)
}

export function useDatabase() {
  return useContext(DatabaseContext)!.database;
}

export function useStoreID() {
  return useContext(DatabaseContext)!.storeID;
}
