import {ExtractOperation, ExtractValue, Optional} from "common/types/index.ts";
import {SheetReference} from "./sheet-reference.ts";
import {Sheet, SheetOperation, SheetType, SheetTypes} from "common/legends/index.ts";
import {useMemo} from "react";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {distinct, map} from "common/observable";
import {pipe} from "common/pipe";
import {MutableRef, Ref} from "common/ref";
import {SheetRef} from "../legends/sheet-ref.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function useSheetSignal(sheetReferenceRef: Ref<Optional<SheetReference>>): MutableRef<Optional<Sheet>, SheetOperation[]> {
  const databaseRef = useDatabase();
  const sheetReference = useRefValue(sheetReferenceRef);
  return useMemo(() => SheetRef(databaseRef, sheetReference), [databaseRef, sheetReference]);
}

export function useTypedSheetSignal<Type extends SheetType>(type: Type, sheetReference: Ref<Optional<SheetReference>>): MutableRef<Optional<ExtractValue<SheetTypes[Type]>>, ExtractOperation<SheetTypes[Type]>[]> {
  const sheet = useSheetSignal(sheetReference);
  return useMemo(() => {
    const valueFn = (a: Optional<Sheet>) => {
      return a?.type === type ? a.data as ExtractValue<SheetTypes[Type]> : undefined;
    };
    return new MutableRef({
      value(): Optional<ExtractValue<SheetTypes[Type]>> {
        return valueFn(sheet.value);
      },
      observe: pipe(sheet.observe, map(valueFn), distinct()),
      apply: (fn) => sheet.apply(prev => {
        if (prev?.type !== type) return [];
        const operations = fn(prev.data as ExtractValue<SheetTypes[Type]>);
        if (operations.length === 0) return [];
        else return [{type, operations}] as SheetOperation[];
      }).then(valueFn)
    })
  }, [sheet, type]);
}
