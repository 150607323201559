import React, {useCallback, useMemo, useRef, useState} from "react";
import {Tree, TreeId, TreePath, VisitResult, walkTree} from "common/types/index.ts";
import {ProxyRegion, ProxyRegionViewer} from "../proxy-region/index.ts";
import {TreeItemView} from "./tree-item-view.tsx";
import {NodeId} from "common/legends/index.ts";
import {useDrop} from "react-dnd";

export type TreeViewProps<T extends Tree<T>, O> = {
  type: string;
  items: T[];
  isVisible: (item: T) => boolean;
  move: (fromPath: TreePath, toPath: TreePath) => void;

  selected?: {[id: string]: boolean};
  onSelect?: (id: TreeId) => void;
  onDeselect?: (id: TreeId) => void;

  expanded: {[id in string]?: boolean};
  onExpand: (id: TreeId) => void;
  onCollapse: (id: TreeId) => void;
  ItemActions: (item: T) => JSX.Element;
};

export const TREE_ITEM_INDENTATION_SIZE = 42;
export const TREE_ITEM_HEIGHT_SIZE = 40;

export function TreeView<T extends Tree<T>, O>({type, isVisible, items, move, selected, onSelect, onDeselect, expanded, onExpand, onCollapse, ItemActions}: TreeViewProps<T, O>) {
  const isExpanded = (nodeID: NodeId): boolean => {
    return (expanded[nodeID] !== undefined && expanded[nodeID]!);
  };

  const filteredItems = useMemo(() => {
    let flattenedItems: [TreePath, T][] = [];
    let currentPath: TreePath = [0];
    walkTree<T>(items, {
      visit(item: T) {
        if (isVisible(item)) {
          flattenedItems.push([[...currentPath], item]);
        }
        if (Tree.isParentNode(item)) {
          currentPath.push(0);
        }
        if (Tree.isParentNode(item) && !isExpanded(item.data.id)) {
          return VisitResult.SKIP_SUBTREE;
        }
      },
      postVisit(item: T) {
        if (Tree.isParentNode(item)) {
          currentPath.pop();
          currentPath[currentPath.length - 1]++;
        } else {
          currentPath[currentPath.length - 1]++;
        }
      }
    });
    return flattenedItems;
  }, [items, isVisible, expanded]);

  const treeViewDivRef = useRef<HTMLDivElement>(null);
  const [proxyRegion, setProxyRegion] = useState<ProxyRegion|undefined>(undefined);

  const getDragPaths = useCallback((dragIndex: number) => {
    let currentPath: TreePath = [0];
    let dragPaths: TreePath[] = [];
    let index = 0;
    walkTree<T>(items, {
      preVisit(value) {
        if (index === dragIndex) {
          if (isVisible(value)) {
            dragPaths.push([...currentPath]);
          }
        }
      },
      visit(value: T) {
        if (Tree.isParentNode(value)) {
          if (isVisible(value)) {
            index ++;
          }
          currentPath.push(0);
        }

        if (Tree.isParentNode(value) && !isExpanded(value.data.id)) {
          return VisitResult.SKIP_SUBTREE;
        }
      },
      postVisit(value: T) {
        if (index === dragIndex) {
          if (Tree.isParentNode(value)) {
            if (isVisible(value) && isExpanded(value.data.id)) {
              dragPaths.push([...currentPath]);
            }
          } else {
            if (isVisible(value)) {
              dragPaths.push([...currentPath]);
            }
          }
        }
        if (Tree.isParentNode(value)) {
          currentPath.pop();
          currentPath[currentPath.length - 1]++;
        } else {
          currentPath[currentPath.length - 1]++;
          if (isVisible(value)) {
            index ++;
          }
        }
      }
    });
    if (index === dragIndex) {
      dragPaths.push([...currentPath]);
    }
    return dragPaths;
  }, [items, isVisible, expanded]);

  const [{isOver}, dropRef] = useDrop<{type: string, id: T, path: TreePath}, unknown, {isOver: boolean}>(() => ({
    accept: `legends/${type}`,
    hover: (_, monitor) => {
      const {x, y} = monitor.getClientOffset()!;
      const {left, top, width, height} = treeViewDivRef.current!.getBoundingClientRect();
      const [lx, ly] = [
        Math.max(0, Math.min(x - left, width)),
        Math.max(0, Math.min(y - top, height))
      ];
      const dragIndex = Math.floor((ly + 24) / TREE_ITEM_HEIGHT_SIZE);
      const dragPaths = getDragPaths(dragIndex);
      if (dragPaths.length > 0) {
        const dragLevel =
          Math.max(
            Math.min(...dragPaths.map(path => path.length - 1)),
            Math.min(Math.floor((lx - 4) / TREE_ITEM_INDENTATION_SIZE), Math.max(...dragPaths.map(path => path.length - 1))
            )
          );
        setProxyRegion({
          x: 4 + dragLevel * TREE_ITEM_INDENTATION_SIZE,
          y: dragIndex * (TREE_ITEM_HEIGHT_SIZE + 2) - 12+1,
          width: width - (4 + dragLevel * TREE_ITEM_INDENTATION_SIZE),
          height: 2
        });
      }
    },
    drop: (item, monitor) => {
      const {x, y} = monitor.getClientOffset()!;
      const {left, top, width, height} = treeViewDivRef.current!.getBoundingClientRect();
      const [lx, ly] = [
        Math.max(0, Math.min(x - left, width)),
        Math.max(0, Math.min(y - top, height))
      ];

      const dragIndex = Math.floor((ly + 24) / TREE_ITEM_HEIGHT_SIZE);
      const dragPaths = getDragPaths(dragIndex);
      const dragLevel =
        Math.max(
          Math.min(...dragPaths.map(path => path.length - 1)),
          Math.min(Math.floor((lx - 4) / TREE_ITEM_INDENTATION_SIZE), Math.max(...dragPaths.map(path => path.length - 1))
          )
        );

      const selectedPaths = dragPaths.filter(path => path.length === dragLevel + 1);
      const fromPath = item.path;
      if (selectedPaths.length > 0) {
        let toPath = selectedPaths[0];
        if (TreePath.isSibling(fromPath, toPath) && TreePath.endsBefore(fromPath, toPath)) {
          toPath[toPath.length - 1] --;
        }

        if (!TreePath.isAncestor(fromPath, toPath) && !TreePath.equals(fromPath, toPath)) {
          move(fromPath, toPath);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({shallow: true})
    })
  }), [setProxyRegion, getDragPaths, move]);

  dropRef(treeViewDivRef);
  return (<div className="relative my-1" ref={treeViewDivRef}>
    <div className="flex flex-col gap-0.5">
      {filteredItems.map(([path, item]) => <TreeItemView
        key={item.data.id}
        type={type}
        path={path}
        selected={selected ? selected[item.data.id] || false : false}
        expanded={isExpanded(item.data.id)}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onExpand={onExpand}
        onCollapse={onCollapse}
        item={item}
        ItemActions={ItemActions}
        />
      )}
    </div>
    {isOver && proxyRegion && <ProxyRegionViewer value={proxyRegion} />}
  </div>);
}
