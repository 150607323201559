export type Vector2f = [number, number];

export const Vector2f = {
  ADDITIVE_IDENTITY: [0, 0] as Vector2f,
  MULTIPLICATIVE_IDENTITY: [1, 1] as Vector2f,
  length: ([x, y]: Vector2f): number => {
    return Math.sqrt(x*x + y*y);
  },
  add: ([x1, y1]: Vector2f, [x2, y2]: Vector2f): Vector2f => {
    return [x1 + x2, y1 + y2];
  },
  subtract: ([x1, y1]: Vector2f, [x2, y2]: Vector2f): Vector2f => {
    return [x1 - x2, y1 - y2];
  },
  floor: ([x1, y1]: Vector2f): Vector2f => {
    return [Math.floor(x1), Math.floor(y1)];
  },
  rotate: ([x, y]: Vector2f, r: number): Vector2f => {
    return [
      x*Math.cos(r) - y*Math.sin(r),
      x*Math.sin(r) + y*Math.cos(r)
    ];
  },
  multiply: ([x1, y1]: Vector2f, s: number): Vector2f => {
    return [s * x1, s * y1];
  },
  multiplyVector: ([x1, y1]: Vector2f, [x2, y2]: Vector2f): Vector2f => {
    return [x1 * x2, y1 * y2];
  },
  normalize: ([x, y]: Vector2f): Vector2f => {
    const length = Vector2f.length([x, y]);
    return [x / length, y / length];
  },
  dot: (a: Vector2f, b: Vector2f): number => {
    return a[0] * b[0] + a[1] * b[1];
  },
  max: (a: Vector2f, b: Vector2f): Vector2f => {
    return (Vector2f.length(a) > Vector2f.length(b)) ? a : b;
  },
  min: (a: Vector2f, b: Vector2f): Vector2f => {
    return (Vector2f.length(a) > Vector2f.length(b)) ? b : a;
  },
  lerp: (a: Vector2f, b: Vector2f, t: number): Vector2f => {
    if (t <= 0) return a;
    if (t >= 1) return b;
    return [
      (a[0] * (1-t)) + (b[0] * t),
      (a[1] * (1-t)) + (b[1] * t)
    ];
  },
  intersect([x1, y1]: Vector2f, [x2, y2]: Vector2f, [x3, y3]: Vector2f, [x4, y4]: Vector2f): Vector2f | undefined {
    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denominator == 0) return undefined;

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    if (ua < 1) return undefined;
    return [
      x1 + ua * (x2 - x1),
      y1 + ua * (y2 - y1)
    ];
  },
  distanceToLine([x1, y1]: Vector2f, [x2, y2]: Vector2f, [x3, y3]: Vector2f, [x4, y4]: Vector2f): number | undefined {
    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denominator == 0) return undefined;
    return ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  },
  distanceSquared([x1, y1]: Vector2f, [x2, y2]: Vector2f): number {
    return Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2);
  },
  isLeft([x1, y1]: Vector2f, [x2, y2]: Vector2f, [x3, y3]: Vector2f): boolean {
    return (x2 - x1)*(y3 - y1) - (y2 - y1)*(x3 - x1) > 0;
  }
};
