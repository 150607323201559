import {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";
import {FaImage} from "react-icons/fa6";

export type ProgressBarProps = HTMLAttributes<HTMLDivElement> & {
  value: number;
};
export function ProgressBar({value, ...props}: ProgressBarProps) {
  return (
    <div {...props} className={twMerge("flex items-center w-full", props.className)}>
      <div className="relative bg-blue-900/50 h-8 flex-1 rounded-lg">
        <span className="absolute top-0 left-0 text-white w-8 h-8 text-h200 flex items-center justify-center"><FaImage /></span>
        <div className="bg-blue-600/50 h-8" style={{width: `${Math.round(value * 100)}%`}} />
        <span className="absolute text-xs px-2 top-2 right-0 text-white/80">{Math.floor(value * 100)}%</span>
      </div>
    </div>
  );
}
