import {Asset, AssetOperation, generateTokenID, Token, TokenID} from "common/legends/index.ts";
import {useCallback} from "react";
import {MutableRef} from "common/ref";

export function useCreateToken(assetRef: MutableRef<Asset, AssetOperation[]>) {
  return useCallback(async (token: Token): Promise<TokenID> => {
    const tokenId = generateTokenID();
    await assetRef.apply(prev => !prev ? [] : [{
      type: "update-tokens",
      operations: [{
        type: "insert",
        index: prev.tokens.length,
        item: token
      }]
    }]);
    return tokenId;
  }, [assetRef]);
}
