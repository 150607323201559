import {NodeId} from "./node-id.ts";
import {ConstantOperation, ConstantType, ListOperation, ListPropertyRef, ListType, PropertyRef, StringOperation, stringType, Tag, tagListType} from "../../types/index.ts";
import {NodeCondition, NodeConditionOperation, nodeConditionType} from "#common/legends/node/condition/node-condition.ts";
import {MutableRef} from "#common/ref";
import {Node, nodeType} from "./node.ts";

export type BaseNode = {
  id: NodeId;
  name: string;
  tags: Tag[];
  conditions: NodeCondition[];
  children: Node[];
};

export type BaseNodeOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  | {type: "update-conditions", operations: ListOperation<NodeCondition, NodeConditionOperation>[]}
  | {type: "update-children", operations: ConstantOperation[]}
  ;

export const baseNodeTypePropTypes = () => ({
  id: new ConstantType<NodeId>(),
  name: stringType,
  tags: tagListType,
  conditions: new ListType(nodeConditionType),
  children: new ConstantType<Node[]>(new ListType(nodeType)),
});

export function BaseNodeSignals(value: MutableRef<BaseNode, BaseNodeOperation[]>) {
  return {
    name: PropertyRef<BaseNode, BaseNodeOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    tags: ListPropertyRef<BaseNode, BaseNodeOperation, Tag, ConstantOperation>(
      value => value.tags,
      operations => [{type: "update-tags", operations}]
    )(value),
    conditionsRef: ListPropertyRef<BaseNode, BaseNodeOperation, NodeCondition, NodeConditionOperation>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value)
  };
}
