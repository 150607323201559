import {DiceExpressionTree} from "./parse-dice-expression.ts";

export function toConstantDiceExpressionTree(expression: DiceExpressionTree, context: {[variable: string]: number}): DiceExpressionTree {
  switch (expression.op) {
    case "variable": return ({op: "constant", value: context[expression.variable] || 0, label: expression.label || expression.variable});
    case "constant": return expression;
    case "dice": return {op: expression.op, count: toConstantDiceExpressionTree(expression.count, context), faces: toConstantDiceExpressionTree(expression.faces, context), label: expression.label};
    case "keep-highest": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), count: toConstantDiceExpressionTree(expression.count, context), label: expression.label});
    case "keep-lowest": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), count: toConstantDiceExpressionTree(expression.count, context), label: expression.label});
    case "drop-highest": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), count: toConstantDiceExpressionTree(expression.count, context), label: expression.label});
    case "drop-lowest": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), count: toConstantDiceExpressionTree(expression.count, context), label: expression.label});
    case "reroll": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), comparator: expression.comparator, target: toConstantDiceExpressionTree(expression.target, context), label: expression.label});
    case "reroll-once": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), comparator: expression.comparator, target: toConstantDiceExpressionTree(expression.target, context), label: expression.label});
    case "explode": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), comparator: expression.comparator, target: toConstantDiceExpressionTree(expression.target, context), label: expression.label});
    case "add": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context)});
    case "subtract": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context)});
    case "multiply": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context)});
    case "divide": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context)});
    case "remainder": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context)});
    case "min": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context), label: expression.label});
    case "max": return ({op: expression.op, left: toConstantDiceExpressionTree(expression.left, context), right: toConstantDiceExpressionTree(expression.right, context), label: expression.label});
    case "parentheses": return ({op: expression.op, expression: toConstantDiceExpressionTree(expression.expression, context), label: expression.label});
    case "count-success": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), comparator: expression.comparator, target: toConstantDiceExpressionTree(expression.target, context), label: expression.label});
    case "count-failure": return ({op: expression.op, dice: toConstantDiceExpressionTree(expression.dice, context), comparator: expression.comparator, target: toConstantDiceExpressionTree(expression.target, context), label: expression.label});
    default: return expression;
  }
}
