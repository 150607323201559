import {ColorOperation, ColorRef, HSLA} from "common/types/index.ts";
import {InputGroup} from "./input-group.tsx";
import {forwardRef, HTMLAttributes, Ref, useMemo} from "react";
import {InputGroupColorButton, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

type ColorAlphaFieldProps = HTMLAttributes<HTMLLabelElement> & {
  label?: string;
  valueRef: MutableRef<HSLA, ColorOperation[]>;
  readOnly?: boolean;
  disabled?: boolean;
};
export const ColorAlphaField = forwardRef(function ColorField({valueRef, readOnly, disabled, label, ...props}: ColorAlphaFieldProps, ref: Ref<HTMLLabelElement>) {
  const {alphaRef} = useMemo(() => ColorRef(valueRef), [valueRef]);

  return <div className="flex flex-row">
    <InputGroup ref={ref} className="flex-[2]" {...props}>
      {label && <InputGroupLabel>{label}</InputGroupLabel>}
      <InputGroupColorButton readOnly={readOnly} disabled={disabled} valueRef={valueRef} />
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Opacity</InputGroupLabel>
      <InputNumber min={0} max={100} value={alphaRef} />
      <InputGroupIcon>%</InputGroupIcon>
    </InputGroup>
  </div>
});