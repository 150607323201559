import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eAttackRollModifier,
  Dnd5eAttackRollModifierOperation,
  Dnd5eAttackRollModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-attack-roll-modifier.ts";
import {InputDiceExpression} from "../../dice-expression/input-dice-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eAttackRollModifierViewProps = {
  value: MutableRef<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eAttackRollModifierView({value, remove, dragHandlerRef, dragRefPreview, readOnly}: Dnd5eAttackRollModifierViewProps) {
  const {expression} = useMemo(() => Dnd5eAttackRollModifierSignals(value), [value])
  return <div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup size="small" className="flex-1 pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Attack Roll</InputGroupLabel>
      <InputDiceExpression readOnly={readOnly} value={expression} />
    </InputGroup>
    {!readOnly && <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>;
}
