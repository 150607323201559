import {z} from "zod";
import {Spline, SplineOperation, splineType} from "common/types/generic/spline/index.ts";
import {OptionalType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {AreaToolMode} from "./area-tool-mode.ts";

export const AreaToolCreateMode = z.optional(Spline);
export type AreaToolCreateMode = z.infer<typeof AreaToolCreateMode>
export const AreaToolCreateModeOperation = ValueOperation(z.optional(Spline), SplineOperation);
export type AreaToolCreateModeOperation = z.infer<typeof AreaToolCreateModeOperation>
export const areaToolCreateModeType: Type<AreaToolCreateMode, AreaToolCreateModeOperation> = new ValueType(new OptionalType(splineType));

export const AreaToolCreateFn = {
  DEFAULT: {
    type: "create",
    data: undefined satisfies AreaToolCreateMode
  } satisfies AreaToolMode
};
