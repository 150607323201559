import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {QLabMessageID, RollResults} from "common/qlab/index.ts";
import {useDecryptValue, useOptionalDecryptValue} from "../use-decrypt-value.ts";
import {Dnd5eFeatureMessage} from "common/qlab/message/dnd-5e/dnd-5e-feature-message.ts";
import {UserNodeIcon} from "../user-node-icon.tsx";
import {Dnd5eDescriptionView} from "./dnd-5e-description-view.tsx";
import {Dnd5eApplyDamageButton} from "./dnd-5e-apply-damage-button.tsx";
import {Dnd5eDamageView} from "./dnd-5e-damage-view.tsx";
import {useIsPartyNode} from "../../../common/node/use-is-party-node.ts";

type Dnd5eFeatureMessageViewProps = {
  messageId: QLabMessageID;
  message: Dnd5eFeatureMessage;
};

export function Dnd5eFeatureMessageView({message}: Dnd5eFeatureMessageViewProps) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const title = useDecryptValue(message.title, userId, user.data?.privateKey);
  const description = useOptionalDecryptValue(message.description, userId, user.data?.privateKey);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);
  const isParty = useIsPartyNode(message.nodeID);

  if (description.isLoading) return <></>
  return <div className="relative mt-7 mb-2 mx-2">
    <div className="w-full bg-zinc-800/50 rounded-lg backdrop-blur-sm shadow-lg">
      {isParty && <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />}
      {!isParty && <UserNodeIcon userID={message.userID} nodeID={undefined} icon={undefined} />}
      <table className="w-full pointer-events-auto ">
        <thead><tr><th colSpan={2} className="pb-2 pt-5 bg-zinc-900/50 rounded-t-lg">
          {title.data || "???"}
        </th></tr></thead>
        <tbody className="divide-y divide-zinc-900/50">
          <Dnd5eDamageView damageRolls={message.damageRolls} requests={rollRequests} results={rollResults} />
          <Dnd5eApplyDamageButton damageRolls={message.damageRolls} results={rollResults} />
          <Dnd5eDescriptionView description={description} requests={rollRequests} results={rollResults} />
        </tbody>
      </table>
    </div>
  </div>;
}
