import {Input, InputProps} from "#lib/components/index.ts";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useCallback, useMemo, useRef, useState} from "react";
import {MathExpression, MathExpressionFn} from "common/math/index.ts";
import {MutableRef} from "common/ref";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {twMerge} from "tailwind-merge";
import {useVariables} from "../../../legends/panel/sheet/editor/dnd-5e/dnd-5e-action/dnd-5e-variables.ts";

export type InputMathExpressionProps = {
  value: MutableRef<MathExpression, ValueOperation<MathExpression, ConstantOperation>[]>;
  onLeaveEdit?: () => void;
} & Omit<InputProps, "value" | "onChange">;

export function InputMathExpression({value, onLeaveEdit, ...props}: InputMathExpressionProps) {
  const variables = useVariables();
  const setValue = useCallback((nextValue: MathExpression) => value.apply(prev => ValueFn.set(prev, nextValue)), [value.apply]);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string | undefined>(undefined);

  const startEdit = useCallback(() => {
    setEditValue(value.value);
    setEditMode(true);
  }, [setEditValue, setEditMode, value]);

  const editValueRef = useRef(editValue);
  editValueRef.current = editValue;
  const finalizeEdit = useCallback(() => {
    const expression = editValueRef.current!;
    if (MathExpressionFn.isMathExpression(expression)) {
      setValue(expression);
    }
    setEditMode(false);
    onLeaveEdit?.();
  }, [setValue, setEditMode, editValueRef]);

  const expression = useRefValue(value);
  const computedValue = useComputedValue(variables, variables => {
    return MathExpressionFn.executeMathExpression(expression, variables || {});
  }, [expression]);
  const isMathExpression = useMemo(() => MathExpressionFn.isMathExpression(editValue || "0"), [editValue])
  const viewLabel = expression !== `${computedValue}` ? `${expression} = ${computedValue}` : expression;

  return <div className="w-full flex items-center">
    {!editMode && <Input {...props} readOnly type="text" value={viewLabel} title={viewLabel} onClick={startEdit} onFocus={startEdit} />}
    {editMode && <div className="w-full flex flex-row gap-2 items-center">
      <Input {...props} className={twMerge(
        !isMathExpression && "bg-red-500/30",
        props.className
      )} autoFocus type="text" value={editValue} onChange={ev => setEditValue(ev.target.value)} onBlur={finalizeEdit} onKeyDown={ev => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          finalizeEdit();
          return true;
        }
        if (ev.key === "Escape") {
          setEditMode(false);
          onLeaveEdit?.();
          ev.preventDefault();
          return true;
        }
      }} />
    </div>}
  </div>
}
