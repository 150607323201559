import {twMerge} from "tailwind-merge";
import {ButtonBar, IconButton} from "#lib/components/index.ts";
import {FaCopy, FaTrash} from "react-icons/fa";
import {AssetID, Token, TokenID, TokenOperation, TokenSignals} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";

export function EditorAssetOutlinerToken({item, remove, duplicate, assetID, navigateToAssetToken}: InputListItemProps<Token, TokenOperation> & {
  assetID: AssetID;
  navigateToAssetToken: (assetID: AssetID, tokenID: TokenID) => void;
}) {
  const {tokenID, nameRef} = useMemo(() => TokenSignals(item), [item]);

  const selectToken = () => {
    navigateToAssetToken(assetID, tokenID.value);
  };

  const name = useRefValue(nameRef);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/token", item, remove);

  return (<ButtonBar role="list-item" ref={dragRefPreview} className={twMerge(
    "flex items-center h-10 text-h200 gap-1",
    "odd:bg-zinc-900/50 even:bg-zinc-900/30 hover:bg-zinc-800/50"
  )}>
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <span className="px-4 h-10 flex-1 cursor-pointer flex items-center" onClick={selectToken}>{name}</span>
    <IconButton disabled={false} onClick={duplicate} title="Duplicate"><FaCopy /></IconButton>
    <IconButton disabled={!remove} variant="destructive" onClick={remove} title="Delete Token"><FaTrash /></IconButton>
  </ButtonBar>);
}
