import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {Dnd5eSavingThrowMessage, Dnd5eSavingThrowRequestMessage, QLabMessageID, RollResults} from "common/qlab/index.ts";
import {useDecryptValue, useOptionalDecryptValue} from "../use-decrypt-value.ts";
import {TextMessageContentView} from "../text-message-content-view.tsx";
import {UserNodeIcon} from "../user-node-icon.tsx";
import {Dnd5eDescriptionView} from "./dnd-5e-description-view.tsx";
import {Dnd5eDifficultyClassView} from "./dnd-5e-difficulty-class-view.tsx";
import {Loader} from "common/loader";
import {Dnd5eApplyDamageButton} from "./dnd-5e-apply-damage-button.tsx";
import {Dnd5eDamageView} from "./dnd-5e-damage-view.tsx";
import {useReferencedMessages} from "../use-referenced-messages.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {RollRequestView} from "../roll-request-view.tsx";
import {useIsPartyNode} from "../../../common/node/use-is-party-node.ts";
import {useIsGameMaster} from "../../../common/game/use-is-game-master.ts";

function Dnd5eSavingThrowMessageResponse({messageID, message}: {messageID: QLabMessageID, message: Dnd5eSavingThrowMessage}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);

  return <div className="inline-flex items-center relative py-2">
    <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />
    <RollRequestView rollType="saving-throw" rollRequest={rollRequests.isSuccess ? rollRequests.data[message.savingThrowRollID] : undefined} rollResult={rollResults.isSuccess && rollResults.data ? rollResults.data[message.savingThrowRollID] : undefined} />
  </div>;
}

export function Dnd5eSavingThrowRequestMessageView({messageId, message}: {
  messageId: QLabMessageID;
  message: Dnd5eSavingThrowRequestMessage;
}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const title = useDecryptValue(message.title, userId, user.data?.privateKey);
  const description = useOptionalDecryptValue(message.description, userId, user.data?.privateKey);
  const hitMessage = useOptionalDecryptValue(message.onHit, userId, user.data?.privateKey);
  const missMessage = useOptionalDecryptValue(message.onMiss, userId, user.data?.privateKey);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);
  const isParty = useIsPartyNode(message.nodeID);
  const isGameMaster = useIsGameMaster();
  const isTokenReveal = isParty || isGameMaster;

  const messages = useRefValue(useReferencedMessages(messageId));


  if (hitMessage.isLoading || missMessage.isLoading) return <></>;
  return <div className="relative mt-7 mb-2 mx-2">
    <div className="w-full bg-zinc-800/50 divide-y divide-zinc-900/20 rounded-lg shadow-lg backdrop-blur-sm">
      {isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />}
      {!isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={undefined} icon={undefined} />}
      <table className="w-full pointer-events-auto">
        <thead>
          <tr><th colSpan={2} className="bg-zinc-900/50 px-4 pb-2 pt-5 rounded-t-lg">{title.data || "???"}</th></tr>
        </thead>
        <tbody className="divide-y divide-zinc-900/20">
        <Dnd5eDifficultyClassView
          defense={message.defense}
          request={rollRequests.isSuccess ? Loader.loaded(rollRequests.data[message.difficultyClass]) : Loader.loading()}
          result={rollResults.isSuccess ? Loader.loaded(rollResults.data ? rollResults.data[message.difficultyClass] : undefined) : Loader.loading()}/>

        <Dnd5eDamageView damageRolls={message.damageRolls} requests={rollRequests} results={rollResults}/>

        {hitMessage.data && <tr>
            <td className="px-4 py-2 font-bold italic text-sm text-right w-[20%]">HIT</td>
            <td className="text-sm py-2 text-white/70 italic">
                <TextMessageContentView messageContent={hitMessage.data} rollRequests={rollRequests.data} rollResults={rollResults.data}/>
            </td>
        </tr>}
        {missMessage.data && <tr>
            <td className="px-4 py-2 font-bold italic text-sm text-right w-[20%]">MISS</td>
            <td className="text-sm py-2 text-white/70 italic">
                <TextMessageContentView messageContent={missMessage.data} rollRequests={rollRequests.data} rollResults={rollResults.data}/>
            </td>
        </tr>}

        <Dnd5eApplyDamageButton damageRolls={message.damageRolls} results={rollResults}/>

        <Dnd5eDescriptionView description={description} requests={rollRequests} results={rollResults}/>

        <tr className="bg-zinc-900/50">
          <td colSpan={2} className="font-bold italic text-sm text-center h-10">
            <span className="flex-grow px-4 py-2">SAVING THROWS</span>
          </td>
        </tr>

        <tr>
          <td colSpan={2}>
            <div className="flex gap-2 justify-center flex-wrap pt-7 pb-2 gap-y-1 min-h-16 box-content">
              {Object.entries(messages).toSorted((a, b) => a[0].localeCompare(b[0]))
                .filter(([_, message]) => message.type === "dnd-5e-saving-throw-message")
                .map(([messageID, message]) => (message.type === "dnd-5e-saving-throw-message")
                  ? <Dnd5eSavingThrowMessageResponse messageID={messageID as QLabMessageID} message={message.data}/>
                  : <></>)}
              {Object.entries(messages).length === 0 && <span className="text-white/80 text-sm italic">
                Awaiting saving throws.
              </span>}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>;
}
