import {ConstantOperation, Optional, ValueOperation, VisitResult, walkTree} from "common/types/index.ts";
import {SceneID, SceneTreeFile, SceneTreeFolder} from "common/legends/index.ts";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputOptionalSelect} from "#lib/components/input/input-optional-select.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {useGame} from "../../../routes/game/index.ts";
import {MutableRef} from "common/ref";

export type InputOptionalSceneProps = {
  value: MutableRef<Optional<SceneID>, ValueOperation<Optional<SceneID>, ConstantOperation>[]>;
};
export function InputOptionalScene({value}: InputOptionalSceneProps) {
  const game = useGame();

  const scenes = useComputedValue(game, game => {
    let scenes: {[sceneID in SceneID]: string} = {};
    walkTree(game.scenes, {
      visit(value: { type: "folder"; data: SceneTreeFolder } | { type: "scene"; data: SceneTreeFile }): VisitResult | void {
        if (value.type === "scene") {
          scenes[value.data.id] = value.data.name
        }
      }
    });
    return scenes;
  });

  return <InputGroup>
    <InputGroupLabel>Scene</InputGroupLabel>
    <InputOptionalSelect value={value} values={Object.keys(scenes) as SceneID[]} labels={scenes} />
  </InputGroup>
}