import {
  QLabMessageChangeset,
  QLabMessageID,
  QLabResourceChangeset,
  QLabResourceID,
  QLabStoreChangeset,
  QLabStoreID
} from "common/qlab/index.ts";
import {AccessToken} from "common/access-token";
import {QLabClientListener} from "./q-lab-client-listener.ts";
import {QLabClient, RequestMessagesParams} from "./q-lab-client.ts";
import {fileServiceUrl, qlabApiServiceUrl, qlabWsServiceUrl} from "#env";
import {DefaultQLabServiceSocket, QLabServiceSocket} from "../service/socket/index.ts";
import {QLabServiceDatastore} from "../service/q-lab-service-datastore.ts";
import {QLabClientObserver} from "./q-lab-client-observer.ts";
import {QLabServiceObserver} from "../service/index.ts";
import {Unsubscribe} from "common/subscription";
import {UserID} from "common/legends/index.ts";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";

export class DefaultQLabClient implements QLabClient {
  private readonly serviceSocket: QLabServiceSocket;
  private readonly serviceDatastore: QLabServiceDatastore;
  constructor(userID: UserID, authToken: string, accessTokens: AccessToken[]) {
    this.serviceSocket = new DefaultQLabServiceSocket(qlabApiServiceUrl, qlabWsServiceUrl, fileServiceUrl, authToken, accessTokens);
    this.serviceDatastore = new QLabServiceDatastore(userID);
  }

  private subscriptions: Map<QLabStoreID, QLabClientObserver> = new Map<QLabStoreID, QLabClientObserver>();
  private getObserver(storeId: QLabStoreID): QLabClientObserver {
    if (!this.subscriptions.has(storeId)) this.subscriptions.set(storeId, new QLabClientObserver(new QLabServiceObserver(
      this.serviceSocket,
      this.serviceDatastore,
      storeId
    )));
    return this.subscriptions.get(storeId)!
  }

  subscribe(storeId: QLabStoreID, listener: QLabClientListener): Unsubscribe {
    return this.getObserver(storeId).subscribe(listener);
  }
  async applyToStore(storeId: QLabStoreID, changesets: QLabStoreChangeset[]): Promise<void> {
    return this.getObserver(storeId).applyToStore(changesets);
  }
  async applyToResource(storeId: QLabStoreID, resourceId: QLabResourceID, changesets: QLabResourceChangeset[]): Promise<void> {
    return this.getObserver(storeId).applyToResource(resourceId, changesets);
  }
  async applyToMessage(storeId: QLabStoreID, messageId: QLabMessageID, changesets: QLabMessageChangeset[]): Promise<void> {
    return this.getObserver(storeId).applyToMessage(messageId, changesets);
  }
  async stream(storeId: QLabStoreID, stream: QLabStream): Promise<void> {
    return this.getObserver(storeId).stream(stream);
  }

  async requestMessages(storeId: QLabStoreID, params: RequestMessagesParams): Promise<void> {
    return this.getObserver(storeId).requestMessages(params);
  }
}
