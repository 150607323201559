import {Asset, AssetID, assetType, Scene, SceneID, sceneType} from "common/legends/index.ts";
import {MapType, ObjectType, stringType, Tag, tagListType} from "common/types/index.ts";

export type LegendsFile = {
  "scenes": {[sceneID in SceneID]: {
    name: string,
    tags: Tag[],
    data: Scene
  }};
  "assets": {[assetID in AssetID]: {
    name: string,
    tags: Tag[],
    data: Asset
  }}
};

export const legendsFileType = new ObjectType({
  scenes: new MapType(new ObjectType({
    name: stringType,
    tags: tagListType,
    data: sceneType
  })),
  assets: new MapType(new ObjectType({
    name: stringType,
    tags: tagListType,
    data: assetType
  }))
}, v => {
  if (v["characters"] !== undefined) {
    v["assets"] = v["characters"];
    delete v["characters"]
  }

  return v;
});
