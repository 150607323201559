import {SetFn} from "common/types/index.ts";
import {Dnd5eCondition, DND_5E_CONDITIONS} from "common/legends/index.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React, {Ref, useCallback} from "react";
import {twMerge} from "tailwind-merge";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {Dnd5eConditionImmunityModifier, Dnd5eConditionImmunityModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-condition-immunity-modifier.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {FaTrash} from "react-icons/fa";
import {CONDITION_IMAGE} from "../dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eConditionImmunitiesViewProps = {
  value: MutableRef<Dnd5eConditionImmunityModifier, Dnd5eConditionImmunityModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
}

export function Dnd5eConditionImmunityModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eConditionImmunitiesViewProps) {
  const resolvedImmunities = useObservable(pipe(
    value.observe,
    map(v => v.conditions),
    distinct()
  ), [], [value.observe]);

  const toggleImmunity = useCallback((condition: Dnd5eCondition) => value.apply(prev => [{
    type: "update-conditions",
    operations: prev.conditions.includes(condition)
      ? SetFn.delete(condition)
      : SetFn.insert(condition)
  }]), [value.apply]);
  const [expanded, toggleExpanded] = useToggle(false);

  return (<div role="list-item">
    <ButtonBar ref={dragRefPreview}>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <span className="px-4 font-bold flex items-center">Condition Immunities</span>
        <div className="shrink-0 flex flex-row gap-1 px-2 py-2 flex-1 overflow-hidden">
          {resolvedImmunities.map(condition => <div
            className="shrink-0 basis-6 inline-flex flex-row items-center h-8 gap-1"
            key={condition} title={condition}>
            <img className="w-6 h-6" alt={condition} src={CONDITION_IMAGE[condition]} />
          </div>)}
        </div>
      </ExpandableLabel>
      {!readOnly && <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
    </ButtonBar>
    {expanded && <Fieldset className="gap-0.5">
      {DND_5E_CONDITIONS.map(condition => <ButtonBar key={condition}>
        <InputGroup className="flex-1 pl-0 rounded-l-full ml-2" onClick={() => toggleImmunity(condition)}>
          <InputGroupLabel className="flex-1 flex items-center gap-2">
            <img className={twMerge(
              "w-8 h-8",
              !resolvedImmunities.includes(condition) ? "opacity-50" : ""
            )} alt={condition} src={CONDITION_IMAGE[condition]} />
            <span className="flex-1">{condition.toUpperCase()}</span>
          </InputGroupLabel>
        </InputGroup>
      </ButtonBar>)}
    </Fieldset>}
  </div>);
}