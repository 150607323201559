import {Observable} from "../observable.ts";

export function skip<T>(qty: number) {
  return (observe: Observable<T>): Observable<T> => {
    let amount = qty;
    return (observer) => observe({
      next: (value: T) => {
        if (amount <= 0) observer.next(value);
        amount --;
      },
      error: observer.error,
      complete: observer.complete
    });
  };
}
