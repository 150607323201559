import {LayoutPath} from "./layout-path.ts";
import {Stack, StackId, StackType} from "../stack/index.ts";
import {Splitter, SplitterId} from "./splitter.ts";
import {Empty} from "./empty.ts";
import {CreateStackLayoutOperation, LayoutOperation} from "./layout-operation.ts";
import {ProxyRegion} from "#lib/components/index.ts";
import {applyAll} from "common/types/index.ts";

export type Layout<Value> = Splitter<Value> | Stack<Value> | Empty;

export const SPLITTER_WIDTH = 8;
export const SPLITTER_HEIGHT = 8;
export const STACK_HEADER_HEIGHT = 48;
export const LAYOUT_ACTION_WIDTH = 72;
export const LAYOUT_ACTION_HEIGHT = 72;

export type LayoutAction<Value> = Omit<CreateStackLayoutOperation<Value>, "value">;

export const Layout = {
  findPathAtPoint: (layout: Layout<any>, [sx, sy]: [number, number], [width, height]: [number, number]): LayoutPath => {
    switch (layout.type) {
      case "empty": return [];
      case "column": {
        let sum = 0;
        for (let i = 0; i < layout.items.length; i ++) {
          const remainingWidth = (width - ((layout.items.length - 1) * SPLITTER_WIDTH));
          const item = layout.items[i];
          if (sum + item.weight * remainingWidth + SPLITTER_WIDTH / 2 > sx) {
            return [i, ...Layout.findPathAtPoint(
              item.content,
              [sx - sum, sy],
              [remainingWidth * item.weight, height]
            )];
          } else {
            sum += item.weight * remainingWidth + SPLITTER_WIDTH;
          }
        }
        break;
      }
      case "row": {
        const remainingHeight = (height - ((layout.items.length - 1) * SPLITTER_HEIGHT));
        let sum = 0;
        for (let i = 0; i < layout.items.length; i ++) {
          const item = layout.items[i];
          if (sum + item.weight * remainingHeight + SPLITTER_HEIGHT / 2 > sy) {
            return [i, ...Layout.findPathAtPoint(
              item.content,
              [sx, sy - sum],
              [width, remainingHeight * item.weight]
            )];
          } else {
            sum += item.weight * remainingHeight + SPLITTER_HEIGHT;
          }
        }
        break;
      }
      case "stack": return [];
    }
    return [];
  },
  findLocalPoint: (layout: Layout<any>, path: number[], [sx, sy]: [number, number], [width, height]: [number, number]): [number, number, number, number] => {
    if (path.length === 0) return [sx, sy, width, height];
    switch (layout.type) {
      case "row": {
        const remainingHeight = height - ((layout.items.length - 1) * SPLITTER_HEIGHT);
        let sum = 0;
        for (let i = 0; i < path[0]; i ++) {
          sum += layout.items[i].weight * remainingHeight + SPLITTER_HEIGHT;
        }
        const item = layout.items[path[0]];
        return Layout.findLocalPoint(
          item.content,
          path.slice(1),
          [sx, sy - sum],
          [width, remainingHeight * item.weight]
        );
      }
      case "column": {
        const remainingWidth = width - ((layout.items.length - 1) * SPLITTER_WIDTH);
        let sum = 0;
        for (let i = 0; i < path[0]; i ++) {
          sum += layout.items[i].weight * remainingWidth + SPLITTER_WIDTH;
        }
        const item = layout.items[path[0]];
        return Layout.findLocalPoint(
          item.content,
          path.slice(1),
          [sx - sum, sy],
          [remainingWidth * item.weight,  height]
        );
      }
    }

    return [sx, sy, width, height];
  },
  getAncestors: <T>(layout: Layout<T>, path: number[]): Layout<T>[] => {
    const ancestors = [];
    for (let i = 0; i < path.length; i ++) {
      ancestors.push(Layout.getLayoutItem(layout, path.slice(0, i)));
    }
    ancestors.push(Layout.getLayoutItem(layout, path));
    return ancestors;
  },
  getLayoutAction: <Value>(layout: Layout<Value>, [sx, sy]: [number, number], [width, height]: [number, number]): LayoutAction<Value> | undefined => {
    const path = Layout.findPathAtPoint(layout, [sx, sy], [width, height]);
    const [lx, ly, lw, lh] = Layout.findLocalPoint(layout, path, [sx, sy], [width, height]);

    const ancestors = Layout.getAncestors(layout, path);

    let [isFirstRow, isLastRow, isFirstColumn, isLastColumn] = [true, true, true, true];
    let [preRowActions, postRowActions, preColumnActions, postColumnActions]: [LayoutAction<Value>[], LayoutAction<Value>[], LayoutAction<Value>[], LayoutAction<Value>[]] = [[], [], [], []];
    for (let i = path.length; i >= 0; i --) {
      const ancestor = ancestors[i];
      if (ancestor.type === "empty") {
      } else if (ancestor.type === "stack") {
        if (i === 1) {
          const parent = Layout.getLayoutItem(layout, path.slice(0, i - 1));
          if (parent.type === "column") {
            preRowActions.push({type: "create-stack", direction: "row", path: [...path, 0]});
            postRowActions.push({type: "create-stack", direction: "row", path: [...path, 1]});
          }
        }
      } else if (ancestor.type === "row") {
        if (i === 1) {
          const parent = Layout.getLayoutItem(layout, path.slice(0, i - 1));
          if (parent.type === "column") {
            if (isFirstRow) preRowActions.push({type: "create-stack", direction: "row", path: [...path.slice(0, i), path[i]]});
            if (isLastRow) postRowActions.push({type: "create-stack", direction: "row", path: [...path.slice(0, i), path[i]+1]});
          }
        }

        if (path[i] !== 0) isFirstRow = false;
        if (ancestor.items.length !== path[i] + 1) isLastRow = false;
      } else if (ancestor.type === "column") {
        if (isFirstColumn) preColumnActions.push({type: "create-stack", direction: "column", path: [...path.slice(0, i), path[i]]});
        if (isLastColumn) postColumnActions.push({type: "create-stack", direction: "column", path: [...path.slice(0, i), path[i] + 1]});
        if (path[i] !== 0) isFirstColumn = false;
        if (ancestor.items.length !== path[i] + 1) isLastColumn = false;
      }
    }

    const item = Layout.getLayoutItem(layout, path);
    const isStack = item.type === "stack";
    if (isStack && ly >= 0 && ly < STACK_HEADER_HEIGHT) {
      return undefined;
    }
    const stackYOffset = isStack && ly >= STACK_HEADER_HEIGHT ? STACK_HEADER_HEIGHT : 0;

    for (let i = preRowActions.length - 1; i >= 0; i --) {
      if (ly - stackYOffset < LAYOUT_ACTION_HEIGHT * (preRowActions.length - i) - (isFirstRow || isStack ? 0 : (LAYOUT_ACTION_HEIGHT + SPLITTER_HEIGHT) / 2)) {
        return preRowActions[i];
      }
    }

    for (let i = postRowActions.length - 1; i >= 0; i --) {
      if (ly > lh - LAYOUT_ACTION_HEIGHT * (postRowActions.length - i) + (isLastRow ? 0 : (LAYOUT_ACTION_HEIGHT + SPLITTER_HEIGHT) / 2)) {
        return postRowActions[i];
      }
    }

    for (let i = preColumnActions.length - 1; i >= 0; i --) {
      if (lx < LAYOUT_ACTION_WIDTH * (preColumnActions.length - i) - (isFirstColumn ? 0 : (LAYOUT_ACTION_HEIGHT + SPLITTER_HEIGHT) / 2)) {
        return preColumnActions[i];
      }
    }

    for (let i = postColumnActions.length - 1; i >= 0; i --) {
      if (lx > lw - LAYOUT_ACTION_WIDTH * (postColumnActions.length - i) + (isLastColumn ? 0 : (LAYOUT_ACTION_WIDTH + SPLITTER_WIDTH) / 2)) {
        return postColumnActions[i];
      }
    }

    return undefined;
  },
  getProxyRegion: <Value>(layout: Layout<Value>, operation: LayoutAction<Value>, [width, height]: [number, number]): ProxyRegion => {
    if (operation.path.length === 1) {
      const index = operation.path[0];
      if (layout.type === "column" && operation.direction === "column") {
        const remainingWidth = width - layout.items.length * SPLITTER_WIDTH;
        if (index === 0) {
          return {x: 0, y: 0, width: remainingWidth * layout.items[0].weight / 2, height: height};
        } else if (index >= layout.items.length) {
          const subWidth = remainingWidth * layout.items[layout.items.length - 1].weight / 2;
          return {x: width - subWidth, y: 0, width: subWidth, height: height};
        } else {
          const offsetX = layout.items.slice(0, index - 1).reduce((sum, item) => sum + item.weight * remainingWidth + SPLITTER_WIDTH, 0) + remainingWidth * layout.items[index -1].weight * 2 / 3;
          const subWidth = remainingWidth * (layout.items[index - 1].weight + layout.items[index].weight) / 3;
          return {x: offsetX, y: 0, width: subWidth, height: height};
        }
      } else if (layout.type === "row" && operation.direction === "row") {
        const remainingHeight = height - layout.items.length * SPLITTER_WIDTH;
        if (index === 0) {
          return {x: 0, y: 0, width: width, height: remainingHeight * layout.items[0].weight / 2};
        } else if (index >= layout.items.length) {
          const subHeight = remainingHeight * layout.items[layout.items.length - 1].weight / 2;
          return {x: 0, y: height - subHeight, width: width, height: subHeight};
        } else {
          const offsetY = layout.items.slice(0, index - 1).reduce((sum, item) => sum + item.weight * remainingHeight + SPLITTER_HEIGHT, 0) + remainingHeight * layout.items[index -1].weight * 2 / 3;
          const subHeight = remainingHeight * (layout.items[index - 1].weight + layout.items[index].weight) / 3;
          return {x: 0, y: offsetY, width: width, height: subHeight};
        }
      } else {
        if (operation.direction === "column") {
          if (index === 0) {
            return {x: 0, y: 0, width: width / 2, height: height};
          } else {
            return {x: width / 2, y: 0, width: width / 2, height: height};
          }
        } else if (operation.direction === "row") {
          if (index === 0) {
            return {x: 0, y: 0, width: width, height: height / 2};
          } else {
            return {x: 0, y: height / 2, width: width, height: height / 2};
          }
        }
      }
      return {x: 0, y: 0, width: width, height: height};
    } else {
      const [firstPath, ...remainingPath] = operation.path;
      if (layout.type === "column") {
        const splitterItem = layout.items[firstPath];
        const remainingWidth = width - (layout.items.length - 1) * SPLITTER_WIDTH;
        const subProxyRegion = Layout.getProxyRegion(splitterItem.content, {...operation, path: remainingPath}, [remainingWidth * splitterItem.weight, height]);
        if (!subProxyRegion) return subProxyRegion;
        const offsetX = layout.items.slice(0, firstPath).reduce((sum, item) => sum + item.weight * remainingWidth + SPLITTER_WIDTH, 0);
        return {x: offsetX + subProxyRegion.x, y: subProxyRegion.y, width: subProxyRegion.width, height: subProxyRegion.height};
      } else if (layout.type === "row") {
        const splitterItem = layout.items[firstPath];
        const remainingHeight = height - (layout.items.length - 1) * SPLITTER_HEIGHT;
        const subProxyRegion = Layout.getProxyRegion(splitterItem.content, {...operation, path: remainingPath}, [width, remainingHeight * splitterItem.weight]);
        if (!subProxyRegion) return subProxyRegion;
        const offsetY = layout.items.slice(0, firstPath).reduce((sum, item) => sum + item.weight * remainingHeight + SPLITTER_WIDTH, 0);
        return {x: subProxyRegion.x, y: offsetY + subProxyRegion.y, width: subProxyRegion.width, height: subProxyRegion.height};
      } else {
        throw new Error("Invalid Region");
      }
    }
  },
  getLayoutItem: (layout: Layout<any>, path: number[]): Layout<any> => {
    if (path.length === 0) return layout;
    switch (layout.type) {
      case "row": return Layout.getLayoutItem(layout.items[path[0]].content, path.slice(1));
      case "column": return Layout.getLayoutItem(layout.items[path[0]].content, path.slice(1));
    }
    throw new Error("Could not find layout item.");
  },
  removeItem: <Value>(prevValue: Splitter<Value>, index: number): Layout<Value> => {
    const items = prevValue.items;
    if (index === 0) {
      if (items.length > 1) {
        return ({
          ...prevValue,
          items: [
            {...items[1], weight: items[1].weight + items[0].weight},
            ...items.slice(2)
          ]
        });
      } else {
        return ({
          ...prevValue,
          items: []
        });
      }
    } else if (index === items.length - 1) {
      return ({
        ...prevValue,
        items: [
          ...items.slice(0, index-1),
          {...items[index-1], weight: items[index-1].weight + items[index].weight}
        ]});
    } else {
      return({
        ...prevValue,
        items: [
          ...items.slice(0, index-1),
          {...items[index-1], weight: items[index-1].weight + (items[index].weight / 2)},
          {...items[index+1], weight: items[index+1].weight + (items[index].weight / 2)},
          ...items.slice(index + 2)
        ]
      });
    }
  },
  isEmpty: (item: Layout<any>): boolean => {
    switch (item.type) {
      case "stack": return Stack.isEmpty(item);
      case "column": return item.items.length === 0 || item.items.map(item => item.content).every(Layout.isEmpty);
      case "row": return item.items.length === 0 || item.items.map(item => item.content).every(Layout.isEmpty);
      default: return false;
    }
  },
  applyToValue: <Value, Operation>(stackType: StackType<Value, Operation>, value: Layout<Value>, operation: LayoutOperation<Value, Operation>): Layout<Value> => {
    if (operation.path.length === 0) {
      if ((value.type === "row" || value.type === "column") && operation.type === "adjust-split") {
        const preHeight = value.items.slice(0, operation.index).reduce((a,b) => a + b.weight, 0);
        const prevSplit = value.items[operation.index].weight;
        const nextSplit = value.items[operation.index + 1].weight;
        const splitPoint = Math.min(Math.max(preHeight, operation.splitPoint), preHeight + prevSplit + nextSplit);

        const newPrevSplit = splitPoint - preHeight;
        const newNextSplit = (prevSplit + nextSplit) - newPrevSplit;

        return ({
          ...value,
          items: [
            ...value.items.slice(0, operation.index),
            {...value.items[operation.index], weight: newPrevSplit},
            {...value.items[operation.index+1], weight: newNextSplit},
            ...value.items.slice(operation.index + 2)
          ]
        })
      } else if (value.type === "stack" && operation.type === "apply-to-stack") {
        return applyAll(stackType, value, operation.operations);
      }
    } else if (operation.path.length === 1) {
      if (operation.type === "create-stack") {
        const index = operation.path[0];
        if (value.type !== "row" && operation.direction === "row") {
          if (index === 0) {
            return ({
              type: "row",
              items: [
                {id: SplitterId.generate(), weight: 0.5, content: operation.value},
                {id: SplitterId.generate(), weight: 0.5, content: value},
              ]
            });
          } else {
            return ({
              type: "row",
              items: [
                {id: SplitterId.generate(), weight: 0.5, content: value},
                {id: SplitterId.generate(), weight: 0.5, content: operation.value}
              ]
            });
          }
        } else if (value.type !== "column" && operation.direction === "column") {
          if (index === 0) {
            return ({
              type: "column",
              items: [
                {id: SplitterId.generate(), weight: 0.5, content: operation.value},
                {id: SplitterId.generate(), weight: 0.5, content: value},
              ]
            });
          } else {
            return ({
              type: "column",
              items: [
                {id: SplitterId.generate(), weight: 0.5, content: value},
                {id: SplitterId.generate(), weight: 0.5, content: operation.value}
              ]
            });
          }
        } else if ((value.type === "row" && operation.direction === "row") || (value.type === "column" && operation.direction === "column")) {
          if (index === 0) {
            return ({
              ...value,
              items: [
                {id: StackId.generate(), weight: value.items[0].weight / 2, content: operation.value},
                {...value.items[0], weight: value.items[0].weight / 2},
                ...value.items.slice(1)
              ]
            });
          } else if (index === value.items.length) {
            return ({
              ...value,
              items: [
                ...value.items.slice(0, value.items.length - 1),
                {...value.items[value.items.length - 1], weight: value.items[value.items.length - 1].weight / 2},
                {id: StackId.generate(), weight: value.items[value.items.length - 1].weight / 2, content: operation.value}
              ]
            });
          } else {
            return ({
              ...value,
              items: [
                ...value.items.slice(0, index - 1),
                {...value.items[index - 1], weight: value.items[index - 1].weight * 2 / 3},
                {id: StackId.generate(), weight: (value.items[index - 1].weight + value.items[index].weight) / 3, content: operation.value},
                {...value.items[index], weight: value.items[index].weight * 2 / 3},
                ...value.items.slice(index+1)
              ]
            });
          }
        }
      }
    }

    if (operation.path.length >= 1) {
      const index = operation.path[0];
      if (value.type === "row" || value.type === "column") {
        const items = value.items;
        const item = items[index];
        const newItemContent = Layout.applyToValue(stackType, item.content, {...operation, path: operation.path.slice(1)});
        if (Layout.isEmpty(newItemContent)) {
          if (items.length === 1) {
            return ({...value, items: []});
          } else if (index === 0) {
            return ({
              ...value,
              items: [
                {...items[1], weight: items[1].weight + item.weight},
                ...items.slice(2)
              ]
            });
          } else if (index + 1 === items.length) {
            return ({
              ...value,
              items: [
                ...items.slice(0, index - 1),
                {...items[items.length - 2], weight: items[items.length - 2].weight + item.weight}
              ]
            });
          } else {
            return ({
              ...value,
              items: [
                ...items.slice(0, index - 1),
                {...items[index - 1], weight: items[index - 1].weight + item.weight / 2},
                {...items[index + 1], weight: items[index + 1].weight + item.weight / 2},
                ...items.slice(index + 2)
              ]
            });
          }
        } else if ((newItemContent.type === "row" || newItemContent.type === "column") && newItemContent.items.length === 1) {
          if (newItemContent.items[0].content.type === value.type) {
            return ({
              ...value,
              items: [
                ...value.items.slice(0, index),
                ...newItemContent.items[0].content.items.map(subItem => ({...subItem, weight: subItem.weight * item.weight})),
                ...value.items.slice(index + 1)
              ]
            });
          } else {
            return ({
              ...value,
              items: [
                ...value.items.slice(0, index),
                {...newItemContent.items[0], weight: item.weight},
                ...value.items.slice(index + 1)
              ]
            });
          }
        } else if (newItemContent.type === value.type) {
          return ({
            ...value,
            items: [
              ...value.items.slice(0, index),
              ...newItemContent.items.map(subItem => ({...subItem, weight: subItem.weight * item.weight})),
              ...value.items.slice(index + 1)
            ]
          });
        } else {
          return ({
            ...value,
            items: [
              ...value.items.slice(0, index),
              { ...item, content: newItemContent},
              ...value.items.slice(index + 1)
            ]
          });
        }
      }
    }

    throw new Error("Unsupported operation:" + JSON.stringify(operation) + " on " + JSON.stringify(value));
  },
  transformPath: <Value, Operation>(value: Layout<Value>, operation: LayoutOperation<Value, Operation>, path: LayoutPath): LayoutPath => {
    if (operation === undefined) return path;
    if (path.length === 0) return [];

    if (operation.path.length === 1 && operation.type === "create-stack") {
      const index = operation.path[0];
      if ((value.type === "column" && operation.direction === "column") || (value.type === "row" && operation.direction === "row")) {
        if (index <= path[0]) {
          return [path[0]+1, ...path.slice(1)];
        } else {
          return path;
        }
      } else {
        if (index === 0) {
          return [1, ...path];
        } else {
          return [0, ...path];
        }
      }
    } else {
      if (operation.path[0] === path[0]) {
        if (value.type === "row" || value.type === "column") {
          return [operation.path[0], ...Layout.transformPath(value.items[operation.path[0]].content, {...operation, path: operation.path.slice(1)}, path.slice(1))];
        } else {
          throw new Error("Invalid action.");
        }
      } else {
        return path;
      }
    }
  },
  migrateValue: <Value, Operation>(stackType: StackType<Value, Operation>, value: Layout<Value>): Layout<Value> => {
    switch (value?.type) {
      case "empty": return value;
      case "column": case "row": return ({
        ...value,
        items: value.items.map(item => ({
          ...item,
          content: Layout.migrateValue(stackType, item.content)
        }))
      });
      case "stack": return stackType.migrateValue(value);
      default: return value;
    }
  }
};


