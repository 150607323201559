import {ConstantOperation, constantType, Point, ValueOperation, ValueType} from "common/types/index.ts";
import {AssetID, NodeID} from "common/legends/index.ts";
import {z} from "zod";

export const AssetContextMenu = z.object({
  clientPos: Point,
  screenPos: Point,
  assetID: AssetID
});
export type AssetContextMenu = z.infer<typeof AssetContextMenu>;

export const TokenContextMenu = z.object({
  clientPos: Point,
  worldPos: Point,
  selectedNodeID: z.optional(NodeID),
  targetNodeIDs: z.array(NodeID)
});
export type TokenContextMenu = z.infer<typeof TokenContextMenu>;

export const ContextMenu = z.optional(z.discriminatedUnion("type", [
  z.object({type: z.literal("token"), data: TokenContextMenu}),
  z.object({type: z.literal("asset"), data: AssetContextMenu})
]));
export type ContextMenu = z.infer<typeof ContextMenu>;

export type ContextMenuOperation = ValueOperation<ContextMenu, ConstantOperation>;

export const contextMenuType = new ValueType(constantType);
