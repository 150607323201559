import React, {useMemo} from "react";
import {Dnd5eAttribute} from "common/legends/asset/index.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {useRollAbilityCheck} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {useSelectedNodeIDRef, useSelectedSheetReference} from "../../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Dice} from "common/dice/dice.ts";
import {useGlobalFeatures} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {dnd5eSheetAbilityCheckHasAdvantage, dnd5eSheetAbilityCheckHasDisadvantage} from "../../../../panel/sheet/editor/dnd-5e/common/dnd-5e-sheet-ability-check-proficiency.ts";
import {dnd5eSheetAbilityCheckModifiers} from "../../../../panel/sheet/editor/dnd-5e/common/dnd-5e-sheet-ability-check-modifiers.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";

export function QuickAccessAbilityCheck({attribute}: {
  attribute: Dnd5eAttribute
}) {
  const nodeIDRef = useSelectedNodeIDRef();
  const sheetRef = useSheetSignal(useSelectedSheetReference());
  const rollAbilityCheck = useRollAbilityCheck(nodeIDRef.observe, sheetRef, attribute);
  const sheet = useRefValue(sheetRef);

  const globalEffects = useGlobalFeatures();
  const hasAdvantage = useObservable(dnd5eSheetAbilityCheckHasAdvantage(sheetRef, globalEffects.observe, attribute), false, [sheet, globalEffects, attribute]);
  const hasDisadvantage = useObservable(dnd5eSheetAbilityCheckHasDisadvantage(sheetRef, globalEffects.observe, attribute), false, [sheet, globalEffects, attribute]);

  const attributeModifier = useObservable(dnd5eSheetAbilityCheckModifiers(sheetRef, globalEffects.observe, attribute), undefined, [sheet, globalEffects, attribute]);
  const contextSignal = useMemo(() => sheetVariablesSignal(sheetRef), [sheet]);
  const contextValue = useRefValue(contextSignal);
  const resolvedAttributeModifier = useMemo(() => attributeModifier ? Dice.toResolvedExpression(attributeModifier, contextValue) : undefined, [attributeModifier, contextValue]);
  const range = useMemo((): [number, number] => attributeModifier ? Dice.getRollRange(attributeModifier, contextValue) : [0, 0], [attributeModifier, contextValue]);

  return (
    <label className="flex-1 flex flex-col items-stretch bg-zinc-900/40 cursor-pointer">
      <span className="font-bold text-center content-center">{attribute.toUpperCase()}</span>
      <span className="shrink-0 basis-6 text-xs italic font-bold text-white/50 text-center whitespace-nowrap" title={resolvedAttributeModifier ? resolvedAttributeModifier : "0"}>
        {Dice.toRangeDisplay(range)}
      </span>
      <DiceButton hasAdvantage={hasAdvantage} hasDisadvantage={hasDisadvantage} className="flex-1" onClick={ev => rollAbilityCheck(ev.shiftKey, ev.ctrlKey)}/>
    </label>
  );
}