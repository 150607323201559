import {BooleanOperation, booleanType, ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";

export type ViewConfiguration = {
  type: "NORTH" | "UP";
  locked: boolean;
};

export type ViewConfigurationOperation =
  | {type: "update-type", operations: ValueOperation<ViewConfiguration["type"], ConstantOperation>[]}
  | {type: "update-locked", operations: BooleanOperation[]};

export const viewConfigurationType: Type<ViewConfiguration, ViewConfigurationOperation> = new ObjectType({
  type: new ValueType(constantType),
  locked: booleanType
});

export const ViewConfigurationFn = {
  DEFAULT: {
    type: "NORTH",
    locked: false
  } satisfies ViewConfiguration
}