import {Tree} from "./tree.ts";
import {TreePath} from "./tree-path.ts";

export function updateTreeValue<Item extends Tree<Item>>(values: Item[], path: TreePath, fn: (value: Item) => Item): Item[] {
  if (path.length === 1) {
    const [pathIndex] = path;
    const newItem = fn(values[pathIndex]);
    return [
      ...values.slice(0, pathIndex),
      newItem,
      ...values.slice(pathIndex + 1)
    ];
  } else if (path.length > 1) {
    const [pathIndex, ...remainingPath] = path;
    const value = values[pathIndex];
    if (Tree.isParentNode(value)) {
      const newChildren = updateTreeValue(value.data.children, remainingPath, fn);
      return [
        ...values.slice(0, pathIndex),
        {
          ...value,
          data: {
            ...value.data,
            children: newChildren
          }
        },
        ...values.slice(pathIndex + 1)
      ];
    } else throw new Error("Cannot update tree at " + JSON.stringify(path));
  } else {
    throw new Error("Cannot update tree at " + JSON.stringify(path));
  }
}

