import {
  ExtractOperation,
  ExtractValue,
  MultiType,
  Optional,
  OptionalOperation,
  OptionalType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {assetType, sceneType} from "#common/legends/index.ts";

export interface ResourceTypes {}
export type ResourceType = keyof ResourceTypes;
export type TypedResourceValue<Type extends ResourceType> = ExtractValue<ResourceTypes[Type]>;
export type TypedResourceOperation<Type extends ResourceType> = ExtractOperation<ResourceTypes[Type]>;
export type ResourceValue = {
  [Type in ResourceType]: {
    type: Type;
    data: TypedResourceValue<Type>
  }
}[ResourceType];
export type ResourceOperation = {
  [Type in ResourceType]: {
    type: Type;
    operations: TypedResourceOperation<Type>[]
  }
}[ResourceType];

export const resourceType: Type<Optional<ResourceValue>, ValueOperation<Optional<ResourceValue>, OptionalOperation<ResourceOperation>>> = new ValueType(new OptionalType(new MultiType({
  asset: assetType,
  scene: sceneType
})), value => {
  if (value?.type === "character") {
    return ({...value, type: "asset"});
  } else {
    return value;
  }
});
