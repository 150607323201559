import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  ObjectType,
  Optional,
  OptionalType,
  Point,
  PointOperation,
  pointType,
  PropertyRef,
  Size,
  SizeOperation,
  sizeType,
  StringOperation,
  stringType,
  TreeOperation,
  TreeType,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {Node, NodeOperation, nodeType} from "../../node/index.ts";
import {generateTokenID, SheetID, TokenID} from "#common/legends/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const SheetPolicy = z.enum(["copy", "link"]);
export type SheetPolicy = z.infer<typeof SheetPolicy>;

export type Token = {
  tokenID: TokenID;
  name: string;
  icon: FileReference;
  origin: Point;
  pivot: Point;
  size: Size;
  children: Node[];
  sheetPolicy: SheetPolicy;
  sheetId: Optional<SheetID>;
  mountable: boolean;
  attachable: boolean;
};

export type TokenOperation =
  | {type: "update-token-i-d", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-icon", operations: FileReferenceOperation[]}
  | {type: "update-origin", operations: PointOperation[]}
  | {type: "update-pivot", operations: PointOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  | {type: "update-children", operations: TreeOperation<Node, NodeOperation>[]}
  | {type: "update-sheet-policy", operations: ValueOperation<SheetPolicy, ConstantOperation>[]}
  | {type: "update-sheet-id", operations: ValueOperation<Optional<SheetID>, ConstantOperation>[]}
  ;

export const tokenType: Type<Token, TokenOperation> = new ObjectType({
  tokenID: constantType,
  name: stringType,
  icon: fileReferenceType,
  origin: pointType,
  pivot: pointType,
  size: sizeType,
  mountable: booleanType,
  attachable: booleanType,
  children: new TreeType<Node, NodeOperation>(nodeType),
  sheetPolicy: new ValueType(constantType),
  sheetId: new ValueType(new OptionalType(constantType)),
}, (value: any) => {
  let v = {...value};
  if (v.pivot === undefined) v.pivot = v.origin;
  if (v["mountable"] === undefined) v["mountable"] = false;
  if (v["attachable"] === undefined) v["attachable"] = v["mountable"];
  return v;
});

export const Token = {
  getTokenID: (token: Token): TokenID => token.tokenID,
  getName: (token: Token): string => token.name,
  getIcon: (token: Token): FileReference => token.icon,
  getChildren: (token: Token): Node[] => token.children,
  getOrigin: (token: Token): Point => token.origin,
  getSize: (token: Token): Size => token.size,
  getSheetId: (token: Token): Optional<SheetID> => token.sheetId,
  getSheetPolicy: (token: Token): SheetPolicy => token.sheetPolicy,
  copyToken: (token: Token): Token => ({...token, tokenID: generateTokenID()}),
  DEFAULT: (): Token => ({
    tokenID: generateTokenID(),
    name: "Token",
    icon: undefined,
    sheetPolicy: "copy",
    mountable: true,
    attachable: true,
    sheetId: undefined,
    origin: [32, 32],
    pivot: [32, 32],
    size: [64, 64],
    children: []
  })
}

export const TokenOperation = {
  updateName: (operations: StringOperation[]): TokenOperation[] => [{type: "update-name", operations}],
  updateIcon: (operations: FileReferenceOperation[]): TokenOperation[] => [{type: "update-icon", operations}],
  updateSheetId: (operations: ValueOperation<Optional<SheetID>, ConstantOperation>[]): TokenOperation[] => [{type: "update-sheet-id", operations}],
  updateSheetPolicy: (operations: ValueOperation<SheetPolicy, ConstantOperation>[]): TokenOperation[] => [{type: "update-sheet-policy", operations}],
  updateChildren: (operations: TreeOperation<Node, NodeOperation>[]): TokenOperation[] => [{type: "update-children", operations}],
  updateSize: (operations: SizeOperation[]): TokenOperation[] => [{type: "update-size", operations}],
  updateOrigin: (operations: PointOperation[]): TokenOperation[] => [{type: "update-origin", operations}]
};

export function TokenSignals(value: MutableRef<Token, TokenOperation[]>) {
  return {
    tokenID: PropertyRef<Token, TokenOperation, TokenID, ConstantOperation>(Token.getTokenID, operations => [{type: "update-token-i-d", operations}])(value),
    nameRef: PropertyRef<Token, TokenOperation, string, StringOperation>(Token.getName, TokenOperation.updateName)(value),
    icon: PropertyRef<Token, TokenOperation, FileReference, FileReferenceOperation>(Token.getIcon, TokenOperation.updateIcon)(value),
    size: PropertyRef<Token, TokenOperation, Size, SizeOperation>(Token.getSize, TokenOperation.updateSize)(value),
    origin: PropertyRef<Token, TokenOperation, Point, PointOperation>(Token.getOrigin, TokenOperation.updateOrigin)(value),
    pivotRef: PropertyRef<Token, TokenOperation, Point, PointOperation>(token => token.pivot, operations => [{type: "update-pivot", operations}])(value),
    children: PropertyRef<Token, TokenOperation, Node[], TreeOperation<Node, NodeOperation>>(Token.getChildren, TokenOperation.updateChildren)(value),
    mountable: PropertyRef<Token, TokenOperation, boolean, BooleanOperation>(value => value.mountable, operations => [{type: "update-mountable", operations}])(value),
    attachable: PropertyRef<Token, TokenOperation, boolean, BooleanOperation>(value => value.attachable, operations => [{type: "update-attachable", operations}])(value),
    sheetId: PropertyRef<Token, TokenOperation, Optional<SheetID>, ValueOperation<Optional<SheetID>, ConstantOperation>>(Token.getSheetId, TokenOperation.updateSheetId)(value),
    sheetPolicy: PropertyRef<Token, TokenOperation, SheetPolicy, ValueOperation<SheetPolicy, ConstantOperation>>(Token.getSheetPolicy, TokenOperation.updateSheetPolicy)(value)
  };
}