import {
  ConstantOperation,
  constantType,
  ObjectType,
  Optional,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Transform,
  TransformOperation,
  transformType,
  Type,
  ValueOperation,
  ValueType
} from "common/types/index.ts";
import {Tool, ToolOperation, toolType} from "../../common/tool-mode/tool.ts";
import {NodeSelectionRef, SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {ViewConfiguration, ViewConfigurationOperation, viewConfigurationType} from "../common/view-configuration.ts";
import {MutableRef} from "common/ref";
import {NodeId} from "common/legends/node/index.ts";

export type ActiveControllerValue = {
  controllerNodeID: NodeId;
  view: Transform;
};
export type ActiveControllerOperation =
  | {type: "update-controller-node-i-d", operations: ConstantOperation[]}
  | {type: "update-view", operations: TransformOperation[]}
  ;
export const activeControllerType: Type<ActiveControllerValue, ActiveControllerOperation> = new ObjectType({
  controllerNodeID: constantType,
  view: transformType
}, v => {
  if (v.controllerNodeRef) {
    v.controllerNodeID = v.controllerNodeRef.nodeID;
    delete v["controllerNodeRef"];
  }
  return v;
});
export const ActiveControllerFn = {
  expand(value: MutableRef<ActiveControllerValue, ActiveControllerOperation[]>) {
    return {
      viewRef: PropertyRef<ActiveControllerValue, ActiveControllerOperation, Transform, TransformOperation>(
        value => value.view,
        operations => [{type: "update-view", operations}]
      )(value)
    };
  }
};

export type TokenViewportProperties = {
  toolMode: Tool;
  activeController: Optional<ActiveControllerValue>;
  selectedControllerNodeIds: NodeSelectionRef[];
  viewConfiguration: ViewConfiguration;
};
export type TokenViewportPropertiesOperation =
  | {type: "update-tool-mode", operations: ToolOperation[]}
  | {type: "update-active-controller", operations: ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>[]}
  | {type: "update-selected-controller-node-ids", operations: SetOperation<NodeSelectionRef>[]}
  | {type: "update-view-configuration", operations: ViewConfigurationOperation[]}
  ;
export const tokenViewportPropertiesType: Type<TokenViewportProperties, TokenViewportPropertiesOperation> = new ObjectType({
  toolMode: toolType,
  activeController: new ValueType(activeControllerType),
  selectedControllerNodeIds: new SetType<NodeSelectionRef>(SelectionRef.equals),
  viewConfiguration: viewConfigurationType
});

export const TokenViewportPropertiesFn = {
  expand(value: MutableRef<TokenViewportProperties, TokenViewportPropertiesOperation[]>) {
    return ({
      toolMode: PropertyRef<TokenViewportProperties, TokenViewportPropertiesOperation, Tool, ToolOperation>(
        value => value.toolMode,
        operations => [{type: "update-tool-mode", operations}]
      )(value),
      activeController: PropertyRef<TokenViewportProperties, TokenViewportPropertiesOperation, Optional<ActiveControllerValue>, ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>>(
        value => value.activeController,
        operations => [{type: "update-active-controller", operations}]
      )(value),
      selectedControllerNodeIds: SetPropertySignal<TokenViewportProperties, TokenViewportPropertiesOperation, NodeSelectionRef>(
        value => value.selectedControllerNodeIds,
        operations => [{type: "update-selected-controller-node-ids", operations}]
      )(value)
    });
  }
};
