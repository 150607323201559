import {arrayEquals} from "#lib/lang/array.ts";

export type LayoutPath = number[];

export const LayoutPath = {
  equals: (a: LayoutPath, b: LayoutPath) => arrayEquals(a, b),
  getParentPath: (path: LayoutPath): LayoutPath => {
    if (path.length > 0) {
      return path.slice(0, path.length - 1);
    } else {
      throw new Error("No parent path.");
    }
  },
  getLastSegment: (path: LayoutPath): number => {
    if (path.length > 0) {
      return path[path.length - 1];
    } else {
      throw new Error("No parent path.");
    }
  }
};
