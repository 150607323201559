import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import {Node} from "common/legends/index.ts";
import {Tool, ToolOperation} from "../../../common/tool-mode/tool.ts";
import {WallToolView} from "./wall-tool-view.tsx";
import {AreaToolView} from "./area-tool-view.tsx";
import {MutableRef, Ref} from "common/ref";

export type ToolViewProps = {
  projection: Matrix4f;
  view: Transform;
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
};
export function ToolView({projection, view, value, nodes}: ToolViewProps) {
  return <>
    <WallToolView projection={projection} view={view} value={value} nodes={nodes} />
    <AreaToolView projection={projection} view={view} value={value} nodes={nodes} />
  </>
}
