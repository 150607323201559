import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {UserID} from "common/legends/user/index.ts";

export function getGameMasterIDs(database: QLabDatabase): UserID[] {
  let gameMasterIDs: UserID[] = [];
  const store = database.store;
  if (store?.type === "game") {
    gameMasterIDs.push(store.data.owner.id);
    gameMasterIDs.push(
      ...Object.entries(store.data.players).filter(([_, player]) => player.gameMaster)
        .map(([playerID, _]) => playerID as UserID)
    );
  }
  return gameMasterIDs;
}
