import {Button, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Invitation} from "common/legends/index.ts";
import {digest} from "common/crypto/index.ts";
import {useMemo, useState} from "react";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";

export type CreateInvitationModalProps = {
  onClose: () => void;
  onCreate: (invitation: Invitation, code: string) => void;
};

export function CreateInvitationModal({onClose, onCreate}: CreateInvitationModalProps) {
  const code = useMemo<string>(() => Invitation.generate(), []);
  const [label, setLabel] = useState("Invitation");

  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <ModalTitle>Create Invite Link</ModalTitle>
        <InputGroup disabled>
          <InputGroupLabel>Code</InputGroupLabel>
          <Input disabled value={code} />
        </InputGroup>

        <InputGroup>
          <InputGroupLabel>Identifier</InputGroupLabel>
          <Input value={label} onChange={ev => setLabel(ev.target.value)} />
        </InputGroup>

        <Button className="flex-1 rounded" onClick={() => {
          digest(code).then(digest => {
            onCreate({
              label,
              digest
            }, code);
          });
        }}>Invite</Button>
      </ModalBody>
    </Modal>
  )
}