import {Point} from "common/types/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {Line} from "./scene-view.tsx";
import {Vector2} from "common/math/vector/vector2.ts";

export function cubicBezier(p1: Point, p2: Point, controlPoint1: Vector2f, controlPoint2: Vector2f, resolution: number, viewLeft: boolean, viewRight: boolean): Line[] {
  if (controlPoint2[0] === 0 && controlPoint2[1] === 0 && controlPoint1[0] === 0 && controlPoint1[1] === 0) {
    return [{start: p1, end: p2, viewLeft, viewRight}];
  }
  const cp1 = Vector2f.add(p1, controlPoint1);
  const cp2 = Vector2f.add(p2, controlPoint2);
  let lastPoint = p1;
  let lines: Line[] = [];
  for (let i = 1; i <= resolution; i ++) {
    const t = i / resolution;
    const a = Vector2f.lerp(p1, cp1, t);
    const b = Vector2f.lerp(cp1, cp2, t);
    const c = Vector2f.lerp(cp2, p2, t);

    const d = Vector2f.lerp(a, b, t);
    const e = Vector2f.lerp(b, c, t);
    const f = Vector2f.lerp(d, e, t);
    lines.push({start: lastPoint, end: f, viewLeft, viewRight});
    lastPoint = f;
  }
  return lines;
}

export function lerpBezier(p1: Point, p2: Point, controlPoint1: Vector2f, controlPoint2: Vector2f, resolution: number, t: number) {
  const lines = cubicBezier(p1, p2, controlPoint1, controlPoint2, resolution, false, false);
  const totalLength = lines.reduce((sum, line) => sum + Vector2.distance(line.start, line.end), 0);
  const targetLength = totalLength * t;
  let acc = 0;
  for (let i = 0; i < lines.length; i ++) {
    const line = lines[i];
    const dist = Vector2.distance(line.start, line.end);
    const remaining = targetLength - acc;
    if (remaining < dist) {
      const t = remaining / dist;
      return Vector2.lerp(line.start, line.end, t);
    }
    acc += dist;
  }
  return lines[lines.length - 1].end;
}

export function perpendicularBezier(p1: Point, p2: Point, controlPoint1: Vector2f, controlPoint2: Vector2f, resolution: number, t: number) {
  const lines = cubicBezier(p1, p2, controlPoint1, controlPoint2, resolution, false, false);
  const totalLength = lines.reduce((sum, line) => sum + Vector2.distance(line.start, line.end), 0);
  const targetLength = totalLength * t;
  let acc = 0;
  for (let i = 0; i < lines.length; i ++) {
    const line = lines[i];
    const dist = Vector2.distance(line.start, line.end);
    const remaining = targetLength - acc;
    if (remaining < dist) {
      return Vector2.perpendicular(line.start, line.end);
    }
    acc += dist;
  }
  return Vector2.perpendicular(lines[lines.length - 1].start, lines[lines.length - 1].end);
}
