import {ListOperation, ListSignal, Optional} from "common/types/index.ts";
import {Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation, Dnd5eFeature, Dnd5eFeatureOperation} from "common/legends/index.ts";
import {Dnd5eFeatureID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MutableRef} from "common/ref";

const valueFn = (value: Dnd5eFeature[], featureID: Optional<Dnd5eFeatureID>): Optional<Dnd5eFeature> => {
  return value.find(v => v.featureID === featureID);
};

export function useFeatureByID(value: ListSignal<Dnd5eFeature, Dnd5eFeatureOperation>, featureID: Dnd5eFeatureID | undefined): MutableRef<Optional<Dnd5eFeature>, Dnd5eFeatureOperation[]> {
  return useMemo((): MutableRef<Optional<Dnd5eFeature>, Dnd5eFeatureOperation[]> => {
    return new MutableRef({
      value(): Optional<Dnd5eFeature> {
        return valueFn(value.value, featureID);
      },
      observe: pipe(value.observe, map(value => valueFn(value, featureID)), distinct()),
      apply: fn => value.apply(prev => {
        const index = prev.findIndex(item => item.featureID === featureID);
        if (index === -1) return [];
        return ListOperation.apply(index, fn(prev[index]));
      }).then(value => valueFn(value, featureID))
    });
  }, [value, featureID]);
}

export function useCharacterClassFeatureByID(value: ListSignal<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>, featureID: Dnd5eFeatureID | undefined): MutableRef<Dnd5eFeature, Dnd5eFeatureOperation[]> | undefined {
  return useMemo((): MutableRef<Dnd5eFeature, Dnd5eFeatureOperation[]> | undefined => {
    const valueFn = (value: Dnd5eCharacterClassFeature[]): Dnd5eFeature => {
      return value.find(v => v.feature.featureID === featureID)!.feature;
    };

    if (featureID === undefined) return undefined;
    return new MutableRef({
      value(): Dnd5eFeature {return valueFn(value.value)},
      observe: pipe(value.observe, map(valueFn), distinct()),
      apply: fn => value.apply(prev => {
        const index = prev.findIndex(item => item.feature.featureID === featureID);
        if (index === -1) return [];
        return ListOperation.apply(index, [{type: "update-feature", operations: fn(prev[index].feature)}]);
      }).then(valueFn)
    });
  }, [value, featureID]);
}