import {Apply, VisitResult, walkTree} from "common/types/index.ts";
import {CharacterPanel, CharacterPanelOperation} from "./character-panel.tsx";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {AssetID, AssetTreeFile} from "common/legends/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ButtonBar, IconButton} from "#lib/components/index.ts";
import {useTypedResource} from "#lib/qlab/react/hooks/resource/use-typed-resource.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";
import {useAssetsByOwner} from "./use-assets-by-owner-i-d.ts";
import {useGame} from "../../../routes/game/index.ts";

export type CharacterItemProps = {
  assetID: AssetID;
};

function CharacterItem({assetID}: CharacterItemProps) {
  const game = useGame();
  const assetName = useComputedValue(game, game => {
    let asset: AssetTreeFile | undefined;
    walkTree(game.assets, {
      visit: (item) => {
        if (item.type === "asset") {
          if (item.data.id === assetID) {
            asset = item.data;
            return VisitResult.TERMINATE;
          }
        }
      }
    });
    return asset?.name;
  }, [assetID]);

  const asset = useRefValue(useTypedResource("asset", assetID));
  const [, dragRefHandler, dragPreviewRef] = useDrag(() => ({
    type: `legends/asset`,
    item: {
      id: assetID
    }
  }), [assetID]);
  dragPreviewRef(EMPTY_IMAGE);

  if (asset === undefined) return <></>;
  return <ButtonBar ref={dragRefHandler} className="cursor-grab">
    <IconButton>
      <DragIndicator />
    </IconButton>
    <span className="px-4">
      {assetName ?? asset.tokens.find(token => token.tokenID === asset.initialTokenId)?.name}
    </span>
  </ButtonBar>

}

type CharacterPanelViewProps = {
  value: CharacterPanel;
  apply: Apply<CharacterPanel, CharacterPanelOperation[]>;
};
export function CharacterPanelView(_: CharacterPanelViewProps) {
  const userID = useUserID()!;
  const assetIDs = useRefValue(useAssetsByOwner(userID));

  return <div className="py-2">
    <BaseComponent>
      {assetIDs.length === 0 && <ButtonBar className="h-10">
        <span className="px-4 text-white/60 italic">There are no characters assigned to you.</span>
      </ButtonBar>}
      {assetIDs.map(assetID => <CharacterItem key={assetID} assetID={assetID} />)}
    </BaseComponent>
  </div>
}