import {Button, ButtonProps} from "#lib/components/index.ts";
import React from "react";
import {twMerge} from "tailwind-merge";
import {useIsShiftDown} from "#lib/components/use-is-shift-down.ts";
import {useIsCtrlDown} from "#lib/components/use-is-ctrl-down.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSolidDiceD20CircleMinus, faSolidDiceD20CirclePlus} from "@awesome.me/kit-c9bc6845cd/icons/kit/custom";
import {faDiceD20} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export type DiceButtonProps = ButtonProps & {
  hasAdvantage?: boolean;
  hasDisadvantage?: boolean;
};

export function DiceButton({hasAdvantage, hasDisadvantage, ...props}: DiceButtonProps) {
  const ctrl = useIsCtrlDown();
  const shift = useIsShiftDown();

  const forceAdvantage = (shift && !ctrl);
  const forceDisadvantage = (!shift && ctrl);

  const isAdvantage = ((forceAdvantage && !forceDisadvantage) || (!ctrl && hasAdvantage && !hasDisadvantage));
  const isDisadvantage = ((forceDisadvantage && !forceAdvantage) || (!shift && hasDisadvantage && !hasAdvantage));

  return (<Button {...props} className={twMerge("shrink-0 px-2 gap-1 cursor-pointer", props.className)}>
    {isAdvantage && <FontAwesomeIcon className="text-green-300 fa-fw w-10 h-5 fa-2x" icon={faSolidDiceD20CirclePlus} />}
    {!(isAdvantage || isDisadvantage) && <FontAwesomeIcon className="fa-fw w-10 h-5 fa-2x" icon={faDiceD20} />}
    {isDisadvantage && <FontAwesomeIcon className="text-red-300 fa-fw w-10 h-5 fa-2x" icon={faSolidDiceD20CircleMinus} />}
  {props.children}
  </Button>
);
}