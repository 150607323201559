import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {Scene, SceneID, SceneOperation} from "common/legends/scene/index.ts";
import {Optional, ValueFn} from "common/types/generic/index.ts";
import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {Loader} from "../loader.ts";

class SceneLoader extends Loader<SceneID, MutableRef<Optional<Scene>, SceneOperation[]>> {
  constructor(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
    super(
      key => key,
      key => databaseRef.map(
        (database: QLabDatabase) => {
          const resource = database.resources[key];
          if (resource?.type === "scene") return resource.data;
          return undefined;
        },
        (database: QLabDatabase, operations: SceneOperation[]): QLabDatabaseOperation[] => {
          if (operations.length === 0) return [];
          const scene = database.resources[key];
          if (scene?.type !== "scene") return [];
          return [{type: "resource", resourceID: key, operations: ValueFn.apply([{type: "scene", operations}])}];
        }
      ).distinct()
    );
  }
}

const loader = new WeakMap<MutableRef<QLabDatabase, QLabDatabaseOperation[]>, Loader<SceneID, MutableRef<Optional<Scene>, SceneOperation[]>>>();
export function SceneRef(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, sceneID: Optional<SceneID>): MutableRef<Optional<Scene>, SceneOperation[]> {
  if (!sceneID) return NOOP_SIGNAL;
  if (!loader.has(databaseRef)) loader.set(databaseRef, new SceneLoader(databaseRef));
  return loader.get(databaseRef)!.get(sceneID);
}
