import {combine, distinct, map, Observable} from "common/observable";
import {Optional} from "common/types/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e-sheet-active-modifiers.ts";
import {Dice, DiceExpression} from "common/dice/index.ts";
import {Dnd5eSavingThrowModifier, Dnd5eSavingThrowType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {getDnd5eSheetSavingThrowProficiency} from "./dnd-5e-sheet-saving-throw-proficiency.ts";

export function getDnd5eSheetSavingThrowModifiers(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], type: Dnd5eSavingThrowType) {
  const activeModifiers = getDnd5eSheetActiveModifiers(sheet, globalFeatures);
  const proficiency = getDnd5eSheetSavingThrowProficiency(sheet, globalFeatures, type);
  let isProficient = proficiency === "proficient";

  // Attribute
  let expressions = [];
  if (type !== "death" && type !== "concentration") expressions.push(`{${type.toUpperCase()}}`);
  if (type === "concentration") expressions.push(`{CON}`);

  // Proficiency Bonus
  if (isProficient) expressions.push(`{PB}`);

  // Misc.
  expressions.push(...(
    activeModifiers
      .filter(m => m.type === "saving-throw")
      .map(m => m.data as Dnd5eSavingThrowModifier)
      .filter(m => (m.savingThrows.includes(type) || (type === "concentration" && m.savingThrows.includes("con"))))
      .map(m => m.expression)
  ));
  const expression = expressions.join("+");
  return Dice.isDiceExpression(expression) ? expression : undefined;
}

export function dnd5eSheetSavingThrowModifiers(sheet: Observable<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, type: Dnd5eSavingThrowType): Observable<Optional<DiceExpression>> {
  return pipe(
    combine(sheet, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetSavingThrowModifiers(sheet, globalFeatures, type)),
    distinct()
  );
}
