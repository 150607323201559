import {parseDiceExpression} from "./parse-dice-expression.ts";
import {DiceExpression} from "./dice-expression.ts";

export function isDiceExpression(expression: string): expression is DiceExpression {
  try {
    parseDiceExpression(expression);
    return true;
  } catch (e) {
    return false;
  }
}
