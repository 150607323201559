import {SetFn, SetOperation} from "common/types/index.ts";
import {Checkbox, ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import React from "react";
import {DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES, DND_5E_SKILL_ATTRIBUTE, DND_5E_SKILLS, DND_5E_TOOLS, isDnd5eAttribute, isDnd5eSkill, isDnd5eTool} from "common/legends/index.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type InputDnd5eAbilityChecksProps = {
  item: MutableRef<Dnd5eAbilityCheckType[], SetOperation<Dnd5eAbilityCheckType>[]>;
};

export function InputDnd5eAbilityChecks({item}: InputDnd5eAbilityChecksProps) {
  const abilityChecks = useRefValue(item);
  const [abilityExpanded, toggleAbilityExpand] = useToggle(false);
  const [skillExpanded, toggleSkillExpand] = useToggle(false);
  const [toolExpanded, toggleToolExpand] = useToggle(false);

  const allAbilityChecks = DND_5E_ATTRIBUTES.every(attribute => abilityChecks.includes(attribute));
  const someAbilityChecks = DND_5E_ATTRIBUTES.some(attribute => abilityChecks.includes(attribute));

  const allSkills = DND_5E_SKILLS.every(skill => abilityChecks.includes(skill));
  const someSkills = DND_5E_SKILLS.some(skill => abilityChecks.includes(skill));

  const allToolsChecks = DND_5E_TOOLS.every(attribute => abilityChecks.includes(attribute));
  const someToolsChecks = DND_5E_TOOLS.some(attribute => abilityChecks.includes(attribute));

  let summary = [];
  if (allAbilityChecks) {
    summary.push("All Checks");
  } else {
    summary.push(...abilityChecks.filter(isDnd5eAttribute).map(attribute => DND_5E_ATTRIBUTE_TITLE[attribute]));
    if (allSkills) summary.push("All Skills");
    else summary.push(...abilityChecks.filter(isDnd5eSkill).filter(skill => !abilityChecks.includes(DND_5E_SKILL_ATTRIBUTE[skill])));
    if (allToolsChecks) summary.push("All Tools");
    else summary.push(...abilityChecks.filter(isDnd5eTool));
    if (!abilityChecks.includes("dex") && abilityChecks.includes("initiative")) summary.push("Initiative");
  }

  return <div className="flex flex-col gap-0.5">
    <div className="flex flex-col gap-0 w-full">
      <ExpandableLabel expanded={abilityExpanded} toggleExpand={toggleAbilityExpand}>
        <Checkbox className="mx-4" checked={allAbilityChecks}
                  indeterminate={!allAbilityChecks && someAbilityChecks} onChange={() => {
          item.apply(prev => SetFn.set(prev, DND_5E_ATTRIBUTES.every(attribute => prev.includes(attribute))
            ? prev.filter(attribute => !isDnd5eAttribute(attribute))
            : [...prev.filter(attribute => !isDnd5eAttribute(attribute)), ...DND_5E_ATTRIBUTES]
          ));
        }}/>
        <span>All Ability Checks</span>
      </ExpandableLabel>
      {abilityExpanded && <Fieldset>
        {DND_5E_ATTRIBUTES.map(attribute => <label
          className="flex flex-row gap-0.5 px-0.5 items-center bg-zinc-900/80 h-10 capitalize">
          <Checkbox className="mx-4" checked={abilityChecks.includes(attribute)} onChange={() => {
            item.apply(prev => prev.includes(attribute) ? SetFn.delete(attribute) : SetFn.insert(attribute))
          }}/>
          <span>{DND_5E_ATTRIBUTE_TITLE[attribute]}</span>
        </label>)}
      </Fieldset>}
    </div>
    <div className="flex flex-col gap-0">
      <ExpandableLabel expanded={skillExpanded} toggleExpand={toggleSkillExpand}>
        <Checkbox className="mx-4" checked={allSkills} indeterminate={!allSkills && someSkills}
                  onChange={() => {
                    item.apply(prev => SetFn.set(prev, DND_5E_SKILLS.every(skill => prev.includes(skill))
                      ? prev.filter(skill => !isDnd5eSkill(skill))
                      : [...prev.filter(skill => !isDnd5eSkill(skill)), ...DND_5E_SKILLS]
                    ));
                  }}/>
        <span>All Skill</span>
      </ExpandableLabel>
      {skillExpanded && <Fieldset>
        {DND_5E_SKILLS.map(skill => <label className="flex flex-row gap-0.5 px-0.5 items-center bg-zinc-900/80 h-10 capitalize">
          <Checkbox className="mx-4" checked={abilityChecks.includes(skill)} onChange={() => {
            item.apply(prev => prev.includes(skill) ? SetFn.delete(skill) : SetFn.insert(skill))
          }}/>
          <span>{skill}</span>
        </label>)}
      </Fieldset>}
    </div>
    <div className="flex flex-col gap-0">
      <ExpandableLabel expanded={toolExpanded} toggleExpand={toggleToolExpand}>
        <Checkbox className="mx-4" checked={allToolsChecks} indeterminate={!allToolsChecks && someToolsChecks}
                  onChange={() => {
                    item.apply(prev => SetFn.set(prev, DND_5E_TOOLS.every(skill => prev.includes(skill))
                      ? prev.filter(tool => !isDnd5eTool(tool))
                      : [...prev.filter(tool => !isDnd5eTool(tool)), ...DND_5E_TOOLS]
                    ));
                  }}/>
        <span>All Tools</span>
      </ExpandableLabel>
      {toolExpanded && <Fieldset>
        {DND_5E_TOOLS.toSorted().map(tool => <label className="flex flex-row gap-0.5 px-0.5 items-center bg-zinc-900/80 h-10 capitalize">
          <Checkbox className="mx-4" checked={abilityChecks.includes(tool)} onChange={() => {
            item.apply(prev => prev.includes(tool) ? SetFn.delete(tool) : SetFn.insert(tool))
          }}/>
          <span>{tool}</span>
        </label>)}
      </Fieldset>}
    </div>
    <div className="flex flex-col gap-0">
      <label className="flex flex-row gap-0.5 px-0.5 items-center bg-zinc-900/80 h-10 capitalize">
        <Checkbox className="mx-4" checked={abilityChecks.includes("initiative")} onChange={() => {
          item.apply(prev => prev.includes("initiative") ? SetFn.delete("initiative") : SetFn.insert("initiative"))
        }}/>
        <span>Initiative</span>
      </label>
    </div>
  </div>;
}
