import {z} from "zod";
import {ConstantOperation, constantType, Type, ValueOperation, ValueType} from "../../../../types/index.ts";

export const InteractionActionTarget = z.union([
  z.literal("source"),
  z.literal("party")
]);
export type InteractionActionTarget = z.infer<typeof InteractionActionTarget>;
export const INTERACTION_ACTION_TARGET_TYPES: readonly InteractionActionTarget[] = [
  "source",
  "party"
];

export const INTERACTION_ACTION_TARGET_TITLES: Readonly<{[type in InteractionActionTarget]: string}> = {
  "source": "Source",
  "party": "Party"
};

export const InteractionActionTargetOperation = ValueOperation(InteractionActionTarget, ConstantOperation);
export type InteractionActionTargetOperation = z.infer<typeof InteractionActionTargetOperation>;
export const interactionActionTargetType: Type<InteractionActionTarget, InteractionActionTargetOperation> = new ValueType(constantType);
