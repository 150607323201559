import {z} from "zod";
import {DiceExpression} from "#common/dice/index.ts";
import {ConstantOperation, constantType, ObjectType, PropertyRef, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eDCModifier = z.object({
  modifierID: Dnd5eModifierID,
  expression: DiceExpression
});
export type Dnd5eDCModifier = z.infer<typeof Dnd5eDCModifier>;

export const Dnd5eDCModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
]);
export type Dnd5eDCModifierOperation = z.infer<typeof Dnd5eDCModifierOperation>;

export const dnd5eDCModifierType: Type<Dnd5eDCModifier, Dnd5eDCModifierOperation> = new ObjectType({
  modifierID: constantType,
  expression: new ValueType(constantType)
});

export function Dnd5eDCModifierSignals(value: MutableRef<Dnd5eDCModifier, Dnd5eDCModifierOperation[]>) {
  return ({
    expression: PropertyRef<Dnd5eDCModifier, Dnd5eDCModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value)
  });
}
