import {SignPrivateKey} from "./private-key.ts";
import {ArrayBufferToBase64Codec, Base64Codec} from "#common/codec";

export async function sign(body: string, privateKey: SignPrivateKey) {
  // @ts-ignore
  const cryptoKey = await crypto.subtle.importKey(
    "jwk",
    JSON.parse(Base64Codec.decode(privateKey)),
    {name: "RSA-PSS", hash: {name: "SHA-256"}},
    false,
    ["sign"]
  );

  const data = new TextEncoder().encode(body);
  // @ts-ignore
  const decodedValue = await crypto.subtle.sign(
    {name: "RSA-PSS", saltLength: 32},
    cryptoKey,
    data
  );

  return ArrayBufferToBase64Codec.encode(decodedValue);
}
