import {MouseEvent} from "react";
import {EditorStackValue} from "./editor-stack.ts";
import {EditorStacks} from "./editor-stacks.ts";
import {FaQuestion} from "react-icons/fa6";
import {DialogID, LayoutPath, StackItem} from "#lib/container/index.ts";
import {EditorSheetTabView} from "../../panel/sheet/editor/editor-sheet-tab-view.tsx";
import {StackItemTab} from "#lib/container/react/stack/stack-item-tab.tsx";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";
import {EditorPropertiesTabView} from "../../panel/properties/editor-properties-tab-view.tsx";

export type EditorStackTabViewProps = {
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  value: StackItem<EditorStackValue>;
  active: boolean;
  onClose?: () => void;
  onToggle: () => void;
};

export function EditorStackTabView({active, dialogId, layoutPath, value, onClose, onToggle}: EditorStackTabViewProps) {
  const [isDragged, dragRef, dragPreviewRef] = useDrag(() => ({
    type: "legends/tab",
    item: ({
      dialogId,
      layoutPath,
      stackId: value.id,
      value: value
    }),
    collect: (monitor) => monitor.isDragging()
  }), [dialogId, layoutPath, value]);
  dragPreviewRef(EMPTY_IMAGE);

  const onMiddleClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (ev.button === 1 && onClose) onClose();
  };

  const content = value.content;
  if (content.type === "properties") {
    return <EditorPropertiesTabView
      ref={dragRef}
      id={value.id}
      content={content.data}
      style={isDragged ? {display: "none"} : {}}
      active={active}
      onClick={onToggle} onClose={onClose}
      onMouseDown={onMiddleClick}
    />
  } else if (value.content.type === "sheet") {
    return <EditorSheetTabView
      ref={dragRef}
      id={value.id}
      content={value.content.data}
      style={isDragged ? {display: "none"} : {}}
      active={active}
      onClick={onToggle} onClose={onClose}
      onMouseDown={onMiddleClick}
    />
  } else {
    const stack = EditorStacks[value.content.type];
    let label = stack?.label;
    const Icon = stack?.Icon;
    return (<StackItemTab
      ref={dragRef}
      style={isDragged ? {display: "none"} : {}}
      active={active}
      Icon={Icon || FaQuestion}
      label={label}
      onClick={onToggle} onClose={onClose}
      onMouseDown={onMiddleClick}
    />);
  }
}

