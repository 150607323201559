import {DialogID, Layout, LayoutFn, LayoutOperation, LayoutPath, Stack, StackFn, StackItem, StackItemFn} from "../../modal/index.ts";
import {StackTabView} from "./stack-tab-view.tsx";
import {CSSProperties, Suspense, useMemo} from "react";
import {ApplyAction} from "#lib/qlab/index.ts";
import {StackContentView} from "./stack-content-view.tsx";
import "./stack-view.css";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {StackTabs} from "#lib/container/react/stack/stack-tabs.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";
import {from} from "common/observable";
import {usePresent} from "../../../../legends/common/use-optional-signal.ts";
import {MutableRef} from "common/ref";

export type StackViewProps<Value, Operation> = {
  style?: CSSProperties;
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  StackContentView: StackContentView<Value, Operation>;
  StackTabView: StackTabView<Value>;
  Stacks: Stacks<Value>,
  value: Stack<Value>;
  apply: (action: ApplyAction<Optional<Layout<Value>>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;
};

export function StackView<Value, Operation>({style, dialogId, layoutPath, value, apply, StackContentView, StackTabView, Stacks}: StackViewProps<Value, Operation>) {
  const items = value.items;
  const activeId = value.activeId;
  const selectedStackItem = useMemo<StackItem<Value> | undefined>(() => {
    for (const item of items) {
      if (item.id === activeId) return item;
    }
    return undefined;
  }, [items, activeId]);

  const activeIndex = items.findIndex(item => item.id === activeId);

  const stackItemContent = usePresent(useMemo((): MutableRef<Optional<Value>, Operation[]> => {
    const valueFn = (prev: Optional<Layout<Value>>): Optional<Optional<Value>> => {
      let node: Layout<Value> | undefined = prev;
      for (let i = 0; i < layoutPath.length; i ++) {
        if (node === undefined) return undefined;
        if (node.type === "row" || node.type === "column") {
          node = node.items[layoutPath[i]].content;
        } else {
          return undefined;
        }
      }
      if (node?.type !== "stack") return undefined;
      return node.items[activeIndex].content;
    };

    return new MutableRef<Optional<Value>, Operation[]>({
      value() {
        return value.items[activeIndex].content;
      },
      observe: from(valueFn(value)),
      apply: fn => apply(prev => {
        if (!prev) return [];
        const content = valueFn(prev);
        if (content === undefined) return [];
        const operations = fn(content);
        if (operations.length == 0) return [];
        return LayoutFn.applyToStack(
          layoutPath,
          StackFn.updateItems(ListOperation.apply(
            activeIndex,
            StackItemFn.updateContent(ValueFn.apply(operations))
          ))
        );
      }).then(valueFn)
    });
  }, [value, apply, layoutPath, activeIndex]));

  return (<div className={`stack-view ${!!dialogId ? "stack-view-dialog" : "stack-view-layout"}`} style={style}>
    <div className="bg-zinc-900/50">
      <StackTabs dialogId={dialogId} layoutPath={layoutPath} value={value} apply={apply} StackTabView={StackTabView} Stacks={Stacks} />
    </div>
    {stackItemContent && selectedStackItem && <ErrorBoundary><Suspense fallback={<></>}>
      <StackContentView key={selectedStackItem.id} value={selectedStackItem.content} apply={stackItemContent.apply} />
    </Suspense></ErrorBoundary>}
  </div>)
}