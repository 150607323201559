import {InputGroup, InputGroupColorButton, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {MeasurementCone, MeasurementConeOperation, MeasurementConeRef} from "./measurement-cone.ts";
import {MutableRef} from "common/ref";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";

export function MeasurementConeProperties({valueRef}: {valueRef: MutableRef<MeasurementCone, MeasurementConeOperation[]>}) {
  const {stickyRef, fillColorRef, sizeAngleRef} = useMemo(() => MeasurementConeRef(valueRef), [valueRef]);

  return <>
    <InputGroup className="pl-0">
      <InputCheckbox value={stickyRef} />
      <InputGroupLabel>Sticky</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <InputGroupColorButton valueRef={fillColorRef} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Angle</InputGroupLabel>
      <InputNumber value={sizeAngleRef} />
    </InputGroup>
  </>
}