import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2ElementArrayBuffer extends WebGL2Node {
  public value?: WebGLVertexArrayObject = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  bind(): void {
    if (this.value === undefined) throw new Error("value must be provided.");

    this.context.bindBuffer(WebGL2RenderingContext.ELEMENT_ARRAY_BUFFER, this.value);
  }

  unbind(): void {
    this.context.bindBuffer(WebGL2RenderingContext.ELEMENT_ARRAY_BUFFER, null);
  }
}
