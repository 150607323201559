import React, {useMemo} from "react";
import {Button, ButtonBar, InputGroup, InputGroupLabel, TagListInput} from "#lib/components/index.ts";
import {AssetTreeFolder, AssetTreeFolderOperation, AssetTreeFolderSignals} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";

export type EditFolderModalProps = {
  onClose: () => void;
  value: MutableRef<AssetTreeFolder, AssetTreeFolderOperation[]>;
};

export function EditFolderModal({onClose, value}: EditFolderModalProps) {
  const {name, tags} = useMemo(() => AssetTreeFolderSignals(value), [value]);

  return (<Modal onClose={onClose}>
    <ModalTitle>Edit Folder</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>
      <TagListInput value={tags} />
    </ModalBody>
    <ButtonBar>
      <Button type="button" className="grow" onClick={onClose}>Cancel</Button>
    </ButtonBar>
  </Modal>);
}