import {combine, distinct, map, Observable} from "common/observable";
import {Optional} from "common/types/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eSavingThrowType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {Dnd5eSavingThrowProficiency} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-saving-throw-proficiency.ts";
import {Dnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";

export function getDnd5eSavingThrowHasAdvantage(activeModifiers: Dnd5eModifier[], type: Dnd5eSavingThrowType): boolean {
  return activeModifiers.some(m => m.type === "saving-throw" && m.data.hasAdvantage && (
    m.data.savingThrows.includes(type) || (
      type === "concentration" && m.data.savingThrows.includes("con")
    )
  ));
}

export function getDnd5eSavingThrowHasDisadvantage(activeModifiers: Dnd5eModifier[], type: Dnd5eSavingThrowType): boolean {
  return activeModifiers.some(m => m.type === "saving-throw" && m.data.hasDisadvantage && (
    m.data.savingThrows.includes(type) || (
      type === "concentration" && m.data.savingThrows.includes("con")
    )
  ));
}


export function getDnd5eSheetSavingThrowProficiency(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], type: Dnd5eSavingThrowType): Dnd5eSavingThrowProficiency {
  const activeModifiers = getDnd5eSheetActiveModifiers(sheet, globalFeatures);
  const isProficient = activeModifiers.some(m => m.type === "saving-throw" && m.data.proficiency === "proficient" && (
    m.data.savingThrows.includes(type) ||
    (type === "concentration" && m.data.savingThrows.includes("con"))
  ));
  return isProficient ? "proficient" : "untrained";
}

export function dnd5eSheetSavingThrowProficiency(sheet: Observable<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, type: Dnd5eSavingThrowType): Observable<Dnd5eSavingThrowProficiency> {
  return pipe(
    combine(sheet, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetSavingThrowProficiency(sheet, globalFeatures, type)),
    distinct()
  );
}

export function dnd5eSheetSavingThrowHasAdvantage(sheet: Observable<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, type: Dnd5eSavingThrowType): Observable<boolean> {
  return pipe(
    combine(sheet, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSavingThrowHasAdvantage(getDnd5eSheetActiveModifiers(sheet, globalFeatures), type)),
    distinct()
  );
}

export function dnd5eSheetSavingThrowHasDisadvantage(sheet: Observable<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, type: Dnd5eSavingThrowType): Observable<boolean> {
  return pipe(
    combine(sheet, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSavingThrowHasDisadvantage(getDnd5eSheetActiveModifiers(sheet, globalFeatures), type)),
    distinct()
  );
}
