import {z} from "zod";
import {NodeId, NodeID} from "../../node-id.ts";
import {InteractionActionTarget, InteractionActionTargetOperation, interactionActionTargetType} from "./interaction-action-target.ts";
import {
  ConstantOperation,
  constantType,
  ObjectType,
  Optional,
  Point,
  PointOperation,
  pointType,
  PropertyRef,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {InteractionActionID} from "./interaction-action-i-d.ts";

export const InteractionActionTeleport = z.object({
  interactionActionID: InteractionActionID,
  target: InteractionActionTarget,
  nodeID: z.optional(NodeID),
  offset: Point
});
export type InteractionActionTeleport = z.infer<typeof InteractionActionTeleport>;

export const InteractionActionTeleportOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-action-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-target"), operations: z.array(InteractionActionTargetOperation)}),
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ValueOperation(z.optional(NodeID), ConstantOperation))}),
  z.object({type: z.literal("update-offset"), operations: z.array(PointOperation)})
]);
export type InteractionActionTeleportOperation = z.infer<typeof InteractionActionTeleportOperation>;

export const interactionActionTeleportType: Type<InteractionActionTeleport, InteractionActionTeleportOperation> = new ObjectType({
  interactionActionID: constantType,
  target: interactionActionTargetType,
  nodeID: new ValueType(constantType),
  offset: pointType
}, v => {
  if (v.offset === undefined) v.offset = [0, 0];
  return v;
});

export const InteractionActionTeleportFn = {
  expand: (value: MutableRef<InteractionActionTeleport, InteractionActionTeleportOperation[]>) => ({
    targetRef: PropertyRef<InteractionActionTeleport, InteractionActionTeleportOperation, InteractionActionTarget, InteractionActionTargetOperation>(
      value => value.target,
      operations => [{type: "update-target", operations}]
    )(value),
    nodeIDRef: ValuePropertyRef<InteractionActionTeleport, InteractionActionTeleportOperation, Optional<NodeId>, ConstantOperation>(
      value => value.nodeID,
      operations => [{type: "update-node-i-d", operations}]
    )(value),
    offsetRef: PropertyRef<InteractionActionTeleport, InteractionActionTeleportOperation, Point, PointOperation>(
      value => value.offset,
      operations => [{type: "update-offset", operations}]
    )(value)
  })
};
