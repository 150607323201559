import {NodeId} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {NodeView} from "../node-view/index.ts";
import {WallNode} from "common/legends/node/wall-node.ts";
import {getLinesFromWallGraph} from "../layer-view/walls.ts";
import {WallLineShader} from "../../../../common/measurement/wall-line-shader.tsx";
import {useIsTokenController} from "./use-is-token-controller.ts";
import {useIsSelected} from "./use-is-selected.ts";

export type WallNodeViewProps = {
  value: WallNode;
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
};
export function WallNodeView({value, projection, model, view, visibilityMask, accessNodeIDs, opacity}: WallNodeViewProps) {
  const isSelected = useIsSelected(value.id);
  const isTokenController = useIsTokenController();
  const valueOpacity = opacity * value.opacity;
  let valueModel = Transform.divide(value.transform, model);
  const walls = useMemo(() => getLinesFromWallGraph(value.graph), [value.graph]);

  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;
  return (<binder>
    {!isTokenController && <WallLineShader projection={projection} view={view} model={model} lines={walls} scale={isSelected ? 1 : 1/2} color={value.color} opacity={valueOpacity} editMode={isSelected}/>}
    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
    })}
  </binder>);
}