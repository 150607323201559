import {
  ConstantOperation,
  ConstantType,
  ListOperation,
  ListType,
  ObjectType,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {BotConfigSubscription} from "./bot-config-subscription.ts";
import {PublicKey} from "../../crypto/index.ts";

export type BotConfig = {
  name: string;
  publicKey: PublicKey;
  endpoint: string;
  subscriptions: BotConfigSubscription[];
};

export type BotConfigOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-public-key", operations: ValueOperation<PublicKey, ConstantOperation>[]}
  | {type: "update-endpoint", operations: ValueOperation<string, ConstantOperation>[]}
  | {type: "update-subscriptions", operations: ListOperation<BotConfigSubscription, ConstantOperation>[]}
  ;

export const botConfigType: Type<BotConfig, BotConfigOperation> = new ObjectType({
  name: stringType,
  publicKey: new ValueType(new ConstantType<PublicKey>()),
  endpoint: new ValueType(new ConstantType<string>()),
  subscriptions: new ListType(new ConstantType<BotConfigSubscription>())
});
