import {NodeTurn, NodeTurnOperation, NodeTurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {FaShieldAlt} from "react-icons/fa";
import {SheetInterface} from "common/legends/index.ts";
import {InputNumber, useToggle} from "#lib/components/index.ts";
import {useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {useNode, useNodeSheetReference} from "../../../viewport/token/use-node.ts";
import {isPartyNode} from "../../../common/node/use-is-party-node.ts";
import {NodeIcon} from "../editor/turn/node-icon.tsx";
import {CONDITION_IMAGE} from "../../sheet/editor/dnd-5e/dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useGlobalFeatures} from "../../sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {MutableRef} from "common/ref";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";

export type PlayerNodeTurnViewProps = {
  nodeTurnRef: MutableRef<NodeTurn, NodeTurnOperation[]>;
  isCurrentTurn: boolean;
};
export function PlayerNodeTurnView({nodeTurnRef, isCurrentTurn}: PlayerNodeTurnViewProps) {
  const {order} = useMemo(() => NodeTurnSignals(nodeTurnRef), [nodeTurnRef]);
  const nodeTurn = useRefValue(nodeTurnRef);
  const nodeRef = useNode(nodeTurn.nodeID);
  const sheet = useRefValue(useSheetSignal(useNodeSheetReference(nodeRef)));

  const [editOrder, toggleEditOrder, setEditOrder] = useToggle(false);
  const isParty = useComputedValue(nodeRef, isPartyNode);
  const globalFeatures = useRefValue(useGlobalFeatures());

  if (isParty) {
    return <div className={twMerge(
      "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
      isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50",
    )}>
      <NodeIcon nodeID={nodeTurn.nodeID} />
      {!editOrder && <div className="shrink overflow-hidden basis-6 h-8 flex items-center justify-center text-white/40 text-xs italic" onClick={toggleEditOrder}>
        {nodeTurn.order || "???"}
      </div>}
      {editOrder && <div className="w-20 px-2"><InputNumber value={order} autoFocus onLeaveEdit={() => setEditOrder(false)} /></div>}
      <span className="shrink-[1000] min-w-0 flex-1 inline-flex items-center overflow-hidden whitespace-nowrap">{sheet ? SheetInterface.getName(sheet) : "??"}</span>

      <div className="inline-flex shrink-[9999] overflow-hidden gap-0.5">
        <div className="inline-flex shrink overflow-hidden gap-0.5">
          <div className="text-sm whitespace-nowrap shrink-[9999] overflow-hidden basis-10 flex flex-row items-center justify-center gap-0.5"><FaShieldAlt className="w-4 h-4 shrink-0" /><span>{sheet ? SheetInterface.getAC(sheet, globalFeatures) : "??"}</span></div>
          <div className="text-xs overflow-hidden shrink">{sheet ? SheetInterface.getHealth(sheet, globalFeatures) : ""}</div>
        </div>
        <div className="flex flex-row gap-1 shrink-[9999] overflow-hidden">{sheet?.data.conditions.map((condition) => <img key={condition} alt={condition} title={condition} className="w-4 h-4" src={CONDITION_IMAGE[condition]} />)}</div>
      </div>
    </div>
  } else {
    return <div className={twMerge(
      "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
      isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50",
    )}>
      <NodeIcon nodeID={nodeTurn.nodeID} />
      <div className="shrink overflow-hidden basis-6 h-8 flex items-center justify-center text-white/40 text-xs italic">{nodeTurn.order}</div>
      <span className="shrink-[1000] basis-6 flex-1 inline-flex items-center overflow-hidden text-white/50">???</span>
      <div className="inline-flex shrink-[9999] overflow-hidden gap-0.5"></div>
    </div>
  }
}
