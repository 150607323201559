import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, Checkbox, InputGroup, InputGroupLabel, Select, Spacer} from "#lib/components/index.ts";
import {
  Dnd5eAction,
  Dnd5eActionFn,
  Dnd5eActionOperation,
  Dnd5eActionSignals,
  Dnd5eActionType,
  DND_5E_ACTION_TYPES
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {BooleanFn, Optional, ValueFn} from "common/types/index.ts";
import React, {useMemo, useRef} from "react";
import {Dnd5eAttribute, Dnd5eFeature, DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES, Sheet} from "common/legends/index.ts";
import {Dnd5eDefense, DND_5E_DEFENSE, DND_5E_DEFENSE_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-defense.ts";
import {FaTag, FaTrash} from "react-icons/fa";
import {FaDiceD20, FaHeartCrack} from "react-icons/fa6";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputRichText} from "#lib/components/input/input-rich-text.tsx";
import {useRollAction} from "../../dnd-5e-character/dnd-5e-action/use-roll-action.ts";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {Dnd5eActionModifiersView} from "./dnd-5e-action-modifiers-view.tsx";
import {Dnd5eActionEffectsView} from "./dnd-5e-action-effects-view.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {exportFile} from "../../../../../common/export-file.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {VariablesProvider} from "./dnd-5e-variables.ts";
import {RollVariables} from "common/qlab/index.ts";
import {useGlobalFeatures} from "../../dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {Observer, Subscription} from "common/observable";
import {Dice, DiceExpression} from "common/dice/index.ts";
import {getActiveActionAttackModifier, getActiveActionDifficultyClass, getActiveActionVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MutableRef, Ref} from "common/ref";

function useActiveActionAttackModifier(sheet: Ref<Optional<Sheet>>, action: Ref<Dnd5eAction>, globalFeatures: Ref<Dnd5eFeature[]>) {
  return useMemo((): Ref<DiceExpression> => {
    const valueFn = (sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): DiceExpression => {
      if (action.defense === "ac") {
        return getActiveActionAttackModifier(sheet, action, globalFeatures);
      } else if (action.defense !== undefined) {
        return getActiveActionDifficultyClass(sheet, action, globalFeatures);
      } else {
        return Dice.assertDiceExpression("0");
      }
    }

    return new MutableRef({
      value(): DiceExpression {
        return valueFn(sheet.value, action.value, globalFeatures.value);
      },
      observe: (observer: Observer<DiceExpression>): Subscription => {
        let sheetValue = sheet.value;
        let actionValue = action.value;
        let globalFeaturesValue = globalFeatures.value;
        observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
        const sheetSubscription = sheet.observe({
          next: sheet => {
            sheetValue = sheet;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const actionSubscription = action.observe({
          next: action => {
            actionValue = action;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const globalFeaturesSubscription = globalFeatures.observe({
          next: globalFeatures => {
            globalFeaturesValue = globalFeatures;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        return () => {
          sheetSubscription();
          actionSubscription();
          globalFeaturesSubscription();
        };
      },
      apply: () => {throw new Error("Unsupported.");}
    });
  }, [sheet, action, globalFeatures]);
}

function useChallengeDisplay(sheet: Ref<Optional<Sheet>>, action: Ref<Dnd5eAction>, globalFeatures: Ref<Dnd5eFeature[]>) {
  return useMemo((): Ref<string> => {
    const valueFn = (sheet: Optional<Sheet>, action: Dnd5eAction, globalFeatures: Dnd5eFeature[]): string => {
      const variables = getActiveActionVariables(sheet, action, globalFeatures);
      if (action.defense === "ac") {
        const attackModifier = getActiveActionAttackModifier(sheet, action, globalFeatures);
        if (attackModifier.startsWith("-")) {
          return `ATK ${Dice.toRangeDisplay(Dice.getRollRange(attackModifier, variables))}`;
        } else {
          return `ATK +${Dice.toRangeDisplay(Dice.getRollRange(attackModifier, variables))}`;
        }
      } else if (action.defense !== undefined) {
        const dc = getActiveActionDifficultyClass(sheet, action, globalFeatures);
        return `DC ${Dice.toRangeDisplay(Dice.getRollRange(dc, variables))}`;
      } else {
        return "";
      }
    }

    return new MutableRef({
      value(): string {
        return valueFn(sheet.value, action.value, globalFeatures.value);
      },
      observe: (observer: Observer<string>): Subscription => {
        let sheetValue = sheet.value;
        let actionValue = action.value;
        let globalFeaturesValue = globalFeatures.value;
        observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
        const sheetSubscription = sheet.observe({
          next: sheet => {
            sheetValue = sheet;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const actionSubscription = action.observe({
          next: action => {
            actionValue = action;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const globalFeaturesSubscription = globalFeatures.observe({
          next: globalFeatures => {
            globalFeaturesValue = globalFeatures;
            observer.next(valueFn(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        return () => {
          sheetSubscription();
          actionSubscription();
          globalFeaturesSubscription();
        };
      },
      apply: () => {throw new Error("Unsupported.");}
    });
  }, [sheet, action, globalFeatures]);
}

function useActionVariables(sheet: Ref<Optional<Sheet>>, action: Ref<Dnd5eAction>, globalFeatures: Ref<Dnd5eFeature[]>) {
  return useMemo((): Ref<RollVariables> => {
    return new MutableRef({
      value(): RollVariables {
        return getActiveActionVariables(sheet.value, action.value, globalFeatures.value);
      },
      observe: (observer: Observer<RollVariables>): Subscription => {
        let sheetValue = sheet.value;
        let actionValue = action.value;
        let globalFeaturesValue = globalFeatures.value;
        observer.next(getActiveActionVariables(sheetValue, actionValue, globalFeaturesValue));
        const sheetSubscription = sheet.observe({
          next: sheet => {
            sheetValue = sheet;
            observer.next(getActiveActionVariables(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const actionSubscription = action.observe({
          next: action => {
            actionValue = action;
            observer.next(getActiveActionVariables(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        const globalFeaturesSubscription = globalFeatures.observe({
          next: globalFeatures => {
            globalFeaturesValue = globalFeatures;
            observer.next(getActiveActionVariables(sheetValue, actionValue, globalFeaturesValue));
          },
          error: observer.error,
          complete: observer.complete
        });

        return () => {
          sheetSubscription();
          actionSubscription();
          globalFeaturesSubscription();
        };
      },
      apply: () => {throw new Error("Unsupported.");}
    });
  }, [sheet, action, globalFeatures]);
}

export type Dnd5eActionEditorProps = {
  value: MutableRef<Dnd5eAction, Dnd5eActionOperation[]>;
  remove?: () => void;
  onClose: () => void;
};

export function Dnd5eActionEditor({value, onClose, remove}: Dnd5eActionEditorProps) {
  const {label, description, range, criticalRange, modifiers, actionEffects, defense, onHit, onMiss, onCrit} = useMemo(() => Dnd5eActionSignals(value), [value]);

  const setOffense = (offense: Optional<Dnd5eAttribute>) => {
    value.apply(prev => Dnd5eActionFn.updateOffense(ValueFn.set(prev.offense, offense)));
  };
  const setDefense = (next: Optional<Dnd5eDefense>) => {
    defense.apply(prev => ValueFn.set(prev, next));
  };
  const setProficient = (nextValue: boolean) => {
    value.apply(prevValue => [{type: "update-proficient", operations: BooleanFn.set(prevValue.proficient, nextValue)}]);
  };

  const globalFeatures = useGlobalFeatures();
  const sheet = useSheetSignal(useSheetReference());
  const actionVariables = useActionVariables(sheet, value, globalFeatures);
  const challengeDisplay = useRefValue(useChallengeDisplay(sheet, value, globalFeatures));

  const expression = useRefValue(useActiveActionAttackModifier(sheet, value, globalFeatures));
  const contextValue = useRefValue(useActionVariables(sheet, value, globalFeatures));
  const challengeDisplayTitle = useMemo(() => Dice.toResolvedExpression(expression, contextValue), [expression, contextValue]);

  const roll = useRollAction(useSelectedNodeID(), sheet, value);
  const action = useRefValue(value);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  return <VariablesProvider value={actionVariables}>
    <Modal onClose={onClose} className="max-w-screen-lg" initialFocus={initialFocusRef}>
      <ModalTitle>
        <span>Action</span>
        <Spacer/>
        <ButtonBar>
          <Button variant="primary" className="gap-1" onClick={ev => roll(ev.shiftKey, ev.ctrlKey)}><FaDiceD20 /><span>Roll</span></Button>
          <ExpandOptions>
            <ExportButton onExport={async () => {
              exportFile(`ACTION-${value.value.label}.lvtt`, new Blob([JSON.stringify(value.value, null, 2)]));
            }}> Export Action </ExportButton>
            {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Action</Button>}
          </ExpandOptions>
        </ButtonBar>
      </ModalTitle>
      <ModalBody className="gap-2">
        <InputGroup className="pr-0">
          <InputGroupIcon />
          <InputGroupLabel>Type</InputGroupLabel>
          <Select value={action.actionType} onChange={ev => {
            const newType = ev.target.value as Dnd5eActionType;
            value.apply(prev => Dnd5eActionFn.updateActionType(ValueFn.set(prev.actionType, newType)));
          }}>
            {DND_5E_ACTION_TYPES.map(type => <option key={type} value={type}>{type}</option>)}
          </Select>
        </InputGroup>

        <InputGroup>
          <InputGroupIcon><FaTag /></InputGroupIcon>
          <InputGroupLabel>Name</InputGroupLabel>
          <InputString ref={initialFocusRef} value={label} />
        </InputGroup>

        <InputRichText placeholder="Description" value={description} />

        <div className="flex flex-row">
          <InputGroup className="pr-0 flex-1">
            <InputGroupIcon title="Attack"><FaHeartCrack /></InputGroupIcon>
            <InputGroupLabel title={challengeDisplayTitle}>{challengeDisplay}</InputGroupLabel>
            <Select value={action.defense || ""} onChange={ev => {
              const v = DND_5E_DEFENSE.includes(ev.target.value as Dnd5eDefense);
              if (v) setDefense(ev.target.value as Optional<Dnd5eDefense>);
              else setDefense(undefined);
            }}>
              <option value="">No Challenge</option>
              {DND_5E_DEFENSE.map(defense => <option key={defense} value={defense}>{DND_5E_DEFENSE_TITLE[defense]}</option>)}
            </Select>
          </InputGroup>
          {action.defense !== undefined && <>
            <InputGroup>
              <InputGroupLabel>contested by</InputGroupLabel>
            </InputGroup>
            <InputGroup className="pr-0 flex-1">
              <Select value={action.offense || ""} onChange={ev => {
                const v = DND_5E_ATTRIBUTES.includes(ev.target.value as Dnd5eAttribute);
                if (v) setOffense(ev.target.value as Optional<Dnd5eAttribute>);
                else setOffense(undefined);
              }}>
                <option value="">N/A</option>
                {DND_5E_ATTRIBUTES.map(attribute => <option key={attribute} value={attribute}>{DND_5E_ATTRIBUTE_TITLE[attribute]}</option>)}
              </Select>
            </InputGroup>
            <InputGroup>
              <Checkbox checked={action.proficient} onChange={ev => setProficient(ev.target.checked)} />
              <InputGroupLabel>Proficient?</InputGroupLabel>
            </InputGroup>
          </>}
        </div>

        <div className="flex flex-col gap-0.5">
          {action.defense === "ac" && <InputGroup>
            <InputGroupLabel>Critical Range</InputGroupLabel>
            <InputMathExpression value={criticalRange} />
          </InputGroup>}

          <InputGroup>
            <InputGroupLabel>Range</InputGroupLabel>
            <InputString type="text" value={range} />
          </InputGroup>

          {action.defense !== undefined && <InputGroup>
              <InputGroupLabel>On Hit</InputGroupLabel>
              <InputString type="text" placeholder="On Hit" value={onHit} />
          </InputGroup>}

          {action.defense === "ac" && <InputGroup>
              <InputGroupLabel>On Crit</InputGroupLabel>
              <InputString type="text" placeholder="On Crit" value={onCrit} />
          </InputGroup>}

          {action.defense !== undefined && action.defense !== "ac" && <InputGroup>
              <InputGroupLabel>On Miss</InputGroupLabel>
              <InputString type="text" placeholder="On Miss" value={onMiss} />
          </InputGroup>}
        </div>

        <Dnd5eActionModifiersView value={modifiers} />

        <Dnd5eActionEffectsView value={actionEffects} />

        <Button className="gap-1" onClick={ev => roll(ev.shiftKey, ev.ctrlKey)}><FaDiceD20 /><span>Roll</span></Button>
      </ModalBody>
    </Modal>
  </VariablesProvider>
}
