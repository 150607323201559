import {z} from "zod";
import {ConstantOperation, ObjectType, Type} from "common/types/index.ts";

export const CharacterPanel = z.object({});
export type CharacterPanel = z.infer<typeof CharacterPanel>;
export const CharacterPanelOperation = ConstantOperation;
export type CharacterPanelOperation = z.infer<typeof CharacterPanelOperation>;

export const characterPanelType: Type<CharacterPanel, CharacterPanelOperation> = new ObjectType({
});
