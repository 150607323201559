import {EditorProperties, EditorPropertiesOperation} from "./editor-properties.ts";
import {useApplyCallback} from "#lib/qlab/index.ts";
import {SceneNodePropertiesView, ScenePropertiesView} from "./scene/index.ts";
import {AssetNodePropertiesView, AssetTokenPropertiesView} from "./asset/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {useSelectionRef} from "./use-properties.ts";
import {SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {ConstantOperation, ValueOperation} from "common/types/generic/index.ts";

export type PropertiesStackViewProps = {
  valueRef: MutableRef<EditorProperties, EditorPropertiesOperation[]>;
};
export function EditorPropertiesStackView({valueRef}: PropertiesStackViewProps) {
  const reference = useSelectionRef(useRefValue(valueRef).reference);
  const applyToPinned = useApplyCallback(valueRef.apply, EditorProperties.getReference, EditorPropertiesOperation.updateReference);
  const pinned = useMemo(() => new MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>({
    value() {return valueRef.value.reference},
    observe: valueRef.map(v => v.reference).observe,
    apply: applyToPinned
  }), [valueRef, reference, applyToPinned]);

  if (reference?.type === "asset-token") {
    return <AssetTokenPropertiesView reference={reference} pinned={pinned} />
  } else if (reference?.type === "asset-token-node") {
    return <AssetNodePropertiesView reference={reference} pinned={pinned} />
  } else if (reference?.type === "scene") {
    return <ScenePropertiesView reference={reference} pinned={pinned} />
  } else if (reference?.type === "scene-node") {
    return <SceneNodePropertiesView reference={reference} pinned={pinned} />
  } else {
    return <></>;
  }
}
