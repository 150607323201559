import {Apply, ApplyAction, Point, PointOperation} from "common/types/index.ts";
import {useCallback} from "react";
import {SceneViewportProperties, SceneViewportPropertiesOperation} from "../scene-viewport-properties.ts";

export function useUpdateViewPosition(apply: Apply<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  return useCallback((action: ApplyAction<Point, PointOperation[]>) => {
    apply(prevValue => {
      const operations = typeof action === "function" ? action(prevValue.view.position): action;
      if (operations.length === 0) return [];
      return [{
        type: "update-view",
        operations: [{
          type: "apply",
          operations: [{
            type: "update-position",
            operations
          }]
        }]
      }];
    });
  }, [apply])
}
