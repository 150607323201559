import {NumberOperation} from "common/types/index.ts";
import {Dnd5eExhaustionLevel} from "common/legends/index.ts";
import {useSetNumberCallback} from "#lib/qlab/index.ts";
import {ButtonBar, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaFaceTired} from "react-icons/fa6";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type ExhaustionLevelProps = {
  value: MutableRef<Dnd5eExhaustionLevel, NumberOperation[]>;
};
export function ExhaustionLevel({value}: ExhaustionLevelProps) {
  const setExhaustionLevel = useSetNumberCallback(value.apply);
  return (
    <BaseComponent><ButtonBar>
      <InputGroup title="Exhaustion Level">
        <InputGroupIcon><FaFaceTired /></InputGroupIcon>
        <InputGroupLabel>Exhaustion Level</InputGroupLabel>
      </InputGroup>
      <InputGroup className="flex-1">
        <Input type="number" value={useRefValue(value)} min={0} max={6} onChange={ev => setExhaustionLevel(Number.parseInt(ev.target.value) | 0)} />
      </InputGroup>
    </ButtonBar></BaseComponent>);
}