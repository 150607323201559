export abstract class WebGL2Node {
  children: WebGL2Node[] = [];

  appendChild(child: WebGL2Node): void {
    this.children.push(child);
  }
  insertBefore(child: WebGL2Node, beforeChild: WebGL2Node): void {
    if (this.children.indexOf(child) !== -1) {
      this.children.splice(this.children.indexOf(child), 1);
    }
    this.children.splice(this.children.indexOf(beforeChild), 0, child);
  }
  removeChild(child: WebGL2Node): void {
    this.children.splice(this.children.indexOf(child), 1);
  }

  bind(): void {
    // no-op
  }

  action(): void {
    // no-op
  }

  unbind(): void {
    // no-op
  }

  visit(): void {
    this.bind();
    this.action();
    for (let i = 0; i < this.children.length; i ++) {
      this.children[i].visit();
    }
    this.unbind();
  }
}












