import {Observable} from "../observable.ts";

export function filter<U>(predicate: (value: U) => boolean): (observe: Observable<U>) => Observable<U> {
  return (observe) => {
    return observer => observe({
      next: (u) => {
        if (predicate(u)) observer.next(u)
      },
      error: observer.error,
      complete: observer.complete
    });
  };
}
