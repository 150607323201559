import {KeyboardEvent, useCallback} from "react";

type KeyEventHandler = (ev: KeyboardEvent) => boolean;
export function useKeyEventHandlers(...handlers: KeyEventHandler[]): KeyEventHandler {
  return useCallback((ev: KeyboardEvent) => {
    for (const handler of handlers) {
      if (!handler(ev)) return false;
    }
    return true;
  }, handlers);
}
