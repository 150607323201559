import {Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/optional/index.ts";
import {Dnd5eActionText} from "./dnd-5e-action-text.tsx";
import {Dnd5eActionAttackRollView} from "./dnd-5e-action-attack-roll-view.tsx";
import {Fragment} from "react";
import {Dnd5eActionDamageView} from "./dnd-5e-action-damage-view.tsx";
import {Dnd5eActionSavingThrowView} from "./dnd-5e-action-saving-throw-view.tsx";

export function Dnd5eActionSegmentView({segment, rollRequests, rollResults}: {segment: Dnd5eActionSegment, rollRequests: RollRequests, rollResults: Optional<RollResults>}) {
  switch (segment.type) {
    case "text": return <Dnd5eActionText message={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "saving-throw": return <Dnd5eActionSavingThrowView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "attack-roll": return <Dnd5eActionAttackRollView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "damage": return <Dnd5eActionDamageView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    default: return <Fragment />
  }
}