import {Tree} from "./tree.ts";

export enum PreVisitResult {
  CONTINUE,
  SKIP_VISIT,
  SKIP_SUBTREE,
  SKIP_SIBLINGS,
  TERMINATE
}
export enum VisitResult {
  CONTINUE,
  SKIP_SUBTREE,
  SKIP_SIBLINGS,
  TERMINATE
}
export enum PostVisitResult {
  CONTINUE,
  SKIP_SIBLINGS,
  TERMINATE
}

export interface TreeVisitor<Item extends Tree<Item>> {
  preVisit?(value: Item): PreVisitResult | void;
  visit?(value: Item, path: number[]): VisitResult | void;
  postVisit?(value: Item): PostVisitResult | void;
}

