import {z} from "zod";
import {ObjectType, PropertyRef} from "../object/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {MutableRef} from "#common/ref";
import {Type} from "../../type/index.ts";
import {Point} from "../point/index.ts";

export const Cone = z.object({
  length: z.number(),
  sizeAngle: z.number()
});
export type Cone = z.infer<typeof Cone>;
export const ConeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-length"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-size-angle"), operations: z.array(NumberOperation)}),
])
export type ConeOperation = z.infer<typeof ConeOperation>;
export const coneType: Type<Cone, ConeOperation> = new ObjectType({
  length: numberType,
  sizeAngle: numberType
});

export function ConeRef(ref: MutableRef<Cone, ConeOperation[]>) {
  return ({
    lengthRef: PropertyRef<Cone, ConeOperation, number, NumberOperation>(value => value.length, operations => [{type: "update-length", operations}])(ref),
    sizeAngleRef: PropertyRef<Cone, ConeOperation, number, NumberOperation>(value => value.sizeAngle, operations => [{type: "update-size-angle", operations}])(ref)
  })
}

export const ConeFn = {
  toPoints: (cone: Cone): [Point, Point] => {
    let radius = cone.length / Math.cos(cone.sizeAngle / 180 / 2 * Math.PI);
    if (!Number.isFinite(radius)) radius = cone.length;
    const a: Point = [
      Math.cos((90-cone.sizeAngle/2) * Math.PI / 180) * radius,
      Math.sin((90-cone.sizeAngle/2) * Math.PI / 180) * radius
    ];
    const b: Point = [
      Math.cos((90+cone.sizeAngle/2) * Math.PI / 180) * radius,
      Math.sin((90+cone.sizeAngle/2) * Math.PI / 180) * radius
    ];
    return [a, b];
  }
}