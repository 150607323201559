import {Type} from "../../type/type.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export type Nullable<T> =
  | null
  | T
  ;
export type NullableOperation<Operation> = Operation;

export class NullableType<Value, Operation> implements Type<Nullable<Value>, NullableOperation<Operation>> {
  constructor(private readonly itemType: Type<Value, Operation>) {
  }
  apply = (value: Nullable<Value>, operation: NullableOperation<Operation>): Nullable<Value> => {
    if (value === null) throw new Error("Cannot apply operations on null value.");
    return this.itemType.apply(value, operation);
  }
  invert = (operation: NullableOperation<Operation>): NullableOperation<Operation>[] => {
    return this.itemType.invert(operation);
  }
  transform = (leftOperation: NullableOperation<Operation>, topOperation: NullableOperation<Operation>, tieBreaker: boolean): NullableOperation<Operation>[] => {
    return this.itemType.transform(leftOperation, topOperation, tieBreaker);
  }
  migrateValue = (value: any): Nullable<Value> => {
    return value === null ? null : this.itemType.migrateValue(value);
  }
  migrateOperation = (operation: any): NullableOperation<Operation>[] => {
    return this.itemType.migrateOperation(operation);
  }
  validate = (value: any): ValidationError[] => {
    if (value === null) return [];
    return this.itemType.validate(value);
  }
}
