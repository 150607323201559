import {DependencyList, useLayoutEffect, useMemo, useState} from "react";
import {pipe} from "common/pipe";
import {distinct as observableDistinct, skip, subscribe} from "common/observable";
import {Ref} from "common/ref";

export function useRefValue<Value>(ref: Ref<Value>): Value {
  const [_, forceUpdate] = useState<boolean>(false);
  useLayoutEffect(() => {
    return pipe(ref.observe, skip(1), observableDistinct(), subscribe(_ => forceUpdate(t => !t)))
  }, [ref, forceUpdate]);
  return ref.value;
}

export function useComputedValue<Value, ComputedValue>(signal: Ref<Value>, fn: (value: Value) => ComputedValue, deps?: DependencyList | undefined): ComputedValue {
  return useRefValue(useMemo(() => signal.map(fn).distinct(), deps ? [signal, ...deps] : [signal]));
}
