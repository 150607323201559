import {Node, NodeId, Token, TokenID} from "#common/legends/index.ts";
import {Optional, Tree} from "#common/types/index.ts";
import {Asset} from "./asset.ts";
import {combine, distinct, map, Observable} from "#common/observable";
import {pipe} from "#common/pipe";

export function assetTokenObservable(
  assetObservable: Observable<Optional<Asset>>,
  tokenIdObservable: Observable<TokenID | undefined>
): Observable<Token | undefined> {
  return pipe(
    combine(assetObservable, tokenIdObservable),
    map(([asset, tokenID]) => tokenID ? asset?.tokens.find(token => token.tokenID === tokenID) : undefined),
    distinct()
  );
}

export function tokenNodeObservable(
  token: Observable<Token | undefined>,
  nodeId: Observable<NodeId | undefined>
): Observable<Node | undefined> {
  return pipe(
    combine(token, nodeId),
    map(([token, nodeId]) => token && nodeId ? Tree.getItemById(token.children, nodeId) : undefined),
    distinct(),
  );
}
