import {applyAll, ExtractOperation, ExtractValue, invertAll, transformAll, Type} from "../../type/index.ts";
import {CamelCaseToKebabCase, kebabCaseToCamelCase, thunkRecord, ThunkRecord} from "../../../utils/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";

export type ObjectValue<T> = {
  [Property in keyof T]: ExtractValue<T[Property]>;
};

export type ObjectOperation<Type> = {
  [Property in keyof Type]: {
    type: `update-${CamelCaseToKebabCase<string & Property>}`;
    operations: ExtractOperation<Type[Property]>[]
  }
}[keyof Type];

export class ObjectType<K extends Record<string, Type<any, any>>> implements Type<ObjectValue<K>, ObjectOperation<K>> {
  constructor(
    private readonly propertyTypes: ThunkRecord<K>,
    private readonly valueUpdater: (value: any) => ObjectValue<K> = (value: any) => value,
    private readonly operationUpdater: (operation: any) => ObjectOperation<K>[] = (operation: any) => [operation]
  ) {
  }

  apply = (value: ObjectValue<K>, operation: ObjectOperation<K>): ObjectValue<K> => {
    const type = kebabCaseToCamelCase(operation.type.substring("update-".length)) as keyof K;
    const propertyValue = value[type];
    const propertyType = thunkRecord(this.propertyTypes)[type];
    const propertyOperations = operation.operations;

    try {
      return ({
        ...value,
        [type]: applyAll(propertyType, propertyValue, propertyOperations)
      });
    } catch (e: any) {
      throw new QLabError(e.message, e.path ? [type, ...e.path] : [type]);
    }
  }

  invert = (operation: ObjectOperation<K>): ObjectOperation<K>[] => {
    const type = kebabCaseToCamelCase(operation.type.substring("update-".length)) as keyof K;
    const propertyType = thunkRecord(this.propertyTypes)[type];
    const propertyOperations = operation.operations;

    return [{
      type: operation.type,
      operations: invertAll(propertyType, propertyOperations)
    }];
  }

  transform = (leftOperation: ObjectOperation<K>, topOperation: ObjectOperation<K>, tieBreaker: boolean): ObjectOperation<K>[] => {
    const leftType = kebabCaseToCamelCase(leftOperation.type.substring("update-".length)) as keyof K;
    const topType = kebabCaseToCamelCase(topOperation.type.substring("update-".length)) as keyof K;
    if (leftType !== topType) {
      return [leftOperation];
    } else {
      const propertyType = thunkRecord(this.propertyTypes)[leftType];
      const leftPropertyOperations = leftOperation.operations;
      const topPropertyOperations = topOperation.operations;
      return [{
        type: leftOperation.type,
        operations: transformAll(propertyType, leftPropertyOperations, topPropertyOperations, tieBreaker)[0]
      }];
    }
  }

  migrateValue = (value: any): ObjectValue<K> => {
    let v = this.valueUpdater(value);
    const types = thunkRecord(this.propertyTypes);
    return Object.keys(types).reduce((obj, key) => {
      obj[key as ObjectValue<K>[string]] = types[key].migrateValue(v[key]);
      return obj;
    }, ({}) as ObjectValue<K>);
  }

  migrateOperation = (operation: any): ObjectOperation<K>[] => {
    const type = kebabCaseToCamelCase(operation.type.substring("update-".length)) as keyof K;
    const propertyType = thunkRecord(this.propertyTypes)[type];
    return [{
      type: operation.type,
      operations: operation.operations.flatMap(this.operationUpdater).flatMap((operation: any) => {
        try {
          return propertyType.migrateOperation(operation);
        } catch (e: any) {
          if (e.code === "INVALID-MIGRATE-OPERATION") {
            throw {code: "INVALID-MIGRATE-OPERATION", path: [type, ...e.path]}
          }
        }
      })
    }];
  }

  validate = (value: any): ValidationError[] => {
    let v = this.valueUpdater(value);
    const types = thunkRecord(this.propertyTypes);
    return Object.keys(types).flatMap((key) => types[key].validate(v[key]).map(error => ({
      path: [key, ...error.path],
      data: error.data
    })));
  }
}
