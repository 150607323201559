import React, {PropsWithChildren, useContext, useMemo} from "react";
import {Observable, Observer} from "common/observable";
import {useRenderingContext} from "#lib/gl-react/index.ts";
import {FileReference} from "common/types/index.ts";

export class TextureManager {
  private textures: {[url: NonNullable<FileReference>]: Promise<WebGLTexture>} = {};
  private observers: {[url: NonNullable<FileReference>]: Observer<WebGLTexture>[]} = {};

  constructor(private readonly context: WebGL2RenderingContext) {}

  textureObservable(url: NonNullable<FileReference>): Observable<WebGLTexture> {
    return (observer) => {
      if (!this.textures[url]) this.textures[url] = this.loadImage(url);
      this.textures[url].then(observer.next);

      if (!this.observers[url]) this.observers[url] = [];
      this.observers[url].push(observer);

      return () => {
        const index = this.observers[url].indexOf(observer);
        if (index === -1) return;
        this.observers[url].splice(index, 1);
        if (this.observers[url].length === 0) {
          // Clean up asset if it isn't used for 5 seconds
          setTimeout(() => {
            if (this.observers[url] === undefined || this.observers[url].length !== 0) return;
            this.textures[url].then(texture => this.context.deleteTexture(texture));
            delete this.textures[url];
            delete this.observers[url];
          }, 5000);
        }
      };
    };
  }

  private loadImage(url: NonNullable<FileReference>): Promise<WebGLTexture> {
    return new Promise((resolve, reject) => {
      const texture = this.context.createTexture()!;
      const image = new Image();
      const bindImageToTexture = () => {
        this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, texture);
        this.context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, image);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_WRAP_S, WebGL2RenderingContext.REPEAT);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_WRAP_T, WebGL2RenderingContext.REPEAT);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
        this.context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.NEAREST);
        this.context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
        resolve(texture);
      }
      image.addEventListener('load', bindImageToTexture);
      image.addEventListener('error', reject);
      image.crossOrigin = "anonymous";
      image.src = url;
    });
  }
}

const TextureManagerContext = React.createContext<TextureManager | undefined>(undefined);
export function TextureManagerProvider({children}: PropsWithChildren<object>) {
  const context = useRenderingContext();
  const textureManager = useMemo(() => new TextureManager(context), [context]);
  return <TextureManagerContext.Provider value={textureManager}>
    {children}
  </TextureManagerContext.Provider>
}

export function useTextureManager() {
  return useContext(TextureManagerContext)!;
}
