import {Button, ButtonBar, IconButton} from "#lib/components/index.ts";
import {Apply} from "#lib/qlab/index.ts";
import {Invitation, InvitationOperation} from "common/legends/index.ts";
import {ListOperation} from "common/types/index.ts";
import {FaPlus, FaTrash} from "react-icons/fa";
import {CreateInvitationModal} from "./create-invitation-modal.tsx";
import {useState} from "react";
import {InvitationModal} from "./invitation-modal.tsx";
import {useGameReference} from "../../../routes/game/index.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";

export type InvitationFieldProps = {
  value: Invitation[];
  apply: Apply<Invitation[], ListOperation<Invitation, InvitationOperation>[]>;
};

export function InvitationField({value, apply}: InvitationFieldProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showInvitationLink, setShowInvitationLink] = useState<string | undefined>(undefined);
  const gameRef = useGameReference();

  const onDeleteInvitation = (index: number) => {
    apply(prevValue => [{type: "delete", index, item: prevValue[index]}]);
  };

  return <div className="flex flex-col gap-1">
    <SectionHeader>Invitations</SectionHeader>
    {showInvitationLink && <InvitationModal onClose={() => {
      setShowInvitationLink(undefined);
    }} gameRef={gameRef} code={showInvitationLink} />}
    {isOpen && <CreateInvitationModal onClose={() => setIsOpen(false)} onCreate={(invitation, code) => {
      apply(prevItem =>[{
        type: "insert",
        index: prevItem.length,
        item: invitation
      }]);
      setIsOpen(false);
      setShowInvitationLink(code);
    }} />}
    <div className="flex flex-col gap-0.5">
      {value.map((invitation, index) => <ButtonBar key={invitation.digest} className="flex items-center bg-zinc-900/50 backdrop-blur-sm pointer-events-auto">
        <span className="flex-1 px-4 py-1 text-h200">{invitation.label}</span>
        <IconButton size="small" variant="destructive" onClick={() => onDeleteInvitation(index)}><FaTrash /></IconButton>
      </ButtonBar>)}
    </div>
    <ButtonBar>
      <Button className="flex-1" onClick={() => setIsOpen(true)}><FaPlus /> Create Invitation</Button>
    </ButtonBar>
  </div>;
}