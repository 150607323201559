import {useCallback} from "react";
import {useDeleteStore} from "#lib/qlab/index.ts";
import {GameID} from "common/legends/index.ts";

export function useDeleteGame(): (gameId: GameID) => Promise<void> {
  const deleteStore = useDeleteStore();
  return useCallback((gameId: GameID): Promise<void> => {
    return deleteStore("game", gameId).then();
  }, [deleteStore]);
}
