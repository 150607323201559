import {Dnd5eActionEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect.ts";
import {generateDnd5eActionEffectID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect-id.ts";
import {copyDnd5eActionModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action-modifier.ts";

export function copyDnd5eActionEffect(value: Dnd5eActionEffect): Dnd5eActionEffect {
  return ({
    ...value,
    enabled: false,
    actionEffectID: generateDnd5eActionEffectID(),
    modifiers: value.modifiers.map(copyDnd5eActionModifier)
  })
}