import {QLabStoreID, StoreType, StoreTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {useInstance} from "../../q-lab-instance-context.ts";
import {useCallback} from "react";
import {filter, map, toPromise} from "common/observable";
import {pipe} from "common/pipe";

export function useGetStore() {
  const instance = useInstance();
  return useCallback(<Type extends StoreType, Value = ExtractValue<StoreTypes[Type]>>(type: Type, storeId: QLabStoreID): Promise<Value> => {
    return pipe(
      instance.observe(storeId),
      filter(store => {
        return store.store?.type === type;
      }),
      map(store => store.store!.data as unknown as Value),
      toPromise
    );
  }, [instance]);
}
