import {GenIcon} from "react-icons";
import {IconBaseProps} from "react-icons";

export const HexagonHorizontalGridTypeIcon = (props: IconBaseProps) => GenIcon({
  tag: "svg",
  attr: {
    viewBox: "0 0 1500 1500",
    width: "1500",
    height: "1500"
  },
  child: [{
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "96px"},
      d: "M 250,750 500,375 1000,375 1250,750 1000,1125 500,1125 Z"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,750 250,750 M 1250,750 1500,750"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,0 250,0 500,375 M 1000,375 1250,0 1500,0"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,1500 250,1500 500,1125 M 1000,1125 1250,1500 1500,1500"
    },
    child: []
  }]
})(props);