import {z} from "zod";
import {BooleanOperation, booleanType, ColorOperation, colorType, HSLA, ObjectType, PropertyRef} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export const MeasurementRectangle = z.object({
  sticky: z.boolean(),
  fillColor: HSLA
});
export type MeasurementRectangle = z.infer<typeof MeasurementRectangle>;

export const MeasurementRectangleOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-sticky"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-fill-color"), operations: z.array(ColorOperation)})
]);
export type MeasurementRectangleOperation = z.infer<typeof MeasurementRectangleOperation>;

export const measurementRectangleType: Type<MeasurementRectangle, MeasurementRectangleOperation> = new ObjectType({
  sticky: booleanType,
  fillColor: colorType
});

export function MeasurementRectangleRef(ref: MutableRef<MeasurementRectangle, MeasurementRectangleOperation[]>) {
  return {
    fillColorRef: PropertyRef<MeasurementRectangle, MeasurementRectangleOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(ref),
    stickyRef: PropertyRef<MeasurementRectangle, MeasurementRectangleOperation, boolean, BooleanOperation>(value => value.sticky, operations => [{type: "update-sticky", operations}])(ref)
  };
}
