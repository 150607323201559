import {z} from "zod";

export const Dnd5eArtisanTool = z.union([
  z.literal("alchemist's supplies"),
  z.literal("brewer's supplies"),
  z.literal("calligrapher's supplies"),
  z.literal("carpenter's tools"),
  z.literal("cartographer's tools"),
  z.literal("cobbler's tools"),
  z.literal("cook's utensils"),
  z.literal("glassblower's tools"),
  z.literal("jeweler's tools"),
  z.literal("leatherworker's tools"),
  z.literal("mason's tools"),
  z.literal("painter's supplies"),
  z.literal("potter's tools"),
  z.literal("smith's tools"),
  z.literal("tinker's tools"),
  z.literal("weaver's tools"),
  z.literal("woodcarver's tools")
]);
export type Dnd5eArtisanTool = z.infer<typeof Dnd5eArtisanTool>;
export const DND_5E_ARTISAN_TOOLS = [
  "alchemist's supplies",
  "brewer's supplies",
  "calligrapher's supplies",
  "carpenter's tools",
  "cartographer's tools",
  "cobbler's tools",
  "cook's utensils",
  "glassblower's tools",
  "jeweler's tools",
  "leatherworker's tools",
  "mason's tools",
  "painter's supplies",
  "potter's tools",
  "smith's tools",
  "tinker's tools",
  "weaver's tools",
  "woodcarver's tools",
] as const as readonly Dnd5eArtisanTool[];

export const Dnd5eGamingSet = z.union([
  z.literal("dice set"),
  z.literal("dragonchess set"),
  z.literal("playing card set"),
  z.literal("three-dragon ante set")
]);
export type Dnd5eGamingSet = z.infer<typeof Dnd5eGamingSet>;
export const DND_5E_GAMING_SETS = [
  "dice set",
  "dragonchess set",
  "playing card set",
  "three-dragon ante set",
] as const as readonly Dnd5eGamingSet[];

export const Dnd5eMusicalInstrument = z.union([
  z.literal("bagpipes"),
  z.literal("drum"),
  z.literal("dulcimer"),
  z.literal("flute"),
  z.literal("lute"),
  z.literal("lyre"),
  z.literal("horn"),
  z.literal("pan flute"),
  z.literal("shawm"),
  z.literal("viol")
]);
export type Dnd5eMusicalInstrument = z.infer<typeof Dnd5eMusicalInstrument>;
export const DND_5E_MUSICAL_INSTRUMENTS = [
  "bagpipes",
  "drum",
  "dulcimer",
  "flute",
  "lute",
  "lyre",
  "horn",
  "pan flute",
  "shawm",
  "viol"
] as const as readonly Dnd5eMusicalInstrument[];

export const Dnd5eTool = z.union([
  Dnd5eArtisanTool,
  Dnd5eGamingSet,
  Dnd5eMusicalInstrument,
  z.literal("disguise kit"),
  z.literal("forgery kit"),
  z.literal("herbalism kit"),
  z.literal("navigator's tools"),
  z.literal("poisoner's kit"),
  z.literal("thieves' tools"),
  z.literal("vehicles (water)"),
  z.literal("vehicles (land)"),
  z.literal("vehicles (air)"),
  z.literal("vehicles (space)")
]);
export type Dnd5eTool = z.infer<typeof Dnd5eTool>;

export const DND_5E_TOOLS = [
  ...DND_5E_ARTISAN_TOOLS,
  ...DND_5E_GAMING_SETS,
  ...DND_5E_MUSICAL_INSTRUMENTS,
  "disguise kit",
  "forgery kit",
  "herbalism kit",
  "navigator's tools",
  "poisoner's kit",
  "thieves' tools",
  "vehicles (water)",
  "vehicles (air)",
  "vehicles (land)",
  "vehicles (space)"
] as const as readonly Dnd5eTool[];

export function isDnd5eTool(value: string): value is Dnd5eTool {
  return DND_5E_TOOLS.includes(value as Dnd5eTool);
}

export const DND_5E_TOOL_TITLE: {[tool in Dnd5eTool]: string} = {
  "alchemist's supplies": "Alchemist's Supplies",
  "brewer's supplies": "Brewer's Supplies",
  "calligrapher's supplies": "Calligrapher's Supplies",
  "carpenter's tools": "Carpenter's Tools",
  "cartographer's tools": "Cartographer's Tools",
  "cobbler's tools": "Cobbler's Tools",
  "cook's utensils": "Cook's Utensils",
  "glassblower's tools": "Glassblower's Tools",
  "jeweler's tools": "Jeweler's Tools",
  "leatherworker's tools": "Leatherworker's Tools",
  "mason's tools": "Mason's Tools",
  "painter's supplies": "Painter's Supplies",
  "potter's tools": "Potter's Tools",
  "smith's tools": "Smith's Tools",
  "tinker's tools": "Tinker's Tools",
  "weaver's tools": "Weaver's Tools",
  "woodcarver's tools": "Woodcarver's Tools",

  "dice set": "Dice Set",
  "dragonchess set": "Dragonchess Set",
  "playing card set": "Playing Card Set",
  "three-dragon ante set": "Three-Dragon Ante Set",

  "bagpipes": "Bagpipes",
  "drum": "Drum",
  "dulcimer": "Dulcimer",
  "flute": "Flute",
  "lute": "Lute",
  "lyre": "Lyre",
  "horn": "Horn",
  "pan flute": "Pan Flute",
  "shawm": "Shawm",
  "viol": "Viol",

  "disguise kit": "Disguise Kit",
  "forgery kit": "Forgery Kit",
  "herbalism kit": "Herbalism Kit",
  "navigator's tools": "Navigator's Tools",
  "poisoner's kit": "Poisoner's Kit",
  "thieves' tools": "Thieves' Tools",
  "vehicles (water)": "Vehicles (Water)",
  "vehicles (land)": "Vehicles (Land)",
  "vehicles (air)": "Vehicles (Air)",
  "vehicles (space)": "Vehicles (Space)",
};