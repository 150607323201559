import {InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import React from "react";
import {
  Dnd5eSpellSlotProgression,
  DND_5E_SPELL_SLOT_PROGRESSION,
  DND_5E_SPELL_SLOT_PROGRESSION_TITLE
} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-spell-slot-progression.ts";
import {ConstantOperation, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type SpellLevelProgressionInputProps = {
  value: MutableRef<Optional<Dnd5eSpellSlotProgression>, ValueOperation<Optional<Dnd5eSpellSlotProgression>, ConstantOperation>[]>;
};

export function SpellSlotProgressionInput({value}: SpellLevelProgressionInputProps) {
  const valueValue = useRefValue(value);
  return (<InputGroup>
    <InputGroupIcon />
    <InputGroupLabel>Spell Progression</InputGroupLabel>
    <Select value={valueValue || ""} onChange={ev => {
      const next = ev.target.value as (Dnd5eSpellSlotProgression | "");
      value.apply(prev => ValueFn.set(prev, next !== "" ? next : undefined))
    }}>
      <option value="">N/A</option>
      {DND_5E_SPELL_SLOT_PROGRESSION.map(type =>
        <option key={type} value={type}>{DND_5E_SPELL_SLOT_PROGRESSION_TITLE[type]}</option>
      )}
    </Select>
  </InputGroup>);
}