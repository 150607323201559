import {ulid} from "ulid";
import {z} from "zod";
import {QLabResourceID} from "#common/qlab/index.ts";

export const SheetID = QLabResourceID.brand("SheetID");
export type SheetID = z.infer<typeof SheetID>;

export function generateSheetID() {
  return ulid() as SheetID;
}
