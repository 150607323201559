import React, {PropsWithChildren, useEffect, useMemo, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {PortalProvider} from "#lib/container/react/external-window/external-portal.tsx";

export type ExternalWindowProps = PropsWithChildren<{
  target: string;
  position: [number, number];
  size: [number, number];
  onMove?: (left: number, top: number) => void;
  onResize?: (width: number, height: number) => void;
  onClose?: () => void;
}>;

export function ExternalWindow({onResize, onMove, onClose, position, size, ...props}: ExternalWindowProps) {
  const externalWindow: WindowProxy | null = useMemo(() => {
    const features = Object.entries({
      top: position[1], left: position[0], width: size[0], height: size[1],
      menubar: 0, scrollbars: 1, status: 0, toolbar: 0, titlebar: 0,
    }).map(([key, value]) => `${key}=${value}`).join();
    return window.open("/popup.html", props.target, features);
  }, [props.target]);

  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (!externalWindow) return;
    const load = () => {
      externalWindow.document.head.innerHTML = window.document.head.innerHTML;
      externalWindow.document.body.childNodes.forEach(childNode => externalWindow.document.body.removeChild(childNode));
      const div = externalWindow.document.createElement("div");
      externalWindow.document.body.appendChild(div);
      setRootElement(div);
    }

    externalWindow.addEventListener("load", load)
    return () => externalWindow.removeEventListener("load", load);
  }, [externalWindow]);

  useEffect(() => {
    return () => externalWindow?.close();
  }, []);

  useEffect(() => {
    if (externalWindow === null) return;
    const resizeHandler = () => {
      if (onResize) onResize(externalWindow!.innerWidth, externalWindow!.innerHeight);
    };
    externalWindow.addEventListener("resize", resizeHandler);
    return () => externalWindow.removeEventListener("resize", resizeHandler);
  }, [externalWindow, onResize])


  const closing = useRef(false);
  useEffect(() => {
    if (externalWindow === null) return;
    var timer = setInterval(function() {
      if(externalWindow.closed) {
        clearInterval(timer);
        if (onClose && !closing.current) onClose();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [externalWindow, onClose, closing]);
  useEffect(() => {
    const close = () => {
      closing.current = true;
      externalWindow?.close();
    }
    window.addEventListener("beforeunload", close);
    return () => window.removeEventListener("beforeunload", close);
  }, [externalWindow, closing]);

  useEffect(() => {
    if (externalWindow === null) return;
    if (!onMove) return;
    let position = [externalWindow.screenLeft, externalWindow.screenTop];
    const interval = setInterval(() => {
      let nextPosition = [externalWindow.screenLeft, externalWindow.screenTop];
      if (position[0] !== nextPosition[0] && position[1] !== nextPosition[1]) {
        onMove(nextPosition[0], nextPosition[1]);
        position = nextPosition;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [externalWindow, onClose])

  if (rootElement === null) return <></>;
  return ReactDOM.createPortal(<div className="bg-[#4c4c4c]">
    <PortalProvider value={rootElement}>
      {props.children}
    </PortalProvider>
  </div>, rootElement);
}

