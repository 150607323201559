import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "#common/dice/index.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ObjectType,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {Dnd5eAttribute, DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-attribute.ts";
import {Dnd5eSkill, DND_5E_SKILL_TITLE, DND_5E_SKILLS} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-skill.ts";
import {Dnd5eTool, DND_5E_TOOL_TITLE, DND_5E_TOOLS} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-tool.ts";
import {Dnd5eAbilityCheckProficiency} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-ability-check-proficiency.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eAbilityCheckType = z.union([
  Dnd5eAttribute,
  Dnd5eSkill,
  Dnd5eTool,
  z.literal("initiative")
]);
export type Dnd5eAbilityCheckType = z.infer<typeof Dnd5eAbilityCheckType>;
export const DND_5E_ABILITY_CHECK_TYPES = [
  ...DND_5E_ATTRIBUTES,
  ...DND_5E_SKILLS,
  ...DND_5E_TOOLS,
  "initiative"
] as const satisfies readonly Dnd5eAbilityCheckType[];

export const Dnd5eAbilityCheckModifier = z.object({
  modifierID: Dnd5eModifierID,
  abilityChecks: z.array(Dnd5eAbilityCheckType),
  expression: DiceExpression,
  proficiency: Dnd5eAbilityCheckProficiency,
  hasAdvantage: z.boolean(),
  hasDisadvantage: z.boolean()
});
export type Dnd5eAbilityCheckModifier = z.infer<typeof Dnd5eAbilityCheckModifier>;

export const Dnd5eAbilityCheckModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-ability-checks"), operations: z.array(SetOperation(Dnd5eAbilityCheckType))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-proficiency"), operations: z.array(ValueOperation(Dnd5eAbilityCheckProficiency, ConstantOperation))}),
  z.object({type: z.literal("update-has-advantage"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-has-disadvantage"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eAbilityCheckModifierOperation = z.infer<typeof Dnd5eAbilityCheckModifierOperation>;

export const dnd5eAbilityCheckModifierType: Type<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation> = new ObjectType({
  modifierID: constantType,
  abilityChecks: new SetType<Dnd5eAbilityCheckType>(),
  expression: new ValueType(constantType),
  proficiency: new ValueType(constantType),
  hasAdvantage: booleanType,
  hasDisadvantage: booleanType
}, (v: any) => {
  if (v.proficiency === undefined) v.proficiency = "untrained";
  if (v.hasAdvantage === undefined) v.hasAdvantage = false;
  if (v.hasDisadvantage === undefined) v.hasDisadvantage = false;
  return v;
});

export function Dnd5eAbilityCheckModifierSignals(value: MutableRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation[]>) {
  return {
    abilityChecks: SetPropertySignal<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, Dnd5eAbilityCheckType>(
      value => value.abilityChecks,
      operations => [{type: "update-ability-checks", operations}]
    )(value),
    expression: PropertyRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value),
    proficiency: ValuePropertyRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, Dnd5eAbilityCheckProficiency, ConstantOperation>(
      value => value.proficiency,
      operations => [{type: "update-proficiency", operations}]
    )(value),
    hasAdvantage: PropertyRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, boolean, BooleanOperation>(
      value => value.hasAdvantage,
      operations => [{type: "update-has-advantage", operations}]
    )(value),
    hasDisadvantage: PropertyRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, boolean, BooleanOperation>(
      value => value.hasDisadvantage,
      operations => [{type: "update-has-disadvantage", operations}]
    )(value),
  };
}

export const DND_5E_ABILITY_CHECK_TITLE: {[type in Dnd5eAbilityCheckType]: string} = {
  ...DND_5E_SKILL_TITLE,
  ...DND_5E_TOOL_TITLE,
  ...DND_5E_ATTRIBUTE_TITLE,
  "initiative": "Initiative"
};
