import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useElementArrayBuffer, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA, Point, Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {getLines} from "../../../viewport/common/node/layer-view/walls.ts";
import {Vector2} from "common/math/vector/vector2.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec4 u_color;
out vec4 outColor;
void main() {
  outColor = u_color;
}
`;

export type WallLineShaderProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  spline: Spline;
  color: HSLA;
};

function isInTriangle(p: Vector2, a: Vector2, b: Vector2, c: Vector2) {
  const ab = Vector2.subtract(b, a);
  const bc = Vector2.subtract(c, b);
  const ca = Vector2.subtract(a, c);

  const ap = Vector2.subtract(p, a);
  const bp = Vector2.subtract(p, b);
  const cp = Vector2.subtract(p, c);
  if (Vector2.cross(ab, ap) > 0 || Vector2.cross(bc, bp) >= 0 || Vector2.cross(ca, cp) >= 0) {
    return false;
  }
  return true;
}
function isClockwise(vertices: Point[]) {
  let signedArea = 0;
  for (let i = 0; i < vertices.length; i ++) {
    signedArea += Vector2.cross(vertices[i], vertices[(i + 1) % vertices.length]);
  }
  return (signedArea >= 0);
}

export function AreaPolyShader({spline, projection, model, view, color}: WallLineShaderProps) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const colorLocation = useUniformLocation(program, "u_color");

  const vertices = useMemo(() => getLines(spline), [spline]).map(l => l.start);
  const vboArray = useMemo(() => {
    return new Float32Array(vertices.flatMap(v => v));
  }, [spline]);

  const vbo = useArrayBuffer(vboArray);
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 2 * 4, 0);

  const eboArray = useMemo(() => {
    let indices = vertices.map((_, index) => index);
    if (indices.length < 3) return new Uint16Array([]);
    // indices need to be ordered clockwise
    if (isClockwise(vertices)) indices.reverse();

    let triangles: number[] = [];
    find_triangle:
    while (indices.length > 3) {
      find_ear:
      for (let i = 0; i < indices.length; i++) {
        let a = indices[i], va = vertices[a];
        let b = indices[(i - 1 + indices.length) % indices.length], vb = vertices[b];
        let c = indices[(i + 1 + indices.length) % indices.length], vc = vertices[c];

        const vab = Vector2.subtract(vb, va);
        const vac = Vector2.subtract(vc, va);
        if (Vector2.cross(vab, vac) < 0) continue find_ear;
        for (let j = 0; j < vertices.length; j ++) {
          if (j === a || j === b || j === c) continue;
          const vp = vertices[j];
          if (isInTriangle(vp, vb, va, vc)) continue find_ear;
        }

        triangles.push(...[b, a, c]);
        indices.splice(i, 1);
        continue find_triangle;
      }
      break;
    }
    if (indices.length === 3) triangles.push(...indices);
    return new Uint16Array(triangles);
  }, [vboArray, vertices]);
  const ebo = useElementArrayBuffer(eboArray);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color])

  return (<program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f}/>
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f}/>
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f}/>
    <uniform4fv location={colorLocation} data={color4f}/>
    <vertexArray value={vao}>
      <elementArrayBuffer value={ebo}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={eboArray.length}/>
      </elementArrayBuffer>
    </vertexArray>
  </program>);
}