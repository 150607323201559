import {InputHTMLAttributes} from "react";
import "./range.css";
import {twMerge} from "tailwind-merge";
import {NumberFn, NumberOperation} from "common/types/index.ts";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";

export type RangeProps = Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> & {
  value: MutableRef<number, NumberOperation[]>;
};

export function InputGroupRange({value, className, ...props}: RangeProps) {
  const loader = useObservableLoader(value.observe);
  return (<div className={twMerge("range", className)}>
    <input disabled={loader.isLoading} value={loader.isSuccess ? loader.data : 0} type="range" onChange={(ev) => {
      const next = Number.parseFloat(ev.target.value);
      value.apply(prev => NumberFn.set(prev, next));
    }} {...props} />
  </div>);
}
