import {z} from "zod";

export const Dnd5eSavingThrowProficiency = z.union([
  z.literal("untrained"),
  z.literal("proficient")
]);
export type Dnd5eSavingThrowProficiency = z.infer<typeof Dnd5eSavingThrowProficiency>;
export const DND_5E_SAVING_THROW_PROFICIENCIES = ["untrained", "proficient"] as const satisfies readonly Dnd5eSavingThrowProficiency[];

export const SAVING_THROW_PROFICIENCY_TITLE: {[proficiency in Dnd5eSavingThrowProficiency]: string} = {
  "untrained": "Not Proficient",
  "proficient": "Proficient"
};
