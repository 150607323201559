import {DialogMoveHandle} from "./dialog-move-handle.tsx";
import {DialogID, Layout, LayoutOperation, Stack} from "../../../modal/index.ts";
import {DialogTopLeftResizer} from "./dialog-top-left-resizer.tsx";
import {DialogTopRightResizer} from "./dialog-top-right-resizer.tsx";
import "./resizer.css";
import {DialogCloseHandle} from "./dialog-close-handle.tsx";
import {DialogExpandHandle} from "#lib/container/react/dialog/resizer/dialog-expand-handle.tsx";
import {DialogMinimizeHandle} from "#lib/container/react/dialog/resizer/dialog-minimize-handle.tsx";
import {ApplyAction, Optional, Point} from "common/types/index.ts";
import {StackTabView} from "#lib/container/react/stack/stack-tab-view.tsx";
import {StackTabs} from "#lib/container/react/stack/stack-tabs.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";

export type DialogTopResizerProps<Value, Operation> = {
  dialogId: DialogID;
  stack: Stack<Value>;
  Stacks: Stacks<Value>;
  applyToStack: (action: ApplyAction<Layout<Value>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;

  StackTabView: StackTabView<Value>;
  minimized: boolean;
  position: Point;
  onToggleMinimized: () => void;
  onExpand: () => void;
  onClose: () => void;
};
export function DialogTopNav<Value, Operation>({dialogId, position, stack, applyToStack, StackTabView, minimized, onExpand, onClose, onToggleMinimized, Stacks}: DialogTopResizerProps<Value, Operation>) {
  return (<div className="dialog-top-resizer">
    <DialogTopLeftResizer dialogId={dialogId} minimized={minimized} />

    <DialogMoveHandle dialogId={dialogId} position={position} onToggleMinimized={onToggleMinimized}>
      <StackTabs dialogId={dialogId} StackTabView={StackTabView} apply={applyToStack} layoutPath={[]} value={stack} Stacks={Stacks} />
    </DialogMoveHandle>

    <div className="flex flex-row gap-0 flex-grow-0 basis-[120px] shrink-0">
      <DialogMinimizeHandle onToggleMinimized={onToggleMinimized} onClose={onClose} minimized={minimized} />
      <DialogExpandHandle onClose={onExpand}/>
      <DialogCloseHandle onClose={onClose}/>
    </div>

    <DialogTopRightResizer dialogId={dialogId} minimized={minimized}/>
  </div>);
}
