import {ConstantOperation, ConstantType, Point, ValueOperation, ValueType} from "#common/types/index.ts";

export type GridRuler = "manhattan" | "euclidean" | "dnd-standard" | "dnd-variant";
export const gridRulerType = new ValueType(new ConstantType<GridRuler>());
export type GridRulerOperation = ValueOperation<GridRuler, ConstantOperation>;

export const GridRulerFn = {
  distance: (ruler: GridRuler, start: Point, end: Point) => {
    switch (ruler) {
      case "manhattan": return Math.abs(start[0] - end[0]) + Math.abs(start[1] - end[1]);
      case "euclidean": return Math.pow(Math.pow(start[0] - end[0], 2) + Math.pow(start[1] - end[1], 2), 0.5);
      case "dnd-standard": return Math.max(Math.abs(start[0] - end[0]), Math.abs(start[1] - end[1]));
      case "dnd-variant": {
        const diffX = Math.abs(start[0] - end[0]);
        const diffY = Math.abs(start[1] - end[1]);
        const maxAxis = Math.max(diffX, diffY);
        const minAxis = Math.min(diffX, diffY);
        return maxAxis + Math.floor(minAxis / 2);
      }
    }

  }
} as const;
