import {DND_5E_ATTRIBUTES} from "common/legends/index.ts";
import React from "react";
import {SavingThrowField} from "../dnd-5e-character/saving-throw-field";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";

export type SavingThrowProficienciesViewProps = {};
export function SavingThrowProficienciesView({}: SavingThrowProficienciesViewProps) {
  const [expanded, toggleExpanded] = useToggle(false);
  return <BaseComponent>
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <span className="flex-1 px-4 py-2 font-bold">Saving Throws</span>
    </ExpandableLabel>
    {expanded && <Fieldset>
      {DND_5E_ATTRIBUTES.map(attribute => <SavingThrowField key={attribute} attribute={attribute} />)}
    </Fieldset>}
  </BaseComponent>;
}