import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, Spacer} from "#lib/components/index.ts";
import {FaTag, FaTrash} from "react-icons/fa";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {memo, useMemo, useRef} from "react";
import {Interaction, InteractionOperation, InteractionSignals} from "common/legends/node/interaction/interaction.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {InputInteractionTriggers} from "./trigger/input-interaction-triggers.tsx";
import {InputInteractionActions} from "./action/input-interaction-actions.tsx";
import {MutableRef} from "common/ref";

export type InteractionEditorProps = {
  value: MutableRef<Interaction, InteractionOperation[]>,
  onClose: () => void;
  remove?: () => void;
};

export const InteractionEditor = memo(function InteractionEditor({value, remove, onClose}: InteractionEditorProps) {
  const {title, triggers, actions} = useMemo(() => InteractionSignals(value), [value]);

  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (<Modal onClose={onClose} initialFocus={initialFocusRef}>
    <ModalTitle>
      <span>Interaction</span>
      <Spacer />
      <ButtonBar>
        <ExpandOptions>
          {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Interaction</Button>}
        </ExpandOptions>
      </ButtonBar>
    </ModalTitle>
    <ModalBody className="pb-8">
      <InputGroup className="flex-1" title="Title">
        <InputGroupIcon><FaTag /></InputGroupIcon>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString ref={initialFocusRef} value={title} />
      </InputGroup>

      <InputInteractionTriggers value={triggers} />
      <InputInteractionActions value={actions} />
    </ModalBody>
  </Modal>);
});
