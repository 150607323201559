import {Button, ButtonBar, Spacer} from "#lib/components/index.ts";
import React, {useCallback, useMemo} from "react";
import {FaTimes} from "react-icons/fa";
import {useEditor} from "../../../editor-context.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {applyAll, Optional, Point, PointOperation, pointType, ValueFn} from "common/types/index.ts";
import {EditorState} from "../../../state/index.ts";
import {distinct, map} from "common/observable";
import {pipe} from "common/pipe";
import {useNode} from "../../../../../viewport/token/use-node.ts";
import {Node, NodeOperation} from "common/legends/index.ts";
import {WallGraphEdge, WallGraphEdgeOperation, WallGraphFn} from "common/legends/node/wall-node.ts";
import {WallToolCreateModeFn} from "../../../../../common/tool-mode/wall/wall-tool-create-mode.ts";
import {WallToolSelectModeFn} from "../../../../../common/tool-mode/wall/wall-tool-select-mode.ts";
import {WallToolDestroyModeFn} from "../../../../../common/tool-mode/wall/wall-tool-destroy-mode.ts";
import {WallToolSlicerModeFn} from "../../../../../common/tool-mode/wall/wall-tool-slicer-mode.ts";
import {WallToolData, WallToolDataOperation} from "../../../../../common/tool-mode/wall/wall-tool-data.ts";
import {useSetSelectTool} from "../use-set-select-tool.ts";
import {LegendsConnectionIndicator} from "../../../../legends-connection-indicator.tsx";
import {MutableRef} from "common/ref";
import {InputWallGraphVertex} from "../../../../../panel/properties/node/wall-segment/input-wall-graph-vertex.tsx";
import {InputWallGraphEdge} from "../../../../../panel/properties/node/wall-segment/input-wall-graph-edge.tsx";

function useWallToolMode() {
  const editor = useEditor();
  return useMemo((): MutableRef<Optional<WallToolData>, WallToolDataOperation[]> => {
    const valueFn = (v: EditorState) => v?.data.toolMode.type === "wall" ? v.data.toolMode.data: undefined;
    return new MutableRef({
      value() {return valueFn(editor.state.value)},
      observe: pipe(editor.state.observe, map(valueFn), distinct()),
      apply: fn => editor.state.apply(prev => {
        if (prev?.data.toolMode.type !== "wall") return [];
        const operations: WallToolDataOperation[] = fn(prev.data.toolMode.data);
        if (operations.length === 0) return [];
        return ValueFn.apply([{type: prev.type, operations: [{
            type: "update-tool-mode",
            operations: ValueFn.apply([{type: "wall", operations}])
          }]}]);
      }).then(valueFn)
    });
  }, [editor.state]);
}

export const WallEditorNavigation = React.memo(function WallEditorNavigation() {
  const wallToolMode = useWallToolMode();
  const wallTool = useRefValue(wallToolMode);

  const toggleCreateMode = useCallback(() => {
    wallToolMode.apply(prev => [{type: "update-mode", operations: ValueFn.set(prev?.mode, WallToolCreateModeFn.DEFAULT)}])
  }, [wallToolMode]);
  const toggleSelectMode = useCallback(() => {
    wallToolMode.apply(prev => [{type: "update-mode", operations: ValueFn.set(prev?.mode, WallToolSelectModeFn.DEFAULT)}])
  }, [wallToolMode]);
  const toggleDestroyMode = useCallback(() => {
    wallToolMode.apply(prev => [{type: "update-mode", operations: ValueFn.set(prev?.mode, WallToolDestroyModeFn.DEFAULT)}])
  }, [wallToolMode]);
  const toggleSlicerMode = useCallback(() => {
    wallToolMode.apply(prev => [{type: "update-mode", operations: ValueFn.set(prev?.mode, WallToolSlicerModeFn.DEFAULT)}])
  }, [wallToolMode]);
  const exit = useSetSelectTool();

  const node = useNode(useComputedValue(wallToolMode, mode => mode?.nodeID));

  const selectedPointSignal = useMemo((): MutableRef<Optional<Point>, PointOperation[]> => {
    const valueFn = (node: Optional<Node>): Optional<Point> => {
      if (node === undefined) return undefined;
      if (wallTool?.mode?.type !== "select" || wallTool.mode.data?.type !== "vertex") return undefined;
      const selection = wallTool.mode.data;
      if (!selection) return undefined;
      if (node.type === "wall") {
        return node.data.graph.vertices[selection.vertexIndex]?.coordinate;
      }
      return undefined;
    };
    return new MutableRef({
      value(): Optional<Point> {
        return valueFn(node.value);
      },
      observe: pipe(node.observe, map(valueFn), distinct()),
      apply: fn => node.apply((node): NodeOperation[] => {
        if (node?.type !== "wall") return [];
        if (wallTool?.mode?.type !== "select" || wallTool.mode.data?.type !== "vertex") return [];
        const selection = wallTool.mode.data;
        if (!selection) return [];
        const vertex = node.data.graph.vertices[selection.vertexIndex];
        if (vertex === undefined) return [];
        const operations = fn(vertex.coordinate);
        if (operations.length === 0) return [];
        const finalPosition = applyAll(pointType, vertex.coordinate, operations);
        return [{type: node.type, operations: [{
            type: "update-graph", operations: WallGraphFn.moveVertex(node.data.graph, selection.vertexIndex, finalPosition)
          }]}];
      }).then(valueFn)
    });
  }, [node, wallTool?.mode?.type === "select" && wallTool.mode.data?.type === "vertex" ? wallTool?.mode?.data?.vertexIndex : undefined]);

  const isPointSelected = useComputedValue(selectedPointSignal, value => value !== undefined);
  const selectedPoint = useMemo(() => {
    return !isPointSelected ? undefined : selectedPointSignal as MutableRef<Point, PointOperation[]>;
  }, [isPointSelected, selectedPointSignal]);

  const removeSelectedPoint = () => {
    node.apply(node => {
      if (node?.type !== "wall") return [];
      const selection = wallTool?.mode?.type === "select" && wallTool.mode.data?.type === "vertex" && wallTool.mode.data;
      if (!selection) return [];
      const operations = WallGraphFn.deleteVertex(node.data.graph, selection.vertexIndex);
      if (operations.length === 0) return [];
      return [{type: node.type, operations: [{type: "update-graph", operations}]}];
    });
    wallToolMode.apply((prev): WallToolDataOperation[] => {
      if (prev?.mode?.type !== "select") return [];
      return [{type: "update-mode", operations: ValueFn.apply([{type: "select", operations: ValueFn.set(prev.mode.data, undefined)}])}];
    })
  };


  const selectedEdgeSignal = useMemo((): MutableRef<Optional<WallGraphEdge>, WallGraphEdgeOperation[]> => {
    const valueFn = (node: Optional<Node>): Optional<WallGraphEdge> => {
      if (node === undefined) return undefined;
      if (wallTool?.mode?.type !== "select" || (wallTool.mode.data?.type !== "edge" && wallTool.mode.data?.type !== "control-point")) return undefined;
      const selection = wallTool.mode.data;
      if (!selection) return undefined;
      if (node.type === "wall") {
        return node.data.graph.edges[selection.edgeIndex];
      }
      return undefined;
    };
    return new MutableRef({
      value(): Optional<WallGraphEdge> {
        return valueFn(node.value);
      },
      observe: pipe(node.observe, map(valueFn), distinct()),
      apply: fn => node.apply((node): NodeOperation[] => {
        if (node?.type !== "wall") return [];
        if (wallTool?.mode?.type !== "select" || (wallTool.mode.data?.type !== "edge" && wallTool.mode.data?.type !== "control-point")) return [];
        const selection = wallTool.mode.data;
        if (!selection) return [];
        const edge = node.data.graph.edges[selection.edgeIndex];
        if (edge === undefined) return [];
        const operations = fn(edge);
        if (operations.length === 0) return [];
        return [{type: node.type, operations: [{
            type: "update-graph", operations: [{type: "update-edge", index: selection.edgeIndex, operations}]
          }]}];
      }).then(valueFn)
    });
  }, [node, wallTool?.mode?.type === "select" && (wallTool.mode.data?.type === "edge" || wallTool.mode.data?.type === "control-point") ? wallTool?.mode?.data?.edgeIndex : undefined]);
  const isEdgeSelected = useComputedValue(selectedEdgeSignal, value => value !== undefined);
  const selectedEdge = useMemo(() => {
    return !isEdgeSelected ? undefined : selectedEdgeSignal as MutableRef<WallGraphEdge, WallGraphEdgeOperation[]>;
  }, [isEdgeSelected, selectedEdgeSignal]);
  const removeSelectedEdge = () => {
    node.apply(node => {
      if (node?.type !== "wall") return [];
      const selection = wallTool?.mode?.type === "select" && (wallTool.mode.data?.type === "edge" || wallTool.mode.data?.type === "control-point") && wallTool.mode.data;
      if (!selection) return [];
      const operations = WallGraphFn.deleteEdge(node.data.graph, selection.edgeIndex);
      if (operations.length === 0) return [];
      return [{type: node.type, operations: [{type: "update-graph", operations}]}];
    });
    wallToolMode.apply((prev): WallToolDataOperation[] => {
      if (prev?.mode?.type !== "select") return [];
      return [{type: "update-mode", operations: ValueFn.apply([{type: "select", operations: ValueFn.set(prev.mode.data, undefined)}])}];
    })
  };

  return (<div className="basis-14 flex flex-row h-14 text-white items-center gap-2 bg-zinc-700/20">
    <LegendsConnectionIndicator/>

    {selectedPoint && <InputWallGraphVertex value={selectedPoint} remove={removeSelectedPoint} />}
    {selectedEdge && <InputWallGraphEdge value={selectedEdge} remove={removeSelectedEdge} />}

    <Spacer/>

    <ButtonBar>
      <Button variant={wallTool?.mode?.type === "select" ? "primary" : "tertiary"} onClick={toggleSelectMode}>Select</Button>
      <Button variant={wallTool?.mode?.type === "create" ? "primary" : "tertiary"} onClick={toggleCreateMode}>Create</Button>
      <Button variant={wallTool?.mode?.type === "destroy" ? "primary" : "tertiary"} onClick={toggleDestroyMode}>Destroy</Button>
      <Button variant={wallTool?.mode?.type === "slicer" ? "primary" : "tertiary"} onClick={toggleSlicerMode}>Slicer</Button>
    </ButtonBar>

    <Button className="px-4" size="large" onClick={exit}><FaTimes /> Exit</Button>
  </div>);
});

