import {DiceResult} from "./execute-dice-formula.ts";

export function getDiceRolls(diceResult: DiceResult): number[] {
  switch (diceResult.op) {
    case "dice": return [
      ...getDiceRolls(diceResult.source.count),
      ...getDiceRolls(diceResult.source.faces),
      ...diceResult.values.flatMap(value => value.rolls)
    ];
    case "min": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "max": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "add": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "subtract": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "multiply": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "divide": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];
    case "remainder": return [...getDiceRolls(diceResult.source.left), ...getDiceRolls(diceResult.source.right)];

    case "drop-highest": return [...getDiceRolls(diceResult.source.dice), ...getDiceRolls(diceResult.source.count)];
    case "drop-lowest": return [...getDiceRolls(diceResult.source.dice), ...getDiceRolls(diceResult.source.count)];
    case "keep-highest": return [...getDiceRolls(diceResult.source.dice), ...getDiceRolls(diceResult.source.count)];
    case "keep-lowest": return [...getDiceRolls(diceResult.source.dice), ...getDiceRolls(diceResult.source.count)];

    case "explode": return [
      ...getDiceRolls(diceResult.source.dice),
      ...getDiceRolls(diceResult.source.target),
      ...diceResult.values.flatMap(value => value.rolls)
    ];
    case "reroll": return [
      ...getDiceRolls(diceResult.source.target),
      ...diceResult.values.flatMap(value => value.rolls)
    ];
    case "reroll-once": return [
      ...getDiceRolls(diceResult.source.target),
      ...diceResult.values.flatMap(value => value.rolls)
    ];
    case "parentheses": return getDiceRolls(diceResult.source.expression);
    case "count-success": return [
      ...getDiceRolls(diceResult.source.dice),
      ...getDiceRolls(diceResult.source.target)
    ];
    case "count-failure": return [
      ...getDiceRolls(diceResult.source.dice),
      ...getDiceRolls(diceResult.source.target)
    ];
    case "constant": return [];
    case "variable": return [];
  }
}