import {DialogID, Layout, LayoutOperation, StackFn, StackItem, StackItemFn, StackOperation} from "../../modal/index.ts";
import React, {useCallback, useMemo} from "react";
import {DialogBottomResizer, DialogLeftResizer, DialogRightResizer, DialogTopNav} from "./resizer/index.ts";
import {ApplyAction} from "#lib/qlab/index.ts";
import "./dialog-view.css";
import {DialogItem, DialogItemOperation} from "../../modal/dialog/dialog-item.ts";
import {useApplyToContainer} from "../container-context.ts";
import {twMerge} from "tailwind-merge";
import {StackContentView} from "#lib/container/react/stack/stack-content-view.tsx";
import {StackTabView} from "#lib/container/react/stack/stack-tab-view.tsx";
import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {Stacks} from "#lib/container/react/stack/stacks.ts";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";

export type DialogViewProps<LV, LO> = {
  value: DialogItem<LV>;
  applyToDialog: (dialogId: DialogID, action: ApplyAction<DialogItem<LV>, DialogItemOperation<LV, LO>[]>) => Promise<Optional<DialogItem<LV>>>;
  StackContentView: StackContentView<LV, LO>;
  StackTabView: StackTabView<LV>;
  Stacks: Stacks<LV>;
};

export function DialogView<LV, LO>({value, applyToDialog, StackTabView, StackContentView, Stacks}: DialogViewProps<LV, LO>) {
  const apply = useCallback((fn: ApplyAction<DialogItem<LV>, DialogItemOperation<LV, LO>[]>) => {
    return applyToDialog(value.id, fn);
  }, [value.id])

  const applyToContainer = useApplyToContainer();
  const onMouseDown = useCallback(() => applyToContainer(_ => [{type: "activate-dialog", id: value.id}]), [value.id, applyToContainer]);
  const onClose = useCallback(() => {
    applyToContainer(_ => [{type: "destroy-dialog", id: value.id}]);
  }, [applyToContainer, value.id]);
  const onExpand = useCallback(() => {
    applyToContainer(_ => [{type: "apply-to-dialog", id: value.id, operations: [{type: "externalize"}]}]);
  }, [applyToContainer, value.id]);

  const minimized = value.minimized;
  const toggleMinimized = useCallback(() => apply(prevValue => [{type: "set-minimized", value: !prevValue.minimized}]), [apply]);

  const applyToLayout = useCallback((fn: ApplyAction<Layout<LV>, LayoutOperation<LV, LO>[]>) => apply(prevValue => {
    if (prevValue.value.type !== "layout") return [];
    return [{type: "apply-to-layout", operations: fn(prevValue.value.layout).flatMap(op => op.type === "apply-to-stack" ? op.operations : [])}];
  }).then(prev => {
    if (prev === undefined || prev.value.type !== "layout") return undefined;
    return prev.value.layout;
  }), [apply]);


  const items = value.value.layout.items;
  const activeId = value.value.layout.activeId;
  const selectedStackItem = useMemo<StackItem<LV> | undefined>(() => {
    for (const item of items) {
      if (item.id === activeId) return item;
    }
    return undefined;
  }, [items, activeId]);


  const activeIndex = items.findIndex(item => item.id === activeId);
  const applyToStackItemContent = useCallback((fn: ApplyAction<Optional<LV>, LO[]>): Promise<Optional<LV>> => {
    const operations: LO[] = fn(selectedStackItem!.content);
    const updateOperations: StackOperation<LV, LO>[] = StackFn.updateItems(ListOperation.apply(
      activeIndex,
      StackItemFn.updateContent(ValueFn.apply(operations))
    ));
    return applyToDialog(value.id, _ => [{type: "apply-to-layout", operations: updateOperations}]).then(response => {
      return response?.value.layout.items[activeIndex].content;
    });
  }, [apply, activeIndex, selectedStackItem]);

  return (<div className={twMerge("dialog-view", minimized && "minimized")} style={{
    position: "absolute",
    left: `${value.position[0]}px`,
    top: `${value.position[1]}px`,
    width: `${value.size[0]}px`,
    height: (!minimized ? `${value.size[1]}px` : `32px`),
  }} onMouseDown={onMouseDown}>
    <DialogTopNav dialogId={value.id} stack={value.value.layout} StackTabView={StackTabView} position={value.position} minimized={minimized} onToggleMinimized={toggleMinimized} onExpand={onExpand} onClose={onClose} applyToStack={applyToLayout} Stacks={Stacks} />
    {!minimized && <div className="flex flex-1 min-h-0 overflow-hidden">
      <DialogLeftResizer dialogId={value.id} minimized={minimized} />
      <div className="dialog-content">
        <ErrorBoundary>
          {selectedStackItem && <StackContentView key={selectedStackItem.id} value={selectedStackItem.content} apply={applyToStackItemContent} />}
        </ErrorBoundary>
      </div>
      <DialogRightResizer dialogId={value.id} minimized={minimized} />
    </div>}
    {!minimized && <DialogBottomResizer dialogId={value.id} minimized={minimized} />}
  </div>);
}

