import {z} from "zod";

export const Dnd5eSpellComponent = z.union([
  z.literal("verbal"),
  z.literal("material"),
  z.literal("somantic"),
  z.literal("royalty")
]);
export type Dnd5eSpellComponent = z.infer<typeof Dnd5eSpellComponent>;

export const DND_5E_SPELL_COMPONENTS = [
  "verbal",
  "material",
  "somantic",
  "royalty"
] as const as readonly Dnd5eSpellComponent[];
