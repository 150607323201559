import React, {useCallback, useContext, useMemo} from "react";
import {ApplyAction, ListOperation} from "common/types/index.ts";
import {Game, GameID, GameOperation, GameReference, Invitation, InvitationOperation} from "common/legends/index.ts";
import {Apply, useApplyCallback} from "#lib/qlab/index.ts";
import {useStoreID} from "./store-context.tsx";
import {MutableRef} from "common/ref";

const GameContext = React.createContext<MutableRef<Game, GameOperation[]> | undefined>(undefined)

export const GameProvider = GameContext.Provider;

export function useGame(): MutableRef<Game, GameOperation[]> {
  return useContext(GameContext)!;
}

export function useGameReference(): GameReference {
  const gameID = useStoreID();
  return useMemo(() => ({storeId: gameID as GameID, accessTokens: []}), [gameID]);
}

export function useGameApply(): Apply<Game, GameOperation[]> {
  const game = useGame();
  return useCallback((fn: ApplyAction<Game, GameOperation[]>) => game.apply(prev => typeof fn === "function" ? fn(prev) : fn), [game]);
}

const getInvitations = (value: Game) => value.invitations;
const updateInvitations = (operations: ListOperation<Invitation, InvitationOperation>[]): GameOperation[] => [{type: "update-invitations", operations}];
export function useApplyToGameInvitations(): Apply<Invitation[], ListOperation<Invitation, InvitationOperation>[]> {
  return useApplyCallback(useGameApply(), getInvitations, updateInvitations);
}

