import {IconButton, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {ValueFn} from "common/types/index.ts";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eSpellSlotConsumptionModifier,
  Dnd5eSpellSlotConsumptionModifierOperation,
  Dnd5eSpellSlotConsumptionModifierSignal,
  Dnd5eSpellSlotLevel,
  DND_5E_SPELL_SLOT_LEVEL,
  DND_5E_SPELL_SLOT_LEVEL_TITLE
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-spell-slot-consumption-modifier.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eSpellSlotConsumptionModifierViewProps = {
  value: MutableRef<Dnd5eSpellSlotConsumptionModifier, Dnd5eSpellSlotConsumptionModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eSpellSlotConsumptionModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eSpellSlotConsumptionModifierViewProps) {
  const {level} = useMemo(() => Dnd5eSpellSlotConsumptionModifierSignal(value), [value]);
  const levelValue = useRefValue(level);

  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Consume</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1 pr-0">
      <InputGroupLabel>Spell Slot Level</InputGroupLabel>
      <Select disabled={readOnly} value={levelValue !== undefined ? levelValue : ""} onChange={readOnly ? undefined : (ev) => {
        const next = ev.target.value;
        level.apply(prev => ValueFn.set(prev, next !== "" ? next as Dnd5eSpellSlotLevel : undefined));
      }}>
        <option value={""}></option>
        {DND_5E_SPELL_SLOT_LEVEL.map(level => <option key={level} value={level}>{DND_5E_SPELL_SLOT_LEVEL_TITLE[level]}</option>)}
      </Select>
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
