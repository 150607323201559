import {Observer, Subscription} from "#common/observable/observable.ts";
import {MutableRef} from "../ref.ts";

export function createValueRef<Value>(initialValue: Value): MutableRef<Value, Value> {
  let value = initialValue;
  let observers: Observer<Value>[] = [];
  return new MutableRef<Value, Value>({
    value() {return value},
    observe: (observer: Observer<Value>): Subscription => {
      observers.push(observer);
      observer.next(value);
      return () => {
        const index = observers.indexOf(observer);
        if (index !== -1) observers.splice(index, 1);
      };
    },
    apply: async (fn: (prev: Value) => Value) => {
      let nextValue = fn(value);
      if (nextValue !== value) {
        value = nextValue;
        observers.forEach(observer => {
          try {observer.next(value)} catch (e) {console.error(e)}
        });
      }
      return value;
    }
  });
}
