import {MouseEvent} from "react";
import {PlayerStacks} from "./player-stacks.ts";
import {PlayerStackValue} from "./player-stack-type.ts";
import {DialogID, LayoutPath, StackItem} from "#lib/container/index.ts";
import {PlayerSheetTabView} from "../../panel/sheet/view/player-sheet-tab-view.tsx";
import {StackItemTab} from "#lib/container/react/stack/stack-item-tab.tsx";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";

export type StackTabViewProps = {
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  value: StackItem<PlayerStackValue>;
  active: boolean;
  onClose?: () => void;
  onToggle: () => void;
};

export function PlayerStackTabView({active, dialogId, layoutPath, value, onClose, onToggle}: StackTabViewProps) {
  const [isDragged, dragRef, dragPreviewRef] = useDrag(() => ({
    type: "legends/tab",
    item: ({
      dialogId,
      layoutPath,
      stackId: value.id,
      value: value
    }),
    collect: (monitor) => monitor.isDragging()
  }), [dialogId, layoutPath, value]);
  dragPreviewRef(EMPTY_IMAGE);

  const onMiddleClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (ev.button === 1 && onClose) onClose();
  };

  const stack = PlayerStacks[value.content.type];
  let label = stack.label;
  const Icon = stack.Icon;
  if (value.content.type === "sheet") {
    return <PlayerSheetTabView
      ref={dragRef}
      id={value.id}
      content={value.content.data}
      style={isDragged ? {display: "none"} : {}}
      active={active}
      onClick={onToggle} onClose={onClose}
      onMouseDown={onMiddleClick}
    />
  } else {
    return (<StackItemTab
      ref={dragRef}
      style={isDragged ? {display: "none"} : {}}
      active={active}
      Icon={Icon}
      label={label}
      onClick={onToggle} onClose={onClose}
      onMouseDown={onMiddleClick}
    />);
  }
}
