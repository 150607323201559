import {z} from "zod";
import {ObjectType, PropertyRef} from "../object/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {Type} from "../../type/index.ts";
import {MutableRef} from "#common/ref";

export const Rectangle = z.object({
  width: z.number(),
  height: z.number()
});
export type Rectangle = z.infer<typeof Rectangle>;

export const RectangleOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-width"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-height"), operations: z.array(NumberOperation)})
]);
export type RectangleOperation = z.infer<typeof RectangleOperation>;

export const rectangleType: Type<Rectangle, RectangleOperation> = new ObjectType({
  width: numberType,
  height: numberType
});

export function RectangleRef(ref: MutableRef<Rectangle, RectangleOperation[]>) {
  return ({
    widthRef: PropertyRef<Rectangle, RectangleOperation, number, NumberOperation>(value => value.width, operations => [{type: "update-width", operations}])(ref),
    heightRef: PropertyRef<Rectangle, RectangleOperation, number, NumberOperation>(value => value.height, operations => [{type: "update-height", operations}])(ref)
  })
}