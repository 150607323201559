import {useEffect, useState} from "react";

export function useIsAltDown() {
  const [alt, setAlt] = useState(false);
  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      setAlt(ev.altKey);
    };
    document.addEventListener('keydown', keydownHandler);
    document.addEventListener('keyup', keydownHandler);
    return () => {
      document.addEventListener('keydown', keydownHandler);
      document.removeEventListener("keyup", keydownHandler);
    };
  }, [setAlt])
  return alt;
}
