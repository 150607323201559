import {KeyboardEvent, RefObject, useCallback} from "react";
import {NumberFn, Point, PointFn, Transform, TransformOperation} from "common/types/index.ts";
import {Matrix4f, Vector2f} from "#lib/math/index.ts";
import {useGetScreenPosition} from "./use-get-screen-pos.ts";
import {MutableRef} from "common/ref";

export function useZoomHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>
) {
  const getScreenPos = useGetScreenPosition(canvasRef);

  const setZoom = useCallback((fn: (prevZoom: number) => number, [sx, sy]: Point) => {
    view.apply((prevValue: Transform): TransformOperation[] => {
      const newScale = Math.pow(2, Math.max(-5, Math.min(fn(Math.log2(prevValue.scale)), 4)));
      const viewMat4 = Matrix4f.transform(prevValue);
      const mousePosition = Matrix4f.multiplyVector(Matrix4f.invert(viewMat4), [sx, sy, 0, 1]).slice(0, 2) as Vector2f;
      const prevPosition = Vector2f.multiply(prevValue.position, -1 / prevValue.scale);
      const rotation = prevValue.rotation*Math.PI/180;
      const viewPosition: Vector2f = Vector2f.rotate(prevPosition, -rotation);
      const diff = Vector2f.subtract(viewPosition, mousePosition);
      const newPosition = Vector2f.rotate(Vector2f.subtract(
        Vector2f.multiply(viewPosition, -newScale),
        Vector2f.subtract(
          Vector2f.multiply(diff, prevValue.scale),
          Vector2f.multiply(diff, newScale)
        )
      ), rotation);

      return [{
        type: "apply",
        operations: [
          {type: "update-position", operations: PointFn.set(prevValue.position, newPosition)},
          {type: "update-scale", operations: NumberFn.set(prevValue.scale, newScale)}
        ]
      }];
    });
  }, [view]);
  const onZoomWheel = useCallback((ev: WheelEvent) => {
    if (!canvasRef.current) return true;
    if (ev.deltaY === 0) return true;
    if (ev.ctrlKey) return true;
    ev.preventDefault();

    let ratio = 1/4;
    if (ev.deltaMode === ev.DOM_DELTA_PIXEL) ratio *= Math.abs(ev.deltaY) < 16 ? 1/16 : 1/64;
    else if (ev.deltaMode === ev.DOM_DELTA_LINE) ratio *= 1/16;
    else if (ev.deltaMode === ev.DOM_DELTA_PAGE) ratio *= 2;

    if (ev.shiftKey) ratio *= 2;

    const scrollDelta = -ev.deltaY * ratio;
    const pos = getScreenPos([ev.clientX, ev.clientY]);
    setZoom(prev => prev + scrollDelta / 4, pos);
    return false;
  }, [view, getScreenPos]);

  const onZoomKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (!ev.ctrlKey) return true;
    if (ev.key === "=") {
      ev.preventDefault();
      setZoom((prev) => prev + 0.25, [0, 0]);
      return false;
    } else if (ev.key === "-") {
      ev.preventDefault();
      setZoom((prev) => prev - 0.25, [0, 0]);
      return false;
    } else if (ev.key === "0") {
      ev.preventDefault();
      setZoom(_ => 0, [0, 0]);
      return false;
    } else {
      return true;
    }
  }, [setZoom]);

  return {onZoomKeyDown, onZoomWheel}
}