import React, {useContext} from "react";
import {Sheet, SheetOperation} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {MutableRef} from "common/ref";

const SheetContext = React.createContext<MutableRef<Optional<Sheet>, SheetOperation[]> | undefined>(undefined);

export const SheetProvider = SheetContext.Provider;

export function useSheetCtx(): MutableRef<Optional<Sheet>, SheetOperation[]> {
  return useContext(SheetContext)!;
}

