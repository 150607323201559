import {Point, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {getWorldPositionFromScreenPosition} from "./use-get-world-pos.ts";
import {useCallback} from "react";
import {Node, NodeOperation} from "common/legends/index.ts";
import {MutableRef} from "common/ref";

export function useDropHandlers(
  view: MutableRef<Transform, TransformOperation[]>,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>
) {
  const onDrop = useCallback((screenPos: Point, fn: (nodePosition: Point) => Node) => {
    nodes.apply((nodes: Node[]): TreeOperation<Node, NodeOperation>[] => {
      const imagePosition = getWorldPositionFromScreenPosition(view.value, screenPos);
      const node = fn(imagePosition);
      return [{
        type: "insert",
        path: [nodes.length],
        item: node
      }];
    });
  }, [view, nodes]);

  return {
    onDrop
  };
}
