import {z} from "zod";

export const DecryptPrivateKey = z.string().brand("DecryptPrivateKey");
export type DecryptPrivateKey = z.infer<typeof DecryptPrivateKey>;

export const SignPrivateKey = z.string().brand("SignPrivateKey");
export type SignPrivateKey = z.infer<typeof SignPrivateKey>;

export const PrivateKey = z.tuple([
  DecryptPrivateKey,
  SignPrivateKey
]);
export type PrivateKey = z.infer<typeof PrivateKey>;
