import {PrivateKey, PublicKey} from "../../crypto/index.ts";
import {ConstantOperation, ConstantType, ObjectType, Type, ValueOperation, ValueType} from "../../types/index.ts";
import {UserID} from "../user/user-id.ts";

export type Bot = {
  owner: UserID;
  publicKey: PublicKey;
  privateKey: PrivateKey;
};

export type BotOperation =
  | {type: "update-owner", operations: ValueOperation<UserID, ConstantOperation>[]}
  | {type: "update-public-key", operations: ValueOperation<PublicKey, ConstantOperation>[]}
  | {type: "update-private-key", operations: ValueOperation<PrivateKey, ConstantOperation>[]}
  ;

export const botType: Type<Bot, BotOperation> = new ObjectType({
  owner: new ValueType(new ConstantType<UserID>()),
  publicKey: new ValueType(new ConstantType<PublicKey>()),
  privateKey: new ValueType(new ConstantType<PrivateKey>())
});

declare module "../../qlab/store/store.ts" {
  export interface StoreTypes {
    bot: Type<Bot, BotOperation>;
  }
}
