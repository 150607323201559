import {useMemo} from "react";
import {Optional, Tree, TreeOperation} from "common/types/index.ts";
import {AreaNode, AreaNodeOperation} from "common/legends/node/area-node.ts";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, map} from "common/observable";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool-mode/area/area-tool-data.ts";
import {MutableRef} from "common/ref";

export function useEditorAreaNode(
  areaTool: MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]>,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>
) {
  return useMemo((): MutableRef<Optional<AreaNode>, AreaNodeOperation[]> => {
    const valueFn = (nodes: Node[], nodeID: Optional<NodeId>): Optional<AreaNode> => {
      const node = nodeID ? Tree.getItemById(nodes, nodeID) : undefined;
      if (node?.type === "area") return node?.data;
      return undefined;
    }
    return new MutableRef({
      value(): Optional<AreaNode> {return valueFn(nodes.value, areaTool.value?.nodeID)},
      observe: pipe(
        combine(nodes.observe, areaTool.observe),
        map(([nodes, areaTool]) => valueFn(nodes, areaTool?.nodeID)),
        distinct()
      ),
      apply: fn => {
        const nodeID = areaTool.value?.nodeID;
        return nodes.apply(prev => {
          const nodePath = Tree.getPath(prev, node => node.data.id === nodeID);
          if (nodePath === undefined) return [];
          const node = Tree.getNode(prev, nodePath);
          if (node?.type !== "area") return [];
          const operations = fn(node.data);
          if (operations.length === 0) return [];
          return TreeOperation.apply(nodePath, [{type: "area", operations}]);
        }).then(nodes => valueFn(nodes, nodeID))
      }
    });
  }, [nodes, areaTool]);
}
