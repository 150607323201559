import {Button, ButtonBar, Checkbox, ExpandableLabel, IconButton, InputGroup, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaPlus, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {BooleanFn, ListOperation} from "common/types/index.ts";
import {Dice} from "common/dice/index.ts";
import React, {useCallback, useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {
  Dnd5eItemEffect,
  Dnd5eItemEffectFn,
  Dnd5eItemEffectOperation,
  Dnd5eItemEffectSignal,
  getItemEffectID
} from "common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect.ts";
import {Dnd5eItemEffectID, generateDnd5eItemEffectID} from "common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect-id.ts";
import {DND_5E_SAVING_THROW_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {Dnd5eModifiersView} from "../dnd-5e-modifier/dnd-5e-modifiers-view.tsx";
import {copyDnd5eItemEffect} from "common/legends/asset/sheet/dnd-5e/character/item/effect/copy-dnd-5e-item-effect.ts";
import {MutableRef} from "common/ref";

export type Dnd5eItemEffectViewProps = InputListItemProps<Dnd5eItemEffect, Dnd5eItemEffectOperation> & {
  toggle: (actionEffectID: Dnd5eItemEffectID) => void;
};
function Dnd5eItemEffectView({item, remove, toggle, duplicate}: Dnd5eItemEffectViewProps) {
  const [expanded, toggleExpanded] = useToggle(false);

  const {name, enabled, modifiers} =  useMemo(() => Dnd5eItemEffectSignal(item), [item]);
  const enabledValue = useRefValue(enabled);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/item-effect", item, remove);
  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <InputGroup>
          <Checkbox checked={enabledValue} onChange={() => toggle(item.value.effectID)} />
        </InputGroup>
        <InputGroup className="flex-1">
          <InputString type="text" value={name} />
        </InputGroup>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Modifier" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <Dnd5eModifiersView value={modifiers} />
    </Fieldset>}
  </div>;
}

export type Dnd5eItemEffectsViewProps = {
  value: MutableRef<Dnd5eItemEffect[], ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[]>;
};

function defaultItemEffect(): Dnd5eItemEffect {
  return {
    effectID: generateDnd5eItemEffectID(),
    name: "Attuned",
    enabled: false,
    modifiers: [{
      type: "saving-throw",
      data: {
        modifierID: generateDnd5eModifierID(),
        savingThrows: [...DND_5E_SAVING_THROW_TYPES],
        expression: Dice.assertDiceExpression("1"),
        proficiency: "untrained",
        hasAdvantage: false,
        hasDisadvantage: false
      }
    }]
  };
}

export function Dnd5eItemEffectsView({value}: Dnd5eItemEffectsViewProps) {
  const addItemEffect = useCallback((actionEffect: Dnd5eItemEffect) => value.apply(prev => ListOperation.insert(prev.length, actionEffect)), [value.apply]);
  const toggle = useCallback((actionEffectID: Dnd5eItemEffectID) => {
    value.apply(prev => {
      const effect = prev.find(e => e.effectID === actionEffectID);
      if (effect === undefined) return [];
      const group = Dnd5eItemEffectFn.getGroup(effect);
      if (group === undefined) {
        return ListOperation.apply(prev.indexOf(effect), [{
          type: "update-enabled",
          operations: BooleanFn.set(effect.enabled, !effect.enabled)
        }]);
      } else {
        if (effect.enabled) {
          return ListOperation.apply(prev.indexOf(effect), [{
            type: "update-enabled",
            operations: BooleanFn.set(effect.enabled, !effect.enabled)
          }]);
        } else {
          const disactivateEffects = prev.filter(e => Dnd5eItemEffectFn.getGroup(e) === group).filter(e => e.enabled);
          const activateEffects = prev.filter(e => Dnd5eItemEffectFn.getGroup(e) === group).filter(e => e.effectID === actionEffectID);
          return [
            ...disactivateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[],
            ...activateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[]
          ];
        }
      }
    });
  }, [value]);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Item Effects</SectionHeader>
      <ButtonBar>
        <Button onClick={() => addItemEffect(defaultItemEffect())}>
          <FaPlus /> New Item Effect
        </Button>
      </ButtonBar>
    </div>

    <InputList<Dnd5eItemEffect, Dnd5eItemEffectOperation>
      accept="legends/item-effect"
      items={value}
      itemKey={getItemEffectID}
      copy={copyDnd5eItemEffect}
      ListItem={useCallback((props) => <Dnd5eItemEffectView {...props} toggle={toggle} />, [toggle])} />
  </div>);
}