import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {walkTree} from "common/types/generic/index.ts";
import {Node} from "common/legends/node/index.ts";
import {getGameMasterIDs} from "./get-game-master-i-ds.ts";

export function getPlayerCharacterTokens(database: QLabDatabase) {
  const gameMasterIDs = getGameMasterIDs(database);
  const elements: Node[] = [];
  for (const resource of Object.values(database.resources)) {
    if (resource?.type !== "scene") continue;
    walkTree(resource.data.children, {
      visit(value) {
        if (value.type === "token") {
          if (value.data.ownerIDs.length > 0 && value.data.ownerIDs.some((ownerID) => !gameMasterIDs.includes(ownerID))) {
            elements.push(value);
          }
        }
      }
    })
  }
  return elements;
}
