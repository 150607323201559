import {DiceResult, diceTreeToDiceExpression, executeDiceFormula, resultToDiceExpression} from "./execute-dice-formula.ts";
import {parseDiceExpression} from "./parse-dice-expression.ts";
import {getDiceRolls} from "./get-dice-rolls.ts";
import {getDiceRange} from "./get-dice-range.ts";
import {DiceExpression} from "./dice-expression.ts";
import {assertDiceExpression} from "./assert-dice-expression.ts";
import {isDiceExpression} from "./is-dice-expression.ts";
import {toConstantDiceExpressionTree} from "./to-expression-without-variables.ts";

export const Dice = {
  assertDiceExpression(expression: string): DiceExpression {
    return assertDiceExpression(expression);
  },
  isDiceExpression(expression: string): expression is DiceExpression {
    return isDiceExpression(expression);
  },
  execute(expression: DiceExpression, context: {[variable: string]: number}): DiceResult {
    return executeDiceFormula(parseDiceExpression(expression), context);
  },
  toDiceExpression(result: DiceResult, context: {[variable: string]: number}): DiceExpression {
    return resultToDiceExpression(result, context);
  },
  getDiceRolls(diceResult: DiceResult) {
    return getDiceRolls(diceResult);
  },
  getRollRange(expression: DiceExpression, context: {[variable: string]: number}): [number, number] {
    const diceRange = getDiceRange(parseDiceExpression(expression), context);
    return [diceRange.minValue, diceRange.maxValue];
  },
  toResolvedExpression(expression: DiceExpression, context: {[variable: string]: number}): DiceExpression {
    const resolvedExpression = toConstantDiceExpressionTree(parseDiceExpression(expression), context);
    return diceTreeToDiceExpression(resolvedExpression);
  },
  toRangeDisplay([min, max]: [number, number]) {
    return (min === max)
      ? min
      : `${min}-${max}`;
  },
  getSuccesses(result: DiceResult): number {
    switch (result.op) {
      case "count-success": {
        const successes = this.getSuccesses(result.source.target) + this.getSuccesses(result.source.target);
        switch (result.source.comparator) {
          case "less": return (result.source.dice.value < result.source.target.value) ? successes + 1 : successes;
          case "less-or-equal": return (result.source.dice.value <= result.source.target.value) ? successes + 1 : successes;
          case "equal": return (result.source.dice.value == result.source.target.value) ? successes + 1 : successes;
          case "greater-or-equal": return (result.source.dice.value >= result.source.target.value) ? successes + 1 : successes;
          case "greater": return (result.source.dice.value > result.source.target.value) ? successes + 1 : successes;
          default: return successes;
        }
      }
      case "dice": return this.getSuccesses(result.source.count) + this.getSuccesses(result.source.faces);
      case "add":
      case "subtract":
      case "multiply":
      case "divide":
      case "max":
      case "min":
      case "remainder":
        return this.getSuccesses(result.source.left) + this.getSuccesses(result.source.right);
      case "drop-lowest":
      case "drop-highest":
      case "keep-highest":
      case "keep-lowest":
        return this.getSuccesses(result.source.dice) + this.getSuccesses(result.source.count);
      case "count-failure":
      case "reroll":
      case "reroll-once":
      case "explode":
        return this.getSuccesses(result.source.dice) + this.getSuccesses(result.source.target);
      case "parentheses": return this.getSuccesses(result.source.expression);
      case "variable":
      case "constant":
        return 0;
      default: return 0;
    }
  },
  getFailures(result: DiceResult): number {
    switch (result.op) {
      case "count-failure": {
        const successes = this.getFailures(result.source.target) + this.getFailures(result.source.target);
        switch (result.source.comparator) {
          case "less": return (result.source.dice.value < result.source.target.value) ? successes + 1 : successes;
          case "less-or-equal": return (result.source.dice.value <= result.source.target.value) ? successes + 1 : successes;
          case "equal": return (result.source.dice.value == result.source.target.value) ? successes + 1 : successes;
          case "greater-or-equal": return (result.source.dice.value > result.source.target.value) ? successes + 1 : successes;
          case "greater": return (result.source.dice.value >= result.source.target.value) ? successes + 1 : successes;
          default: return successes;
        }
      }
      case "dice": return this.getFailures(result.source.count) + this.getFailures(result.source.faces);
      case "add":
      case "subtract":
      case "multiply":
      case "divide":
      case "max":
      case "min":
      case "remainder":
        return this.getFailures(result.source.left) + this.getFailures(result.source.right);
      case "drop-lowest":
      case "drop-highest":
      case "keep-highest":
      case "keep-lowest":
        return this.getFailures(result.source.dice) + this.getFailures(result.source.count);
      case "count-success":
      case "reroll":
      case "reroll-once":
      case "explode":
        return this.getFailures(result.source.dice) + this.getFailures(result.source.target);
      case "parentheses": return this.getFailures(result.source.expression);
      case "variable":
      case "constant":
        return 0;
      default: return 0;
    }
  }
};
