import {z} from "zod";
import {ConstantOperation, constantType, ValueOperation, ValueType} from "common/types/index.ts";
import {WallToolMode} from "./wall-tool-mode.ts";

export const WallToolSelection = z.discriminatedUnion("type", [
  z.object({type: z.literal("vertex"), vertexIndex: z.number()}),
  z.object({type: z.literal("edge"), edgeIndex: z.number()}),
  z.object({type: z.literal("control-point"), edgeIndex: z.number(), controlPointIndex: z.union([z.literal(0), z.literal(1)])})
]);
export type WallToolSelection = z.infer<typeof WallToolSelection>;

export const WallToolSelectMode = z.optional(WallToolSelection);
export type WallToolSelectMode = z.infer<typeof WallToolSelectMode>
export const WallToolSelectModeOperation = ValueOperation(WallToolSelectMode, ConstantOperation);
export type WallToolSelectModeOperation = z.infer<typeof WallToolSelectModeOperation>;
export const wallToolSelectModeType = new ValueType(constantType);

export const WallToolSelectModeFn = {
  DEFAULT: {
    type: "select",
    data: undefined
  } satisfies WallToolMode
};
