import {useCallback} from "react";
import {QLabStoreID, StoreType} from "common/qlab/index.ts";
import {ValueFn} from "common/types/index.ts";
import {useInstance} from "../../q-lab-instance-context.ts";

export function useDeleteStore() {
  const instance = useInstance();
  return useCallback(async <Type extends StoreType>(type: Type, storeId: QLabStoreID) => {
    return instance.applyToStore(storeId, (prev) => {
      if (!prev || prev?.type !== type) throw new Error(`Unexpected type. Got ${prev?.type} but expected: ${type}.`);
      return ValueFn.set(prev, undefined);
    });
  }, [instance]);
}
