import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {Asset, AssetOperation, Token, TokenID, TokenOperation} from "common/legends/asset/index.ts";
import {ListOperation, Optional} from "common/types/generic/index.ts";
import {Loader} from "../loader.ts";

class AssetTokenLoader extends Loader<TokenID, MutableRef<Optional<Token>, TokenOperation[]>> {
  constructor(assetRef: MutableRef<Optional<Asset>, AssetOperation[]>) {
    super(
      key => key,
      (tokenID) => assetRef.map(
        value => value?.tokens.find(token => token.tokenID === tokenID),
        (asset: Optional<Asset>, operations: TokenOperation[]): AssetOperation[] => {
          if (!asset) return [];
          if (operations.length === 0) return [];
          const tokenIndex = asset.tokens.findIndex(token => token.tokenID === tokenID);
          if (tokenIndex === -1) return [];
          return [{type: "update-tokens", operations: ListOperation.apply(tokenIndex, operations)}];
        }
      ).distinct()
    );
  }
}

const loader = new WeakMap<MutableRef<Optional<Asset>, AssetOperation[]>, Loader<TokenID, MutableRef<Optional<Token>, TokenOperation[]>>>();
export function AssetTokenRef(assetRef: MutableRef<Optional<Asset>, AssetOperation[]>, tokenID: Optional<TokenID>): MutableRef<Optional<Token>, TokenOperation[]> {
  if (!tokenID) return NOOP_SIGNAL;
  if (!loader.has(assetRef)) loader.set(assetRef, new AssetTokenLoader(assetRef));
  return loader.get(assetRef)!.get(tokenID);
}
