import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ListOperation, ListSignal} from "common/types/index.ts";
import React, {useState} from "react";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {twMerge} from "tailwind-merge";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {
  copyInteractionAction,
  getInteractionActionID,
  InteractionAction,
  InteractionActionOperation,
  InteractionActionTypes
} from "common/legends/node/interaction/action/interaction-action.ts";
import {InputInteractionActionSetToken} from "./input-interaction-action-set-token.tsx";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";
import {InputInteractionActionTeleport} from "./input-interaction-action-teleport.tsx";
import {generateInteractionActionID} from "common/legends/node/interaction/action/interaction-action-i-d.ts";
import {Menu} from "@headlessui/react";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {usePopper} from "react-popper";
import {InputInteractionActionFocus} from "./input-interaction-action-focus.tsx";
import {InputInteractionActionMount} from "./input-interaction-action-mount.tsx";
import {InputInteractionActionDismount} from "./input-interaction-action-dismount.tsx";

export function InteractionActionField({item, remove}: InputListItemProps<InteractionAction, InteractionActionOperation>) {
  const [type, typeSignal] = useTypedRef<InteractionActionTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/interaction-action", item, remove);
  return (<div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    {type === "set-token" && <InputInteractionActionSetToken dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "teleport" && <InputInteractionActionTeleport dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "focus" && <InputInteractionActionFocus dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "mount" && <InputInteractionActionMount dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "dismount" && <InputInteractionActionDismount dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
  </div>);
}

export type InputInteractionActionsProps = {
  value: ListSignal<InteractionAction, InteractionActionOperation>;
};
export function InputInteractionActions({value}: InputInteractionActionsProps) {
  const onAddSetTokenInteractionAction = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "set-token", data: {interactionActionID: generateInteractionActionID(), tokenID: undefined}} satisfies InteractionAction
    ));
  };
  const onAddTeleportInteractionAction = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "teleport", data: {interactionActionID: generateInteractionActionID(), target: "source", nodeID: undefined, offset: [0, 0]}} satisfies InteractionAction
    ));
  };
  const onAddFocusInteractionAction = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "focus", data: {interactionActionID: generateInteractionActionID(), nodeID: undefined}} satisfies InteractionAction
    ));
  };
  const onAddMountInteractionAction = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "mount", data: {interactionActionID: generateInteractionActionID()}} satisfies InteractionAction
    ));
  };
  const onAddDismountInteractionAction = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "dismount", data: {interactionActionID: generateInteractionActionID()}} satisfies InteractionAction
    ));
  };

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });
  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Actions</SectionHeader>
      <Menu ref={ref => setReferenceElement(ref)} as="div">
        <Menu.Button as={Button}>
          <FaPlus /> Add Action
        </Menu.Button>
        <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
          <Menu.Item as={Button} className="justify-start" onClick={onAddSetTokenInteractionAction}>
            <span>Set Token</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddTeleportInteractionAction}>
            <span>Teleport</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddFocusInteractionAction}>
            <span>Focus</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddMountInteractionAction}>
            <span>Mount</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddDismountInteractionAction}>
            <span>Dismount</span>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>

    <InputList<InteractionAction, InteractionActionOperation>
      accept="legends/interaction-action"
      items={value}
      itemKey={getInteractionActionID}
      copy={copyInteractionAction}
      ListItem={InteractionActionField} />
  </div>);
}
