import {Observer, Subscription} from "common/observable";
import {MutableRef, Ref} from "common/ref";

const observers: Observer<string | undefined>[] = [];

function getExpiration(token: string) {
  const [_header, _payload, _signature] = token.split(".");
  const payload = JSON.parse(atob(_payload));
  return payload.exp || Infinity;
}

export type AuthToken = string;

let token: string | undefined = localStorage.getItem("token") ?? undefined;
if (token !== undefined) {
  const exp = getExpiration(token);
  const now = Date.now() / 1000;
  if (now >= exp) {
    localStorage.removeItem("token");
    token = undefined;
  }
}

export const authClient = {
  tokenObservable(observer: Observer<string | undefined>): Subscription {
    observers.push(observer);
    observer.next(authClient.getToken());
    return () => {
      observers.splice(observers.indexOf(observer), 1);
    };
  },
  getToken(): string | undefined {
    return token ?? undefined;
  },
  updateToken(newToken: string | undefined) {
    if (newToken !== undefined) {
      localStorage.setItem("token", newToken);
    } else {
      localStorage.removeItem("token");
    }
    token = newToken;
    observers.forEach(observer => {
      try {observer.next(newToken ?? undefined)} catch (e) {console.error(e)}
    });
  }
};

let prevHandler: ReturnType<typeof setTimeout>;
authClient.tokenObservable(({
  next: (token) => {
    clearTimeout(prevHandler);
    if (token === undefined) return;
    const now = Date.now() / 1000;
    const exp = getExpiration(token);
    if (now >= exp) {
      authClient.updateToken(undefined);
      window.location.href = "/auth/authorize";
    } else {
      prevHandler = setTimeout(() => {
        authClient.updateToken(undefined);
        window.location.href = "/auth/authorize";
      }, Math.min(0x7FFFFFFF, (exp - now) * 1000));
    }
  },
  error: console.error,
  complete: () => {}
}));

export const authTokenRef: Ref<AuthToken | undefined> = new MutableRef<string | undefined, never[]>({
  value(): AuthToken | undefined {
    return authClient.getToken() ?? undefined;
  },
  observe: authClient.tokenObservable,
  apply: () => {throw new Error("Unsupported")}
});
