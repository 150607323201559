import {useEffect, useState} from "react";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {useComputedValue} from "#lib/signal/index.ts";

export function useConnected(): boolean {
  const database = useDatabase();
  return useComputedValue(database, database => database.connected);
}

export function useHasOutstandingChangesets(): boolean {
  const database = useDatabase();
  const [delayedStatus, setDelayedStatus] = useState(false);

  const hasOutstandingChangesets = useComputedValue(database, database => database.outstandingChangesets);
  useEffect(() => {
    if (hasOutstandingChangesets) {
      const timer = setTimeout(() => {
        setDelayedStatus(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setDelayedStatus(false);
    }
  }, [hasOutstandingChangesets]);
  return delayedStatus;
}