import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {Optional, ValueFn} from "common/types/generic/index.ts";
import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {Loader} from "../loader.ts";
import {MessageOperation, MessageValue, QLabMessageID} from "common/qlab/message/index.ts";

class MessageLoader extends Loader<QLabMessageID, MutableRef<Optional<MessageValue>, MessageOperation[]>> {
  constructor(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
    super(
      key => key,
      key => databaseRef.map(
        (database: QLabDatabase): Optional<MessageValue> => {
          return database.messages[key];
        },
        (database: QLabDatabase, operations: MessageOperation[]): QLabDatabaseOperation[] => {
          if (operations.length === 0) return [];
          const asset = database.messages[key];
          if (!asset) return [];
          return [{type: "message", messageID: key, operations: ValueFn.apply(operations)}];
        }
      ).distinct()
    );
  }
}

const loader = new WeakMap<MutableRef<QLabDatabase, QLabDatabaseOperation[]>, Loader<QLabMessageID, MutableRef<Optional<MessageValue>, MessageOperation[]>>>();
export function MessageRef(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, messageID: Optional<QLabMessageID>): MutableRef<Optional<MessageValue>, MessageOperation[]> {
  if (!messageID) return NOOP_SIGNAL;
  if (!loader.has(databaseRef)) loader.set(databaseRef, new MessageLoader(databaseRef));
  return loader.get(databaseRef)!.get(messageID);
}
