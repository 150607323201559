import {z} from "zod";
import {AreaToolCreateMode, AreaToolCreateModeOperation, areaToolCreateModeType} from "./area-tool-create-mode.ts";
import {ConstantOperation, constantType, MultiType, ValueOperation, ValueType} from "common/types/index.ts";
import {AreaToolSelectMode} from "./area-tool-select-mode.ts";

export const AreaToolMode = z.discriminatedUnion("type", [
  z.object({type: z.literal("select"), data: AreaToolSelectMode}),
  z.object({type: z.literal("create"), data: AreaToolCreateMode})
]);
export type AreaToolMode = z.infer<typeof AreaToolMode>;

export const AreaToolModeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("select"), operations: z.array(ValueOperation(AreaToolSelectMode, ConstantOperation))}),
  z.object({type: z.literal("create"), operations: z.array(AreaToolCreateModeOperation)})
]);
export type AreaToolModeOperation = z.infer<typeof AreaToolModeOperation>;

export const areaToolModeType = new MultiType({
  select: new ValueType(constantType),
  create: areaToolCreateModeType
});

export type AreaToolModes = {
  select: {
    value: AreaToolSelectMode,
    operation: ValueOperation<AreaToolSelectMode, ConstantOperation>
  };
  create: {
    value: AreaToolCreateMode,
    operation: AreaToolCreateModeOperation
  };
};

