import {Button, InputGroup, InputGroupLabel, TagListInput, useTempRef} from "#lib/components/index.ts";
import React from "react";
import {ConstantOperation, ListOperation, ListType, stringType, Tag, tagType} from "common/types/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {toPromise} from "common/observable";

export type CreateFolderModalProps = {
  onCreate: (name: string, tags: Tag[]) => void;
  onCancel: () => void;
};

const tagsType = new ListType(tagType);
export function CreateFolderModal({onCancel, onCreate}: CreateFolderModalProps) {
  const name = useTempRef(stringType, "");
  const tags = useTempRef<Tag[], ListOperation<Tag, ConstantOperation>>(tagsType, []);

  return (<Modal onClose={onCancel}>
      <ModalTitle>Create Folder</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>

      <TagListInput value={tags} />
    </ModalBody>
    <Button className="flex-1" variant="primary" size="medium" type="submit" onClick={async () => {
      const n = await toPromise(name.observe);
      const t = await toPromise(tags.observe);
      onCreate(n, t);
    }}>Create Folder</Button>
  </Modal>);
}