import {MouseEvent, useCallback} from "react";

type MouseEventHandler = (ev: MouseEvent) => boolean;
export function useMouseEventHandlers(...handlers: MouseEventHandler[]): MouseEventHandler {
  return useCallback((ev: MouseEvent) => {
    for (const handler of handlers) {
      if (!handler(ev)) return false;
    }
    return true;
  }, handlers);
}


type WheelEventHandler = (ev: WheelEvent) => boolean;
export function useWheelEventHandlers(...handlers: WheelEventHandler[]): WheelEventHandler {
  return useCallback((ev: WheelEvent) => {
    for (const handler of handlers) {
      if (!handler(ev)) return false;
    }
    return true;
  }, handlers);
}
