import {GameID} from "../game/game-i-d.ts";
import {PrivateKey, PublicKey} from "../../crypto/index.ts";
import {ConstantOperation, ConstantType, ObjectType, SetOperation, SetType, Type, ValueOperation, ValueType} from "../../types/index.ts";
import {z} from "zod";

export const User = z.object({
  gameIds: z.array(GameID),
  publicKey: PublicKey,
  privateKey: PrivateKey
});
export type User = z.infer<typeof User>;

export type UserOperation =
  | {type: "update-game-ids", operations: SetOperation<GameID>[]}
  | {type: "update-public-key", operations: ValueOperation<PublicKey, ConstantOperation>[]}
  | {type: "update-private-key", operations: ValueOperation<PrivateKey, ConstantOperation>[]}
  ;

export const userType: Type<User, UserOperation> = new ObjectType({
  gameIds: new SetType<GameID>(),
  publicKey: new ValueType(new ConstantType<PublicKey>()),
  privateKey: new ValueType(new ConstantType<PrivateKey>())
});

declare module "../../qlab/store/store.ts" {
  export interface StoreTypes {
    "user": Type<User, UserOperation>;
  }
}
