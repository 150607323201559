import React, {useState} from "react";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, IconButton, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Dnd5eCharacter, generateSheetID, Sheet, SheetID, SheetType} from "common/legends/index.ts";
import {FaGhost, FaUser} from "react-icons/fa";
import {Dnd5eStatBlock} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";

export type CreateSheetModalProps = {
  onClose: () => void;
  onCreate: (value: SheetID, sheet: Sheet) => void;
};
export function CreateSheetModal({onClose, onCreate}: CreateSheetModalProps) {
  const [type, setType] = useState<SheetType>("dnd-5e-character");
  const [name, setName] = useState<string>("");
  return (<Modal onClose={onClose}>
    <ModalTitle>Create Sheet</ModalTitle>
    <ModalBody>
      <ButtonBar className="justify-around">
        <label className="flex flex-col items-center text-sm">
          <IconButton size="large" variant={type === "dnd-5e-character" ? "primary" : undefined} onClick={() => setType("dnd-5e-character")}><FaUser /></IconButton>
          <span>5e Character</span>
        </label>
        <label className="flex flex-col items-center text-sm">
          <IconButton size="large" variant={type === "dnd-5e-stat-block" ? "primary" : undefined} onClick={() => setType("dnd-5e-stat-block")}><FaGhost /></IconButton>
          <span>5e Stat Block</span>
        </label>
      </ButtonBar>

      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <Input value={name} onChange={ev => setName(ev.target.value)} />
      </InputGroup>
    </ModalBody>
    <ButtonBar>
      <Button type="submit" variant="primary" className="flex-1" onClick={async () => {
        const sheetID = generateSheetID();
        if (type === "dnd-5e-character") {
          onCreate(sheetID, {
            type: "dnd-5e-character",
            data: {
              name: name,
              race: {
                name: "",
                features: []
              },
              background: {
                name: "",
                features: []
              },
              movementSpeeds: {walk: 30},
              exhaustionLevel: 0,
              hitPoints: {current: 4, temp: 0},
              deathSaves: {successes: 0, failures: 0},
              experience: {current: 0, max: 0},
              classes: [],
              alignment: "unaligned",
              attributes: {str: 10, dex: 10, con: 10, int: 10, wis: 10, cha: 10},
              weaponProficiencies: {},
              armorProficiencies: {},
              languageProficiencies: {},
              inventory: [],
              spells: [],
              features: [],
              globalFeatures: {},
              conditions: [],
              pactSlots: 0,
              spellSlots: {
                "cantrip": 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0
              }
            } satisfies Dnd5eCharacter
          });
        } else {
          onCreate(sheetID, {
            type: "dnd-5e-stat-block",
            data: {
              name,
              proficiencyBonus: 2,
              hitPoints: {current: 50, temp: 0},
              attributes: {str: 10, dex: 10, con: 10, int: 10, wis: 10, cha: 10},
              conditions: [],
              languages: [],
              features: [],
              movementSpeeds: {walk: 30},
              spells: [],
              spellCasterLevel: 0,
              spellSlots: {"cantrip": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0},
              globalFeatures: {},
              hitDice: {current: 10, diceFace: 8}
            } satisfies Dnd5eStatBlock
          });
        }
      }}>Create Sheet</Button>
    </ButtonBar>
  </Modal>);
}