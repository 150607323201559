import {ListOperation} from "common/types/index.ts";
import {useCallback, useState} from "react";
import {Dice} from "common/dice/index.ts";
import {Button, ButtonBar} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {
  Dnd5eActionModifier,
  Dnd5eActionModifierOperation,
  Dnd5eActionModifierTypes,
  getActionModifierID
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Menu} from "@headlessui/react";
import {createPortal} from "react-dom";
import {usePopper} from "react-popper";
import {Dnd5eAttackRollModifierView} from "../dnd-5e-modifier/dnd-5e-attack-roll-modifier-view.tsx";
import {Dnd5eDCModifierView} from "../dnd-5e-modifier/dnd-5e-d-c-modifiers-view.tsx";
import {Dnd5eDamageRollModifierView} from "../dnd-5e-modifier/dnd-5e-damage-roll-modifier-view.tsx";
import {Dnd5eSpellSlotConsumptionModifierView} from "../dnd-5e-modifier/dnd-5e-spell-slot-consumption-modifier-view.tsx";
import {Dnd5eResourceConsumptionModifierView} from "../dnd-5e-modifier/dnd-5e-resource-consumption-modifier-view.tsx";
import {Dnd5eItemConsumptionModifierView} from "../dnd-5e-modifier/dnd-5e-item-consumption-modifier-view.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {MathExpressionFn} from "common/math/index.ts";
import {Dnd5eVariableModifierView} from "../dnd-5e-modifier/dnd-5e-variable-modifier-view.tsx";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {copyDnd5eActionModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action-modifier.ts";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef} from "common/ref";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";


export type Dnd5eActionModifierViewProps = InputListItemProps<Dnd5eActionModifier, Dnd5eActionModifierOperation>;
export function Dnd5eActionModifierView({item, remove}: Dnd5eActionModifierViewProps) {
  const [type, typeSignal] = useTypedRef<Dnd5eActionModifierTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem<Dnd5eActionModifier>("legends/action-modifier", item, remove);
  if (type === "attack-roll") {
    return <Dnd5eAttackRollModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "difficulty-class") {
    return <Dnd5eDCModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "damage-roll") {
    return <Dnd5eDamageRollModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "spell-slot-consumption") {
    return <Dnd5eSpellSlotConsumptionModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "resource-consumption") {
    return <Dnd5eResourceConsumptionModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "item-consumption") {
    return <Dnd5eItemConsumptionModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "variable-override") {
    return <Dnd5eVariableModifierView value={typeSignal} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  }
  return <></>;
}


export type Dnd5eActionModifiersViewProps = {
  value: MutableRef<Dnd5eActionModifier[], ListOperation<Dnd5eActionModifier, Dnd5eActionModifierOperation>[]>;
  readOnly?: boolean;
};
export function Dnd5eActionModifiersView({value, readOnly}: Dnd5eActionModifiersViewProps) {
  const addDamageRollModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "damage-roll",
    data: {
      modifierID: generateDnd5eModifierID(),
      damageType: "bludgeoning (non-magical)",
      hitExpression: Dice.assertDiceExpression("1d6"),
      critExpression: Dice.assertDiceExpression("2d6")
    }
  })), [value.apply]);
  const addAttackRollModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "attack-roll",
    data: {
      modifierID: generateDnd5eModifierID(),
      expression: Dice.assertDiceExpression("1")
    }
  })), [value.apply]);
  const addDCModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "difficulty-class",
    data: {
      modifierID: generateDnd5eModifierID(),
      expression: Dice.assertDiceExpression("1")
    }
  })), [value.apply]);
  const addSpellSlotConsumptionModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "spell-slot-consumption",
    data: {
      modifierID: generateDnd5eModifierID(),
      level: "1"
    }
  })), [value.apply]);
  const addResourceConsumptionModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "resource-consumption",
    data: {
      modifierID: generateDnd5eModifierID(),
      resourceID: undefined,
      qty: 1
    }
  })), [value.apply]);
  const addItemConsumptionModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "item-consumption",
    data: {
      modifierID: generateDnd5eModifierID(),
      itemID: undefined,
      qty: 1
    }
  })), [value.apply]);
  const addVariableOverrideModifier = useCallback(() => value.apply(prev => ListOperation.insert(prev.length, {
    type: "variable-override",
    data: {
      modifierID: generateDnd5eModifierID(),
      name: "Variable",
      expression: MathExpressionFn.assertMathExpression("0")
    }
  })), [value.apply]);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: "top-end"
  });

  return <div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Modifiers</SectionHeader>
      <ButtonBar>
        {!readOnly && <Menu as="div" className="relative flex">
            <Menu.Button as={Button} ref={ref => setReferenceElement(ref)}><FaPlus /> New Modifier</Menu.Button>
          {createPortal(<Menu.Items ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="flex flex-col m-1 rounded-md text-white overflow-hidden">
            <Menu.Item as={Button} className="justify-start" onClick={addDamageRollModifier}>Damage</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addAttackRollModifier}>Attack</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addDCModifier}>Difficulty Class</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addSpellSlotConsumptionModifier}>Consume Spell Slot Level</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addResourceConsumptionModifier}>Consume Resource</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addItemConsumptionModifier}>Consume Item</Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addVariableOverrideModifier}>Variable</Menu.Item>
          </Menu.Items>, usePortal())}
        </Menu>}
      </ButtonBar>
    </div>

    <InputList<Dnd5eActionModifier, Dnd5eActionModifierOperation>
      accept="legends/action-modifier"
      items={value}
      itemKey={getActionModifierID}
      copy={copyDnd5eActionModifier}
      ListItem={Dnd5eActionModifierView} />
  </div>;
}
