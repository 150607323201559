import {Size, SizeOperation, SizeSignals} from "common/types/index.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {MutableRef} from "common/ref";

export type SizeInputProps = {
  value: MutableRef<Size, SizeOperation[]>;
  className?: string
};

export function SizeField({value, className}: SizeInputProps) {
  const {width, height} = useMemo(() => SizeSignals(value), [value]);

  return (<div className={twMerge("shrink-0 flex flex-row", className)}>
    <InputGroup>
      <InputGroupLabel>Size</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>W</InputGroupLabel>
      <InputNumber value={width} />
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>H</InputGroupLabel>
      <InputNumber size="small" value={height} />
    </InputGroup>
  </div>)
}