import {Dnd5eActionModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export function copyDnd5eActionModifier(value: Dnd5eActionModifier): Dnd5eActionModifier {
  return ({
    ...value,
    data: {
      ...value.data,
      modifierID: generateDnd5eModifierID()
    }
  }) as Dnd5eActionModifier;
}
