import {z} from "zod";

export const EncryptPublicKey = z.string().brand("EncryptPublicKey");
export type EncryptPublicKey = z.infer<typeof EncryptPublicKey>;

export const VerifyPublicKey = z.string().brand("VerifyPublicKey");
export type VerifyPublicKey = z.infer<typeof VerifyPublicKey>;

export const PublicKey = z.tuple([
  EncryptPublicKey,
  VerifyPublicKey
]);
export type PublicKey = z.infer<typeof PublicKey>;
