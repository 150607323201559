import {z} from "zod";
import {DiceExpression} from "#common/dice/index.ts";
import {Dnd5eAttribute, DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-attribute.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ObjectType,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eSavingThrowProficiency} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-saving-throw-proficiency.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eSavingThrowType = z.union([
  Dnd5eAttribute,
  z.literal("concentration"),
  z.literal("death")
]);
export type Dnd5eSavingThrowType = z.infer<typeof Dnd5eSavingThrowType>;
export const DND_5E_SAVING_THROW_TYPES = [
  ...DND_5E_ATTRIBUTES,
  "concentration",
  "death"
] as const satisfies readonly Dnd5eSavingThrowType[];

export const Dnd5eSavingThrowModifier = z.object({
  modifierID: Dnd5eModifierID,
  savingThrows: z.array(Dnd5eSavingThrowType),
  expression: DiceExpression,
  proficiency: Dnd5eSavingThrowProficiency,
  hasAdvantage: z.boolean(),
  hasDisadvantage: z.boolean()
});
export type Dnd5eSavingThrowModifier = z.infer<typeof Dnd5eSavingThrowModifier>;

export const Dnd5eSavingThrowModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-saving-throws"), operations: z.array(SetOperation(Dnd5eSavingThrowType))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-proficiency"), operations: z.array(ValueOperation(Dnd5eSavingThrowProficiency, ConstantOperation))}),
  z.object({type: z.literal("update-has-advantage"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-has-disadvantage"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eSavingThrowModifierOperation = z.infer<typeof Dnd5eSavingThrowModifierOperation>;

export const dnd5eSavingThrowModifierType: Type<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation> = new ObjectType({
  modifierID: constantType,
  savingThrows: new SetType<Dnd5eSavingThrowType>(),
  expression: new ValueType(constantType),
  proficiency: new ValueType(constantType),
  hasAdvantage: booleanType,
  hasDisadvantage: booleanType
}, (value: any) => {
  if (value.hasAdvantage === undefined) value.hasAdvantage = false;
  if (value.hasDisadvantage === undefined) value.hasAdvantage = false;

  return value;
});

export function Dnd5eSavingThrowModifierSignals(value: MutableRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation[]>) {
  return ({
    savingThrows: SetPropertySignal<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation, Dnd5eSavingThrowType>(
      value => value.savingThrows,
      operations => [{type: "update-saving-throws", operations}]
    )(value),
    expression: PropertyRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value),
    proficiency: ValuePropertyRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation, Dnd5eSavingThrowProficiency, ConstantOperation>(
      value => value.proficiency,
      operations => [{type: "update-proficiency", operations}]
    )(value),
    hasAdvantage: PropertyRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation, boolean, BooleanOperation>(
      value => value.hasAdvantage,
      operations => [{type: "update-has-advantage", operations}]
    )(value),
    hasDisadvantage: PropertyRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation, boolean, BooleanOperation>(
      value => value.hasDisadvantage,
      operations => [{type: "update-has-disadvantage", operations}]
    )(value),
  });
}

export const DND_5E_SAVING_THROW_TITLE: {[type in Dnd5eSavingThrowType]: string} = {
  ...DND_5E_ATTRIBUTE_TITLE,
  "concentration": "Concentration",
  "death": "Death"
};
