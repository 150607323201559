import {z} from "zod";
import {Dnd5eDamageType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {DiceExpression} from "#common/dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, PropertyRef, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eDamageRollModifier = z.object({
  modifierID: Dnd5eModifierID,
  damageType: Dnd5eDamageType.optional(),
  hitExpression: DiceExpression,
  critExpression: DiceExpression
});
export type Dnd5eDamageRollModifier = z.infer<typeof Dnd5eDamageRollModifier>;
export const Dnd5eDamageRollModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-damage-type"), operations: z.array(ValueOperation(z.optional(Dnd5eDamageType), ConstantOperation))}),
  z.object({type: z.literal("update-hit-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-crit-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
]);
export type Dnd5eDamageRollModifierOperation = z.infer<typeof Dnd5eDamageRollModifierOperation>;

export const dnd5eDamageRollModifierType: Type<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation> = new ObjectType({
  modifierID: constantType,
  damageType: new ValueType(constantType),
  hitExpression: new ValueType(constantType),
  critExpression: new ValueType(constantType)
});

export function Dnd5eDamageRollModifierEntity(value: MutableRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation[]>) {
  return ({
    damageType: PropertyRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation, Optional<Dnd5eDamageType>, ValueOperation<Optional<Dnd5eDamageType>, ConstantOperation>>(
      value => value.damageType,
      operations => [{type: "update-damage-type", operations}]
    )(value),
    hitExpression: PropertyRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.hitExpression,
      operations => [{type: "update-hit-expression", operations}]
    )(value),
    critExpression: PropertyRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.critExpression,
      operations => [{type: "update-crit-expression", operations}]
    )(value)
  });
}
