import {CamelCaseToKebabCase} from "../../utils/index.ts";
import {ExtractOperation, ExtractValue, MultiType, Optional, OptionalOperation, OptionalType, Type, ValueOperation, ValueType} from "../../types/index.ts";
import {TextMessage, TextMessageOperation, textMessageType} from "./text/index.ts";
import {
  Dnd5eAbilityCheckMessage,
  Dnd5eAbilityCheckMessageOperation,
  dnd5eAbilityCheckMessageType,
  Dnd5eAttackMessage,
  Dnd5eAttackMessageOperation,
  dnd5eAttackMessageType,
  Dnd5eSavingThrowMessage,
  Dnd5eSavingThrowMessageOperation,
  dnd5eSavingThrowMessageType,
  Dnd5eSavingThrowRequestMessage,
  Dnd5eSavingThrowRequestMessageOperation,
  dnd5eSavingThrowRequestMessageType
} from "#common/qlab/message/dnd-5e/index.ts";
import {Dnd5eFeatureMessage, Dnd5eFeatureMessageOperation, dnd5eFeatureMessageType} from "#common/qlab/message/dnd-5e/dnd-5e-feature-message.ts";
import {InitiativeRequestMessage, InitiativeRequestMessageOperation, initiativeRequestMessageType} from "./initiative/initiative-request-message.ts";
import {Dnd5eActionMessage, Dnd5eActionMessageOperation, dnd5eActionMessageType} from "./dnd-5e/dnd-5e-action-message.ts";

export interface MessageTypes {
  text: Type<TextMessage, TextMessageOperation>;
  initiativeRequestMessage: Type<InitiativeRequestMessage, InitiativeRequestMessageOperation>;
  dnd5eActionMessage: Type<Dnd5eActionMessage, Dnd5eActionMessageOperation>;
  dnd5eSavingThrowRequestMessage: Type<Dnd5eSavingThrowRequestMessage, Dnd5eSavingThrowRequestMessageOperation>;
  dnd5eAttackMessage: Type<Dnd5eAttackMessage, Dnd5eAttackMessageOperation>;
  dnd5eAbilityCheckMessage: Type<Dnd5eAbilityCheckMessage, Dnd5eAbilityCheckMessageOperation>;
  dnd5eSavingThrowMessage: Type<Dnd5eSavingThrowMessage, Dnd5eSavingThrowMessageOperation>;
  dnd5eFeatureMessage: Type<Dnd5eFeatureMessage, Dnd5eFeatureMessageOperation>;
}
export type MessageType = keyof MessageTypes;
export type MessageValue = {
  [Type in MessageType]: {
    type: CamelCaseToKebabCase<Type>;
    data: ExtractValue<MessageTypes[Type]>;
  };
}[MessageType];
export type MessageOperation = {
  [Type in MessageType]: {
    type: CamelCaseToKebabCase<Type>;
    operations: ExtractOperation<MessageTypes[Type]>[];
  };
}[MessageType];

export const messageType: Type<Optional<MessageValue>, ValueOperation<Optional<MessageValue>, OptionalOperation<MessageOperation>>> = new ValueType(new OptionalType(new MultiType({
  text: textMessageType,
  initiativeRequestMessage: initiativeRequestMessageType,
  dnd5eActionMessage: dnd5eActionMessageType,
  dnd5eSavingThrowRequestMessage: dnd5eSavingThrowRequestMessageType,
  dnd5eAttackMessage: dnd5eAttackMessageType,
  dnd5eAbilityCheckMessage: dnd5eAbilityCheckMessageType,
  dnd5eSavingThrowMessage: dnd5eSavingThrowMessageType,
  dnd5eFeatureMessage: dnd5eFeatureMessageType
})));
