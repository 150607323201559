import {VisitResult, walkTree} from "common/types/index.ts";
import {Node, NodeId, SceneID, UserID} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {QLabDatabase, QLabResourceID} from "common/qlab/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {MutableRef, Ref} from "common/ref";
import {useMemo} from "react";

function tokensByUserID(databaseRef: Ref<QLabDatabase>, userID: UserID): Ref<{[nodeID: NodeId]: SceneID}> {
  const valueFn = (database: QLabDatabase): {[nodeID: NodeId]: SceneID} => {
    const tokens: {[nodeID: NodeId]: SceneID} = {};
    for (const resourceID of Object.keys(database.resources) as QLabResourceID[]) {
      const resource = database.resources[resourceID];
      if (resource?.type !== "scene") continue;
      walkTree(resource.data.children, {visit(value: Node): VisitResult | void {
        if (value.type === "token" && (value.data.ownerIDs.includes(userID) || value.data.ownerIDs.includes("GLOBAL" as UserID))) {
          tokens[value.data.id] = resourceID as SceneID;
        }
      }});
    }
    return tokens
  };

  return new MutableRef({
    value(): {[nodeID: NodeId]: SceneID} {
      return valueFn(databaseRef.value)
    },
    observe: pipe(databaseRef.observe, map(valueFn), distinct((a,b) => {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) return false;
      for (const aKey of aKeys) {if (!bKeys.includes(aKey)) return false;}
      for (const bKey of bKeys) {if (!aKeys.includes(bKey)) return false;}
      for (const aKey of aKeys) {if (a[aKey] !== b[aKey]) return false;}
      return true;
    })),
    apply: () => {throw new Error("Unsupported.")}
  });
}

export function useCharactersNodeSceneIDs(ownerID: UserID): Ref<{[nodeId: NodeId]: SceneID}> {
  const databaseRef = useDatabase();
  return useMemo(() => tokensByUserID(databaseRef, ownerID), [databaseRef, ownerID]);
}
