import {AssetID, GameID, NodeId, SceneID, TokenID} from "common/legends/index.ts";

export type SceneSelectionRef = {
  type: "scene";
  storeId: GameID;
  resourceId: SceneID;
};

export type SceneNodeSelectionRef = {
  type: "scene-node";
  storeId: GameID;
  resourceId: SceneID;
  nodeId: NodeId;
};

export type AssetSelectionRef = {
  type: "asset";
  storeId: GameID;
  resourceId: AssetID;
};

export type AssetTokenSelectionRef = {
  type: "asset-token";
  storeId: GameID;
  resourceId: AssetID;
  tokenId: TokenID;
};

export type AssetTokenNodeSelectionRef = {
  type: "asset-token-node";
  storeId: GameID;
  resourceId: AssetID;
  tokenId: TokenID;
  nodeId: NodeId;
};

export type SelectionRef =
  | SceneSelectionRef
  | SceneNodeSelectionRef
  | AssetSelectionRef
  | AssetTokenSelectionRef
  | AssetTokenNodeSelectionRef
  | undefined
  ;

export type NodeSelectionRef =
  | SceneNodeSelectionRef
  | AssetTokenNodeSelectionRef
  ;

export const SelectionRef = {
  equals: (a: SelectionRef | undefined, b: SelectionRef | undefined) => {
    if (a === undefined && b === undefined) return true;
    if (a === undefined || b === undefined) return false;
    if (a.type === "asset" && b.type === "asset") {
      return (
        a.storeId === b.storeId &&
        a.resourceId === b.resourceId
      );
    } else if (a.type === "asset-token" && b.type === "asset-token") {
      return (
        a.storeId === b.storeId &&
        a.resourceId === b.resourceId &&
        a.tokenId === b.tokenId
      );
    } else if (a.type === "asset-token-node" && b.type === "asset-token-node") {
      return (
        a.storeId === b.storeId &&
        a.resourceId === b.resourceId &&
        a.tokenId === b.tokenId &&
        a.nodeId === b.nodeId
      );
    } else if (a.type === "scene" && b.type === "scene") {
      return (
        a.storeId === b.storeId &&
        a.resourceId === b.resourceId
      );
    } else if (a.type === "scene-node" && b.type === "scene-node") {
      return (
        a.storeId === b.storeId &&
        a.resourceId === b.resourceId &&
        a.nodeId === b.nodeId
      );
    } else {
      return false;
    }
  }
};


