import {Grammar, Parser} from "nearley";
import textMessageGrammar from "./text-message-grammar.ts";
import {Dice, DiceExpression} from "../../../dice/index.ts";
import {TextMessageExpression} from "./text-message-expression.ts";
import {TextMessageContent, TextMessageToken} from "./text-message-content.ts";
import {ulid} from "ulid";
import {RollRequestID} from "#common/qlab/index.ts";

type TextMessageOperation =
  | {op: "str", value: string}
  | {op: "dice", value: string}
  ;
const compiledGrammar = Grammar.fromCompiled(textMessageGrammar);

const parseTextMessageExpression = (textMessageExpression: string): TextMessageOperation[] => {
  try {
    const results = new Parser(compiledGrammar)
      .feed(textMessageExpression)
      .finish();
    if (results.length >= 1) return results[0];
  } catch (e) {
    throw e;
  }
  throw new Error("Cannot parse text message expression: " + textMessageExpression);
}

export const TextMessageParser = {
  parse(textMessage: TextMessageExpression): {content: TextMessageContent, rollRequests: {[rollId: string]: DiceExpression} } {
    const tokens = parseTextMessageExpression(textMessage);
    let content: TextMessageToken[] = [];
    let rollRequests: {[rollId: RollRequestID]: DiceExpression} = {};
    for (const token of tokens) {
      if (token.op === "str") {
        content.push({type: "string", value: token.value});
      } else if (token.op === "dice") {
        const rollID = ulid() as RollRequestID;
        content.push({type: "roll", value: rollID});
        rollRequests[rollID] = token.value as DiceExpression;
      }
    }
    return {
      content,
      rollRequests
    };
  },
  isValid(message: string): message is TextMessageExpression {
    try {
      const messageExpression = parseTextMessageExpression(message);
      return messageExpression
        .every(token => {
          if (token.op === "str") return true;
          return token.op === "dice" && Dice.isDiceExpression(token.value)
        });
    } catch (e) {
      return false;
    }
  }
};

