import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ListOperation, ListSignal} from "common/types/index.ts";
import React, {useState} from "react";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {twMerge} from "tailwind-merge";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {
  copyInteractionTrigger,
  getInteractionTriggerID,
  InteractionTrigger,
  InteractionTriggerOperation,
  InteractionTriggerTypes
} from "common/legends/node/interaction/trigger/interaction-trigger.ts";
import {InputInteractionTriggerClick} from "./input-interaction-trigger-click.tsx";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";
import {generateInteractionTriggerID} from "common/legends/node/interaction/trigger/interaction-trigger-i-d.ts";
import {InputInteractionTriggerEnter} from "./input-interaction-trigger-enter.tsx";
import {InputInteractionTriggerExit} from "./input-interaction-trigger-exit.tsx";
import {Menu} from "@headlessui/react";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {usePopper} from "react-popper";

export type InputInteractionTriggerProps = InputListItemProps<InteractionTrigger, InteractionTriggerOperation>;
export function InputInteractionTrigger({item, remove}: InputInteractionTriggerProps) {
  const [type, typeSignal] = useTypedRef<InteractionTriggerTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/interaction-trigger", item, remove);
  return (<div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    {type === "click" && <InputInteractionTriggerClick dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "enter" && <InputInteractionTriggerEnter dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
    {type === "exit" && <InputInteractionTriggerExit dragHandlerRef={dragHandlerRef} value={typeSignal} remove={remove} />}
  </div>);
}

export function InputInteractionTriggers({value}: {
  value: ListSignal<InteractionTrigger, InteractionTriggerOperation>;
}) {
  const onAddClickInteractionTrigger = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "click", data: {interactionTriggerID: generateInteractionTriggerID()}} satisfies InteractionTrigger
    ));
  };
  const onAddEnterInteractionTrigger = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "enter", data: {interactionTriggerID: generateInteractionTriggerID()}} satisfies InteractionTrigger
    ));
  };
  const onAddExitInteractionTrigger = () => {
    value.apply(prev => ListOperation.insert(
      prev.length,
      {type: "exit", data: {interactionTriggerID: generateInteractionTriggerID()}} satisfies InteractionTrigger
    ));
  };

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });
  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Triggers</SectionHeader>
      <Menu ref={ref => setReferenceElement(ref)} as="div">
        <Menu.Button as={Button}>
          <FaPlus /> New Trigger
        </Menu.Button>
        <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
          <Menu.Item as={Button} className="justify-start" onClick={onAddClickInteractionTrigger}>
            <span>Click</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddEnterInteractionTrigger}>
            <span>Enter</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" onClick={onAddExitInteractionTrigger}>
            <span>Exit</span>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>

    <InputList<InteractionTrigger, InteractionTriggerOperation>
      accept="legends/interaction-trigger"
      items={value}
      itemKey={getInteractionTriggerID}
      copy={copyInteractionTrigger}
      ListItem={InputInteractionTrigger} />
  </div>);
}
