import {UserID} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {FileReference} from "common/types/index.ts";
import {useGame} from "./model/index.ts";

export function usePlayerIcon(userID: UserID): FileReference | undefined {
  const game = useGame();
  return useObservable(pipe(
    game.observe,
    map(game => {
      if (game.players[userID]) return game.players[userID].icon;
      if (game.owner.id === userID) return game.owner.icon;
      return undefined;
    }),
    distinct()
  ), undefined, [game, userID])
}