import {useInstance} from "#lib/qlab/index.ts";
import {generateMessageID, RollRequestID, RollRequests, TextMessageParser} from "common/qlab/index.ts";
import {encryptValue} from "common/types/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import type {RollVariables} from "common/qlab/message/roll-request/index.ts";
import {useCallback} from "react";
import {toPromise} from "common/observable";
import {useStoreID} from "./use-store-id.ts";
import {usePlayerIcon} from "./use-player-icon.ts";
import {useGetNodeIcon} from "../../legends/common/use-get-node-icon.ts";
import {useSelectedNodeID} from "../../legends/panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useSeedTime} from "./use-seed-time.ts";

export function useSendTextMessage(): (message: string, variables?: RollVariables) => Promise<void> {
  const instance = useInstance();
  const storeID = useStoreID();
  const userID = useUserID()!;
  const playerIcon = usePlayerIcon(userID);

  const getNodeIcon = useGetNodeIcon();
  const selectedNodeID = useSelectedNodeID();

  const time = useSeedTime();
  return useCallback(async (message: string, variables?: RollVariables) => {
    const nodeID = await toPromise(selectedNodeID);
    const nodeIcon = await getNodeIcon(nodeID);

    const messageID = generateMessageID(time());
    if (!TextMessageParser.isValid(message)) throw new Error(`Invalid Message: ${message}`);
    const {content, rollRequests} = TextMessageParser.parse(message);
    const encryptedContent = await encryptValue({}, () => content);
    const encryptedRollRequests = await encryptValue({}, () => Object.entries(rollRequests).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
      return rollRequests;
    }, {} as RollRequests));
    return instance.applyToMessage(storeID, messageID, _ => [{
      type: "set",
      prevValue: undefined,
      nextValue: {
        type: "text",
        data: {
          userID,
          nodeID,
          icon: nodeIcon || playerIcon,
          content: encryptedContent,
          rollRequests: encryptedRollRequests,
          rollResults: undefined
        }
      }
    }]);
  }, [instance, storeID, userID, playerIcon]);
}
