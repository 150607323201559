import {z} from "zod";

export const Dnd5eArmor = z.union([
  z.literal("light"),
  z.literal("medium"),
  z.literal("heavy"),
  z.literal("shield")
])
export const DND_5E_ARMOR_TYPES = [
  "light",
  "medium",
  "heavy",
  "shield"
] as const;
export type Dnd5eArmor = typeof DND_5E_ARMOR_TYPES[number];
