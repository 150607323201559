import {Input, InputGroup, InputGroupLabel} from "../input/index.ts";
import {FaPlus} from "react-icons/fa";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {ConstantOperation, HSLA, ListEntity, Tag, TagId} from "common/types/index.ts";
import {TagListViewer} from "./tag-list-viewer.tsx";
import {TAG_COLORS, TagListInputColorPicker} from "./tag-list-input-color-picker.tsx";
import "./tag-list-input.css";
import {useObservable} from "#lib/qlab/index.ts";
import {IconButton} from "../button/index.ts";

export type TagListInputProps = {
  value: ListEntity<Tag, ConstantOperation>;
};

export function TagListInput({value}: TagListInputProps) {
  const onClose = useCallback((index: number) => {
    value.apply(prev => [{type: "delete", index, item: prev[index]}]);
  }, [value.apply]);

  const [newTagValue, setNewTagValue] = useState("");
  const onInputChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setNewTagValue(ev.target.value);
  }, [setNewTagValue]);
  const [tagColor, setTagColor] = useState<HSLA>(TAG_COLORS[0]);
  const [enableColorPicker, setEnableColorPicker] = useState(false);
  const onAddTag = useCallback(() => {
    if (newTagValue !== "") {
      value.apply(prevTags =>[{
        type: "insert",
        index: prevTags.length,
        item: {
          id: TagId.generate(),
          label: newTagValue,
          color: tagColor
        }
      }]);
      setNewTagValue("");
    }
  }, [newTagValue, tagColor, setNewTagValue, value.apply]);

  // Remove color picker if they click anywhere else
  useEffect(() => {
    const handler = () => {
      if (enableColorPicker) {
        setEnableColorPicker(false);
      }
    };
    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, [enableColorPicker, setEnableColorPicker]);

  const handleTagColor = useCallback((value: HSLA) => {
    setTagColor(value);
    setEnableColorPicker(false);
  }, [setTagColor, setEnableColorPicker]);
  const toggleColorPicker = useCallback(() => setEnableColorPicker(prevValue => !prevValue), [setEnableColorPicker]);

  const list = useObservable(value.observe, [], [value.observe]);

  return <>
    <TagListViewer value={list} onClose={onClose} />

    <div className="flex relative">
      <div className="relative flex-grow-0 flex-shrink-0 basis-[32px]">
        {enableColorPicker && <TagListInputColorPicker onSelect={handleTagColor} />}
        <IconButton onClick={toggleColorPicker} style={{
          backgroundColor: `hsl(${tagColor[0]*360}, ${tagColor[1] * 100}%, ${tagColor[2] * 100}%)`
        }}>&nbsp;</IconButton>
      </div>
      <InputGroup className="tag-label">
        <InputGroupLabel>Tags</InputGroupLabel>
        <Input placeholder="Tag" value={newTagValue} onChange={onInputChange} onKeyPress={ev => {
          if (ev.key === "Enter" && newTagValue !== "") {
            ev.preventDefault();
            onAddTag();
          }
        }} />
      </InputGroup>
      <IconButton variant="tertiary" onClick={onAddTag}><FaPlus /></IconButton>
    </div>
  </>;
}