import {QLabStoreID, StoreType, StoreTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {useMemo} from "react";
import {storeObservable} from "./store-observable.ts";
import {pipe} from "common/pipe";
import {Loader} from "common/loader";
import {useLoader} from "../../../../../legends/common/use-loader.ts";
import {useInstance} from "../../q-lab-instance-context.ts";

export function useStore<
  Type extends StoreType,
  Value = ExtractValue<StoreTypes[Type]>
>(type: Type, storeId: QLabStoreID): Loader<Value> {
  return useLoader(useStoreObservable(type, storeId));
}

export function useStoreObservable<
  Type extends StoreType,
  Value = ExtractValue<StoreTypes[Type]>
>(type: Type, storeId: QLabStoreID) {
  const instance = useInstance();
  return useMemo(() => pipe(
    instance.observe(storeId),
    storeObservable<Type, Value>(type)
  ), [instance, type, storeId]);
}
