import {Type} from "../../type/type.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export type Optional<T> =
  | undefined
  | T
  ;
export type OptionalOperation<Operation> = Operation;

export class OptionalType<Value, Operation> implements Type<Optional<Value>, OptionalOperation<Operation>> {
  constructor(private readonly itemType: Type<Value, Operation>) {
  }
  apply = (value: Optional<Value>, operation: OptionalOperation<Operation>): Optional<Value> => {
    if (value === undefined) throw new Error("Cannot apply operations on uninitialized store.");
    return this.itemType.apply(value, operation);
  }
  invert = (operation: OptionalOperation<Operation>): OptionalOperation<Operation>[] => {
    return this.itemType.invert(operation);
  }
  transform = (leftOperation: OptionalOperation<Operation>, topOperation: OptionalOperation<Operation>, tieBreaker: boolean): OptionalOperation<Operation>[] => {
    return this.itemType.transform(leftOperation, topOperation, tieBreaker);
  }
  migrateValue = (value: any): Optional<Value> => {
    return value === undefined ? undefined : this.itemType.migrateValue(value);
  }
  migrateOperation = (operation: any): OptionalOperation<Operation>[] => {
    return this.itemType.migrateOperation(operation);
  }
  validate = (value: any): ValidationError[] => {
    if (value === undefined) return [];
    return this.itemType.validate(value);
  }
}
