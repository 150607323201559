import {Dnd5eItemEffect} from "#common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect.ts";
import {copyDnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/copy-dnd-5e-modifier.ts";
import {
  generateDnd5eItemEffectID
} from "#common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect-id.ts";

export function copyDnd5eItemEffect(value: Dnd5eItemEffect): Dnd5eItemEffect {
  return ({
    ...value,
    enabled: false,
    effectID: generateDnd5eItemEffectID(),
    modifiers: value.modifiers.map(copyDnd5eModifier)
  })
}