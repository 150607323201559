import {Optional, Tree, TreeId, TreeOperation} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export function getTreeItemSignal<Item extends Tree<Item>, ItemOperation>(signal: MutableRef<Item[], TreeOperation<Item, ItemOperation>[]>, id: TreeId): MutableRef<Optional<Item>, ItemOperation[]> {
  return signal
    .map<Optional<Item>, ItemOperation[]>(
      (value) => Tree.getItemById(value, id),
      (prev, operations) => {
        const path = Tree.getPath(prev, item => item.data.id === id);
        if (path === undefined) return [];
        return [{type: "apply", path, operations: operations}]
      }
    );
}