import {Suspense, useCallback, useMemo, useState} from "react";
import {
  AssetTreeId,
  GameSignals,
  Scene,
  SceneID,
  SceneTree,
  SceneTreeFile,
  SceneTreeFileOperation,
  SceneTreeFolder,
  SceneTreeFolderOperation,
  SceneTreeId,
  SceneTreeItem
} from "common/legends/index.ts";
import {ConstantOperation, MapFn, MapOperation, Tag, Tree, ValueFn, walkTree} from "common/types/index.ts";
import {Breadcrumb, BreadcrumbItem, Button, ButtonBar, IconButton, Spacer, useToggle} from "#lib/components/index.ts";
import {FaEdit, FaFileExport, FaFolderPlus, FaHome, FaMap, FaPlus, FaTrash} from "react-icons/fa";
import {CreateSceneModal} from "./create-scene-modal.tsx";
import {CreateFolderModal} from "./create-folder-modal.tsx";
import {EditFolderModal} from "./edit-folder-modal.tsx";
import {EditSceneModal} from "./edit-scene-modal.tsx";
import {ApplyAction, useCreateResource, useDeleteResource, useObservable} from "#lib/qlab/index.ts";
import {SceneNavigator, SceneNavigatorOperation} from "./scene-navigator.ts";
import {TreeView} from "#lib/components/tree-view/index.ts";
import {from} from "common/observable";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {useGame} from "../../../routes/game/index.ts";
import {useNavigateToSceneEditor} from "../../viewport/scene/index.ts";
import {useExtractScene} from "../../common/scene/use-export.ts";
import {exportFile} from "../../common/export-file.ts";
import {useImportFile} from "../../common/scene/use-import-file.ts";
import {legendsFileType} from "../../common/scene/legends-file.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {Menu} from "@headlessui/react";
import {getTreeItemSignal} from "common/types/generic/tree/get-tree-item-signal.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {useEditor} from "../../container/editor/editor-context.ts";
import {SceneOutlinerView} from "./scene-outliner-view.tsx";
import {useSceneViewportProperties} from "../../viewport/scene/use-scene-viewport-properties.ts";
import {FaLocationCrosshairs} from "react-icons/fa6";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";

export function SceneSelector({valueRef, navigateToScene}: {
  valueRef: MutableRef<SceneNavigator, SceneNavigatorOperation[]>;
  navigateToScene: (sceneID: SceneID) => void;
}) {
  const value = useRefValue(valueRef);
  const apply = valueRef.apply;

  const game = useGame();
  const {scenesRef} = useMemo(() => GameSignals(game), [game]);
  const editor = useEditor();

  const createResource = useCreateResource();

  const navigateToFile = useCallback((item: SceneTreeItem) => {
    if (item.type === "scene") navigateToScene(item.data.id);
  }, [navigateToScene]);

  const [createSceneModalIsOpen, toggleCreateSceneModalIsOpen, setCreateSceneModalIsOpen] = useToggle(false);
  const handleCreateScene = useCallback(async (file: SceneTreeFile, content: Scene) => {
    await createResource("scene", file.id, content);
    scenesRef.apply(prevValue => [{
      type: "insert",
      path: [prevValue.length],
      item: {type: "scene", data: file}
    }]);
    setCreateSceneModalIsOpen(false);
    navigateToScene(file.id);
  }, [scenesRef, createResource, setCreateSceneModalIsOpen, navigateToScene]);

  const [createFolderModalIsOpen, toggleCreateFolderModalIsOpen, setCreateFolderModalIsOpen] = useToggle(false);
  const handleCreateFolder = useCallback(async (name: string, tags: Tag[]) => {
    const folderId = SceneTreeId.generate();
    scenesRef.apply(prevFiles => [{
      type: "insert",
      path: [prevFiles.length],
      item: {
        type: "folder",
        data: {id: folderId, name: name, tags: tags, children: []}
      }
    }]);

    apply(_ => [{
      type: "update-expanded",
      operations: [{type: "put", key: folderId, item: true}]
    }]);
    setCreateFolderModalIsOpen(false);
  }, [scenesRef, apply, setCreateFolderModalIsOpen]);

  const expanded = useMemo(() => ({
    observe: from(value.expanded),
    apply: (fn: ApplyAction<{[id in SceneTreeId]?: boolean}, MapOperation<SceneTreeId, boolean, ConstantOperation>[]>) => apply(prev => [{type: "update-expanded", operations: fn(prev.expanded)}])
  }), [value.expanded, apply]);

  const toggleExpanded = useCallback((id: SceneTreeId) => {
    expanded.apply(prev => {
      if (prev[id] !== undefined) return MapFn.delete<SceneTreeId, boolean>(id, prev[id]!);
      return MapFn.put(id, true);
    });
  }, [expanded.apply]);

  const deleteResource = useDeleteResource();
  const deleteFolder = useCallback((item: SceneTreeItem) => {
    if (item) {
      const path = SceneTree.getItemPath(scenesRef.value, item.data.id);
      if (path) {
        const promises: Promise<any>[] = [];
        walkTree([item], {
          visit: (item: SceneTreeItem) => {
            if (item.type === "scene") {
              promises.push(deleteResource("scene", item.data.id));
            }
          }
        });
        Promise.all(promises)
          .catch(console.error);
        scenesRef.apply(_ => [{type: "delete", path: path, prevItem: item}]);
      }
    }
  }, [scenesRef, deleteResource, scenesRef]);

  const deleteFile = useCallback((item: SceneTreeItem) => {
    const path = SceneTree.getItemPath(scenesRef.value, item.data.id);
    if (path && item.type === "scene") {
      deleteResource("scene", item.data.id).catch(console.error);
      scenesRef.apply(_ => [{type: "delete", path: path, prevItem: item}]);
      editor.state.apply(appState => {
        if (appState?.type !== "scene" || appState.data.sceneReference.sceneID !== item.data.id) return [];
        return ValueFn.set(appState, undefined);
      });
    }
  }, [scenesRef, editor, deleteResource]);

  const isVisible = useCallback((_: SceneTreeItem) => true, []);

  const extractScene = useExtractScene();
  const exportScene = useCallback(async (item: SceneTreeItem) => {
    if (item?.type !== "scene") return;
    const file = await extractScene(item.data.id);
    exportFile(`SCENE-${item.data.name}.lvtt`, new Blob([JSON.stringify(file, null, 2)]));
  }, [extractScene]);

  const importFile = useImportFile();
  const resolvedExpanded = useObservable(expanded.observe, {}, [expanded.observe]);

  const [editFolderSignal, setEditFolderSignalSignal] = useState<MutableRef<SceneTreeFolder, SceneTreeFolderOperation[]> | undefined>(undefined);
  const [editFileSignal, setEditFileSignal] = useState<MutableRef<SceneTreeFile, SceneTreeFileOperation[]> | undefined>(undefined);

  const getFileSignal = useCallback((id: AssetTreeId): MutableRef<SceneTreeFile, SceneTreeFileOperation[]> => {
    return getTreeItemSignal(scenesRef, id)
      .map<SceneTreeFile, SceneTreeFileOperation[]>(
        (value) => {
          if (value?.type === "scene") return value.data;
          else throw new Error(`Unsupported type: ${value?.type}.`);
        },
        (prev, operations) => (prev?.type !== "scene") ? [] : [{type: "scene", operations}]
      );
  }, [scenesRef]);

  const getFolderSignal = useCallback((id: SceneTreeId): MutableRef<SceneTreeFolder, SceneTreeFolderOperation[]> => {
    return getTreeItemSignal(scenesRef, id)
      .map<SceneTreeFolder, SceneTreeFolderOperation[]>(
        (value) => {
          if (value?.type === "folder") return value.data;
          else throw new Error(`Unsupported type: ${value?.type}.`);
        },
        (prev, operations) => (prev?.type !== "folder") ? [] : [{type: "folder", operations}]
      )
  }, [scenesRef]);

  const scenesValue = useRefValue(scenesRef);

  return (<>
    <ButtonBar>
        <Spacer/>
        <Button onClick={toggleCreateSceneModalIsOpen}><FaPlus /> Add Scene</Button>
        <ExpandOptions options={{placement:"right-start"}}>
            <Button onClick={toggleCreateFolderModalIsOpen}><FaFolderPlus /> Add Folder</Button>
            <ImportButton type={legendsFileType} onImport={importFile}>Import Scene</ImportButton>
        </ExpandOptions>
    </ButtonBar>

    <TreeView
      type={"scene"}
      items={scenesValue}
      isVisible={isVisible}
      move={(fromPath, toPath) => scenesRef.apply(_ => [{type: "move", fromPath, toPath}])}
      expanded={resolvedExpanded}
      onExpand={toggleExpanded}
      onCollapse={toggleExpanded}
      ItemActions={(item) => {
        return <>
          {item.type === "scene" && <IconButton variant="primary" onClick={() => navigateToFile(item)} title={`Navigate to ${item.data.name}`}><FaMap /></IconButton>}
          <ExpandOptions options={{placement: "right-start"}}>
            {item.type === "folder" && <>
                <Menu.Item as={Button} className="justify-start" title="Edit Folder" onClick={() => setEditFolderSignalSignal(getFolderSignal(item.data.id))}>
                    <FaEdit /> Edit Folder
                </Menu.Item>
                <Menu.Item as={Button} className="justify-start" title="Delete Folder" variant={"destructive"} onClick={() => deleteFolder(item)}>
                    <FaTrash /> Delete Folder
                </Menu.Item>
            </>}
            {item.type === "scene" && <>
                <Menu.Item as={Button} className="justify-start" title="Export Scene" onClick={() => exportScene(item)}>
                    <FaFileExport /> Export Scene
                </Menu.Item>
                <Menu.Item as={Button} className="justify-start" title="Edit Scene" onClick={() => setEditFileSignal(getFileSignal(item.data.id))}>
                    <FaEdit /> Edit Scene
                </Menu.Item>
                <Menu.Item as={Button} className="justify-start" title="Delete Scene" variant="destructive" onClick={() => deleteFile(item)}>
                    <FaTrash /> Delete Scene
                </Menu.Item>
            </>}
          </ExpandOptions>
        </>
      }} />
    {createSceneModalIsOpen && <CreateSceneModal onCancel={toggleCreateSceneModalIsOpen} onCreate={handleCreateScene} />}
    {createFolderModalIsOpen && <CreateFolderModal onCancel={toggleCreateFolderModalIsOpen} onCreate={handleCreateFolder} />}
    {editFolderSignal && <EditFolderModal onClose={() => setEditFolderSignalSignal(undefined)} value={editFolderSignal} />}
    {editFileSignal && <EditSceneModal onClose={() => setEditFileSignal(undefined)} value={editFileSignal} />}
  </>);
}


function SceneBreadcrumbItem({sceneID, onClick}: {sceneID: SceneID, onClick: () => void}) {
  const databaseRef = useDatabase();
  const name = useComputedValue(databaseRef, database => {
    const store = database.store;
    if (store?.type === "game") {
      const game = store.data;
      const item = Tree.getItemById(game.scenes, sceneID);
      return item?.data.name || "";
    }
    return "";
  }, [sceneID]);
  return <BreadcrumbItem Icon={<FaMap />} onClick={onClick}>
    {name}
  </BreadcrumbItem>
}

export function SceneNavigatorStackView({valueRef}: {
  valueRef: MutableRef<SceneNavigator, SceneNavigatorOperation[]>;
}) {
  const sceneID = useComputedValue(useSceneViewportProperties(), (sceneViewportProperties) => sceneViewportProperties?.sceneReference.sceneID);
  const [sceneSelection, setSceneSelection] = useState(sceneID === undefined);
  const navigateToScene = useNavigateToSceneEditor();
  return (<div className="tab-content flex flex-col gap-1 py-2">
    <BaseComponent>
      <Breadcrumb>
        <BreadcrumbItem Icon={<FaHome />} onClick={() => setSceneSelection(true)}>Scenes</BreadcrumbItem>
        {!sceneSelection && sceneID && <SceneBreadcrumbItem sceneID={sceneID} onClick={() => setSceneSelection(false)} />}
        {sceneSelection && sceneID !== undefined && <>
          <Spacer/>
          <BreadcrumbItem title={"Go to Current Scene"} Icon={<FaLocationCrosshairs/>} onClick={() => setSceneSelection(false)}/>
        </>}
      </Breadcrumb>
      {sceneSelection && <SceneSelector navigateToScene={(sceneID) => {
        setSceneSelection(false);
        navigateToScene(sceneID);
      }} valueRef={valueRef} />}
      {!sceneSelection && <Suspense fallback={<></>}>
        <SceneOutlinerView valueRef={valueRef} />
      </Suspense>}
    </BaseComponent>
  </div>);
}

