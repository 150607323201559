import React, {PropsWithChildren, useRef} from "react";
import {FaFileImport} from "react-icons/fa";
import {Button} from "../button/index.ts";
import {Type} from "common/types/index.ts";

export type ImportButtonProps<T> = PropsWithChildren<{
  type: Type<T, any>,
  onImport: (value: T) => void;
}>;
export function ImportButton<T>({type, onImport, children}: ImportButtonProps<T>) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return <>
    <input ref={fileInputRef} type="file" className="hidden" onChange={(ev) => {
      if ((ev.target.files?.length || 0) > 0) {
        const file = ev.target.files!.item(0)!;
        file.text().then(text => {
          const value = type.migrateValue(JSON.parse(text));
          const errors = type.validate(value);
          if (errors.length === 0) {
            onImport(value);
          } else {
            console.error(`Invalid File Format:\n- ${errors.map(error => JSON.stringify(error)).join("\n- ")}\n\nGot: ${value}`);
          }
        });
      }
      return false;
    }} accept=".lvtt"/>
    <Button onClick={() => {
      fileInputRef?.current?.click();
    }} title="Import">
      <FaFileImport/>
      {children}
    </Button>
  </>
}
