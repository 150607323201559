import {PlayerSheetStack, PlayerSheetStackOperation} from "./player-sheet-stack.ts";
import {Apply, ConstantOperation, Optional, ValueOperation} from "common/types/index.ts";
import React, {useMemo} from "react";
import {from} from "common/observable";
import {usePlayerSheetReference} from "./use-player-sheet-reference.ts";
import {SheetProvider} from "../../../common/sheet/sheet-context.ts";
import {SheetReferenceProvider} from "../../../common/sheet/sheet-reference-context.ts";
import {Dnd5eCharacterSheetView} from "../editor/dnd-5e-character/dnd-5e-character-view.tsx";
import {Dnd5eStatBlockView} from "../editor/dnd-5e-stat-block/dnd-5e-stat-block-view.tsx";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {GMSheetFn} from "../editor/g-m-sheet-type.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {VariablesProvider} from "../editor/dnd-5e/dnd-5e-action/dnd-5e-variables.ts";
import {MutableRef} from "common/ref";

export type SheetStackViewProps = {
  value: PlayerSheetStack;
  apply: Apply<PlayerSheetStack, PlayerSheetStackOperation[]>;
};
export function PlayerSheetStackView({value, apply}: SheetStackViewProps) {
  const pinnedSheetReference = useMemo((): MutableRef<Optional<SheetReference>, ValueOperation<Optional<SheetReference>, ConstantOperation>[]> => {
    return new MutableRef({
      value(): Optional<SheetReference> {
        return value.reference
      },
      observe: from(value.reference),
      apply: (fn) => apply(prev => {
        const operations = fn(prev.reference)
        return operations.length > 0 ? GMSheetFn.updateReference(operations) : [];
      }).then(value => value.reference)
    });
  }, [value, apply]);

  const sheetReference = usePlayerSheetReference(pinnedSheetReference);
  const sheet = useSheetSignal(sheetReference);
  const type = useComputedValue(sheet, sheet => sheet?.type);
  const variables = useMemo(() => sheetVariablesSignal(sheet), [sheet]);

  if (!type || !sheetReference) return (<div className="text-small text-center italic px-4 py-2">
    No sheet selected...
  </div>);

  return (<SheetProvider value={sheet}>
    <VariablesProvider value={variables}>
      <SheetReferenceProvider value={sheetReference}>
        {type === "dnd-5e-character" && <Dnd5eCharacterSheetView reference={sheetReference} pinned={pinnedSheetReference} />}
        {type === "dnd-5e-stat-block" && <Dnd5eStatBlockView reference={sheetReference} pinned={pinnedSheetReference} />}
      </SheetReferenceProvider>
    </VariablesProvider>
  </SheetProvider>);
}
