import {Arc, Transform} from "common/types/generic/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useGrid} from "../../../viewport/common/node/node-view/grid-view/grid-context.ts";
import {useMemo} from "react";
import {CircleMeasurement} from "common/legends/measurement/index.ts";
import {ArcShader} from "../../../viewport/common/node/node-view/shape/arc-shader.tsx";
import {Grid} from "common/legends/scene/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";

export function CircleMeasurementView({projection, view, measurement: {origin, radius, color}}: {
  projection: Matrix4f, view: Transform,
  measurement: CircleMeasurement
}) {
  const grid = useGrid();

  const model: Transform = useMemo(() => ({
    scale: 1,
    position: origin,
    rotation: 0
  }), [origin]);

  const radiusTextModel: Transform = useMemo(() => ({
    scale: 1 / view.scale,
    position: [origin[0], origin[1] + radius],
    rotation: 0
  }), [origin, radius]);

  const circle = useMemo((): Arc => ({
    radius: radius,
    startAngle: 0,
    endAngle: 360
  }), [radius]);

  const distance = Grid.distance({...grid, subdivisions: grid.subdivisions + 1}, origin, [origin[0], origin[1] + radius]);

  return <>
    <ArcShader projection={projection} view={view} model={model} origin={[0,0]} color={color} arc={circle} />
    <TextShader projection={projection} view={view} model={radiusTextModel} value={`${distance} ${grid.unit[1]}`} size={32} vTextAlign="bottom" />
  </>;
}
