import {DeflateCodec} from "./deflate-codec.ts";

const Utf16ToUtf8Codec = {
  encode: (str: string): string => {
    const binary = [];
    const bytes = new TextEncoder().encode(str);
    for (let i = 0, il = bytes.byteLength; i < il; i++) {
      binary.push(String.fromCharCode(bytes[i]));
    }
    return binary.join('');
  },
  decode: (str: string): string => {
    return new TextDecoder().decode(Uint8Array.from(str, c => c.charCodeAt(0)));
  }
}

export const Base64Codec = {
  encode: (str: string): string => {
    return btoa(Utf16ToUtf8Codec.encode(str));
  },
  decode: (str: string): string => {
    return Utf16ToUtf8Codec.decode(atob(str));
  }
}

export const ArrayBufferToBase64Codec = {
  encode(buffer: ArrayBuffer): string {
    const uint8Buffer = new Uint8Array(buffer);
    return btoa(String.fromCharCode.apply(null, Array.from(uint8Buffer)));
  },
  decode(base64string: string): ArrayBuffer {
    return Uint8Array.from(atob(base64string), c => c.charCodeAt(0));
  }
};

export const Codec = {
  encode: async (decodedValue: any): Promise<string> => {
    const jsonString = JSON.stringify(decodedValue);
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(jsonString);
    const compressedData = await DeflateCodec.encode(encodedData);
    let binary = '';
    const len = compressedData.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(compressedData[i]);
    }
    return btoa(binary);
  },
  decode: async (encodedValue: string): Promise<any> => {
    const binaryString = atob(encodedValue);
    const len = binaryString.length;
    const compressedData = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      compressedData[i] = binaryString.charCodeAt(i);
    }
    const decompressedData = await DeflateCodec.decode(compressedData);
    const decoder = new TextDecoder();
    const jsonString = decoder.decode(decompressedData);
    return JSON.parse(jsonString);
  }
};
