import {Input, InputProps} from "#lib/components/index.ts";
import {NumberFn, NumberOperation} from "common/types/index.ts";
import {useCallback, useState} from "react";
import {MathExpressionFn} from "common/math/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef, Ref} from "common/ref";

export type InputNumberProps = (
  | {
    readOnly: true,
    value: Ref<number>;
    onLeaveEdit?: () => void;
  }
  | {
    readOnly?: false;
    value: MutableRef<number, NumberOperation[]>;
    onLeaveEdit?: () => void;
  }) & Omit<InputProps, "value" | "onChange">;

export function InputNumber({value, onLeaveEdit, ...props}: InputNumberProps) {
  const query = useRefValue(value);
  const setValue = useCallback((nextValue: number) => {
    if (props.readOnly) return;
    (value as MutableRef<number, NumberOperation[]>).apply(prev => NumberFn.set(prev, nextValue))
  }, [props.readOnly, value.apply]);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string | undefined>(undefined);

  const edit = useCallback(() => {
    setEditValue(`${value.value}`);
    setEditMode(true);
  }, [setEditValue, setEditMode, value]);

  const finalizeEdit = useCallback(() => {
    const expression = editValue!;
    if (expression === "") {
      setValue(0);
    } else if (MathExpressionFn.isMathExpression(expression)) {
      setValue(MathExpressionFn.executeMathExpression(expression, {}));
    }
    setEditMode(false);
    onLeaveEdit?.();
  }, [editValue, setValue, setEditMode]);

  return <div className="w-full flex items-center">
    {!editMode && <Input {...props} readOnly type="number" value={Number.isFinite(query) ? query : ""} onChange={ev => setValue(parseFloat(ev.target.value))} onClick={edit} onFocus={edit} />}
    {editMode && <div className="w-full flex flex-row gap-2 items-center">
      <Input {...props} autoFocus type="text" value={editValue} onChange={ev => setEditValue(ev.target.value)} onBlur={finalizeEdit} onKeyDown={ev => {
        if (ev.key === "Enter") {
          finalizeEdit();
          ev.preventDefault();
          return false;
        }
        if (ev.key === "Escape") {
          setEditMode(false);
          onLeaveEdit?.();

          ev.preventDefault();
          return false;
        }
      }} />
    </div>}
  </div>
}
