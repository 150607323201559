import {Dnd5eActionDamage} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {RollRequestView} from "../../roll-request-view.tsx";
import {Button} from "#lib/components/button/index.ts";
import {FaSpinner} from "react-icons/fa";
import {useSelectedSheet} from "../../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {useApplyDamageToSelectedCharacter, useApplyHealingToSelectedCharacter, useApplyTempHPToSelectedCharacter} from "../use-apply-to-selected-character.ts";
import {MouseEvent, useState} from "react";
import {useIsAltDown} from "#lib/is-alt-down.ts";
import {useIsShiftDown} from "#lib/components/use-is-shift-down.ts";
import {useIsCtrlDown} from "#lib/components/use-is-ctrl-down.ts";

export function Dnd5eActionDamageView({segment, rollRequests, rollResults}: {segment: Dnd5eActionDamage[], rollRequests: RollRequests, rollResults: Optional<RollResults>}) {
  const selectedSheet = useSelectedSheet();
  const isSheetSelected = useComputedValue(selectedSheet, selectedSheet => selectedSheet !== undefined);
  const applyDamage = useApplyDamageToSelectedCharacter(selectedSheet);
  const applyTempHP = useApplyTempHPToSelectedCharacter(selectedSheet);
  const applyHealing = useApplyHealingToSelectedCharacter(selectedSheet);

  const [isProcessing, setIsProcessing] = useState(false);

  const altDown = useIsAltDown();
  const shiftDown = useIsShiftDown();
  const ctrlDown = useIsCtrlDown();

  const hasHealing = altDown || segment.some(damage => damage.damageType === "healing");
  const hasTempHP = segment.some(damage => damage.damageType === "temp hp");

  const applyNormalDamage = (ev: MouseEvent) => {
    if (hasHealing) {
      setIsProcessing(true);
      applyHealing(segment.map(dr => (rollResults ? rollResults[dr.damageRollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else if (hasTempHP) {
      setIsProcessing(true);
      applyTempHP(segment.map(dr => (rollResults ? rollResults[dr.damageRollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else {
      setIsProcessing(true);
      applyDamage(
        segment.map(dr => ({rollResult: rollResults ? rollResults[dr.damageRollID] : undefined, damageType: dr.damageType})),
        ev.ctrlKey, ev.shiftKey
      ).finally(() => setTimeout(() => setIsProcessing(false), 500));
    }
  };

  if (segment.length === 0) return <></>;
  return <>
    <div className="flex flex-wrap gap-2 justify-center">
      {segment.map((damageRoll, index) => <div key={index} className="m-0.5">
        <RollRequestView
          rollType="damage"
          damageType={damageRoll.damageType}
          rollRequest={rollRequests[damageRoll.damageRollID]}
          rollResult={rollResults ? rollResults[damageRoll.damageRollID] :undefined } />
      </div>)}
    </div>

    <Button variant="primary" disabled={isProcessing || !isSheetSelected} className="w-full" onClick={applyNormalDamage}>
      {isProcessing && <FaSpinner className="animate-spin"/>}
      {hasHealing
        ? "Apply Healing"
        : (hasTempHP
          ? "Apply Temp HP"
          : `Apply ${shiftDown && !ctrlDown ? "Double " : ""}${!shiftDown && ctrlDown ? "Half " : ""}Damage`)
      }
    </Button>
  </>
}