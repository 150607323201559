import {LayoutOperation} from "./layout/index.ts";
import {DialogID} from "./dialog/index.ts";
import {DialogItem, DialogItemOperation} from "./dialog/dialog-item.ts";

export type ApplyLayoutContainerOperation<LV, LO> = {
  type: "apply-to-layout";
  operations: LayoutOperation<LV, LO>[]
};

export type CreateDialogContainerOperation<LV> = {
  type: "create-dialog",
  content: DialogItem<LV>;
};
export type ActivateDialogContainerOperation = {
  type: "activate-dialog",
  id: DialogID;
};
export type ApplyDialogContainerOperation<LV, LO> = {
  type: "apply-to-dialog";
  id: DialogID;
  operations: DialogItemOperation<LV, LO>[];
};
export type DestroyDialogContainerOperation = {
  type: "destroy-dialog";
  id: DialogID;
};

export type ContainerOperation<LV, LO> =
  | ApplyLayoutContainerOperation<LV, LO>
  | CreateDialogContainerOperation<LV>
  | ActivateDialogContainerOperation
  | ApplyDialogContainerOperation<LV, LO>
  | DestroyDialogContainerOperation
  ;

export const ContainerFn = {
  applyToLayout<LV, LO>(operations: LayoutOperation<LV, LO>[]): ContainerOperation<LV, LO>[] {
    return [{type: "apply-to-layout", operations}]
  },
  createDialog<LV, LO>(content: DialogItem<LV>): ContainerOperation<LV, LO>[] {
    return [{type: "create-dialog", content: content}];
  },
  applyToDialog<LV, LO>(id: DialogID, operations: DialogItemOperation<LV, LO>[]): ContainerOperation<LV, LO>[] {
    return [{type: "apply-to-dialog", id, operations}]
  }
}