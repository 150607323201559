import {GenIcon} from "react-icons";
import {IconBaseProps} from "react-icons";

export const SquareGridTypeIcon = (props: IconBaseProps) => GenIcon({
  tag: "svg",
  attr: {
    viewBox: "0 0 1500 1500",
    width: "1500",
    height: "1500"
  },
  child: [{
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "96px"},
      d: "M 375,375 1125,375 1125,1125 375,1125 Z"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,375 375,375 375,0 M 1125,0 1125,375 1500,375"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,1125 375,1125 375,1500 M 1125,1500 1125,1125 1500,1125"
    },
    child: []
  }]
})(props);