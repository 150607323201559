import {UUID} from "common/utils";

export type StackId = string;

export const StackId = {
  generate: (): StackId => {
    return UUID.generate();
  }
};

