import {z} from "zod";

export const ToggleBooleanOperation = z.object({type: z.literal("toggle")});
export type ToggleBooleanOperation = z.infer<typeof ToggleBooleanOperation>;

export const SetBooleanOperation = z.object({type: z.literal("set"), prevValue: z.boolean(), nextValue: z.boolean()});
export type SetBooleanOperation = z.infer<typeof SetBooleanOperation>;

export const BooleanOperation = z.discriminatedUnion("type", [
  ToggleBooleanOperation,
  SetBooleanOperation
]);
export type BooleanOperation = z.infer<typeof BooleanOperation>;
