import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {Optional} from "common/types/index.ts";
import {Ref} from "common/ref";
import {useSelectedNodeIDRef} from "../editor/dnd-5e-character/use-selected-sheet.ts";
import {useNode, useNodeSheetReference} from "../../../viewport/token/use-node.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";

export function usePlayerSheetReference(pinnedReference: Ref<Optional<SheetReference>>): Ref<Optional<SheetReference>> {
  const currentSheetReference = useNodeSheetReference(useNode(useRefValue(useSelectedNodeIDRef())));
  const isPinnedReferencePresent = usePresent(pinnedReference);
  return isPinnedReferencePresent ? pinnedReference : currentSheetReference;
}
