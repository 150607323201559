export class Loader<Key, Value> {
  private keyMap: Map<string, object>;
  private weakCache: WeakMap<object, Value>;
  constructor(private readonly keyFactory: (key: Key) => string, private readonly factory: (key: Key) => Value) {
    this.keyMap = new Map();
    this.weakCache = new WeakMap();
  }
  private getKeyObject(key: Key): object {
    const keyString = this.keyFactory(key);
    if (!this.keyMap.has(keyString)) {
      this.keyMap.set(keyString, {});
    }
    return this.keyMap.get(keyString)!;
  }
  get(key: Key): Value {
    const keyObject = this.getKeyObject(key);
    if (!this.weakCache.has(keyObject)) {
      this.weakCache.set(keyObject, this.factory(key));
    }
    return this.weakCache.get(keyObject)!;
  }
  set(key: Key, value: Value): void {
    const keyObject = this.getKeyObject(key);
    this.weakCache.set(keyObject, value);
  }
}
