import {useCallback} from "react";
import {AssetID, assetType, SceneID, sceneType} from "common/legends/index.ts";
import {TreeOperation, ValueFn, VisitResult, walkTree} from "common/types/index.ts";
import {LegendsFile} from "./legends-file.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {QLabDatabaseOperation, QLabResourceID} from "common/qlab/index.ts";

export function useImportFile() {
  const databaseRef = useDatabase();
  return useCallback(async (value: LegendsFile) => {
    await databaseRef.apply(database => {
      const changesets: QLabDatabaseOperation[] = [];
      for (const [sceneID, scene] of Object.entries(value.scenes)) {
        const prevValue = database.resources[sceneID as QLabResourceID];
        changesets.push({type: "resource", resourceID: sceneID as QLabResourceID, operations: ValueFn.set(
          prevValue,
          {type: "scene", data: sceneType.migrateValue(scene.data)}
        )});
      }
      for (const [assetID, asset] of Object.entries(value.assets)) {
        const prevValue = database.resources[assetID as QLabResourceID]
        changesets.push({type: "resource", resourceID: assetID as QLabResourceID, operations: ValueFn.set(
          prevValue,
          {type: "asset", data: assetType.migrateValue(asset.data)}
        )});
      }

      if (database.store?.type === "game") {
        const store = database.store;
        changesets.push({
          type: "store", operations: ValueFn.apply([{type: "game", operations: [
            {type: "update-scenes", operations: Object.entries(value.scenes)
              .filter(([sceneID]) => {
                let found = false;
                walkTree(store.data.scenes, {
                  visit(value) {
                    if (value.type === "scene" && value.data.id === sceneID) {
                      found = true;
                      return VisitResult.TERMINATE
                    }
                  }
                });
                return !found;
              })
              .flatMap(([sceneID, scene], index) => TreeOperation.insert(
                [store.data.scenes.length + index],
                {type: "scene", data: {id: sceneID as SceneID, name: scene.name, tags: scene.tags}}
              ))},
            {type: "update-assets", operations: Object.entries(value.assets)
              .filter(([assetID]) => {
                let found = false;
                walkTree(store.data.assets, {
                  visit(value) {
                    if (value.type === "asset" && value.data.id === assetID) {
                      found = true;
                      return VisitResult.TERMINATE
                    }
                  }
                });
                return !found;
              })
              .flatMap(([assetID, asset], index) => TreeOperation.insert(
                [store.data.assets.length + index],
                {type: "asset", data: {id: assetID as AssetID, name: asset.name, tags: asset.tags}}
              ))
            }
          ]}])
        });
      }
      return changesets;
    });
  }, [databaseRef]);
}
