import {Type} from "./type.ts";
import {QLabError} from "#common/error/QLabError.ts";

export function applyAll<Value, Operation>(type: Type<Value, Operation>, value: Value, operations: Operation[]): Value {
  return operations.reduce((value, operation) => {
    try {
      return type.apply(value, operation);
    } catch (e: any) {
      if (e.path) {
        throw new QLabError(e.message, e.path);
      } else {
        throw new QLabError(`Failed to apply changeset.\nCHANGESET:\n${JSON.stringify(operation)}\n\nVALUE:\n${JSON.stringify(value)}\n\nERROR:\n${e.message}`, []);
      }
    }
  }, value);
}