import {Observable, Subscription} from "../observable.ts";

export function combine<A, B>(a: Observable<A>): Observable<[A]>;
export function combine<A, B>(a: Observable<A>, b: Observable<B>): Observable<[A, B]>;
export function combine<A, B, C>(a: Observable<A>, b: Observable<B>, c: Observable<C>): Observable<[A, B, C]>;

export function combine(...args: Observable<any>[]): Observable<any> {
  return (observer): Subscription => {
    const received = args.map(_ => false);
    let values = args.map(_ => undefined);
    const subscriptions = args.map((observe, index) => observe({
      next: value => {
        values = [...values];
        values[index] = value;
        received[index] = true;
        if (received.every(v => v)) {
          observer.next(values);
        }
      },
      error: observer.error,
      complete: () => {}
    }));

    return () => {
      subscriptions.forEach(subscription => subscription());
    };
  };
}
