import {RollRequestView} from "../roll-request-view.tsx";
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {RollRequestID, RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Loader} from "common/loader";

export function Dnd5eDamageView({damageRolls, criticalDamage, requests, results}: {
  damageRolls: {
    damageType?: Optional<Dnd5eDamageType>,
    hitDamageRollID: RollRequestID,
    critDamageRollID?: RollRequestID
  }[],
  criticalDamage?: boolean,
  requests: Loader<RollRequests>,
  results: Loader<Optional<RollResults>>
}) {
  return (<>
    {!criticalDamage && damageRolls.length > 0 && <tr className="divide-x divide-slate-900/50">
        <td className="text-sm py-2 text-center" colSpan={2}>
          {damageRolls.map((damageRoll, index) => <div key={index} className="m-0.5">
            <RollRequestView
              rollType="damage"
              damageType={damageRoll.damageType}
              rollRequest={requests.isSuccess ? requests.data[damageRoll.hitDamageRollID] : undefined}
              rollResult={requests.isSuccess && results.data ? results.data[damageRoll.hitDamageRollID] :undefined } />
          </div>)}
        </td>
    </tr>}

    {criticalDamage && damageRolls.length > 0 && <tr className="divide-x divide-slate-900/50">
        <td className="text-sm py-2 text-center" colSpan={2}>
          {damageRolls.filter(damageRoll => damageRoll.critDamageRollID).map((damageRoll, index) => <div key={index} className="m-0.5">
            <RollRequestView
              rollType="damage"
              damageType={damageRoll.damageType}
              rollRequest={requests.isSuccess ? requests.data[damageRoll.critDamageRollID!] : undefined}
              rollResult={requests.isSuccess && results.data ? results.data[damageRoll.critDamageRollID!] :undefined } />
          </div>)}
        </td>
    </tr>}
  </>);
}