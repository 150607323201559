import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, Type} from "../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {InteractionActionID} from "./interaction-action-i-d.ts";

export const InteractionActionMount = z.object({
  interactionActionID: InteractionActionID
});
export type InteractionActionMount = z.infer<typeof InteractionActionMount>;

export const InteractionActionMountOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-action-i-d"), operations: z.array(ConstantOperation)})
]);
export type InteractionActionMountOperation = z.infer<typeof InteractionActionMountOperation>;

export const interactionActionMountType: Type<InteractionActionMount, InteractionActionMountOperation> = new ObjectType({
  interactionActionID: constantType
});

export const InteractionActionMountFn = {
  expand: (value: MutableRef<InteractionActionMount, InteractionActionMountOperation[]>) => ({
  })
};
