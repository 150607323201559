import {Color, ListOperation} from "common/types/index.ts";
import {useMultiTypeApplyCallback} from "#lib/qlab/index.ts";
import React, {useCallback, useMemo, useState} from "react";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "common/dice/index.ts";
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {pipe} from "common/pipe";
import {map} from "common/observable";
import {Dnd5eModifier, Dnd5eModifierOperation, getModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-attack-roll-modifier.ts";
import {Dnd5eDCModifier, Dnd5eDCModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-d-c-modifier.ts";
import {Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-roll-modifier.ts";
import {Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {Menu} from "@headlessui/react";
import {Dnd5eConditionImmunityModifier, Dnd5eConditionImmunityModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-condition-immunity-modifier.ts";
import {Dnd5eDamageImmunityModifier, Dnd5eDamageImmunityModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-immunity-modifier.ts";
import {Dnd5eDamageResistanceModifier, Dnd5eDamageResistanceModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-resistance-modifier.ts";
import {
  Dnd5eDamageVulnerabilityModifier,
  Dnd5eDamageVulnerabilityModifierOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-vulnerability-modifier.ts";
import {usePopper} from "react-popper";
import {createPortal} from "react-dom";
import {Popper} from "#lib/components/popper/popper.tsx";
import {Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-reduction-modifier.ts";
import {Dnd5eDamageThresholdModifier, Dnd5eDamageThresholdModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-threshold-modifier.ts";
import {Dnd5eTraitModifier, Dnd5eTraitModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-trait-modifier.ts";
import {Dnd5eAbilityCheckModifierView} from "./dnd-5e-ability-check-modifier-view.tsx";
import {Dnd5eAttackRollModifierView} from "./dnd-5e-attack-roll-modifier-view.tsx";
import {Dnd5eDCModifierView} from "./dnd-5e-d-c-modifiers-view.tsx";
import {Dnd5eDamageRollModifierView} from "./dnd-5e-damage-roll-modifier-view.tsx";
import {Dnd5eSavingThrowModifierView} from "./dnd-5e-saving-throw-modifiers-view.tsx";
import {Dnd5eConditionImmunityModifierView} from "./dnd-5e-condition-immunity-modifier-view.tsx";
import {Dnd5eDamageImmunityModifierView} from "./dnd-5e-damage-immunity-modifier-view.tsx";
import {Dnd5eDamageResistanceModifierView} from "./dnd-5e-damage-resistance-modifier-view.tsx";
import {Dnd5eDamageVulnerabilityModifierView} from "./dnd-5e-damage-vulnerability-modifier-view.tsx";
import {Dnd5eDamageReductionModifierView} from "./dnd-5e-damage-reduction-modifier-view.tsx";
import {Dnd5eDamageThresholdModifierView} from "./dnd-5e-damage-threshold-modifier-view.tsx";
import {Dnd5eTraitModifierView} from "./dnd-5e-trait-modifier-view.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {copyDnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/copy-dnd-5e-modifier.ts";
import {Dnd5eArmorClassFormulaModifierView} from "./dnd-5e-armor-class-formula-modifier-view.tsx";
import {Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-armor-class-formula-modifier.ts";
import {MathExpressionFn} from "common/math/index.ts";
import {Dnd5eVariableModifierView} from "./dnd-5e-variable-modifier-view.tsx";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd5e-variable-modifier.ts";
import {InputStatusIndicatorModifier} from "../../../../../common/character/status-indicator/input-status-indicator-modifier.tsx";
import {StatusIndicatorModifier, StatusIndicatorModifierOperation} from "common/legends/asset/status-indicator/index.ts";
import {Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-max-h-p-modifier.ts";
import {Dnd5eMaxHPModifierView} from "./dnd-5e-max-h-p-modifier-view.tsx";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef} from "common/ref";
import {Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-armor-class-modifier.ts";
import {Dnd5eArmorClassModifierView} from "./dnd-5e-armor-class-modifier-view.tsx";

export type Dnd5eModifierViewProps = InputListItemProps<Dnd5eModifier, Dnd5eModifierOperation>;
export function Dnd5eModifierView({item, remove}: Dnd5eModifierViewProps) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem<Dnd5eModifier>("legends/modifier", item, remove);

  const applyToType = useMultiTypeApplyCallback(item.apply);
  const type = useComputedValue(item, item => item.type);

  const typedValue = useMemo(() => new MutableRef({
    value() {
      return item.value.data;
    },
    observe: pipe(item.observe, map(value => value.data)),
    apply: applyToType
  }), [item, applyToType]);

  if (type === "ability-check") {
    return <Dnd5eAbilityCheckModifierView item={typedValue as MutableRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "attack-roll") {
    return <Dnd5eAttackRollModifierView value={typedValue as MutableRef<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "difficulty-class") {
    return <Dnd5eDCModifierView value={typedValue as MutableRef<Dnd5eDCModifier, Dnd5eDCModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "damage-roll") {
    return <Dnd5eDamageRollModifierView value={typedValue as MutableRef<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "saving-throw") {
    return <Dnd5eSavingThrowModifierView value={typedValue as MutableRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "condition-immunity") {
    return <Dnd5eConditionImmunityModifierView value={typedValue as MutableRef<Dnd5eConditionImmunityModifier, Dnd5eConditionImmunityModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />;
  } else if (type === "damage-immunity") {
    return <Dnd5eDamageImmunityModifierView value={typedValue as MutableRef<Dnd5eDamageImmunityModifier, Dnd5eDamageImmunityModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-resistance") {
    return <Dnd5eDamageResistanceModifierView value={typedValue as MutableRef<Dnd5eDamageResistanceModifier, Dnd5eDamageResistanceModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-vulnerability") {
    return <Dnd5eDamageVulnerabilityModifierView value={typedValue as MutableRef<Dnd5eDamageVulnerabilityModifier, Dnd5eDamageVulnerabilityModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-reduction") {
    return <Dnd5eDamageReductionModifierView value={typedValue as MutableRef<Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "damage-threshold") {
    return <Dnd5eDamageThresholdModifierView value={typedValue as MutableRef<Dnd5eDamageThresholdModifier, Dnd5eDamageThresholdModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "trait") {
    return <Dnd5eTraitModifierView value={typedValue as MutableRef<Dnd5eTraitModifier, Dnd5eTraitModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "armor-class-formula") {
    return <Dnd5eArmorClassFormulaModifierView value={typedValue as MutableRef<Dnd5eArmorClassFormulaModifier, Dnd5eArmorClassFormulaModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "armor-class") {
    return <Dnd5eArmorClassModifierView value={typedValue as MutableRef<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "variable-override") {
    return <Dnd5eVariableModifierView value={typedValue as MutableRef<Dnd5eVariableModifier, Dnd5eVariableModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "status-indicator") {
    return <InputStatusIndicatorModifier value={typedValue as MutableRef<StatusIndicatorModifier, StatusIndicatorModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  } else if (type === "max-h-p") {
    return <Dnd5eMaxHPModifierView value={typedValue as MutableRef<Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation[]>} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} remove={remove} />
  }
  return <></>;
}

export type Dnd5eModifiersViewProps = {
  value: MutableRef<Dnd5eModifier[], ListOperation<Dnd5eModifier, Dnd5eModifierOperation>[]>;
  label?: string;
  readOnly?: boolean;
};
export function Dnd5eModifiersView({value, label = "Modifiers", readOnly}: Dnd5eModifiersViewProps) {
  const addModifier = useCallback((modifier: Dnd5eModifier) => value.apply(prev => ListOperation.insert(prev.length, modifier)), [value.apply]);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return <div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">{label}</SectionHeader>
      {!readOnly && <Menu as="div" className="flex flex-row" ref={ref => setReferenceElement(ref)}>
          <Menu.Button as={Button} className="flex-1"><FaPlus/> New Modifier</Menu.Button>
        {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)}
                                  style={styles.popper} {...attributes.popper}>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "ability-check",
            data: {
              modifierID: generateDnd5eModifierID(),
              abilityChecks: [],
              expression: DiceExpression.parse("0"),
              proficiency: "untrained",
              hasAdvantage: false,
              hasDisadvantage: false
            }
          })}>Ability Check</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "attack-roll",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: DiceExpression.parse("1")
            }
          })}>Attack Roll</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "saving-throw",
            data: {
              modifierID: generateDnd5eModifierID(),
              savingThrows: [],
              expression: DiceExpression.parse("0"),
              proficiency: "untrained",
              hasAdvantage: false,
              hasDisadvantage: false
            }
          })}>Saving Throw</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "difficulty-class",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: DiceExpression.parse("1")
            }
          })}>Difficulty Class</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-roll",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageType: undefined,
              hitExpression: DiceExpression.parse("1d6"),
              critExpression: DiceExpression.parse("2d6")
            }
          })}>Damage</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "condition-immunity",
            data: {
              modifierID: generateDnd5eModifierID(),
              conditions: []
            }
          })}>Condition Immunity</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-immunity",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Immunity</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-vulnerability",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Vulnerability</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-resistance",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: []
            }
          })}>Damage Resistance</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-reduction",
            data: {
              modifierID: generateDnd5eModifierID(),
              damageTypes: [],
              amount: 5
            }
          })}>Damage Reduction</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "damage-threshold",
            data: {
              modifierID: generateDnd5eModifierID(),
              amount: 10
            }
          })}>Damage Threshold</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "trait",
            data: {
              modifierID: generateDnd5eModifierID(),
              trait: undefined
            }
          })}>Trait</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "armor-class-formula",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: MathExpressionFn.assertMathExpression("10+{DEX}"),
              shieldBonus: true
            }
          })}>Armor Class Formula</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "armor-class",
            data: {
              modifierID: generateDnd5eModifierID(),
              modifierType: "MISC",
              expression: MathExpressionFn.assertMathExpression("1")
            }
          })}>Armor Class</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "variable-override",
            data: {
              modifierID: generateDnd5eModifierID(),
              name: "Variable",
              expression: MathExpressionFn.assertMathExpression("0")
            }
          })}>Variable</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "status-indicator",
            data: {
              modifierID: generateDnd5eModifierID(),
              color: Color.WHITE,
              file: undefined
            }
          })}>Status Indicator</Menu.Item>
          <Menu.Item as={Button} className="justify-start h-8" onClick={() => addModifier({
            type: "max-h-p",
            data: {
              modifierID: generateDnd5eModifierID(),
              expression: MathExpressionFn.assertMathExpression("5")
            }
          })}>Max HP</Menu.Item>
        </Menu.Items>, usePortal())}
      </Menu>}
    </div>

    <InputList<Dnd5eModifier, Dnd5eModifierOperation>
      accept="legends/modifier"
      items={value}
      itemKey={getModifierID}
      copy={copyDnd5eModifier}
      ListItem={Dnd5eModifierView} />
  </div>;
}
