import {z} from "zod";
import {Spline, SplineOperation, splineType} from "common/types/generic/spline/index.ts";
import {ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {WallToolMode} from "./wall-tool-mode.ts";

export const WallToolSlicerMode = z.object({
  spline: z.optional(Spline)
});
export type WallToolSlicerMode = z.infer<typeof WallToolSlicerMode>
export const WallToolSlicerModeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-spline"), operations: z.array(ValueOperation(z.optional(Spline), SplineOperation))})
])
export type WallToolSlicerModeOperation = z.infer<typeof WallToolSlicerModeOperation>
export const wallToolSlicerModeType: Type<WallToolSlicerMode, WallToolSlicerModeOperation> = new ObjectType({
  spline: new ValueType(splineType)
});

export const WallToolSlicerModeFn = {
  DEFAULT: {
    type: "slicer",
    data: {
      spline: undefined
    } satisfies WallToolSlicerMode
  } satisfies WallToolMode
};
