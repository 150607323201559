import {z} from "zod";
import {BooleanOperation, booleanType, ConstantOperation, constantType, ListType, ObjectType, StringOperation, stringType, Type, ZodListOperation} from "#common/types/index.ts";
import {Dnd5eActionEffectID} from "./dnd-5e-action-effect-id.ts";
import {Dnd5eActionModifier, Dnd5eActionModifierOperation, dnd5eActionModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionEffect = z.object({
  actionEffectID: Dnd5eActionEffectID,
  name: z.string(),
  enabled: z.boolean(),
  modifiers: z.array(Dnd5eActionModifier)
});
export type Dnd5eActionEffect = z.infer<typeof Dnd5eActionEffect>;

export const Dnd5eActionEffectOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-effect-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-enabled"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionModifier, Dnd5eActionModifierOperation))})
]);
export type Dnd5eActionEffectOperation = z.infer<typeof Dnd5eActionEffectOperation>;

export const dnd5eActionEffectType: Type<Dnd5eActionEffect, Dnd5eActionEffectOperation> = new ObjectType({
  actionEffectID: constantType,
  name: stringType,
  enabled: booleanType,
  modifiers: new ListType(dnd5eActionModifierType)
});

export const Dnd5eActionEffectFn = {
  updateName: (operations: StringOperation[]): Dnd5eActionEffectOperation[] => [{type: "update-name", operations}],
  updateEnabled: (operations: BooleanOperation[]): Dnd5eActionEffectOperation[] => [{type: "update-enabled", operations}],
  getGroup(effect: Dnd5eActionEffect): string | undefined {
    const i = effect.name.indexOf(":");
    return (i !== -1)
      ? effect.name.substring(0, i)
      : undefined;
  }
}
export function Dnd5eActionEffectSignal(signal: MutableRef<Dnd5eActionEffect, Dnd5eActionEffectOperation[]>) {
  return {
    name: PropertyRef<Dnd5eActionEffect, Dnd5eActionEffectOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(signal),
    enabled: PropertyRef<Dnd5eActionEffect, Dnd5eActionEffectOperation, boolean, BooleanOperation>(
      value => value.enabled,
      operations => [{type: "update-enabled", operations}]
    )(signal),
    modifiers: ListPropertyRef<Dnd5eActionEffect, Dnd5eActionEffectOperation, Dnd5eActionModifier, Dnd5eActionModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(signal)
  };
}

export function getActionEffectID(value: Dnd5eActionEffect): Dnd5eActionEffectID {
  return value.actionEffectID;
}
