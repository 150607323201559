import {SquareGridView} from "./square-grid-view.tsx";
import {HexagonHorizontalGridView} from "./hexagon-horizontal-grid-view.tsx";
import {HexagonVerticalGridView} from "./hexagon-vertical-grid-view.tsx";
import {IsometricGridView} from "./isometric-grid-view.tsx";
import {GridNode, NodeId} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import React from "react";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {useGrid} from "./grid-context.ts";
import {NodeView} from "../node-view.tsx";

export type GridNodeViewProps = {
  value: GridNode;
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
};
export function GridNodeView({value, projection, model, view, visibilityMask, accessNodeIDs, opacity}: GridNodeViewProps) {
  const grid = useGrid();
  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;

  const valueOpacity = opacity;
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    {grid.shape === "square" && <SquareGridView value={value} projection={projection} model={valueModel} view={view} />}
    {grid.shape === "hexagon-horizontal" && <HexagonHorizontalGridView value={value} projection={projection} model={valueModel} view={view} />}
    {grid.shape === "hexagon-vertical" && <HexagonVerticalGridView value={value} projection={projection} model={valueModel} view={view} />}
    {grid.shape === "isometric" && <IsometricGridView value={value} projection={projection} model={valueModel} view={view} />}

    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
    })}
  </binder>);
}