import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValuePropertyRef, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MathExpression} from "#common/math/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eMaxHPModifier = z.object({
  modifierID: Dnd5eModifierID,
  expression: MathExpression
});
export type Dnd5eMaxHPModifier = z.infer<typeof Dnd5eMaxHPModifier>;

export const Dnd5eMaxHPModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))})
]);
export type Dnd5eMaxHPModifierOperation = z.infer<typeof Dnd5eMaxHPModifierOperation>;

export const dnd5eMaxHPModifierType: Type<Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation> = new ObjectType({
  modifierID: constantType,
  expression: new ValueType(constantType)
});

export function Dnd5eMaxHPModifierSignals(value: MutableRef<Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation[]>) {
  return ({
    expression: ValuePropertyRef<Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation, MathExpression, ConstantOperation>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value)
  });
}

