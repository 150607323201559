import {Apply} from "../../apply-action.ts";
import {ValueOperation} from "common/types/index.ts";
import {useCallback} from "react";

export function useSetValueCallback<Type, Operation>(apply: Apply<Type, ValueOperation<Type, Operation>[]>): (nextValue: Type) => void {
  return useCallback((nextValue: Type) =>
      apply((prevValue: Type): ValueOperation<Type, Operation>[] =>
        [{type: "set", prevValue, nextValue}]
      ),
    [apply]
  );
}
