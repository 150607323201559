import skillUntrained from "./skill-proficiency-untrained.svg";
import skillProficient from "./skill-proficiency-proficient.svg";
import skillExpertise from "./skill-proficiency-expertise.svg";
import {Dnd5eAbilityCheckProficiency, ABILITY_CHECK_PROFICIENCY_TITLE} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-ability-check-proficiency.ts";

export type SkillProficiencyProps = {
  value: Dnd5eAbilityCheckProficiency;
  onChange?: (nextValue: Dnd5eAbilityCheckProficiency) => void;
};
export function SkillProficiency({value, onChange}: SkillProficiencyProps) {
  return <div title={ABILITY_CHECK_PROFICIENCY_TITLE[value]} className="shrink-0 basis-6" onClick={onChange ? () => {
    switch (value) {
      case "untrained": return onChange("proficient");
      case "proficient": return onChange("expertise");
      case "expertise": return onChange("untrained");
    }
  } : undefined}>
    <img width={20} height={20} alt={value} src={value === "untrained" ? skillUntrained : (value === "proficient" ? skillProficient : skillExpertise)} />
  </div>
}