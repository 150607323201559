import {z} from "zod";
import {NodeID, UserID} from "#common/legends/index.ts";
import {
  FileReference,
  ConstantOperation,
  constantType,
  Encrypted,
  ObjectType,
  Optional,
  RichText,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {RollRequestID, RollRequests, RollResults} from "#common/qlab/index.ts";
import {Dnd5eDamageType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";

export const Dnd5eFeatureMessage = z.object({
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  title: Encrypted(z.string()),
  damageRolls: z.array(z.object({
    damageType: z.optional(Dnd5eDamageType),
    hitDamageRollID: RollRequestID
  })),
  description: z.optional(Encrypted(RichText)),
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
});
export type Dnd5eFeatureMessage = z.infer<typeof Dnd5eFeatureMessage>;

export type Dnd5eFeatureMessageOperation =
  | {type: "update-user-i-d", operations: ConstantOperation[]}
  | {type: "update-icon", operations: ConstantOperation[]}
  | {type: "update-node-i-d", operations: ConstantOperation[]}
  | {type: "update-title", operations: ConstantOperation[]}
  | {type: "update-damage-rolls", operations: ConstantOperation[]}
  | {type: "update-description", operations: ConstantOperation[]}
  | {type: "update-roll-requests", operations: ConstantOperation[]}
  | {type: "update-roll-results", operations: ValueOperation<Optional<Encrypted<RollResults>>, ConstantOperation>[]}
  ;
export const dnd5eFeatureMessageType: Type<Dnd5eFeatureMessage, Dnd5eFeatureMessageOperation> = new ObjectType({
  userID: constantType,
  icon: constantType,
  nodeID: constantType,
  title: constantType,
  damageRolls: constantType,
  description: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType)
});
