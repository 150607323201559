import {Dnd5eAction, Dnd5eActionID, Dnd5eActionOperation, dnd5eActionType, generateDnd5eActionID, getActionID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {ListOperation, ListSignal} from "common/types/index.ts";
import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import React, {useCallback, useState} from "react";
import {FaDiceD20} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useRollAction} from "../../dnd-5e-character/dnd-5e-action/use-roll-action.ts";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {Dnd5eActionEditor} from "./dnd-5e-action-editor.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useActionByID} from "./use-action-by-i-d.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {copyDnd5eAction} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action.ts";
import {MathExpressionFn} from "common/math/index.ts";
import {usePresent} from "../../../../../common/use-optional-signal.ts";


type Dnd5eActionListItemProps = InputListItemProps<Dnd5eAction, Dnd5eActionOperation>;
function Dnd5eActionListItem({item, remove}: Dnd5eActionListItemProps) {
  const action = useRefValue(item);
  const [edit, setEdit] = useState(false);
  const sheet = useSheetSignal(useSheetReference());
  const rollAction = useRollAction(useSelectedNodeID(), sheet!, item);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action", item, remove);
  return <div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    <ButtonBar className="rounded-none" key={action.actionID}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1 cursor-pointer" onClick={() => setEdit(true)}>
        <InputGroupLabel>{action.label}</InputGroupLabel>
      </InputGroup>
      <IconButton size="small" title="Roll" onClick={ev => rollAction(ev.shiftKey, ev.ctrlKey)}><FaDiceD20/></IconButton>
    </ButtonBar>
    {edit && <Dnd5eActionEditor value={item} onClose={() => setEdit(false)} remove={remove}/>}
  </div>;
}

export type Dnd5eActionsViewProps = {
  value: ListSignal<Dnd5eAction, Dnd5eActionOperation>;
  label?: string;
  className?: string;
};
export function Dnd5eActionsView({value, label = "Actions", className}: Dnd5eActionsViewProps) {
  const [editActionID, setEditActionID] = useState<Dnd5eActionID | undefined>(undefined);

  const addAction = useCallback(() => {
    const actionID = generateDnd5eActionID();
    value.apply(prev => ListOperation.insert(prev.length, {
      actionID: actionID,
      criticalRange: MathExpressionFn.assertMathExpression("20"),
      favorite: false,
      label: "Attack",
      description: [{children: [{text: ""}]}],
      actionType: "action",
      modifiers: [],
      proficient: true,
      offense: "str",
      defense: "ac",
      actionEffects: [],
      range: "5 ft.",
      onHit: "",
      onCrit: "",
      onMiss: ""
    })).then(() => setEditActionID(actionID));
  }, [value, setEditActionID]);

  const editSignal = usePresent(useActionByID(value, editActionID));
  const remove = useCallback(() => value.apply(prev => {
    if (editActionID === undefined) return [];
    const index = prev.findIndex(item => item.actionID === editActionID);
    if (index === -1) return [];
    return ListOperation.delete(index, prev[index]);
  }), [value, editActionID]);

  return <div className={twMerge("flex flex-col gap-1", className)}>
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1 items-center">{label}</SectionHeader>
      <Button onClick={addAction}><FaPlus/> New Action</Button>
      <ExpandOptions>
        <ImportButton type={dnd5eActionType} onImport={(next) => {
          value.apply(prev => ListOperation.insert(prev.length, copyDnd5eAction(next)));
        }}>Import Action</ImportButton>
      </ExpandOptions>
    </div>

    <InputList<Dnd5eAction, Dnd5eActionOperation>
      accept="legends/action"
      items={value}
      itemKey={getActionID}
      copy={copyDnd5eAction}
      ListItem={Dnd5eActionListItem} />

    {editSignal && <Dnd5eActionEditor value={editSignal} onClose={() => setEditActionID(undefined)} remove={remove} />}
  </div>
}
