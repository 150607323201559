import {
  ConstantOperation,
  constantType,
  MapOperation,
  MapPropertyRef,
  MapType,
  NumberOperation,
  numberType,
  ObjectType,
  Optional,
  Type,
  ValueFn,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {z} from "zod";
import {Turn, TurnOperation, turnType} from "./turn.ts";
import {ulid} from "ulid";
import {MutableRef} from "#common/ref";

export const TurnID = z.string().brand("TurnID");
export type TurnID = z.infer<typeof TurnID>;

export const TurnTracker = z.object({
  round: z.number(),
  currentTurnID: z.optional(TurnID),
  turns: z.record(TurnID, Turn)
});
export type TurnTracker = z.infer<typeof TurnTracker>;

export const TurnTrackerOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-round"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-current-turn-i-d"), operations: z.array(ValueOperation(z.optional(TurnID), ConstantOperation))}),
  z.object({type: z.literal("update-turns"), operations: z.array(MapOperation(TurnID, Turn, TurnOperation))})
]);
export type TurnTrackerOperation = z.infer<typeof TurnTrackerOperation>;

export const turnTrackerType: Type<TurnTracker, TurnTrackerOperation> = new ObjectType({
  round: numberType,
  currentTurnID: new ValueType<Optional<TurnID>, ConstantOperation>(constantType),
  turns: new MapType<TurnID, Turn, TurnOperation>(turnType)
});

export function generateTurnID() {
  return ulid();
}


export const TurnTrackerFn = {
  orderedTurnIDs(turns: TurnTracker["turns"]): TurnID[] {
    return (Object.keys(turns) as TurnID[]).sort((a,b) => {
      const aOrder = turns[a]!.data.order;
      const bOrder = turns[b]!.data.order;
      if (aOrder === bOrder) return a.localeCompare(b);
      return bOrder - aOrder;
    });
  },
  isInRound(orderedTurnIDs: TurnID[], currentTurnID: Optional<TurnID>): (turnID: TurnID) => boolean {
    return (turnID: TurnID) => {
      if (currentTurnID === undefined) return true;
      return orderedTurnIDs.indexOf(turnID) >= orderedTurnIDs.indexOf(currentTurnID);
    };
  },
  isNextRound(orderedTurnIDs: TurnID[], currentTurnID: Optional<TurnID>): (turnID: TurnID) => boolean {
    const isInRound = TurnTrackerFn.isInRound(orderedTurnIDs, currentTurnID);
    return (turnID) => !isInRound(turnID);
  }
};

export function TurnTrackerSignals(value: MutableRef<TurnTracker, ValueOperation<TurnTracker, TurnTrackerOperation>[]>) {
  return {
    turns: MapPropertyRef<TurnTracker, ValueOperation<TurnTracker, TurnTrackerOperation>, TurnID, Turn, TurnOperation>(
      value => value.turns,
      operations => ValueFn.apply([{type: "update-turns", operations}])
    )(value)
  };
}
