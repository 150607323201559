import {distinct, map, Observable} from "common/observable";
import {pipe} from "common/pipe";
import {SelectionRef} from "./selection-ref.ts";
import {EditorState} from "./editor-state.ts";

export function selectionRefObservable(editorState: Observable<EditorState>): Observable<SelectionRef | undefined> {
  return pipe(
    editorState,
    map((state): SelectionRef | undefined => {
      if (state?.type === "asset") {
        if (state.data.selectedNodeIds.length === 1) return state.data.selectedNodeIds[0];
        const tokenReference = state.data.tokenReference;
        return {type: "asset-token", storeId: tokenReference.gameID, resourceId: tokenReference.assetID, tokenId: tokenReference.tokenID};
      } else if (state?.type === "scene") {
        if (state.data.selectedNodeIds.length === 1) return state.data.selectedNodeIds[0];
        const sceneReference = state.data.sceneReference;
        return {type: "scene", storeId: sceneReference.gameID, resourceId: sceneReference.sceneID};
      } else {
        return undefined;
      }
    }),
    distinct(SelectionRef.equals)
  );
}
