import {z} from "zod";

export const Dnd5eAttribute = z.union([
  z.literal("str"),
  z.literal("dex"),
  z.literal("con"),
  z.literal("int"),
  z.literal("wis"),
  z.literal("cha")
]);
export type Dnd5eAttribute = z.infer<typeof Dnd5eAttribute>;

export const DND_5E_ATTRIBUTES = [
  "str", "dex", "con", "int", "wis", "cha"
] satisfies Dnd5eAttribute[];

export const Dnd5eAttributeFn = {
  modifier: (value: number) => {
    return Math.floor((value - 10) / 2);
  }
} as const;

export function isDnd5eAttribute(value: string): value is Dnd5eAttribute {
  return DND_5E_ATTRIBUTES.includes(value as Dnd5eAttribute);
}

export const DND_5E_ATTRIBUTE_TITLE: {[attribute in Dnd5eAttribute]: string} = {
  "str": "Strength",
  "dex": "Dexterity",
  "con": "Constitution",
  "int": "Intelligence",
  "wis": "Wisdom",
  "cha": "Charisma"
};
