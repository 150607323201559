import ReactReconciler from "react-reconciler";
import * as ReactGLHostConfig from "./web-g-l-2-host-config.ts";

export const WebGL2ReactReconciler = ReactReconciler(ReactGLHostConfig);
WebGL2ReactReconciler.injectIntoDevTools({
  bundleType: process.env.NODE_ENV === 'production' ? 0 : 1,
  rendererPackageName: 'WebGL2',
  version: '0.0.2',
  findFiberByHostInstance: WebGL2ReactReconciler.findHostInstance
});

