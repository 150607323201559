import {ArrayBufferToBase64Codec, Base64Codec} from "#common/codec";
import {VerifyPublicKey} from "./public-key.ts";

export async function verify(body: string, signature: string, publicKey: VerifyPublicKey): Promise<boolean> {
  // @ts-ignore
  const cryptoKey = await crypto.subtle.importKey(
    "jwk",
    JSON.parse(Base64Codec.decode(publicKey)),
    {name: "RSA-PSS", hash: {name: "SHA-256"}},
    false,
    ["verify"]
  );

  const data = new TextEncoder().encode(body);
  // @ts-ignore
  return crypto.subtle.verify(
    {name: "RSA-PSS", saltLength: 32},
    cryptoKey,
    ArrayBufferToBase64Codec.decode(signature),
    data
  );
}
