import {applyAll, invertAll, transformAll, Type} from "../../type/index.ts";
import {SizeOperation} from "./size-operation.ts";
import {Size} from "./size.ts";
import {numberType} from "../number/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const sizeType: Type<Size, SizeOperation> = {
  apply: (value: Size, operation: SizeOperation): Size => {
    switch (operation.type) {
      case "update-width": return [
        applyAll(numberType, value[0], operation.operations),
        value[1]
      ];
      case "update-height": return [
        value[0],
        applyAll(numberType, value[1], operation.operations)
      ];
    }
  },

  invert: (operation: SizeOperation): SizeOperation[] => {
    switch (operation.type) {
      case "update-width": return [{type: "update-width", operations: invertAll(numberType, operation.operations)}];
      case "update-height": return [{type: "update-height", operations: invertAll(numberType, operation.operations)}];
    }
  },

  transform(leftOperation: SizeOperation, topOperation: SizeOperation, tieBreaker: boolean): SizeOperation[] {
    if (leftOperation.type === "update-width" && topOperation.type === "update-width") {
      return [{
        type: "update-width",
        operations: transformAll(numberType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
      }];
    } else if (leftOperation.type === "update-height" && topOperation.type === "update-height") {
      return [{
        type: "update-height",
        operations: transformAll(numberType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
      }];
    } else {
      return [leftOperation];
    }
  },
  migrateValue: (value: any): Size => {
    return value;
  },
  migrateOperation: (operation: any): SizeOperation[] => {
    return [operation]
  },
  validate(value: any): ValidationError[] {
    if (!Array.isArray(value) || value.length !== 2) return [{path: [], data: {message: "Invalid type. Expected Size.", value}}];
    return value.flatMap((value, index) => numberType.validate(value).map(error => ({
      path: [index, ...error.path],
      data: error.data
    })));
  }
}