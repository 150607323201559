import React, {useContext} from "react";

const ResolutionContext = React.createContext<[number, number] | undefined>(undefined);

export const ResolutionProvider = ResolutionContext.Provider;

export function useResolution(): [number, number] {
  const resolutionContext = useContext(ResolutionContext);
  if (!resolutionContext) {
    throw new Error("Resolution Context does not exist.");
  }
  return resolutionContext;
}
