function numberToHex(value: number) {
  return value.toString(16).padStart(2, '0');
}

export function digest(value: string): Promise<string> {
  //@ts-ignore
  return crypto.subtle.digest(
    {name: "SHA-512"},
    new TextEncoder().encode(value)
  )
    //@ts-ignore
    .then(hash => Array.from(new Uint8Array(hash)))
    //@ts-ignore
    .then(hash => hash.map(numberToHex).join(''));
}
