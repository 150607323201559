import {Dnd5eFeature, Dnd5eFeatureOperation, dnd5eFeatureType} from "../dnd-5e-feature/index.ts";
import {z} from "zod";
import {ListOperation, ListType, ObjectType, StringOperation, stringType, Type, ValueFn, ValueOperation} from "#common/types/index.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eBackground = z.object({
  name: z.string(),
  features: z.array(Dnd5eFeature)
});
export type Dnd5eBackground = z.infer<typeof Dnd5eBackground>;
export type Dnd5eBackgroundOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-features", operations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]}
  ;

export const dnd5eBackgroundType: Type<Dnd5eBackground, Dnd5eBackgroundOperation> = new ObjectType({
  name: stringType,
  features: new ListType(dnd5eFeatureType)
});

export function Dnd5eBackgroundSignal(signal: MutableRef<Dnd5eBackground, ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>[]>) {
  return {
    name: PropertyRef<Dnd5eBackground, ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>, string, StringOperation>(
      value => value.name,
      (operations) => ValueFn.apply([{type: "update-name", operations}])
    )(signal),
    features: ListPropertyRef<Dnd5eBackground, ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>, Dnd5eFeature, Dnd5eFeatureOperation>(
      value => value.features,
      (operations) => ValueFn.apply([{type: "update-features", operations}])
    )(signal)
  };
}
