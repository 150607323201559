import React, {PropsWithChildren} from "react";
import {FaTimes} from "react-icons/fa";

type ErrorBoundaryState = {
  hasError: boolean
};

export class ErrorBoundary extends React.Component<PropsWithChildren<{
  fallback?: JSX.Element
}>, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return <div className="flex flex-row gap-2 px-4 py-4 items-center justify-center bg-white w-full h-full">
        <FaTimes className="text-red-700" />
        <span className="text-sm italic text-black">An error has occurred.</span>
      </div>;
    } else {
      return this.props.children;
    }
  }
}
