import {FaPlus} from "react-icons/fa";
import {Button} from "#lib/components/index.ts";
import React, {useState} from "react";
import {IconType} from "react-icons";
import {usePopper} from "react-popper";
import {Popper} from "#lib/components/popper/popper.tsx";
import {Popover} from "@headlessui/react";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";

export type AddToStackButtonProps<LV> = {
  Stacks: {[type: string]: {Icon: IconType, label: string, defaultContent: LV}};
  onAddStackItem: (value: LV) => void
};

export function AddToStackButton<LV>({Stacks, onAddStackItem}: AddToStackButtonProps<LV>) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  return (<Popover className="relative">
    <Popover.Button as={"button"} ref={ref => setReferenceElement(ref)} className="text-white/50 hover:text-white basis-[24px] min-w-[24px] h-[24px] w-[24px] justify-center items-center flex hover:bg-zinc-300/20 hover:rounded-full m-1 outline-none" title="Add Panel">
      <FaPlus size={12}/>
    </Popover.Button>
    <Popover.Panel as={ExternalPortal}>
      {({close}) => <Popper ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="m-1">
        {Object.entries(Stacks).map(([stackType, stack]) => {
          const TabIcon = stack.Icon;
          return <Button key={stackType} className={`flex justify-start gap-2 px-4 py-1 font-bold h-[32px]`} size={"small"} onClick={() => {
            onAddStackItem(stack.defaultContent);
            close();
          }}>
            <TabIcon />
            <span>{stack.label}</span>
          </Button>
        })}
      </Popper>}
    </Popover.Panel>
  </Popover>);
}
