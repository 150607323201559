import {useCallback} from "react";
import {defaultGridNode, Game, GameID, generateGameID, generateSceneID, Grid} from "common/legends/index.ts";
import environment from "#env";
import {useCreateStore, useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {HSLA} from "common/types/index.ts";

export function useCreateGame(): (name: string) => Promise<GameID> {
  const userId = useUserID()!;
  const createStore = useCreateStore();
  const instance = useInstance();
  return useCallback(async (name: string): Promise<GameID> => {
    const user = await instance.getStore(userId);
    if(user?.type !== "user") return Promise.reject("Invalid User");
    const publicKey = user.data.publicKey;

    const gameID = generateGameID();
    const sceneID = generateSceneID();
    await createStore("game", gameID, {
      owner: {
        id: userId,
        name: "Game Master",
        publicKey,
        color: [0, 0, 1, 1] as HSLA,
        icon: undefined
      },
      name,
      defaultSceneID: undefined,
      scenes: [{
        type: "scene",
        data: {
          id: sceneID,
          name: "Default Scene",
          tags: []
        }
      }],
      assets: [],
      invitations: [],
      players: {},
      bots: {
        [environment.botId]: {
          name: "Dice Bot",
          endpoint: environment.botEndpoint,
          publicKey: environment.botPublicKey,
          subscriptions: [{path: "/message/:messageId", messageId: "*", type: "*"}]
        }
      },
      turnTracker: {
        round: 0,
        turns: {},
        currentTurnID: undefined
      },
      systems: {
        dND5e: {
          globalFeatures: []
        }
      },
      xCard: false
    } satisfies Game);
    await instance.applyToResource(gameID, sceneID, _ => [{type: "set", prevValue: undefined, nextValue: {
      type: "scene",
      data: {
        grid: Grid.DEFAULT,
        name: "Scene",
        children: [{
          type: "grid",
          data: defaultGridNode()
        }],
        backgroundColor: [0, 0, 0.5, 1] as HSLA
      }
    }}]);
    return gameID;
  }, [instance, createStore, userId]);
}
