import {z} from "zod";

export const Dnd5eWeaponCategory = z.union([
  z.literal("simple"),
  z.literal("martial")
]);
export const DND_5E_WEAPON_CATEGORIES = [
  "simple",
  "martial"
] as const;
export type Dnd5eWeaponCategory = typeof DND_5E_WEAPON_CATEGORIES[number];
export const Dnd5eWeapon = z.string();
export type Dnd5eWeapon = string;

