import {Transform, TransformOperation} from "common/types/index.ts";
import {TransformOptions} from "./transform-options.tsx";
import {ExpandableLabel, useToggle} from "#lib/components/index.ts";
import React from "react";
import {MutableRef} from "common/ref";

export type TransformFieldProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function InputTransform({value}: TransformFieldProps) {
  const [expand, toggleExpand] = useToggle(false);
  return (<div className="flex flex-col gap-0">
    <ExpandableLabel className="w-full" expanded={expand} toggleExpand={toggleExpand}>
      <span className="flex-1 px-4 cursor-pointer" onClick={toggleExpand}>Transform</span>
    </ExpandableLabel>
    {expand && <TransformOptions value={value} />}
  </div>);
}