import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {Ref, useMemo} from "react";
import {FaTrash} from "react-icons/fa";
import {InteractionActionDismount, InteractionActionDismountFn, InteractionActionDismountOperation} from "common/legends/node/interaction/action/interaction-action-dismount.ts";
import {MutableRef} from "common/ref";

export function InputInteractionActionDismount({dragHandlerRef, value, remove}: {
  value: MutableRef<InteractionActionDismount, InteractionActionDismountOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  remove: () => void;
}) {
  const {} = useMemo(() => InteractionActionDismountFn.expand(value), [value]);
  return <>
    <ExpandableLabel expanded={false}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1">
        <InputGroupLabel>Dismount</InputGroupLabel>
      </InputGroup>
      <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>
    </ExpandableLabel>
  </>;
}