import {QLabAsset, AssetType} from "common/qlab/index.ts";

export function fileToAsset(file: File): Promise<[string, QLabAsset]> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend  = () => {
      const result = (fileReader.result as string);
      resolve([file.name, {
        type: file.type as AssetType,
        data: result.substring(result.indexOf(",")+1)
      }]);
    }
    fileReader.onerror = error => reject(error);
    fileReader.readAsDataURL(file);
  });
}
