import {authTokenRef} from "./auth-client.ts";
import {UserID} from "common/legends/index.ts";
import {Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

function getUserID(token: string): UserID;
function getUserID(token: string): UserID | null {
  const [_encodedHeader, encodedPayload, _encodedSignature] = token.split(".");
  const payload = JSON.parse(atob(encodedPayload));
  return payload.properties.userID;
}
function userIDFn(token: string | undefined) {
  return token !== undefined ? getUserID(token) : undefined;
}

export const userIDRef: Ref<UserID | undefined> = authTokenRef.map(userIDFn);
export function useUserID(): UserID | undefined {
  return useRefValue(userIDRef);
}
