import {StackId} from "./stack-id.ts";
import {StackItem, StackItemOperation, StackItemType} from "./stack-item/index.ts";
import {
  ConstantOperation,
  ConstantType,
  ListOperation,
  ListType,
  ObjectType,
  Optional,
  Type,
  ValueOperation,
  ValueType
} from "common/types/index.ts";

export type Stack<Value> = {
  type: "stack";
  activeId: StackId | undefined;
  items: StackItem<Value>[];
};

export type StackOperation<Value, Operation> =
  | {type: "update-type", operations: ConstantOperation[]}
  | {type: "update-active-id", operations: ValueOperation<Optional<StackId>, ConstantOperation>[]}
  | {type: "update-items", operations: ListOperation<StackItem<Value>, StackItemOperation<Value, Operation>>[]}
  ;

export class StackType<Value, Operation> extends ObjectType<{
  type: ConstantType<"stack">,
  activeId: ValueType<StackId | undefined, undefined>,
  items: ListType<StackItem<Value>, StackItemOperation<Value, Operation>>
}> implements Type<Stack<Value>, StackOperation<Value, Operation>> {
  constructor(type: Type<Value, Operation>) {
    super({
      type: new ConstantType(),
      activeId: new ValueType(new ConstantType()),
      items: new ListType(new StackItemType(type))
    });

    let oldApply = this.apply;
    this.apply = (value: Stack<Value>, operation: StackOperation<Value, Operation>): Stack<Value> => {
      let newValue = oldApply(value, operation);
      if (!newValue.items.some(item => item.id === newValue.activeId) && newValue.items.length > 0) {
        newValue = {...newValue, activeId: newValue.items[0].id};
      }
      return newValue;
    }
  }
}

export const StackFn = {
  updateItems<V, O>(operations: ListOperation<StackItem<V>, StackItemOperation<V, O>>[]): StackOperation<V, O>[] {
    return [{type: "update-items", operations}]
  }
};

export const Stack = {
  isEmpty: <T>(value: Stack<T>): boolean => {
    return value.items.length === 0;
  }
}
