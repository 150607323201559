import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaShieldAlt} from "react-icons/fa";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "./dnd-5e-action/use-global-features.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useMemo} from "react";
import {SheetInterface} from "common/legends/index.ts";
import {MutableRef} from "common/ref";

export type ArmorClassProps = {
};
export function ArmorClass({}: ArmorClassProps) {
  const sheetRef = useSheetSignal(useSheetReference());
  const globalFeaturesRef = useGlobalFeatures();
  const armorClass = useRefValue(useMemo(() => MutableRef.all(sheetRef, globalFeaturesRef).map(
    ([sheet, globalFeatures]) => SheetInterface.getAC(sheet, globalFeatures)
  ).distinct(), [sheetRef, globalFeaturesRef]));

  return (<BaseComponent><ButtonBar>
    <InputGroup title="Armor Class">
      <InputGroupIcon><FaShieldAlt /></InputGroupIcon>
      <InputGroupLabel>Armor Class</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>{armorClass}</InputGroupLabel>
    </InputGroup>
  </ButtonBar></BaseComponent>);
}