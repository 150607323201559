import {GenIcon} from "react-icons";
import {IconBaseProps} from "react-icons";

export const IsometricGridTypeIcon = (props: IconBaseProps) => GenIcon({
  tag: "svg",
  attr: {
    viewBox: "0 0 1500 1500",
    width: "1500",
    height: "1500"
  },
  child: [{
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "96px"},
      d: "M 750,562.5 1125,750 750,937.5 375,750 Z"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,187.5 750,562.5 1500,187.5"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 1500,562.5 1125,750 1500,937.5"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 0,937.5 375,750 0,562.5"
    },
    child: []
  }, {
    tag: "path",
    attr: {
      /** @ts-ignore */
      style: {fill: "none", strokeWidth: "32px"},
      d: "M 1500,1312.5 750,937.5 0,1312.5"
    },
    child: []
  }

    , {
      tag: "path",
      attr: {
        /** @ts-ignore */
        style: {fill: "none", strokeWidth: "8px"},
        d: "M 375,0 1500,562.5"
      },
      child: []
    }, {
      tag: "path",
      attr: {
        /** @ts-ignore */
        style: {fill: "none", strokeWidth: "8px"},
        d: "M 1500,937.5 375,1500"
      },
      child: []
    }, {
      tag: "path",
      attr: {
        /** @ts-ignore */
        style: {fill: "none", strokeWidth: "8px"},
        d: "M 1125,1500 0,937.5"
      },
      child: []
    }, {
      tag: "path",
      attr: {
        /** @ts-ignore */
        style: {fill: "none", strokeWidth: "8px"},
        d: "M 0,562.5 1125,0"
      },
      child: []
    }]
})(props);
