import {FaAlignCenter, FaAlignLeft, FaAlignRight} from "react-icons/fa6";
import {Button, ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {HorizontalTextAlign} from "common/legends/node/index.ts";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function InputHorizontalAlignment({value}: {value: MutableRef<HorizontalTextAlign, ValueOperation<HorizontalTextAlign, ConstantOperation>[]>}) {
  const currentValue = useRefValue(value);

  const onSetLeft = () => value.apply(prev => ValueFn.set(prev, "left"));
  const onSetCenter = () => value.apply(prev => ValueFn.set(prev, "center"));
  const onSetRight = () => value.apply(prev => ValueFn.set(prev, "right"));

  return (
    <InputGroup className="flex-1">
      <InputGroupLabel>Horizontal Alignment</InputGroupLabel>
      <ButtonBar>
        <Button variant={currentValue === "left" ? "primary" : undefined} title="Align Left" onClick={onSetLeft}><FaAlignLeft /></Button>
        <Button variant={currentValue === "center" ? "primary" : undefined} title="Align Center" onClick={onSetCenter}><FaAlignCenter /></Button>
        <Button variant={currentValue === "right" ? "primary" : undefined} title="Align Right" onClick={onSetRight}><FaAlignRight /></Button>
      </ButtonBar>
    </InputGroup>
  );
}