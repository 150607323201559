import {SheetInterface} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {SheetReference} from "./sheet-reference.ts";
import {useSheetSignal} from "./use-sheet-signal.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {Ref} from "common/ref";

export function useSheetName(sheetReference: Ref<Optional<SheetReference>>): string | undefined {
  const sheet = useSheetSignal(sheetReference);
  return useComputedValue(sheet, sheet => sheet ? SheetInterface.getName(sheet) : undefined);
}
