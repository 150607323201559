import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {Button, ButtonBar, Checkbox, InputGroup, InputGroupLabel, Spacer, useToggle} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {
  Dnd5eSpell,
  Dnd5eSpellFn,
  Dnd5eSpellID,
  Dnd5eSpellLevel,
  Dnd5eSpellOperation,
  Dnd5eSpellSignal,
  dnd5eSpellType,
  DND_5E_SPELL_LEVEL,
  generateDnd5eSpellID
} from "common/legends/index.ts";
import {BooleanFn, ListOperation, ListSignal} from "common/types/index.ts";
import {Dnd5eSpellEditor} from "./dnd-5e-spell-editor.tsx";
import React, {useCallback, useMemo, useState} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {useListSignals} from "../../../../../common/use-list-signals.ts";
import {MutableRef} from "common/ref";


type InputDnd5eSpellProps = {
  value: MutableRef<Dnd5eSpell, Dnd5eSpellOperation[]>;
  remove: () => void;
};

function InputDnd5eSpell({value, remove}: InputDnd5eSpellProps) {
  const {name, level, prepared, concentration, ritual} = useMemo(() => Dnd5eSpellSignal(value), [value]);

  const nameValue = useRefValue(name);
  const concentrationValue = useRefValue(concentration);
  const ritualValue = useRefValue(ritual);
  const levelValue = useRefValue(level);
  const preparedValue = useRefValue(prepared);

  const tags = [];
  if (ritualValue) tags.push("ritual");
  if (concentrationValue) tags.push("concentration");

  const setPrepared = useCallback((next: boolean) => prepared.apply(prev => BooleanFn.set(prev, next)), [prepared]);
  const [expanded, toggleExpanded] = useToggle(false);
  return (<tr>
    <td>
      <InputGroup className="flex-1 justify-center">
        <Checkbox checked={preparedValue} onChange={ev => setPrepared(ev.target.checked)} />
      </InputGroup>
    </td>
    <td onClick={() => toggleExpanded()}>
      <InputGroup className="cursor-pointer">
        <InputGroupLabel>{nameValue}</InputGroupLabel>
        <Spacer/>
        <InputGroupLabel disabled className="text-sm">{tags.length > 0 ? <em>{tags.join(", ")}</em> : ""}</InputGroupLabel>
      </InputGroup>
    </td>
    <td>
      <InputGroup>
        <InputGroupLabel>{levelValue === "cantrip" ? "Cantrip" : `Level ${levelValue}`}</InputGroupLabel>
      </InputGroup>
    </td>
    {expanded && <Dnd5eSpellEditor value={value} onClose={toggleExpanded} remove={remove} />}
  </tr>);
}

export type InputSpellsProps = {
  value: ListSignal<Dnd5eSpell, Dnd5eSpellOperation>;
}

export function InputDnd5eSpells({value}: InputSpellsProps) {
  const [spellEditor, setSpellEditor] = useState<Dnd5eSpellID | undefined>(undefined);

  const deleteSpell = useCallback((spellID: Dnd5eSpellID) => {
    value.apply(prev => {
      const i = prev.findIndex(s => s.spellID === spellID);
      if (i === -1) return [];
      return ListOperation.delete(i, prev[i]);
    });
  }, [value]);

  const createNewSpell = useCallback((level: Dnd5eSpellLevel) => {
    const spellID = generateDnd5eSpellID();
    value.apply(prev => ListOperation.insert(prev.length, {
      spellID,
      level: level,
      name: "",
      description: [{children: [{text: ""}]}],
      prepared: true,
      duration: "",
      royaltyComponent: "",
      materialComponent: "",
      range: "",
      concentration: false,
      castingTime: "1 action",
      components: [],
      ritual: false,
      school: "evocation",
      actions: []
    }));
    setSpellEditor(spellID);
  }, [value]);
  const spellsSignals = useListSignals(value, Dnd5eSpellFn.getSpellID, Dnd5eSpellFn.copySpell);
  const spellsSignalsByKey = useMemo(() => Object.fromEntries(spellsSignals), [spellsSignals]);
  const spellEditorSignal = useMemo(() => spellEditor ? spellsSignalsByKey[spellEditor] : undefined, [spellsSignalsByKey, spellEditor])

  return (
    <BaseComponent>
      <div className="flex flex-row gap-0.5">
        <SectionHeader className="flex-1">Spells</SectionHeader>
        <ButtonBar>
          <Button onClick={() => createNewSpell("cantrip")}><FaPlus/> New Spell</Button>
          <ExpandOptions>
            <ImportButton type={dnd5eSpellType} onImport={(next) => {
              value.apply(prev => ListOperation.insert(prev.length, next));
            }}>Import Spell</ImportButton>
          </ExpandOptions>
        </ButtonBar>
      </div>
      <div className="flex flex-col gap-0.5">
        <table className="table-auto border-separate border-spacing-0.5 border-0">
          <thead className="h-10">
          <tr>
            <th className="text-center bg-zinc-900/50 text-h300 px-4">Prepared</th>
            <th className="text-left bg-zinc-900/50 text-h400 px-4 w-full">Name</th>
            <th className="text-center bg-zinc-900/50 text-h300 px-4">Level</th>
          </tr>
          </thead>
          <tbody>
          {[...spellsSignals]
            .sort((a, b) => {
              if (DND_5E_SPELL_LEVEL.indexOf(a[1].itemRef.value.level) > DND_5E_SPELL_LEVEL.indexOf(b[1].itemRef.value.level)) return 1;
              if (DND_5E_SPELL_LEVEL.indexOf(a[1].itemRef.value.level) < DND_5E_SPELL_LEVEL.indexOf(b[1].itemRef.value.level)) return -1;
              if (a[1].itemRef.value.name > b[1].itemRef.value.name) return 1;
              if (a[1].itemRef.value.name < b[1].itemRef.value.name) return -1;
              return 0;
            })
            .map(([key, props]) => <InputDnd5eSpell key={key} value={props.itemRef} remove={props.remove}/>)}
          </tbody>
        </table>
      </div>

      {spellEditor && spellEditorSignal && <Dnd5eSpellEditor value={spellEditorSignal.itemRef} onClose={() => setSpellEditor(undefined)} remove={spellEditorSignal.remove} />}

      <ButtonBar>
        <Button className="flex-1" onClick={() => createNewSpell("cantrip")}><FaPlus/> New Spell</Button>
        <ImportButton type={dnd5eSpellType} onImport={(next) => {
          value.apply(prev => ListOperation.insert(prev.length, next));
        }}>Import Spell</ImportButton>
      </ButtonBar>
    </BaseComponent>
  );
}
