import {z} from "zod";

export const Dnd5eDamageType = z.union([
  z.literal("acid"),
  z.literal("bludgeoning (non-magical)"),
  z.literal("bludgeoning (magical)"),
  z.literal("bludgeoning (silver)"),
  z.literal("bludgeoning (adamantine)"),
  z.literal("cold"),
  z.literal("fire"),
  z.literal("force"),
  z.literal("lightning"),
  z.literal("necrotic"),
  z.literal("piercing (non-magical)"),
  z.literal("piercing (magical)"),
  z.literal("piercing (silver)"),
  z.literal("piercing (adamantine)"),
  z.literal("poison"),
  z.literal("psychic"),
  z.literal("radiant"),
  z.literal("slashing (non-magical)"),
  z.literal("slashing (magical)"),
  z.literal("slashing (silver)"),
  z.literal("slashing (adamantine)"),
  z.literal("thunder"),
  z.literal("healing"),
  z.literal("temp hp")
]);
export type Dnd5eDamageType = z.infer<typeof Dnd5eDamageType>;

export const DND_5E_DAMAGE_TYPES = [
  "acid",
  "bludgeoning (non-magical)",
  "bludgeoning (magical)",
  "bludgeoning (silver)",
  "bludgeoning (adamantine)",
  "cold",
  "fire",
  "force",
  "lightning",
  "necrotic",
  "piercing (non-magical)",
  "piercing (magical)",
  "piercing (silver)",
  "piercing (adamantine)",
  "poison",
  "psychic",
  "radiant",
  "slashing (non-magical)",
  "slashing (magical)",
  "slashing (silver)",
  "slashing (adamantine)",
  "thunder",
  "healing",
  "temp hp"
] satisfies Dnd5eDamageType[];

export const DND_5E_OFFENSIVE_DAMAGE_TYPES = DND_5E_DAMAGE_TYPES.filter(damageType => damageType !== "healing" && damageType !== "temp hp");

export function isDamageType(value: string): value is Dnd5eDamageType {
  return DND_5E_DAMAGE_TYPES.indexOf(value as Dnd5eDamageType) !== -1;
}

export const DND_5E_DAMAGE_TYPE_TITLES: {[type in Dnd5eDamageType]: string} = {
  "acid": "Acid",
  "bludgeoning (non-magical)": "Bludgeoning (non-magical)",
  "bludgeoning (magical)": "Bludgeoning (magical)",
  "bludgeoning (silver)": "Bludgeoning (silver)",
  "bludgeoning (adamantine)": "Bludgeoning (adamantine)",
  "cold": "Cold",
  "fire": "Fire",
  "force": "Force",
  "lightning": "Lightning",
  "necrotic": "Necrotic",
  "piercing (non-magical)": "Piercing (non-magical)",
  "piercing (magical)": "Piercing (magical)",
  "piercing (silver)": "Piercing (silver)",
  "piercing (adamantine)": "Piercing (adamantine)",
  "poison": "Poison",
  "psychic": "Psychic",
  "radiant": "Radiant",
  "slashing (non-magical)": "Slashing (non-magical)",
  "slashing (magical)": "Slashing (magical)",
  "slashing (silver)": "Slashing (silver)",
  "slashing (adamantine)": "Slashing (adamantine)",
  "thunder": "Thunder",
  "healing": "Healing",
  "temp hp": "Temp HP"
};
