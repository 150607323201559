import {applyAll, invertAll, transformAll, Type} from "../../type/index.ts";
import {DeleteTreeOperation, TreeOperation} from "./tree-operation.ts";
import {Tree} from "./tree.ts";
import {updateTreeValue} from "./update-tree-value.ts";
import {TreePath} from "./tree-path.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";

export class TreeType<Item extends Tree<Item>, ItemOperation> implements Type<Item[], TreeOperation<Item, ItemOperation>> {
  constructor(private readonly itemType: Type<Item, ItemOperation>, private readonly updateFn: (value: any) => Item = (v) => v) {}

  apply = (value: Item[], operation: TreeOperation<Item, ItemOperation>): Item[] => {
    switch (operation.type) {
      case "insert": {
        const [parentPath, pathIndex] = TreePath.splitParent(operation.path);
        if (parentPath.length === 0) {
          return [
            ...value.slice(0, pathIndex),
            operation.item,
            ...value.slice(pathIndex)
          ]
        } else {
          return updateTreeValue(value, parentPath, (value) => {
            if (Tree.isParentNode(value)) {
              return ({
                ...value,
                data: {
                  ...value.data,
                  children: [
                    ...value.data.children.slice(0, pathIndex),
                    operation.item,
                    ...value.data.children.slice(pathIndex)
                  ]
                }
              });
            } else throw new Error("Cannot insert at: " + JSON.stringify(parentPath));
          });
        }
      }
      case "delete": {
        const [parentPath, pathIndex] = TreePath.splitParent(operation.path);
        if (parentPath.length === 0) {
          return [
            ...value.slice(0, pathIndex),
            ...value.slice(pathIndex + 1)
          ];
        } else {
          return updateTreeValue(value, parentPath, (value) => {
            if (Tree.isParentNode(value)) {
              return ({
                ...value,
                data: {
                  ...value.data,
                  children: [
                    ...value.data.children.slice(0, pathIndex),
                    ...value.data.children.slice(pathIndex+1)
                  ]
                }
              });
            } else throw new Error("Cannot delete at: " + JSON.stringify(parentPath));
          });
        }
      }
      case "apply": {
        try {
          return updateTreeValue(value, operation.path, (itemValue) => {
            return applyAll(this.itemType, itemValue, operation.operations);
          });
        } catch (e: any) {
          throw new QLabError(e.message, e.path ? [...operation.path, ...e.path] : [...operation.path]);
        }
      }
      case "move": {
        const targetNode = Tree.getNode(value, operation.fromPath);
        if (targetNode === null) throw new Error("Could not find node.");
        const deleteNode = (value: Item[], path: TreePath): Item[] => {
          const [parentPath, pathIndex] = TreePath.splitParent(path);
          if (parentPath.length === 0) {
            return [
              ...value.slice(0, pathIndex),
              ...value.slice(pathIndex + 1)
            ];
          } else {
            return updateTreeValue(value, parentPath, (node) => {
              if (Tree.isParentNode(node)) {
                return ({
                  ...node,
                  data: {
                    ...node.data,
                    children: [
                      ...node.data.children.slice(0, pathIndex),
                      ...node.data.children.slice(pathIndex+1)
                    ]
                  }
                });
              } else throw new Error("Cannot delete at: " + JSON.stringify(parentPath));
            });
          }
        }
        const insertNode = (value: Item[], path: TreePath, newItem: Item): Item[] => {
          const [parentPath, pathIndex] = TreePath.splitParent(path);
          if (parentPath.length === 0) {
            return [
              ...value.slice(0, pathIndex),
              newItem,
              ...value.slice(pathIndex)
            ];
          } else {
            return updateTreeValue(value, parentPath, (node) => {
              if (Tree.isParentNode(node)) {
                return ({
                  ...node,
                  data: {
                    ...node.data,
                    children: [
                      ...node.data.children.slice(0, pathIndex),
                      newItem,
                      ...node.data.children.slice(pathIndex)
                    ]
                  }
                });
              } else throw new Error("Cannot insert at: " + JSON.stringify(parentPath));
            });
          }
        };
        return insertNode(deleteNode(value, operation.fromPath), this.transformPath(operation.fromPath, operation), targetNode);
      }
    }

    throw new Error("Unsupported Operation");
  }
  invert = (operation: TreeOperation<Item, ItemOperation>): TreeOperation<Item, ItemOperation>[] => {
    switch (operation.type) {
      case "insert": return [{type: "delete", path: operation.path, prevItem: operation.item}];
      case "delete": return [{type: "insert", path: operation.path, item: operation.prevItem}];
      case "apply": return [{type: "apply", path: operation.path, operations: invertAll(this.itemType, operation.operations)}];
      case "move": {
        if (TreePath.equals(operation.fromPath, operation.toPath)) return [operation];
        if (TreePath.isSibling(operation.fromPath, operation.toPath)) return [{type: "move", fromPath: operation.toPath, toPath: operation.fromPath}];
        else return [{
          type: "move",
          fromPath: this.transformPath(operation.fromPath, operation),
          toPath: this.transformPath(TreePath.next(operation.fromPath), operation)
        }];
      }
    }
    return [];
  }

  transform = (leftOperation: TreeOperation<Item, ItemOperation>, topOperation: TreeOperation<Item, ItemOperation>, tieBreaker: boolean): TreeOperation<Item, ItemOperation>[] => {
    switch (leftOperation.type) {
      case "insert": {
        switch (topOperation.type) {
          case "insert": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            return [{...leftOperation, path: newPath}];
          }
          case "move": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            return [{...leftOperation, path: newPath}];
          }
          case "apply": {
            return [leftOperation];
          }
          case "delete": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            if (newPath === null) return [];
            return [{...leftOperation, path: newPath}];
          }
        }
        break;
      }
      case "delete": {
        switch (topOperation.type) {
          case "delete": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            if (newPath === null) return [];
            return [{...leftOperation, path: newPath}];
          }
          case "apply": {
            if (TreePath.isAncestor(leftOperation.path, topOperation.path)) {
              const newPrevItem = this.apply([leftOperation.prevItem], {...topOperation, path: [0, ...topOperation.path.slice(leftOperation.path.length)]})[0];
              return [{...leftOperation, prevItem: newPrevItem}];
            } else {
              const newPath = this.transformPath(leftOperation.path, topOperation);
              if (newPath === null) return [];
              return [leftOperation];
            }
          }
          case "insert": {
            if (TreePath.isAncestor(leftOperation.path, topOperation.path)) {
              const newPrevItem = this.apply([leftOperation.prevItem], {...topOperation, path: [0, ...topOperation.path.slice(leftOperation.path.length)]})[0];
              return [{...leftOperation, prevItem: newPrevItem}];
            } else {
              const newPath = this.transformPath(leftOperation.path, topOperation);
              if (newPath === null) return [];
              return [leftOperation];
            }
          }
          case "move": {
            if (tieBreaker) return [leftOperation];
            if (TreePath.isAncestor(leftOperation.path, topOperation.fromPath) && TreePath.isAncestor(leftOperation.path, topOperation.toPath)) {
              const newPrevItem = this.apply([leftOperation.prevItem], {
                ...topOperation,
                fromPath: topOperation.fromPath.slice(leftOperation.path.length),
                toPath: topOperation.toPath.slice(leftOperation.path.length)
              })[0];
              return [{...leftOperation, prevItem: newPrevItem}];
            } else if (TreePath.isAncestor(leftOperation.path, topOperation.fromPath)) {
              const newPath = this.transformPath(leftOperation.path, topOperation);
              const newPrevItem = this.apply([leftOperation.prevItem], {
                ...topOperation,
                type: "delete",
                path: [0, ...topOperation.fromPath.slice(leftOperation.path.length)],
                prevItem: Tree.getNode([leftOperation.prevItem], [0, ...topOperation.fromPath.slice(leftOperation.path.length)])}
              )[0];
              return [{...leftOperation, path: newPath, prevItem: newPrevItem}];
            } else if (TreePath.isAncestor(leftOperation.path, topOperation.toPath)) {
              const newPath = this.transformPath(leftOperation.path, topOperation);
              return [{...leftOperation, path: newPath}];
            } else {
              return [leftOperation]
            }
          }
        }
        break;
      }
      case "move": {
        switch (topOperation.type) {
          case "delete": {
            if (tieBreaker) return [leftOperation];
            if (TreePath.equals(topOperation.path, leftOperation.fromPath)) return [];
            if (TreePath.equals(topOperation.path, leftOperation.toPath)) {
              const fromPath = this.transformPath(leftOperation.fromPath, topOperation);
              if (fromPath === null) return [];

              const toPath = leftOperation.toPath;
              if (leftOperation.fromPath !== fromPath || leftOperation.toPath !== toPath) {
                return [{...leftOperation, fromPath: fromPath, toPath: toPath}];
              } else {
                return [leftOperation];
              }
            }
            const fromPath = this.transformPath(leftOperation.fromPath, topOperation);
            const toPath = this.transformPath(leftOperation.toPath, topOperation);
            if (fromPath === null || toPath === null) return [];
            return [{...leftOperation, fromPath, toPath}];
          }
          default: {
            if (tieBreaker) return [leftOperation];
            const fromPath = this.transformPath(leftOperation.fromPath, topOperation);
            const toPath = this.transformPath(leftOperation.toPath, topOperation);
            if (fromPath === null || toPath === null) return [];
            return [{...leftOperation, fromPath, toPath}];
          }
        }
      }
      case "apply": {
        switch (topOperation.type) {
          case "insert": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            return [{...leftOperation, path: newPath}];
          }
          case "delete": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            if (newPath === null) return [];
            return [{...leftOperation, path: newPath}];
          }
          case "move": {
            if (tieBreaker) return [leftOperation];
            const newPath = this.transformPath(leftOperation.path, topOperation);
            return [{...leftOperation, path: newPath}];
          }
          case "apply": {
            if (tieBreaker) return [leftOperation];
            if (TreePath.equals(leftOperation.path, topOperation.path)) {
              return [{
                ...leftOperation,
                operations:
                  transformAll(
                    this.itemType,
                    leftOperation.operations,
                    topOperation.operations,
                    tieBreaker
                  )[0]
              }]
            } else {
              return [leftOperation];
            }
          }
        }
        break;
      }
    }
    throw new Error("Unsupported Operation");
  }

  transformPath(path: TreePath, operation: DeleteTreeOperation<Item>): TreePath | null;
  transformPath(path: TreePath, operation: TreeOperation<Item, ItemOperation>): TreePath;
  transformPath(path: TreePath, operation: TreeOperation<Item, ItemOperation>): TreePath | null {
    if (path.length === 0) return path;

    if (operation.type === "insert") {
      if (TreePath.equals(operation.path, path) || TreePath.endsBefore(operation.path, path) || TreePath.isAncestor(operation.path, path)) {
        const newPath = [...path];
        newPath[operation.path.length - 1] += 1;
        return newPath;
      } else {
        return path;
      }
    } else if (operation.type === "delete") {
      if (TreePath.equals(operation.path, path) || TreePath.isAncestor(operation.path, path)) {
        return null;
      } else if (TreePath.endsBefore(operation.path, path)) {
        const newPath = [...path];
        newPath[operation.path.length - 1] -= 1;
        return newPath;
      } else {
        return path;
      }
    } else if (operation.type === "move") {
      const {fromPath, toPath} = operation
      if (TreePath.equals(fromPath, operation.toPath)) {
        return path;
      }

      if (TreePath.isAncestor(fromPath, path) || TreePath.equals(fromPath, path)) {
        const newPath = [...toPath];
        if (TreePath.endsBefore(fromPath, toPath) && fromPath.length < toPath.length) {
          newPath[fromPath.length - 1] -= 1
        }
        return newPath.concat(path.slice(fromPath.length));
      } else if (TreePath.isSibling(fromPath, toPath) && (TreePath.isAncestor(toPath, path) || TreePath.equals(toPath, path))) {
        if (TreePath.endsBefore(fromPath, path)) {
          const newPath = [...path];
          newPath[fromPath.length - 1] -= 1;
          return newPath;
        } else {
          const newPath = [...path];
          newPath[fromPath.length - 1] += 1;
          return newPath;
        }
      } else if (TreePath.endsBefore(toPath, path) || TreePath.equals(toPath, path) || TreePath.isAncestor(toPath, path)) {
        const newPath = [...path];
        if (TreePath.endsBefore(fromPath, path)) {
          newPath[fromPath.length - 1] -= 1
        }
        newPath[toPath.length - 1] += 1
        return newPath;
      } else if (TreePath.endsBefore(fromPath, path)) {
        const newPath = [...path];
        if (TreePath.equals(toPath, path)) {
          newPath[toPath.length - 1] += 1
        }
        newPath[fromPath.length - 1] -= 1
        return newPath;
      } else {
        return path;
      }
    } else {
      return path;
    }
  }
  migrateValue = (value: any): Item[] => {
    return value.map(this.updateFn).map(this.itemType.migrateValue);
  }
  migrateOperation = (operation: any): TreeOperation<Item, ItemOperation>[] => {
    if (operation.type === "insert") {
      return [{type: "insert", path: operation.path, item: this.itemType.migrateValue(operation.item)}];
    } else if (operation.type === "delete") {
      return [{type: "delete", path: operation.path, prevItem: this.itemType.migrateValue(operation.prevItem)}];
    } else if (operation.type === "apply") {
      return [{type: "apply", path: operation.path, operations: operation.operations.flatMap(this.itemType.migrateOperation)}];
    } else if (operation.type === "move") {
      return [{type: "move", fromPath: operation.fromPath, toPath: operation.toPath}];
    } else {
      throw new Error("Unexpected Operation " + JSON.stringify(operation));
    }
  }
  validate = (value: any): ValidationError[] => {
    if (!Array.isArray(value)) return [{path: [], data: {message: "Invalid type. Expected tree.", value}}];
    return value.flatMap((value, index) => this.itemType.validate(value).map(error => ({
      path: [index, ...error.path],
      data: error.data
    })));
  }
}