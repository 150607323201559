import {StackItemTab, StackItemTabProps} from "#lib/container/react/stack/stack-item-tab.tsx";
import {StackId} from "#lib/container/index.ts";
import {PlayerSheetStack} from "./player-sheet-stack.ts";
import {usePlayerSheetReference} from "./use-player-sheet-reference.ts";
import {useSheetName} from "../../../common/sheet/use-sheet.ts";
import {PlayerStacks} from "../../../container/view/player-stacks.ts";
import React, {Ref, useMemo} from "react";
import {createRef} from "common/ref";

export type PropertiesTabViewProps = Omit<StackItemTabProps, "Icon" | "label" | "content"> & {
  id: StackId;
  content: PlayerSheetStack;
};

export const PlayerSheetTabView = React.forwardRef(function PlayerSheetTabView({id, content, ...props}: PropertiesTabViewProps, ref: Ref<any>) {
  const referenceSheet = usePlayerSheetReference(useMemo(() => createRef(content.reference), [content.reference]));
  const sheetName = useSheetName(referenceSheet);

  if (sheetName === undefined || content.reference === undefined) {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={PlayerStacks["sheet"].Icon}
      label={PlayerStacks["sheet"].label}
    />);
  } else {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={PlayerStacks["sheet"].Icon}
      label={`${PlayerStacks["sheet"].label} / ${sheetName}`}
    />);
  }
});
