import {NodeId, NodeID} from "#common/legends/node/node-id.ts";
import {ConstantOperation, constantType, NumberOperation, numberType, ObjectType, PropertyRef, Type} from "#common/types/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const NodeTurn = z.object({
  nodeID: NodeID,
  order: z.number()
});
export type NodeTurn = z.infer<typeof NodeTurn>;

export const NodeTurnOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-order"), operations: z.array(NumberOperation)})
])
export type NodeTurnOperation = z.infer<typeof NodeTurnOperation>;

export const nodeTurnType: Type<NodeTurn, NodeTurnOperation> = new ObjectType({
  nodeID: constantType,
  order: numberType
});

export function NodeTurnSignals(value: MutableRef<NodeTurn, NodeTurnOperation[]>) {
  return {
    order: PropertyRef<NodeTurn, NodeTurnOperation, number, NumberOperation>(
      value => value.order,
      operations => [{type: "update-order", operations}]
    )(value),
    nodeID: PropertyRef<NodeTurn, NodeTurnOperation, NodeId, never>(
      value => value.nodeID,
      _ => {
        throw new Error("Cannot change NodeID")
      }
    )(value)
  };
}
