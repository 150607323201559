import {z} from "zod";
import {BooleanOperation, booleanType, ColorOperation, colorType, HSLA, NumberOperation, numberType, ObjectType, PropertyRef} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export const MeasurementCone = z.object({
  sticky: z.boolean(),
  fillColor: HSLA,
  sizeAngle: z.number()
});
export type MeasurementCone = z.infer<typeof MeasurementCone>;

export const MeasurementConeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-sticky"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-fill-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-size-angle"), operations: z.array(NumberOperation)})
]);
export type MeasurementConeOperation = z.infer<typeof MeasurementConeOperation>;

export const measurementConeType: Type<MeasurementCone, MeasurementConeOperation> = new ObjectType({
  sticky: booleanType,
  fillColor: colorType,
  sizeAngle: numberType
});

export function MeasurementConeRef(ref: MutableRef<MeasurementCone, MeasurementConeOperation[]>) {
  return {
    fillColorRef: PropertyRef<MeasurementCone, MeasurementConeOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(ref),
    stickyRef: PropertyRef<MeasurementCone, MeasurementConeOperation, boolean, BooleanOperation>(value => value.sticky, operations => [{type: "update-sticky", operations}])(ref),
    sizeAngleRef: PropertyRef<MeasurementCone, MeasurementConeOperation, number, NumberOperation>(value => value.sizeAngle, operations => [{type: "update-size-angle", operations}])(ref)
  };
}
