import {Grammar, Parser} from "nearley";
import diceGrammar from "./dice-grammar.ts";
import {DiceExpression} from "./dice-expression.ts";
import {z} from "zod";

export const Comparator = z.union([
  z.literal("equal"),
  z.literal("greater"),
  z.literal("greater-or-equal"),
  z.literal("less"),
  z.literal("less-or-equal")
]);
export type Comparator = z.infer<typeof Comparator>;

export const ComparatorFn = {
  toString: (comparator: Comparator) => {
    switch (comparator) {
      case "less": return "<";
      case "less-or-equal": return "<=";
      case "equal": return "";
      case "greater-or-equal": return ">=";
      case "greater": return ">";
    }
  }
};

export type DiceExpressionTree =
  | {op: "constant", value: number, label?: string}
  | {op: "variable", variable: string, label?: string}
  | {op: "dice", count: DiceExpressionTree, faces: DiceExpressionTree, label?: string}
  | {op: "keep-highest", dice: DiceExpressionTree, count: DiceExpressionTree, label?: string}
  | {op: "keep-lowest", dice: DiceExpressionTree, count: DiceExpressionTree, label?: string}
  | {op: "drop-highest", dice: DiceExpressionTree, count: DiceExpressionTree, label?: string}
  | {op: "drop-lowest", dice: DiceExpressionTree, count: DiceExpressionTree, label?: string}
  | {op: "reroll", dice: DiceExpressionTree, comparator: Comparator, target: DiceExpressionTree, label?: string}
  | {op: "reroll-once", dice: DiceExpressionTree, comparator: Comparator, target: DiceExpressionTree, label?: string}
  | {op: "explode", dice: DiceExpressionTree, comparator: Comparator, target: DiceExpressionTree, label?: string}
  | {op: "add", left: DiceExpressionTree, right: DiceExpressionTree}
  | {op: "subtract", left: DiceExpressionTree, right: DiceExpressionTree}
  | {op: "multiply", left: DiceExpressionTree, right: DiceExpressionTree}
  | {op: "divide", left: DiceExpressionTree, right: DiceExpressionTree}
  | {op: "remainder", left: DiceExpressionTree, right: DiceExpressionTree}
  | {op: "min", left: DiceExpressionTree, right: DiceExpressionTree, label?: string}
  | {op: "max", left: DiceExpressionTree, right: DiceExpressionTree, label?: string}
  | {op: "parentheses", expression: DiceExpressionTree, label?: string}
  | {op: "count-success", dice: DiceExpressionTree, comparator: Comparator, target: DiceExpressionTree, label?: string}
  | {op: "count-failure", dice: DiceExpressionTree, comparator: Comparator, target: DiceExpressionTree, label?: string}
  ;

export function diceExpressionTreeToDiceExpression(result: DiceExpressionTree): DiceExpression {
  switch (result.op) {
    case "constant": return (result.value + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "variable": return (`{${result.variable}}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "dice": return (diceExpressionTreeToDiceExpression(result.count) + "d" + diceExpressionTreeToDiceExpression(result.faces) + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "explode": return (`${diceExpressionTreeToDiceExpression(result.dice)}!${ComparatorFn.toString(result.comparator)}${diceExpressionTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll": return (`${diceExpressionTreeToDiceExpression(result.dice)}r${ComparatorFn.toString(result.comparator)}${diceExpressionTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "reroll-once": return (`${diceExpressionTreeToDiceExpression(result.dice)}ro${ComparatorFn.toString(result.comparator)}${diceExpressionTreeToDiceExpression(result.target)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-highest": return (`${diceExpressionTreeToDiceExpression(result.dice)}kh${diceExpressionTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "keep-lowest": return (`${diceExpressionTreeToDiceExpression(result.dice)}kl${diceExpressionTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-highest": return (`${diceExpressionTreeToDiceExpression(result.dice)}dh${diceExpressionTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "drop-lowest": return (`${diceExpressionTreeToDiceExpression(result.dice)}dl${diceExpressionTreeToDiceExpression(result.count)}` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "min": return (`min(${diceExpressionTreeToDiceExpression(result.left)},${diceExpressionTreeToDiceExpression(result.right)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "max": return (`max(${diceExpressionTreeToDiceExpression(result.left)},${diceExpressionTreeToDiceExpression(result.right)})` + (result.label ? ` [${result.label}]` : '')) as DiceExpression;
    case "parentheses": return (`(${diceExpressionTreeToDiceExpression(result.expression)})`) as DiceExpression;
    case "add": return (`${diceExpressionTreeToDiceExpression(result.left)}+${diceExpressionTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "subtract": return (`${diceExpressionTreeToDiceExpression(result.left)}-${diceExpressionTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "multiply": return (`${diceExpressionTreeToDiceExpression(result.left)}*${diceExpressionTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "divide": return (`${diceExpressionTreeToDiceExpression(result.left)}/${diceExpressionTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "remainder": return (`${diceExpressionTreeToDiceExpression(result.left)}%${diceExpressionTreeToDiceExpression(result.right)}`) as DiceExpression;
    case "count-success": return (`${diceExpressionTreeToDiceExpression(result.dice)}cs${ComparatorFn.toString(result.comparator)}${diceExpressionTreeToDiceExpression(result.target)}`) as DiceExpression;
    case "count-failure": return (`${diceExpressionTreeToDiceExpression(result.dice)}cf${ComparatorFn.toString(result.comparator)}${diceExpressionTreeToDiceExpression(result.target)}`) as DiceExpression;
  }
}

const compiledGrammar = Grammar.fromCompiled(diceGrammar);

export const parseDiceExpression = (diceExpression: string): DiceExpressionTree => {
  try {
    const results = new Parser(compiledGrammar)
      .feed(diceExpression)
      .finish();
    if (results.length >= 1) return results[0];
  } catch (e) {
    throw e;
  }
  throw new Error("Cannot parse dice expression: " + diceExpression);
}