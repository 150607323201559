import {useEffect, useState} from "react";

export function useIsShiftDown() {
  const [shift, setShift] = useState(false);
  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      setShift(ev.shiftKey);
    };
    document.addEventListener('keydown', keydownHandler);
    document.addEventListener('keyup', keydownHandler);
    return () => {
      document.addEventListener('keydown', keydownHandler);
      document.removeEventListener("keyup", keydownHandler);
    };
  }, [setShift])
  return shift;
}
