import {ConstantOperation, ListOperation, MapFn, MapOperation, NumberFn, NumberOperation, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {
  Dnd5eBackground,
  Dnd5eBackgroundOperation,
  Dnd5eCharacter,
  Dnd5eCharacterClass,
  Dnd5eCharacterClassFeature,
  Dnd5eCharacterClassFeatureOperation,
  Dnd5eCharacterClassOperation,
  Dnd5eCharacterOperation,
  Dnd5eFeature,
  Dnd5eFeatureOperation,
  Dnd5eInventoryItem,
  Dnd5eInventoryItemOperation,
  Dnd5eRace,
  Dnd5eRaceOperation,
  Dnd5eSpellLevel,
  DND_5E_SPELL_LEVEL,
  Sheet,
  SheetOperation
} from "common/legends/index.ts";
import {useCallback} from "react";
import {Dnd5eResourceType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-type.ts";
import {Dnd5eResource, Dnd5eResourceOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eStatBlock, Dnd5eStatBlockOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {MathExpressionFn} from "common/math/index.ts";
import {getDnd5eCharacterVariables, getDnd5eStatBlockVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {getMaxPactSlots, getMaxSpellSlotsByClasses, getMaxSpellSlotsBySpellCasterLevel} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {MutableRef} from "common/ref";

function resetResource(resource: Dnd5eResource, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eResourceOperation[] {
  if (!resourceTypes.includes(resource.resourceType)) return [];
  const currentMax = MathExpressionFn.executeMathExpression(resource.max, context);
  if (resource.current === currentMax) return [];
  return [{type: "update-current", operations: NumberFn.set(resource.current, currentMax)}];
}

function resetFeature(feature: Dnd5eFeature, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eFeatureOperation[] {
  const resourcesOperations = feature.resources.flatMap((resource, resourceIndex) => {
    const resourceOperations = resetResource(resource, resourceTypes, context);
    if (resourceOperations.length === 0) return [];
    return ListOperation.apply(resourceIndex, resourceOperations);
  }) satisfies ListOperation<Dnd5eResource, Dnd5eResourceOperation>[];
  if (resourcesOperations.length === 0) return [];
  return [{type: "update-resources", operations: resourcesOperations}];
}

function resetRace(race: Dnd5eRace, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eRaceOperation[] {
  const featureOperations = race.features.flatMap((feature, featureIndex) => {
    const featureOperations = resetFeature(feature, resourceTypes, context);
    if (featureOperations.length === 0) return [];
    return ListOperation.apply(featureIndex, featureOperations);
  }) satisfies ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[];
  if (featureOperations.length === 0) return [];
  return [{type: "update-features", operations: featureOperations}];
}

function resetBackground(background: Dnd5eBackground, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eBackgroundOperation[] {
  const featureOperations = background.features.flatMap((feature, featureIndex) => {
    const featureOperations = resetFeature(feature, resourceTypes, context);
    if (featureOperations.length === 0) return [];
    return ListOperation.apply(featureIndex, featureOperations);
  }) satisfies ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[];
  if (featureOperations.length === 0) return [];
  return [{type: "update-features", operations: featureOperations}];
}

function resetItem(item: Dnd5eInventoryItem, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eInventoryItemOperation[] {
  const featureOperations = item.resources.flatMap((resource, resourceIndex) => {
    const resourceOperation = resetResource(resource, resourceTypes, context);
    if (resourceOperation.length === 0) return [];
    return ListOperation.apply(resourceIndex, resourceOperation);
  }) satisfies ListOperation<Dnd5eResource, Dnd5eResourceOperation>[];
  if (featureOperations.length === 0) return [];
  return [{type: "update-resources", operations: featureOperations}];
}

function resetClass(clazz: Dnd5eCharacterClass, resourceTypes: Dnd5eResourceType[], context: {[variable: string]: number}): Dnd5eCharacterClassOperation[] {
  const classOperations: Dnd5eCharacterClassOperation[] = [];
  const featureOperations = clazz.features.flatMap((feature, featureIndex) => {
    const featureOperations = resetFeature(feature.feature, resourceTypes, context);
    if (featureOperations.length === 0) return [];
    return ListOperation.apply(featureIndex, [{type: "update-feature", operations: featureOperations}]);
  }) satisfies ListOperation<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>[];
  if (featureOperations.length !== 0) classOperations.push({type: "update-features", operations: featureOperations});

  if (resourceTypes.includes("long rest")) {
    if (clazz.hitDice.current !== clazz.level) {
      classOperations.push({
        type: "update-hit-dice",
        operations: [{
          type: "update-current",
          operations: NumberFn.set(
            clazz.hitDice.current,
            Math.min(clazz.level, clazz.hitDice.current + Math.max(1, Math.floor(clazz.level / 2)))
          )
        }]
      });
    }
  }

  return classOperations;
}

function resetCharacterSheet(sheet: Dnd5eCharacter, resourceTypes: Dnd5eResourceType[]): Dnd5eCharacterOperation[] {
  const context = getDnd5eCharacterVariables(sheet);
  const characterOperations: Dnd5eCharacterOperation[] = [];
  const inventoryOperations = sheet.inventory.flatMap((item, itemIndex) => {
    const itemOperations = resetItem(item, resourceTypes, context);
    if (itemOperations.length === 0) return [];
    return ListOperation.apply(itemIndex, itemOperations);
  }) satisfies ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[];
  if (inventoryOperations.length !== 0) characterOperations.push({type: "update-inventory", operations: inventoryOperations});

  const raceOperations = resetRace(sheet.race, resourceTypes, context);
  if (raceOperations.length !== 0) characterOperations.push({type: "update-race", operations: ValueFn.apply(raceOperations)});

  const backgroundOperations = resetBackground(sheet.background, resourceTypes, context);
  if (backgroundOperations.length !== 0) characterOperations.push({type: "update-background", operations: ValueFn.apply(backgroundOperations)});

  const classesOperations = sheet.classes.flatMap((clazz, classIndex) => {
    const classOperations = resetClass(clazz, resourceTypes, context);
    if (classOperations.length === 0) return [];
    return ListOperation.apply(classIndex, classOperations);
  }) satisfies ListOperation<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>[];
  if (classesOperations.length !== 0) characterOperations.push({type: "update-classes", operations: classesOperations});

  const featureOperations = sheet.features.flatMap((feature, featureIndex) => ListOperation.apply(
    featureIndex,
    resetFeature(feature, resourceTypes, context)
  )) satisfies ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[];
  if (featureOperations.length !== 0) characterOperations.push({type: "update-features", operations: featureOperations});

  if (resourceTypes.includes("long rest")) {
    const spellSlotsOperations = Object.keys(sheet.spellSlots).flatMap((level) => {
      const maxSpellSlots = getMaxSpellSlotsByClasses(sheet.classes, level as Dnd5eSpellLevel);
      const spellSlots = sheet.spellSlots[level as Dnd5eSpellLevel];
      if (spellSlots === undefined) return [];
      if (spellSlots === maxSpellSlots) return [];
      return MapFn.apply(level as Dnd5eSpellLevel, ValueFn.set(spellSlots, maxSpellSlots));
    }) satisfies MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[];
    if (spellSlotsOperations.length !== 0) characterOperations.push({type: "update-spell-slots", operations: spellSlotsOperations});

    if (sheet.hitPoints.current !== 0) {
      characterOperations.push({
        type: "update-hit-points",
        operations: [{type: "update-current", operations: NumberFn.set(sheet.hitPoints.current, 0)}]
      });
    }
    if (sheet.exhaustionLevel > 0) {
      characterOperations.push({type: "update-exhaustion-level", operations: NumberFn.decrement(1)});
    }
  }
  if (resourceTypes.includes("short rest")) {
    const pactSlotOperations = DND_5E_SPELL_LEVEL.flatMap((level) => {
      const maxPactSlots = getMaxPactSlots(sheet.classes, level as Dnd5eSpellLevel);
      if (maxPactSlots === 0) return [];
      const pactSlots = sheet.pactSlots;
      if (pactSlots === maxPactSlots) return [];
      return NumberFn.set(pactSlots, maxPactSlots);
    }) satisfies NumberOperation[];
    if (pactSlotOperations.length !== 0) characterOperations.push({type: "update-pact-slots", operations: pactSlotOperations});
  }
  return characterOperations;
}

function resetStatBlockSheet(sheet: Dnd5eStatBlock, resourceTypes: Dnd5eResourceType[]): Dnd5eStatBlockOperation[] {
  const context = getDnd5eStatBlockVariables(sheet);

  const statBlockOperations: Dnd5eStatBlockOperation[] = [];
  const featureOperations = sheet.features.flatMap((feature, featureIndex) => ListOperation.apply(
    featureIndex,
    resetFeature(feature, resourceTypes, context)
  )) satisfies ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[];
  if (featureOperations.length !== 0) statBlockOperations.push({type: "update-features", operations: featureOperations});

  if (resourceTypes.includes("long rest")) {
    if (sheet.hitPoints.current !== 0) {
      statBlockOperations.push({
        type: "update-hit-points",
        operations: [{type: "update-current", operations: NumberFn.set(sheet.hitPoints.current, 0)}]
      });
    }
    const spellSlotsOperations: MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[] = [];
    for (const level of DND_5E_SPELL_LEVEL) {
      const maxSlots = getMaxSpellSlotsBySpellCasterLevel(sheet.spellCasterLevel, level);
      if (maxSlots === 0 || !Number.isFinite(maxSlots)) continue;
      if (sheet.spellSlots[level] !== maxSlots) {
        const operations = MapFn.apply<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>(level, ValueFn.set(sheet.spellSlots[level]!, maxSlots));
        spellSlotsOperations.push(...operations);
      }
    }
    if (spellSlotsOperations.length > 0) statBlockOperations.push({type: "update-spell-slots", operations: spellSlotsOperations});
  }
  return statBlockOperations;
}

export function useShortRest(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  const globalFeatures = useGlobalFeatures();
  return useCallback(() => {
    sheet.apply(prev => {
      if (prev?.type === "dnd-5e-character") {
        const characterOperations = resetCharacterSheet(prev.data, ["short rest"]);
        if (characterOperations.length === 0) return [];
        return [{type: "dnd-5e-character", operations: characterOperations}];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const statBlockOperations = resetStatBlockSheet(prev.data, ["short rest"]);
        if (statBlockOperations.length === 0) return [];
        return [{type: "dnd-5e-stat-block", operations: statBlockOperations}];
      } else {
        return [];
      }
    });
  }, [sheet, globalFeatures]);
}

export function useLongRest(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  const globalFeatures = useGlobalFeatures();
  return useCallback(() => {
    sheet.apply(prev => {
      if (prev?.type === "dnd-5e-character") {
        const characterOperations = resetCharacterSheet(prev.data, ["long rest", "short rest"]);
        if (characterOperations.length === 0) return [];
        return [{type: "dnd-5e-character", operations: characterOperations}];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const statBlockOperations = resetStatBlockSheet(prev.data, ["long rest", "short rest"]);
        if (statBlockOperations.length === 0) return [];
        return [{type: "dnd-5e-stat-block", operations: statBlockOperations}];
      } else {
        return [];
      }
    });
  }, [sheet, globalFeatures]);
}