import {ExtractOperation, ExtractValue, MultiType, Type} from "../../types/index.ts";
import {TokenNode, TokenNodeOperation, tokenNodeType} from "./token-node.ts";
import {ImageNode, ImageNodeOperation, imageNodeType} from "./image-node.ts";
import {GroupNode, GroupNodeOperation, groupNodeType} from "./group-node.ts";
import {GridNode, GridNodeOperation, gridNodeType} from "./grid-node.ts";
import {WallNode, WallNodeOperation, wallNodeType} from "./wall-node.ts";
import {AreaNode, AreaNodeOperation, areaNodeType} from "./area-node.ts";
import {TextNode, TextNodeOperation, textNodeType} from "./text-node.ts";
import {ShapeNode, ShapeNodeOperation, shapeNodeType} from "./shape-node.ts";
import {ParallaxNode, ParallaxNodeOperation, parallaxNodeType} from "./parallax-node.ts";

export interface NodeTypes {
  "area": Type<AreaNode, AreaNodeOperation>;
  "grid": Type<GridNode, GridNodeOperation>;
  "group": Type<GroupNode, GroupNodeOperation>;
  "image": Type<ImageNode, ImageNodeOperation>;
  "shape": Type<ShapeNode, ShapeNodeOperation>;
  "text": Type<TextNode, TextNodeOperation>;
  "token": Type<TokenNode, TokenNodeOperation>;
  "wall": Type<WallNode, WallNodeOperation>;
  "parallax": Type<ParallaxNode, ParallaxNodeOperation>;
}
export type NodeType = keyof NodeTypes;
export type Node = {[Type in NodeType]: {
  type: Type;
  data: ExtractValue<NodeTypes[Type]>
}}[NodeType];
export type NodeOperation = {[Type in NodeType]: {
  type: Type;
  operations: ExtractOperation<NodeTypes[Type]>[]
}}[NodeType];

export const nodeType: Type<Node, NodeOperation> = new MultiType(() => ({
  area: areaNodeType,
  grid: gridNodeType,
  group: groupNodeType,
  image: imageNodeType,
  parallax: parallaxNodeType,
  shape: shapeNodeType,
  text: textNodeType,
  token: tokenNodeType,
  wall: wallNodeType
}));
