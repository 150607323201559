import {Node, NodeId} from "common/legends/node/index.ts";
import {Optional, Tree} from "common/types/generic/index.ts";

export function getParentNode(children: Node[], nodeID: NodeId): Optional<Node> {
  const path = Tree.getPath(children, n => n.data.id === nodeID);
  if (path === undefined || path.length === 1) return undefined;

  const parent = Tree.getNode(children, path.slice(0, path.length - 1));
  if (parent === undefined) return undefined;
  return parent;
}
