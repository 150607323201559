import {
  ConstantOperation,
  ConstantType,
  ListOperation,
  ListType,
  MapOperation,
  MapType,
  ObjectType,
  SetOperation,
  SetType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {Token, TokenID, TokenOperation, tokenType} from "./token/index.ts";
import {UserID} from "../user/index.ts";
import {AccessMask, AccessMaskOperation, accessMaskType, VisibilityLayer, VisibilityLayerOperation, visibilityLayerType} from "#common/legends/visibility/index.ts";
import {Sheet, SheetID, SheetOperation, sheetType} from "#common/legends/asset/sheet/index.ts";
import {Mask, MaskOperation, maskType} from "../../types/generic/mask/mask.ts";

export type Asset = {
  ownerIDs: UserID[];
  initialTokenId: TokenID;
  tokens: Token[];
  sheets: Record<SheetID, Sheet>;
  selectionMask: Mask;
  visibilityLayer: VisibilityLayer;
  accessMask: AccessMask;
};

export type AssetOperation =
  | {type: "update-owner-i-ds", operations: SetOperation<UserID>[]}
  | {type: "update-initial-token-id", operations: ValueOperation<TokenID, ConstantOperation>[]}
  | {type: "update-tokens", operations: ListOperation<Token, TokenOperation>[]}
  | {type: "update-sheets", operations: MapOperation<SheetID, Sheet, SheetOperation>[]}
  | {type: "update-visibility-layer", operations: VisibilityLayerOperation[]}
  | {type: "update-selection-mask", operations: MaskOperation[]}
  | {type: "update-access-mask", operations: AccessMaskOperation[]}
  ;
export const AssetOperationFn = {
  updateInitialTokenId: (operations: ValueOperation<TokenID, ConstantOperation>[]): AssetOperation[] => [{type: "update-initial-token-id", operations}],
  updateTokens: (operations: ListOperation<Token, TokenOperation>[]): AssetOperation[] => [{type: "update-tokens", operations}]
} as const;

export const assetType: Type<Asset, AssetOperation> = new ObjectType({
  ownerIDs: new SetType<UserID>(),
  initialTokenId: new ValueType(new ConstantType<TokenID>()),
  tokens: new ListType<Token, TokenOperation>(tokenType),
  sheets: new MapType<SheetID, Sheet, SheetOperation>(sheetType),
  visibilityLayer: visibilityLayerType,
  selectionMask: maskType,
  accessMask: accessMaskType
}, (value: any) => {
  if (value.visibilityMask) {
    value.accessMask = value.visibilityMask;
    delete value["visibilityMask"];
  }
  if (typeof value.tokens === "object") {
    value.tokens = Object.keys(value.tokens).map(tokenID => ({
      tokenID: tokenID,
      ...value.tokens[tokenID]
    }));
  }
  if (value.selectionMask === undefined) {
    value.selectionMask = 0;
  }
  return value;
});

declare module "../../qlab/resource/resource.ts" {
  export interface ResourceTypes {
    "asset": typeof assetType
  }
}
