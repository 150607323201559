import {z} from "zod";

export const Dnd5eSpellSlotProgression = z.union([
  z.literal("FULL"),
  z.literal("HALF1"),
  z.literal("HALF2"),
  z.literal("THIRD"),
  z.literal("PACT")
]);
export type Dnd5eSpellSlotProgression = z.infer<typeof Dnd5eSpellSlotProgression>;

export const DND_5E_SPELL_SLOT_PROGRESSION = [
  "FULL",
  "HALF1",
  "HALF2",
  "THIRD",
  "PACT"
] as const satisfies readonly Dnd5eSpellSlotProgression[];

export const DND_5E_SPELL_SLOT_PROGRESSION_TITLE: {[type in Dnd5eSpellSlotProgression]: string} = {
  "FULL": "Spellcasting (Full)",
  "HALF1": "Spellcasting (Half, 1st level)",
  "HALF2": "Spellcasting (Half, 2nd level)",
  "THIRD": "Spellcasting (Third)",
  "PACT": "Pact Magic",
};
