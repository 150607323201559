import {z} from "zod";

export const Dnd5eAbilityCheckProficiency = z.union([
  z.literal("untrained"),
  z.literal("proficient"),
  z.literal("expertise")
]);
export type Dnd5eAbilityCheckProficiency = z.infer<typeof Dnd5eAbilityCheckProficiency>;
export const DND_5E_ABILITY_CHECK_PROFICIENCIES = ["untrained", "proficient", "expertise"] as const satisfies readonly Dnd5eAbilityCheckProficiency[];

export const ABILITY_CHECK_PROFICIENCY_TITLE: {[proficiency in Dnd5eAbilityCheckProficiency]: string} = {
  "untrained": "Not Proficient",
  "proficient": "Proficient",
  "expertise": "Expertise"
};
