import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Uniform1F extends WebGL2Node {
  private location?: WebGLUniformLocation;
  private data?: GLfloat;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.location === undefined) throw new Error("location must be provided.");
    if (this.data === undefined) throw new Error("data must be provided.");

    this.context.uniform1f(this.location, this.data);
  }
}
