import {Dnd5eEffectID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {Dnd5eFeature} from "#common/legends/index.ts";
import {Dnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {BooleanOperation, booleanType, MapOperation, MapType, ObjectType, Type} from "#common/types/index.ts";
import {z} from "zod";

export const Dnd5eFeatureOverride = z.object({
  effects: z.record(Dnd5eEffectID, z.boolean())
});
export type Dnd5eFeatureOverride = z.infer<typeof Dnd5eFeatureOverride>;
export type Dnd5eFeatureOverrideOperation =
  | {type: "update-effects", operations: MapOperation<Dnd5eEffectID, boolean, BooleanOperation>[]};
export const dnd5eFeatureOverrideType: Type<Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation> = new ObjectType({
  effects: new MapType<Dnd5eEffectID, boolean, BooleanOperation>(booleanType)
});

export function overrideFeatures(features: Dnd5eFeature[], overrides: {[featureID in Dnd5eFeatureID]?: Dnd5eFeatureOverride}) {
  return features.map(feature => {
    const featureOverride = overrides[feature.featureID] || {effects: {}};
    return ({
      ...feature,
      effects: feature.effects.map(effect => {
        const effectOverride = featureOverride.effects[effect.effectID] || false;
        return ({
          ...effect,
          enabled: effectOverride
        });
      })
    });
  });
}

