import {
  ConstantOperation,
  constantType,
  MapOperation,
  MapType,
  ObjectType, Optional, OptionalType,
  StringOperation,
  stringType,
  Type, ValueOperation, ValueType
} from "common/types/index.ts";
import {
  AssetTreeId,
  SceneTreeId
} from "common/legends/index.ts";

export type AssetNavigator = {
  searchTerm: string;
  selectedCharacterTreeId: AssetTreeId | undefined;
  expanded: Record<AssetTreeId, true>
};

export type AssetNavigatorOperation =
  | {type: "update-search-term", operations: StringOperation[]}
  | {type: "update-selected-character-tree-id", operations: ValueOperation<Optional<AssetTreeId>, ConstantOperation>[]}
  | {type: "update-expanded", operations: MapOperation<SceneTreeId, boolean, ConstantOperation>[]}
  ;

export const characterNavigatorType: Type<AssetNavigator, AssetNavigatorOperation> = new ObjectType({
  searchTerm: stringType,
  selectedCharacterTreeId: new ValueType(new OptionalType(constantType)),
  expanded: new MapType<AssetTreeId, true, ConstantOperation>(constantType)
});

export const AssetNavigator = {
  getSearchTerm: (value: AssetNavigator): string => value.searchTerm
};
export const AssetNavigatorOperation = {
  updateSearchTerm: (operations: StringOperation[]): AssetNavigatorOperation[] => [{type: "update-search-term", operations}]
};
