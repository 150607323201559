import {z} from "zod";
import {NodeID, UserID} from "#common/legends/index.ts";
import {ConstantOperation, constantType, Encrypted, FileReference, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {RollRequests, RollResults} from "#common/qlab/index.ts";
import {RollRequestID} from "#common/qlab/message/roll-request/index.ts";
import {Dnd5eAbilityCheckType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {QLabMessageID} from "../q-lab-message-id.ts";

export const Dnd5eAbilityCheckMessage = z.object({
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  referenceMessageID: z.optional(QLabMessageID),
  abilityCheckType: Dnd5eAbilityCheckType,
  abilityRollID: RollRequestID,
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
});
export type Dnd5eAbilityCheckMessage = z.infer<typeof Dnd5eAbilityCheckMessage>;

export type Dnd5eAbilityCheckMessageOperation =
  | {type: "update-user-i-d", operations: ConstantOperation[]}
  | {type: "update-icon", operations: ConstantOperation[]}
  | {type: "update-node-i-d", operations: ConstantOperation[]}
  | {type: "update-reference-message-i-d", operations: ConstantOperation[]}
  | {type: "update-ability-check-type", operations: ConstantOperation[]}
  | {type: "update-ability-roll-i-d", operations: ConstantOperation[]}
  | {type: "update-roll-requests", operations: ConstantOperation[]}
  | {type: "update-roll-results", operations: ValueOperation<Optional<Encrypted<RollResults>>, ConstantOperation>[]}
  ;


export const dnd5eAbilityCheckMessageType: Type<Dnd5eAbilityCheckMessage, Dnd5eAbilityCheckMessageOperation> = new ObjectType({
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  referenceMessageID: constantType,
  abilityCheckType: constantType,
  abilityRollID: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType)
});
