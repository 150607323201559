import {Dnd5eActionAttackRoll} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/optional/index.ts";
import {Dice} from "common/dice/dice.ts";
import {useState} from "react";
import {RollRequestView} from "../../roll-request-view.tsx";
import {IconButton} from "#lib/components/index.ts";
import {FaSkull} from "react-icons/fa6";
import {Dnd5eActionSegmentView} from "./dnd-5e-action-segment-view.tsx";

export function Dnd5eActionAttackRollView({segment, rollRequests, rollResults}: {
  segment: Dnd5eActionAttackRoll,
  rollRequests: RollRequests,
  rollResults: Optional<RollResults>
}) {
  const [critToggle, setCritToggle] = useState(false);

  const isCritDefault = rollResults && rollResults[segment.attackRollID] && Dice.getSuccesses(rollResults[segment.attackRollID]!) > 0;
  const isCrit = critToggle ? !isCritDefault : isCritDefault;

  return <>
    <div className="w-full flex bg-zinc-900/50">
      <span className="flex-grow px-2 py-2 font-bold italic text-sm text-center">ATTACK ROLL</span>
    </div>

    <RollRequestView rollType="attack-roll" rollRequest={rollRequests[segment.attackRollID]} rollResult={rollResults ? rollResults[segment.attackRollID] : undefined}/>

    <div className="w-full flex bg-zinc-900/50">
      <span className="flex-grow pl-14 pr-4 py-2 font-bold italic text-sm text-center">{isCrit ? "CRIT " : ""} DAMAGE</span>
      <IconButton variant="tertiary" className={isCrit ? "text-red-400" : undefined} onClick={() => setCritToggle(p => !p)} title={!isCrit ? "Make it a Crit!" : "Make it a non-Crit"}>
        <FaSkull/>
      </IconButton>
    </div>

    {isCrit && segment.onCriticalHit.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests} rollResults={rollResults}/>)}
    {!isCrit && segment.onHit.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests} rollResults={rollResults}/>)}
  </>
}