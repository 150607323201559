import {z} from "zod";
import {MeasurementPath, MeasurementPathOperation, measurementPathType} from "./measurement-path.ts";
import {MeasurementCone, MeasurementConeOperation, measurementConeType} from "./measurement-cone.ts";
import {MultiType, ValueOperation, ValueType} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MeasurementRectangle, MeasurementRectangleOperation, measurementRectangleType} from "./measurement-rectangle.ts";
import {MeasurementCircle, MeasurementCircleOperation, measurementCircleType} from "./measurement-circle.ts";
import {MeasurementBeam, MeasurementBeamOperation, measurementBeamType} from "./measurement-beam.ts";

export const Measurement = z.discriminatedUnion("type", [
  z.object({type: z.literal("path"), data: MeasurementPath}),
  z.object({type: z.literal("cone"), data: MeasurementCone}),
  z.object({type: z.literal("rectangle"), data: MeasurementRectangle}),
  z.object({type: z.literal("circle"), data: MeasurementCircle}),
  z.object({type: z.literal("beam"), data: MeasurementBeam})
]);
export type Measurement = z.infer<typeof Measurement>;

export const MeasurementOperation = ValueOperation(Measurement, z.discriminatedUnion("type", [
  z.object({type: z.literal("path"), operations: z.array(MeasurementPathOperation)}),
  z.object({type: z.literal("cone"), operations: z.array(MeasurementConeOperation)}),
  z.object({type: z.literal("rectangle"), operations: z.array(MeasurementRectangleOperation)}),
  z.object({type: z.literal("circle"), operations: z.array(MeasurementCircleOperation)}),
  z.object({type: z.literal("beam"), operations: z.array(MeasurementBeamOperation)})
]));
export type MeasurementOperation = z.infer<typeof MeasurementOperation>;

export type MeasurementTypes = {
  path: Type<MeasurementPath, MeasurementPathOperation>;
  cone: Type<MeasurementCone, MeasurementConeOperation>;
  rectangle: Type<MeasurementRectangle, MeasurementRectangleOperation>;
  circle: Type<MeasurementCircle, MeasurementCircleOperation>;
  beam: Type<MeasurementBeam, MeasurementBeamOperation>;
};
export const measurementType: Type<Measurement, MeasurementOperation> = new ValueType(new MultiType<MeasurementTypes>({
  path: measurementPathType,
  cone: measurementConeType,
  rectangle: measurementRectangleType,
  circle: measurementCircleType,
  beam: measurementBeamType
}));
