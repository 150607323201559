import {PlayerIcon} from "../common/player-icon.tsx";
import {GameSignals, UserID} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {useGame} from "../../../routes/game/index.ts";
import {MapSignals} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function PlayersStatus() {
  const game = useGame();
  const {owner, players} = useMemo(() => GameSignals(game), [game]);
  const playerEntities = useRefValue(useMemo(() => MapSignals.expand(players), [players]));
  const resolvedOwner = useRefValue(owner);
  return (<div className="inline-flex gap-1 items-center">
    {resolvedOwner && <PlayerIcon userID={resolvedOwner.id}/>}
    {Object.keys(playerEntities).filter(userID => userID !== resolvedOwner?.id).map(userID => <PlayerIcon key={userID} userID={userID as UserID}/>)}
  </div>);
}