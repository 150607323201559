export const Base64 = {
  base64toArrayBuffer: (data: string): ArrayBuffer => {
    return Uint8Array.from(atob(data), c => c.charCodeAt(0));
  },
  arrayBufferToBase64: (data: ArrayBuffer): string => {
    const arrayBuffer = new Uint8Array(data);
    let binary = '';
    for (let i = 0; i < arrayBuffer.length; i ++) {
      binary += String.fromCharCode(arrayBuffer[i]);
    }
    return btoa(binary);
  }
}