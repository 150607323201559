import {NodeId, TokenNode} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {useTokenSheet, useTokenSheetReference} from "./use-token-sheet.ts";
import {useIsSelected} from "./use-is-selected.ts";
import {NodeView} from "./node-view.tsx";
import {HealthIndicatorView} from "./health-indicator-view.tsx";
import {StatusIndicatorsView} from "./status-indicators-view.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {useIsNodeTurn} from "../../../../common/turn-tracker/use-is-current-turn.ts";
import {SheetReferenceProvider} from "../../../../common/sheet/sheet-reference-context.ts";
import {useAsset} from "../../../../common/character/use-asset.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";

export type TokenNodeViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;

  opacity: number;
  value: TokenNode;
};

export function TokenNodeView({projection, model, view, visibilityMask, accessNodeIDs, opacity, value}: TokenNodeViewProps) {
  const asset = useRefValue(useAsset(value.tokenReference.assetID));

  const sheetReference = useTokenSheetReference(value);
  const sheet = useTokenSheet(value);

  const isNodeTurn = useObservable(useIsNodeTurn(value.id), false, [value.id]);

  const isSelected = useIsSelected(value.id);
  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;

  const token = !asset ? undefined : asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID);
  const valueOpacity = value.opacity * opacity;
  let valueModel = Transform.divide(value.transform, model);
  const interfaceModel = {...valueModel, scale: valueModel.scale * value.interfaceScale};

  return (<SheetReferenceProvider value={sheetReference}>
    {token && <React.Fragment key={value.id}>
      {token.children.map((child) =>
        <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
      )}

      <HealthIndicatorView
        projection={projection} model={interfaceModel} view={view}
        sheetRef={sheet}
        origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
        size={Vector2.multiply(token.size, 1 / value.interfaceScale)}
        displayNumber={value.ownerIDs.length > 0}
      />

      <StatusIndicatorsView
        projection={projection} model={interfaceModel} view={view}
        value={sheet}
        opacity={opacity}
        origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
        size={Vector2.multiply(token.size, 1 / value.interfaceScale)} />

      {(isSelected || isNodeTurn) && <SelectionIndicator
          projection={projection} view={view} model={interfaceModel}
          origin={Vector2.multiply(token.origin, 1 / value.interfaceScale)}
          size={Vector2.multiply(token.size, 1 / value.interfaceScale)}
          repeatX={1} repeatY={1}
          color={isSelected ? Color.WHITE : Color.GREEN}/>}
      {isSelected && <SelectionIndicator
          projection={projection} view={view} model={interfaceModel}
          origin={[token.origin[0] - token.pivot[0] + 2, token.origin[1] - token.pivot[1] + 2]}
          size={[4, 4]}
          repeatX={1} repeatY={1}
          color={Color.GREEN} />}

      {value.ownerIDs.length > 0 && <TextShader projection={projection} view={view} model={Transform.divide({
        position: [
          ((token.size[0] || 0)/2 - (token.origin[0] || 0)),
          -(token.origin[1] || 32)-2
        ],
        scale: value.interfaceScale,
        rotation: 0
      }, valueModel)} size={22} hTextAlign="center" vTextAlign="top" value={value.name} />}
    </React.Fragment>}

    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
    })}
  </SheetReferenceProvider>);
}