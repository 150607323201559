import {GameID, Node, NodeId, PlayerRef, Scene, SceneFn, SceneID, SceneOperation, UserID} from "common/legends/index.ts";
import {HSLA, Optional, Point, Transform, TransformOperation, ValueFn} from "common/types/index.ts";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {TokenViewportPropertiesFn} from "./token-viewport-properties.ts";
import {useInstance, useObservable} from "#lib/qlab/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {pipe} from "common/pipe";
import {distinct, map, subscribe} from "common/observable";
import {AccessMask, AccessMaskFn, AccessMaskOperation} from "common/legends/visibility/index.ts";
import {measurementType} from "common/legends/measurement/index.ts";
import {userIDRef, useUserID} from "#lib/auth/use-get-user-id.ts";
import {SceneSelectionRef} from "../../container/editor/state/selection-ref.ts";
import {PlayerControllerProvider, usePlayerController} from "../../container/view/player-controller-provider.ts";
import {useActiveControllerReferenceNode} from "./use-active-controller-node-reference.ts";
import {useNode} from "./use-node.ts";
import {useNodeTreeIDs} from "./use-node-tree-i-ds.ts";
import {NodeDropZone, useTempRef} from "#lib/components/index.ts";
import {contextMenuType} from "../../../routes/game/context-menu/context-menu.ts";
import {usePlayer} from "../../../routes/game/use-player.ts";
import {useToolHandlers} from "../../../routes/game/use-tool-handlers.ts";
import {usePlayerToolMode} from "../../container/view/use-player-tool-mode.ts";
import {useSceneFocus} from "./use-scene-focus.ts";
import {LegendsWebGL2Canvas} from "../../../routes/game/index.ts";
import {SelectedNodeIDsProvider} from "../common/node/selected-node-i-ds.ts";
import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {SceneView} from "../common/node/layer-view/scene-view.tsx";
import {ScenePingView} from "../common/scene-ping.tsx";
import {MeasurementView} from "../../common/measurement/measurement-view.tsx";
import {SceneMeasurementView} from "../common/scene-measurement.tsx";
import {GridProvider} from "../common/node/node-view/grid-view/grid-context.ts";
import {useGetNode} from "../../common/node/use-get-node.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {EditorContextMenu} from "../../container/editor/editor-context-menu/editor-context-menu.tsx";
import {ToolView} from "../common/tool/tool-view.tsx";
import {MutableRef, Ref} from "common/ref";
import {CurrentSceneProvider} from "../scene/current-scene-provider.ts";
import {useGoToNode} from "../../container/editor/use-go-to-node.ts";
import {isNodeOwner} from "../../common/node/use-is-party-node.ts";

export function TokenViewport({storeId, sceneID, scene}: {
  storeId: GameID;
  sceneID: SceneID,
  scene: MutableRef<Scene, SceneOperation[]>
}) {
  const {children} = useMemo(() => SceneFn.expand(scene), [scene]);
  const sceneValue = useRefValue(scene);

  const [size, setSize] = useState<[number, number]>([0, 0]);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = divRef?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setSize([element.clientWidth, element.clientHeight]);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [setSize, divRef]);

  const playerController = usePlayerController();
  const {activeController, selectedControllerNodeIds, toolMode} = useMemo(() => TokenViewportPropertiesFn.expand(playerController.state), [playerController.state]);
  const view = useMemo((): MutableRef<Transform, TransformOperation[]> => {
    return new MutableRef({
      value(): Transform {
        return activeController.value?.view || Transform.DEFAULT;
      },
      observe: pipe(activeController.observe, map(t => t?.view || Transform.DEFAULT), distinct()),
      apply: (fn) => activeController.apply((prev) => {
        if (prev === undefined) return [];
        return ValueFn.apply([{type: "update-view", operations: fn(prev.view)}]);
      }).then(t => t?.view || Transform.DEFAULT)
    })
  }, [activeController]);

  const getNode = useGetNode();
  const userID = useUserID()!;

  const activeTokenNode = useActiveControllerReferenceNode();

  // Automatically switch characters when selecting owned node
  useEffect(() => {
    return pipe(
      selectedControllerNodeIds.observe,
      subscribe(async (selectedNodeIDs) => {
        if (selectedNodeIDs.length === 1) {
          const nodeRef = selectedNodeIDs[0];
          const node = getNode(nodeRef.nodeId);
          if (node?.type === "token" && (node.data.ownerIDs.includes(userID) || node.data.ownerIDs.includes("GLOBAL" as UserID))) {
            if (activeTokenNode.value === nodeRef.nodeId) return;
            activeTokenNode.apply(prevValue => {
              if (prevValue === nodeRef.nodeId) return [];
              return [{type: "set", prevValue, nextValue: nodeRef.nodeId}];
            });
          }
        }
      })
    );
  }, [selectedControllerNodeIds, getNode, activeTokenNode]);

  // If the scene is newly loaded, go to controller node!
  const goToNode = useGoToNode();
  const activeNodeID = useRefValue(activeTokenNode);
  useEffect(() => {
    // wait a tick to ensure databaseRef is updated
    setTimeout(() => {
      if (activeNodeID) goToNode(activeNodeID);
    }, 0);
  }, [scene, goToNode]);

  const activeNodeIdRef = useMemo((): Ref<Optional<NodeId>> => new MutableRef({
    value() {
      return activeController.value?.controllerNodeID;
    },
    observe: pipe(
      activeController.observe,
      map(value => value?.controllerNodeID),
      distinct()
    ),
    apply: () => Promise.reject("Unsupported")
  }), [activeController]);

  const rootSelectionRef = useMemo<SceneSelectionRef>(() => ({type: "scene", storeId, resourceId: sceneID}), [storeId, sceneID]);

  const instance = useInstance();
  const ping = useCallback((p: Point, focus: boolean, color: HSLA) => {
    instance.stream(storeId, {type: "ping", data: {
      type: "scene",
      sceneID: sceneID,
      position: p,
      focus,
      color
    }}).catch(console.error);
  }, [instance, storeId, sceneID]);

  const node = useNode(useRefValue(activeTokenNode));
  const accessMask = useMemo((): MutableRef<AccessMask, AccessMaskOperation[]> => {
    const valueFn = (n: Optional<Node>) => {
      if (n?.type !== "token") return 1 as AccessMask;
      return n.data.accessMask;
    }
    return new MutableRef({
      value(): AccessMask {return valueFn(node.value)},
      observe: pipe(node.observe, map(valueFn), distinct()),
      apply: fn => node.apply(prev => {
        if (prev?.type !== "token") return [];
        const operations = fn(prev.data.accessMask);
        if (operations.length <= 0) return [];
        return [{type: "token", operations: [{type: "update-access-mask", operations}]}];
      }).then(valueFn)
    })
  }, [node]);

  const visibleNodeIDsRef = useNodeTreeIDs(activeTokenNode);
  const accessMaskValue = useRefValue(accessMask);
  const visibilityNodeIDs = useRefValue(visibleNodeIDsRef);

  const measurement = useTempRef(measurementType, undefined);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextMenu = useTempRef(contextMenuType, undefined);

  const player = usePlayer(useUserID()!);
  const {color} = useMemo(() => PlayerRef(player), [player]);

  const isAccessible = useCallback((node: Node) => {
    return node.type === "wall" || node.type === "area" || (node.data.selectionMask & accessMask.value) > 0 || activeTokenNode.value === node.data.id || isNodeOwner(node, userIDRef.value!);
  }, [accessMask, activeTokenNode]);
  const isVisible = useCallback((node: Node) => {
    return AccessMaskFn.canSee(accessMask.value, node.data.visibilityLayer) || visibleNodeIDsRef.value.includes(node.data.id);
  }, [accessMask, visibleNodeIDsRef]);
  const {onDrop, onWheel, onTouchStart, onTouchMove, onTouchEnd, ...toolHandlers} = useToolHandlers(
    canvasRef,
    rootSelectionRef,
    view,
    measurement,
    sceneValue.grid,
    children,
    selectedControllerNodeIds,
    activeNodeIdRef,
    usePlayerToolMode(),
    ping,
    useObservable(color.observe, [0, 1, 1, 1] as HSLA, [color]),
    contextMenu,
    isAccessible,
    isVisible
  );

  useSceneFocus(storeId, sceneID, view.apply);

  const [width, height] = size;
  const projection = useMemo<Matrix4f>(() => Matrix4f.orthogonal(
    -width/2, width/2,
    height/2, -height/2,
    -100, 100
  ), [width, height]);

  useEffect(() => {
    const onBodyWheel = (ev: WheelEvent) => {
      if (ev.ctrlKey) return onWheel(ev);
      return true;
    };
    document.body.addEventListener("wheel", onBodyWheel, {passive: false});
    canvasRef.current?.addEventListener("wheel", onWheel, {passive: false});
    canvasRef.current?.addEventListener("touchstart", onTouchStart, {passive: false});
    canvasRef.current?.addEventListener("touchend", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchcancel", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchmove", onTouchMove, {passive: false});
    return () => {
      document.body.removeEventListener("wheel", onBodyWheel);
      canvasRef.current?.removeEventListener("wheel", onWheel);
      canvasRef.current?.removeEventListener("touchstart", onTouchStart);
      canvasRef.current?.removeEventListener("touchend", onTouchEnd);
      canvasRef.current?.removeEventListener("touchmove", onTouchMove);
      canvasRef.current?.removeEventListener("touchcancel", onTouchEnd);
    }
  }, [canvasRef, onWheel, onTouchStart, onTouchMove, onTouchEnd]);

  const activeControllerValue = useRefValue(activeController);
  return (<div className="relative flex-1 overflow-hidden" ref={divRef}>
    <CurrentSceneProvider value={sceneID}>
      <NodeDropZone storeId={storeId} onDrop={onDrop} contextMenu={contextMenu}>
        <LegendsWebGL2Canvas ref={canvasRef} width={size[0]} height={size[1]} tabIndex={0} {...toolHandlers}>
          <PlayerControllerProvider value={usePlayerController()}>
            <SelectedNodeIDsProvider value={selectedControllerNodeIds}>
              <GridProvider value={sceneValue.grid}>
                <ClearCanvas width={size[0]} height={size[1]}/>
                <SceneView projection={projection} view={activeControllerValue?.view || Transform.DEFAULT} visibilityMask={accessMaskValue} accessNodeIDs={visibilityNodeIDs} scene={sceneValue} />
                <ScenePingView projection={projection} view={activeControllerValue?.view || Transform.DEFAULT} gameID={storeId} sceneID={sceneID} />
                <MeasurementView projection={projection} view={activeControllerValue?.view || Transform.DEFAULT} measurementRef={measurement} />
                <SceneMeasurementView projection={projection} view={activeControllerValue?.view || Transform.DEFAULT} gameID={storeId} sceneID={sceneID} scene={sceneValue} visibilityMask={accessMaskValue} visibilityNodeIDs={visibilityNodeIDs} />
                <ToolView projection={projection} view={activeControllerValue?.view || Transform.DEFAULT} value={toolMode} nodes={children} />
              </GridProvider>
            </SelectedNodeIDsProvider>
          </PlayerControllerProvider>
        </LegendsWebGL2Canvas>
      </NodeDropZone>
      <EditorContextMenu value={contextMenu} onDrop={onDrop} />
    </CurrentSceneProvider>
  </div>);
}
