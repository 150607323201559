import {QLabMessageID, RollResults} from "common/qlab/message/index.ts";
import {Dnd5eActionMessage} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {UserNodeIcon} from "../../user-node-icon.tsx";
import {useDecryptValue, useOptionalDecryptValue} from "../../use-decrypt-value.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {Dnd5eActionSegmentView} from "./dnd-5e-action-segment-view.tsx";

export function Dnd5eActionMessageView({message}: {
  messageId: QLabMessageID;
  message: Dnd5eActionMessage;
}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);

  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);

  if (rollRequests.isLoading || rollResults.isLoading) return <></>;
  return <div className="relative mt-7 mb-2 mx-2">
    <div className="rounded-lg shadow-lg bg-zinc-800/50 backdrop-blur-md">
      <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />

      <div className="pointer-events-auto overflow-hidden rounded-lg flex flex-col gap-2 items-center">
        <div className="w-full font-bold italic text-center pb-2 pt-5 bg-zinc-800/80 rounded-t-lg">{message.title}</div>
        {message.segments.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests.data} rollResults={rollResults.data}/>)}
        {message.segments.length > 0 && <div className="w-full h-2 bg-zinc-800/80" />}
      </div>
    </div>
  </div>
}
