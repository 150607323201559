import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eSavingThrowModifier,
  Dnd5eSavingThrowModifierOperation,
  Dnd5eSavingThrowModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {InputDiceExpression} from "../../dice-expression/input-dice-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {InputDnd5eSavingThrows, summarizeSavingThrows} from "./input-dnd-5e-saving-throw.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputDnd5eSavingThrowProficiency} from "./input-dnd-5e-saving-throw-proficiency.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eSavingThrowModifierViewProps = {
  value: MutableRef<Dnd5eSavingThrowModifier, Dnd5eSavingThrowModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eSavingThrowModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eSavingThrowModifierViewProps) {
  const {expression, savingThrows, proficiency, hasAdvantage, hasDisadvantage} = useMemo(() => Dnd5eSavingThrowModifierSignals(value), [value]);
  const [expanded, toggleExpanded] = useToggle(false);

  const summary = summarizeSavingThrows(useRefValue(savingThrows));

  return <div role="list-item" className="flex flex-col gap-0">
    <ExpandableLabel ref={dragRefPreview} expanded={expanded} toggleExpand={readOnly ? undefined : toggleExpanded}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup>
        <InputGroupLabel>Saving Throw</InputGroupLabel>
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel title={summary}>
          <span className="overflow-hidden overflow-ellipsis whitespace-nowrap capitalize">{summary}</span>
        </InputGroupLabel>
      </InputGroup>
      {!readOnly && <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
    </ExpandableLabel>
    {expanded && <Fieldset>
      <InputDnd5eSavingThrows value={savingThrows} readOnly={readOnly} />
      <InputGroup>
        <InputGroupLabel>Modifier</InputGroupLabel>
        <InputDiceExpression readOnly={readOnly} value={expression} />
      </InputGroup>
      <InputGroup className="pr-0">
        <InputGroupLabel>Proficiency</InputGroupLabel>
        <InputDnd5eSavingThrowProficiency value={proficiency} />
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={hasAdvantage}/>
        <InputGroupLabel>Has Advantage</InputGroupLabel>
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={hasDisadvantage}/>
        <InputGroupLabel>Has Disadvantage</InputGroupLabel>
      </InputGroup>
    </Fieldset>}
  </div>;
}
