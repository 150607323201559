import {Optional, RichText, RichTextFn} from "common/types/generic/index.ts";
import {RichTextView} from "#lib/components/input/rich-text-view.tsx";
import {Loader} from "common/loader";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";

export function Dnd5eDescriptionView({description, requests, results}: {description: Loader<Optional<RichText>>, requests: Loader<RollRequests>, results: Loader<Optional<RollResults>>}) {
  if (!description.isSuccess || !description.data || RichTextFn.isEmpty(description.data)) return <></>;

  return (<tr className="divide-x divide-slate-900/20">
      <td colSpan={2} className="text-sm py-2 text-left px-4">
        <RichTextView value={description.data} rollRequests={requests.data} rollResults={results.data} />
      </td>
    </tr>
  );
}