import {z} from "zod";
import {BooleanOperation, booleanType, ConstantOperation, constantType, ListType, ObjectType, StringOperation, stringType, Type, ZodListOperation} from "#common/types/index.ts";
import {Dnd5eItemEffectID} from "./dnd-5e-item-effect-id.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {Dnd5eModifier, Dnd5eModifierOperation, dnd5eModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eItemEffect = z.object({
  effectID: Dnd5eItemEffectID,
  name: z.string(),
  enabled: z.boolean(),
  modifiers: z.array(Dnd5eModifier)
});
export type Dnd5eItemEffect = z.infer<typeof Dnd5eItemEffect>;

export const Dnd5eItemEffectOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-effect-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-enabled"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eModifier, Dnd5eModifierOperation))})
]);
export type Dnd5eItemEffectOperation = z.infer<typeof Dnd5eItemEffectOperation>;

export const dnd5eItemEffectType: Type<Dnd5eItemEffect, Dnd5eItemEffectOperation> = new ObjectType({
  effectID: constantType,
  name: stringType,
  enabled: booleanType,
  modifiers: new ListType(dnd5eModifierType)
});

export const Dnd5eItemEffectFn = {
  getGroup(effect: Dnd5eItemEffect): string | undefined {
    const i = effect.name.indexOf(":");
    return (i !== -1)
      ? effect.name.substring(0, i)
      : undefined;
  }
}
export function Dnd5eItemEffectSignal(signal: MutableRef<Dnd5eItemEffect, Dnd5eItemEffectOperation[]>) {
  return {
    name: PropertyRef<Dnd5eItemEffect, Dnd5eItemEffectOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(signal),
    enabled: PropertyRef<Dnd5eItemEffect, Dnd5eItemEffectOperation, boolean, BooleanOperation>(
      value => value.enabled,
      operations => [{type: "update-enabled", operations}]
    )(signal),
    modifiers: ListPropertyRef<Dnd5eItemEffect, Dnd5eItemEffectOperation, Dnd5eModifier, Dnd5eModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(signal)
  };
}

export function getItemEffectID(value: Dnd5eItemEffect): Dnd5eItemEffectID {
  return value.effectID;
}
