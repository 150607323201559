import {TreePath} from "./tree-path.ts";
import {Tree} from "./tree.ts";

export type InsertTreeOperation<Item> = {
  type: "insert";
  path: TreePath;
  item: Item;
};
export type DeleteTreeOperation<Item> = {
  type: "delete";
  path: TreePath;
  prevItem: Item;
};
export type ApplyTreeOperation<ItemOperation> = {
  type: "apply",
  path: TreePath;
  operations: ItemOperation[]
};
export type MoveTreeOperation = {
  type: "move",
  fromPath: TreePath;
  toPath: TreePath;
};


export type TreeOperation<Item extends Tree<Item>, ItemOperation> =
  | InsertTreeOperation<Item>
  | DeleteTreeOperation<Item>
  | MoveTreeOperation
  | ApplyTreeOperation<ItemOperation>
  ;
export const TreeOperation = {
  apply: <Item extends Tree<Item>, ItemOperation>(path: TreePath, operations: ItemOperation[]): TreeOperation<Item, ItemOperation>[] => [{type: "apply", path, operations}],
  insert: <Item extends Tree<Item>, ItemOperation>(path: TreePath, item: Item): TreeOperation<Item, ItemOperation>[] => [{type: "insert", path, item}],
  delete: <Item extends Tree<Item>>(path: TreePath, prevItem: Item): TreeOperation<Item, any>[] => [{type: "delete", path, prevItem}]
};
