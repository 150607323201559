import {Sheet} from "common/legends/index.ts";
import {listIdentity} from "common/observable";
import {useMemo} from "react";
import {Dnd5eResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Optional} from "common/types/index.ts";
import {Ref} from "common/ref";

export function useSheetResources(value: Ref<Optional<Sheet>>): Ref<Dnd5eResource[]> {
  return useMemo(() => value.map(sheet => {
    if (sheet?.type === "dnd-5e-character") {
      const raceResources = sheet.data.race.features.filter(feature => feature.enabled).flatMap(f => f.resources);
      const backgroundResources = sheet.data.background.features.filter(feature => feature.enabled).flatMap(f => f.resources);
      const classesResources = sheet.data.classes.flatMap(c => c.features.filter(f => f.level <= c.level).map(f => f.feature)).filter(feature => feature.enabled).flatMap(f => f.resources);
      const inventoryResources = sheet.data.inventory.filter(item => item.equipped).flatMap(item => item.resources);
      const featureResources = sheet.data.features.filter(f => f.enabled).flatMap(f => f.resources);
      return [
        ...raceResources,
        ...backgroundResources,
        ...classesResources,
        ...inventoryResources,
        ...featureResources
      ];
    } else if (sheet?.type === "dnd-5e-stat-block") {
      return sheet.data.features.filter(feature => feature.enabled).flatMap(feature => feature.resources);
    } else {
      return [];
    }
  }).distinct(listIdentity), [value]);
}
