import {applyAll, invertAll, Optional, OptionalOperation, transformAll, Type, ValueOperation} from "../../types/index.ts";
import {UserID} from "../../legends/index.ts";
import {StoreOperation, storeType, StoreValue} from "./store.ts";
import {QLabStoreChangesetID} from "#common/qlab/store/q-lab-store-changeset-id.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export type QLabStoreRevision = number;
export type QLabStoreChangeset = {
  changesetId: QLabStoreChangesetID;
  userId: UserID;
  revision: QLabStoreRevision;
  operations: ValueOperation<Optional<StoreValue>, OptionalOperation<StoreOperation>>[];
};
export interface QLabStore {
  value: Optional<StoreValue>;
  revision: QLabStoreRevision;
}

export const qlabStoreType: Type<QLabStore, QLabStoreChangeset> = {
  apply: (value: QLabStore, operation: QLabStoreChangeset): QLabStore => {
    if (value.revision !== operation.revision) {
      throw new Error("Mismatch between store and changeset. Store: " + JSON.stringify(value) + " Changeset: " + JSON.stringify(operation));
    }
    return {
      value: applyAll(storeType, value.value, operation.operations),
      revision: value.revision + 1
    };
  },
  invert: (operation: QLabStoreChangeset) => {
    return [{
      ...operation,
      operations: invertAll(storeType, operation.operations)
    }];
  },
  transform: (leftOperation: QLabStoreChangeset, topOperation: QLabStoreChangeset, tieBreaker: boolean): QLabStoreChangeset[] => {
    if (leftOperation.revision !== topOperation.revision) throw new Error("Mismatch revision. " + JSON.stringify(leftOperation) + " -- " + JSON.stringify(topOperation));
    if (leftOperation.changesetId === topOperation.changesetId) return [];
    return [{
      ...leftOperation,
      operations: transformAll(storeType, leftOperation.operations, topOperation.operations, tieBreaker)[0],
      revision: tieBreaker ? leftOperation.revision : leftOperation.revision + 1
    }];
  },
  migrateValue(value: any): QLabStore {
    return {
      revision: value.revision,
      value: storeType.migrateValue(value.value)
    };
  },
  migrateOperation(operation: any): QLabStoreChangeset[] {
    return [{
      changesetId: operation.changesetId,
      userId: operation.userId,
      revision: operation.revision,
      operations: operation.operations.flatMap(storeType.migrateOperation)
    }];
  },
  validate: (value: any): ValidationError[] => {
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type. Expected QLabStore.", value}}];
    if (typeof value.revision !== "number") return [{path: [], data: {message: "Invalid type. Expected QLabStore.", value}}];
    return storeType.validate(value.value);
  }
}
