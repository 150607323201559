import React from "react";
import {ButtonBar} from "#lib/components/index.ts";
import {SheetReference} from "../../../../common/sheet/sheet-reference.ts";
import {SheetReferenceProvider} from "../../../../common/sheet/sheet-reference-context.ts";
import {QuickAccessHitPointsMenu} from "./quick-access-hit-points.tsx";
import {QuickAccessDiceMenu} from "./quick-access-dice-menu.tsx";
import {QuickAccessActionsMenu} from "./quick-access-actions.tsx";
import {QuickAccessEffectsMenu} from "./quick-access-effects.tsx";
import {QuickAccessAbilityChecksMenu} from "./quick-access-ability-checks-menu.tsx";
import {Optional} from "common/types/index.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {Ref} from "common/ref";

export type ActionMenuProps = {
  sheetRef: Ref<Optional<SheetReference>>;
};

export const QuickAccessMenu = React.memo(function QuickAccessMenu({sheetRef}: ActionMenuProps) {
  const sheet = useSheetSignal(sheetRef);
  const isSheetSelected = useComputedValue(sheet, sheet => sheet !== undefined)
  return (isSheetSelected ? <SheetReferenceProvider value={sheetRef}>
    <ButtonBar className="flex flex-row gap-0.5 rounded-md overflow-hidden">
      <QuickAccessHitPointsMenu sheetRef={sheetRef} />
    </ButtonBar>

    <ButtonBar className="flex flex-row gap-0.5 rounded-md overflow-hidden">
      <QuickAccessDiceMenu />
      <QuickAccessActionsMenu sheetRef={sheetRef} />
      <QuickAccessEffectsMenu />
      <QuickAccessAbilityChecksMenu sheetRef={sheetRef} />
    </ButtonBar>
  </SheetReferenceProvider> : <></>);
});
