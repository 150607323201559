import {NodeId, Sheet, SheetOperation} from "common/legends/index.ts";
import {useContext, useMemo} from "react";
import {combine, distinct, from, map, Observable} from "common/observable";
import {pipe} from "common/pipe";
import {Optional, Tree} from "common/types/index.ts";
import {PlayerControllerContext, usePlayerController} from "../../../../container/view/player-controller-provider.ts";
import {EditorContext, useEditor} from "../../../../container/editor/editor-context.ts";
import {editorNodeIDObservable} from "../use-editor-sheet-reference.ts";
import {SheetReference, SheetReferenceFn} from "../../../../common/sheet/sheet-reference.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {MutableRef, Ref} from "common/ref";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {EditorState} from "../../../../container/editor/state/index.ts";
import {TokenViewportProperties} from "../../../../viewport/token/token-viewport-properties.ts";
import {getNode} from "../../../../common/node/use-get-node.ts";

export function useSelectedNodeIDRef(): Ref<Optional<NodeId>> {
  const playerController = useContext(PlayerControllerContext);
  const editor = useContext(EditorContext);
  return useMemo(() => new MutableRef({
    value(): Optional<NodeId> {
      const controllerNodeID = playerController?.state.value.activeController?.controllerNodeID;
      if (controllerNodeID) return controllerNodeID;
      const editorState = editor?.state.value;
      if (editorState?.type === "scene" || editorState?.type === "asset") {
        const selectedNodeIds = editorState.data.selectedNodeIds;
        if (selectedNodeIds.length === 0) return undefined;
        return selectedNodeIds[0].nodeId;
      }
      return undefined;
    },
    observe: playerController
      ? pipe(playerController.state.observe, map(state => state.activeController?.controllerNodeID), distinct())
      : (editor ? editorNodeIDObservable(editor.state.observe) : from(undefined)),
    apply: () => Promise.reject("Unsupported")
  }), [playerController, editor]);
}

export function useSelectedNodeID(): Observable<NodeId | undefined> {
  return useSelectedNodeIDRef().observe;
}

export function useSelectedSheetReference(): Ref<Optional<SheetReference>> {
  const editor = useEditor();
  const playerController = usePlayerController();
  const databaseRef = useDatabase();

  return useMemo((): Ref<Optional<SheetReference>> => {
    if (editor !== undefined) {
      const valueFn = (database: QLabDatabase, editor: EditorState): Optional<SheetReference> => {
        if (editor?.type === "asset") {
          const {assetID, tokenID} = editor.data.tokenReference;
          const resource = database.resources[assetID];
          if (resource?.type !== "asset") return undefined;
          const token = resource.data.tokens.find(token => token.tokenID === tokenID);
          if (token?.sheetId === undefined) return undefined;
          return {type: "link", assetID: assetID, sheetID: token.sheetId};
        } else if (editor?.type === "scene") {
          if (editor.data.selectedNodeIds.length === 0) return undefined;
          const nodeIDReference = editor.data.selectedNodeIds[0];
          if (nodeIDReference.type === "scene-node") {
            const resource = database.resources[nodeIDReference.resourceId];
            if (resource?.type !== "scene") return undefined;
            const node = Tree.getItemById(resource.data.children, nodeIDReference.nodeId);
            if (node?.type !== "token") return undefined;
            const tokenSheet = node.data.tokenSheets[node.data.tokenReference.tokenID];
            if (tokenSheet?.type === "link") return {type: "link", assetID: node.data.tokenReference.assetID, sheetID: tokenSheet.data};
            if (tokenSheet?.type === "copy") return {type: "copy", nodeID: nodeIDReference.nodeId, tokenID: node.data.tokenReference.tokenID};
            return undefined;
          }
        }
        return undefined;
      };

      return new MutableRef({
        value() {
          return valueFn(databaseRef.value, editor.state.value);
        },
        observe: pipe(
          combine(databaseRef.observe, editor.state.observe),
          map(([database, state]) => valueFn(database, state)),
          distinct(SheetReferenceFn.equals)
        ),
        apply: () => {throw new Error("Unsupported");}
      })
    } else if (playerController !== undefined) {
      const valueFn = (database: QLabDatabase, state: TokenViewportProperties): Optional<SheetReference> => {
        const nodeID = state.activeController?.controllerNodeID;
        if (nodeID === undefined) return undefined;
        const node = getNode(database, nodeID);
        if (node?.type !== "token") return undefined;

        const {assetID, tokenID} = node.data.tokenReference;
        const sheet = node.data.tokenSheets[tokenID];
        if (sheet === undefined) return undefined;
        if (sheet.type === "link") return {type: "link", assetID, sheetID: sheet.data};
        return {type: "copy", nodeID: nodeID, tokenID};
      }

      return new MutableRef({
        value() {
          return valueFn(databaseRef.value, playerController.state.value);
        },
        observe: pipe(
          combine(databaseRef.observe, playerController.state.observe),
          map(([database, playerController]) => valueFn(database, playerController)),
          distinct(SheetReferenceFn.equals)
        ),
        apply: () => Promise.reject("Unsupported")
      });
    } else {
      return new MutableRef({
        value() {return undefined},
        observe: from(undefined),
        apply: () => Promise.reject("Unsupported")
      });
    }
  }, [editor, playerController, databaseRef]);
}

export function useSelectedSheet(): MutableRef<Optional<Sheet>, SheetOperation[]> {
  return useSheetSignal(useSelectedSheetReference());
}