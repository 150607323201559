import React, {forwardRef, HTMLAttributes, Ref, useCallback} from "react";
import {Menu} from "@headlessui/react";
import {createPortal} from "react-dom";
import {Button, ButtonBar} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faRectangle, faRuler, faSlashForward, faTriangle} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {Popper} from "#lib/components/popper/popper.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {Measurement, MeasurementOperation, MeasurementTypes} from "../../../../common/tool-mode/measurement/measurement.ts";
import {MutableRef} from "common/ref";
import {HSLA, ValueFn} from "common/types/generic/index.ts";
import {useTypedRef} from "../../../../common/use-typed-ref.ts";
import {useUnwrapValueRef} from "../../../../common/use-unwrap-value-ref.ts";
import {MeasurementConeProperties} from "../../../../common/tool-mode/measurement/measurement-cone-properties.tsx";
import {MeasurementBeamProperties} from "../../../../common/tool-mode/measurement/measurement-beam-properties.tsx";
import {MeasurementRectangleProperties} from "../../../../common/tool-mode/measurement/measurement-rectangle-properties.tsx";
import {MeasurementCircleProperties} from "../../../../common/tool-mode/measurement/measurement-circle-properties.tsx";
import {usePlayer} from "../../../../../routes/game/use-player.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";

export const MeasurementPanel = forwardRef(function MeasurementPanel({measurementRef, ...props}: {measurementRef: MutableRef<Measurement, MeasurementOperation[]>} & HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) {
  const player = usePlayer(useUserID()!);

  const setPath = useCallback(() => {
    measurementRef.apply(prev => ValueFn.set(prev, {type: "path", data: {}}));
  }, [measurementRef, player]);
  const setCone = useCallback(() => {
    const [h,s,l] = player.value.color;
    measurementRef.apply(prev => ValueFn.set(prev, {type: "cone", data: {
      sticky: false,
      fillColor: [h, s, l, 0.5] as HSLA,
      sizeAngle: 2 * Math.atan(0.5) * 360 / (2 * Math.PI)
    }}));
  }, [measurementRef, player]);
  const setRectangle = useCallback(() => {
    const [h,s,l] = player.value.color;
    measurementRef.apply(prev => ValueFn.set(prev, {type: "rectangle", data: {
      sticky: false,
      fillColor: [h, s, l, 0.5] as HSLA,
    }}));
  }, [measurementRef, player]);
  const setCircle = useCallback(() => {
    const [h,s,l] = player.value.color;
    measurementRef.apply(prev => ValueFn.set(prev, {type: "circle", data: {
      sticky: false,
      fillColor: [h, s, l, 0.5] as HSLA,
    }}));
  }, [measurementRef, player]);
  const setBeam = useCallback(() => {
    const [h,s,l] = player.value.color;
    measurementRef.apply(prev => ValueFn.set(prev, {type: "beam", data: {
      sticky: false,
      fillColor: [h, s, l, 0.5] as HSLA,
      width: 64
    }}));
  }, [measurementRef, player]);

  const [type, typeRef] = useTypedRef<MeasurementTypes>(useUnwrapValueRef(measurementRef));

  return <>
    {createPortal(<Menu.Items ref={ref} as={Popper} {...props} className="mt-4 w-full max-w-screen-md flex flex-row gap-0">
      <BaseComponent className="w-full mx-0">
        <ButtonBar>
          <Button className="flex-1" variant={type === "path" ? "primary" : undefined} onClick={type !== "path" ? setPath : undefined}>
            <FontAwesomeIcon icon={faRuler} />
            Path
          </Button>
          <Button className="flex-1" variant={type === "cone" ? "primary" : undefined} onClick={type !== "cone" ? setCone : undefined}>
            <FontAwesomeIcon icon={faTriangle} />
            Cone
          </Button>
          <Button className="flex-1" variant={type === "rectangle" ? "primary" : undefined} onClick={type !== "rectangle" ? setRectangle : undefined}>
            <FontAwesomeIcon icon={faRectangle} />
            Rectangle
          </Button>
          <Button className="flex-1" variant={type === "circle" ? "primary" : undefined} onClick={type !== "circle" ? setCircle : undefined}>
            <FontAwesomeIcon icon={faCircle} />
            Circle
          </Button>
          <Button className="flex-1" variant={type === "beam" ? "primary" : undefined} onClick={type !== "beam" ? setBeam : undefined}>
            <FontAwesomeIcon icon={faSlashForward} />
            Beam
          </Button>
        </ButtonBar>
        {type === "cone" && <MeasurementConeProperties valueRef={typeRef} />}
        {type === "beam" && <MeasurementBeamProperties valueRef={typeRef} />}
        {type === "rectangle" && <MeasurementRectangleProperties valueRef={typeRef} />}
        {type === "circle" && <MeasurementCircleProperties valueRef={typeRef} />}
      </BaseComponent>
    </Menu.Items>, usePortal())}
  </>
});
