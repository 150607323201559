import {z} from "zod";
import {
  Dnd5eAttackRollModifier,
  Dnd5eAttackRollModifierOperation,
  dnd5eAttackRollModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-attack-roll-modifier.ts";
import {Dnd5eDCModifier, Dnd5eDCModifierOperation, dnd5eDCModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-d-c-modifier.ts";
import {
  Dnd5eDamageRollModifier,
  Dnd5eDamageRollModifierOperation,
  dnd5eDamageRollModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-roll-modifier.ts";
import {MultiType, Type} from "#common/types/index.ts";
import {
  Dnd5eSpellSlotConsumptionModifier,
  Dnd5eSpellSlotConsumptionModifierOperation,
  dnd5eSpellSlotConsumptionModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-spell-slot-consumption-modifier.ts";
import {
  Dnd5eResourceConsumptionModifier,
  Dnd5eResourceConsumptionModifierOperation,
  dnd5eResourceConsumptionModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-resource-consumption-modifier.ts";
import {
  Dnd5eItemConsumptionModifier,
  Dnd5eItemConsumptionModifierOperation,
  dnd5eItemConsumptionModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-item-consumption-modifier.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd5e-variable-modifier.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export const Dnd5eActionModifier = z.discriminatedUnion("type", [
  z.object({type: z.literal("attack-roll"), data: Dnd5eAttackRollModifier}),
  z.object({type: z.literal("difficulty-class"), data: Dnd5eDCModifier}),
  z.object({type: z.literal("damage-roll"), data: Dnd5eDamageRollModifier}),
  z.object({type: z.literal("spell-slot-consumption"), data: Dnd5eSpellSlotConsumptionModifier}),
  z.object({type: z.literal("resource-consumption"), data: Dnd5eResourceConsumptionModifier}),
  z.object({type: z.literal("item-consumption"), data: Dnd5eItemConsumptionModifier}),
  z.object({type: z.literal("variable-override"), data: Dnd5eVariableModifier})
]);
export type Dnd5eActionModifier = z.infer<typeof Dnd5eActionModifier>;

export const Dnd5eActionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("attack-roll"), operations: z.array(Dnd5eAttackRollModifierOperation)}),
  z.object({type: z.literal("difficulty-class"), operations: z.array(Dnd5eDCModifierOperation)}),
  z.object({type: z.literal("damage-roll"), operations: z.array(Dnd5eDamageRollModifierOperation)}),
  z.object({type: z.literal("spell-slot-consumption"), operations: z.array(Dnd5eSpellSlotConsumptionModifierOperation)}),
  z.object({type: z.literal("resource-consumption"), operations: z.array(Dnd5eResourceConsumptionModifierOperation)}),
  z.object({type: z.literal("item-consumption"), operations: z.array(Dnd5eItemConsumptionModifierOperation)}),
  z.object({type: z.literal("variable-override"), operations: z.array(Dnd5eVariableModifierOperation)})
]);
export type Dnd5eActionModifierOperation = z.infer<typeof Dnd5eActionModifierOperation>;

export type Dnd5eActionModifierTypes = {
  attackRoll: Type<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation>;
  difficultyClass: Type<Dnd5eDCModifier, Dnd5eDCModifierOperation>;
  damageRoll: Type<Dnd5eDamageRollModifier, Dnd5eDamageRollModifierOperation>;
  spellSlotConsumption: Type<Dnd5eSpellSlotConsumptionModifier, Dnd5eSpellSlotConsumptionModifierOperation>;
  resourceConsumption: Type<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation>;
  itemConsumption: Type<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation>;
  variableOverride: Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
};
export const dnd5eActionModifierType: Type<Dnd5eActionModifier, Dnd5eActionModifierOperation> = new MultiType<Dnd5eActionModifierTypes>({
  attackRoll: dnd5eAttackRollModifierType,
  difficultyClass: dnd5eDCModifierType,
  damageRoll: dnd5eDamageRollModifierType,
  spellSlotConsumption: dnd5eSpellSlotConsumptionModifierType,
  resourceConsumption: dnd5eResourceConsumptionModifierType,
  itemConsumption: dnd5eItemConsumptionModifierType,
  variableOverride: dnd5eVariableModifierType
});

export const DND_5E_ACTION_MODIFIER_TYPES = [
  "attack-roll",
  "difficulty-class",
  "damage-roll",
  "spell-slot-consumption",
  "resource-consumption",
  "item-consumption",
  "variable-override"
] as const satisfies readonly Dnd5eActionModifier["type"][];

export function isActionModifierType(type: string): type is Dnd5eActionModifier["type"] {
  return DND_5E_ACTION_MODIFIER_TYPES.includes(type as Dnd5eActionModifier["type"]);
}

export function getActionModifierID(value: Dnd5eActionModifier): Dnd5eModifierID {
  return value.data.modifierID;
}
