import {IndexedDB} from "./indexed-d-b.ts";

export class DefaultIndexedDB implements IndexedDB {
  private readonly database: Promise<IDBDatabase>;
  constructor(databaseName: string, version: number, upgrade: (db: IDBDatabase, oldVersion: number) => void) {
    this.database = new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(databaseName, version);
      openRequest.addEventListener('upgradeneeded', ev => upgrade(openRequest.result, ev.oldVersion));
      openRequest.addEventListener('success', () => resolve(openRequest.result));
      openRequest.addEventListener('error', () => reject(openRequest.error));
    });
  }

  getAllKeys(objectStore: string, key: IDBKeyRange): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      this.database.then(db => {
        const tx = db.transaction(objectStore, "readonly");
        const getAllKeysRequest = tx.objectStore(objectStore).getAllKeys(key);
        getAllKeysRequest.addEventListener('success', () => resolve(getAllKeysRequest.result as string[][]));
        getAllKeysRequest.addEventListener('error', () => reject(getAllKeysRequest.error));
      }).catch(reject);
    });
  }

  get<Value>(objectStore: string, key: IDBValidKey): Promise<Value | undefined>;
  get<Value>(objectStore: string, key: IDBValidKey, defaultValue: Value): Promise<Value>;
  get<Value>(objectStore: string, key: IDBValidKey, defaultValue?: Value): Promise<Value | undefined> {
    return new Promise((resolve, reject) => {
      this.database.then(db => {
        const tx = db.transaction(objectStore, "readonly");
        const getRequest = tx.objectStore(objectStore).get(key);
        getRequest.addEventListener('success', () => resolve((getRequest.result || defaultValue) as (Value | undefined)));
        getRequest.addEventListener('error', () => reject(getRequest.error));
      }).catch(reject);
    });
  }

  put<Value>(objectStore: string, key: IDBValidKey, value: Value): Promise<void> {
    return new Promise((resolve, reject) => {
      this.database.then(db => {
        const tx = db.transaction(objectStore, "readwrite");
        const putRequest = tx.objectStore(objectStore).put(value, key);
        putRequest.addEventListener('success', () => resolve());
        putRequest.addEventListener('error', (reason?: unknown) => reject(reason));
      }).catch(reject);
    });
  }

  delete(objectStore: string, key: IDBValidKey): Promise<void> {
    return new Promise((resolve, reject) => {
      this.database.then(db => {
        const tx = db.transaction(objectStore, "readwrite");
        const deleteRequest = tx.objectStore(objectStore).delete(key);
        deleteRequest.addEventListener("success", () => resolve());
        deleteRequest.addEventListener("error", (reason?: unknown) => reject(reason));
      })
    });
  }
}
