import {Button, ButtonBar, Spacer} from "#lib/components/index.ts";
import {Color, ConstantOperation, HSLA, MapRef, SetOperation, Transform, Tree, TreeId, TreeOperation, TreePath} from "common/types/index.ts";
import {GroupNode, ImageNode, Node, NodeId, NodeOperation, ShapeNode, TextNode} from "common/legends/index.ts";
import {Apply} from "#lib/qlab/index.ts";
import {useCallback, useMemo, useState} from "react";
import {FaBorderAll, FaChartArea, FaObjectGroup, FaPlus, FaTrash} from "react-icons/fa";
import {VisibilityLayerFn} from "common/legends/visibility/index.ts";
import {FaFont, FaImage} from "react-icons/fa6";
import {Menu} from "@headlessui/react";
import {usePopper} from "react-popper";
import {TreeView} from "#lib/components/tree-view/index.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../container/editor/state/selection-ref.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {WallNode} from "common/legends/node/wall-node.ts";
import {AreaNode} from "common/legends/node/area-node.ts";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBlockBrick, faShapes, faCloud} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {defaultParallaxNode} from "common/legends/node/parallax-node.ts";

export type OutlinerNodesProps = {
  rootSelectionRef: AssetTokenSelectionRef | SceneSelectionRef,
  value: Node[];
  apply: Apply<Node[], TreeOperation<Node, NodeOperation>[]>;
  expanded: MapRef<NodeId, boolean, ConstantOperation>;
  selectedNodeIds: NodeSelectionRef[];
  applyToSelectedNodeIds: Apply<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>;
};

export function OutlinerView({rootSelectionRef, value, apply, expanded, selectedNodeIds, applyToSelectedNodeIds}: OutlinerNodesProps) {
  const isCreateGroupDisabled = useMemo(() => {
    if (selectedNodeIds.length === 0) return true;
    const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(value, child => child.data.id === nodeRef.nodeId));
    const ancestorPaths = nodePaths.map(path => path ? TreePath.splitParent(path)[0] : []);
    return ancestorPaths.some(path => !TreePath.equals(path, ancestorPaths[0]));
  }, [value, selectedNodeIds]);

  const deleteNode = useCallback((item: NodeId) => {
    apply(prevNodes => {
      const nodePath = Tree.getPath(prevNodes, value => value.data.id === item);
      if (!nodePath) return [];
      const prevValue = Tree.getNode(prevNodes, nodePath);
      return [{type: "delete", path: nodePath, prevItem: prevValue}];
    });
    applyToSelectedNodeIds(prev => [{
      type: "set",
      prevItems: prev,
      nextItems: []
    }]);
  }, [apply, applyToSelectedNodeIds, selectedNodeIds]);

  const createGroup = useCallback(() => {
    apply((prevNodes: Node[]): TreeOperation<Node, NodeOperation>[] => {
      if (!prevNodes) return [];
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      if (nodePaths.length === 0) return [];
      const groupPath = nodePaths[0];
      let [ancestorPath, groupIndex] = TreePath.splitParent(groupPath);
      return [{
          type: "insert",
          path: groupPath,
          item: {
            type: "group",
            data: {
              ...GroupNode.DEFAULT,
              id: NodeId.generate()
            }
          }
        }, ...nodePaths
          .reverse()
          .flatMap((nodePath): TreeOperation<Node,  NodeOperation>[] => {
            let parentIndex = TreePath.splitParent(nodePath)[1];
            return [{
              type: "move",
              fromPath: [...ancestorPath, parentIndex >= groupIndex ? parentIndex + 1 : parentIndex],
              toPath: [...groupPath, 0]
            }];
          })
      ];
    });
  }, [apply, selectedNodeIds]);
  const createGrid = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "grid",
        data: {
          id: NodeId.generate(),
          name: "Grid",
          thickness: 0,
          noise: 0.125,
          color: [0, 0, 0, 1] as HSLA,
          opacity: 0.2,
          tags: [],
          children: [],
          origin: [0, 0],
          pivot: [0, 0],
          selectionMask: 0,
          size: undefined,
          transform: Transform.DEFAULT,
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          conditions: []
        }
      });
    });
  };
  const createImage = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "image",
        data: {
          id: NodeId.generate(),
          name: "Image",
          file: undefined,
          opacity: 1,
          tags: [],
          children: [],
          origin: [32, 32],
          pivot: [0, 0],
          size: [64, 64],
          mountable: false,
          attachable: false,
          repeatX: 1,
          repeatY: 1,
          selectionMask: 2,
          transform: Transform.DEFAULT,
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          conditions: []
        } satisfies ImageNode
      });
    });
  };
  const createWall = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "wall",
        data: {
          id: NodeId.generate(),
          name: "Wall",
          tags: [],
          children: [],
          origin: [0, 0],
          pivot: [0, 0],
          selectionMask: 0,
          transform: Transform.DEFAULT,
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          color: [1, 0, 0, 1] as HSLA,
          opacity: 0.75,
          conditions: [],
          graph: {vertices: [], edges: []}
        } satisfies WallNode
      });
    });
  };
  const createArea = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "area",
        data: {
          id: NodeId.generate(),
          name: "Area",
          tags: [],
          selectionMask: 0,
          origin: [0, 0],
          pivot: [0, 0],
          children: [],
          transform: Transform.DEFAULT,
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          conditions: [],
          suppressWalls: false,
          color: Color.GREEN,
          opacity: 0.25,
          areas: [],
          interactions: []
        } satisfies AreaNode
      });
    });
  };
  const createText = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "text",
        data: {
          id: NodeId.generate(),
          name: "Text",
          tags: [],
          children: [],
          transform: Transform.DEFAULT,
          selectionMask: 0,
          size: 32,
          origin: [0, 0],
          pivot: [0, 0],
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          conditions: [],
          text: "Text",
          vTextAlign: "middle",
          hTextAlign: "center",
          fillColor: Color.WHITE,
          outlineColor: Color.BLACK
        } satisfies TextNode
      });
    });
  };
  const createShape = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "shape",
        data: {
          id: NodeId.generate(),
          name: "Shape",
          tags: [],
          children: [],
          transform: Transform.DEFAULT,
          selectionMask: 0,
          origin: [32, 32],
          pivot: [32, 32],
          visibilityLayer: VisibilityLayerFn.DEFAULT,
          conditions: [],
          shape: {
            type: "rectangle",
            data: {
              width: 64,
              height: 64
            }
          },
          fillColor: Color.WHITE75
        } satisfies ShapeNode
      });
    });
  };
  const createParallax = () => {
    apply(prevNodes => {
      const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      const path = (nodePaths.length === 0) ? [prevNodes.length] : nodePaths[0];
      return TreeOperation.insert(path, {
        type: "parallax",
        data: defaultParallaxNode()
      });
    });
  };

  const resolvedExpanded = useRefValue(expanded);
  const onToggle = (id: TreeId) => {
    expanded.apply((prev) => {
      if (prev[id] === undefined) return [{type: "put", key: id, item: true}];
      return [{type: "delete", key: id, item: prev[id]!}];
    })
  };
  const selected = selectedNodeIds.reduce((a: {[id: string]: boolean}, b) => {
    a[b.nodeId] = true;
    return a;
  }, {});
  const onSelect = (id: TreeId) => applyToSelectedNodeIds(prev => [{type: "set", prevItems: prev, nextItems: [
    rootSelectionRef.type === "asset-token"
      ? {...rootSelectionRef, type: "asset-token-node", nodeId: id}
      : {...rootSelectionRef, type: "scene-node", nodeId: id}
  ]}]);
  const onDeselect = () => applyToSelectedNodeIds(prev => [{type: "set", prevItems: prev, nextItems: []}]);

  const moveNode = (fromPath: TreePath, toPath: TreePath) => {
    const node = Tree.getNode(value, fromPath);
    const oldTransform = node.data.transform;

    let rootTransform = fromPath.slice(0, fromPath.length-1).map((_, i) => fromPath.slice(0, i+1))
      .map(path => Tree.getNode(value, path))
      .reverse()
      .reduce((a, b) => b.type !== "parallax" ? Transform.divide(a, b.data.transform) : a, oldTransform);
    let newTransform = toPath.slice(0, toPath.length - 1).map((_, i) => toPath.slice(0, i+1))
      .map(path => Tree.getNode(value, path))
      .reduce((a, b) => b.type !== "parallax" ? Transform.multiply(a, b.data.transform) : a, rootTransform);

    apply(_ => [{
      type: "apply", path: fromPath, operations: [{type: node.type, operations: [{
        type: "update-transform",
        operations: [{type: "set", prevValue: oldTransform, nextValue: newTransform}]
      }]}]
    }, {type: "move", fromPath, toPath}]);
  };

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return <div className="flex flex-col gap-1">
    <ButtonBar>
      <Spacer />

      {!isCreateGroupDisabled && <Button className="justify-start" title="Group" onClick={createGroup}>
        <FaObjectGroup />
        <span>Group</span>
      </Button>}

      <Menu ref={ref => setReferenceElement(ref)} as="div">
        <Menu.Button as={Button}>
          <FaPlus /> Add Element
        </Menu.Button>
        <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
          <Menu.Item as={Button} className="justify-start" title="Image" onClick={createImage}>
            <FaImage />
            <span>Image</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Grid" onClick={createGrid}>
            <FaBorderAll />
            <span>Grid</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Group" onClick={createWall}>
            <FontAwesomeIcon icon={faBlockBrick} />
            <span>Wall</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Area" onClick={createArea}>
            <FaChartArea />
            <span>Area</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Text" onClick={createText}>
            <FaFont />
            <span>Text</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Shape" onClick={createShape}>
            <FontAwesomeIcon icon={faShapes} />
            <span>Shape</span>
          </Menu.Item>
          <Menu.Item as={Button} className="justify-start" title="Shape" onClick={createParallax}>
            <FontAwesomeIcon icon={faCloud} />
            <span>Parallax</span>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </ButtonBar>

    <TreeView type={"nodes"} items={value} isVisible={() => true} move={moveNode} selected={selected} onSelect={onSelect} onDeselect={onDeselect} expanded={resolvedExpanded} onExpand={onToggle} onCollapse={onToggle} ItemActions={(item) => <>
      <ExpandOptions options={{placement: "right-start"}}>
        <Button size="small" variant="destructive" onClick={() => deleteNode(item.data.id)} title="Delete Node"><FaTrash /> Delete Node</Button>
      </ExpandOptions>
    </>} />
  </div>;
}
