import {Type} from "../../type/type.ts";
import {SetOperation} from "./set-operation.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {MutableRef} from "#common/ref";

export class SetType<Item> implements Type<Item[], SetOperation<Item>> {
  constructor(private readonly equals: (a: Item, b: Item) => boolean = (a, b) => a === b) {
  }

  apply = (value: Item[], operation: SetOperation<Item>): Item[] => {
    switch (operation.type) {
      case "insert": {
        if (value.some(item => this.equals(item, operation.item))) {
          return value;
        } else {
          return [...value, operation.item];
        }
      }
      case "delete": {
        return value.filter(item => !this.equals(item, operation.item));
      }
      case "set": {
        return operation.nextItems;
      }
    }
  }

  invert = (operation: SetOperation<Item>): SetOperation<Item>[] => {
    switch (operation.type) {
      case "insert": return [{type: "delete", item: operation.item}];
      case "delete": return [{type: "insert", item: operation.item}];
      case "set": return [{type: "set", prevItems: operation.nextItems, nextItems: operation.prevItems}];
    }
  }

  transform = (leftOperation: SetOperation<Item>, topOperation: SetOperation<Item>, tieBreaker: boolean): SetOperation<Item>[] => {
    switch (leftOperation.type) {
      case "insert": {
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker && this.equals(leftOperation.item, topOperation.item)) return [];
            return [leftOperation];
          }
          case "delete": {
            return [leftOperation];
          }
          case "set": {
            if (tieBreaker) {
              return [leftOperation];
            } else {
              return [];
            }
          }
        }
        break;
      }
      case "delete": {
        switch (topOperation.type) {
          case "insert": {
            return [leftOperation];
          }
          case "delete": {
            if (!tieBreaker && this.equals(leftOperation.item, topOperation.item)) return [];
            return [leftOperation];
          }
          case "set": {
            if (tieBreaker) return [leftOperation];
            return [];
          }
        }
        break;
      }
      case "set": {
        if (tieBreaker) return [leftOperation];
        const prevItems = this.apply(leftOperation.prevItems, topOperation);
        return [{type: "set", prevItems, nextItems: leftOperation.nextItems}];
      }
    }
  }
  migrateValue(value: any): Item[] {
    return value;
  }
  migrateOperation(operation: any): SetOperation<Item>[] {
    if (operation.type === "insert") {
      return [{type: "insert", item: operation.item}];
    } else if (operation.type === "delete") {
      return [{type: "delete", item: operation.item}]
    } else if (operation.type === "set") {
      return [{type: "set", prevItems: operation.prevItems, nextItems: operation.nextItems}];
    } else {
      throw new Error("Unknown Operation: ", operation);
    }
  }
  validate = (value: any): ValidationError[] => {
    if (Array.isArray(value)) return [];
    return [{path: [], data: {message: "Invalid Type. Expected set.", value}}]
  }
}

export type SetEntity<Item> = MutableRef<Item[], SetOperation<Item>[]>;
