import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {
  Dnd5eVariableModifier,
  Dnd5eVariableModifierOperation,
  Dnd5eVariableOverrideModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd5e-variable-modifier.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eVariableModifierViewProps = {
  value: MutableRef<Dnd5eVariableModifier, Dnd5eVariableModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eVariableModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eVariableModifierViewProps) {
  const {name, expression} = useMemo(() => Dnd5eVariableOverrideModifierSignals(value), [value]);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Variable</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Name</InputGroupLabel>
      <InputString value={name} />
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Expression</InputGroupLabel>
      <InputMathExpression value={expression} />
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
