import {useCallback} from "react";
import {Dnd5eActionMessage} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {generateMessageID, MessageOperation, MessageValue} from "common/qlab/message/index.ts";
import {useSeedTime} from "../../../../../routes/game/use-seed-time.ts";
import {MutableRef} from "common/ref";
import {MessageRef} from "../../../../common/legends/message-ref.ts";
import {Optional} from "common/types/generic/optional/index.ts";
import {ValueFn} from "common/types/generic/value/index.ts";

export function useSendDnd5eAction() {
  const databaseRef = useDatabase();
  const time = useSeedTime();
  return useCallback(async (props: Omit<Dnd5eActionMessage, "rollResults">): Promise<MutableRef<Optional<MessageValue>, MessageOperation[]>> => {
    const messageID = generateMessageID(time());
    return databaseRef.apply((_) => {
      return [{type: "message", messageID, operations: ValueFn.set(undefined, {
        type: "dnd-5e-action-message", data: {
          ...props,
          rollResults: undefined
        }
      })}]
    }).then(() => MessageRef(databaseRef, messageID));
  }, [databaseRef, time])
}