import {Sheet, SheetID, TokenID, TokenNode, TokenNodeOperation, TokenNodeOperationUtil} from "common/legends/index.ts";
import {useGetResource} from "#lib/qlab/index.ts";
import {Button, ButtonBar, IconButton, useToggle} from "#lib/components/index.ts";
import {FaCaretLeft, FaCaretRight, FaRandom} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {MapFn, Optional, ValueFn} from "common/types/index.ts";
import {Embeddable} from "common/types/generic/embeddable/index.ts";
import {toPromise} from "common/observable";
import {useAsset} from "../../../common/character/use-asset.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {FaArrowRotateLeft} from "react-icons/fa6";
import {resetTokenSheet} from "../../../common/node/reset-token-sheet.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";

export function useSetTokenReference(value: MutableRef<Optional<TokenNode>, TokenNodeOperation[]>) {
  const getResource = useGetResource();
  return async (tokenID: TokenID) => {
    const tokenNode = await toPromise(value.observe);
    if (tokenNode === undefined) return;
    const {gameID, assetID} = tokenNode.tokenReference;
    const asset = await getResource("asset", gameID, assetID);
    const token = asset.tokens.find(token => token.tokenID === tokenID)!;
    const tokenSheet: Embeddable<SheetID, Sheet> | undefined = token.sheetId !== undefined
      ? (
        token.sheetPolicy === "link"
         ? {type: "link", data: token.sheetId}
         : {type: "copy", data: (await getResource("asset", gameID, assetID)).sheets[token.sheetId]}
      ) : undefined;
    value.apply((prev) => {
      if (prev === undefined) return [];
      if (prev.tokenSheets[tokenID] || tokenSheet === undefined) {
        return TokenNodeOperationUtil.updateTokenReference(
          ValueFn.set(prev.tokenReference, {gameID: gameID, assetID, tokenID})
        );
      } else {
        return [
          ...TokenNodeOperationUtil.updateTokenSheets(MapFn.put(tokenID, tokenSheet)),
          ...TokenNodeOperationUtil.updateTokenReference(
            ValueFn.set(prev.tokenReference, {gameID: gameID, assetID, tokenID})
          )
        ];
      }
    });
  };
}

export type ActiveTokenFieldProps = {
  valueRef: MutableRef<TokenNode, TokenNodeOperation[]>;
};
export function ActiveTokenField({valueRef}: ActiveTokenFieldProps) {
  const setTokenReference = useSetTokenReference(valueRef);

  const value = useRefValue(valueRef);
  const assetRef = useAsset(value.tokenReference.assetID);
  const asset = useRefValue(assetRef);

  const onPrevHandler = () => {
    const options = assetRef.value?.tokens;
    if (!options) return;
    const optionIds = options.map(option => option.tokenID);
    const index = optionIds.indexOf(value.tokenReference.tokenID);
    const prevIndex = (index + optionIds.length - 1) % optionIds.length;
    const tokenID = optionIds[prevIndex];
    setTokenReference(tokenID);
  };
  const onNextHandler = () => {
    const options = assetRef.value?.tokens;
    if (!options) return;
    const optionIds = options.map(option => option.tokenID);
    const index = optionIds.indexOf(value.tokenReference.tokenID);
    const nextIndex = (index + 1) % optionIds.length;
    const tokenID = optionIds[nextIndex];
    setTokenReference(tokenID);
  };
  const onRandomHandler = () => {
    const options = assetRef.value?.tokens;
    if (!options) return;
    const optionIds = options.map(option => option.tokenID);
    const randomIndex = Math.floor(Math.random() * optionIds.length);
    const tokenID = optionIds[randomIndex];
    setTokenReference(tokenID);
  };
  const databaseRef = useDatabase();
  const onResetSheet = () => {
    resetTokenSheet(databaseRef, valueRef)
  };

  const [expanded, toggleExpanded] = useToggle(false);

  return (<div className="shrink-0 flex flex-col">
    <ButtonBar>
      <IconButton variant="tertiary" onClick={onPrevHandler}><FaCaretLeft /></IconButton>
      <span className="flex-1 cursor-pointer text-h200 flex items-center justify-center whitespace-nowrap overflow-hidden" onClick={toggleExpanded}>{asset ? asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID)?.name : ""}</span>
      <IconButton variant="tertiary" onClick={onNextHandler}><FaCaretRight /></IconButton>
      <IconButton variant="tertiary" onClick={onRandomHandler}><FaRandom /></IconButton>
      <IconButton variant="tertiary" onClick={onResetSheet}><FaArrowRotateLeft /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      {asset && asset.tokens.map(token =>
        <Button key={token.tokenID} variant={token.tokenID === value.tokenReference.tokenID ? "primary" : "tertiary"} className="whitespace-nowrap overflow-hidden" onClick={() => {
          setTokenReference(token.tokenID);
        }}>{token.name}</Button>
      )}
    </Fieldset>}
  </div>);
}