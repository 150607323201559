import {NodeId} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {HSLA, Transform} from "common/types/index.ts";
import React from "react";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {NodeView} from "../node-view/index.ts";
import {AreaNode} from "common/legends/node/area-node.ts";
import {useIsTokenController} from "./use-is-token-controller.ts";
import {AreaShader} from "../../../../common/measurement/area-shader/area-shader.tsx";
import {SplineFn} from "common/types/generic/spline/spline.ts";
import {Vector2} from "common/math/vector/vector2.ts";

export type AreaNodeViewProps = {
  value: AreaNode;
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
};
export function AreaNodeView({value, projection, model, view, visibilityMask, accessNodeIDs, opacity}: AreaNodeViewProps) {
  const isTokenController = useIsTokenController();
  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;

  const valueOpacity = opacity * value.opacity;
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    {!isTokenController && value.areas.map((area, index) =>
      <AreaShader key={index} projection={projection} view={view} model={model} spline={SplineFn.map(area, (spline) => Vector2.multiplyMatrix4x4(spline, Transform.toMatrix4x4(value.transform)))} scale={1} color={[value.color[0], value.color[1], value.color[2], valueOpacity] as HSLA} />
    )}
    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
    })}
  </binder>);
}