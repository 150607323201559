import {FaRegSquare, FaWindowMinimize} from "react-icons/fa6";

export type DialogMinimizeHandleProps = {
  minimized: boolean;
  onToggleMinimized: () => void;
  onClose: () => void;
};

export function DialogMinimizeHandle({minimized, onToggleMinimized, onClose}: DialogMinimizeHandleProps) {
  return (<div className="dialog-top-button dialog-minimize-handle" title={minimized ? "Maximize" : "Minimize"} onMouseDown={ev => {
    if (ev.button === 1) onClose();
  }} onClick={onToggleMinimized}>
    {(minimized && <>
      <FaRegSquare size={12} />
    </>) || <>
      <FaWindowMinimize size={12}/>
    </>}
  </div>);
}
