import {ConstantOperation, constantType, ListOperation, ListPropertyRef, ObjectType, PropertyRef, StringOperation, stringType, Tag, tagListType, Type} from "../../../types/index.ts";
import {AssetTreeItem} from "./asset-tree-type.ts";
import {AssetTreeId} from "./asset-tree-id.ts";
import {MutableRef} from "#common/ref";

export type AssetTreeFolder = {
  id: AssetTreeId;
  name: string;
  tags: Tag[];
  children: AssetTreeItem[];
};
export type AssetTreeFolderOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  | {type: "update-children", operations: ConstantOperation[]}
  ;

export const assetFolderType: Type<AssetTreeFolder, AssetTreeFolderOperation> = new ObjectType({
  id: constantType,
  name: stringType,
  tags: tagListType,
  children: constantType
});

export function AssetTreeFolderSignals(value: MutableRef<AssetTreeFolder, AssetTreeFolderOperation[]>) {
  return {
    name: PropertyRef<AssetTreeFolder, AssetTreeFolderOperation, string, StringOperation>(
      (value) => value.name,
      (operations) => [{type: "update-name", operations}]
    )(value),
    tags: ListPropertyRef<AssetTreeFolder, AssetTreeFolderOperation, Tag, ConstantOperation>(
      (value: AssetTreeFolder) => value.tags,
      (operations) => [{type: "update-tags", operations}]
    )(value)
  };
}
