import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValuePropertyRef, ValueType} from "#common/types/index.ts";
import {MathExpression} from "#common/math/index.ts";
import {MutableRef} from "#common/ref";

export const ArmorClassModifierType = z.union([
  z.literal("SHIELD"),
  z.literal("MISC")
]);
export type ArmorClassModifierType = z.infer<typeof ArmorClassModifierType>;
export const ARMOR_CLASS_TYPES: readonly ArmorClassModifierType[] = ["SHIELD", "MISC"] as const;
export const ARMOR_CLASS_TYPE_LABELS: {[type in ArmorClassModifierType]: string} = {
  "SHIELD": "Shield",
  "MISC": "Misc"
};

export const Dnd5eArmorClassModifier = z.object({
  modifierID: Dnd5eModifierID,
  modifierType: ArmorClassModifierType,
  expression: MathExpression
});
export type Dnd5eArmorClassModifier = z.infer<typeof Dnd5eArmorClassModifier>;

export const Dnd5eArmorClassModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-modifier-type"), operations: z.array(ValueOperation(ArmorClassModifierType, ConstantOperation))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))})
]);
export type Dnd5eArmorClassModifierOperation = z.infer<typeof Dnd5eArmorClassModifierOperation>;

export const dnd5eArmorClassModifierType: Type<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation> = new ObjectType({
  modifierID: constantType,
  modifierType: new ValueType(constantType),
  expression: new ValueType(constantType)
});

export function Dnd5eArmorClassModifierSignals(value: MutableRef<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation[]>) {
  return {
    expressionRef: ValuePropertyRef<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation, MathExpression, ConstantOperation>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value),
    modifierTypeRef: ValuePropertyRef<Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation, ArmorClassModifierType, ConstantOperation>(
      value => value.modifierType,
      operations => [{type: "update-modifier-type", operations}]
    )(value)
  };
}
