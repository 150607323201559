import {combine, distinct, listIdentity, map, Observable} from "common/observable";
import {Dnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {pipe} from "common/pipe";
import {useMemo} from "react";
import {Sheet} from "common/legends/index.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useGlobalFeatures} from "./use-global-features.ts";

export function useSheetModifiers(sheet: Observable<Sheet | undefined>): Observable<Dnd5eModifier[]> {
  const globalFeatures = useGlobalFeatures();
  return useMemo(() => pipe(
    combine(sheet, globalFeatures.observe),
    map(([sheet, globalFeatures]) => getActiveModifiers(sheet, globalFeatures)),
    distinct(listIdentity)
  ), [sheet, globalFeatures]);
}
