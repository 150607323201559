import {NodeId, ShapeNode} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Color, ShapeFn, Transform} from "common/types/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {useIsSelected} from "./use-is-selected.ts";
import {NodeView} from "./node-view.tsx";
import {useSheetEffects} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-effects.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {RectangleShader} from "./shape/rectangle-shader.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {ConeShader} from "./shape/cone-shader.tsx";
import {ArcShader} from "./shape/arc-shader.tsx";
import {useRefValue} from "#lib/signal/index.ts";

export type ShapeNodeViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
  value: ShapeNode;
};
export function ShapeNodeView({projection, view, visibilityMask, accessNodeIDs, model, opacity, value: {id, children, transform, conditions, visibilityLayer, shape, fillColor, origin, pivot}}: ShapeNodeViewProps) {
  const valueModel = Transform.divide(transform, model);
  const finalOpacity = opacity;
  const isSelected = useIsSelected(id);

  const sheetEffects = useSheetEffects(useSheetSignal(useSheetReference()), useGlobalFeatures());
  const conditionsMet = useRefValue(useMemo(() => sheetEffects.map(effects => {
    return conditions.length === 0 || conditions.every(condition => condition.type !== "effect" ||
      effects
        .filter(effect => effect.effectID === condition.data.effectID && effect.enabled)
        .length > 0
    )}).distinct(), [sheetEffects, conditions]));

  const shapeOrigin = ShapeFn.getShapeOrigin(shape, origin);
  const shapeSize = ShapeFn.getShapeSize(shape);

  if ((!conditionsMet || !AccessMaskFn.canSee(visibilityMask, visibilityLayer)) && !accessNodeIDs.includes(id)) return <></>;
  return (<binder>
    {shape.type === "rectangle" && <RectangleShader projection={projection} view={view} model={valueModel} origin={origin} color={fillColor} rectangle={shape.data} />}
    {shape.type === "cone" && <ConeShader projection={projection} view={view} model={valueModel} origin={origin} color={fillColor} cone={shape.data} />}
    {shape.type === "arc" && <ArcShader projection={projection} view={view} model={valueModel} origin={origin} color={fillColor} arc={shape.data} />}
    {children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} model={valueModel} value={child} opacity={finalOpacity} />
    })}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={shapeOrigin} size={shapeSize} color={Color.WHITE} repeatX={1} repeatY={1} />}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[origin[0] - pivot[0] + 2, origin[1] - pivot[1] + 2]} size={[4, 4]} color={Color.GREEN} repeatX={1} repeatY={1} />}
  </binder>);
}