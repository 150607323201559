import {MapRef, MapSignals, NumberOperation} from "common/types/index.ts";
import {Dnd5eAttribute, DND_5E_ATTRIBUTES} from "common/legends/index.ts";
import {Button, InputNumber} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {FaDiceD20} from "react-icons/fa6";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useRollAbilityCheck} from "../dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {useSelectedNodeID} from "../dnd-5e-character/use-selected-sheet.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";

export type AttributeProps = {
  attribute: Dnd5eAttribute;
  value: MutableRef<number, NumberOperation[]>
};

export function Attribute({attribute, value}: AttributeProps) {
  const modifier = useObservable(pipe(
    value.observe,
    map(v => Math.floor((v - 10)/2)),
    distinct()
  ), 0, [value.observe]);

  const sheet = useSheetSignal(useSheetReference());
  const rollAbilityCheck = useRollAbilityCheck(useSelectedNodeID(), sheet, attribute);
  return (<label className="relative basis-16 flex flex-col items-center border border-zinc-800/40 bg-zinc-900/40 focus-within:border-blue-600/50 rounded-lg overflow-hidden">
    <span className="pt-1.5 px-2 font-bold text-xs">{attribute.toUpperCase()}</span>
    <InputNumber className="text-center py-0" value={value} />
    <Button size="small" className="flex flex-row gap-1 w-full h-8 px-1" onClick={ev => rollAbilityCheck(ev.shiftKey, ev.ctrlKey)}>
      <span>{modifier >= 0 ? `+${modifier}` : modifier}</span>
      <FaDiceD20 />
    </Button>
  </label>);
}

export type AttributesProps = {
  value: MapRef<Dnd5eAttribute, number, NumberOperation>;
};
export function Attributes({value}: AttributesProps) {
  const attributes = useRefValue(useMemo(() => MapSignals.expand(value), [value]));
  return (<div className="flex flex-row flex-wrap justify-around">
    {DND_5E_ATTRIBUTES.map(attribute => <Attribute key={attribute} attribute={attribute} value={attributes[attribute]!} />)}
  </div>);
}
