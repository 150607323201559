import {Input, InputProps} from "#lib/components/index.ts";
import {useCallback, useMemo, useRef, useState} from "react";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {NOOP_SIGNAL} from "common/ref";
import {twMerge} from "tailwind-merge";
import {Dice, DiceExpression} from "common/dice/index.ts";
import {useVariables} from "../dnd-5e/dnd-5e-action/dnd-5e-variables.ts";
import {MutableRef} from "common/ref";

export type InputDiceExpressionProps = {
  value: MutableRef<DiceExpression, ValueOperation<DiceExpression, ConstantOperation>[]>;
  onLeaveEdit?: () => void;
} & Omit<InputProps, "value" | "onChange">;

export function InputDiceExpression({value, onLeaveEdit, ...props}: InputDiceExpressionProps) {
  const variables = useVariables();

  const setValue = useCallback((nextValue: DiceExpression) => value.apply(prev => ValueFn.set(prev, nextValue)), [value.apply]);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string | undefined>(undefined);

  const startEdit = useCallback(() => {
    setEditValue(value.value);
    setEditMode(true);
  }, [setEditValue, setEditMode, value]);

  const editValueRef = useRef(editValue);
  editValueRef.current = editValue;
  const finalizeEdit = useCallback(() => {
    const expression = editValueRef.current!;
    if (Dice.isDiceExpression(expression)) {
      setValue(expression);
    }
    setEditMode(false);
    onLeaveEdit?.();
  }, [setValue, setEditMode, editValueRef]);

  const expression = useRefValue(value);
  const range = useComputedValue(variables || NOOP_SIGNAL, variables => {
    return Dice.getRollRange(expression, variables || {});
  }, [expression]);
  const isDiceExpression = useMemo(() => Dice.isDiceExpression(editValue || "0"), [editValue])
  const viewLabel = expression !== `${Dice.toRangeDisplay(range)}` ? `${expression} = ${Dice.toRangeDisplay(range)}` : expression;

  return <div className="w-full flex items-center">
    {!editMode && <Input {...props} readOnly type="text" value={viewLabel} title={viewLabel} onClick={startEdit} onFocus={startEdit} />}

    {editMode && <div className="w-full flex flex-row gap-2 items-center">
        <Input {...props} className={twMerge(
          !isDiceExpression && "bg-red-500/30",
          props.className
        )} autoFocus type="text" value={editValue} onChange={ev => setEditValue(ev.target.value)} onBlur={finalizeEdit} onKeyDown={ev => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            finalizeEdit();
            return true;
          }
          if (ev.key === "Escape") {
            setEditMode(false);
            onLeaveEdit?.();
            ev.preventDefault();
            return true;
          }
        }} />
    </div>}
  </div>
}