import {PointSignals, Transform, TransformOperation, TransformSignals} from "common/types/index.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useMemo} from "react";
import {MutableRef} from "common/ref";

export type TransformOptionsProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function TransformOptions({value}: TransformOptionsProps) {
  const {position, scale, rotation} = useMemo(() => TransformSignals(value), [value]);
  const {x, y} = useMemo(() => PointSignals(position), [position]);

  return <Fieldset>
    <div className="flex flex-row">
      <InputGroup size="small">
        <InputGroupLabel>Position</InputGroupLabel>
      </InputGroup>
      <InputGroup size="small" className="flex-1">
        <InputGroupLabel>X</InputGroupLabel>
        <InputNumber size="small" value={x} />
      </InputGroup>
      <InputGroup size="small" className="flex-1">
        <InputGroupLabel>Y</InputGroupLabel>
        <InputNumber size="small" value={y} />
      </InputGroup>
    </div>
    <InputGroup size="small">
      <InputGroupLabel>Rotation</InputGroupLabel>
      <InputNumber size="small" value={rotation} />
    </InputGroup>
    <InputGroup size="small">
      <InputGroupLabel>Scale</InputGroupLabel>
      <InputNumber size="small" value={scale} />
    </InputGroup>
  </Fieldset>
}