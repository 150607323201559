import {ValueOperation} from "./value.ts";
import {PropertyRef} from "../object/property-ref.ts";
import {MutableRef} from "#common/ref";

export function ValuePropertyRef<AV, AO, BV, BO>(
  valueFn: (value: AV) => BV,
  updateFn: (operations: ValueOperation<BV, BO>[]) => AO[]
): (signal: MutableRef<AV, AO[]>) => MutableRef<BV, ValueOperation<BV, BO>[]> {
  return (signal: MutableRef<AV, AO[]>): MutableRef<BV, ValueOperation<BV, BO>[]> =>
    PropertyRef<AV, AO, BV, ValueOperation<BV, BO>>(valueFn, updateFn)(signal);
}
