// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "MAIN", "symbols": ["_", "SUM", "_"], "postprocess": r => r[1]},
    {"name": "SUM", "symbols": ["SUM", "_", {"literal":"+"}, "_", "PRODUCT"], "postprocess": r => ({op: "add", left: r[0], right: r[4] })},
    {"name": "SUM", "symbols": ["SUM", "_", {"literal":"-"}, "_", "PRODUCT"], "postprocess": r => ({op: "subtract", left: r[0], right: r[4] })},
    {"name": "SUM", "symbols": ["PRODUCT"], "postprocess": r => r[0]},
    {"name": "PRODUCT$subexpression$1", "symbols": [{"literal":"*"}]},
    {"name": "PRODUCT$subexpression$1", "symbols": [{"literal":"x"}]},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", "PRODUCT$subexpression$1", "_", "VALUE"], "postprocess": r => ({op: "multiply", left: r[0], right: r[4] })},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", {"literal":"/"}, "_", "VALUE"], "postprocess": r => ({op: "divide", left: r[0], right: r[4] })},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", {"literal":"%"}, "_", "VALUE"], "postprocess": r => ({op: "remainder", left: r[0], right: r[4] })},
    {"name": "PRODUCT", "symbols": ["FUNC"], "postprocess": r => r[0]},
    {"name": "FUNC$string$1", "symbols": [{"literal":"m"}, {"literal":"i"}, {"literal":"n"}, {"literal":"("}], "postprocess": (d) => d.join('')},
    {"name": "FUNC", "symbols": ["FUNC$string$1", "_", "SUM", "_", {"literal":","}, "_", "SUM", "_", {"literal":")"}], "postprocess": r => ({op: "min", left: r[2], right: r[6] })},
    {"name": "FUNC$string$2", "symbols": [{"literal":"m"}, {"literal":"a"}, {"literal":"x"}, {"literal":"("}], "postprocess": (d) => d.join('')},
    {"name": "FUNC", "symbols": ["FUNC$string$2", "_", "SUM", "_", {"literal":","}, "_", "SUM", "_", {"literal":")"}], "postprocess": r => ({op: "max", left: r[2], right: r[6] })},
    {"name": "FUNC", "symbols": ["VALUE"], "postprocess": r => r[0]},
    {"name": "VALUE", "symbols": ["decimal"], "postprocess": r => r[0]},
    {"name": "VALUE", "symbols": [{"literal":"("}, "_", "SUM", "_", {"literal":")"}], "postprocess": r => r[2]},
    {"name": "VALUE", "symbols": ["VARIABLE"], "postprocess": r => r[0]},
    {"name": "decimal$ebnf$1", "symbols": [{"literal":"-"}], "postprocess": id},
    {"name": "decimal$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "decimal$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "decimal$ebnf$2", "symbols": ["decimal$ebnf$2", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "decimal$ebnf$3$subexpression$1$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "decimal$ebnf$3$subexpression$1$ebnf$1", "symbols": ["decimal$ebnf$3$subexpression$1$ebnf$1", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "decimal$ebnf$3$subexpression$1", "symbols": [{"literal":"."}, "decimal$ebnf$3$subexpression$1$ebnf$1"]},
    {"name": "decimal$ebnf$3", "symbols": ["decimal$ebnf$3$subexpression$1"], "postprocess": id},
    {"name": "decimal$ebnf$3", "symbols": [], "postprocess": () => null},
    {"name": "decimal", "symbols": ["decimal$ebnf$1", "decimal$ebnf$2", "decimal$ebnf$3"], "postprocess":  r => ({ op: "value", value: parseFloat(
            (r[0] || "") +
            r[1].join("") +
            (r[2] ? "."+r[2][1].join("") : "")
        ) }) },
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null }},
    {"name": "VARIABLE$subexpression$1", "symbols": [{"literal":"{"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VARIABLE$ebnf$1", "symbols": [/[a-zA-Z0-9_-]/]},
    {"name": "VARIABLE$ebnf$1", "symbols": ["VARIABLE$ebnf$1", /[a-zA-Z0-9_-]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "VARIABLE$subexpression$2", "symbols": [{"literal":"}"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VARIABLE", "symbols": ["VARIABLE$subexpression$1", "VARIABLE$ebnf$1", "VARIABLE$subexpression$2"], "postprocess": r => ({op: "variable", variable: r[1].join("")})}
  ],
  ParserStart: "MAIN",
};

export default grammar;
