import {BotID} from "common/legends/index.ts";
import {EncryptPublicKey, PublicKey, VerifyPublicKey} from "common/crypto/index.ts";

const QLAB_WS_SERVICE_URL = import.meta.env.VITE_QLAB_WS_SERVICE_URL as string;
const QLAB_API_SERVICE_URL = import.meta.env.VITE_APP_API_URL as string;
const FILE_SERVICE_URL = import.meta.env.VITE_FILE_SERVICE_URL as string;
const BUCKET_URL = import.meta.env.VITE_BUCKET_URL as string;
const USER_POOL_ID = import.meta.env.VITE_USER_POOL_ID as string;
const USER_POOL_CLIENT_ID = import.meta.env.VITE_USER_POOL_CLIENT_ID as string;
const IS_LOCAL = (import.meta.env.VITE_IS_LOCAL as string) === "true";

const DICE_BOT_ENDPOINT = import.meta.env.VITE_DICE_BOT_ENDPOINT as string;
const DICE_BOT_ID = import.meta.env.VITE_DICE_BOT_ID as BotID;
const DICE_BOT_PUBLIC_KEY: PublicKey = [
  import.meta.env.VITE_DICE_BOT_ENCRYPT_PUBLIC_KEY as EncryptPublicKey,
  import.meta.env.VITE_DICE_BOT_VERIFY_PUBLIC_KEY as VerifyPublicKey
];

export const fileServiceUrl = FILE_SERVICE_URL;
export const qlabApiServiceUrl = QLAB_API_SERVICE_URL;
export const qlabWsServiceUrl = QLAB_WS_SERVICE_URL;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  qlabApiServiceUrl: QLAB_API_SERVICE_URL,
  qlabWsServiceUrl: QLAB_WS_SERVICE_URL,
  fileServiceUrl: FILE_SERVICE_URL,
  bucketUrl: BUCKET_URL,
  userPoolId: USER_POOL_ID,
  userPoolClientId: USER_POOL_CLIENT_ID,
  isLocal: IS_LOCAL,

  botId: DICE_BOT_ID,
  botEndpoint: DICE_BOT_ENDPOINT,
  botPublicKey: DICE_BOT_PUBLIC_KEY
};
