import {
  ExtractOperation,
  ExtractValue,
  MultiType,
  Optional,
  OptionalOperation,
  OptionalType,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {botType, gameType, userType} from "#common/legends/index.ts";

export interface StoreTypes {};
export type StoreType = keyof StoreTypes;

export type StoreValue = {
  [Type in StoreType]: {
    type: Type;
    data: ExtractValue<StoreTypes[Type]>
  }
}[StoreType];
export type StoreOperation = {
  [Type in StoreType]: {
    type: Type;
    operations: ExtractOperation<StoreTypes[Type]>[]
  }
}[StoreType];

export const storeType: Type<Optional<StoreValue>, ValueOperation<Optional<StoreValue>, OptionalOperation<StoreOperation>>> = new ValueType(new OptionalType(new MultiType({
  game: gameType,
  user: userType,
  bot: botType
})));
