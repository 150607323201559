import {z} from "zod";
import {TokenID} from "#common/legends/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, OptionalType, Type, ValueOperation, ValuePropertyRef, ValueType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {generateInteractionActionID, InteractionActionID} from "./interaction-action-i-d.ts";

export const InteractionActionSetToken = z.object({
  interactionActionID: InteractionActionID,
  tokenID: z.optional(TokenID)
});
export type InteractionActionSetToken = z.infer<typeof InteractionActionSetToken>;
export const InteractionActionSetTokenOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-action-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-token-i-d"), operations: z.array(ValueOperation(TokenID, ConstantOperation))})
]);
export type InteractionActionSetTokenOperation = z.infer<typeof InteractionActionSetTokenOperation>;
export const interactionActionSetTokenType: Type<InteractionActionSetToken, InteractionActionSetTokenOperation> = new ObjectType({
  interactionActionID: constantType,
  tokenID: new ValueType(new OptionalType(constantType))
}, v => {
  if (v.interactionActionID === undefined) v.interactionActionID = generateInteractionActionID();
  return v;
});

export function InteractionActionSetTokenSignals(value: MutableRef<InteractionActionSetToken, InteractionActionSetTokenOperation[]>) {
  return ({
    tokenID: ValuePropertyRef<InteractionActionSetToken, InteractionActionSetTokenOperation, Optional<TokenID>, ConstantOperation>(
      value => value.tokenID,
      operations => [{type: "update-token-i-d", operations}]
    )(value)
  })
}
