import React, {useContext} from "react";
import {Grid} from "common/legends/index.ts";

const GridContext = React.createContext<Grid | undefined>(undefined);

export const GridProvider = GridContext.Provider;

export function useGrid(): Grid {
  return useContext(GridContext) || Grid.DEFAULT;
}


