import React, {ChangeEvent, useCallback} from "react";
import {Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Select} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";

export type InputOptionalSelectProps<T extends string> = {
  value: MutableRef<Optional<T>, ValueOperation<Optional<T>, never>[]>;
  values: readonly T[];
  labels: {[value in T]: string};
};

export function InputOptionalSelect<T extends string>({value, values, labels}: InputOptionalSelectProps<T>) {
  return <Select className="-mr-4" value={useRefValue(value)} onChange={useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    const newValue = ev.target.value as T || "";
    value.apply(prev => ValueFn.set(prev, newValue !== "" ? newValue : undefined));
  }, [value])}>
    <option value=""></option>
    {values.map(key => <option key={key} value={key}>{labels[key]}</option>)}
  </Select>
}
