import {Observable, Subscription} from "../observable.ts";

export function switchAll<U>(): (observe: Observable<Observable<U>>) => Observable<U> {
  return observe => observer => {
    let prevSubscription: Subscription | undefined = undefined;
    const closeSubscription = observe({
      next: (observe) => {
        if (prevSubscription) prevSubscription();
        prevSubscription = observe({
          next: observer.next,
          error: observer.error,
          complete: () => {
          }
        });
      },
      error: observer.error,
      complete: observer.complete
    });
    return () => {
      if (prevSubscription) prevSubscription();
      closeSubscription();
    }
  };
}