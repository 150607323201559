import {VISIBILITY_IDS, VisibilityID, VisibilityLayer, VisibilityLayerOperation} from "common/legends/visibility/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {useCallback} from "react";
import {MutableRef} from "common/ref";

export type VisibilityLayerInputProps = {
  value: MutableRef<VisibilityLayer, VisibilityLayerOperation[]>;
};
export function VisibilityLayerInput({value}: VisibilityLayerInputProps) {
  const visibilityLayer = useObservable(value.observe, 0, [value.observe]);
  const toggle = useCallback((visibilityID: VisibilityID, select: boolean) => {
    value.apply(prev => select ? [{type: "set", prev, op: visibilityID}] : [{type: "xor", op: visibilityID}]);
  }, [value.apply]);

  return <div className="inline-flex flex-col h-7 divide-y divide-zinc-900/20 rounded-lg overflow-hidden shadow-md">
    {[0,10].map((i, r) => <div key={r} className="flex flex-row divide-x divide-zinc-900/20">
      {[0, 1, 2, 3, 4].map((j, c) => <div key={c} className={twMerge(
        "w-3.5 h-3.5 bg-zinc-100/50",
        (VISIBILITY_IDS[i+j] & visibilityLayer) > 0 ? "bg-zinc-100/90" : ""
      )} onClick={(ev) => toggle(VISIBILITY_IDS[i+j], ev.shiftKey)}>
      </div>)}
    </div>)}
  </div>
}
