import {useIsMountedRef} from "../is-mounted/index.ts";
import {useCallback, useEffect, useRef} from "react";

export function useSample<T>(callback: (value: T) => void, interval: number) {
  const isMountedRef = useIsMountedRef();
  const lastValue = useRef<T>();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isMountedRef.current && lastValue.current !== undefined) {
        callback(lastValue.current)
        lastValue.current = undefined;
      }
    }, interval);

    return () => {
      clearInterval(intervalId)
    };
  }, [callback, interval, isMountedRef])

  return useCallback((value: T) => {
    lastValue.current = value;
  }, []);
}
