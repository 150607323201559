import {Optional} from "common/types/index.ts";
import {useMemo} from "react";
import {useComputedValue} from "#lib/signal/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MutableRef} from "common/ref";

export function usePresent<T, O>(value: MutableRef<Optional<T>, O[]>): Optional<MutableRef<T, O[]>> {
  const isPresent = useComputedValue(value, value => value !== undefined);
  const presentSignal = useMemo(() => {
    const firstValue = value.value;
    if (firstValue === undefined) return undefined;
    let lastValue: T = firstValue;
    return new MutableRef<T, O[]>({
      value(): T {
        return value.value ?? lastValue;
      },
      observe: pipe(
        value.observe,
        map(v => {
          lastValue = v ?? lastValue;
          return lastValue;
        }),
        distinct()
      ),
      apply: fn => value.apply(prev => {
        if (prev === undefined) return [];
        return fn(prev);
      }).then(v => v ?? lastValue)
    });
  }, [value, isPresent]);
  return isPresent ? presentSignal : undefined;
}

export function useSuspendPresent<T, O>(value: MutableRef<Optional<T>, O[]>): MutableRef<T, O[]> {
  const valueRef = usePresent(value);
  if (valueRef === undefined) throw new Promise(() => {});
  return valueRef as MutableRef<T, O[]>;
}
