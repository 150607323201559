import {NodeId} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {NodeView} from "../node-view/index.ts";
import {ParallaxNode} from "common/legends/node/parallax-node.ts";
import {Vector2} from "common/math/vector/vector2.ts";

export type ParallaxNodeViewProps = {
  value: ParallaxNode;
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
};
export function ParallaxNodeView({value, projection, model, view, visibilityMask, accessNodeIDs, opacity}: ParallaxNodeViewProps) {
  const viewParallax = useMemo((): Transform => {
    const offset = Matrix4f.invert(Transform.toMatrix4x4(value.transform));
    return {
      ...view,
      position: Vector2.multiplyMatrix4x4(view.position, offset)
    };
  }, [view, value.transform]);

  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;
  return (<binder>
    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={viewParallax} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={model} opacity={opacity} />
    })}
  </binder>);
}