import {z} from "zod";

export const Dnd5eDefense = z.union([
  z.literal("ac"),
  z.literal("str"),
  z.literal("dex"),
  z.literal("con"),
  z.literal("int"),
  z.literal("wis"),
  z.literal("cha")
]);
export type Dnd5eDefense = z.infer<typeof Dnd5eDefense>;

export const DND_5E_DEFENSE = [
  "ac",
  "str",
  "dex",
  "con",
  "int",
  "wis",
  "cha"
] satisfies Dnd5eDefense[];


export const DND_5E_DEFENSE_TITLE: {[defence in Dnd5eDefense]: string} = {
  "ac": "Armor Class",
  "str": "Strength Saving Throw",
  "dex": "Dexterity Saving Throw",
  "con": "Constitution Saving Throw",
  "int": "Intelligence Saving Throw",
  "wis": "Wisdom Saving Throw",
  "cha": "Charisma Saving Throw"
} as const;