import {z} from "zod";

export const Dnd5eMovementType = z.union([
  z.literal("walk"),
  z.literal("swim"),
  z.literal("climb"),
  z.literal("fly"),
  z.literal("fly (hover)"),
  z.literal("burrow"),
]);

export const DND_5E_MOVEMENT_TYPES = ["walk", "swim", "climb", "fly", "fly (hover)", "burrow"] as const satisfies readonly Dnd5eMovementType[];
export type Dnd5eMovementType = z.infer<typeof Dnd5eMovementType>;

