import {Point, PointOperation, pointType, PropertyRef, Transform, TransformOperation, transformType} from "#common/types/index.ts";
import {BaseNode, BaseNodeOperation, BaseNodeSignals, baseNodeTypePropTypes} from "./base-node.ts";
import {VisibilityLayer, VisibilityLayerOperation, visibilityLayerType} from "#common/legends/visibility/index.ts";
import {Mask, MaskOperation, maskType} from "#common/types/generic/mask/mask.ts";
import {MutableRef} from "#common/ref";

export type LocalNode = BaseNode & {
  transform: Transform;
  visibilityLayer: VisibilityLayer;
  selectionMask: Mask;
  origin: Point;
  pivot: Point;
};

export type LocalNodeOperation =
  | BaseNodeOperation
  | {type: "update-origin", operations: PointOperation[]}
  | {type: "update-pivot", operations: PointOperation[]}
  | {type: "update-transform", operations: TransformOperation[]}
  | {type: "update-visibility-layer", operations: VisibilityLayerOperation[]}
  | {type: "update-selection-mask", operations: MaskOperation[]}
  ;


export const localNodeTypePropTypes = () => ({
  ...baseNodeTypePropTypes(),
  origin: pointType,
  pivot: pointType,
  transform: transformType,
  selectionMask: maskType,
  visibilityLayer: visibilityLayerType
});

export const localNodeUpdater = (v: any) => {
  if (v.pivot === undefined) v.pivot = v.origin;
  return v;
}

export function LocalNodeSignals(value: MutableRef<LocalNode, LocalNodeOperation[]>) {
  return {
    ...BaseNodeSignals(value),
    originRef: PropertyRef<LocalNode, LocalNodeOperation, Point, PointOperation>(
      value => value.origin,
      operations => [{type: "update-origin", operations}]
    )(value),
    pivotRef: PropertyRef<LocalNode, LocalNodeOperation, Point, PointOperation>(
      value => value.pivot,
      operations => [{type: "update-pivot", operations}]
    )(value),
    transformRef: PropertyRef<LocalNode, LocalNodeOperation, Transform, TransformOperation>(
      value => value.transform,
      operations => [{type: "update-transform", operations}]
    )(value),
    selectionMaskRef: PropertyRef<LocalNode, LocalNodeOperation, Mask, MaskOperation>(
      value => value.selectionMask,
      operations => [{type: "update-selection-mask", operations}]
    )(value),
    visibilityLayerRef: PropertyRef<LocalNode, LocalNodeOperation, VisibilityLayer, VisibilityLayerOperation>(
      value => value.visibilityLayer,
      operations => [{type: "update-visibility-layer", operations}]
    )(value)
  };
}
