import {Point} from "#common/types/generic/point/index.ts";
import {ConstantOperation, constantType, HSLA, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {MeasurementID} from "#common/legends/measurement/measurement-i-d.ts";
import {AssetID, GameID, NodeId, SceneID, TokenID} from "#common/legends/index.ts";

type ResourceRef =
  | {type: "scene", gameID: GameID, sceneID: SceneID}
  | {type: "asset-token", gameID: GameID, assetID: AssetID, tokenID: TokenID};

export type ConeMeasurement = {
  id: MeasurementID;
  type: "cone";
  resourceRef: ResourceRef;
  color: HSLA;
  startPoint: Point;
  endPoint: Point;
  sizeAngle: number;
};
export type RectangleMeasurement = {
  id: MeasurementID;
  type: "rectangle";
  resourceRef: ResourceRef;
  color: HSLA;
  startPoint: Point;
  endPoint: Point;
};
export type CircleMeasurement = {
  id: MeasurementID;
  type: "circle";
  resourceRef: ResourceRef;
  color: HSLA;
  origin: Point;
  radius: number;
};
export type BeamMeasurement = {
  id: MeasurementID;
  type: "beam";
  resourceRef: ResourceRef;
  color: HSLA;
  width: number;
  startPoint: Point;
  endPoint: Point;
};

export type PathMeasurement = {
  id: MeasurementID;
  type: "path";
  resourceRef: ResourceRef;
  nodeID: Optional<NodeId>;
  color: HSLA;
  nodes: Point[];
};
export type NoopMeasurement = {
  id: MeasurementID;
  type: "noop";
  resourceRef: ResourceRef
};
export type Measurement = Optional<
  PathMeasurement | ConeMeasurement | NoopMeasurement | RectangleMeasurement | CircleMeasurement | BeamMeasurement
>;
export type MeasurementOperation = ValueOperation<Measurement, ConstantOperation>;

export const measurementType: Type<Measurement, MeasurementOperation> = new ValueType(constantType);
