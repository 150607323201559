import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";

export type OriginFieldProps = {
  valueRef: MutableRef<Point, PointOperation[]>;
};

export function OriginField({valueRef}: OriginFieldProps) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (
    <div className="flex flex-row">
      <InputGroup>
        <InputGroupLabel>Origin</InputGroupLabel>
      </InputGroup>
      <InputGroup size="small" className="flex-1">
        <InputGroupLabel>X</InputGroupLabel>
        <InputNumber size="small" value={x} />
      </InputGroup>
      <InputGroup size="small" className="flex-1">
        <InputGroupLabel>Y</InputGroupLabel>
        <InputNumber size="small" value={y} />
      </InputGroup>
    </div>
  );
}