import {applyAll, ConstantOperation, constantType, ListSignal, ListType} from "common/types/index.ts";
import {Dnd5eActionSource} from "./quick-access-menu/dnd-5e/dnd-5e-action-source.ts";
import {createValueRef, MutableRef} from "common/ref";
import {useMemo} from "react";

const editors = createValueRef<Dnd5eActionSource[]>([]);
const actionSourceEditorsType = new ListType<Dnd5eActionSource, ConstantOperation>(constantType);

export function useActionSourceEditors(): ListSignal<Dnd5eActionSource, ConstantOperation> {
  return useMemo(() => new MutableRef({
    value() {
      return editors.value;
    },
    observe: editors.observe,
    apply: fn => editors.apply(prev => {
      const operations = fn(prev);
      return applyAll(actionSourceEditorsType, prev, operations);
    })
  }), []);
}
