import {ExtractOperation, ExtractValue, MultiType, Type} from "#common/types/index.ts";
import {Dnd5eCharacter, Dnd5eCharacterOperation, dnd5eCharacterType} from "./dnd-5e/index.ts";
import {
  Dnd5eStatBlock,
  Dnd5eStatBlockOperation,
  dnd5eStatBlockType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";

export interface SheetTypes {
  "dnd-5e-character": Type<Dnd5eCharacter, Dnd5eCharacterOperation>;
  "dnd-5e-stat-block": Type<Dnd5eStatBlock, Dnd5eStatBlockOperation>;
}

export type SheetType = keyof SheetTypes;
export type TypedSheetValue<Type extends SheetType> = ExtractValue<SheetTypes[Type]>;
export type TypedSheetOperation<Type extends SheetType> = ExtractOperation<SheetTypes[Type]>;
export type Sheet = {
  [Type in SheetType]: {
    type: Type;
    data: TypedSheetValue<Type>
  }
}[SheetType];
export type SheetOperation = {
  [Type in SheetType]: {
    type: Type;
    operations: TypedSheetOperation<Type>[]
  }
}[SheetType];

export const sheetType: Type<Sheet, SheetOperation> = new MultiType({
  dnd5eCharacter: dnd5eCharacterType,
  dnd5eStatBlock: dnd5eStatBlockType
});
