import {ProxyRegion} from "./proxy-region.ts";
import "./proxy-region.css";

export type ProxyRegionViewerProps = {
  value: ProxyRegion;
};

export function ProxyRegionViewer({value}: ProxyRegionViewerProps) {
  return (<div className={"proxy-region"} style={{position: "absolute", left: value.x, top: value.y, width: value.width, height: value.height}}>
    <div className={"proxy-region-border"} />
  </div>)
}