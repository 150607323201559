import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eSpellSlotLevel = z.union([
  z.literal("1"),
  z.literal("2"),
  z.literal("3"),
  z.literal("4"),
  z.literal("5"),
  z.literal("6"),
  z.literal("7"),
  z.literal("8"),
  z.literal("9"),
  z.literal("pact-slot")
]);
export type Dnd5eSpellSlotLevel = z.infer<typeof Dnd5eSpellSlotLevel>;
export const DND_5E_SPELL_SLOT_LEVEL = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "pact-slot"
] as const satisfies readonly Dnd5eSpellSlotLevel[];

export const DND_5E_SPELL_SLOT_LEVEL_TITLE: {[level in Dnd5eSpellSlotLevel]: string} = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "pact-slot": "Pact Slot",
};

export const Dnd5eSpellSlotConsumptionModifier = z.object({
  modifierID: Dnd5eModifierID,
  level: z.optional(Dnd5eSpellSlotLevel)
});
export type Dnd5eSpellSlotConsumptionModifier = z.infer<typeof Dnd5eSpellSlotConsumptionModifier>;

export const Dnd5eSpellSlotConsumptionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-level"), operations: z.array(ValueOperation(z.optional(Dnd5eSpellSlotLevel), ConstantOperation))})
]);
export type Dnd5eSpellSlotConsumptionModifierOperation = z.infer<typeof Dnd5eSpellSlotConsumptionModifierOperation>;

export const dnd5eSpellSlotConsumptionModifierType: Type<Dnd5eSpellSlotConsumptionModifier, Dnd5eSpellSlotConsumptionModifierOperation> = new ObjectType({
  modifierID: constantType,
  level: new ValueType(constantType)
});

export function Dnd5eSpellSlotConsumptionModifierSignal(value: MutableRef<Dnd5eSpellSlotConsumptionModifier, Dnd5eSpellSlotConsumptionModifierOperation[]>) {
  return ({
    level: PropertyRef<Dnd5eSpellSlotConsumptionModifier, Dnd5eSpellSlotConsumptionModifierOperation, Optional<Dnd5eSpellSlotLevel>, ValueOperation<Optional<Dnd5eSpellSlotLevel>, ConstantOperation>>(
      value => value.level,
      operations => [{type: "update-level", operations}]
    )(value)
  });
}

