import {Sheet, SheetOperation} from "common/legends/index.ts";
import {ButtonBar, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {FaHeart} from "react-icons/fa6";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {MutableRef} from "common/ref";
import {HealthIndicatorRef, useSheetHitPointsSignals} from "./dnd-5e-action/use-sheet-hit-points.ts";
import {Optional} from "common/types/generic/index.ts";

export type HitPointsProps = {
  sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>;
};
export function HitPoints({sheetRef}: HitPointsProps) {
  const healthIndicator = useSheetHitPointsSignals(sheetRef);
  const {currentRef, tempRef, maxRef} = useMemo(() => HealthIndicatorRef(healthIndicator), [healthIndicator]);
  return (<BaseComponent><ButtonBar>
    <InputGroup title="Hit Points">
      <InputGroupIcon  className="text-red-700 group-focus-within:text-red-400"><FaHeart /></InputGroupIcon>
      <InputGroupLabel>Hit Points</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-[2]">
      <InputNumber className="text-right" value={currentRef}/>
      <InputGroupLabel className="text-center">/</InputGroupLabel>
      <InputNumber readOnly className="text-left" value={maxRef}/>
    </InputGroup>
    <InputGroup className="flex-1 pl-0 pr-2 gap-0">
      <InputGroupLabel title="Temp" className="w-8 flex items-center justify-center">T</InputGroupLabel>
      <InputNumber className="w-8 text-center" value={tempRef}/>
    </InputGroup>
  </ButtonBar></BaseComponent>);
}
