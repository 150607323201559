import React from "react";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Dnd5eCharacter} from "common/legends/index.ts";
import {useComputedValue} from "#lib/signal/index.ts";

export type ProficiencyBonusFieldProps = {};

export function ProficiencyBonusField({}: ProficiencyBonusFieldProps) {
  const sheet = useSheetSignal(useSheetReference());
  const proficiencyBonus = useComputedValue(sheet, sheet => sheet?.type === "dnd-5e-character" ? Dnd5eCharacter.getProficiencyBonus(sheet.data) : 0);

  return (<ButtonBar>
    <InputGroup className="flex-1">
      <InputGroupIcon />
      <InputGroupLabel className="flex-1 basis-0 shrink text-ellipsis overflow-hidden whitespace-nowrap">Proficiency Bonus</InputGroupLabel>
      <span className="shrink-0 basis-6 text-xs italic font-bold text-white/50 text-right whitespace-nowrap" title={`{PB}`}>
        {proficiencyBonus}
      </span>
    </InputGroup>
  </ButtonBar>);
}
