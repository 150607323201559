import {ObjectType, Type} from "common/types/index.ts";

export type PlayerTurnTracker = {};
export type PlayerTurnTrackerOperation =
  undefined
  ;

export const playerTurnTrackerType: Type<PlayerTurnTracker, PlayerTurnTrackerOperation> = new ObjectType({
});

