import {z} from "zod";
import {DiceExpression} from "#common/dice/index.ts";
import {ConstantOperation, constantType, ObjectType, PropertyRef, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eAttackRollModifier = z.object({
  modifierID: Dnd5eModifierID,
  expression: DiceExpression
});
export type Dnd5eAttackRollModifier = z.infer<typeof Dnd5eAttackRollModifier>;

export const Dnd5eAttackRollModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))})
]);
export type Dnd5eAttackRollModifierOperation = z.infer<typeof Dnd5eAttackRollModifierOperation>;

export const dnd5eAttackRollModifierType: Type<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation> = new ObjectType({
  modifierID: constantType,
  expression: new ValueType(constantType)
});

export function Dnd5eAttackRollModifierSignals(value: MutableRef<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation[]>) {
  return ({
    expression: PropertyRef<Dnd5eAttackRollModifier, Dnd5eAttackRollModifierOperation, DiceExpression, ValueOperation<DiceExpression, ConstantOperation>>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(value)
  });
}
