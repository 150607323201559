import {ConstantOperation, MapFn, MapRef} from "common/types/index.ts";
import {Dnd5eWeapon, Dnd5eWeaponProficiency, DND_5E_WEAPON_CATEGORIES} from "common/legends/index.ts";
import {ExpandableLabel, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import React from "react";
import {SavingThrowProficiency} from "./saving-throw-proficiency/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useRefValue} from "#lib/signal/index.ts";

export type WeaponProficienciesProps = {
  value: MapRef<Dnd5eWeapon, Dnd5eWeaponProficiency, ConstantOperation>;
};
export function WeaponProficiencies({value}: WeaponProficienciesProps) {
  const setProficiency = (weapon: Dnd5eWeapon, nextValue: Dnd5eWeaponProficiency) => {
    value.apply(prevValue => {
      if (nextValue === "proficient") {
        if (prevValue[weapon] === undefined) return [{type: "put", key: weapon, item: "proficient"}];
        else if (prevValue[weapon] === "untrained") return [
          ...MapFn.delete(weapon, prevValue[weapon]!),
          ...MapFn.put(weapon, nextValue)
        ];
      } else {
        if (prevValue[weapon] !== undefined) return [{type: "delete", key: weapon, item: prevValue[weapon]!}];
      }
      return [];
    });
  };

  const proficiencies = useRefValue(value);

  const [expanded, toggleExpanded] = useToggle(false);

  return <div className="shrink-0 flex flex-col mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <span className="px-4 font-bold">Weapon Proficiencies</span>
    </ExpandableLabel>
    {expanded && <Fieldset>
      {DND_5E_WEAPON_CATEGORIES.map(weapon => <InputGroup key={weapon}>
        <SavingThrowProficiency value={proficiencies[weapon] || "untrained"} onChange={(nextValue) => {
          setProficiency(weapon, nextValue);
        }} />
        <InputGroupLabel>{weapon}</InputGroupLabel>
      </InputGroup>)}
    </Fieldset>}
  </div>
}