import {WebGL2Node} from "./web-g-l-2-node.ts";

/** eslint-disable @typescript-eslint/no-non-null-assertion */
export class WebGL2Viewport extends WebGL2Node {
  public x?: GLint;
  public y?: GLint;
  public width?: GLsizei;
  public height?: GLsizei;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.x === undefined) throw new Error("x must be provided.");
    if (this.y === undefined) throw new Error("y must be provided.");
    if (this.width === undefined) throw new Error("width must be provided.");
    if (this.height === undefined) throw new Error("height must be provided.");

    this.context.viewport(this.x, this.y, this.width, this.height);
  }
}
