import {UUID} from "common/utils";
import {z} from "zod";

export const DialogID = z.string().brand("DialogID");
export type DialogID = z.infer<typeof DialogID>;

export const DialogIDFn = {
  generate(): DialogID {
    return UUID.generate() as DialogID;
  }
} as const;
