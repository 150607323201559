import {z} from "zod";
import {Dnd5eEffectID} from "./dnd-5e-effect-id.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ZodListOperation
} from "#common/types/index.ts";
import {Dnd5eModifier, Dnd5eModifierOperation, dnd5eModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eEffect = z.object({
  effectID: Dnd5eEffectID,
  enabled: z.boolean(),
  name: z.string(),
  modifiers: z.array(Dnd5eModifier),
});
export type Dnd5eEffect = z.infer<typeof Dnd5eEffect>;

export const Dnd5eEffectOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-effect-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-enabled"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eModifier, Dnd5eModifierOperation))})
]);
export type Dnd5eEffectOperation = z.infer<typeof Dnd5eEffectOperation>;

export const dnd5eEffectType: Type<Dnd5eEffect, Dnd5eEffectOperation> = new ObjectType({
  effectID: constantType,
  enabled: booleanType,
  name: stringType,
  modifiers: new ListType(dnd5eModifierType)
}, (value) => {
  let v = {...value};
  if (v.modifiers === undefined) {
    v.modifiers = [];
    for (const modifier of v["abilityCheckModifiers"]) {
      v.modifiers.push({
        type: "ability-check",
        data: {
          modifierID: modifier.modifierID,
          abilityChecks: modifier.types,
          expression: modifier.expression,
          proficiency: "untrained",
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    }
    delete v["abilityCheckModifiers"];

    for (const modifier of v["attackRollModifiers"]) {
      v.modifiers.push({
        type: "attack-roll",
        data: {
          modifierID: modifier.modifierID,
          expression: modifier.expression
        }
      } satisfies Dnd5eModifier);
    }
    delete v["attackRollModifiers"];

    for (const modifier of v["dCModifiers"]) {
      v.modifiers.push({
        type: "difficulty-class",
        data: {
          modifierID: modifier.modifierID,
          expression: modifier.expression
        }
      } satisfies Dnd5eModifier);
    }
    delete v["dCModifiers"];

    for (const modifier of v["damageRollModifiers"]) {
      v.modifiers.push({
        type: "damage-roll",
        data: {
          modifierID: modifier.modifierID,
          damageType: modifier.damageType,
          hitExpression: modifier.hitExpression,
          critExpression: modifier.critExpression
        }
      } satisfies Dnd5eModifier);
    }
    delete v["damageRollModifiers"];

    for (const modifier of v["savingThrowModifiers"]) {
      v.modifiers.push({
        type: "saving-throw",
        data: {
          modifierID: modifier.modifierID,
          savingThrows: modifier.types,
          expression: modifier.expression,
          proficiency: "untrained",
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
      delete v["savingThrowModifiers"];
    }
  }
  if (v["statusIndicator"]) {
    v.modifiers.push({
      type: "status-indicator",
      data: {
        modifier: generateDnd5eModifierID(),
        ...v["statusIndicator"]
      }
    });
    delete v["statusIndicator"];
  }
  return v;
});

export function Dnd5eEffectSignals(value: MutableRef<Dnd5eEffect, Dnd5eEffectOperation[]>) {
  return {
    enabled: PropertyRef<Dnd5eEffect, Dnd5eEffectOperation, boolean, BooleanOperation>(
      value => value.enabled,
      operations => [{type: "update-enabled", operations}]
    )(value),
    name: PropertyRef<Dnd5eEffect, Dnd5eEffectOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    modifiers: ListPropertyRef<Dnd5eEffect, Dnd5eEffectOperation, Dnd5eModifier, Dnd5eModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(value)
  };
}

export const Dnd5eEffectFn = {
  getEffectID(value: Dnd5eEffect) {return value.effectID},
  getGroup: (value: Dnd5eEffect): string => {
    const i = value.name.indexOf(":");
    return (i !== -1)
      ? value.name.substring(0, i)
      : value.name;
  }
}
