import {z} from "zod";
import {NodeId, NodeID} from "../../node-id.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {InteractionActionID} from "./interaction-action-i-d.ts";

export const InteractionActionFocus = z.object({
  interactionActionID: InteractionActionID,
  nodeID: z.optional(NodeID)
});
export type InteractionActionFocus = z.infer<typeof InteractionActionFocus>;

export const InteractionActionFocusOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-action-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ValueOperation(z.optional(NodeID), ConstantOperation))})
]);
export type InteractionActionFocusOperation = z.infer<typeof InteractionActionFocusOperation>;

export const interactionActionFocusType: Type<InteractionActionFocus, InteractionActionFocusOperation> = new ObjectType({
  interactionActionID: constantType,
  nodeID: new ValueType(constantType)
});

export const InteractionActionFocusFn = {
  expand: (value: MutableRef<InteractionActionFocus, InteractionActionFocusOperation[]>) => ({
    nodeIDRef: ValuePropertyRef<InteractionActionFocus, InteractionActionFocusOperation, Optional<NodeId>, ConstantOperation>(
      value => value.nodeID,
      operations => [{type: "update-node-i-d", operations}]
    )(value)
  })
};
