import {Optional, SetOperation, ValueFn} from "common/types/index.ts";
import {useMemo} from "react";
import {distinct, listIdentity, map} from "common/observable";
import {pipe} from "common/pipe";
import {NodeSelectionRef} from "../../../container/editor/state/selection-ref.ts";
import {useEditor} from "../../../container/editor/editor-context.ts";
import {EditorState} from "../../../container/editor/state/index.ts";
import {MutableRef} from "common/ref";

export function useEditorSelectedNodeIDs(): MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]> {
  const editor = useEditor();
  return useMemo((): MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]> => {
    const valueFn = (editorState: Optional<EditorState>) => {
      if (editorState?.type === "asset") return editorState.data.selectedNodeIds;
      else if (editorState?.type === "scene") return editorState.data.selectedNodeIds;
      else return [];
    };
    return new MutableRef({
      value() {
        return valueFn(editor.state.value);
      },
      observe: pipe(
        editor.state.observe,
        map(valueFn),
        distinct(listIdentity)
      ),
      apply: fn => editor.state.apply(value => {
        if (value?.type === "asset") {
          const operations = typeof fn === "function" ? fn(value.data.selectedNodeIds) : fn;
          return ValueFn.apply([{
            type: "asset",
            operations: [{
              type: "update-selected-node-ids",
              operations
            }]
          }]);
        } else if (value?.type === "scene") {
          const operations = typeof fn === "function" ? fn(value.data.selectedNodeIds) : fn;
          return ValueFn.apply([{
            type: "scene",
            operations: [{
              type: "update-selected-node-ids",
              operations
            }]
          }]);
        } else {
          return [];
        }
      }).then(valueFn)
    })
  }, [editor.state]);
}
