import {QLabInstance, resourceObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {Asset, AssetReference} from "common/legends/index.ts";
import {from, map, Observable, switchAll} from "common/observable";
import {Optional} from "common/types/index.ts";

export function assetObservable(instance: QLabInstance) {
  return (assetReference: Observable<Optional<AssetReference>>): Observable<Optional<Asset>> => pipe(
    assetReference,
    map((assetReference): Observable<Optional<Asset>> => assetReference ? pipe(
      instance.observe(assetReference.gameID),
      resourceObservable("asset", assetReference.assetID)
    ) : from(undefined)),
    switchAll()
  );
}
