import {ConstantOperation, constantType, ObjectType, SetOperation, SetType} from "#common/types/index.ts";
import {z} from "zod";
import {Dnd5eCondition} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-condition/dnd-5e-condition.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export const Dnd5eConditionImmunityModifier = z.object({
  modifierID: Dnd5eModifierID,
  conditions: z.array(Dnd5eCondition)
});
export type Dnd5eConditionImmunityModifier = z.infer<typeof Dnd5eConditionImmunityModifier>;

export const Dnd5eConditionImmunityModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-conditions"), operations: z.array(SetOperation(Dnd5eCondition))})
]);
export type Dnd5eConditionImmunityModifierOperation = z.infer<typeof Dnd5eConditionImmunityModifierOperation>;

export const dnd5eConditionImmunityModifierType = new ObjectType({
  modifierID: constantType,
  conditions: new SetType<Dnd5eCondition>()
});
