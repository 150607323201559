import {z} from "zod";
import {MultiType} from "../multi-type/index.ts";
import {Arc, ArcFn, ArcOperation, arcType} from "./arc.ts";
import {Cone, ConeFn, ConeOperation, coneType} from "./cone.ts";
import {Rectangle, RectangleOperation, rectangleType} from "./rectangle.ts";
import {Type} from "../../type/index.ts";
import {ValueOperation, ValueType} from "../value/index.ts";
import {Point} from "../point/index.ts";
import {Vector2} from "../../../math/vector/vector2.ts";
import {Size} from "../size/index.ts";

export const Shape = z.discriminatedUnion("type", [
  z.object({type: z.literal("arc"), data: Arc}),
  z.object({type: z.literal("cone"), data: Cone}),
  z.object({type: z.literal("rectangle"), data: Rectangle})
]);
export type Shape = z.infer<typeof Shape>;
export const ShapeOperation = ValueOperation(Shape, z.discriminatedUnion("type", [
  z.object({type: z.literal("arc"), operations: z.array(ArcOperation)}),
  z.object({type: z.literal("cone"), operations: z.array(ConeOperation)}),
  z.object({type: z.literal("rectangle"), operations: z.array(RectangleOperation)})
]));
export type ShapeOperation = z.infer<typeof ShapeOperation>;

export type ShapeTypes = {
  arc: Type<Arc, ArcOperation>,
  cone: Type<Cone, ConeOperation>,
  rectangle: Type<Rectangle, RectangleOperation>,
};
export const shapeType: Type<Shape, ShapeOperation> = new ValueType(new MultiType<ShapeTypes>({
  arc: arcType,
  cone: coneType,
  rectangle: rectangleType
}));

export const ShapeFn = {
  getShapeOrigin: (shape: Shape, origin: Point): Point => {
    if (shape.type === "rectangle") {
      return origin;
    } else if (shape.type === "cone") {
      const [a, b] = ConeFn.toPoints(shape.data);
      return Vector2.add([
        -Math.min(a[0], b[0], 0),
        -Math.min(a[1], b[1], 0)
      ], origin);
    } else if (shape.type === "arc") {
      const points = ArcFn.toPoints(shape.data);
      return Vector2.add([
        -Math.min(...points.map(p => p[0])),
        -Math.min(...points.map(p => p[1]))
      ], origin);
    }
    return origin;
  },
  getShapeSize: (shape: Shape): Size => {
    if (shape.type === "rectangle") {
      return [shape.data.width, shape.data.height];
    } else if (shape.type === "cone") {
      const [a, b] = ConeFn.toPoints(shape.data);
      return [
        Math.max(a[0], b[0], 0) - Math.min(a[0], b[0], 0),
        Math.max(a[1], b[1], 0) - Math.min(a[1], b[1], 0)
      ];
    } else if (shape.type === "arc") {
      const points = ArcFn.toPoints(shape.data);
      return [
        Math.max(...points.map(p => p[0])) - Math.min(...points.map(p => p[0])),
        Math.max(...points.map(p => p[1])) - Math.min(...points.map(p => p[1]))
      ];
    }
    return [0, 0];
  }
};