import {twMerge} from "tailwind-merge";
import {FaUser} from "react-icons/fa";
import {NodeId, UserID} from "common/legends/index.ts";
import {useFromObservable} from "#lib/qlab/react/use-from-observable.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {distinct, map} from "common/observable";
import React from "react";
import {pipe} from "common/pipe";
import {FileReference} from "common/types/index.ts";
import {usePlayerName} from "../../routes/game/use-player-name.ts";
import {usePlayerIcon} from "../../routes/game/use-player-icon.ts";
import {useNode} from "../viewport/token/use-node.ts";
import {useNodeIcon} from "./use-get-node-icon.ts";
import {useIsPartyNode} from "./node/use-is-party-node.ts";
import {useIsGameMaster} from "./game/use-is-game-master.ts";
import {useDrag} from "react-dnd";

export type NodeIconProps = {
  userID: UserID;
  nodeID?: NodeId;
  icon?: FileReference;
  className?: string;
  size?: number;
  onClick?: () => void;
};

export function NodeIcon({userID, nodeID, className, icon, size = 20, onClick}: NodeIconProps) {
  const playerName = usePlayerName(userID);
  const playerIcon = usePlayerIcon(userID);
  const node = useNode(nodeID);
  const name = useObservable(pipe(node.observe, map(node => node?.data.name), distinct()), undefined, [node.observe]);

  const nodeIcon = useObservable(useNodeIcon(useFromObservable(nodeID)), undefined, [nodeID]);
  const isParty = useIsPartyNode(nodeID);
  const isGameMaster = useIsGameMaster();
  const isReveal = isParty || isGameMaster || nodeID === undefined;
  const displayIcon = isReveal ? icon || nodeIcon || playerIcon : playerIcon;

  const [, dragRef] = useDrag(() => ({
    type: "legends/token",
    item: ({
      nodeID
    })
  }), [nodeID]);

  return (
    <div ref={dragRef} className={twMerge(
      "rounded-full bg-zinc-900 w-10 h-10 inline-flex items-center justify-center pointer-events-auto ring-1 ring-inset ring-zinc-900 overflow-hidden",
      nodeID && "cursor-pointer",
      className
    )} title={isReveal && name ? `${name} (${playerName})` : playerName} onClick={onClick}>
      {(isReveal && displayIcon) && <img draggable={false} alt={name ? `${name} (${playerName})` : playerName} src={displayIcon} crossOrigin="anonymous" className="w-full h-full" />}
      {(!isReveal || !displayIcon) && <FaUser size={size} />}
    </div>
  );
}
