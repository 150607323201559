import {Node, NodeOperation} from "common/legends/index.ts";
import {ConstantOperation, Optional, Tree, TreeOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {distinct} from "common/observable";
import {SceneNodeSelectionRef, SelectionRef} from "../../../container/editor/state/selection-ref.ts";
import {NodeProperties} from "../node/node-properties.tsx";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/index.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";

function useSceneNode(reference: SceneNodeSelectionRef): MutableRef<Optional<Node>, NodeOperation[]> {
  const database = useDatabase();
  return useMemo((): MutableRef<Optional<Node>, NodeOperation[]> => {
    const valueFn = (database: QLabDatabase) => {
      const resource = database.resources[reference.resourceId];
      if (resource?.type !== "scene") return undefined;
      return Tree.getItemById(resource.data.children, reference.nodeId);
    };

    return new MutableRef<Optional<Node>, NodeOperation[]>({
      value(): Optional<Node> {
        return valueFn(database.value);
      },
      observe: distinct<Optional<Node>>()((observer) => {
        return database.observe({
          next(v) {observer.next(valueFn(v))},
          error: observer.error,
          complete: observer.complete
        });
      }),
      apply: (fn) => database.apply(prev => {
        const resource = prev.resources[reference.resourceId];
        if (resource?.type !== "scene") return [];
        const path = Tree.getPath(resource.data.children, node => node.data.id === reference.nodeId);
        if (path === undefined) return [];
        const operations = fn(valueFn(prev));
        return [{
          type: "resource",
          resourceID: reference.resourceId,
          operations: [{type: "apply", operations: [{type: "scene", operations: [{
            type: "update-children", operations: TreeOperation.apply(path, operations)
          }]}]}]
        }];
      }).then(v => valueFn(v))
    })
  }, [database, reference]);
}

export type NodeFieldProps = {
  reference: SceneNodeSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function SceneNodePropertiesView({reference, pinned}: NodeFieldProps) {
  const node = usePresent(useSceneNode(reference));
  if (!node) return <></>;
  return <NodeProperties value={node} reference={reference} pinned={pinned} />
}