import {MarkerTurn, MarkerTurnOperation, NodeTurn, NodeTurnOperation, Turn, TurnOperation, TurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {useMemo} from "react";
import {useObservable} from "#lib/qlab/index.ts";
import {PlayerNodeTurnView} from "./player-node-turn-view.tsx";
import {PlayerMarkerTurnView} from "./player-marker-turn-view.tsx";
import {MutableRef} from "common/ref";

export type PlayerTurnViewProps = {
  value: MutableRef<Turn, TurnOperation[]>;
  isCurrentTurn: boolean;
};
export function PlayerTurnView({value, isCurrentTurn}: PlayerTurnViewProps) {
  const {type, data} = useMemo(() => TurnSignals(value), [value]);
  const resolvedType = useObservable(type.observe, undefined, [type.observe]);
  if (resolvedType === undefined) return (<></>);

  if (resolvedType === "node") {
    // @ts-ignore
    return <PlayerNodeTurnView isCurrentTurn={isCurrentTurn} nodeTurnRef={data as MutableRef<NodeTurn, NodeTurnOperation[]>} />
  } else if (resolvedType === "marker") {
    // @ts-ignore
    return <PlayerMarkerTurnView isCurrentTurn={isCurrentTurn} value={data as MutableRef<MarkerTurn, MarkerTurnOperation[]>} />
  } else {
    return <>Unknown!</>
  }
}
