import {z} from "zod";

export const Dnd5eSpellSchool = z.union([
  z.literal("abjuration"),
  z.literal("conjuration"),
  z.literal("divination"),
  z.literal("enchantment"),
  z.literal("evocation"),
  z.literal("illusion"),
  z.literal("necromancy"),
  z.literal("transmutation")
]);
export type Dnd5eSpellSchool = z.infer<typeof Dnd5eSpellSchool>;
export const DND_5E_SPELL_SCHOOLS = [
  "abjuration",
  "conjuration",
  "divination",
  "enchantment",
  "evocation",
  "illusion",
  "necromancy",
  "transmutation"
] as const as readonly Dnd5eSpellSchool[];

export const DND_5E_SPELL_SCHOOL_LABEL: {[school in Dnd5eSpellSchool]: string} = {
  "abjuration": "Abjuration",
  "conjuration": "Conjuration",
  "divination": "Divination",
  "enchantment": "Enchantment",
  "evocation": "Evocation",
  "illusion": "Illusion",
  "necromancy": "Necromancy",
  "transmutation": "Transmutation",
};
