import {ExtractOperation, ExtractValue, MultiType, Type} from "common/types/index.ts";
import {
  SceneViewportProperties,
  SceneViewportPropertiesOperation,
  sceneViewportPropertiesType
} from "../../../viewport/scene/scene-viewport-properties.ts";
import {
  AssetViewportProperties,
  AssetViewportPropertiesOperation,
  assetViewportPropertiesType
} from "../../../viewport/character/asset-viewport-properties.ts";

export interface Editors {
  "scene": Type<SceneViewportProperties, SceneViewportPropertiesOperation>;
  "asset": Type<AssetViewportProperties, AssetViewportPropertiesOperation>;
}
export type EditorType = keyof Editors;
export type Editor = {
  [Type in EditorType]: {
    type: Type,
    data: ExtractValue<Editors[Type]>
  }
}[EditorType];
export type EditorOperation = {
  [Type in EditorType]: {
    type: Type,
    operations: ExtractOperation<Editors[Type]>[]
  }
}[EditorType];

export const editorType: Type<Editor, EditorOperation> = new MultiType({
  scene: sceneViewportPropertiesType,
  asset: assetViewportPropertiesType
}, v => {
  if (v.type === "character") return ({...v, type: "asset"});
  return v;
});
