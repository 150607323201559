import {Color, ColorOperation, ColorRef, HSLA, ValueFn} from "common/types/index.ts";
import {HTMLAttributes, useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export type ColorButtonProps = Omit<HTMLAttributes<HTMLInputElement>, "value" | "onChange"> & {
  valueRef: MutableRef<HSLA, ColorOperation[]>;
  readOnly?: boolean;
  disabled?: boolean;
};

export function InputGroupColorButton({valueRef, className, readOnly, disabled, ...props}: ColorButtonProps){
  const {colorRef} = useMemo(() => ColorRef(valueRef), [valueRef]);
  const value = useRefValue(colorRef);
  return (<input type="color" readOnly={readOnly} disabled={disabled} className={twMerge("w-full bg-transparent", className)} value={`#${Color.hslToHex(value)}`} onChange={readOnly ? undefined : (ev) => {
    const [r,g,b] = Color.fromHex(ev.target.value.substring(1));
    colorRef.apply(prev => ValueFn.set(prev, Color.toHSL([r,g,b])));
  }} {...props} />);
}