import {Color, HSLA, Transform, Tree} from "common/types/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import React, {useMemo} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {Tool, ToolOperation} from "../../../common/tool-mode/tool.ts";
import {WallLineShader} from "../../../common/measurement/wall-line-shader.tsx";
import {AreaToolSelection} from "../../../common/tool-mode/area/area-tool-select-mode.ts";
import {VertexSelectionIndicator} from "../node/node-view/vertex-selection-indicator.tsx";
import {AreaShader} from "../../../common/measurement/area-shader/area-shader.tsx";
import {cubicBezier} from "../node/layer-view/bezier.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {LineShader} from "../../../common/measurement/line-shader.tsx";
import {MutableRef, Ref} from "common/ref";
import {SplineFn} from "common/types/generic/spline/spline.ts";
import {getNodeTransformMatrix} from "../../../common/tool-mode/use-node-pos-to-world-pos.ts";
import {listIdentity} from "common/observable";

function useAreaNode(nodesRef: Ref<Node[]>, nodeID: NodeId) {
  return useComputedValue(nodesRef, nodes => {
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "area") return undefined;
    return node.data;
  }, [nodeID]);
}
function useNodeMatrix(nodesRef: Ref<Node[]>, nodeID: NodeId) {
  return useRefValue(useMemo(() => nodesRef.map(nodes => getNodeTransformMatrix(nodes, nodeID)).distinct(listIdentity), [nodesRef]));
}

function AreaVertexNode({nodeID, projection, view, nodes: nodesRef, selection}: {
  projection: Matrix4f;
  view: Transform;
  nodeID: NodeId,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const areaNode = useAreaNode(nodesRef, nodeID);
  const pointMatrix = useNodeMatrix(nodesRef, nodeID);

  const point = useMemo(() => {
    if (selection?.type !== "vertex") return undefined;
    const area = areaNode?.areas[selection.areaIndex];
    if (area === undefined) return undefined;
    return selection.vertexIndex === 0 ? area.start : area.curves[selection.vertexIndex - 1].end;
  }, [areaNode, selection]);

  return <>
    {point && <VertexSelectionIndicator projection={projection} view={view} model={Transform.DEFAULT} origin={Vector2.multiplyMatrix4x4(point, pointMatrix)} />}
  </>
}

function AreaEdgeNode({nodeID, projection, view, nodes, selection}: {
  projection: Matrix4f;
  view: Transform;
  nodeID: NodeId,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const areaNode = useAreaNode(nodes, nodeID);
  const area = areaNode?.areas[selection.areaIndex];
  const pointMatrix = useNodeMatrix(nodes, nodeID);

  const lines = useMemo(() => {
    if (!area) return undefined;
    if (selection?.type !== "edge" && selection?.type !== "control-point") return undefined;
    const {edgeIndex} = selection;
    return cubicBezier(
      edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end,
      edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end,
      edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2,
      edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1,
      8,
      false,
      false
    ).map(line => ({
      ...line,
      start: Vector2.multiplyMatrix4x4(line.start, pointMatrix),
      end: Vector2.multiplyMatrix4x4(line.end, pointMatrix)
    }));
  }, [area, selection, pointMatrix]);

  const controlPoints = useMemo(() => {
    if (!area) return undefined;
    if (selection?.type !== "edge" && selection?.type !== "control-point") return undefined;
    const {edgeIndex} = selection;

    const start = edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end;
    const cp1 = edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;

    const end = edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end;
    const cp2 = edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;

    return [
      start,
      end,
      Vector2.add(start, cp1),
      Vector2.add(end, cp2)
    ].map(p => Vector2.multiplyMatrix4x4(p, pointMatrix));
  }, [area, selection, pointMatrix]);

  return <>
    {lines && <WallLineShader projection={projection} view={view} model={Transform.DEFAULT} lines={lines} scale={1} color={Color.WHITE} opacity={1} />}
    {controlPoints && <>
      <LineShader projection={projection} view={view} model={Transform.DEFAULT} start={controlPoints[0]} end={controlPoints[2]} scale={1} color={Color.BLACK75} />
      <LineShader projection={projection} view={view} model={Transform.DEFAULT} start={controlPoints[1]} end={controlPoints[3]} scale={1} color={Color.BLACK75} />
      <VertexSelectionIndicator projection={projection} view={view} model={Transform.DEFAULT} origin={controlPoints[2]} />
      <VertexSelectionIndicator projection={projection} view={view} model={Transform.DEFAULT} origin={controlPoints[3]} />
    </>}
  </>
}


function AreaAreaNode({nodeID, projection, view, nodes: nodesRef, selection}: {
  projection: Matrix4f;
  view: Transform;
  nodeID: NodeId,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const areaNode = useAreaNode(nodesRef, nodeID);
  const pointMatrix = useNodeMatrix(nodesRef, nodeID);
  const spline = areaNode?.areas[selection.areaIndex];

  return <>
    {spline && (<AreaShader
      projection={projection}
      view={view}
      model={Transform.DEFAULT}
      spline={SplineFn.map(spline, p => Vector2.multiplyMatrix4x4(p, pointMatrix))}
      scale={1}
      color={Color.WHITE25} />)}
  </>
}

export function AreaToolView({projection, view, value, nodes}: {
  projection: Matrix4f;
  view: Transform;
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
}) {
  const areaTool = useComputedValue(value, value => value.type === "area" ? value.data : undefined);
  if (areaTool === undefined) return <></>
  if (areaTool.mode?.type === "create") {
    return <>{areaTool.mode.data && <AreaShader projection={projection} view={view} model={Transform.DEFAULT} spline={areaTool.mode.data} scale={1} color={[Color.GREEN[0], Color.GREEN[1], Color.GREEN[2], 0.5] as HSLA} />}
    </>;
  } else if (areaTool.mode?.type === "select") {
    if (areaTool.mode.data?.type === "vertex") {
      return <AreaVertexNode projection={projection} view={view} nodeID={areaTool.nodeID} selection={areaTool.mode.data} nodes={nodes} />
    } else if (areaTool.mode.data?.type === "edge" || areaTool.mode.data?.type === "control-point") {
      return <AreaEdgeNode projection={projection} view={view} nodeID={areaTool.nodeID} selection={areaTool.mode.data} nodes={nodes}/>
    } else if (areaTool.mode.data?.type === "area") {
      return <AreaAreaNode projection={projection} view={view} nodeID={areaTool.nodeID} selection={areaTool.mode.data} nodes={nodes}/>
    }
    return <></>
  } else {
    return <></>
  }
}
