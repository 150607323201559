import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, StringOperation, stringType, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MathExpression} from "#common/math/index.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ValuePropertyRef} from "#common/types/generic/value/value-property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eVariableModifier = z.object({
  modifierID: Dnd5eModifierID,
  name: z.string(),
  expression: MathExpression
});
export type Dnd5eVariableModifier = z.infer<typeof Dnd5eVariableModifier>;

export const Dnd5eVariableModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(MathExpression, ConstantOperation))})
]);
export type Dnd5eVariableModifierOperation = z.infer<typeof Dnd5eVariableModifierOperation>;

export const dnd5eVariableModifierType: Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation> = new ObjectType({
  modifierID: constantType,
  name: stringType,
  expression: new ValueType(constantType)
});

export function Dnd5eVariableOverrideModifierSignals(signal: MutableRef<Dnd5eVariableModifier, Dnd5eVariableModifierOperation[]>) {
  return ({
    name: PropertyRef<Dnd5eVariableModifier, Dnd5eVariableModifierOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(signal),
    expression: ValuePropertyRef<Dnd5eVariableModifier, Dnd5eVariableModifierOperation, MathExpression, ConstantOperation>(
      value => value.expression,
      operations => [{type: "update-expression", operations}]
    )(signal)
  });
}

