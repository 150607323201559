import {Input, InputProps} from "#lib/components/index.ts";
import {StringFn, StringOperation} from "common/types/index.ts";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {FaSpinner} from "react-icons/fa";
import {forwardRef, Ref, useCallback, useState} from "react";
import {FaCheck} from "react-icons/fa6";
import {BareIconButton} from "#lib/components/bare-icon-button/index.ts";
import {toPromise} from "common/observable";
import {MutableRef} from "common/ref";

export type InputStringProps = {
  value: MutableRef<string, StringOperation[]>;
} & Omit<InputProps, "value" | "onChange">;

export const InputString = forwardRef<HTMLInputElement, InputStringProps>(function InputString({value, className, ...props}: InputStringProps, ref: Ref<HTMLInputElement>) {
  const query = useObservableLoader(value.observe);
  const setValue = useCallback((nextValue: string) => value.apply(prev => StringFn.set(prev, nextValue)), [value.apply]);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState<string | undefined>(undefined);

  const edit = useCallback(() => {
    if (query.isLoading) return;
    setEditValue(`${query.data}`);
    setEditMode(true);
  }, [setEditValue, setEditMode, query.data, query.isLoading]);

  const finalizeEdit = useCallback(async () => {
    const initialValue = await toPromise(value.observe);
    const expression = editValue!;
    if (initialValue !== expression) setValue(expression);
    setEditMode(false);
  }, [editValue, setValue, setEditMode, value]);

  if (query.isLoading) return <div className="w-full flex items-center justify-center"><FaSpinner className="animate-spin opacity-40" /></div>
  return <div className="w-full flex items-center gap-2">
    {!editMode && <Input ref={ref} key="editor" type="text" {...props} readOnly value={query.data} onClick={props.readOnly ? undefined : edit} onFocus={props.readOnly ? undefined : edit} />}
    {editMode && <Input ref={ref} key="editor" type="text" {...props} autoFocus value={editValue} onChange={ev => setEditValue(ev.target.value)} onBlur={(ev) => {
      finalizeEdit();
      return props.onBlur?.(ev);
    }} onKeyDown={ev => {
      if (ev.key === "Enter") finalizeEdit();
      if (ev.key === "Escape") setEditMode(false);
    }} />}
    {editMode && <BareIconButton tabIndex={-1} variant="primary"><FaCheck /></BareIconButton>}
  </div>
});