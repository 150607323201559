import {RefObject, useCallback, useRef} from "react";
import {NumberFn, Point, Transform, TransformOperation, ValueFn} from "common/types/index.ts";
import {getWorldPositionFromScreenPosition, useGetWorldPositionFromScreenPosition} from "./use-get-world-pos.ts";
import {getScreenPosition, useGetScreenPosition} from "./use-get-screen-pos.ts";
import {useSample} from "#lib/components/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {MutableRef} from "common/ref";

type TouchState = {
  touches: {[identifier: number]: {
      startPosition: Point
    }}
};
const DEFAULT_TOUCH_STATE: TouchState = {
  touches: {}
};
export function useTouchHandlers(canvasRef: RefObject<HTMLCanvasElement>, view: MutableRef<Transform, TransformOperation[]>) {
  const getWorldPos = useGetWorldPositionFromScreenPosition(view);
  const getScreenPos = useGetScreenPosition(canvasRef);

  const touchState = useRef(DEFAULT_TOUCH_STATE);
  const onTouchStart = useCallback((ev: TouchEvent) => {
    for (let i = 0; i < ev.touches.length; i ++) {
      let touch = ev.touches.item(i)!;
      touchState.current.touches[touch.identifier] = {
        startPosition: getWorldPos(getScreenPos([touch.clientX, touch.clientY]))
      };
    }
    // if (ev.touches.length > 1) {
    //   ev.preventDefault();
    //   return false;
    // }
  }, [touchState, getWorldPos, getScreenPos]);

  const updatePosition = useSample(useCallback((touches: Touch[]) => {
    if (touches.length === 1) {
      const p1 = touches[0];
      const fixedP1 = touchState.current.touches[p1.identifier]?.startPosition;
      if (!fixedP1) {
        return;
      }

      view.apply(prev => {
        const newP1 = getWorldPositionFromScreenPosition(prev, getScreenPosition(canvasRef.current!, [p1.clientX, p1.clientY]));
        const offset = Vector2f.subtract(newP1, fixedP1);
        const viewPosition: Vector2f = Vector2f.subtract(
          Vector2f.rotate(Vector2f.multiply(
            prev.position,
            -1 / prev.scale), -prev.rotation*Math.PI/180
          ), offset);
        const newPosition = Vector2f.rotate(
          Vector2f.multiply(viewPosition, -prev.scale),
          prev.rotation*Math.PI/180
        );

        return ValueFn.apply([
          {type: "update-position", operations: [
              {type: "update-x", operations: NumberFn.set(prev.position[0], newPosition[0])},
              {type: "update-y", operations: NumberFn.set(prev.position[1], newPosition[1])}
            ]}
        ])
      });
    } else if (touches.length === 2) {
      const [p1, p2] = touches;
      const fixedP2 = touchState.current.touches[p2.identifier]?.startPosition;
      const fixedP1 = touchState.current.touches[p1.identifier]?.startPosition;
      if (!fixedP1 || !fixedP2) return;
      view.apply(prev => {
        const newP1 = getWorldPositionFromScreenPosition(prev, getScreenPosition(canvasRef.current!, [p1.clientX, p1.clientY]));
        const newP2 = getWorldPositionFromScreenPosition(prev, getScreenPosition(canvasRef.current!, [p2.clientX, p2.clientY]));

        const fixed = Vector2f.subtract(fixedP2, fixedP1);
        const fixedDist = Vector2f.length(fixed);
        const fixedMid = Vector2f.add(fixedP1, Vector2f.multiply(fixed, 0.5));

        const line = Vector2f.subtract(newP2, newP1);
        const newDist = Vector2f.length(line);
        const mid = Vector2f.add(newP1, Vector2f.multiply(line, 0.5));

        const fixedRotation = Math.atan2(fixed[1], fixed[0]) * 180 / Math.PI;
        const newRotation = Math.atan2(line[1], line[0]) * 180 / Math.PI;

        let rotation = newRotation - fixedRotation;
        if (rotation < -180) rotation += 360;
        if (rotation > 180) rotation -= 360;

        const scaleMult = newDist / fixedDist;
        const newScale = prev.scale * scaleMult;

        const offset = Vector2f.subtract(mid, fixedMid);
        const viewPosition: Vector2f = Vector2f.subtract(
          Vector2f.rotate(Vector2f.multiply(
            prev.position,
            -1 / prev.scale), -prev.rotation*Math.PI/180
          ), offset);
        const diff = Vector2f.subtract(viewPosition, fixedMid);
        const newPosition = Vector2f.rotate(Vector2f.add(
          Vector2f.multiply(viewPosition, -newScale),
          Vector2f.subtract(
            Vector2f.multiply(diff, prev.scale),
            Vector2f.multiply(diff, newScale)
          )
        ), (prev.rotation + rotation)*Math.PI/180);

        return ValueFn.apply([
          {type: "update-rotation", operations: NumberFn.increment(rotation)},
          {type: "update-position", operations: [
              {type: "update-x", operations: NumberFn.set(prev.position[0], newPosition[0])},
              {type: "update-y", operations: NumberFn.set(prev.position[1], newPosition[1])}
            ]},
          {type: "update-scale", operations: NumberFn.set(prev.scale, newScale)}
        ])
      });
    }
  }, [touchState, view, canvasRef]), 32);

  const onTouchMove = useCallback((ev: TouchEvent) => {
    if (ev.touches.length === 1) {
      const p1 = ev.touches.item(0)!;
      updatePosition([p1]);
    } else if (ev.touches.length === 2) {
      const p1 = ev.touches.item(0)!;
      const p2 = ev.touches.item(1)!;
      updatePosition([p1, p2]);
      ev.preventDefault();
      return false;
    } else if (ev.touches.length > 2) {
      ev.preventDefault();
      return false;
    }
  }, [updatePosition]);
  const onTouchEnd = useCallback((ev: TouchEvent) => {
    for (const key of Object.keys(touchState.current.touches)) {
      let found = false;
      for (let i = 0; i < ev.touches.length; i++) {
        const touch = ev.touches.item(i)!;
        if (touch.identifier === Number.parseInt(key)) {
          found = true;
          break;
        }
      }
      if (!found) delete touchState.current.touches[Number.parseInt(key)];
    }
  }, [touchState]);
  return {onTouchStart, onTouchMove, onTouchEnd};
}


