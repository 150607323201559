import {useCallback} from "react";
import {Transform, ValueFn} from "common/types/index.ts";
import {AssetID, TokenID} from "common/legends/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {useEditor} from "../../../container/editor/editor-context.ts";
import {ToolFn} from "../../../common/tool-mode/tool.ts";
import {ViewConfigurationFn} from "../../common/view-configuration.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {useStoreID} from "../../../../routes/game/use-store-id.ts";

export function useNavigateToAssetEditor() {
  const database = useDatabase();
  const editor = useEditor();
  const gameID = useStoreID();
  return useCallback((assetID: AssetID, tokenID?: TokenID) => {
    const asset = database.value.resources[assetID];
    if (asset?.type !== "asset") return;
    return editor.state.apply(prev => ValueFn.set(prev, ({
      type: "asset",
      data: {
        tokenReference: {gameID: gameID, assetID: assetID, tokenID: tokenID ?? asset.data.initialTokenId},
        toolMode: editor.state.value?.data.toolMode || ToolFn.DEFAULT,
        visibilityMask: editor.state.value?.data.visibilityMask || AccessMaskFn.DEFAULT,
        selectedNodeIds: [],
        view: Transform.DEFAULT,
        viewConfiguration: editor.state.value?.data.viewConfiguration || ViewConfigurationFn.DEFAULT
      }
    })));
  }, [database, editor.state, gameID])
}
