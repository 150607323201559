import {Apply, ApplyAction} from "#common/types/apply.ts";
import {createValueRef} from "#common/ref";
import {MutableRef} from "#common/ref";

export function observableValue<V, O>(
  initialValue: V,
  reducer: (value: V, operation: O) => V
): [MutableRef<V, V>, Apply<V, O>, (value: V) => void] {
  let signal = createValueRef(initialValue);
  const setObservable = (nextValue: V) => signal.apply((_: V) => nextValue);
  const applyToObservable = (fn: ApplyAction<V, O>): Promise<V> => signal.apply((v: V) => reducer(v, fn(v)));
  return [signal, applyToObservable, setObservable];
}
