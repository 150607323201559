import {useInstance, useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {UserID} from "common/legends/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStoreID} from "../../../routes/game/use-store-id.ts";

export function useIsPlayerOnline(userID: UserID) {
  const instance = useInstance();
  const gameID = useStoreID();
  const currentUserID = useUserID();
  return useObservable(pipe(
    instance.observe(gameID),
    map(store => {
      if (!store.connected) return false;
      for (const connectionUserId of Object.values(store.connections)) {
        if (connectionUserId === userID) return true;
      }
      return userID === currentUserID;
    }),
    distinct()
  ), false, [instance, gameID, userID]);
}
