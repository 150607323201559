import {z} from "zod";
import {Dnd5eEffectID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {MutableRef} from "#common/ref";
import {generateNodeConditionID, NodeConditionID} from "./node-condition-i-d.ts";

export const EffectNodeCondition = z.object({
  conditionID: NodeConditionID,
  effectID: z.optional(Dnd5eEffectID)
});
export type EffectNodeCondition = z.infer<typeof EffectNodeCondition>;
export const EffectNodeConditionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-condition-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-effect-i-d"), operations: z.array(ValueOperation(z.optional(Dnd5eEffectID), ConstantOperation))})
]);
export type EffectNodeConditionOperation = z.infer<typeof EffectNodeConditionOperation>;
export const effectNodeConditionType: Type<EffectNodeCondition, EffectNodeConditionOperation> = new ObjectType({
  conditionID: constantType,
  effectID: new ValueType(constantType)
}, v => {
  if (v.conditionID === undefined) v.conditionID = generateNodeConditionID();
  return v;
});

export function EffectNodeConditionSignal(signal: MutableRef<EffectNodeCondition, EffectNodeConditionOperation[]>) {
  return ({
    effectID: PropertyRef<EffectNodeCondition, EffectNodeConditionOperation, Optional<Dnd5eEffectID>, ValueOperation<Optional<Dnd5eEffectID>, ConstantOperation>>(
      value => value.effectID,
      operations => [{type: "update-effect-i-d", operations}]
    )(signal)
  })
}
