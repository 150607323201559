import {Dnd5eAttribute, Dnd5eCharacter, Dnd5eFeature, Sheet} from "#common/legends/index.ts";
import {Optional} from "#common/types/index.ts";
import {getActiveFeatures} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";

export const Dnd5eSheetFn = {
  getEffects: (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]) => {
    return getActiveFeatures(sheet, globalFeatures).flatMap(f => f.effects);
  },
  getAttributeModifier: (sheet: Optional<Sheet>, attribute: Dnd5eAttribute) => {
    if (sheet?.type === "dnd-5e-character") {
      return Dnd5eSheetFn.getModifier(sheet.data.attributes[attribute]!);
    } else if (sheet?.type === "dnd-5e-stat-block") {
      return Dnd5eSheetFn.getModifier(sheet.data.attributes[attribute]!);
    } else {
      return 0;
    }
  },
  getModifier: (value: number) => {
    return Math.floor((value - 10) / 2);
  },
  getProficiencyBonus: (sheet: Optional<Sheet>) => {
    if (sheet?.type === "dnd-5e-character") {
      return Dnd5eCharacter.getProficiencyBonus(sheet.data);
    } else if (sheet?.type === "dnd-5e-stat-block") {
      return sheet.data.proficiencyBonus || 2;
    } else {
      return 0;
    }
  }
} as const;
