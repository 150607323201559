import {InputGroup, InputGroupLabel, InputGroupRange} from "#lib/components/index.ts";
import {NumberOperation} from "common/types/index.ts";
import {MutableRef} from "common/ref";

export type OpacityFieldProps = {
  value: MutableRef<number, NumberOperation[]>;
};

export function OpacityField({value}: OpacityFieldProps) {
  return (
    <InputGroup>
      <InputGroupLabel>Opacity</InputGroupLabel>
      <InputGroupRange min={0} max={1} value={value} step={0.1} />
    </InputGroup>
  )
}