import {z} from "zod";
import {MultiType, Type} from "#common/types/index.ts";
import {InteractionTriggerClick, InteractionTriggerClickOperation, interactionTriggerClickType} from "./interaction-trigger-click.ts";
import {InteractionTriggerEnter, InteractionTriggerEnterOperation, interactionTriggerEnterType} from "./interaction-trigger-enter.ts";
import {InteractionTriggerExit, InteractionTriggerExitOperation, interactionTriggerExitType} from "./interaction-trigger-exit.ts";
import {InteractionTriggerID} from "./interaction-trigger-i-d.ts";

export const InteractionTrigger = z.discriminatedUnion("type", [
  z.object({type: z.literal("click"), data: InteractionTriggerClick}),
  z.object({type: z.literal("enter"), data: InteractionTriggerEnter}),
  z.object({type: z.literal("exit"), data: InteractionTriggerExit})
]);
export type InteractionTrigger = z.infer<typeof InteractionTrigger>;

export const InteractionTriggerOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("click"), operations: z.array(InteractionTriggerClickOperation)}),
  z.object({type: z.literal("enter"), operations: z.array(InteractionTriggerEnterOperation)}),
  z.object({type: z.literal("exit"), operations: z.array(InteractionTriggerExitOperation)})
]);
export type InteractionTriggerOperation = z.infer<typeof InteractionTriggerOperation>;

export type InteractionTriggerTypes = {
  click: Type<InteractionTriggerClick, InteractionTriggerClickOperation>;
  enter: Type<InteractionTriggerEnter, InteractionTriggerEnterOperation>;
  exit: Type<InteractionTriggerEnter, InteractionTriggerExitOperation>;
};
export const interactionTriggerType: Type<InteractionTrigger, InteractionTriggerOperation> = new MultiType<InteractionTriggerTypes>({
  click: interactionTriggerClickType,
  enter: interactionTriggerEnterType,
  exit: interactionTriggerExitType
});

export function getInteractionTriggerID(value: InteractionTrigger): InteractionTriggerID {
  return value.data.interactionTriggerID;
}

export function copyInteractionTrigger(value: InteractionTrigger): InteractionTrigger {
  return value;
}
