import {z} from "zod";

export const IncrementNumberOperation = z.object({
  type: z.literal("increment"),
  amount: z.number()
});
export type IncrementNumberOperation = z.infer<typeof IncrementNumberOperation>;
export const DecrementNumberOperation = z.object({
  type: z.literal("decrement"),
  amount: z.number()
});
export type DecrementNumberOperation = z.infer<typeof DecrementNumberOperation>;
export const SetNumberOperation = z.object({
  type: z.literal("set"),
  prevValue: z.number(),
  nextValue: z.number()
});
export type SetNumberOperation = z.infer<typeof SetNumberOperation>;

export const NumberOperation = z.discriminatedUnion("type", [IncrementNumberOperation, DecrementNumberOperation, SetNumberOperation]);
export type NumberOperation = z.infer<typeof NumberOperation>;

export const NumberFn = {
  set: (prevValue: number, nextValue: number): NumberOperation[] => {
    if (prevValue === nextValue) return [];
    return [{type: "set", prevValue, nextValue}];
  },
  increment: (amount: number): NumberOperation[] => [{type: "increment", amount}],
  decrement: (amount: number): NumberOperation[] => [{type: "decrement", amount}]
};
