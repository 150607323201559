import {useEffect, useState} from "react";

export function useIsCtrlDown() {
  const [ctrl, setCtrl] = useState(false);
  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      setCtrl(ev.ctrlKey);
    };
    document.addEventListener('keydown', keydownHandler);
    document.addEventListener('keyup', keydownHandler);
    return () => {
      document.addEventListener('keydown', keydownHandler);
      document.removeEventListener("keyup", keydownHandler);
    };
  }, [setCtrl])
  return ctrl;
}
