import {QLabStoreID, storeType, StoreType, StoreTypes, StoreValue} from "common/qlab/index.ts";
import {ExtractValue, ValueFn} from "common/types/index.ts";
import {useCallback} from "react";
import {useInstance} from "../../q-lab-instance-context.ts";

export function useCreateStore() {
  const instance = useInstance();
  return useCallback(<Type extends StoreType, Value = ExtractValue<StoreTypes[Type]>>(type: Type, storeId: QLabStoreID, data: Value): Promise<void> => {
    if (storeType.validate({type, data}).length > 0) throw new Error("Invalid Data.");
    return instance.applyToStore(storeId, _ => ValueFn.set(
      undefined,
      {type, data} as unknown as StoreValue
    )).then();
  }, [instance]);
}
