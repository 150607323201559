import {Dnd5eFeature, Sheet} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import React, {useMemo} from "react";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eDamageType, DND_5E_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {pipe} from "common/pipe";
import {combine, distinct, listIdentity, map} from "common/observable";
import {useGlobalFeatures} from "./dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../common/sheet/sheet-reference-context.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {Dnd5eDamageTypeTag} from "./dnd-5e/dnd-5e-modifier/dnd-5e-damage-type-view.tsx";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {MutableRef, Ref} from "common/ref";

function getSheetDamageVulnerabilities(sheet: Ref<Optional<Sheet>>, globalEffects: Ref<Dnd5eFeature[]>): Ref<Dnd5eDamageType[]> {
  const valueFn = (value: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eDamageType[] => {
    const damageTypes = getDnd5eSheetActiveModifiers(value, globalFeatures)
      .flatMap(modifier => modifier.type === "damage-vulnerability" ? modifier.data.damageTypes : []);
    return DND_5E_DAMAGE_TYPES.filter(type => damageTypes.includes(type));
  };
  return new MutableRef({
    value(): Dnd5eDamageType[] {
      return valueFn(sheet.value, globalEffects.value);
    },
    observe: pipe(
      combine(sheet.observe, globalEffects.observe),
      map(([sheet, globalEffects]) => valueFn(sheet, globalEffects)),
      distinct(listIdentity)
    ),
    apply: () => {throw new Error("Unsupported Operation")}
  });
}


export type Dnd5eDamageVulnerabilitiesViewProps = {};
export function Dnd5eDamageVulnerabilitiesView({}: Dnd5eDamageVulnerabilitiesViewProps) {
  const sheet = useSheetSignal(useSheetReference());
  const globalFeatures = useGlobalFeatures();
  const damageTypes = useRefValue(useMemo(() => getSheetDamageVulnerabilities(sheet, globalFeatures), [sheet, globalFeatures]));

  if (damageTypes.length === 0) return <></>;
  return <BaseComponent>
    <ButtonBar className="gap-1 items-start">
      <InputGroup>
        <InputGroupIcon />
        <InputGroupLabel>Damage Vulnerability</InputGroupLabel>
      </InputGroup>
      <div className="flex flex-row gap-x-1 gap-y-1 py-2 flex-wrap">
        {damageTypes.map(damageType => <Dnd5eDamageTypeTag key={damageType} type={damageType} />)}
      </div>
    </ButtonBar>
  </BaseComponent>
}
