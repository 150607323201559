import {useMemo} from "react";
import {distinct, map, Observer} from "common/observable";
import {ApplyAction, ConstantOperation, constantType, Optional, ValueFn, ValueOperation, ValueType} from "common/types/index.ts";
import {pipe} from "common/pipe";
import {usePlayerController} from "../../container/view/player-controller-provider.ts";
import {ActiveControllerOperation, ActiveControllerValue, TokenViewportPropertiesFn} from "./token-viewport-properties.ts";
import {ViewConfigurationFn} from "../common/view-configuration.ts";
import {useEditor} from "../../container/editor/editor-context.ts";
import {EditorState} from "../../container/editor/state/index.ts";
import {MutableRef} from "common/ref";
import {NodeId} from "common/legends/node/index.ts";

export function useIsPlayerController() {
  const playerController = usePlayerController();
  return playerController !== undefined;
}

export function useActiveController(): MutableRef<Optional<ActiveControllerValue>, ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>[]> {
  const editor = useEditor();
  const playerController = usePlayerController();
  return useMemo(() => {
    if (editor?.state !== undefined) {
      const valueFn = (value: EditorState): Optional<ActiveControllerValue> => {
        if (value?.type === "scene" || value?.type === "asset") {
          if (value.data.selectedNodeIds.length > 0) {
            const nodeIds = value.data.selectedNodeIds;
            return ({
              controllerNodeID: nodeIds[0].nodeId,
              view: value.data.view
            })
          }
        }
        return undefined;
      }
      return new MutableRef({
        value(): Optional<ActiveControllerValue> {
          return valueFn(editor.state.value);
        },
        observe: pipe(editor.state.observe, map(valueFn), distinct()),
        apply: _ => {
          throw new Error("Unsupported.");
        }
      });
    } else if (playerController?.state !== undefined) {
      return TokenViewportPropertiesFn.expand(playerController.state).activeController;
    } else {
      return new MutableRef({
        value(): Optional<ActiveControllerValue> {
          return undefined;
        },
        observe: (observer: Observer<Optional<ActiveControllerValue>>) => {
          observer.next(undefined);
          return () => {}
        },
        apply: async (_: ApplyAction<Optional<ActiveControllerValue>, ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>[]>) => {
          throw new Error("Unsupported.");
        }
      });
    }
  }, [editor?.state, playerController?.state]);
}

export function useActiveControllerReferenceNode(): MutableRef<Optional<NodeId>, ValueOperation<Optional<NodeId>, ConstantOperation>[]> {
  const value = useActiveController();
  return useMemo(() => new MutableRef({
    value() {
      return value.value?.controllerNodeID;
    },
    observe: pipe(
      value.observe,
      map(v => v?.controllerNodeID),
      distinct()
    ),
    apply: fn => value.apply(prev => {
      if (prev === undefined) return [];
      const nodeReferenceType = new ValueType(constantType);
      const nextValue = fn(prev?.controllerNodeID).reduce(nodeReferenceType.apply, prev?.controllerNodeID);
      return ValueFn.set(prev, {
        controllerNodeID: nextValue,
        view: prev?.view || ViewConfigurationFn.DEFAULT
      });
    }).then(response => {
      if (response === undefined) return undefined;
      return response.controllerNodeID;
    })
  }), [value]);
}
