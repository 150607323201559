import React, {useMemo} from "react";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {dnd5eSheetSavingThrowHasAdvantage, dnd5eSheetSavingThrowHasDisadvantage, dnd5eSheetSavingThrowProficiency} from "../dnd-5e/common/dnd-5e-sheet-saving-throw-proficiency.ts";
import {SavingThrowProficiency} from "./saving-throw-proficiency/index.ts";
import {useGlobalFeatures} from "./dnd-5e-action/use-global-features.ts";
import {dnd5eSheetSavingThrowModifiers} from "../dnd-5e/common/dnd-5e-sheet-saving-throw-modifiers.ts";
import {useRollSavingThrow} from "./dnd-5e-action/use-roll-saving-throw.ts";
import {Dice} from "common/dice/index.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {Dnd5eSavingThrowType, DND_5E_SAVING_THROW_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";


export type SavingThrowFieldProps = {
  attribute: Dnd5eSavingThrowType;
};
export function SavingThrowField({attribute}: SavingThrowFieldProps) {
  const sheet = useSheetSignal(useSheetReference());
  const globalEffects = useGlobalFeatures();
  const proficiency = useObservable(dnd5eSheetSavingThrowProficiency(sheet.observe, globalEffects.observe, attribute), "untrained", [sheet, globalEffects, attribute]);
  const hasAdvantage = useObservable(dnd5eSheetSavingThrowHasAdvantage(sheet.observe, globalEffects.observe, attribute), false, [sheet, globalEffects, attribute]);
  const hasDisadvantage = useObservable(dnd5eSheetSavingThrowHasDisadvantage(sheet.observe, globalEffects.observe, attribute), false, [sheet, globalEffects, attribute]);
  const savingThrowModifier = useObservable(dnd5eSheetSavingThrowModifiers(sheet.observe, globalEffects.observe, attribute), undefined, [sheet, globalEffects, attribute]);
  const contextSignal = useMemo(() => sheetVariablesSignal(sheet), [sheet]);
  const contextValue = useRefValue(contextSignal);
  const range = useMemo((): [number, number] => savingThrowModifier ? Dice.getRollRange(savingThrowModifier, contextValue) : [0, 0], [savingThrowModifier, contextValue]);
  const resolvedSavingThrowModifier = useMemo(() => savingThrowModifier ? Dice.toResolvedExpression(savingThrowModifier, contextValue) : undefined, [savingThrowModifier, contextValue]);

  const rollSavingThrow = useRollSavingThrow(useSelectedNodeID(), sheet);
  return (<ButtonBar>
    <InputGroup size="small" className="flex-1 px-2 gap-2">
      <SavingThrowProficiency value={proficiency}/>
      <InputGroupLabel className="flex-1 basis-0 shrink text-ellipsis overflow-hidden whitespace-nowrap text-sm">{DND_5E_SAVING_THROW_TITLE[attribute]}</InputGroupLabel>
      <span className="shrink-0 basis-6 text-xs italic font-bold text-white/50 text-right whitespace-nowrap" title={resolvedSavingThrowModifier ? resolvedSavingThrowModifier : "0"}>
        {Dice.toRangeDisplay(range)}
      </span>
    </InputGroup>
    <DiceButton hasAdvantage={hasAdvantage} hasDisadvantage={hasDisadvantage} onClick={ev => rollSavingThrow(attribute, ev.shiftKey, ev.ctrlKey)} />
  </ButtonBar>);
}