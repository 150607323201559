import {MapFn, MapOperation, MapRef} from "./map-operation.ts";
import {transformAll, Type} from "../../type/index.ts";
import {distinct, filter, map, Observer} from "#common/observable";
import {pipe} from "#common/pipe";
import {ApplyAction} from "#common/types/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";
import {MutableRef, Ref} from "#common/ref";

export class MapType<Key extends keyof any, Item, ItemOperation> implements Type<Record<Key, Item>, MapOperation<Key, Item, ItemOperation>> {
  constructor(private readonly itemType: Type<Item, ItemOperation>) {
  }

  apply = (value: Record<Key, Item>, operation: MapOperation<Key, Item, ItemOperation>): Record<Key, Item> => {
    switch (operation.type) {
      case "put":
        if (value[operation.key] !== undefined) {
          throw new Error("Invariant: Cannot put key that already exists.");
        }

        return ({
          ...value,
          [operation.key]: operation.item
        });
      case "delete": {
        if (value[operation.key] === undefined) {
          throw new Error("Invariant: Cannot delete key that does not exists.");
        }

        const newValue = {...value};
        delete newValue[operation.key];
        return newValue;
      }
      case "move": {
        if (value[operation.fromKey] === undefined) {
          throw new Error("Invariant: Cannot move non-existing key.");
        }
        if (value[operation.toKey] !== undefined) {
          throw new Error("Invariant: Cannot move key to existing key.");
        }

        const newValue = {...value};
        delete newValue[operation.fromKey];
        delete newValue[operation.toKey];
        newValue[operation.toKey] = value[operation.fromKey];
        return newValue;
      }
      case "apply": {
        if (value[operation.key] === undefined) {
          throw new Error("Invariant: Cannot apply on non-existing key.");
        }

        try {
          return ({
            ...value,
            [operation.key]: operation.operations.reduce(this.itemType.apply, value[operation.key])
          });
        } catch (e: any) {
          throw new QLabError(e.message, e.path ? [operation.key, ...e.path] : [operation.key]);
        }
      }
      default: return value;
    }
  }


  defaultValue = (): {[id: string]: Item} => {
    return ({});
  }

  invert = (operation: MapOperation<Key, Item, ItemOperation>): MapOperation<Key, Item, ItemOperation>[] => {
    switch (operation.type) {
      case "put": return [{type: "delete", key: operation.key, item: operation.item}];
      case "delete": return [{type: "put", key: operation.key, item: operation.item}];
      case "move": return [{type: "move", fromKey: operation.toKey, toKey: operation.fromKey}];
      case "apply": return [{type: "apply", key: operation.key, operations: operation.operations.flatMap(this.itemType.invert) }]
    }
  }

  transform = (leftOperation: MapOperation<Key, Item, ItemOperation>, topOperation: MapOperation<Key, Item, ItemOperation>, tieBreaker: boolean): MapOperation<Key, Item, ItemOperation>[] => {
    switch (leftOperation.type) {
      case "put":
        switch (topOperation.type) {
          case "put":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "move":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.toKey) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "apply": return [leftOperation];
          case "delete": return [leftOperation];
        }
        break;
      case "apply":
        switch (topOperation.type) {
          case "apply":
            if (leftOperation.key === topOperation.key) {
              return [{
                type: "apply",
                key: leftOperation.key,
                operations: transformAll(this.itemType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
              }];
            } else {
              return [leftOperation];
            }
          case "delete":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "move":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.fromKey) {
                return [{
                  type: "apply",
                  key: topOperation.toKey,
                  operations: leftOperation.operations
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "put":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
        }
        break;
      case "delete":
        switch (topOperation.type) {
          case "delete":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "apply":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [{
                  type: "delete",
                  key: leftOperation.key,
                  item: topOperation.operations.reduce((value, operation) => this.itemType.apply(value, operation), leftOperation.item)
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "put":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "move":
            if (!tieBreaker) {
              if (leftOperation.key === topOperation.fromKey) {
                return [{type: "delete", key: topOperation.toKey, item: leftOperation.item}];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation]
            }
        }
        break;
      case "move":
        switch (topOperation.type) {
          case "apply":
            if (!tieBreaker) {
              return [leftOperation];
            } else {
              return [leftOperation];
            }
          case "put":
            if (!tieBreaker) {
              if (leftOperation.fromKey === topOperation.key) {
                return [];
              } else if (leftOperation.toKey === topOperation.key) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "delete":
            if (!tieBreaker) {
              if (topOperation.key === leftOperation.fromKey) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "move":
            if (!tieBreaker) {
              if (leftOperation.fromKey === topOperation.fromKey) {
                return [{type: "move", fromKey: topOperation.toKey, toKey: leftOperation.toKey}];
              } else if (leftOperation.toKey === topOperation.toKey) {
                return [];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
        }
        break;
    }
    throw new Error("Not Implemented");
  }
  migrateValue = (value: any): Record<Key, Item> => {
    return Object.keys(value).reduce((obj, key) => {
      obj[key as Key] = this.itemType.migrateValue(value[key]);
      return obj;
    }, ({}) as Record<Key, Item>);
  }
  migrateOperation = (operation: any): MapOperation<Key, Item, ItemOperation>[] => {
    if (operation.type === "put") {
      return [{type: "put", key: operation.key, item: this.itemType.migrateValue(operation.item)}];
    } else if (operation.type === "move") {
      return [{type: "move", fromKey: operation.fromKey, toKey: operation.toKey}];
    } else if (operation.type === "delete") {
      return [{type: "delete", key: operation.key, item: this.itemType.migrateValue(operation.item)}]
    } else if (operation.type === "apply") {
      return [{type: "apply", key: operation.key, operations: operation.operations.flatMap(this.itemType.migrateOperation)}]
    } else {
      throw new Error("Unexpected Operation: " + JSON.stringify(operation));
    }
  }
  validate = (value: any): ValidationError[] => {
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type. Expected map.", value}}];
    return Object.entries(value).flatMap(([key, value]) => this.itemType.validate(value).map(error => ({
      path: [key, ...error.path],
      data: error.data
    })));
  }
}

export const MapSignals = {
  expand<Key extends string, I, O>(mapEntity: MapRef<Key, I, O>): Ref<{ [key in Key]?: MutableRef<I, O[]> }> {
    const KeyedSignal = (key: Key): MutableRef<I, O[]> => new MutableRef({
      value(): I {
        return mapEntity.value[key]!;
      },
      observe: pipe(
        mapEntity.observe,
        filter(features => features[key] !== undefined),
        map(features => features[key]!),
        distinct()
      ),
      apply: (fn: ApplyAction<I, O[]>) =>
        mapEntity.apply(prev => MapFn.apply(key, fn(prev[key]!)))
          .then(map => map[key]!)
    });

    return new MutableRef<{[key in Key]?: MutableRef<I, O[]>}, never[]>({
      value() {
        let signals: {[key in Key]?: MutableRef<I, O[]>} = {};
        const value = mapEntity.value;
        for (const key of Object.keys(value) as Key[]) {
          if (signals[key] !== undefined) continue;
          signals[key] = KeyedSignal(key);
        }
        return signals;
      },
      observe: (observer: Observer<{[key in Key]?: MutableRef<I, O[]>}>) => {
        let signals: {[key in Key]?: MutableRef<I, O[]>} = {};
        return mapEntity.observe({
          next: (values: {[key in Key]?: I}) => {
            signals = {
              ...signals
            };
            for (const key of Object.keys(signals) as Key[]) {
              if (values[key] === undefined) {
                delete signals[key];
              }
            }
            for (const key of Object.keys(values) as Key[]) {
              if (signals[key] !== undefined) continue;
              signals[key] = KeyedSignal(key);
            }
            observer.next(signals);
          },
          complete: observer.complete,
          error: observer.error
        });
      },
      apply: () => Promise.reject("Unsupported")
    });
  }
};
