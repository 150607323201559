import {MapFn, MapRef, MapSignals, NumberOperation} from "common/types/index.ts";
import {Dnd5eMovementType, DND_5E_MOVEMENT_TYPES} from "common/legends/index.ts";
import {
  ButtonBar,
  ExpandableLabel,
  IconButton,
  InputGroup,
  InputGroupLabel,
  InputNumber,
  useToggle
} from "#lib/components/index.ts";
import {FaMinus, FaPlus} from "react-icons/fa";
import React from "react";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";

export type MovementSpeedsProps = {
  value: MapRef<Dnd5eMovementType, number, NumberOperation>;
};
export function MovementSpeeds({value}: MovementSpeedsProps) {
  const setMovementSpeed = (type: Dnd5eMovementType, nextValue: number) => {
    return value.apply(prev => {
      if (prev[type] !== undefined) {
        return MapFn.apply(type, [{type: "set", prevValue: prev[type]!, nextValue}]);
      } else {
        return [{type: "put", key: type, item: nextValue}];
      }
    });
  };
  const removeMovementSpeed = (type: Dnd5eMovementType) => {
    return value.apply(prev => {
      if (prev[type] === undefined) return [];
      return [{type: "delete", key: type, item: prev[type]!}];
    });
  };

  const movementSpeeds = useRefValue(MapSignals.expand(value));
  const movementSpeedLabel = useComputedValue(value, value => Object.entries(value).map(([k,v]) => `${k} ${v}`).join(", "));

  const [expanded, toggleExpanded] = useToggle(false);

  return (<div className="shrink-0 mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <ButtonBar>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <span className="px-4 font-bold flex items-center">Speed</span>
        <span>{movementSpeedLabel}</span>
      </ExpandableLabel>
    </ButtonBar>
    {expanded && <Fieldset>
      {DND_5E_MOVEMENT_TYPES.map(type => <ButtonBar key={type}>
        <InputGroup key={type} className="flex-1">
          <InputGroupLabel>{type}</InputGroupLabel>
          {movementSpeeds[type] && <InputNumber value={movementSpeeds[type]!} />}
        </InputGroup>
        {movementSpeeds[type] === undefined && <IconButton onClick={() => setMovementSpeed(type, 30)}><FaPlus /></IconButton>}
        {movementSpeeds[type] !== undefined && <IconButton onClick={() => removeMovementSpeed(type)}><FaMinus /></IconButton>}
      </ButtonBar>)}
    </Fieldset>}
  </div>);
}