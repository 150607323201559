import {GameID, SceneID} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useEffect, useMemo, useState} from "react";
import {pipe} from "common/pipe";
import {filter, map, subscribe} from "common/observable";
import {ScenePing} from "common/qlab/stream/q-lab-stream.ts";
import {HSLA, Point, Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {PingShader} from "./node/node-view/ping-shader.tsx";

export type Ping = {
  position: Point;
  time: number;
  color: HSLA;
};

const PING_INTERVAL = 1500;

export type ScenePingViewProps = {
  projection: Matrix4f;
  view: Transform;

  gameID: GameID;
  sceneID: SceneID;
};
export function ScenePingView({projection, view, gameID, sceneID}: ScenePingViewProps) {
  const [pings, setPings] = useState<Ping[]>([]);

  const instance = useInstance();
  useEffect(() => pipe(
    instance.streamObservable(gameID),
    filter(effect => effect.type === "ping" && effect.data.type === "scene" && effect.data.sceneID === sceneID),
    map(effect => effect.data as ScenePing),
    subscribe(effect => {
      setPings(pings => [...pings, {
        position: effect.position,
        color: effect.color,
        time: new Date().getTime()
      }]);

      setTimeout(() => {
        setPings(pings => pings.filter(p => p.time + PING_INTERVAL > new Date().getTime()))
      }, PING_INTERVAL*1.1);
    })
  ), [gameID, sceneID, setPings]);


  const [time, setTime] = useState(0);
  useEffect(() => {
    if (pings.length > 0) {
      const interval = setInterval(() => {
        setTime(new Date().getTime())
      }, 1000/60);
      return () => clearInterval(interval);
    }
  }, [pings.length]);

  const viewMat4 = useMemo(() => Matrix4f.transform(view), [view]);


  return <>
    {pings.map((ping, index) => {
      const t = Math.max(0, Math.min((time - ping.time) / PING_INTERVAL, 1));
      return <PingShader
          key={index}
          projection={projection} view={viewMat4}
          model={Matrix4f.MULTIPLICATIVE_IDENTITY}
          origin={ping.position} size={Math.pow(1-t, 3)}
          scale={1/view.scale}
          color={ping.color}
          />
      }
    )}
  </>
}
