import {Button, InputGroup, InputGroupLabel, InputNumber, Select, useTempRef} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {FaDiceD20, FaHashtag, FaHeart} from "react-icons/fa6";
import {
  Dnd5eCharacterClassHitDice,
  Dnd5eCharacterClassHitDiceOperation,
  Dnd5eCharacterClassHitDiceSignals
} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-hit-dice.ts";
import {useMemo, useState} from "react";
import {distinct, map, toPromise} from "common/observable";
import {Menu} from "@headlessui/react";
import {Popper} from "#lib/components/popper/popper.tsx";
import {numberType} from "common/types/index.ts";
import {usePopper} from "react-popper";
import {createPortal} from "react-dom";
import {pipe} from "common/pipe";
import {useRollHitDice} from "./dnd-5e-action/use-roll-hit-dice.ts";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useSheetCtx} from "../../../../common/sheet/sheet-context.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef} from "common/ref";

export type HitDiceInputProps = {
  value: MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>;
};

export function HitDiceInput({value}: HitDiceInputProps) {
  const {hitDice, current} = useMemo(() => Dnd5eCharacterClassHitDiceSignals(value), [value]);
  const currentValue = useObservable(hitDice.observe, 8, [hitDice]);

  const rollHitDice = useRollHitDice();
  const nodeID = useSelectedNodeID();
  const sheet = useSheetCtx();
  const con = useMemo(() => pipe(
    sheet.observe,
    map((sheet): number => {
      if (sheet?.type === "dnd-5e-character") {
        return sheet.data.attributes["con"];
      } else if (sheet?.type === "dnd-5e-stat-block") {
        return sheet.data.attributes["con"] || 10;
      } else {
        return 10;
      }
    }),
    distinct()
  ), [sheet]);

  const qty = useTempRef(numberType, 1);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return <div className="inline-flex flex-1">
    <InputGroup>
      <InputGroupIcon><FaDiceD20 /></InputGroupIcon>
      <InputGroupLabel>Hit Dice</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <Select value={currentValue} onChange={ev => {
        const nextValue = Number.parseInt(ev.target.value);
        if (Number.isFinite(nextValue)) {
          value.apply(prevValue => [{type: "update-dice-face", operations: [{type: "set", prevValue: prevValue.diceFace, nextValue}]}]);
        }
      }}>
        <option value={4}>d4</option>
        <option value={6}>d6</option>
        <option value={8}>d8</option>
        <option value={10}>d10</option>
        <option value={12}>d12</option>
      </Select>
    </InputGroup>
    <InputGroup>
      <InputGroupIcon><FaHeart /></InputGroupIcon>
      <InputGroupLabel>Remaining Hit Dice</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1" title="Remaining Hit Dice">
      <InputNumber value={current} />
    </InputGroup>
    <Menu as="div" ref={ref => setReferenceElement(ref)}>
      <Menu.Button as={Button}>
        <FaDiceD20 />
        Roll Hit Dice
      </Menu.Button>
      {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper}>
        <div className="flex flex-row">
          <InputGroup className="w-40" title="Hit Dice">
            <InputGroupLabel><FaHashtag/></InputGroupLabel>
            <InputNumber value={qty}/>
          </InputGroup>
          <Button variant="primary" title="Roll Hit Dice" onClick={async () => rollHitDice(
            value,
            await toPromise(nodeID),
            await toPromise(qty.observe),
            await toPromise(con)
          )}>
            <FaDiceD20/>
            Roll
          </Button>
        </div>
      </Menu.Items>, usePortal())}
    </Menu>
  </div>
}