import {FaClock, FaEnvelope, FaUser} from "react-icons/fa";
import {PlayerStackValue} from "./player-stack-type.ts";
import {Stacks} from "#lib/container/react/stack/stacks.ts";

export const PlayerStacks: Stacks<PlayerStackValue> = {
  "chat": {
    label: "Chat",
    Icon: FaEnvelope,
    defaultContent: {type: "chat", data: {}}
  },
  "sheet": {
    label: "Sheet",
    Icon: FaUser,
    defaultContent: {type: "sheet", data: {
      reference: undefined
    }}
  },
  "turn-tracker": {
    label: "Turn Tracker",
    Icon: FaClock,
    defaultContent: {type: "turn-tracker", data: {}}
  },
  "characters": {
    label: "Characters",
    Icon: FaUser,
    defaultContent: {type: "characters", data: {}}
  }
};
