import {generateMessageID} from "common/qlab/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStoreID} from "./use-store-id.ts";
import {useSeedTime} from "./use-seed-time.ts";

export function useRequestInitiative(): () => void {
  const instance = useInstance();
  const storeID = useStoreID();
  const userID = useUserID()!;
  const time = useSeedTime();
  return () => {
    const messageID = generateMessageID(time());
    return instance.applyToMessage(storeID, messageID, _ => [{
      type: "set",
      prevValue: undefined,
      nextValue: {
        type: "initiative-request-message",
        data: {
          userID
        }
      }
    }]);
  };
}
