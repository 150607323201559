import {MarkerTurn, MarkerTurnOperation, MarkerTurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {FaForward, FaTimes} from "react-icons/fa";
import {IconButton, InputNumber, useToggle} from "#lib/components/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {useMemo} from "react";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {NodeIcon} from "./node-icon.tsx";
import {MutableRef} from "common/ref";

export type EditorMarkerTurnViewProps = {
  value: MutableRef<MarkerTurn, MarkerTurnOperation[]>;
  isCurrentTurn: boolean;
  remove?: () => void;
  makeActiveTurn?: () => void;
};
export function EditorMarkerTurnView({value, isCurrentTurn, makeActiveTurn, remove}: EditorMarkerTurnViewProps) {
  const {label, order} = useMemo(() => MarkerTurnSignals(value), [value]);

  const [editOrder, toggleEditOrder, setEditOrder] = useToggle(false);

  const resolvedOrder = useObservable(order.observe, undefined, [order.observe]);

  return <div className={twMerge(
    "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
    isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50"
  )}>
    <NodeIcon nodeID={undefined} />
    {!editOrder && <div className="shrink overflow-hidden basis-6 h-8 flex items-center justify-center text-white/40 text-xs italic" onClick={toggleEditOrder}>{resolvedOrder || "??"}</div>}
    {editOrder && <div className="shrink basis-0 w-20 px-2"><InputNumber value={order} autoFocus onLeaveEdit={() => setEditOrder(false)} /></div>}
    <div className="shrink-[1000] overflow-hidden basis-0 flex-1 flex items-center font-bold"><InputString value={label} /></div>
    <div className="shrink-[9999] inline-flex"></div>
    {makeActiveTurn && <IconButton disabled={isCurrentTurn} size="small" title="Make Active Turn" onClick={makeActiveTurn}><FaForward /></IconButton>}
    {remove && <IconButton size="small" variant="destructive" onClick={remove} title="Remove from Tracker"><FaTimes /></IconButton>}
  </div>
}
