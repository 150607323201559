import {RollRequestView} from "../roll-request-view.tsx";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {DND_5E_DEFENSE_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-defense.ts";
import {Loader} from "common/loader";
import {RollRequest} from "common/qlab/message/index.ts";
import {DiceResult} from "common/dice/execute-dice-formula.ts";
import {Optional} from "common/types/generic/index.ts";
import {useSelectedNodeID, useSelectedSheet} from "../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useRollSavingThrow} from "../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-saving-throw.ts";
import {Dnd5eAttribute} from "common/legends/asset/index.ts";

export function Dnd5eDifficultyClassView({defense, request, result}: {
  defense: Dnd5eAttribute,
  request: Loader<Optional<RollRequest>>,
  result: Loader<Optional<DiceResult>>
}) {
  const selectedNodeID = useSelectedNodeID();
  const selectedCharacter = useSelectedSheet();
  const isCharacterSelected = useObservable(pipe(selectedCharacter.observe, map(c => c !== undefined), distinct()), false, [selectedCharacter.observe]);
  const rollSave = useRollSavingThrow(selectedNodeID, selectedCharacter);

  return (<>
    <tr>
      <td className="px-4 py-2 font-bold italic text-sm text-right w-[20%]">DC</td>
      <td className="text-sm py-2">
        {defense.toUpperCase()} vs DC <RollRequestView rollType="saving-throw-dc" rollRequest={request.isSuccess ? request.data : undefined}
                                                       rollResult={result.isSuccess && result.data ? result.data : undefined}/>
      </td>
    </tr>
    <tr>
      <td colSpan={2}>
        <DiceButton variant={"primary"} disabled={!isCharacterSelected} className="w-full gap-2"
                    onClick={ev => rollSave(defense, ev.shiftKey, ev.ctrlKey)}>
          <span>Roll {DND_5E_DEFENSE_TITLE[defense]}</span>
        </DiceButton>
      </td>
    </tr>
  </>);
}