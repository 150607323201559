import {InitiativeRequestMessage} from "common/qlab/message/initiative/initiative-request-message.ts";
import {Dnd5eAbilityCheckMessage, QLabMessageID, RollResults} from "common/qlab/index.ts";
import {Button} from "#lib/components/index.ts";
import {FaDiceD20} from "react-icons/fa6";
import {useSelectedNodeID, useSelectedSheet} from "../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useObservable, useStore} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useRollAbilityCheck} from "../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {useReferencedMessages} from "./use-referenced-messages.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {UserNodeIcon} from "./user-node-icon.tsx";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useDecryptValue, useOptionalDecryptValue} from "./use-decrypt-value.ts";
import {RollRequestView} from "./roll-request-view.tsx";

function InitiativeRequestMessageResponse({message}: {messageID: QLabMessageID, message: Dnd5eAbilityCheckMessage}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);

  return <div className="inline-flex items-center relative py-2">
    <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />
    <RollRequestView rollType="ability-check" rollRequest={rollRequests.isSuccess ? rollRequests.data[message.abilityRollID] : undefined} rollResult={rollResults.isSuccess && rollResults.data ? rollResults.data[message.abilityRollID] : undefined} />
  </div>;
}

export function InitiativeRequestMessageView({messageId}: {
  messageId: QLabMessageID;
  message: InitiativeRequestMessage;
}) {
  const selectedCharacter = useSelectedSheet();
  const isCharacterSelected = useObservable(pipe(selectedCharacter.observe, map(c => c !== undefined), distinct()), false, [selectedCharacter.observe]);
  const rollAbility = useRollAbilityCheck(useSelectedNodeID(), selectedCharacter, "initiative");

  const messages = useRefValue(useReferencedMessages(messageId));

  return <>
    <div className="relative mb-2 mx-2">
      <div className="bg-zinc-800/50 rounded-lg shadow-lg backdrop-blur-sm overflow-hidden">
        <div className="pointer-events-auto">
          <div className="font-bold italic text-center py-2 bg-zinc-800/50 rounded-t-lg">{"INITIATIVE".toUpperCase()}</div>
        </div>

        <Button variant="primary" disabled={!isCharacterSelected} className="w-full my-2" onClick={ev => {
          rollAbility(ev.shiftKey, ev.ctrlKey);
        }}>
          <FaDiceD20/>
          <span>Roll Initiative</span>
        </Button>

        <div className="flex gap-2 justify-center flex-wrap pt-7 pb-2 gap-y-1 min-h-16 box-content">
          {Object.entries(messages).filter(([_, message]) => message.type === "dnd-5e-ability-check-message").map(([messageID, message]) => message.type === "dnd-5e-ability-check-message"
            ? <InitiativeRequestMessageResponse key={messageID} messageID={messageID as QLabMessageID} message={message.data}/>
            : <></>)}
          {Object.entries(messages).length === 0 && <span className="text-white/80 text-sm italic">
            Awaiting initiative rolls.
          </span>}
        </div>
      </div>
    </div>
  </>
}