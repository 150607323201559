import {ConstantOperation, constantType, ListOperation, ListType, ObjectType, PropertyRef, StringOperation, stringType, Tag, tagType, Type} from "../../../types/index.ts";
import {SceneID} from "../../scene/index.ts";
import {MutableRef} from "#common/ref";

export type SceneTreeFile = {
  id: SceneID;
  name: string;
  tags: Tag[];
};
export type SceneTreeFileOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  ;
export const sceneTreeFileType: Type<SceneTreeFile, SceneTreeFileOperation> = new ObjectType({
  id: constantType,
  name: stringType,
  tags: new ListType(tagType)
});

export const SceneTreeFile = {
  getName: (file: SceneTreeFile) => file.name,
  getTags: (file: SceneTreeFile) => file.tags
};

export const SceneTreeFileOperation = {
  updateName: (operations: StringOperation[]): SceneTreeFileOperation[] => [{type: "update-name", operations}],
  updateTags: (operations: ListOperation<Tag, undefined>[]): SceneTreeFileOperation[] => [{type: "update-tags", operations}]
};

export function SceneTreeFileFn(value: MutableRef<SceneTreeFile, SceneTreeFileOperation[]>) {
  return {
    name: PropertyRef(SceneTreeFile.getName, SceneTreeFileOperation.updateName)(value),
    tags: PropertyRef(SceneTreeFile.getTags, SceneTreeFileOperation.updateTags)(value)
  };
}
