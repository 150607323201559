import {z} from "zod";

export const VisibilityID = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(4),
  z.literal(8),
  z.literal(16),
  z.literal(32),
  z.literal(64),
  z.literal(128),
  z.literal(256),
  z.literal(512),
  z.literal(1024),
  z.literal(2048),
  z.literal(4096),
  z.literal(8192),
  z.literal(16384),
  z.literal(32768),
  z.literal(65536),
  z.literal(131072),
  z.literal(262144),
  z.literal(524288)
]);
export type VisibilityID = z.infer<typeof VisibilityID>;

export const VISIBILITY_IDS = [
     1,    2,    4,     8,    16,    32,     64,    128,    256, 512,
  1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144, 524288
] as const satisfies readonly VisibilityID[];
