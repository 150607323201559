import fontDescriptor from "./fonts/honeyblot_caps-mtsdf.json"
import fontImage from "./fonts/honeyblot_caps.png";
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useBindVertexElementArrayBuffer,
  useElementArrayBuffer,
  useProgram,
  useRenderingContext,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {RGB, Transform} from "common/types/index.ts";
import {useSourceImageTexture} from "../../../legends/viewport/common/node/node-view/use-source-image-texture.ts";
import {HorizontalTextAlign, VerticalTextAlign} from "common/legends/node/index.ts";

const fontVertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;
out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;
const fontFragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
uniform float u_opacity;
uniform sampler2D u_texture;
uniform float u_thickness;
uniform vec4 u_color;
uniform vec4 u_border_color;
uniform float u_distance_factor;

in vec2 fragCoord;
out vec4 outColor;

float median(float r, float g, float b) {
  return max(min(r, g), min(max(r, g), b));
}

void main() {
  vec4 c = texture(u_texture, v_tex_coord);
  float sigDist = (median(c.r, c.g, c.b) - 0.5)*u_distance_factor;
  float thickness = u_thickness / 2. * u_distance_factor;
  float w = fwidth(sigDist);
  vec4 color = mix(u_border_color, u_color, smoothstep(thickness-w/2.,thickness+w/2.,sigDist));
  outColor = vec4(color.rgb, color.a * u_opacity * smoothstep(-thickness-w/2.,-thickness+w/2.,sigDist));
}
`;



export type TextShaderProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  hTextAlign?: HorizontalTextAlign;
  vTextAlign?: VerticalTextAlign;

  color?: RGB;
  borderColor?: RGB;
  borderThickness?: number;
  size?: number;
  opacity?: number;
  value: string;
};

export function TextShader({projection, view, model, value, hTextAlign, vTextAlign, opacity, size, color, borderColor, borderThickness}: TextShaderProps) {
  const fontSize: number = size || 1;
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, fontVertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fontFragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const opacityLocation = useUniformLocation(program, "u_opacity");
  const thicknessLocation = useUniformLocation(program, "u_thickness");
  const colorLocation = useUniformLocation(program, "u_color");
  const borderColorLocation = useUniformLocation(program, "u_border_color");
  const distanceFactorLocation = useUniformLocation(program, "u_distance_factor");

  const [fontLoaded, fontTexture] = useSourceImageTexture(fontImage);
  const context = useRenderingContext();
  useMemo(() => {
    if (fontLoaded) {
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, fontTexture);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
    }
  }, [fontTexture]);

  const vao = useVertexBuffer();
  const attributes = useMemo((): Float32Array => {
    const attributes = [];
    let x = 0;
    let y = 0;
    let lastChar = undefined;
    for (let i = 0; i < value.length; i ++) {
      const c = value.charCodeAt(i);
      const char = fontDescriptor.glyphs.find(char => char.unicode === c);
      if (char === undefined) continue;
      const kerning = (lastChar !== undefined
        ? fontDescriptor.kerning.find(kerning => kerning.unicode1 === lastChar!.unicode && kerning.unicode2 === char.unicode)?.advance
        : undefined
      ) || 0;

      x += kerning*fontSize;
      if (char.atlasBounds && char.planeBounds) {
        const {atlasBounds, planeBounds} = char;
        const textureLeft = atlasBounds.left/fontDescriptor.atlas.width;
        const textureRight = atlasBounds.right/fontDescriptor.atlas.width;
        const textureTop = 1-atlasBounds.top/fontDescriptor.atlas.height;
        const textureBottom = 1-atlasBounds.bottom/fontDescriptor.atlas.height;
        const planeLeft = planeBounds.left*fontSize;
        const planeRight = planeBounds.right*fontSize;
        const planeTop = planeBounds.top*fontSize;
        const planeBottom = planeBounds.bottom*fontSize;
        attributes.push(...[
           planeLeft+x, planeBottom+y,  textureLeft, textureBottom,
           planeLeft+x,    planeTop+y,  textureLeft,    textureTop,
          planeRight+x,    planeTop+y, textureRight,    textureTop,
          planeRight+x, planeBottom+y, textureRight, textureBottom
        ]);
      }
      x += char.advance*fontSize;
      lastChar = char;
    }

    // center text
    for (let i = 0; i < attributes.length; i+= 4) {
      if (hTextAlign === undefined || hTextAlign === "center") {
        attributes[i] -= x / 2;
      } else if (hTextAlign === "right") {
        attributes[i] -= x;
      }

      if (vTextAlign === undefined || vTextAlign === "middle") {
        attributes[i+1] -= fontDescriptor.metrics.ascender*fontSize/2;
      } else if (vTextAlign === "top") {
        attributes[i+1] -= fontDescriptor.metrics.lineHeight * fontSize/2 + fontDescriptor.metrics.ascender * fontSize/2;
      } else if (vTextAlign === "bottom") {
        attributes[i+1] -= fontDescriptor.metrics.descender * fontSize/2;
      }
    }
    return new Float32Array(attributes);
  }, [value, hTextAlign, vTextAlign, fontSize]);
  const vbo = useArrayBuffer(attributes);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const elements = useMemo((): Uint16Array => {
    const elements = [];
    let j = 0;
    for (let i = 0; i < attributes.length; i += 4*4) {
      elements.push(...[
        j*4+0, j*4+1, j*4+2,
        j*4+2, j*4+3, j*4+0
      ]);
      j += 1;
    }
    return new Uint16Array(elements)
  }, [attributes]);
  const ebo = useElementArrayBuffer(elements);
  useBindVertexElementArrayBuffer(vao, ebo);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const color4f = useMemo(() => new Float32Array(color ? [...color, 1] : [1, 1, 1, 1]), [color]);
  const borderColor4f = useMemo(() => new Float32Array(borderColor ? [...borderColor, 1]: [0, 0, 0, 1]), [borderColor]);
  const thickness = (borderThickness === undefined ? 1 / 4 : borderThickness);

  if (!fontLoaded || value.length === 0) return <></>;
  return (<program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
    <uniform1f location={opacityLocation} data={opacity || 1} />
    <uniform1f location={thicknessLocation} data={thickness}/>
    <uniform4fv location={colorLocation} data={color4f}/>
    <uniform4fv location={borderColorLocation} data={borderColor4f}/>
    <uniform1f location={distanceFactorLocation} data={fontDescriptor.atlas.distanceRange} />
    <texture2d value={fontTexture}>
      <vertexArray value={vao}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={elements.length} />
      </vertexArray>
    </texture2d>
  </program>);
}