import {distinct, Observer, Subscription} from "#common/observable";
import {z, ZodType} from "zod";
import {MutableRef, Ref} from "#common/ref";

type InsertListOperation<Item> = {
  type: 'insert';
  index: number;
  item: Item;
};

type DeleteListOperation<Item> = {
  type: 'delete';
  index: number;
  item: Item;
};

type MoveListOperation = {
  type: 'move';
  fromIndex: number;
  toIndex: number;
};

type ApplyListOperation<ItemOperation> = {
  type: 'apply';
  index: number;
  operations: ItemOperation[];
};

type SetListOperation<Item> = {
  type: "set",
  index: number;
  prev: Item;
  next: Item;
};

export type ListOperation<Item, ItemOperation> =
  | InsertListOperation<Item>
  | DeleteListOperation<Item>
  | MoveListOperation
  | SetListOperation<Item>
  | ApplyListOperation<ItemOperation>
  ;
export function ZodListOperation<Item, ItemOperation, ZodItem extends ZodType<Item>, ZodItemOperation extends ZodType<ItemOperation>>(Item: ZodItem, ItemOperation: ZodItemOperation) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("delete"), index: z.number(), item: Item}),
    z.object({type: z.literal("insert"), index: z.number(), item: Item}),
    z.object({type: z.literal("move"), fromIndex: z.number(), toIndex: z.number()}),
    z.object({type: z.literal("set"), index: z.number(), prev: Item, next: Item}),
    z.object({type: z.literal("apply"), index: z.number(), operations: z.array(ItemOperation)}),
  ]);
}

export const ListOperation = {
  insert: <Item>(index: number, item: Item): ListOperation<Item, any>[] => [{type: "insert", index, item}],
  apply<Item, ItemOperation>(index: number, operations: ItemOperation[]): ListOperation<Item, ItemOperation>[] {
    if (operations.length === 0) return [];
    return [{type: "apply", index, operations}];
  },
  set: <Item>(index: number, prev: Item, next: Item): ListOperation<Item, any>[] => [{type: "set", index, prev, next}],
  move: (fromIndex: number, toIndex: number): ListOperation<any, any>[] => [{type: "move", fromIndex, toIndex}],
  delete: <Item>(index: number, item: Item): ListOperation<Item, any>[] => [{type: "delete", index, item}]
};

export type ListEntity<Item, Operation> = MutableRef<Item[], ListOperation<Item, Operation>[]>;

type ListKey = string | number;
type KeyedListItemSignal<V, O> = [ListKey, {
  itemRef: MutableRef<V, O[]>;
  remove: () => void;
  duplicate: () => void;
}];
export const KeyedListSignal = <V, O>(
  itemsRef: MutableRef<V[], ListOperation<V, O>[]>,
  itemKey: (value: V, index: number) => ListKey = (_, index) => index,
  copy: (value: V) => V
): Ref<KeyedListItemSignal<V, O>[]> => {
  const removeByKey = (key: string | number) => itemsRef.apply(prev => {
    const index = prev.findIndex((item, index) => itemKey === undefined
      ? index === key
      : itemKey(item, index) === key
    );
    if (index === -1) return [];
    return ListOperation.delete(index, prev[index]);
  });
  const duplicateByKey = (key: string | number) => itemsRef.apply(prev => {
    const item = prev.find((item, index) => itemKey === undefined ? index === key : itemKey(item, index) === key);
    if (item === undefined) return [];
    return ListOperation.insert(prev.length, copy(item));
  });
  const ListKeySignal = (key: ListKey): {
    itemRef: MutableRef<V, O[]>,
    remove: () => void,
    duplicate: () => void
  } => {
    return {
      itemRef: new MutableRef({
        value(): V {
          return itemsRef.value.find((item, index) => itemKey(item, index) === key)!;
        },
        apply: (fn) => itemsRef.apply(prev => {
          const index = itemsRef.value.findIndex((item, index) => itemKey(item, index) === key);
          if (index === -1) return [];
          const value = prev[index];
          return ListOperation.apply(index, fn(value));
        }).then(value => value.find((item, index) => itemKey(item, index) === key)!),
        observe: distinct<V>()((observer) => itemsRef.observe({
          next: (prev) => {
            const itemIndex = prev.findIndex((item, index) => itemKey(item, index) === key);
            if (itemIndex === -1) return;
            observer.next(prev[itemIndex]);
          },
          error: observer.error,
          complete: observer.complete
        }))
      }),
      remove: () => removeByKey(key),
      duplicate: () => duplicateByKey(key)
    };
  };

  let listSignals: {[key in ListKey]: KeyedListItemSignal<V, O>} = {};
  let lastValue: KeyedListItemSignal<V, O>[] = [];

  let closeSubscription: Subscription;
  let observers: Observer<KeyedListItemSignal<V, O>[]>[] = [];

  return new MutableRef({
    value(): KeyedListItemSignal<V, O>[] {
      const keys = itemsRef.value.map((item, index) => itemKey(item, index));
      for (const listKey of Object.keys(listSignals)) {
        if (!keys.includes(listKey)) {
          delete listSignals[listKey];
        }
      }
      for (const listKey of keys) {
        if (listSignals[listKey] === undefined) {
          listSignals[listKey] = [listKey, ListKeySignal(listKey)];
        }
      }

      const nextValue = keys.map(key => listSignals[key]);
      if (lastValue.length !== nextValue.length || nextValue.some((item, index) => lastValue[index] !== item)) {
        lastValue = nextValue;
      }
      return lastValue;
    },
    apply: (_) => {
      throw new Error("Immutable");
    },
    observe: (observer) => {
      if (observers.length === 0) {
        observers.push(observer);
        const keys = itemsRef.value.map((item, index) => itemKey(item, index));
        for (const listKey of Object.keys(listSignals)) {
          if (!keys.includes(listKey)) {
            delete listSignals[listKey];
          }
        }
        for (const listKey of keys) {
          if (listSignals[listKey] === undefined) {
            listSignals[listKey] = [listKey, ListKeySignal(listKey)];
          }
        }
        lastValue = keys.map(key => listSignals[key]);

        observer.next(lastValue);

        closeSubscription = itemsRef.observe({
          next: (value) => {
            const keys = value.map((item, index) => itemKey(item, index));
            for (const listKey of Object.keys(listSignals)) {
              if (!keys.includes(listKey)) {
                delete listSignals[listKey];
              }
            }
            for (const valueKey of keys) {
              if (listSignals[valueKey] === undefined) {
                listSignals[valueKey] = [valueKey, ListKeySignal(valueKey)];
              }
            }

            const nextValue = keys.map(key => listSignals[key]);
            if (lastValue.length !== nextValue.length || nextValue.some((item, index) => lastValue[index] !== item)) {
              observers.forEach(observer => {
                try {observer.next(nextValue)} catch (e) {console.error(e)}
              });
              lastValue = nextValue;
            }
          },
          error: observer.error,
          complete: observer.complete
        });
      } else {
        observers.push(observer);
        observer.next(lastValue);
      }

      return () => {
        const index = observers.indexOf(observer);
        if (index !== -1) {
          observers.splice(index, 1);
          if (observers.length === 0) {
            closeSubscription();
          }
        }
      };
    }
  });
}
