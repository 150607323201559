import {StringOperation, stringType} from "#common/types/generic/string/index.ts";
import {NumberOperation, numberType} from "#common/types/generic/number/index.ts";
import {ObjectType, PropertyRef} from "#common/types/generic/object/index.ts";
import {Type} from "#common/types/type/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const MarkerTurn = z.object({
  label: z.string(),
  order: z.number()
});
export type MarkerTurn = z.infer<typeof MarkerTurn>;

export const MarkerTurnOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-label"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-order"), operations: z.array(NumberOperation)})
])
export type MarkerTurnOperation = z.infer<typeof MarkerTurnOperation>;

export const markerTurnType: Type<MarkerTurn, MarkerTurnOperation> = new ObjectType({
  label: stringType,
  order: numberType
});

export function MarkerTurnSignals(value: MutableRef<MarkerTurn, MarkerTurnOperation[]>) {
  return {
    label: PropertyRef<MarkerTurn, MarkerTurnOperation, string, StringOperation>(
      value => value.label,
      operations => [{type: "update-label", operations}]
    )(value),
    order: PropertyRef<MarkerTurn, MarkerTurnOperation, number, NumberOperation>(
      value => value.order,
      operations => [{type: "update-order", operations}]
    )(value)
  };
}