import {TreePath} from "./tree-path.ts";
import {Tag} from "../tag/index.ts";
import {walkTree} from "./walk-tree.ts";
import {VisitResult} from "./tree-visitor.ts";

export type TreeId = string;

export type TreeParentNode<Item> = {
  data: {
    id: TreeId;
    name: string;
    tags: Tag[];
    children: Item[];
  }
};
export type TreeLeafNode<Item> = {
  data: {
    id: TreeId;
    name: string;
    tags: Tag[];
  }
};

export type Tree<Item> =
  | TreeParentNode<Item>
  | TreeLeafNode<Item>
  ;

export const Tree = {
  isParentNode: function isParentNode<Item>(value: Tree<Item>): value is TreeParentNode<Item> {
    return value.data.hasOwnProperty("children");
  },
  getPath: function getPath<Item extends Tree<Item>>(values: Item[], predicate: (value: Item) => boolean): TreePath | undefined {
    for (let i = 0; i < values.length; i ++) {
      const value = values[i];
      if (predicate(value)) return [i];

      if (Tree.isParentNode(value)) {
        const result = Tree.getPath(value.data.children, predicate);
        if (result !== undefined) return [i, ...result];
      }
    }
    return undefined;
  },
  getNode: function getNode<Item extends Tree<Item>>(rootNodes: Item[], path: TreePath): Item {
    let nodes: Item[] = rootNodes;
    for (let i = 0; i < path.length - 1; i ++) {
      const node = nodes[path[i]];
      if (Tree.isParentNode(node)) {
        nodes = node.data.children;
      } else throw new Error("Cannot access path: " + JSON.stringify(path));
    }
    return nodes[path[path.length - 1]];
  },
  getItemById<Item extends Tree<Item>>(items: Item[], id: TreeId): Item | undefined {
    let item: Item | undefined = undefined;
    walkTree<Item>(items, {
      visit(value: Item) {
        if (value.data.id === id) {
          item = value;
          return VisitResult.TERMINATE;
        }
      }
    });
    return item;
  },
  getItemByPath<Item extends Tree<Item>>(items: Item[], path: TreePath): Item {
    const item = items[path[0]];
    if (path.length === 1) {
      return item;
    } else if (Tree.isParentNode(item)) {
      return Tree.getItemByPath(item.data.children, path.slice(1));
    } else {
      throw new Error("Item not Found");
    }
  }
};
