import {ColorOperation, ConstantOperation, HSLA, ValueOperation} from "common/types/index.ts";
import {Scene, SceneFn, SceneOperation} from "common/legends/index.ts";
import {NameField} from "../name-field.tsx";
import {useMemo} from "react";
import {InputGroup, InputGroupColorButton, InputGroupLabel} from "#lib/components/index.ts";
import {SceneSelectionRef, SelectionRef} from "../../../container/editor/state/selection-ref.ts";
import {GridField} from "./grid-button/grid-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {useScene} from "../../../viewport/token/use-scene.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";

export type LayerOptionsProps = {
  reference: SceneSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function ScenePropertiesView({reference, pinned}: LayerOptionsProps) {
  const value = usePresent(useScene(reference.resourceId));
  if (!value) return <></>;
  return <LoadedEditorScenePropertiesView value={value} reference={reference} pinned={pinned} />
}

type LoadedEditorScenePropertiesViewProps = {
  value: MutableRef<Scene, SceneOperation[]>;
  reference: SceneSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

function LoadedEditorScenePropertiesView({value, reference, pinned}: LoadedEditorScenePropertiesViewProps) {
  const {name, grid, backgroundColor} = useMemo(() => SceneFn.expand(value), [value]);
  return (<div className="tab-content flex flex-col py-2 gap-2">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><GridField value={grid} /></BaseComponent>
    <BaseComponent><BackgroundField valueRef={backgroundColor} /></BaseComponent>
  </div>);
}

type BackgroundFieldProps = {
  valueRef: MutableRef<HSLA, ColorOperation[]>;
};

function BackgroundField({valueRef}: BackgroundFieldProps) {
  return (<InputGroup>
    <InputGroupLabel>Background Color</InputGroupLabel>
    <InputGroupColorButton className="flex-[3] basis-2" valueRef={valueRef} />
  </InputGroup>);
}