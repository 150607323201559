import {z} from "zod";

export const DND_5E_SPELL_LEVEL = [
  "cantrip",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9"
] as const satisfies readonly Dnd5eSpellLevel[];

export const Dnd5eSpellLevel = z.union([
  z.literal("cantrip"),
  z.literal("1"),
  z.literal("2"),
  z.literal("3"),
  z.literal("4"),
  z.literal("5"),
  z.literal("6"),
  z.literal("7"),
  z.literal("8"),
  z.literal("9")
]);
export type Dnd5eSpellLevel = z.infer<typeof Dnd5eSpellLevel>;


export const DND_5E_SPELL_LEVEL_LABEL: {[level in Dnd5eSpellLevel]: string} = {
  "cantrip": "Cantrip",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9"
};
