import {Rectangle, Transform} from "common/types/generic/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {useGrid} from "../../../viewport/common/node/node-view/grid-view/grid-context.ts";
import {useMemo} from "react";
import {BeamMeasurement} from "common/legends/measurement/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {RectangleShader} from "../../../viewport/common/node/node-view/shape/rectangle-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {Grid} from "common/legends/scene/index.ts";

export function BeamMeasurementView({projection, view, measurement: {startPoint, endPoint, width, color}}: {
  projection: Matrix4f, view: Transform,
  measurement: BeamMeasurement
}) {
  const grid = useGrid();
  const direction = useMemo(() => Vector2.subtract(endPoint, startPoint), [startPoint, endPoint])
  const model: Transform = useMemo(() => {
    const normalizedDirection = Vector2.normalize(direction);
    return ({
      scale: 1,
      position: Vector2.add(startPoint, [
        -width * normalizedDirection[1] / 2,
        width * normalizedDirection[0] / 2
      ]),
      rotation: (Math.atan2(direction[1], direction[0]) - Math.PI / 2) / Math.PI * 180
    })
  }, [startPoint, direction]);
  const distanceTextModel: Transform = useMemo(() => {
    return ({
      scale: 1 / view.scale,
      position: endPoint,
      rotation: (Math.atan2(direction[1], direction[0]) - Math.PI / 2) / Math.PI * 180
    })
  }, [endPoint, direction, view.scale]);
  const beam = useMemo((): Rectangle => ({
    width: width,
    height: Vector2.distance(endPoint, startPoint)
  }), [startPoint, endPoint, width]);

  const distance = Grid.distance({...grid, subdivisions: grid.subdivisions + 1}, [0, 0], [Vector2.distance(startPoint, endPoint), 0]);
  return <>
    <RectangleShader projection={projection} view={view} model={model} origin={[0,0]} color={color} rectangle={beam} />
    <TextShader projection={projection} view={view} model={distanceTextModel} value={`${distance} ${grid.unit[1]}`} size={32} vTextAlign={"bottom"} />
  </>;
}
