import {Matrix4f} from "#lib/math/index.ts";
import {Point, Transform} from "common/types/index.ts";
import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useElementArrayBuffer, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import {useMemo} from "react";

const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec2 u_screen;
in vec2 v_tex_coord;
out vec4 outColor;

void main() {
  float d = distance(u_screen, v_tex_coord);
  if (d < 12. && d > 8.) {
    outColor = vec4(1., 1., 1., 1.);
  } else if (d < 16. && d > 12.) {
    outColor = vec4(0., 0., 0., 1.);
  }
}
`;

export type VertexSelectionIndicatorProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;
  origin: Point;
};
export function VertexSelectionIndicator({projection, view, model, origin}: VertexSelectionIndicatorProps) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const screenLocation = useUniformLocation(program, "u_screen");

  const vbo = useArrayBuffer(useMemo(() => {
    const [w, h] = [32, 32];
    const [x, y] = origin;
    return new Float32Array([
      x - w/2, y - h/2, -w/2, -h/2,
      x + w/2, y - h/2,  w/2, -h/2,
      x + w/2, y + h/2,  w/2,  h/2,
      x - w/2, y + h/2, -w/2,  h/2
    ]);
  }, [origin]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);
  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);

  const screen2f = useMemo(() => new Float32Array([0, 0]), []);

  return (<program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
    <uniform2fv location={screenLocation} data={screen2f} />

    <vertexArray value={vao}>
      <elementArrayBuffer value={ebo}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
      </elementArrayBuffer>
    </vertexArray>
  </program>);
}
