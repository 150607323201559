import {twMerge} from "tailwind-merge";
import React, {HTMLAttributes} from "react";
import {NodeId} from "common/legends/index.ts";
import {FaUser} from "react-icons/fa";

export type ImageIconProps = HTMLAttributes<HTMLDivElement> & {
  nodeID?: NodeId;
  src?: string
  size?: number;
};
export function ImageIcon({nodeID, src, size, ...props}: ImageIconProps) {
  return (
    <div {...props} className={twMerge(
      "rounded-full bg-zinc-900 w-10 h-10 inline-flex items-center justify-center pointer-events-auto ring-1 ring-inset ring-zinc-900 overflow-hidden",
      nodeID && "cursor-pointer",
      props.className
    )}>
      {src
        ? <img draggable={false} alt={props.title} src={src} crossOrigin="anonymous" className="w-full h-full"/>
        : <FaUser size={size} />}
    </div>
  );
}
