import {z} from "zod";
import {ConstantOperation, constantType, FileReference, FileReferenceOperation, fileReferenceType, HSLA, ObjectType, PropertyRef, Type} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";
import {ColorOperation, colorType} from "../../../types/index.ts";

export const StatusIndicatorModifier = z.object({
  modifierID: Dnd5eModifierID,
  color: HSLA,
  file: FileReference
});
export type StatusIndicatorModifier = z.infer<typeof StatusIndicatorModifier>;

export const StatusIndicatorModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-file"), operations: z.array(FileReferenceOperation)})
]);
export type StatusIndicatorModifierOperation = z.infer<typeof StatusIndicatorModifierOperation>;

export const statusIndicatorModifierType: Type<StatusIndicatorModifier, StatusIndicatorModifierOperation> = new ObjectType({
  modifierID: constantType,
  color: colorType,
  file: fileReferenceType
}, v => {
  if (v["asset"]) {
    v["file"] = v["asset"];
    delete v["asset"];
  }
  return v;
});

export const StatusIndicatorFn = {
  expand(value: MutableRef<StatusIndicatorModifier, StatusIndicatorModifierOperation[]>) {
    return {
      color: PropertyRef<StatusIndicatorModifier, StatusIndicatorModifierOperation, HSLA, ColorOperation>(
        value => value.color,
        operations => [{type: "update-color", operations}]
      )(value),
      file: PropertyRef<StatusIndicatorModifier, StatusIndicatorModifierOperation, FileReference, FileReferenceOperation>(
        value => value.file,
        operations => [{type: "update-file", operations}]
      )(value)
    };
  }
};
