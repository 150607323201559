import {Dialog} from "@headlessui/react";
import React, {PropsWithChildren} from "react";
import {IconButton} from "../button/index.ts";
import {FaTimes} from "react-icons/fa";
import {useModalClose} from "./modal-context.ts";

export function ModalTitle({children}: PropsWithChildren<object>) {
  const onClose = useModalClose();
  return <Dialog.Title className="flex flex-row bg-zinc-900/50 h-10">
    <div className="flex-1 pl-4 pr-2 text-h400 font-bold flex items-center">
      {children}
    </div>
    {onClose && <IconButton tabIndex={-1} variant="destructive" title="Close" onClick={onClose}>
      <FaTimes className="text-lg" />
    </IconButton>}
  </Dialog.Title>;
}
