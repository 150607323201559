import {z} from "zod";
import {MultiType, Type} from "#common/types/index.ts";
import {InteractionActionID} from "./interaction-action-i-d.ts";
import {InteractionActionSetToken, InteractionActionSetTokenOperation, interactionActionSetTokenType} from "./interaction-action-set-token.ts";
import {InteractionActionTeleport, InteractionActionTeleportOperation, interactionActionTeleportType} from "./interaction-action-teleport.ts";
import {InteractionActionFocus, InteractionActionFocusOperation, interactionActionFocusType} from "./interaction-action-focus.ts";
import {InteractionActionMount, InteractionActionMountOperation, interactionActionMountType} from "./interaction-action-mount.ts";
import {InteractionActionDismount, InteractionActionDismountOperation, interactionActionDismountType} from "./interaction-action-dismount.ts";

export const InteractionAction = z.discriminatedUnion("type", [
  z.object({type: z.literal("set-token"), data: InteractionActionSetToken}),
  z.object({type: z.literal("teleport"), data: InteractionActionTeleport}),
  z.object({type: z.literal("focus"), data: InteractionActionFocus}),
  z.object({type: z.literal("mount"), data: InteractionActionMount}),
  z.object({type: z.literal("dismount"), data: InteractionActionDismount}),
]);
export type InteractionAction = z.infer<typeof InteractionAction>;

export const InteractionActionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("set-token"), operations: z.array(InteractionActionSetTokenOperation)}),
  z.object({type: z.literal("teleport"), operations: z.array(InteractionActionTeleportOperation)}),
  z.object({type: z.literal("focus"), operations: z.array(InteractionActionFocusOperation)}),
  z.object({type: z.literal("mount"), operations: z.array(InteractionActionMountOperation)}),
  z.object({type: z.literal("dismount"), operations: z.array(InteractionActionDismountOperation)}),
]);
export type InteractionActionOperation = z.infer<typeof InteractionActionOperation>;

export type InteractionActionTypes = {
  setToken: Type<InteractionActionSetToken, InteractionActionSetTokenOperation>,
  teleport: Type<InteractionActionTeleport, InteractionActionTeleportOperation>,
  focus: Type<InteractionActionFocus, InteractionActionFocusOperation>,
  mount: Type<InteractionActionMount, InteractionActionMountOperation>,
  dismount: Type<InteractionActionDismount, InteractionActionDismountOperation>,
};
export const interactionActionType = new MultiType<InteractionActionTypes>({
  setToken: interactionActionSetTokenType,
  teleport: interactionActionTeleportType,
  focus: interactionActionFocusType,
  mount: interactionActionMountType,
  dismount: interactionActionDismountType,
});

export function getInteractionActionID(value: InteractionAction): InteractionActionID {
  return value.data.interactionActionID;
}

export function copyInteractionAction(value: InteractionAction): InteractionAction {
  return value;
}
