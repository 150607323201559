import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {Asset, AssetID, AssetOperation} from "common/legends/asset/index.ts";
import {Optional, ValueFn} from "common/types/generic/index.ts";
import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {Loader} from "../loader.ts";

class AssetLoader extends Loader<AssetID, MutableRef<Optional<Asset>, AssetOperation[]>> {
  constructor(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
    super(
      key => key,
      key => databaseRef.map(
        (database: QLabDatabase) => {
          const resource = database.resources[key];
          if (resource?.type === "asset") return resource.data;
          return undefined;
        },
        (database: QLabDatabase, operations: AssetOperation[]): QLabDatabaseOperation[] => {
          if (operations.length === 0) return [];
          const asset = database.resources[key];
          if (asset?.type !== "asset") return [];
          return [{type: "resource", resourceID: key, operations: ValueFn.apply([{type: "asset", operations}])}];
        }
      ).distinct()
    );
  }
}

const loader = new WeakMap<MutableRef<QLabDatabase, QLabDatabaseOperation[]>, Loader<AssetID, MutableRef<Optional<Asset>, AssetOperation[]>>>();
export function AssetRef(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, assetID: Optional<AssetID>): MutableRef<Optional<Asset>, AssetOperation[]> {
  if (!assetID) return NOOP_SIGNAL;
  if (!loader.has(databaseRef)) loader.set(databaseRef, new AssetLoader(databaseRef));
  return loader.get(databaseRef)!.get(assetID);
}
