import {ObjectType, Type} from "common/types/index.ts";

export type Chat = {};
export type ChatOperation =
  undefined
  ;

export const chatType: Type<Chat, ChatOperation> = new ObjectType({
});

