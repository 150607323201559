import {createRoot} from "react-dom/client";

window.global ||= window;
import React from 'react';
import './index.css';
import App from './routes/App.tsx';
import {decrypt, encrypt, generateKey, sign, verify} from "common/crypto/index.ts";
import {DefaultQLabClient, DefaultQLabInstance} from "#lib/qlab/index.ts";

const root = createRoot(document.getElementById('root')!);
root.render(<React.StrictMode><App /></React.StrictMode>);

// @ts-ignore
window.generateKey = generateKey;
// @ts-ignore
window.encrypt = encrypt;
// @ts-ignore
window.decrypt = decrypt;
// @ts-ignore
window.sign = sign;
// @ts-ignore
window.verify = verify;

// @ts-ignore
window.DefaultQLabClient = DefaultQLabClient;

// @ts-ignore
window.DefaultQLabInstance = DefaultQLabInstance;
