import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaMapPin, FaTag} from "react-icons/fa";
import React, {useCallback} from "react";
import {ConstantOperation, Optional, StringOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {pipe} from "common/pipe";
import {from, map} from "common/observable";
import {SheetReference} from "../../../../common/sheet/sheet-reference.ts";
import {MutableRef} from "common/ref";

export type SheetNameFieldProps = {
  value: MutableRef<string, StringOperation[]>;
  reference: MutableRef<Optional<SheetReference>, never>;
  pinned?: MutableRef<Optional<SheetReference>, ValueOperation<Optional<SheetReference>, ConstantOperation>[]>;
};

export function SheetNameField({value, reference, pinned}: SheetNameFieldProps) {
  const togglePinned = useCallback(() => {
    const currentReference = reference.value;
    return pinned?.apply((prevValue) => prevValue !== reference.value
      ? ValueFn.set(prevValue, currentReference)
      : ValueFn.set(prevValue, undefined)
    );
  }, [pinned?.apply, reference]);
  const isPinned = useObservable(pipe(
    pinned?.observe || from(undefined),
    map(r => r !== undefined)
  ), false, [pinned?.observe, reference]);

  return (<ButtonBar>
    <InputGroup className="flex-1" title="Name">
      <InputGroupLabel><FaTag /></InputGroupLabel>
      <InputString placeholder="Name" type="text" value={value} />
    </InputGroup>
    {pinned && <IconButton onClick={togglePinned} variant={isPinned ? "primary" : "tertiary"}><FaMapPin/></IconButton>}
  </ButtonBar>);
}