import {Dnd5eFeature, Dnd5eFeatureOperation, dnd5eFeatureType} from "#common/legends/index.ts";
import {z} from "zod";
import {ListOperation, ListType, ObjectType, StringOperation, stringType, Type, ValueFn, ValueOperation} from "#common/types/index.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eRace = z.object({
  name: z.string(),
  features: z.array(Dnd5eFeature)
});
export type Dnd5eRace = z.infer<typeof Dnd5eRace>;
export type Dnd5eRaceOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-features", operations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]}
  ;
export const dnd5eRaceType: Type<Dnd5eRace, Dnd5eRaceOperation> = new ObjectType({
  name: stringType,
  features: new ListType(dnd5eFeatureType)
});

export function Dnd5eRaceSignal(signal: MutableRef<Dnd5eRace, ValueOperation<Dnd5eRace, Dnd5eRaceOperation>[]>){
  return {
    name: PropertyRef<Dnd5eRace, ValueOperation<Dnd5eRace, Dnd5eRaceOperation>, string, StringOperation>(
      value => value.name,
      (operations) => ValueFn.apply([{type: "update-name", operations}])
    )(signal),
    features: ListPropertyRef<Dnd5eRace, ValueOperation<Dnd5eRace, Dnd5eRaceOperation>, Dnd5eFeature, Dnd5eFeatureOperation>(
      value => value.features,
      (operations) => ValueFn.apply([{type: "update-features", operations}])
    )(signal)
  };
}
