import {Dice, DiceExpression} from "common/dice/index.ts";
import {generateMessageID, generateRollRequestID, QLabMessageID, RollRequestID, RollRequests, RollVariables} from "common/qlab/index.ts";
import {Observable, toPromise} from "common/observable";
import {useCallback} from "react";
import {NodeId, Sheet, SheetOperation} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {encryptValue, Optional} from "common/types/index.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {useStoreID} from "../../../../../../routes/game/use-store-id.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {getDnd5eSheetActiveModifiers} from "../../dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {getDnd5eSheetAbilityCheckModifiers} from "../../dnd-5e/common/dnd-5e-sheet-ability-check-modifiers.ts";
import {getDnd5eAbilityCheckProficiency} from "../../dnd-5e/common/get-dnd5e-ability-check-proficiency.ts";
import {getActiveVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {useSeedTime} from "../../../../../../routes/game/use-seed-time.ts";
import {MutableRef} from "common/ref";
import {useMessageID} from "../../../../chat/message-context.ts";

export function useSendAbilityCheck() {
  const instance = useInstance();
  const storeID = useStoreID();
  const userID = useUserID()!;
  const getNodeIcon = useGetNodeIcon();
  const time = useSeedTime();

  return async (nodeID: NodeId | undefined, abilityCheckType: Dnd5eAbilityCheckType, formula: DiceExpression, referenceMessageID: Optional<QLabMessageID>, variables?: RollVariables) => {
    const icon = await getNodeIcon(nodeID);
    const messageID = generateMessageID(time());
    const rollID = generateRollRequestID();
    const rollRequests = await encryptValue({}, () => Object.entries({
      [rollID]: formula
    }).reduce((rollRequests, [rollId, expression]) => {
      rollRequests[rollId as RollRequestID] = {
        expression: expression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
      return rollRequests;
    }, {} as RollRequests));

    return instance.applyToMessage(storeID, messageID, _ => [{
      type: "set",
      prevValue: undefined,
      nextValue: {
        type: "dnd-5e-ability-check-message",
        data: {
          userID,
          nodeID,
          icon,
          referenceMessageID,
          abilityCheckType: abilityCheckType,
          abilityRollID: rollID,
          rollRequests: rollRequests,
          rollResults: undefined
        }
      }
    }]);
  };
}

export function useRollAbilityCheck(nodeID: Observable<NodeId | undefined>, sheet: MutableRef<Optional<Sheet>, SheetOperation[]>, abilityCheckType: Dnd5eAbilityCheckType) {
  const sendAbilityCheck = useSendAbilityCheck();
  const globalEffects = useGlobalFeatures();
  const referenceMessageID = useMessageID();

  return useCallback(async (forceAdvantage: boolean, forceDisadvantage: boolean) => {
    const resolvedNodeID = await toPromise(nodeID);
    const globalEffectsValue = globalEffects.value;
    const sheetValue = sheet.value;
    if (sheetValue === undefined) return;
    const activeModifiers = getDnd5eSheetActiveModifiers(sheetValue, globalEffectsValue);
    const expression = getDnd5eSheetAbilityCheckModifiers(sheetValue, globalEffectsValue, abilityCheckType);
    const proficiency = getDnd5eAbilityCheckProficiency(sheetValue, globalEffectsValue, abilityCheckType);
    const variables = getActiveVariables(sheetValue, globalEffectsValue);

    const hasReliableTalent = activeModifiers.some(m => m.type === "trait" && m.data.trait === "reliable-talent");
    const hasHalflingLuck = activeModifiers.some(m => m.type === "trait" && m.data.trait === "halfling-luck");
    const hasSilverTongue = activeModifiers.some(m => m.type === "trait" && m.data.trait === "silver-tongue");
    const hasGlibness = activeModifiers.some(m => m.type === "trait" && m.data.trait === "glibness");
    const hasAdvantage = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheckType) && m.data.hasAdvantage);
    const hasDisadvantage = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheckType) && m.data.hasDisadvantage);

    let formula = "1d20";
    if ((hasAdvantage && !forceDisadvantage) || (forceAdvantage && !forceDisadvantage)) formula = `2d20${hasHalflingLuck ?"ro1" :""}kh1`;
    else if ((hasDisadvantage && !forceAdvantage) || (!forceAdvantage && forceDisadvantage)) formula = "2d20kl1";

    // min 10
    if (hasGlibness) formula = `max(15, ${formula})[Glibness]`;
    else if (proficiency !== "untrained" && hasReliableTalent) formula = `max(10, ${formula})[Reliable Talent]`;
    else if (hasSilverTongue && (abilityCheckType === "deception" || abilityCheckType === "persuasion")) {
      formula = `max(10, ${formula})[Silver Tongue]`;
    }

    if (expression && expression.length > 0) formula += `+${expression}`;
    formula = formula.replaceAll("+-", "-");
    await sendAbilityCheck(resolvedNodeID, abilityCheckType, Dice.assertDiceExpression(formula), referenceMessageID, variables);
  }, [nodeID, sheet.observe, sendAbilityCheck, referenceMessageID]);
}
