import {z} from "zod";
import {MultiType, Type} from "common/types/index.ts";
import {WallToolCreateMode, WallToolCreateModeOperation, wallToolCreateModeType} from "./wall-tool-create-mode.ts";
import {WallToolSelectMode, WallToolSelectModeOperation, wallToolSelectModeType} from "./wall-tool-select-mode.ts";
import {WallToolDestroyMode, WallToolDestroyModeOperation, wallToolDestroyModeType} from "./wall-tool-destroy-mode.ts";
import {WallToolSlicerMode, WallToolSlicerModeOperation, wallToolSlicerModeType} from "./wall-tool-slicer-mode.ts";

export const WallToolMode = z.discriminatedUnion("type", [
  z.object({type: z.literal("create"), data: WallToolCreateMode}),
  z.object({type: z.literal("select"), data: WallToolSelectMode}),
  z.object({type: z.literal("destroy"), data: WallToolDestroyMode}),
  z.object({type: z.literal("slicer"), data: WallToolSlicerMode})
]);
export type WallToolMode = z.infer<typeof WallToolMode>;
export const WallToolModeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("create"), operations: z.array(WallToolCreateModeOperation)}),
  z.object({type: z.literal("select"), operations: z.array(WallToolSelectModeOperation)}),
  z.object({type: z.literal("destroy"), operations: z.array(WallToolDestroyModeOperation)}),
  z.object({type: z.literal("slicer"), operations: z.array(WallToolSlicerModeOperation)})
]);
export type WallToolModeOperation = z.infer<typeof WallToolModeOperation>;
export const wallToolModeType: Type<WallToolMode, WallToolModeOperation> = new MultiType({
  create: wallToolCreateModeType,
  select: wallToolSelectModeType,
  destroy: wallToolDestroyModeType,
  slicer: wallToolSlicerModeType
});
