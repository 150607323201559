import {LayoutPath} from "./layout-path.ts";
import {Stack, StackOperation} from "../stack/index.ts";

export type CreateStackLayoutOperation<Value> = {
  type: "create-stack";
  direction: "row" | "column";
  path: LayoutPath;
  value: Stack<Value>
};

export type ApplyToStackOperation<Value, Operation> = {
  type: "apply-to-stack",
  path: LayoutPath,
  operations: StackOperation<Value, Operation>[];
};

export type AdjustSplitLayoutOperation = {
  type: "adjust-split";
  path: LayoutPath;
  index: number;
  splitPoint: number;
};

export type LayoutOperation<Value, Operation> =
  | CreateStackLayoutOperation<Value>
  | ApplyToStackOperation<Value, Operation>
  | AdjustSplitLayoutOperation
  ;

export const LayoutFn = {
  applyToStack<V, O>(path: LayoutPath, operations: StackOperation<V, O>[]): LayoutOperation<V, O>[] {
    return [{type: "apply-to-stack", path, operations}];
  }
};