import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {Ref} from "common/ref";
import {MessageValue, QLabMessageID} from "common/qlab/message/index.ts";
import {useMemo} from "react";
import {mapIdentity} from "common/observable";

export function useReferencedMessages(messageID: QLabMessageID): Ref<{[messageID: QLabMessageID]: MessageValue}> {
  const databaseRef = useDatabase();
  return useMemo(() => {
    return databaseRef.map(database => Object.fromEntries(Object.entries(database.messages).filter(([_, message]) => {
      switch (message?.type) {
        case "dnd-5e-saving-throw-message": return message.data.referenceMessageID === messageID;
        case "dnd-5e-ability-check-message": return message.data.referenceMessageID === messageID;
        default: return false;
      }
    })) as {[messageID: QLabMessageID]: MessageValue}).distinct(mapIdentity);
  }, [databaseRef, messageID])
}