// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "MAIN", "symbols": ["_", "SUM", "_"], "postprocess": r => r[1]},
    {"name": "LABEL$subexpression$1", "symbols": [{"literal":"["}], "postprocess": function(d) {return d.join(""); }},
    {"name": "LABEL$ebnf$1", "symbols": [/[a-zA-Z0-9 ]/]},
    {"name": "LABEL$ebnf$1", "symbols": ["LABEL$ebnf$1", /[a-zA-Z0-9 ]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "LABEL$subexpression$2", "symbols": [{"literal":"]"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "LABEL", "symbols": ["LABEL$subexpression$1", "LABEL$ebnf$1", "LABEL$subexpression$2"], "postprocess": r => r[1].join("")},
    {"name": "N$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "N$ebnf$1", "symbols": ["N$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "N$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "N$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "N$ebnf$2", "symbols": ["N$ebnf$2", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "N", "symbols": ["N$ebnf$1", "_", "N$ebnf$2"], "postprocess": r => Number.parseInt((r[0] || "") + r[2].join(""))},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null }},
    {"name": "VARIABLE$subexpression$1", "symbols": [{"literal":"{"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VARIABLE$ebnf$1", "symbols": [/[A-Za-z0-9_-]/]},
    {"name": "VARIABLE$ebnf$1", "symbols": ["VARIABLE$ebnf$1", /[A-Za-z0-9_-]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "VARIABLE$subexpression$2", "symbols": [{"literal":"}"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VARIABLE$ebnf$2", "symbols": ["LABEL"], "postprocess": id},
    {"name": "VARIABLE$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "VARIABLE", "symbols": ["VARIABLE$subexpression$1", "VARIABLE$ebnf$1", "VARIABLE$subexpression$2", "_", "VARIABLE$ebnf$2"], "postprocess": r => ({op: "variable", variable: r[1].join(""), label: r[4] || r[1].join("") || undefined})},
    {"name": "VALUE$ebnf$1", "symbols": ["LABEL"], "postprocess": id},
    {"name": "VALUE$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "VALUE", "symbols": ["N", "_", "VALUE$ebnf$1"], "postprocess": r => ({op: "constant", value: r[0], label: r[2] || undefined})},
    {"name": "VALUE", "symbols": ["DICE"], "postprocess": r => r[0]},
    {"name": "VALUE", "symbols": ["VARIABLE"], "postprocess": r => r[0]},
    {"name": "VALUE$subexpression$1", "symbols": [{"literal":"("}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VALUE$subexpression$2", "symbols": [{"literal":")"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VALUE", "symbols": ["VALUE$subexpression$1", "_", "SUM", "_", "VALUE$subexpression$2"], "postprocess": r => ({op: "parentheses", expression: r[2], label: undefined})},
    {"name": "VALUE$subexpression$3", "symbols": [{"literal":"("}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VALUE$subexpression$4", "symbols": [{"literal":")"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "VALUE", "symbols": ["VALUE$subexpression$3", "_", "SUM", "_", "VALUE$subexpression$4", "_", "LABEL"], "postprocess": r => ({op: "parentheses", expression: r[2], label: r[6] || undefined})},
    {"name": "DICE$subexpression$1", "symbols": [/[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$1", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE$subexpression$1", "_", "VALUE", "_", "DICE$ebnf$1"], "postprocess": r => ({op: "dice", count: ({op: "constant", value: 1}), faces: r[2], label: r[4] || undefined})},
    {"name": "DICE$subexpression$2", "symbols": [/[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$2", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["VALUE", "_", "DICE$subexpression$2", "_", "VALUE", "_", "DICE$ebnf$2"], "postprocess": r => ({op: "dice", count: r[0], faces: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$3$subexpression$1", "symbols": [/[kK]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$subexpression$3", "symbols": ["DICE$subexpression$3$subexpression$1"]},
    {"name": "DICE$subexpression$3$subexpression$2", "symbols": [/[kK]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$subexpression$3", "symbols": ["DICE$subexpression$3$subexpression$2"]},
    {"name": "DICE$ebnf$3", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$3", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$3", "_", "VALUE", "_", "DICE$ebnf$3"], "postprocess": r => ({op: "keep-highest", dice: r[0], count: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$4", "symbols": [/[kK]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$4", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$4", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$4", "_", "VALUE", "_", "DICE$ebnf$4"], "postprocess": r => ({op: "keep-lowest", dice: r[0], count: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$5", "symbols": [/[dD]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$5", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$5", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$5", "_", "VALUE", "_", "DICE$ebnf$5"], "postprocess": r => ({op: "drop-highest", dice: r[0], count: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$6", "symbols": [/[dD]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$6", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$6", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$6", "_", "VALUE", "_", "DICE$ebnf$6"], "postprocess": r => ({op: "drop-lowest", dice: r[0], count: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$7", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$7", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$7", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$7", "_", "VALUE", "_", "DICE$ebnf$7"], "postprocess": r => ({op: "reroll", dice: r[0], comparator: "equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$8", "symbols": [/[rR]/, {"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$8", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$8", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$8", "_", "VALUE", "_", "DICE$ebnf$8"], "postprocess": r => ({op: "reroll", dice: r[0], comparator: "greater", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$9", "symbols": [/[rR]/, {"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$9", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$9", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$9", "_", "VALUE", "_", "DICE$ebnf$9"], "postprocess": r => ({op: "reroll", dice: r[0], comparator: "less", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$10", "symbols": [/[rR]/, {"literal":">"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$10", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$10", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$10", "_", "VALUE", "_", "DICE$ebnf$10"], "postprocess": r => ({op: "reroll", dice: r[0], comparator: "greater-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$11", "symbols": [/[rR]/, {"literal":"<"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$11", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$11", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$11", "_", "VALUE", "_", "DICE$ebnf$11"], "postprocess": r => ({op: "reroll", dice: r[0], comparator: "less-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$12", "symbols": [/[rR]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$12", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$12", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$12", "_", "VALUE", "_", "DICE$ebnf$12"], "postprocess": r => ({op: "reroll-once", dice: r[0], comparator: "equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$13", "symbols": [/[rR]/, /[oO]/, {"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$13", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$13", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$13", "_", "VALUE", "_", "DICE$ebnf$13"], "postprocess": r => ({op: "reroll-once", dice: r[0], comparator: "greater", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$14", "symbols": [/[rR]/, /[oO]/, {"literal":">"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$14", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$14", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$14", "_", "VALUE", "_", "DICE$ebnf$14"], "postprocess": r => ({op: "reroll-once", dice: r[0], comparator: "greater-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$15", "symbols": [/[rR]/, /[oO]/, {"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$15", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$15", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$15", "_", "VALUE", "_", "DICE$ebnf$15"], "postprocess": r => ({op: "reroll-once", dice: r[0], comparator: "less", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$16", "symbols": [/[rR]/, /[oO]/, {"literal":"<"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$16", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$16", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$16", "_", "VALUE", "_", "DICE$ebnf$16"], "postprocess": r => ({op: "reroll-once", dice: r[0], comparator: "less-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$17", "symbols": [{"literal":"!"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$17", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$17", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$17", "_", "VALUE", "_", "DICE$ebnf$17"], "postprocess": r => ({op: "explode", dice: r[0], comparator: "equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$18", "symbols": [{"literal":"!"}, {"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$18", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$18", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$18", "_", "VALUE", "_", "DICE$ebnf$18"], "postprocess": r => ({op: "explode", dice: r[0], comparator: "greater", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$19", "symbols": [{"literal":"!"}, {"literal":">"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$19", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$19", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$19", "_", "VALUE", "_", "DICE$ebnf$19"], "postprocess": r => ({op: "explode", dice: r[0], comparator: "greater-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$20", "symbols": [{"literal":"!"}, {"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$20", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$20", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$20", "_", "VALUE", "_", "DICE$ebnf$20"], "postprocess": r => ({op: "explode", dice: r[0], comparator: "less", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$21", "symbols": [{"literal":"!"}, {"literal":"<"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$21", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$21", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$21", "_", "VALUE", "_", "DICE$ebnf$21"], "postprocess": r => ({op: "explode", dice: r[0], comparator: "less-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$22", "symbols": [/[cC]/, /[sS]/, {"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$22", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$22", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$22", "_", "VALUE", "_", "DICE$ebnf$22"], "postprocess": r => ({op: "count-success", dice: r[0], comparator: "less", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$23", "symbols": [/[cC]/, /[sS]/, {"literal":"<"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$23", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$23", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$23", "_", "VALUE", "_", "DICE$ebnf$23"], "postprocess": r => ({op: "count-success", dice: r[0], comparator: "less-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$24", "symbols": [/[cC]/, /[sS]/, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$24", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$24", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$24", "_", "VALUE", "_", "DICE$ebnf$24"], "postprocess": r => ({op: "count-success", dice: r[0], comparator: "equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$25", "symbols": [/[cC]/, /[sS]/, {"literal":">"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$25", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$25", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$25", "_", "VALUE", "_", "DICE$ebnf$25"], "postprocess": r => ({op: "count-success", dice: r[0], comparator: "greater-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$26", "symbols": [/[cC]/, /[sS]/, {"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$26", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$26", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$26", "_", "VALUE", "_", "DICE$ebnf$26"], "postprocess": r => ({op: "count-success", dice: r[0], comparator: "greater", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$27", "symbols": [/[cC]/, /[fF]/, {"literal":"<"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$27", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$27", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$27", "_", "VALUE", "_", "DICE$ebnf$27"], "postprocess": r => ({op: "count-failure", dice: r[0], comparator: "less", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$28", "symbols": [/[cC]/, /[fF]/, {"literal":"<"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$28", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$28", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$28", "_", "VALUE", "_", "DICE$ebnf$28"], "postprocess": r => ({op: "count-failure", dice: r[0], comparator: "less-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$29", "symbols": [/[cC]/, /[fF]/, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$29", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$29", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$29", "_", "VALUE", "_", "DICE$ebnf$29"], "postprocess": r => ({op: "count-failure", dice: r[0], comparator: "equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$30", "symbols": [/[cC]/, /[fF]/, {"literal":">"}, {"literal":"="}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$30", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$30", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$30", "_", "VALUE", "_", "DICE$ebnf$30"], "postprocess": r => ({op: "count-failure", dice: r[0], comparator: "greater-or-equal", target: r[4], label: r[6] || undefined})},
    {"name": "DICE$subexpression$31", "symbols": [/[cC]/, /[fF]/, {"literal":">"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "DICE$ebnf$31", "symbols": ["LABEL"], "postprocess": id},
    {"name": "DICE$ebnf$31", "symbols": [], "postprocess": () => null},
    {"name": "DICE", "symbols": ["DICE", "_", "DICE$subexpression$31", "_", "VALUE", "_", "DICE$ebnf$31"], "postprocess": r => ({op: "count-failure", dice: r[0], comparator: "greater", target: r[4], label: r[6] || undefined})},
    {"name": "SUM$subexpression$1", "symbols": [{"literal":"+"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "SUM", "symbols": ["SUM", "_", "SUM$subexpression$1", "_", "PRODUCT"], "postprocess": r => ({op: "add", left: r[0], right: r[4]})},
    {"name": "SUM$subexpression$2", "symbols": [{"literal":"-"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "SUM", "symbols": ["SUM", "_", "SUM$subexpression$2", "_", "PRODUCT"], "postprocess": r => ({op: "subtract", left: r[0], right: r[4]})},
    {"name": "SUM", "symbols": ["PRODUCT"], "postprocess": r => r[0]},
    {"name": "PRODUCT$subexpression$1$subexpression$1", "symbols": [{"literal":"*"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "PRODUCT$subexpression$1", "symbols": ["PRODUCT$subexpression$1$subexpression$1"]},
    {"name": "PRODUCT$subexpression$1$subexpression$2", "symbols": [/[xX]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "PRODUCT$subexpression$1", "symbols": ["PRODUCT$subexpression$1$subexpression$2"]},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", "PRODUCT$subexpression$1", "_", "VALUE"], "postprocess": r => ({op: "multiply", left: r[0], right: r[4]})},
    {"name": "PRODUCT$subexpression$2", "symbols": [{"literal":"/"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", "PRODUCT$subexpression$2", "_", "VALUE"], "postprocess": r => ({op: "divide", left: r[0], right: r[4]})},
    {"name": "PRODUCT$subexpression$3", "symbols": [{"literal":"%"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "PRODUCT", "symbols": ["PRODUCT", "_", "PRODUCT$subexpression$3", "_", "VALUE"], "postprocess": r => ({op: "remainder", left: r[0], right: r[4]})},
    {"name": "PRODUCT", "symbols": ["FUNC"], "postprocess": r => r[0]},
    {"name": "FUNC$subexpression$1", "symbols": [/[mM]/, /[iI]/, /[nN]/, {"literal":"("}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$subexpression$2", "symbols": [{"literal":","}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$subexpression$3", "symbols": [{"literal":")"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$ebnf$1", "symbols": ["LABEL"], "postprocess": id},
    {"name": "FUNC$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "FUNC", "symbols": ["FUNC$subexpression$1", "_", "SUM", "_", "FUNC$subexpression$2", "_", "SUM", "_", "FUNC$subexpression$3", "_", "FUNC$ebnf$1"], "postprocess": r => ({op: "min", left: r[2], right: r[6], label: r[10] || undefined})},
    {"name": "FUNC$subexpression$4", "symbols": [/[mM]/, /[aA]/, /[xX]/, {"literal":"("}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$subexpression$5", "symbols": [{"literal":","}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$subexpression$6", "symbols": [{"literal":")"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "FUNC$ebnf$2", "symbols": ["LABEL"], "postprocess": id},
    {"name": "FUNC$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "FUNC", "symbols": ["FUNC$subexpression$4", "_", "SUM", "_", "FUNC$subexpression$5", "_", "SUM", "_", "FUNC$subexpression$6", "_", "FUNC$ebnf$2"], "postprocess": r => ({op: "max", left: r[2], right: r[6], label: r[10] || undefined})},
    {"name": "FUNC", "symbols": ["VALUE"], "postprocess": r => r[0]}
  ],
  ParserStart: "MAIN",
};

export default grammar;
