import {ButtonHTMLAttributes, forwardRef, Ref} from 'react';
import {twMerge} from "tailwind-merge";

export type ButtonVariant =
  | "primary"
  | "tertiary"
  | "destructive"
  ;
export type ButtonSize =
  | "large"
  | "medium"
  | "small"
  ;

export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "size" | "variant">;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button({variant = "tertiary", size = "small", className, ...props}: ButtonProps, ref: Ref<HTMLButtonElement>): JSX.Element {
  return (
    <button ref={ref} className={twMerge(
      "inline-flex justify-center items-center cursor-pointer outline-none border-none disabled:opacity-50 pointer-events-auto gap-2",
      variant === "primary" && "text-white bg-blue-800 hover:bg-blue-700 focus:bg-blue-700",
      variant === "tertiary" && "bg-zinc-900/80 hover:bg-zinc-800/80 focus:bg-zinc-700",
      variant === "destructive" && "bg-red-900 text-red-100 hover:bg-red-700 focus:bg-red-700",
      size === "large" && "h-14 py-4 px-8 text-h400",
      size === "medium" && "h-12 py-3 px-6 text-h300",
      size === "small" && "h-10 py-2 px-4 text-h200",
      className
    )} {...props}/>
  );
});
