import {MutableRef} from "common/ref";
import {MultiTypeOperation, MultiTypeRef, MultiTypeValue} from "common/types/generic/index.ts";
import {ExtractOperation, ExtractValue, Type} from "common/types/type/index.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {useMemo} from "react";
import {useSuspendPresent} from "./use-optional-signal.ts";
import {CamelCaseToKebabCase} from "common/utils";

// TS-Ignore because `keyof K` returns string|number, but we already keyof K to just string

// @ts-ignore
export function useTypedRef<K extends Record<string, Type<any, any>>>(ref: MutableRef<MultiTypeValue<K>,  MultiTypeOperation<K>[]>): {[T in keyof K]: [CamelCaseToKebabCase<T>, MutableRef<ExtractValue<K[T]>, ExtractOperation<K[T]>[]>]}[keyof K] {
  // @ts-ignore
  const type: CamelCaseToKebabCase<keyof K> = useComputedValue(ref, value => value.type);
  return [type, useSuspendPresent(useMemo(() => MultiTypeRef(type, ref), [ref, type]))];
}

