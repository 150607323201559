import {listIdentity} from "common/observable";
import {Dnd5eCondition, Sheet, SheetOperation} from "common/legends/index.ts";
import {Dnd5eConditionImmunityModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-condition-immunity-modifier.ts";
import {useMemo} from "react";
import {MutableRef, Ref} from "common/ref";
import {getDnd5eSheetActiveModifiers} from "../../dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {useGlobalFeatures} from "./use-global-features.ts";

export function useSheetConditionImmunities(value: MutableRef<Sheet | undefined, SheetOperation[]>): Ref<Dnd5eCondition[]> {
  const globalFeaturesRef = useGlobalFeatures();
  return useMemo(() => MutableRef.all(value, globalFeaturesRef)
    .map(([sheet, globalFeatures]) => {
      const modifiers = getDnd5eSheetActiveModifiers(sheet, globalFeatures)
      return modifiers
        .filter(m => m.type === "condition-immunity")
        .map(m => m?.data as Dnd5eConditionImmunityModifier)
        .flatMap(m => m.conditions);
    })
    .distinct(listIdentity), [value, globalFeaturesRef]);
}
