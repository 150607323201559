import {IconButton, Spacer} from "#lib/components/index.ts";
import {Menu} from "@headlessui/react";
import React, {useMemo} from "react";
import {UUID} from "common/utils";
import {GameSignals} from "common/legends/index.ts";
import {useEditor} from "../editor-context.ts";
import {useGame} from "../../../../routes/game/index.ts";
import {EditorStackValue} from "../editor-stack.ts";
import {useSelectedSheetReference} from "../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useEditorVisibilityMask} from "../use-editor-visibility-mask.ts";
import {QuickAccessMenu} from "../../../nav/common/quick-access-menu/dnd-5e/quick-access-menu.tsx";
import {EditorGamePlayersView} from "./editor-game-players-view.tsx";
import {InputMask} from "#lib/components/input-mask/input-mask.tsx";
import {ToolModeInput} from "../../../nav/common/tool-mode/tool-mode-input.tsx";
import {EditorStacks} from "../editor-stacks.ts";
import {XCardButton} from "../../../nav/common/x-card/x-card-button.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {DialogIDFn} from "#lib/container/index.ts";
import {FaEllipsisVertical} from "react-icons/fa6";
import {useEditorTool} from "../tool-mode/use-editor-tool.ts";
import {LegendsConnectionIndicator} from "../../legends-connection-indicator.tsx";
import {usePresent} from "../../../common/use-optional-signal.ts";

export const EditorGameNavigation = React.memo(function GMNavigation() {
  const editor = useEditor();
  const game = useGame();
  const {name} = useMemo(() => GameSignals(game), [game]);
  const gameName = useRefValue(name);

  const onAddStackItem = (stackItem: EditorStackValue) => {
    const dialogId = DialogIDFn.generate();
    const stackId = UUID.generate();
    editor.container.apply(prev => [{
      type: "create-dialog",
      content: {
        id: dialogId,
        value: {
          type: "layout",
          layout: {
            type: "stack",
            activeId: stackId,
            items: [{
              id: stackId,
              content: stackItem
            }]
          },
        },
        minimized: false,
        external: false,
        position: [48 * prev.dialogs.length + 48, 24 * prev.dialogs.length + 24],
        size: [720, 480]
      }
    }]);
  }
  const sheetReference = usePresent(useSelectedSheetReference());

  const visibilityMaskRef = useEditorVisibilityMask();
  const toolRef = useEditorTool();
  return (<div className="basis-14 flex flex-row h-14 text-white items-center gap-2 bg-zinc-700/20">
    <LegendsConnectionIndicator />
    <span className="font-bold">{gameName}</span>

    {sheetReference && <QuickAccessMenu sheetRef={sheetReference} />}

    <Spacer/>

    <EditorGamePlayersView />

    <InputMask title="Access Mask" value={visibilityMaskRef} />

    <ToolModeInput valueRef={toolRef} />

    <XCardButton />

    <Menu as="div" className="basis-14 shrink-0">
      <Menu.Button as={IconButton} size="large" title="Add Panels">
        <FaEllipsisVertical />
      </Menu.Button>
      <Menu.Items className="absolute z-10 right-2 mt-2 w-48 origin-top-right divide-y divide-zinc-900/20 rounded-md overflow-hidden bg-zinc-800/50 backdrop-blur shadow-lg focus:outline-none flex flex-col">
        {Object.entries(EditorStacks).map(([stackType, stack]) => {
          const TabIcon = stack.Icon;
          return <Menu.Item key={stackType}>{({active}) => (
            <button className={`flex justify-flex-end items-center gap-2 px-4 py-2 font-bold ${active ? "bg-zinc-900/50" : ""}`} onClick={() => onAddStackItem(stack.defaultContent)}>
              {stack.label}
              <TabIcon />
            </button>
          )}</Menu.Item>
        })}
        <Menu.Item>{({active}) => (
          <button className={`flex justify-flex-end items-center gap-2 px-4 py-2 font-bold ${active ? "bg-zinc-900/50" : ""}`} onClick={() => {
            editor.resetContainer();
          }}>
            Reset Panels
          </button>
        )}</Menu.Item>
      </Menu.Items>
    </Menu>
  </div>);
});
