import {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

export type StackItemTabProps = HTMLAttributes<HTMLDivElement>;
export function StackProxyRegion(props: StackItemTabProps) {
  return <div className={twMerge(
    "box-border items-stretch basis-[240px] flex-grow-0",
    "pt-[2px] pb-[1px] min-w-none",
    "flex flex-row",
    "outline-none"
  )} {...props}>
    <div className={twMerge("flex-1 m-0.5 border-2 border-dashed border-white rounded-md")} />
  </div>
}
