import {Layout} from "./layout/index.ts";
import {ContainerOperation} from "./container-operation.ts";
import {Stack, StackType} from "./stack/index.ts";
import {DialogItem} from "./dialog/dialog-item.ts";
import {DialogType} from "./dialog/index.ts";

export type Container<LV> = {
  layout: Layout<LV>;
  dialogs: DialogItem<LV>[];
};

export const Container = {
  applyToValue: <LV, LO, WO>(
    stackType: StackType<LV, LO>,
    value: Container<LV>,
    operation: ContainerOperation<LV, LO>
  ): Container<LV> => {
    switch (operation.type) {
      case "apply-to-layout": {
        const newLayout = operation.operations.reduce((value, operation) => Layout.applyToValue(stackType, value, operation), value.layout);
        return ({
          ...value,
          layout: newLayout
        });
      }
      case "create-dialog": {
        return ({
          ...value,
          dialogs: [
            ...value.dialogs,
            operation.content
          ]
        });
      }
      case "activate-dialog": {
        if (value.dialogs.length > 0 && value.dialogs[value.dialogs.length - 1].id === operation.id) return value;
        return ({
          ...value,
          dialogs: [
            ...value.dialogs.filter(dialog => dialog.id !== operation.id),
            ...value.dialogs.filter(dialog => dialog.id === operation.id)
          ]
        });
      }
      case "apply-to-dialog": {
        return ({
          ...value,
          dialogs: value.dialogs
            .map(dialogItem => {
              if (dialogItem.id !== operation.id) return dialogItem;
              return operation.operations.reduce((value, operation) => DialogType.applyToValue(stackType, value, operation), dialogItem);
            })
            .filter(dialog => !DialogType.isEmpty(dialog))
        });
      }
      case "destroy-dialog": {
        return ({
          ...value,
          dialogs: value.dialogs.filter(dialog => dialog.id !== operation.id)
        })
      }
    }
  },
  migrateValue: <LV, LO>(
    stackType: StackType<LV, LO>,
    value: Container<LV>,
  ): Container<LV> => {
    return ({
      layout: Layout.migrateValue<LV, LO>(stackType, value.layout),
      dialogs: value.dialogs.map(dialog => ({
        ...dialog,
        value: {
          ...dialog.value,
          layout: Layout.migrateValue<LV, LO>(stackType, dialog.value.layout) as Stack<LV>
        }
      }))
    });
  }
};
