import {Dnd5eEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/dnd-5e-effect.ts";
import {generateDnd5eEffectID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/dnd-5e-effect-id.ts";
import {copyDnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/copy-dnd-5e-modifier.ts";

export function copyDnd5eEffect(value: Dnd5eEffect): Dnd5eEffect {
  return ({
    ...value,
    effectID: generateDnd5eEffectID(),
    modifiers: value.modifiers.map(copyDnd5eModifier)
  });
}