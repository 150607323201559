import {ColorOperation, colorType, HSLA, ObjectType, PropertyRef, Shape, ShapeOperation, shapeType, Type} from "../../types/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type ShapeNode = LocalNode & {
  shape: Shape;
  fillColor: HSLA;
};
export type ShapeNodeOperation =
  | LocalNodeOperation
  | {type: "update-shape", operations: ShapeOperation[]}
  | {type: "update-fill-color", operations: ColorOperation[]}
  ;
export const shapeNodeType: Type<ShapeNode, ShapeNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  shape: shapeType,
  fillColor: colorType,
}), (value) => {
  value = localNodeUpdater(value);
  return value;
});

export function ShapeNodeSignals(value: MutableRef<ShapeNode, ShapeNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    shapeRef: PropertyRef<ShapeNode, ShapeNodeOperation, Shape, ShapeOperation>(value => value.shape, operations => [{type: "update-shape", operations}])(value),
    fillColorRef: PropertyRef<ShapeNode, ShapeNodeOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(value),
  };
}
