import {ButtonHTMLAttributes, forwardRef, Ref} from 'react';
import {twMerge} from "tailwind-merge";

export type BareIconButtonVariant = "primary" | "tertiary" | "destructive";
export type BareIconButtonSize = "large" | "medium" | "small";
export type BareIconButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "size"> & {
  variant?: BareIconButtonVariant;
  size?: BareIconButtonSize;
};

export const BareIconButton = forwardRef(function BareIconButton({className, size = "medium", variant = "primary", ...props}: BareIconButtonProps, ref: Ref<HTMLButtonElement>): JSX.Element {
  return (
    <button ref={ref} className={twMerge(
      "border-none bg-transparent whitespace-nowrap box-border inline-flex justify-center items-center cursor-pointer outline-none p-0",
      "disabled:opacity-50",
      variant === "primary" ? "text-white hover:text-white/80 focus:text-white/80 hover:focus:text-white/60" : "",
      variant === "tertiary" ? "text-white/20 hover:text-white/50 focus:text-white/50 hover:focus:text-white/70" : "",
      variant === "destructive" ? "text-red-500 focus:text-red-400 hover:text-red-400" : "",
      size === "small" ? "w-4 h-4" : "",
      size === "medium" ? "w-5 h-5" : "",
      size === "large" ? "w-6 h-6" : "",
      className
    )} {...props}/>
  );
});
