import {Sheet} from "./sheet.ts";
import {getActiveModifiers, getActiveVariables} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MathExpressionFn} from "#common/math/index.ts";
import {Optional} from "#common/types/index.ts";
import {Dnd5eFeature} from "#common/legends/index.ts";
import {Dnd5eModifier} from "./dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {RollVariables} from "../../../qlab/index.ts";

export const SheetInterface = {
  getName: (sheet: Sheet): string => {
    switch (sheet.type) {
      case "dnd-5e-character": return sheet.data.name;
      case "dnd-5e-stat-block": return sheet.data.name;
    }
  },
  getLevel: (sheet: Optional<Sheet>): number => {
    if (sheet?.type === "dnd-5e-character") {
      return sheet.data.classes.reduce((a, c) => a + c.level, 0);
    }

    return 0;
  },
  getHealth: (sheet: Sheet, globalFeatures: Dnd5eFeature[]): string => {
    switch (sheet.type) {
      case "dnd-5e-stat-block":
      case "dnd-5e-character": {
        const main = `${SheetInterface.getMaxHP(sheet, globalFeatures) + sheet.data.hitPoints.current}/${SheetInterface.getMaxHP(sheet, globalFeatures)}`;
        if (sheet.data.hitPoints.temp > 0) {
          return `${main}+${sheet.data.hitPoints.temp}`;
        } else {
          return main;
        }
      }
    }
  },
  getMaxHP: (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]) => {
    const modifiers = getActiveModifiers(sheet, globalFeatures);
    const context = getActiveVariables(sheet, globalFeatures);
    let maxHP = 0;
    for (const modifier of modifiers) {
      if (modifier.type === "max-h-p") {
        const increaseAmount = MathExpressionFn.executeMathExpression(modifier.data.expression, context);
        maxHP += increaseAmount;
      }
    }
    return maxHP;
  },
  getShieldBonus: (modifiers: Dnd5eModifier[], activeVariables: RollVariables): number => {
    let total = 0;
    for (const modifier of modifiers) {
      if (modifier.type !== "armor-class") continue;
      if (modifier.data.modifierType !== "SHIELD") continue;
      total += MathExpressionFn.executeMathExpression(modifier.data.expression, activeVariables);
    }
    return total;
  },
  getMiscBonus: (modifiers: Dnd5eModifier[], activeVariables: RollVariables): number => {
    let total = 0;
    for (const modifier of modifiers) {
      if (modifier.type !== "armor-class") continue;
      if (modifier.data.modifierType !== "MISC") continue;
      total += MathExpressionFn.executeMathExpression(modifier.data.expression, activeVariables);
    }
    return total;
  },
  getAC: (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): string => {
    const modifiers = getActiveModifiers(sheet, globalFeatures);
    const activeVariables = getActiveVariables(sheet, globalFeatures)
    const shieldBonus = SheetInterface.getShieldBonus(modifiers, activeVariables);
    const miscBonus = SheetInterface.getMiscBonus(modifiers, activeVariables);
    let armorClass = MathExpressionFn.executeMathExpression(MathExpressionFn.assertMathExpression("10+{DEX}"), activeVariables) + shieldBonus + miscBonus;
    for (const modifier of modifiers) {
      if (modifier.type !== "armor-class-formula") continue;
      const modifierArmorClass =
        MathExpressionFn.executeMathExpression(modifier.data.expression, activeVariables)
          + (modifier.data.shieldBonus ? shieldBonus : 0)
          + miscBonus;
      armorClass = Math.max(armorClass, modifierArmorClass);
    }
    return `${armorClass}`;
  }
};
