import React, {PropsWithChildren} from "react";
import {ListOperation} from "common/types/index.ts";
import {DropTargetMonitor} from "react-dnd";
import {twMerge} from "tailwind-merge";
import {useListSignals} from "../../../legends/common/use-list-signals.ts";
import {MutableRef} from "common/ref";

export type PlaceholderProps = { active: boolean; };
export function DefaultPlaceholder({active}: PlaceholderProps) {
  return <div className={"py-1 -my-2"}>
    <hr className={twMerge(
      "border-white/30 border-dashed border-t-4",
      active && "border-white"
    )} />
  </div>
}

export function getPlaceholderIndex(monitor: DropTargetMonitor, listElement: HTMLElement) {
  const offset = monitor.getClientOffset();
  if (offset === null) return -1;

  let index = 0;
  const {y} = offset;
  const isOver = monitor.isOver({shallow:false});
  if (isOver) {
    const children = listElement.children;
    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);
      if (child?.role === "list-item") {
        const {top, bottom} = child.getBoundingClientRect();
        const height = bottom - top;

        if (top + height / 2 > y) break;
        index++;
      }
    }
  }
  return index;
}

type Component<T, O> = (props: PropsWithChildren<any>) => JSX.Element;
export type ListItemProps<T, O> = {
  itemKey: string | number;
  item: MutableRef<T, O[]>;
  remove: () => void;
};

export type InputListProps<T, O> = {
  itemKey: (item: T, index: number) => string | number;
  items: MutableRef<T[], ListOperation<T, O>[]>;
  placeholders?: boolean;
  activePlaceholderIndex?: number;

  Component: Component<T, O>;
  Empty?: () => JSX.Element;
  Placeholder?: (props: PlaceholderProps) => JSX.Element;
  ListItem: (props: ListItemProps<T, O>) => JSX.Element;
};

export function DefaultEmpty() {
  return <div className="h-10" />
}

export function UnsupportedCopy(): never {
  throw new Error("Unsupported");
}

export function GenericInputList<T, O>({items, itemKey, placeholders, activePlaceholderIndex, Component, Placeholder = DefaultPlaceholder, ListItem, Empty = DefaultEmpty}: InputListProps<T, O>) {
  const itemsSignal = useListSignals(items, itemKey, UnsupportedCopy);
  return (<Component items={items}>
    {itemsSignal.map(([key, props], index) => <React.Fragment key={key}>
      {placeholders && <Placeholder key="placeholder" active={activePlaceholderIndex === index} />}
      <ListItem key="item" item={props.itemRef} itemKey={key} remove={props.remove} />
    </React.Fragment>)}
    {placeholders && <Placeholder key="placeholder" active={activePlaceholderIndex === itemsSignal.length} />}
    {placeholders && itemsSignal.length === 0 && <Empty />}
  </Component>);
}
