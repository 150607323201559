import {ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {SelectionRef} from "../../container/editor/state/selection-ref.ts";

export type EditorProperties = {
  reference: SelectionRef;
};

export type EditorPropertiesOperation =
  | {type: "update-reference", operations: ValueOperation<SelectionRef, ConstantOperation>[]}
  ;

export const editorPropertiesType: Type<EditorProperties, EditorPropertiesOperation> = new ObjectType({
  reference: new ValueType(constantType)
});

export const EditorProperties = {
  getReference: (properties: EditorProperties) => properties.reference
};
export const EditorPropertiesOperation = {
  updateReference(operations: ValueOperation<SelectionRef, ConstantOperation>[]): EditorPropertiesOperation[] {
    return [{type: "update-reference", operations}];
  }
};
