import {z} from "zod";
import {Spline, SplineOperation, splineType} from "common/types/generic/spline/index.ts";
import {ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {WallToolMode} from "./wall-tool-mode.ts";

export const WallToolCreateMode = z.object({
  spline: z.optional(Spline)
});
export type WallToolCreateMode = z.infer<typeof WallToolCreateMode>
export const WallToolCreateModeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-spline"), operations: z.array(ValueOperation(z.optional(Spline), SplineOperation))})
])
export type WallToolCreateModeOperation = z.infer<typeof WallToolCreateModeOperation>
export const wallToolCreateModeType: Type<WallToolCreateMode, WallToolCreateModeOperation> = new ObjectType({
  spline: new ValueType(splineType)
});

export const WallToolCreateModeFn = {
  DEFAULT: {
    type: "create",
    data: {
      spline: undefined
    }
  } satisfies WallToolMode
};
