import {useMemo} from "react";
import {ListOperation} from "common/types/index.ts";
import {Dnd5eSystemSignals} from "common/legends/game/system/dnd-5e-system.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, GameSignals} from "common/legends/index.ts";
import {useGame} from "../../../../../../routes/game/index.ts";
import {SystemSignals} from "common/legends/game/system/game-systems.ts";
import {MutableRef} from "common/ref";

export function useGlobalFeatures(): MutableRef<Dnd5eFeature[], ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]> {
  const game = useGame();
  return useMemo(() => {
    const {systems} = GameSignals(game);
    const {dND5e} = SystemSignals(systems);
    const {globalFeatures} = Dnd5eSystemSignals(dND5e);
    return globalFeatures;
  }, [game]);
}
