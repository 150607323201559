import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Tag,
  tagType,
  Type
} from "#common/types/index.ts";
import {AssetID} from "../../asset/asset-i-d.ts";
import {MutableRef} from "#common/ref";

export type AssetTreeFile = {
  id: AssetID;
  name: string;
  tags: Tag[];
};
export type AssetTreeFileOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  ;
export const assetFileType: Type<AssetTreeFile, AssetTreeFileOperation> = new ObjectType({
  id: constantType,
  name: stringType,
  tags: new ListType(tagType)
});

export function AssetTreeFileSignals(value: MutableRef<AssetTreeFile, AssetTreeFileOperation[]>) {
  return {
    name: PropertyRef<AssetTreeFile, AssetTreeFileOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    tags: ListPropertyRef<AssetTreeFile, AssetTreeFileOperation, Tag, ConstantOperation>(
      value => value.tags,
      operations => [{type: "update-tags", operations}]
    )(value)
  };
}
