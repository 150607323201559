import {ArrayBufferToBase64Codec, Base64Codec} from "#common/codec";
import {PublicKey} from "./public-key.ts";

export async function encrypt(body: string, publicKey: PublicKey): Promise<string> {
  //@ts-ignore
  const cryptoKey = await crypto.subtle.importKey(
    "jwk",
    JSON.parse(Base64Codec.decode(publicKey[0])),
    {name: "RSA-OAEP", hash: {name: "SHA-256"}},
    false,
    ["encrypt"]
  );

  //@ts-ignore
  const encryptedData: ArrayBuffer = await crypto.subtle.encrypt(
    {name: "RSA-OAEP"},
    cryptoKey,
    new TextEncoder().encode(body)
  );
  return ArrayBufferToBase64Codec.encode(encryptedData);
}
