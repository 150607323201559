import React, {ChangeEvent, useCallback} from "react";
import {ValueFn, ValueOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Select} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";

export type InputSelectProps<T extends string> = {
  value: MutableRef<T, ValueOperation<T, never>[]>;
  values: readonly T[];
  labels: Readonly<{[value in T]: string}>;
};

export function InputSelect<T extends string>({value, values, labels}: InputSelectProps<T>) {
  return <Select className="-mr-4" value={useRefValue(value)} onChange={useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    const newValue = ev.target.value as T;
    value.apply(prev => ValueFn.set(prev, newValue));
  }, [value])}>
    {values.map(key => <option key={key} value={key}>{labels[key]}</option>)}
  </Select>
}
