import {z} from "zod";
import {generateInteractionID, InteractionID} from "#common/legends/node/interaction/interaction-i-d.ts";
import {ConstantOperation, constantType, ListPropertyRef, ListType, ObjectType, PropertyRef, StringOperation, stringType, Type, ZodListOperation} from "#common/types/index.ts";
import {copyInteractionTrigger, InteractionTrigger, InteractionTriggerOperation, interactionTriggerType} from "#common/legends/node/interaction/trigger/interaction-trigger.ts";
import {copyInteractionAction, InteractionAction, InteractionActionOperation, interactionActionType} from "#common/legends/node/interaction/action/interaction-action.ts";
import {MutableRef} from "#common/ref";


export const Interaction = z.object({
  interactionID: InteractionID,
  title: z.string(),
  triggers: z.array(InteractionTrigger),
  actions: z.array(InteractionAction)
});
export type Interaction = z.infer<typeof Interaction>;

export const InteractionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-title"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-triggers"), operations: z.array(ZodListOperation(InteractionTrigger, InteractionTriggerOperation))}),
  z.object({type: z.literal("update-actions"), operations: z.array(ZodListOperation(InteractionAction, InteractionActionOperation))})
]);
export type InteractionOperation = z.infer<typeof InteractionOperation>;

export const interactionType: Type<Interaction, InteractionOperation> = new ObjectType({
  interactionID: constantType,
  title: stringType,
  triggers: new ListType(interactionTriggerType),
  actions: new ListType(interactionActionType)
});

export function InteractionSignals(value: MutableRef<Interaction, InteractionOperation[]>) {
  return ({
    title: PropertyRef<Interaction, InteractionOperation, string, StringOperation>(
      value => value.title,
      operations => [{type: "update-title", operations}]
    )(value),
    triggers: ListPropertyRef<Interaction, InteractionOperation, InteractionTrigger, InteractionTriggerOperation>(
      value => value.triggers,
      operations => [{type: "update-triggers", operations}]
    )(value),
    actions: ListPropertyRef<Interaction, InteractionOperation, InteractionAction, InteractionActionOperation>(
      value => value.actions,
      operations => [{type: "update-actions", operations}]
    )(value)
  })
}

export function getInteractionID(value: Interaction) {
  return value.interactionID;
}

export function copyInteraction(value: Interaction): Interaction {
  return ({
    interactionID: generateInteractionID(),
    title: value.title,
    triggers: value.triggers.map(copyInteractionTrigger),
    actions: value.actions.map(copyInteractionAction)
  });
}