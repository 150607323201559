import {Type} from "../../type/type.ts";
import {NumberOperation} from "./number-operation.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const numberType: Type<number, NumberOperation> = {
  apply: (value: number, operation: NumberOperation): number => {
    switch (operation.type) {
      case "increment": return value + operation.amount;
      case "decrement": return value - operation.amount;
      case "set": return operation.nextValue;
      default: throw new Error("Unsupported operation: " + JSON.stringify(operation));
    }
  },
  invert: (operation: NumberOperation): NumberOperation[] => {
    switch (operation.type) {
      case "increment": return [{type: "decrement", amount: operation.amount}];
      case "decrement": return [{type: "increment", amount: operation.amount}];
      case "set": return [{type: "set", prevValue: operation.nextValue, nextValue: operation.prevValue}];
      default: throw new Error("Unsupported operation: " + JSON.stringify(operation));
    }
  },
  transform: (leftOperation: NumberOperation, topOperation: NumberOperation, tieBreaker: boolean): NumberOperation[] => {
    switch (leftOperation.type) {
      case "increment":
        switch (topOperation.type) {
          case "increment": return [leftOperation];
          case "decrement": return [leftOperation];
          case "set": return tieBreaker ? [leftOperation] : [];
        }
        break;
      case "decrement":
        switch (topOperation.type) {
          case "increment": return [leftOperation];
          case "decrement": return [leftOperation];
          case "set": return tieBreaker ? [leftOperation] : [];
        }
        break;
      case "set":
        switch (topOperation.type) {
          case "increment": return tieBreaker
            ? [leftOperation]
            : [{
              type: "set",
              prevValue: leftOperation.prevValue + topOperation.amount,
              nextValue: leftOperation.nextValue
            }];
          case "decrement": return tieBreaker
            ? [leftOperation]
            : [{
              type: "set",
              prevValue: leftOperation.prevValue - topOperation.amount,
              nextValue: leftOperation.nextValue
            }];
          case "set": return tieBreaker
            ? [leftOperation]
            : [{
              type: "set",
              prevValue: topOperation.nextValue,
              nextValue: leftOperation.nextValue
            }];
        }
        break;
    }
    throw new Error("Unsupported operation: " + JSON.stringify(leftOperation) + ", " + JSON.stringify(topOperation))
  },
  migrateValue: (value: any): number => {
    return value;
  },
  migrateOperation: (operation: any): NumberOperation[] => {
    return [operation];
  },
  validate(value: any): ValidationError[] {
    if (typeof value !== "number") return [{path: [], data: {message: "Invalid type. Expected number.", value}}];
    return [];
  }
}