import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaMapPin, FaTag} from "react-icons/fa";
import {useObservable} from "#lib/qlab/index.ts";
import {ConstantOperation, Optional, StringOperation, ValueOperation} from "common/types/index.ts";
import {useCallback} from "react";
import {pipe} from "common/pipe";
import {distinct, from, map} from "common/observable";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";

export type NameFieldProps<T> = {
  value: MutableRef<string, StringOperation[]>;
  reference: T;
  pinned?: MutableRef<Optional<T>, ValueOperation<Optional<T>, ConstantOperation>[]>;
};

export function NameField({value, reference, pinned}: NameFieldProps<any>) {
  const togglePinned = useCallback(() => {
    pinned?.apply(prevValue => {
      if (prevValue === reference) return [{type: "set", prevValue, nextValue: undefined}];
      return [{
        type: "set",
        prevValue: prevValue,
        nextValue: reference
      }];
    });
  }, [reference, pinned?.apply]);

  const isPinned = useObservable(pipe(pinned?.observe || from(undefined), map(r => r === reference), distinct()), false, [reference, pinned?.observe]);

  return (<ButtonBar>
    <InputGroup size="small" className="flex-1 pl-0 pr-2">
      <InputGroupLabel className="w-8 h-8 flex items-center justify-center"><FaTag /></InputGroupLabel>
      <InputString value={value} onFocus={ev => ev.target.select()} />
    </InputGroup>
    {pinned?.apply && <IconButton size="small" onClick={togglePinned} variant={isPinned ? "primary" : "tertiary"}><FaMapPin/></IconButton>}
  </ButtonBar>)
}
