import {useCallback, useMemo} from "react";
import {FileReference, VisitResult, walkTree} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {distinct, from, map, Observable, switchAll} from "common/observable";
import {TokenReference} from "common/legends/asset/token/token-reference.ts";
import {pipe} from "common/pipe";
import {useStoreID} from "../../routes/game/use-store-id.ts";
import {QLabDatabase} from "common/qlab/index.ts";
import {useDatabase} from "../../routes/game/model/store-context.tsx";
import {NodeRef} from "./legends/node-ref.ts";
import {AssetTokenRef} from "./legends/asset-token-ref.ts";
import {AssetRef} from "./legends/asset-ref.ts";

export function useNodeIcon(observe: Observable<NodeId | undefined>): Observable<FileReference> {
  const instance = useInstance();
  const gameID = useStoreID();

  return useMemo(() => pipe(
    observe,
    map((nodeID: NodeId | undefined): Observable<FileReference> => {
      if (nodeID === undefined) return from(undefined);
      return pipe(
        instance.observe(gameID),
        map((game: QLabDatabase): FileReference => {
          for (const resource of Object.values(game.resources)) {
            if (resource?.type !== "scene") continue;
            let tokenReference: TokenReference | undefined;
            walkTree(resource.data.children, {
              visit(node) {
                if (node?.type === "token") {
                  if (node.data.id === nodeID) {
                    tokenReference = node.data.tokenReference;
                    return VisitResult.TERMINATE;
                  }
                }
              }
            });

            if (tokenReference !== undefined) {
              const asset = game.resources[tokenReference.assetID];
              if (asset?.type !== "asset") return undefined satisfies FileReference;
              const token = asset.data.tokens.find(token => token.tokenID === tokenReference?.tokenID);
              if (token === undefined) return undefined satisfies FileReference;
              return token.icon satisfies FileReference;
            }
          }
          return undefined satisfies FileReference;
        })
      );
    }),
    switchAll(),
    distinct()
  ), [observe, instance, gameID]);
}

export function useGetNodeIcon() {
  const databaseRef = useDatabase();
  return useCallback(async (nodeID: NodeId | undefined): Promise<FileReference> => {
    const node = NodeRef(databaseRef, nodeID).value;
    if (node?.type !== "token") return undefined;
    const tokenReference = node.data.tokenReference;
    if (!tokenReference) return undefined;
    const assetToken = AssetTokenRef(AssetRef(databaseRef, tokenReference.assetID), tokenReference.tokenID).value;
    return assetToken?.icon;
  }, [databaseRef]);
}
