import {EffectNodeCondition, EffectNodeConditionOperation, EffectNodeConditionSignal} from "common/legends/node/condition/effect-node-condition.ts";
import {ButtonBar, IconButton, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {ValueFn} from "common/types/index.ts";
import {Dnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {FaTrash} from "react-icons/fa";
import {ListControl} from "./list-control.ts";
import {useSheetEffects} from "../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-effects.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {MutableRef} from "common/ref";

export type EffectNodeConditionFieldProps = {
  value: MutableRef<EffectNodeCondition, EffectNodeConditionOperation[]>;
  controls: ListControl;
};
export function EffectNodeConditionField({value, controls}: EffectNodeConditionFieldProps) {
  const {effectID} = useMemo(() => EffectNodeConditionSignal(value), [value]);
  const effectIDValue = useRefValue(effectID);
  const sheetEffects = useSheetEffects(useSheetSignal(useSheetReference()), useGlobalFeatures());
  const effects = useRefValue(sheetEffects)

  return <InputGroup className="pr-0">
    <InputGroupLabel>
      Effect
    </InputGroupLabel>
    <Select value={effectIDValue || ""} onChange={e => {
      const next = e.target.value as Dnd5eEffectID | "";
      effectID.apply(prev => next === "" ? ValueFn.set(prev, undefined) : ValueFn.set(prev, next));
    }}>
      <option value="">N/A</option>
      {effects.map(effect => <option key={effect.effectID} value={effect.effectID}>
        {effect.name}
      </option>)}
    </Select>
    <ButtonBar>
      {controls.remove && <IconButton title="Remove" variant="destructive" onClick={controls.remove}><FaTrash /></IconButton>}
    </ButtonBar>
  </InputGroup>
}