import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2ActiveTexture extends WebGL2Node {
  public texture?: GLenum;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.texture === undefined) throw new Error("texture must be provided.");

    this.context.activeTexture(this.texture);
  }
}
