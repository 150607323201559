import savingThrowUntrained from "./saving-throw-untrained.svg";
import savingThrowProficient from "./saving-throw-proficient.svg";
import {Dnd5eSavingThrowProficiency, SAVING_THROW_PROFICIENCY_TITLE} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-saving-throw-proficiency.ts";

export type SavingThrowProficiencyProps = {
  value: Dnd5eSavingThrowProficiency;
  onChange?: (nextValue: Dnd5eSavingThrowProficiency) => void;
};
export function SavingThrowProficiency({value, onChange}: SavingThrowProficiencyProps) {
  return <div title={SAVING_THROW_PROFICIENCY_TITLE[value]} onClick={onChange ? () => onChange(value === "untrained" ? "proficient" : "untrained") : undefined} className="basis-6">
    <img width={20} height={20} alt={value} src={value === "untrained" ? savingThrowUntrained : savingThrowProficient} />
  </div>
}