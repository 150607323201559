import {z} from "zod";
import {constantType, Type} from "#common/types/index.ts";
import {InteractionTriggerID} from "./interaction-trigger-i-d.ts";
import {ConstantOperation, ObjectType} from "../../../../types/index.ts";

export const InteractionTriggerExit = z.object({
  interactionTriggerID: InteractionTriggerID
});
export type InteractionTriggerExit = z.infer<typeof InteractionTriggerExit>;
export const InteractionTriggerExitOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-trigger-i-d"), operations: z.array(ConstantOperation)})
]);
export type InteractionTriggerExitOperation = z.infer<typeof InteractionTriggerExitOperation>;
export const interactionTriggerExitType: Type<InteractionTriggerExit, InteractionTriggerExitOperation> = new ObjectType({
  interactionTriggerID: constantType
});
