import {useRenderingContext} from "./rendering-context.ts";
import {useMemo} from "react";

export function useBindVertexArribArray(vao: WebGLVertexArrayObject, attributeLocation: GLint, buffer: WebGLBuffer, size: GLint, type: GLenum, normalized: boolean, stride: GLint, offset: GLint): void {
  const context = useRenderingContext();
  useMemo((): void => {
    context.bindVertexArray(vao);
    context.enableVertexAttribArray(attributeLocation);
    context.bindBuffer(WebGL2RenderingContext.ARRAY_BUFFER, buffer);
    context.vertexAttribPointer(attributeLocation, size, type, normalized, stride, offset);
    context.bindBuffer(WebGL2RenderingContext.ARRAY_BUFFER, null);
    context.bindVertexArray(null);
  }, [context, vao, attributeLocation, buffer, size, type, normalized, stride,  offset]);
}