import {AssetID, NodeId, SceneID, TokenID} from "common/legends/index.ts";
import {Optional, Tree, TreePath} from "common/types/index.ts";
import {useCallback} from "react";
import {QLabDatabase} from "common/qlab/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";

export type NodePath =
  | {type: "scene", sceneID: SceneID, path: TreePath}
  | {type: "asset", assetID: AssetID, tokenID: TokenID, path: TreePath}
  ;

export function getNodePath(database: QLabDatabase, nodeID: Optional<NodeId>): Optional<NodePath> {
  if (nodeID === undefined) return undefined;
  for (const [resourceID, resource] of Object.entries(database.resources)) {
    if (resource?.type === "scene") {
      const path = Tree.getPath(resource.data.children, node => node.data.id === nodeID);
      if (path === undefined) continue;
      return {type: "scene", sceneID: resourceID as SceneID, path};
    } else if (resource?.type === "asset") {
      for (const token of resource.data.tokens) {
        const path = Tree.getPath(token.children, node => node.data.id === nodeID);
        if (path === undefined) continue;
        return {type: "asset", assetID: resourceID as AssetID, tokenID: token.tokenID, path};
      }
    }
  }
  return undefined;
}

export function useGetNodePath() {
  const databaseRef = useDatabase();
  return useCallback((nodeID: NodeId): Optional<NodePath> => {
    return getNodePath(databaseRef.value, nodeID);
  }, [databaseRef]);
}
