import {twMerge} from "tailwind-merge";
import {DAMAGE_TYPE_IMAGES} from "../../../../../common/systems/dnd5e/damage-types/index.ts";
import React from "react";
import {Dnd5eDamageType, DND_5E_DAMAGE_TYPE_TITLES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";

export type Dnd5eDamageTypeViewProps = {
  active: boolean;
  damageType: Dnd5eDamageType;
}

export function Dnd5eDamageTypeView({active, damageType}: Dnd5eDamageTypeViewProps) {
  return <img key={damageType} className={twMerge(
    "basis-6 shrink-0 grow-0",
    "w-6 h-6",
    !active ? "opacity-50" : ""
  )} alt={damageType} title={damageType} src={DAMAGE_TYPE_IMAGES[damageType]}/>;
}

export type Dnd5eDamageTypeTagProps = {
  type: Dnd5eDamageType;
};

export function Dnd5eDamageTypeTag({type}: Dnd5eDamageTypeTagProps) {
  return (
    <div className="h-6 flex flex-row gap-1 rounded-full pr-3 bg-zinc-900/50 items-center">
      <Dnd5eDamageTypeView key={type} damageType={type} active/>
      <span className="text-xs text-white/80 whitespace-nowrap shrink-0 uppercase select-none">{DND_5E_DAMAGE_TYPE_TITLES[type]}</span>
    </div>
  );
}