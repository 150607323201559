import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {NodeId} from "common/legends/node/index.ts";
import {getNodePath} from "../node/use-get-node-path.ts";
import {Grid} from "common/legends/scene/index.ts";

export function getGrid(database: QLabDatabase, targetNodeId: NodeId) {
  const nodePath = getNodePath(database, targetNodeId);
  if (nodePath?.type === "scene") {
    const scene = database.resources[nodePath.sceneID];
    if (scene?.type !== "scene") return Grid.DEFAULT;
    return scene.data.grid;
  } else {
    return Grid.DEFAULT;
  }
}
