import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Action extends WebGL2Node {
  public onAction?: (context: WebGL2RenderingContext) => void = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.onAction) {
      this.onAction(this.context);
    }
  }
}
