import {ProxyRegion, ProxyRegionViewer} from "#lib/components/index.ts";
import {Container, DialogID, DialogIDFn, Layout, LayoutFn, LayoutOperation, LayoutPath, Stack, StackFn, StackItem} from "../modal/index.ts";
import React, {useCallback, useRef, useState} from "react";
import {ContainerFn, ContainerOperation} from "../modal/container-operation.ts";
import {DialogView} from "./dialog/index.ts";
import {Point} from "#lib/math/index.ts";
import {ApplyAction} from "#lib/qlab/index.ts";
import "./container-view.css";
import {DialogItem, DialogItemFn, DialogItemOperation} from "../modal/dialog/dialog-item.ts";
import {LayoutContentView} from "./layout/layout-content-view.ts";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {useDragLayer, useDrop} from "react-dnd";
import {ListOperation, Optional} from "common/types/index.ts";
import {ExternalDialogView} from "#lib/container/react/dialog/external-dialog-view.tsx";
import {StackContentView} from "#lib/container/react/stack/stack-content-view.tsx";
import {StackTabView} from "#lib/container/react/stack/stack-tab-view.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export type ContainerViewProps<LV, LO> = {
  containerRef: MutableRef<Container<LV>, ContainerOperation<LV, LO>[]>;
  labelFor: (stack: StackItem<LV>) => string;
  LayoutContentView: LayoutContentView<LV, LO>;
  StackContentView: StackContentView<LV, LO>;
  StackTabView: StackTabView<LV>;
  Stacks: Stacks<LV>;
};

const DEFAULT_DIALOG_SIZE: [number, number] = [720, 480];

let lastDragTime: number = 0;
let dragHandler: NodeJS.Timeout | null = null;

export function ContainerView<LV, LO>({containerRef, LayoutContentView, StackContentView, StackTabView, Stacks}: ContainerViewProps<LV, LO>) {
  const [proxyRegion, setProxyRegion] = useState<ProxyRegion | undefined>(undefined);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const [, resizeDropRef] = useDrop<{dialogId: DialogID, direction: "bottom-left" | "bottom" | "bottom-right" | "left" | "right", initialPosition?: Point}>(() => ({
    accept: "legends/dialog-resize",
    hover: ({dialogId, direction, initialPosition}, monitor) => {
      const value = containerRef.value;
      if (dragHandler) clearTimeout(dragHandler);
      dragHandler = setTimeout(() => {
        const {left, top, width, height} = containerDivRef.current!.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();
        if (clientOffset === null) return;
        const {x, y} = clientOffset;
        const [sx, sy] = [x - left, y - top];
        const dialogItem = value.dialogs.find(dialog => dialog.id === dialogId)!;

        if (direction === "bottom-left") {
          const newPosition: [number, number] = [
            Math.max(0, Math.min(sx, dialogItem.position[0] + dialogItem.size[0] - 136)),
            dialogItem.position[1]
          ];
          const newSize: [number, number] = [
            dialogItem.size[0] + (dialogItem.position[0] - newPosition[0]),
            Math.max(40, Math.min((sy - newPosition[1]), height - newPosition[1])),
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "bottom") {
          const newPosition: [number, number] = dialogItem.position;
          const newSize: [number, number] = [
            dialogItem.size[0],
            Math.max(40, Math.min(sy - newPosition[1], height - newPosition[1]))
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "bottom-right") {
          const newPosition: [number, number] = dialogItem.position;
          const newSize: [number, number] = [
            Math.max(136, Math.min((sx - newPosition[0]), width - newPosition[0])),
            Math.max(40, Math.min((sy - newPosition[1]), height - newPosition[1]))
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "left") {
          const newPosition: [number, number] = [
            Math.max(0, Math.min(sx, dialogItem.position[0] + dialogItem.size[0] - 136)),
            dialogItem.position[1]
          ];
          const newSize: [number, number] = [
            dialogItem.size[0] + (dialogItem.position[0] - newPosition[0]),
            dialogItem.size[1]
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "right") {
          const newPosition: [number, number] = dialogItem.position;
          const newSize: [number, number] = [
            Math.max(136, Math.min((sx - newPosition[0]), width - newPosition[0])),
            dialogItem.size[1]
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "top-left") {
          const newPosition: [number, number] = [
            Math.max(0, Math.min(sx, dialogItem.position[0] + dialogItem.size[0] - 136)),
            Math.max(0, Math.min(sy, dialogItem.position[1] + dialogItem.size[1] - 16))
          ];
          const newSize: [number, number] = [
            dialogItem.size[0] + (dialogItem.position[0] - newPosition[0]),
            dialogItem.size[1] + (dialogItem.position[1] - newPosition[1])
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "top") {
          const newPosition: [number, number] = [
            dialogItem.position[0],
            Math.max(0, Math.min(sy, dialogItem.position[1] + dialogItem.size[1] - 16))
          ];
          const newSize: [number, number] = [
            dialogItem.size[0],
            dialogItem.size[1] + (dialogItem.position[1] - newPosition[1])
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "top-right") {
          const newPosition: [number, number] = [
            dialogItem.position[0],
            Math.max(0, Math.min(sy, dialogItem.position[1] + dialogItem.size[1] - 16))
          ];
          const newSize: [number, number] = [
            Math.max(136, Math.min((sx - newPosition[0]), width - newPosition[0])),
            dialogItem.size[1] + (dialogItem.position[1] - newPosition[1])
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, newSize)));
        } else if (direction === "move") {
          const {width, height} = containerDivRef.current!.getBoundingClientRect();
          const clientOffset = monitor.getClientOffset();
          const initialClientOffset = monitor.getInitialClientOffset();
          if (clientOffset === null || initialClientOffset === null || initialPosition === undefined) return;
          const dialogItem = value.dialogs.find(dialog => dialog.id === dialogId)!;

          const newPosition: [number, number] = [
            Math.max(0, Math.min(initialPosition[0] + clientOffset.x - initialClientOffset.x, width - dialogItem.size[0])),
            Math.max(0, Math.min(initialPosition[1] + clientOffset.y - initialClientOffset.y, height - 24))
          ];
          containerRef.apply(_ => ContainerFn.applyToDialog(dialogId, DialogItemFn.move(newPosition, dialogItem.size)));
          dragHandler = null;
          lastDragTime = new Date().getTime();
        }
        dragHandler = null;
        lastDragTime = new Date().getTime();
      }, Math.max(0, 16 - (new Date().getTime() - lastDragTime)));
    }
  }), [containerRef, containerDivRef]);

  const [{isOver}, tabRef] = useDrop<{
    dialogId?: DialogID,
    layoutPath: LayoutPath,
    value: StackItem<LV>
  }, unknown, {isOver: boolean}>(() => ({
    accept: "legends/tab",
    collect: (monitor) => ({
      isOver: monitor.isOver({shallow: true})
    }),
    hover: (_, monitor) => {
      const {left, top, width, height} = containerDivRef.current!.getBoundingClientRect();
      const {x, y} = monitor.getClientOffset()!;
      const [sx, sy] = [x - left, y - top];
      if (dragHandler) clearTimeout(dragHandler)
      dragHandler = setTimeout(() => {
        const value = containerRef.value;
        // Check dialogs
        for (let i = value.dialogs.length - 1; i >= 0; i --) {
          const dialog = value.dialogs[i];
          if (dialog.minimized) continue;
          if (dialog.value.type !== "layout") continue;
          if (dialog.position[0] <= sx && sx <= dialog.position[0] + dialog.size[0]
            && dialog.position[1] <= sy && sy <= dialog.position[1] + dialog.size[1]) {
            const layoutAction = Layout.getLayoutAction(dialog.value.layout, [sx - dialog.position[0], sy - dialog.position[1]], dialog.size);
            if (layoutAction !== undefined) {
              const proxyRegion = Layout.getProxyRegion(dialog.value.layout, layoutAction, dialog.size);
              setProxyRegion({x: proxyRegion.x + dialog.position[0], y: proxyRegion.y + dialog.position[1], width: proxyRegion.width, height: proxyRegion.height});
              return;
            }
          }
        }

        // Check layout
        const layoutAction = Layout.getLayoutAction(value.layout, [sx, sy], [width, height]);
        if (layoutAction !== undefined) {
          const proxyRegion = Layout.getProxyRegion(value.layout, layoutAction, [width, height]);
          setProxyRegion(proxyRegion);
        } else {
          // New Dialog
          setProxyRegion({
            x: Math.min(Math.max(0, sx - DEFAULT_DIALOG_SIZE[0]/2), width - DEFAULT_DIALOG_SIZE[0]),
            y: Math.min(Math.max(0, sy), height - DEFAULT_DIALOG_SIZE[1]),
            width: DEFAULT_DIALOG_SIZE[0],
            height: DEFAULT_DIALOG_SIZE[1]
          });
        }
        dragHandler = null;
        lastDragTime = new Date().getTime();
      }, Math.max(0, 16 - (new Date().getTime() - lastDragTime)));
    },
    drop: (data, monitor) => {
      const value = containerRef.value;
      const {left, top, width, height} = containerDivRef.current!.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (clientOffset === null) return;
      const {x, y} = clientOffset;
      const [sx, sy] = [x - left, y - top];

      if (dragHandler) clearTimeout(dragHandler);
      setProxyRegion(undefined);
      // ev.dataTransfer.dropEffect = "move";
      const stack: Stack<LV> = {type: "stack", activeId: data.value.id, items: [data.value]};

      // Drop Tab into a Dialog
      for (let i = value.dialogs.length - 1; i >= 0; i --) {
        const dialogItem = value.dialogs[i];
        if (dialogItem.minimized) continue;
        if (dialogItem.value.type !== "layout") continue;

        if (dialogItem.position[0] <= sx && sx <= dialogItem.position[0] + dialogItem.size[0]
          && dialogItem.position[1] <= sy && sy <= dialogItem.position[1] + dialogItem.size[1]) {
          return;
        }
      }

      // Drop tab into layout
      const targetDialogId: DialogID | undefined = undefined;
      const layoutAction = Layout.getLayoutAction(value.layout, [sx, sy], [width, height]);
      if (layoutAction !== undefined) {
        const sourceDialogId = data.dialogId;
        const insertOperation: LayoutOperation<LV, LO> = {...layoutAction, value: stack};
        const newPath =
          sourceDialogId === targetDialogId
            ? Layout.transformPath(value.layout, insertOperation, data.layoutPath)
            : data.layoutPath;
        const removeStack = StackFn.updateItems(ListOperation.delete(newPath[newPath.length - 1], data.value))
        const removeOperations: LayoutOperation<LV, LO>[] = LayoutFn.applyToStack(
          newPath.slice(0, -1),
          removeStack
        );
        containerRef.apply(_ => [
          ...ContainerFn.applyToLayout<LV, LO>([insertOperation]),
          ...(sourceDialogId === undefined
            ? ContainerFn.applyToLayout<LV, LO>(removeOperations)
            : ContainerFn.applyToDialog<LV, LO>(sourceDialogId, DialogItemFn.applyToLayout(removeStack))
          )
        ]);
      } else {
        const sourceDialogId = data.dialogId;
        const newPath = data.layoutPath;
        const removeStack = StackFn.updateItems(ListOperation.delete(newPath[newPath.length - 1], data.value))
        const removeOperations: LayoutOperation<LV, LO>[] = LayoutFn.applyToStack(
          newPath.slice(0, -1),
          removeStack
        );

        // Create New Dialog
        containerRef.apply(_ => [
          ...ContainerFn.createDialog<LV, LO>({
            id: DialogIDFn.generate(),
            minimized: false,
            position: [
              Math.min(Math.max(0, sx - DEFAULT_DIALOG_SIZE[0]/2), width - DEFAULT_DIALOG_SIZE[0]),
              Math.min(Math.max(0, sy), height - DEFAULT_DIALOG_SIZE[1])
            ],
            size: DEFAULT_DIALOG_SIZE,
            external: false,
            value: {type: "layout", layout: stack}
          }),
          ...(sourceDialogId === undefined
              ? ContainerFn.applyToLayout<LV, LO>(removeOperations)
              : ContainerFn.applyToDialog<LV, LO>(sourceDialogId, DialogItemFn.applyToLayout(removeStack))
          )
        ]);
      }
    }
  }), [containerRef, containerDivRef, setProxyRegion]);

  const {isDragging} = useDragLayer((monitor) => ({
    isDragging: monitor.getItemType() === "legends/dialog-resize" || monitor.getItemType() === "legends/tab"
  }));

  const applyToDialog = useCallback((dialogId: DialogID, action: ApplyAction<DialogItem<LV>, DialogItemOperation<LV, LO>[]>): Promise<Optional<DialogItem<LV>>> => {
    return containerRef.apply(prevValue => {
      const dialog = prevValue.dialogs.find(dialog => dialog.id === dialogId);
      if (!dialog) return [];
      return [{type: "apply-to-dialog", id: dialogId, operations: action(dialog)}];
    }).then(response => response.dialogs.find(dialog => dialog.id === dialogId))
  }, [containerRef]);

  const applyToLayout = useCallback((action: ApplyAction<Layout<LV>, LayoutOperation<LV, LO>[]>): Promise<Layout<LV>> => {
    return containerRef.apply(prevValue => [{type: "apply-to-layout", operations: action(prevValue.layout)}])
      .then(response => response.layout);
  }, [containerRef]);

  const value = useRefValue(containerRef);
  tabRef(resizeDropRef(containerDivRef));
  return (<div ref={containerDivRef} className={"container-view"} style={isDragging ? {pointerEvents: "all"} : undefined}>
    <ErrorBoundary>
      <LayoutContentView dialogId={undefined} value={value.layout} apply={applyToLayout}/>
    </ErrorBoundary>

    <>
      {value.dialogs.map((dialogItem) => dialogItem.external
        ? <ExternalDialogView<LV, LO> key={dialogItem.id} value={dialogItem} applyToDialog={applyToDialog} LayoutContentView={LayoutContentView} />
        : <DialogView<LV, LO> key={dialogItem.id} value={dialogItem} applyToDialog={applyToDialog} StackContentView={StackContentView} StackTabView={StackTabView} Stacks={Stacks} />)}
    </>

    {isOver && proxyRegion && <ProxyRegionViewer key="proxy" value={proxyRegion}/>}
  </div>);
}
