import {Dnd5eInventoryItem} from "#common/legends/index.ts";
import {generateDnd5eItemID} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-item-i-d.ts";
import {copyDnd5eResource} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/copy-dnd-5e-resource.ts";
import {copyDnd5eAction} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action.ts";
import {copyDnd5eItemEffect} from "#common/legends/asset/sheet/dnd-5e/character/item/effect/copy-dnd-5e-item-effect.ts";

export function copyDnd5eItem(value: Dnd5eInventoryItem): Dnd5eInventoryItem {
  return ({
    ...value,
    itemID: generateDnd5eItemID(),
    actions: value.actions.map(copyDnd5eAction),
    effects: value.effects.map(copyDnd5eItemEffect),
    resources: value.resources.map(copyDnd5eResource)
  });
}