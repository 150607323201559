import {
  FileReference,
  ConstantOperation,
  constantType,
  Encrypted,
  ObjectType,
  Optional,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {NodeId, UserID} from "#common/legends/index.ts";
import {DiceExpression} from "#common/dice/index.ts";
import {TextMessageContent} from "./text-message-content.ts";
import {RollRequests, RollResults} from "#common/qlab/message/roll-request/index.ts";

export type TextMessage = {
  userID: UserID;
  nodeID: Optional<NodeId>,
  icon: FileReference,
  content: Encrypted<TextMessageContent>;
  rollRequests: Encrypted<RollRequests>;
  rollResults: Optional<Encrypted<RollResults>>;
};

export type TextMessageOperation =
  | {type: "update-user-i-d", operations: ConstantOperation[]}
  | {type: "update-node-i-d", operations: ConstantOperation[]}
  | {type: "update-icon", operations: ConstantOperation[]}
  | {type: "update-sheet-i-d", operations: ConstantOperation[]}
  | {type: "update-content", operations: ConstantOperation[]}
  | {type: "update-roll-requests", operations: ConstantOperation[]}
  | {type: "update-roll-results", operations: ValueOperation<Optional<Encrypted<RollResults>>, ConstantOperation>[]}
  ;

export const textMessageType: Type<TextMessage, TextMessageOperation> = new ObjectType({
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  content: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType)
});

export const TextMessageFn = {
  escapeDiceExpression: (diceExpression: DiceExpression) => {
    return diceExpression
      .replaceAll("\\", "\\\\")
      .replaceAll("[", "\\[").replaceAll("]", "\\]")
  }
};
