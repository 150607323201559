import React from "react";
import {Button, InputNumber} from "#lib/components/index.ts";
import {NumberOperation} from "common/types/index.ts";
import {Dnd5eAttribute} from "common/legends/index.ts";
import {FaDiceD20} from "react-icons/fa6";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {useRollAbilityCheck} from "./dnd-5e-action/use-roll-ability-check.ts";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Dnd5eSheetFn} from "common/legends/asset/sheet/dnd-5e/dnd-5e-sheet-helper.ts";
import {MutableRef} from "common/ref";

export type AttributeFieldProps = {
  attribute: Dnd5eAttribute;
  value: MutableRef<number, NumberOperation[]>;
};

export function AttributeField({attribute, value}: AttributeFieldProps) {
  const rollAbilityCheck = useRollAbilityCheck(useSelectedNodeID(), useSheetSignal(useSheetReference()), attribute);
  const valueQuery = useObservableLoader(value.observe);
  const modifier = Dnd5eSheetFn.getModifier(valueQuery.data !== undefined ? valueQuery.data : 10);
  return (<label className="relative w-16 flex flex-col items-center border border-zinc-800/40 bg-zinc-900/40 focus-within:border-blue-600/50 mx-2 rounded-lg overflow-hidden pointer-events-auto backdrop-blur-sm">
    <span className="pt-1.5 px-2 font-bold text-xs">{attribute.toUpperCase()}</span>
    <InputNumber title={`{${attribute.toUpperCase()}_SCORE}`} className="text-center py-0" value={value} />
    <Button size="small" className="flex flex-row gap-1 w-full h-8 px-1" onClick={ev => rollAbilityCheck(ev.shiftKey, ev.ctrlKey)}>
      <span title={`{${attribute.toUpperCase()}}`}>{modifier >= 0 ? `+${modifier}` : modifier}</span>
      <FaDiceD20 />
    </Button>
  </label>);
}
