import {useRenderingContext} from "./rendering-context.ts";
import {useEffect, useMemo} from "react";

export function useArrayBuffer(data: BufferSource | null): WebGLBuffer {
  const context = useRenderingContext();
  const buffer = useMemo((): WebGLBuffer => {
    const buffer = context.createBuffer();
    if (buffer === null) throw new Error("Cannot create buffer.");
    return buffer;
  }, [context]);

  useMemo(() => {
    context.bindBuffer(WebGL2RenderingContext.ARRAY_BUFFER, buffer);
    context.bufferData(WebGL2RenderingContext.ARRAY_BUFFER, data, WebGL2RenderingContext.STATIC_DRAW);
    context.bindBuffer(WebGL2RenderingContext.ARRAY_BUFFER, null);
  }, [context, buffer, data]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteBuffer(buffer);
    }
  }, [context, buffer])

  return buffer;
}
