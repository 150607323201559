import {ExternalWindow} from "#lib/container/react/external-window/external-window.tsx";
import React, {useCallback} from "react";
import {ApplyAction, Optional} from "common/types/index.ts";
import {DialogID, Layout, LayoutOperation, StackOperation} from "#lib/container/index.ts";
import {DialogItem, DialogItemOperation} from "#lib/container/modal/dialog/dialog-item.ts";
import {LayoutContentView} from "#lib/container/react/layout/layout-content-view.ts";
import {useApplyToContainer} from "#lib/container/react/container-context.ts";

export type DialogViewProps<LV, LO> = {
  value: DialogItem<LV>;
  applyToDialog: (dialogId: DialogID, action: ApplyAction<DialogItem<LV>, DialogItemOperation<LV, LO>[]>) => Promise<Optional<DialogItem<LV>>>;
  LayoutContentView: LayoutContentView<LV, LO>;
};

export function ExternalDialogView<LV, LO>({value, applyToDialog, LayoutContentView}: DialogViewProps<LV, LO>) {
  const apply = useApplyToContainer();
  const applyToLayout = useCallback((fn: ApplyAction<Layout<LV>, LayoutOperation<LV, LO>[]>) => {
    return applyToDialog(value.id, prevValue => {
      if (prevValue.value.type !== "layout") return [];
      return [{type: "apply-to-layout", operations: fn(prevValue.value.layout).flatMap((op): StackOperation<LV, LO>[] => op.type === "apply-to-stack" ? op.operations : [])}];
    }).then(prev => {
      if (prev === undefined || prev.value.type !== "layout") return undefined;
      return prev.value.layout;
    })
  }, [value.id, applyToDialog]);

  const onResize = useCallback((width: number, height: number) => {
    applyToDialog(value.id, (prev) => [{
      type: "move",
      position: prev.position,
      size: [width, height]
    }]);
  }, [applyToDialog, value.id]);
  const onMove = useCallback((left: number, top: number) => {
    applyToDialog(value.id, (prev) => [{
      type: "move",
      position: [left, top],
      size: prev.size
    }]);
  }, [applyToDialog, value.id]);
  const onClose = useCallback(() => apply(_ => [{type: "destroy-dialog", id: value.id}]), [apply, value.id]);
  return <ExternalWindow target={value.id} position={value.position} size={value.size} onResize={onResize} onMove={onMove} onClose={onClose}>
    <div className="external-window h-[100vh]">
      <LayoutContentView dialogId={value.id} value={value.value.layout} apply={applyToLayout}/>
    </div>
  </ExternalWindow>
}