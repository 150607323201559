import {TagSize, TagViewer} from "./tag-viewer.tsx";
import React from "react";
import {Tag} from "common/types/index.ts";
import {twMerge} from "tailwind-merge";

export type TagListViewerProps = {
  size?: TagSize;
  value: Tag[];
  onClose?: (index: number) => void;
};

export function TagListViewer({value, size, onClose}: TagListViewerProps) {
  return (<>
    {value.length > 0 && <div className={twMerge("flex flex-wrap gap-1")}>
      {value.map((tag, index) => <TagViewer key={tag.id} value={tag} size={size} onClose={onClose ? () => onClose(index) : undefined} />)}
    </div>}
  </>);
}
