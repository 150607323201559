import {FaPlus} from "react-icons/fa";
import {AssetID, AssetSignals, Token, TokenID} from "common/legends/index.ts";
import {Button, ButtonBar, Spacer} from "#lib/components/index.ts";
import {useMemo} from "react";
import {useCreateToken} from "../../viewport/character";
import {EditorAssetOutlinerToken} from "./editor-asset-outliner-token.tsx";
import {InputList} from "#lib/components/list/input-list.tsx";
import {useAsset} from "../../common/character/use-asset.ts";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";

export function AssetTokenSelector({assetID, navigateToAssetToken}: {
  assetID: AssetID;
  navigateToAssetToken: (assetID: AssetID, tokenID: TokenID) => void;
}) {
  const assetRef = useSuspendPresent(useAsset(assetID));
  const {tokens} = useMemo(() => AssetSignals(assetRef), [assetRef]);
  const createToken = useCreateToken(assetRef);
  const onCreateHandler = () => {
    const token = Token.DEFAULT();
    createToken(token).catch(console.error);
    navigateToAssetToken(assetID, token.tokenID);
  };

  return (<div className="shrink-0 flex flex-col gap-1">
    <ButtonBar>
      <Spacer/>
      <Button onClick={onCreateHandler}><FaPlus /> Add Token</Button>
    </ButtonBar>
    <InputList accept={"legends/token"} items={tokens} itemKey={Token.getTokenID} copy={Token.copyToken} ListItem={(props) => <EditorAssetOutlinerToken {...props} assetID={assetID} navigateToAssetToken={navigateToAssetToken} />} />
  </div>);
}
