import {Dnd5eFeature, Dnd5eFeatureOperation, dnd5eFeatureType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/index.ts";
import {z} from "zod";
import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListType,
  NumberOperation,
  numberType,
  ObjectType,
  Optional,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {
  Dnd5eCharacterClassHitDice,
  Dnd5eCharacterClassHitDiceOperation,
  dnd5eCharacterClassHitDiceType
} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-hit-dice.ts";
import {Dnd5eCharacterClassID, generateDnd5eCharacterClassID} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-i-d.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {Dnd5eSpellSlotProgression} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-spell-slot-progression.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eCharacterClassFeature = z.object({
  level: z.number(),
  feature: Dnd5eFeature
});
export type Dnd5eCharacterClassFeature = z.infer<typeof Dnd5eCharacterClassFeature>;
export const Dnd5eCharacterClassFeatureOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-level"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-feature"), operations: z.array(Dnd5eFeatureOperation)})
]);
export type Dnd5eCharacterClassFeatureOperation = z.infer<typeof Dnd5eCharacterClassFeatureOperation>;
export const dnd5eCharacterClassFeatureType: Type<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation> = new ObjectType({
  level: numberType,
  feature: dnd5eFeatureType
});
export function Dnd5eCharacterClassFeatureSignals(value: MutableRef<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation[]>) {
  return ({
    feature: PropertyRef<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation, Dnd5eFeature, Dnd5eFeatureOperation>(
      value => value.feature,
      operations => [{type: "update-feature", operations}]
    )(value)
  });
}


export const Dnd5eCharacterClass = z.object({
  id: Dnd5eCharacterClassID,
  "class": z.string(),
  "subclass": z.string(),
  hitDice: Dnd5eCharacterClassHitDice,
  level: z.number(),
  features: z.array(Dnd5eCharacterClassFeature),
  spellSlotProgression: z.optional(Dnd5eSpellSlotProgression)
});
export type Dnd5eCharacterClass = z.infer<typeof Dnd5eCharacterClass>;

export type Dnd5eCharacterClassOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-class", operations: StringOperation[]}
  | {type: "update-subclass", operations: StringOperation[]}
  | {type: "update-level", operations: NumberOperation[]}
  | {type: "update-features", operations: ListOperation<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>[]}
  | {type: "update-hit-dice", operations: Dnd5eCharacterClassHitDiceOperation[]}
  | {type: "update-spell-slot-progression", operations: ValueOperation<Optional<Dnd5eSpellSlotProgression>, ConstantOperation>[]}
  ;

export const dnd5eCharacterClassType: Type<Dnd5eCharacterClass, Dnd5eCharacterClassOperation> = new ObjectType({
  id: constantType,
  "class": stringType,
  "subclass": stringType,
  level: numberType,
  features: new ListType(dnd5eCharacterClassFeatureType),
  hitDice: dnd5eCharacterClassHitDiceType,
  spellSlotProgression: new ValueType(constantType)
}, (value: any) => {
  if (value.id === undefined) value.id = generateDnd5eCharacterClassID();
  if (value.hitDice === undefined) value.hitDice = 8;
  if (typeof value.hitDice === "number") value.hitDice = {current: value.level, diceFace: value.hitDice};
  for (let i = 0; i < value.features.length; i ++) {
    const feature = value.features[i];
    if (feature.level === undefined) {
      value.features[i] = {level: 1, feature};
    }
  }
  return value;
});

export function Dnd5eCharacterClassSignal(signal: MutableRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation[]>) {
  return {
    "id": PropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, Dnd5eCharacterClassID, ConstantOperation>(
      value => value.id,
      operations => [{type: "update-id", operations}]
    )(signal),
    "class": PropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, string, StringOperation>(
      value => value.class,
      operations => [{type: "update-class", operations}]
    )(signal),
    "subclass": PropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, string, StringOperation>(
      value => value.subclass,
      operations => [{type: "update-subclass", operations}]
    )(signal),
    level: PropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, number, NumberOperation>(
      value => value.level,
      operations => [{type: "update-level", operations}]
    )(signal),
    features: ListPropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>(
      value => value.features,
      operations => [{type: "update-features", operations}]
    )(signal),
    hitDice: PropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation>(
      value => value.hitDice,
      operations => [{type: "update-hit-dice", operations}]
    )(signal),
    spellSlotProgression: ValuePropertyRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation, Optional<Dnd5eSpellSlotProgression>, ConstantOperation>(
      value => value.spellSlotProgression,
      operations => [{type: "update-spell-slot-progression", operations}]
    )(signal)
  };
}

export const Dnd5eCharacterClassFn = {
  getClassID: (value: Dnd5eCharacterClass): Dnd5eCharacterClassID => value.id,
  toLabel: (value: Dnd5eCharacterClass): string => {
    return (value.subclass === undefined || value.subclass.length === 0)
      ? value.class
      : `${value.class} (${value.subclass})`;
  }
}
