import {z} from "zod";

export const Dnd5eCondition = z.union([
  z.literal("blinded"),
  z.literal("charmed"),
  z.literal("deafened"),
  z.literal("frightened"),
  z.literal("grappled"),
  z.literal("incapacitated"),
  z.literal("invisible"),
  z.literal("paralyzed"),
  z.literal("petrified"),
  z.literal("poisoned"),
  z.literal("prone"),
  z.literal("restrained"),
  z.literal("stunned"),
  z.literal("unconscious")
]);
export type Dnd5eCondition = z.infer<typeof Dnd5eCondition>;
export const DND_5E_CONDITIONS = [
  "blinded",
  "charmed",
  "deafened",
  "frightened",
  "grappled",
  "incapacitated",
  "invisible",
  "paralyzed",
  "petrified",
  "poisoned",
  "prone",
  "restrained",
  "stunned",
  "unconscious"
] satisfies readonly Dnd5eCondition[];
