import {z, ZodType} from "zod";
import {PropertyRef} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

type PutMapOperation<Key, Item> = {
  type: 'put';
  key: Key;
  item: Item;
};

type MoveMapOperation<Key> = {
  type: 'move';
  fromKey: Key;
  toKey: Key;
};

type DeleteMapOperation<Key, Item> = {
  type: 'delete';
  key: Key;
  item: Item
};

type ApplyMapOperation<Key, ItemOperation> = {
  type: 'apply';
  key: Key;
  operations: ItemOperation[];
};

export type MapOperation<Key, Item, ItemOperation> =
  | PutMapOperation<Key, Item>
  | MoveMapOperation<Key>
  | DeleteMapOperation<Key, Item>
  | ApplyMapOperation<Key, ItemOperation>
  ;
export function MapOperation<Key, Item, ItemOperation>(
  keyType: ZodType<Key>,
  itemType: ZodType<Item>,
  operationType: ZodType<ItemOperation>
) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("put"), key: keyType, item: itemType}),
    z.object({type: z.literal("move"), fromKey: keyType, toKey: keyType}),
    z.object({type: z.literal("delete"), key: keyType, item: itemType}),
    z.object({type: z.literal("apply"), key: keyType, operations: z.array(operationType)}),
  ])
}

export const MapFn = {
  apply: <Key, Item, ItemOperation>(key: Key, operations: ItemOperation[]): MapOperation<Key, Item, ItemOperation>[] => {
    if (operations.length === 0) return [];
    return [{type: "apply", key, operations}];
  },
  delete: <Key, Item>(key: Key, item: Item): MapOperation<Key, Item, any>[] => [{type: "delete", key, item}],
  put: <Key, Item>(key: Key, item: Item): MapOperation<Key, Item, any>[] => [{type: "put", key, item}]
};

export type MapRef<K extends string, V, O> = MutableRef<{ [key in K]?: V }, MapOperation<K, V, O>[]>;

export function MapPropertyRef<AV, AO, K extends string, BV, BO>(
  valueFn: (value: AV) => Partial<Record<K, BV>>,
  updateFn: (operations: MapOperation<K, BV, BO>[]) => AO[]
): (signal: MutableRef<AV, AO[]>) => MapRef<K, BV, BO> {
  return (signal: MutableRef<AV, AO[]>): MapRef<K, BV, BO> =>
    PropertyRef<AV, AO, Partial<Record<K, BV>>, MapOperation<K, BV, BO>>(valueFn, updateFn)(signal);
}

