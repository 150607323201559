import {QLabResourceID, ResourceType, ResourceTypes, ResourceValue} from "common/qlab/index.ts";
import {ExtractValue, ValueFn} from "common/types/index.ts";
import {useCallback} from "react";
import {useInstance} from "../../q-lab-instance-context.ts";
import {useGameReference} from "../../../../../routes/game/index.ts";

export function useCreateResource(): <Type extends ResourceType, Value = ExtractValue<ResourceTypes[Type]>>(type: Type, resourceID: QLabResourceID, data: Value) => Promise<void> {
  const {storeId} = useGameReference();
  const instance = useInstance();
  return useCallback(<Type extends ResourceType, Value = ExtractValue<ResourceTypes[Type]>>(type: Type, resourceId: QLabResourceID, data: Value): Promise<void> => {
    return instance.applyToResource(storeId, resourceId, _prev => ValueFn.set(
      undefined,
      {type, data} as unknown as ResourceValue
    )).then();
  }, [instance, storeId]);
}
