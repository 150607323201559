import {ListOperation} from "./list-operation.ts";
import {applyAll, transformAll, Type} from "../../type/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";

export class ListType<Item, ItemOperation> implements Type<Item[], ListOperation<Item, ItemOperation>> {

  constructor(private itemType: Type<Item, ItemOperation>) {
  }

  apply = (value: Item[], operation: ListOperation<Item, ItemOperation>): Item[] => {
    switch (operation.type) {
      case "insert": return (
        [
        ...value.slice(0, operation.index),
        operation.item,
        ...value.slice(operation.index)
      ]);
      case "apply": try {return ([
        ...value.slice(0, operation.index),
        operation.operations.reduce(this.itemType.apply, value[operation.index]),
        ...value.slice(operation.index + 1)
      ]);} catch (e:any) {
        throw new QLabError(e.message, e.path ? [operation.index, ...e.path] : [operation.index]);
      }
      case "delete": return ([
        ...value.slice(0, operation.index),
        ...value.slice(operation.index + 1)
      ]);
      case "set": return ([
        ...value.slice(0, operation.index),
        operation.next,
        ...value.slice(operation.index + 1)
      ]);
      case "move": {
        if (operation.fromIndex === operation.toIndex) return value;
        const array = [
          ...value.slice(0, operation.fromIndex),
          ...value.slice(operation.fromIndex + 1)
        ];
        array.splice(operation.toIndex, 0, value[operation.fromIndex]);
        return array;
      }
    }
    throw new Error(`Unsupported operation ${JSON.stringify(operation)} on ${JSON.stringify(value)}`);
  }

  invert = (operation: ListOperation<Item, ItemOperation>): ListOperation<Item, ItemOperation>[] => {
    switch (operation.type) {
      case "insert": return [{type: "delete", index: operation.index, item: operation.item}];
      case "delete": return [{type: "insert", index: operation.index, item: operation.item}];
      case "move": return [{type: "move", fromIndex: operation.toIndex, toIndex: operation.fromIndex}];
      case "set": return [{type: "set", index: operation.index, prev: operation.next, next: operation.prev}]
      case "apply": return [{type: "apply", index: operation.index, operations: operation.operations.slice().reverse().flatMap(this.itemType.invert)}];
      default: return [];
    }
  }

  transform = (leftOperation: ListOperation<Item, ItemOperation>, topOperation: ListOperation<Item, ItemOperation>, tieBreaker: boolean): ListOperation<Item, ItemOperation>[] => {
    switch (leftOperation.type) {
      case "insert":
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker && leftOperation.index >= topOperation.index) {
              return [{
                type: "insert",
                index: leftOperation.index + 1,
                item: leftOperation.item
              }];
            } else {
              return [leftOperation];
            }
          }
          case "set": {
            return [leftOperation];
          }
          case "delete": {
            if (!tieBreaker && leftOperation.index > topOperation.index) {
              return [{
                type: "insert",
                index: leftOperation.index - 1,
                item: leftOperation.item
              }];
            } else {
              return [leftOperation]
            }
          }
          case "apply": return [leftOperation];
          case "move": {
            if (!tieBreaker && topOperation.fromIndex >= leftOperation.index && topOperation.toIndex < leftOperation.index) {
              return [{
                type: "insert",
                index: leftOperation.index + 1,
                item: leftOperation.item
              }];
            } else if (!tieBreaker && topOperation.fromIndex < leftOperation.index && topOperation.toIndex >= leftOperation.index) {
              return [{
                type: "insert",
                index: leftOperation.index - 1,
                item: leftOperation.item
              }];
            } else {
              return [leftOperation];
            }
          }
        }
        break;
      case "delete":
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker && leftOperation.index >= topOperation.index) {
              return [{
                type: "delete",
                index: leftOperation.index + 1,
                item: leftOperation.item
              }];
            } else {
              return [leftOperation];
            }
          }
          case "set": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [{type: "delete", index: leftOperation.index, item: topOperation.next}];
            } else {
              return [leftOperation];
            }
          }
          case "delete": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [];
            } else if (!tieBreaker && leftOperation.index > topOperation.index) {
              return [{
                type: "delete",
                index: leftOperation.index - 1,
                item: leftOperation.item
              }];
            } else {
              return [leftOperation];
            }
          }
          case "apply": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [{
                type: "delete",
                index: leftOperation.index,
                item: topOperation.operations.reduce((value, operation) => this.itemType.apply(value, operation), leftOperation.item)
              }];
            } else {
              return [leftOperation]
            }
          }
          case "move": {
            if (!tieBreaker) {
              if (leftOperation.index === topOperation.fromIndex) {
                return [{
                  type: "delete",
                  index: topOperation.toIndex,
                  item: leftOperation.item
                }];
              } else if (leftOperation.index > topOperation.fromIndex && leftOperation.index <= topOperation.toIndex) {
                return [{
                  type: "delete",
                  index: leftOperation.index - 1,
                  item: leftOperation.item
                }];
              } else if (leftOperation.index < topOperation.fromIndex && leftOperation.index >= topOperation.toIndex) {
                return [{
                  type: "delete",
                  index: leftOperation.index + 1,
                  item: leftOperation.item
                }];
              } else {
                return [leftOperation]
              }
            } else {
              return [leftOperation];
            }
          }
        }
        break;
      case "apply":
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker && leftOperation.index >= topOperation.index) {
              return [{
                type: "apply",
                index: leftOperation.index + 1,
                operations: leftOperation.operations
              }];
            } else {
              return [leftOperation];
            }
          }
          case "set": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [];
            } else {
              return [leftOperation];
            }
          }
          case "delete": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [];
            } else if (!tieBreaker && leftOperation.index > topOperation.index) {
              return [{
                type: "apply",
                index: leftOperation.index - 1,
                operations: leftOperation.operations
              }];
            } else {
              return [leftOperation];
            }
          }
          case "apply": {
            if (leftOperation.index === topOperation.index) {
              return [{
                type: "apply",
                index: leftOperation.index,
                operations: transformAll(this.itemType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
              }];
            } else {
              return [leftOperation];
            }
          }
          case "move": {
            if (!tieBreaker && leftOperation.index === topOperation.fromIndex) {
              return [{
                type: "apply",
                index: topOperation.toIndex,
                operations: leftOperation.operations
              }];
            } else if (!tieBreaker && leftOperation.index > topOperation.fromIndex && leftOperation.index <= topOperation.toIndex) {
              return [{
                type: "apply",
                index: leftOperation.index - 1,
                operations: leftOperation.operations
              }];
            } else if (!tieBreaker && leftOperation.index < topOperation.fromIndex && leftOperation.index >= topOperation.toIndex) {
              return [{
                type: "apply",
                index: leftOperation.index + 1,
                operations: leftOperation.operations
              }];
            } else {
              return [leftOperation]
            }
          }
        }
        break;
      case "move":
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker) {
              return [{
                type: "move",
                fromIndex: leftOperation.fromIndex >= topOperation.index ? leftOperation.fromIndex + 1 : leftOperation.fromIndex,
                toIndex: leftOperation.toIndex >= topOperation.index ? leftOperation.toIndex + 1 : leftOperation.toIndex
              }];
            } else {
              return [leftOperation];
            }
          }
          case "set": {
            return [leftOperation];
          }
          case "delete": {
            if (!tieBreaker) {
              if (leftOperation.fromIndex === topOperation.index) {
                return [];
              } else if (leftOperation.toIndex === topOperation.index) {
                if (leftOperation.fromIndex > topOperation.index) {
                  return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                } else {
                  return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex - 1}];
                }
              } else {
                return [{
                  type: "move",
                  fromIndex: leftOperation.fromIndex > topOperation.index ? leftOperation.fromIndex - 1 : leftOperation.fromIndex,
                  toIndex: leftOperation.toIndex >= topOperation.index ? leftOperation.toIndex - 1 : leftOperation.toIndex
                }];
              }
            } else {
              return [leftOperation];
            }
          }
          case "apply": {
            return [leftOperation];
          }
          case "move": {
            if (!tieBreaker) {
              if (leftOperation.fromIndex === topOperation.fromIndex) {
                if (leftOperation.fromIndex === topOperation.toIndex) {
                  return [leftOperation];
                }
                else if (leftOperation.fromIndex > topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implied
                    return [leftOperation];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implied
                    return [{type: "move", fromIndex: topOperation.toIndex, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    if (leftOperation.toIndex === topOperation.toIndex) {
                      return [];
                    } else if (leftOperation.toIndex !== topOperation.toIndex) {
                      return [{type: "move", fromIndex: topOperation.toIndex, toIndex: leftOperation.toIndex}]
                    }
                  }
                }
                else if (leftOperation.fromIndex < topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [leftOperation];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    if (leftOperation.toIndex === topOperation.toIndex) {
                      return [];
                    }
                    else if (leftOperation.toIndex !== topOperation.toIndex) {
                      return [{type: "move", fromIndex: topOperation.toIndex, toIndex: leftOperation.toIndex}];
                    }
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [{type: "move", fromIndex: topOperation.toIndex, toIndex: leftOperation.toIndex}];
                  }
                }
              }
              else if (leftOperation.fromIndex > topOperation.fromIndex) {
                if (leftOperation.fromIndex === topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    if (leftOperation.toIndex === topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex}];
                    }
                    else if (leftOperation.toIndex > topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex - 1}];
                    }
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                  }
                }
                else if (leftOperation.fromIndex > topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    if (leftOperation.toIndex > topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex + 1}];
                    }
                    else if (leftOperation.toIndex <= topOperation.toIndex) {
                      return [leftOperation];
                    }
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    if (leftOperation.toIndex > topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex}];
                    }
                    else if (leftOperation.toIndex <= topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex - 1}];
                    }
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex + 1}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [leftOperation];
                    }
                  }
                }
                else if (leftOperation.fromIndex < topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex - 1}];
                    }
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    // (leftOperation.toIndex < topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex - 1, toIndex: leftOperation.toIndex}];
                  }
                }
              }
              else if (leftOperation.fromIndex < topOperation.fromIndex) {
                if (leftOperation.fromIndex === topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    if (leftOperation.toIndex === topOperation.toIndex) {
                      return [leftOperation];
                    }
                    else if (leftOperation.toIndex > topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex + 1}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                    }
                  }
                }
                else if (leftOperation.fromIndex > topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implied
                    return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    // (leftOperation.toIndex > topOperation.toIndex) implies
                    return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex + 1}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex + 1, toIndex: leftOperation.toIndex}];
                    }
                  }
                }
                else if (leftOperation.fromIndex < topOperation.toIndex) {
                  if (leftOperation.toIndex === topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [leftOperation];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex - 1}];
                    }
                  }
                  else if (leftOperation.toIndex > topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [leftOperation];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex - 1}];
                    }
                  }
                  else if (leftOperation.toIndex < topOperation.fromIndex) {
                    if (leftOperation.toIndex >= topOperation.toIndex) {
                      return [{type: "move", fromIndex: leftOperation.fromIndex, toIndex: leftOperation.toIndex + 1}];
                    }
                    else if (leftOperation.toIndex < topOperation.toIndex) {
                      return [leftOperation];
                    }
                  }
                }
              }
            } else {
              return [leftOperation];
            }
          }
        }
        break;
      case "set":
        switch (topOperation.type) {
          case "insert": {
            if (!tieBreaker && topOperation.index <= leftOperation.index) {
              return [{type: "set", index: leftOperation.index + 1, prev: leftOperation.prev, next: leftOperation.next}]
            } else {
              return [leftOperation];
            }
          }
          case "delete": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [];
            } else if (!tieBreaker && leftOperation.index > topOperation.index) {
              return [{type: "set", index: leftOperation.index - 1, prev: leftOperation.prev, next: leftOperation.next}];
            } else {
              return [leftOperation];
            }
          }
          case "apply": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [{type: "set", index: leftOperation.index, prev: applyAll(this.itemType, leftOperation.prev, topOperation.operations), next: leftOperation.next}];
            } else {
              return [leftOperation];
            }
          }
          case "set": {
            if (!tieBreaker && leftOperation.index === topOperation.index) {
              return [{type: "set", index: leftOperation.index, prev: topOperation.next, next: leftOperation.next}];
            } else {
              return [leftOperation];
            }
          }
          case "move": {
            if (!tieBreaker) {
              if (leftOperation.index === topOperation.fromIndex) {
                return [{type: "set", index: topOperation.toIndex, prev: leftOperation.prev, next: leftOperation.next}];
              } else if (topOperation.fromIndex < leftOperation.index && topOperation.toIndex < leftOperation.index) { // TODO: < or <= on toIndex?
                return [leftOperation];
              } else if (topOperation.fromIndex < leftOperation.index && topOperation.toIndex >= leftOperation.index) {
                return [{
                  type: "set",
                  index: leftOperation.index - 1,
                  prev: leftOperation.prev,
                  next: leftOperation.next
                }];
              } else if (topOperation.fromIndex > leftOperation.index && topOperation.toIndex > leftOperation.index) { // TODO: > or >= on toIndex
                return [leftOperation];
              } else if (topOperation.fromIndex > leftOperation.index && topOperation.toIndex <= leftOperation.index) {
                return [{
                  type: "set",
                  index: leftOperation.index + 1,
                  prev: leftOperation.prev,
                  next: leftOperation.next
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          }
        }
        break;
    }
    throw new Error("Unsupported Operation: " + JSON.stringify(leftOperation) + ", " + JSON.stringify(topOperation));
  }
  migrateValue = (value: any): Item[] => {
    if (value === undefined) return [];
    return value.map(this.itemType.migrateValue);
  }
  migrateOperation = (operation: any): ListOperation<Item, ItemOperation>[] => {
    if (operation.type === "insert") {
      return [{type: "insert", index: operation.index, item: this.itemType.migrateValue(operation.item)}];
    } else if (operation.type === "delete") {
      return [{type: "delete", index: operation.index, item: this.itemType.migrateValue(operation.item)}];
    } else if (operation.type === "move") {
      return [{type: "move", fromIndex: operation.fromIndex, toIndex: operation.toIndex}];
    } else if (operation.type === "apply") {
      return [{type: "apply", index: operation.index, operations: operation.operations.flatMap(this.itemType.migrateOperation)}];
    } else if (operation.type === "set") {
      return [{type: "set", index: operation.index, prev: this.itemType.migrateValue(operation.prev), next: this.itemType.migrateValue(operation.next)}];
    } else {
      throw new Error("Unsupported Operation: " + JSON.stringify(operation));
    }
  }

  validate = (value: any): ValidationError[] => {
    if (Array.isArray(value)) {
      return value.flatMap((item, index) => this.itemType.validate(item).map((error) => ({
        path: [index, ...error.path],
        data: error.data
      })));
    }
    return [{path: [], data: {message: "Invalid type. Expected list.", value}}];
  }
}
