import {FaArrowUpRightFromSquare} from "react-icons/fa6";

export type DialogExpandHandleProps = {
  onClose: () => void;
};

export function DialogExpandHandle({onClose}: DialogExpandHandleProps) {
  return (<div className="dialog-top-button dialog-expand-handle" onClick={onClose} title="Open in Window">
    <FaArrowUpRightFromSquare size={12} />
  </div>);
}
