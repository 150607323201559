import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {Ref, useMemo} from "react";
import {FaTrash} from "react-icons/fa";
import {InteractionActionFocus, InteractionActionFocusFn, InteractionActionFocusOperation} from "common/legends/node/interaction/action/interaction-action-focus.ts";
import {NodeIDField} from "./node-i-d-field.tsx";
import {Node} from "common/legends/node/index.ts";
import {NodePath} from "../../../../../viewport/common/node/model/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {MutableRef} from "common/ref";

function isFocusElement(node: Node, path: NodePath): boolean {
  if (path.type === "asset") return false;
  return node.type === "token";
}

export function InputInteractionActionFocus({dragHandlerRef, value, remove}: {
  value: MutableRef<InteractionActionFocus, InteractionActionFocusOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  remove: () => void;
}) {
  const {nodeIDRef} = useMemo(() => InteractionActionFocusFn.expand(value), [value]);
  const [expanded, toggleExpanded] = useToggle(false);

  return <>
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1">
        <InputGroupLabel>Focus</InputGroupLabel>
      </InputGroup>
      <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>
    </ExpandableLabel>
    {expanded && <Fieldset>
      <NodeIDField valueRef={nodeIDRef} predicate={isFocusElement} />
    </Fieldset>}
  </>;
}