export const DeflateCodec = {
  encode: async (data: Uint8Array): Promise<Uint8Array> => {
    //@ts-ignore
    const cs = new CompressionStream('deflate');
    const writer = cs.writable.getWriter();

    writer.write(data);
    writer.close();

    const compressedStream = cs.readable;
    const compressedChunks = [];
    const reader = compressedStream.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      compressedChunks.push(value);
    }
    const compressedData = new Uint8Array(compressedChunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of compressedChunks) {
      compressedData.set(chunk, offset);
      offset += chunk.length;
    }
    return compressedData;
  },
  decode: async (compressedData: Uint8Array): Promise<Uint8Array> => {
    //@ts-ignore
    const ds = new DecompressionStream('deflate');
    const writer = ds.writable.getWriter();
    writer.write(compressedData);
    writer.close();
    const decompressedStream = ds.readable;
    const decompressedChunks = [];
    const reader = decompressedStream.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      decompressedChunks.push(value);
    }
    const decompressedData = new Uint8Array(decompressedChunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of decompressedChunks) {
      decompressedData.set(chunk, offset);
      offset += chunk.length;
    }
    return decompressedData;
  }
};
