import {QLabResource, QLabResourceID, qlabResourceType, QLabStore, QLabStoreID, qlabStoreType} from "common/qlab/index.ts";
import {UserID} from "common/legends/index.ts"
import {DefaultIndexedDB, IndexedDB} from "../indexed-d-b/index.ts";

const DEFAULT_STORE: QLabStore = {value: undefined, revision: 0};
const DEFAULT_RESOURCE: QLabResource = {value: undefined, revision: 0};

export class QLabServiceDatastore {
  private datastore: IndexedDB;
  constructor(userId: UserID) {
    this.datastore = new DefaultIndexedDB(`SERVICE-${userId}`, 1, (db, oldVersion) => {
      if (oldVersion < 1) {
        db.createObjectStore("STORE");
        db.createObjectStore("RESOURCE");
      }
    });
  }
  async getStore(storeID: QLabStoreID): Promise<QLabStore> {
    return this.datastore.get("STORE", [storeID], DEFAULT_STORE).then(qlabStoreType.migrateValue);
  }
  putStore(storeID: QLabStoreID, store: QLabStore): Promise<void> {
    return this.datastore.put("STORE", [storeID], store);
  }

  getResourceIds(storeID: QLabStoreID): Promise<QLabResourceID[]> {
    return this.datastore.getAllKeys("RESOURCE", IDBKeyRange.lowerBound([storeID]))
      .then(keys => {
        return keys
          .filter(key => key[0] === storeID)
          .map(key => key[1] as QLabResourceID)
      });
  }
  getResource(storeID: QLabStoreID, resourceID: QLabResourceID): Promise<QLabResource> {
    return this.datastore.get("RESOURCE", [storeID, resourceID], DEFAULT_RESOURCE).then(qlabResourceType.migrateValue);
  }
  putResource(storeID: QLabStoreID, resourceID: QLabResourceID, resource: QLabResource): Promise<void> {
    return this.datastore.put("RESOURCE", [storeID, resourceID], resource);
  }
}
