import {EditorState, EditorStateOperation, editorStateType} from "./state/index.ts";
import {QLabStoreID} from "common/qlab/index.ts";
import {Container, DialogIDFn, SplitterId, StackId} from "#lib/container/index.ts";
import {EditorStackOperation, editorStackType, EditorStackValue} from "./editor-stack.ts";
import {ContainerOperation} from "#lib/container/modal/container-operation.ts";
import {pipe} from "common/pipe";
import {observableValue, subscribe, throttle} from "common/observable";
import {MutableRef} from "common/ref";

function defaultContainerState(): Container<EditorStackValue> {
  const scenesStackID = StackId.generate();
  const chatID = StackId.generate();
  const propertiesStackID = StackId.generate();
  const sheetStackID = StackId.generate();
  return ({
    layout: {
      type: "column",
      items: [
        {id: SplitterId.generate(), weight: 3/16, content: {
          type: "row",
          items: [{id: SplitterId.generate(), weight: 1/2, content: {
            type: "stack",
            activeId: scenesStackID,
            items: [
              {id: scenesStackID, content: {type: "scene-navigator", data: {searchTerm: "", expanded: {}}}},
              {id: StackId.generate(), content: {type: "character-navigator", data: {searchTerm: "", expanded: {}, selectedCharacterTreeId: undefined}}}
            ]
          }}, {id: SplitterId.generate(), weight: 1/2, content: {
            type: "stack",
            activeId: propertiesStackID,
            items: [
              {id: propertiesStackID, content: {type: "properties", data: {reference: undefined}}}
            ]
          }}]
        }},
        {id: SplitterId.generate(), weight: 10/16, content: {type: "empty"}},
        {id: SplitterId.generate(), weight: 3/16, content: {
          type: "row",
          items: [{id: SplitterId.generate(), weight: 1, content: {
            type: "stack",
            activeId: chatID,
            items: [
              {id: StackId.generate(), content: {type: "settings", data: {}}},
              {id: chatID, content: {type: "chat", data: {channelReference: undefined}}},
              {id: StackId.generate(), content: {type: "turn-tracker", data: {channelReference: undefined}}}
            ]
          }}]
        }}
      ]
    },
    dialogs: [{
      id: DialogIDFn.generate(),
      position: [720, 40],
      size: [800, 640],
      minimized: false,
      external: false,
      value: {
        type: "layout",
        layout: {
          type: "stack",
          activeId: sheetStackID,
          items: [{
            id: sheetStackID,
            content: {
              type: "sheet",
              data: {
                reference: undefined
              }
            }
          }]
        }
      }
    }]
  });
}

export class EditorController {
  readonly state: MutableRef<EditorState, EditorStateOperation[]>;
  readonly container: MutableRef<Container<EditorStackValue>, ContainerOperation<EditorStackValue, EditorStackOperation>[]>;
  readonly setContainer: (value: Container<EditorStackValue>) => void;
  resetContainer() {
    this.setContainer(defaultContainerState());
  }

  constructor(storeId: QLabStoreID) {
    const initialState = (() => {
      try {
        const initial = localStorage.getItem(`state-${storeId}`);
        if (initial === null) return undefined;
        return editorStateType.migrateValue(JSON.parse(initial));
      } catch (e) {
        return undefined;
      }
    })();

    const [state, applyToState] = observableValue<EditorState, EditorStateOperation[]>(initialState, (value, fn) => fn.reduce(editorStateType.apply, value));
    this.state = new MutableRef({
      value() {return state.value},
      observe: state.observe,
      apply: applyToState
    });

    pipe(
      state.observe,
      throttle(1000),
      subscribe(value => localStorage.setItem(`state-${storeId}`, JSON.stringify(value)))
    );

    const initialContainer = Container.migrateValue(editorStackType, (() => {
      try {
        const initial = localStorage.getItem(`container-${storeId}`);
        if (initial === null) return defaultContainerState();
        return JSON.parse(initial);
      } catch (e) {
        return defaultContainerState();
      }
    })());

    const [containerSignal, applyToContainer, setContainer] = observableValue<Container<EditorStackValue>, ContainerOperation<EditorStackValue, EditorStackOperation>[]>(initialContainer, (value, fn) => {
      return fn.reduce((container, operation) => Container.applyToValue(editorStackType, container, operation), value);
    });
    this.setContainer = setContainer;
    this.container = new MutableRef({
      value() {return containerSignal.value},
      observe: containerSignal.observe,
      apply: applyToContainer
    });

    pipe(
      containerSignal.observe,
      throttle(1000),
      subscribe(value => localStorage.setItem(`container-${storeId}`, JSON.stringify(value)))
    );
  }
}
