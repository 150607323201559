import {MultiType, Tree, TreePath, TreeType, Type} from "../../../types/index.ts";
import {assetFileType, AssetTreeFile, AssetTreeFileOperation} from "./asset-tree-file.ts";
import {assetFolderType, AssetTreeFolder, AssetTreeFolderOperation} from "./asset-tree-folder.ts";
import {AssetTreeId} from "./asset-tree-id.ts";

export type AssetTreeItem =
  | {type: "folder", data: AssetTreeFolder}
  | {type: "asset", data: AssetTreeFile}
  ;
export type AssetTreeItemOperation =
  | {type: "folder", operations: AssetTreeFolderOperation[]}
  | {type: "asset", operations: AssetTreeFileOperation[]}
  ;

export const assetTreeItemType: Type<AssetTreeItem, AssetTreeItemOperation> = new MultiType({
  folder: assetFolderType,
  asset: assetFileType,
}, v => {
  if (v?.type === "character") return ({...v, type: "asset"});
  if (v?.type === "folder") {
    return ({
      ...v,
      data: {
        ...v.data,
        children: v.data.children.map(assetTreeItemType.migrateValue)
      }
    });
  }
  return v;
});
export const assetTreeType: TreeType<AssetTreeItem, AssetTreeItemOperation> = new TreeType(assetTreeItemType);

export const AssetTree = {
  getItem: (values: AssetTreeItem[], path: TreePath): AssetTreeItem | undefined => {
    const [first, ...rest] = path;
    const file = values[first];
    if (path.length === 1) {
      return file;
    } else if (file.type === "folder") {
      return AssetTree.getItem(file.data.children, rest);
    } else {
      throw new Error("Could not find file.");
    }
  },
  getItemPath: (values: AssetTreeItem[], id: AssetTreeId): TreePath | undefined => {
    return Tree.getPath(values, value => value.data.id === id);
  }
};
