import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {Select} from "#lib/components/index.ts";
import {Dnd5eAbilityCheckProficiency, DND_5E_ABILITY_CHECK_PROFICIENCIES} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-ability-check-proficiency.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

export type InputDnd5eAbilityCheckProficiencyProps = {
  readOnly?: boolean;
  value: MutableRef<Dnd5eAbilityCheckProficiency, ValueOperation<Dnd5eAbilityCheckProficiency, ConstantOperation>[]>;
};

export function InputDnd5eAbilityCheckProficiency({value, readOnly}: InputDnd5eAbilityCheckProficiencyProps) {
  const proficiencyValue = useRefValue(value);
  return <Select disabled={readOnly} value={proficiencyValue} onChange={ev => {
    const next = ev.target.value as Dnd5eAbilityCheckProficiency;
    value.apply(prev => ValueFn.set(prev, next));
  }}>
    <option value="untrained"></option>
    {DND_5E_ABILITY_CHECK_PROFICIENCIES.filter(t => t !== "untrained").map(skillProficiency => <option key={skillProficiency} value={skillProficiency}>{skillProficiency} </option>)}
  </Select>
}
