export type Loaded<T> = {
  isSuccess: true;
  isLoading: false;
  data: T;
};
export type Loading = {
  isSuccess: false;
  isLoading: true;
  data: undefined;
};
export type Loader<T> =
  | Loading
  | Loaded<T>
  ;

const LOADING: Loader<any> = {isSuccess: false, isLoading: true, data: undefined};

export const Loader = {
  loaded<T>(data: T): Loader<T> {
    return {isSuccess: true, isLoading: false, data};
  },
  loading<T>(): Loader<T> {
    return LOADING;
  },
  isLoaded<T>(value: Loader<T>): value is Loaded<T> {
    return !value.isLoading;
  },
  isLoading(value: Loader<unknown>): value is Loading {
    return value.isLoading;
  }
}