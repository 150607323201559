// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "MESSAGE", "symbols": ["STR"], "postprocess": d => [d[0]]},
    {"name": "MESSAGE$ebnf$1", "symbols": ["STR"], "postprocess": id},
    {"name": "MESSAGE$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "MESSAGE$ebnf$2$subexpression$1$ebnf$1", "symbols": ["STR"], "postprocess": id},
    {"name": "MESSAGE$ebnf$2$subexpression$1$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "MESSAGE$ebnf$2$subexpression$1", "symbols": ["DICE", "MESSAGE$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "MESSAGE$ebnf$2", "symbols": ["MESSAGE$ebnf$2$subexpression$1"]},
    {"name": "MESSAGE$ebnf$2$subexpression$2$ebnf$1", "symbols": ["STR"], "postprocess": id},
    {"name": "MESSAGE$ebnf$2$subexpression$2$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "MESSAGE$ebnf$2$subexpression$2", "symbols": ["DICE", "MESSAGE$ebnf$2$subexpression$2$ebnf$1"]},
    {"name": "MESSAGE$ebnf$2", "symbols": ["MESSAGE$ebnf$2", "MESSAGE$ebnf$2$subexpression$2"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "MESSAGE", "symbols": ["MESSAGE$ebnf$1", "MESSAGE$ebnf$2"], "postprocess": d => [d[0], ...d[1].flatMap((n: any) => [n[0], n[1]])].filter((n: any) => n !== null)},
    {"name": "DICE$string$1", "symbols": [{"literal":"["}, {"literal":"["}], "postprocess": (d) => d.join('')},
    {"name": "DICE$string$2", "symbols": [{"literal":"]"}, {"literal":"]"}], "postprocess": (d) => d.join('')},
    {"name": "DICE", "symbols": ["DICE$string$1", "STR", "DICE$string$2"], "postprocess": d => ({op: "dice", value: d[1].value })},
    {"name": "STR$ebnf$1", "symbols": ["C"]},
    {"name": "STR$ebnf$1", "symbols": ["STR$ebnf$1", "C"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "STR", "symbols": ["STR$ebnf$1"], "postprocess": d => ({op: "str", value: d[0].join("") })},
    {"name": "C", "symbols": [/[^\\\[\]]/]},
    {"name": "C", "symbols": [{"literal":"\\"}, /[\\\[\]]/], "postprocess": d => d[1]}
  ],
  ParserStart: "MESSAGE",
};

export default grammar;
