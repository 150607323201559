import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation, Dnd5eMaxHPModifierSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-max-h-p-modifier.ts";
import {MutableRef} from "common/ref";

export type Dnd5eMaxHPModifierViewProps = {
  value: MutableRef<Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eMaxHPModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eMaxHPModifierViewProps) {
  const {expression} = useMemo(() => Dnd5eMaxHPModifierSignals(value), [value]);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Hit Points</InputGroupLabel>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupLabel>Expression</InputGroupLabel>
      <InputMathExpression value={expression} />
    </InputGroup>
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}
