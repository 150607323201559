import {combine, distinct, map, Observable} from "common/observable";
import {Optional} from "common/types/index.ts";
import {Dnd5eFeature, Dnd5eSkill, Sheet} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {Dnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eAbilityCheckProficiency} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-ability-check-proficiency.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Ref} from "common/ref";

export function getDnd5eAbilityCheckHasAdvantage(activeModifiers: Dnd5eModifier[], abilityCheck: Dnd5eAbilityCheckType) {
  return activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheck) && m.data.hasAdvantage);
}
export function getDnd5eAbilityCheckHasDisadvantage(activeModifiers: Dnd5eModifier[], abilityCheck: Dnd5eAbilityCheckType) {
  return activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheck) && m.data.hasDisadvantage);
}


export function getDnd5eAbilityCheckProficiency(activeModifiers: Dnd5eModifier[], abilityCheck: Dnd5eAbilityCheckType) {
  const isExpertise = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheck) && m.data.proficiency === "expertise");
  if (isExpertise) return "expertise";
  const isProficient = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(abilityCheck) && m.data.proficiency === "proficient");
  if (isProficient) return "proficient";
  return "untrained";
}

export function dnd5eSheetAbilityCheckProficiency(sheet: Ref<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, skill: Dnd5eSkill): Observable<Dnd5eAbilityCheckProficiency> {
  return pipe(
    combine(sheet.observe, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetActiveModifiers(sheet, globalFeatures)),
    map(activeModifiers => getDnd5eAbilityCheckProficiency(activeModifiers, skill)),
    distinct()
  );
}


export function dnd5eSheetAbilityCheckHasAdvantage(sheet: Ref<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, abilityCheck: Dnd5eAbilityCheckType): Observable<boolean> {
  return pipe(
    combine(sheet.observe, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetActiveModifiers(sheet, globalFeatures)),
    map(activeModifiers => getDnd5eAbilityCheckHasAdvantage(activeModifiers, abilityCheck)),
    distinct()
  );
}

export function dnd5eSheetAbilityCheckHasDisadvantage(sheet: Ref<Optional<Sheet>>, globalFeatures: Observable<Dnd5eFeature[]>, abilityCheck: Dnd5eAbilityCheckType): Observable<boolean> {
  return pipe(
    combine(sheet.observe, globalFeatures),
    map(([sheet, globalFeatures]) => getDnd5eSheetActiveModifiers(sheet, globalFeatures)),
    map(activeModifiers => getDnd5eAbilityCheckHasDisadvantage(activeModifiers, abilityCheck)),
    distinct()
  );
}
