import {Optional, RichText, RichTextDescendant, RichTextElement, RichTextText} from "common/types/index.ts";
import {twMerge} from "tailwind-merge";
import {RollRequests, RollResults} from "common/qlab/message/roll-request/index.ts";

type RichTextDescendantViewProps = {
  value: RichTextDescendant;
  rollRequests: Optional<RollRequests>;
  rollResults: Optional<RollResults>;
};
function RichTextDescendantView({value, rollRequests, rollResults}: RichTextDescendantViewProps) {
  const parse = RichTextElement.safeParse(value);
  if (parse.success) {
    return <p className="min-h-[1.5rem]">
      {parse.data.children.map((child, index) => <RichTextDescendantView key={index} value={child} rollRequests={rollRequests} rollResults={rollResults} />)}
    </p>
  }

  let textNode = RichTextText.safeParse(value);
  if (textNode.success) {
    return <span className={twMerge(
      textNode.data.bold && "font-bold",
      textNode.data.italic && "italic"
    )}>
      {textNode.data.text || " "}
    </span>
  }

  return <></>
}


export type RichTextViewProps = {
  value: RichText;
  rollRequests: Optional<RollRequests>;
  rollResults: Optional<RollResults>;
};
export function RichTextView({value, rollRequests, rollResults}: RichTextViewProps) {
  return <div className="flex flex-col gap-0">
    {value.map((child, index) => <RichTextDescendantView key={index} value={child} rollRequests={rollRequests} rollResults={rollResults} />)}
  </div>
}

