import {MultiType, Tree, TreePath, TreeType, Type} from "../../../types/index.ts";
import {SceneTreeFile, SceneTreeFileOperation, sceneTreeFileType} from "./scene-tree-file.ts";
import {SceneTreeFolder, SceneTreeFolderOperation, sceneFolderType} from "./scene-tree-folder.ts";
import {SceneTreeId} from "./scene-tree-id.ts";

export type SceneTreeItem =
  | {type: "folder", data: SceneTreeFolder}
  | {type: "scene", data: SceneTreeFile}
  ;
export type SceneTreeItemOperation =
  | {type: "folder", operations: SceneTreeFolderOperation[]}
  | {type: "scene", operations: SceneTreeFileOperation[]}
  ;

export const sceneTreeItemType: Type<SceneTreeItem, SceneTreeItemOperation> = new MultiType({
  folder: sceneFolderType,
  scene: sceneTreeFileType,
});
export const sceneTreeType: TreeType<SceneTreeItem, SceneTreeItemOperation> = new TreeType(sceneTreeItemType);

export const SceneTree = {
  getItem: (values: SceneTreeItem[], path: TreePath): SceneTreeItem | undefined => {
    const [first, ...rest] = path;
    const file = values[first];
    if (path.length === 1) {
      return file;
    } else if (file.type === "folder") {
      return SceneTree.getItem(file.data.children, rest);
    } else {
      throw new Error("Could not find file.");
    }
  },
  getItemPath: (values: SceneTreeItem[], id: SceneTreeId): TreePath | undefined => {
    return Tree.getPath(values, value => value.data.id === id);
  }
};
