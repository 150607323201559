import {RollRequestView} from "../roll-request-view.tsx";
import {RollRequest} from "common/qlab/message/index.ts";
import {Loader} from "common/loader";
import {DiceResult} from "common/dice/execute-dice-formula.ts";

export function Dnd5eAttackRollView(props: {
  request: Loader<RollRequest | undefined>,
  result: Loader<DiceResult | undefined>
}) {
  return (
    <div className="py-2 flex flex-col gap-1 items-center justify-center">
      <span className="text-white/80 text-sm italic font-bold">Attack Roll</span>
      <RollRequestView rollType="attack-roll" rollRequest={props.request.isSuccess ? props.request.data : undefined} rollResult={props.result.isSuccess ? props.result.data : undefined}/>
    </div>
  );
}