import {z} from "zod";
import {NodeID} from "common/legends/index.ts";
import {ConstantOperation, constantType, ObjectType, ValueOperation, ValueType} from "common/types/index.ts";
import {WallToolMode, WallToolModeOperation, wallToolModeType} from "./wall-tool-mode.ts";
import {WallToolCreateModeFn} from "./wall-tool-create-mode.ts";

export const WallToolData = z.object({
  nodeID: NodeID,
  mode: z.optional(WallToolMode)
});
export type WallToolData = z.infer<typeof WallToolData>;
export const WallToolDataOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-mode"), operations: z.array(ValueOperation(z.optional(WallToolMode), WallToolModeOperation))})
])
export type WallToolDataOperation = z.infer<typeof WallToolDataOperation>;

export const wallToolType = new ObjectType({
  nodeID: constantType,
  mode: new ValueType(wallToolModeType)
}, (v: any) => {
  if (v.spline) {
    v.mode = {type: "create", data: {spline: v.spline}}
    delete v["spline"];
  }
  return v;
})

export const WallToolFn = {
  DEFAULT: {
    nodeID: "",
    mode: WallToolCreateModeFn.DEFAULT
  } satisfies WallToolData
};

