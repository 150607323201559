import {DiceResult} from "common/dice/index.ts";
import {Dnd5eHealthOperation, Sheet, SheetInterface, SheetOperation} from "common/legends/index.ts";
import {NumberFn, Optional} from "common/types/index.ts";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Dnd5eModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {useGlobalFeatures} from "../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MutableRef} from "common/ref";

export function useApplyDamageToSelectedCharacter(sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  const globalFeaturesRef = useGlobalFeatures();

  return async (damages: {
    rollResult: DiceResult | undefined,
    damageType: Optional<Dnd5eDamageType>
  }[], halfDamage: boolean, doubleDamage: boolean) => {
    sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
      if (prev?.type !== "dnd-5e-stat-block" && prev?.type !== "dnd-5e-character") return [];
      const globalFeatures = globalFeaturesRef.value;
      let activeModifiers: Dnd5eModifier[] = getActiveModifiers(prev, globalFeatures);
      const damageReductions = activeModifiers.flatMap(m => (m.type === "damage-reduction") ? [m.data] : []);
      const damageImmunities = activeModifiers.flatMap(m => (m.type === "damage-immunity") ? m.data.damageTypes : []);
      const damageVulnerabilities = activeModifiers.flatMap(m => (m.type === "damage-vulnerability") ? m.data.damageTypes : []);
      const damageResistances = activeModifiers.flatMap(m => (m.type === "damage-resistance") ? m.data.damageTypes : []);
      const damageThreshold = activeModifiers.flatMap(m => (m.type === "damage-threshold") ? [m.data.amount] : []);

      let totalAmount = 0;
      for (const damage of damages) {
        let damageTotal = damage.rollResult?.value || 0;
        if (doubleDamage) damageTotal *= 2;
        if (halfDamage) damageTotal /= 2;
        damageTotal = Math.floor(damageTotal);
        if (damage.damageType !== undefined) {
          for (const damageReduction of damageReductions) {
            if (damageReduction.damageTypes.includes(damage.damageType)) {
              damageTotal = Math.max(0, damageTotal - damageReduction.amount);
            }
          }
          if (damageImmunities.includes(damage.damageType)) damageTotal = 0;
          if (damageVulnerabilities.includes(damage.damageType)) damageTotal *= 2;
          if (damageResistances.includes(damage.damageType)) damageTotal /= 2;
        }
        damageTotal = Math.floor(damageTotal);
        totalAmount += damageTotal;
      }
      if (damageThreshold.some(threshold => threshold > totalAmount)) return [];
      if (totalAmount <= 0) return [];

      const lostTempHP = Math.min(prev.data.hitPoints.temp, totalAmount);

      const max = SheetInterface.getMaxHP(prev, globalFeatures);
      const maxDamage = max + prev.data.hitPoints.current;
      const lostHP = Math.min(maxDamage, totalAmount - lostTempHP);
      const operations: Dnd5eHealthOperation[] = [];
      if (lostTempHP > 0) operations.push({type: "update-temp", operations: NumberFn.decrement(lostTempHP)});
      if (lostHP > 0) operations.push({type: "update-current", operations: NumberFn.decrement(lostHP)});

      if (prev.type === "dnd-5e-stat-block") {
        return (operations.length !== 0) ? [{type: "dnd-5e-stat-block", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else if (prev.type === "dnd-5e-character") {
        return (operations.length !== 0) ? [{type: "dnd-5e-character", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else {
        return [];
      }
    });
  };
}

export function useApplyHealingToSelectedCharacter(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  return async (rollResult: (DiceResult | undefined)[]) => {
    sheet.apply((prev: Optional<Sheet>): SheetOperation[] => {
      const totalHealing = rollResult.reduce((a, b) => {
        return a + (b ? b.value : 0);
      }, 0);
      if (prev?.type === "dnd-5e-character") {
        const gainHP = Math.max(0, Math.min(-prev.data.hitPoints.current, totalHealing));
        const operations: Dnd5eHealthOperation[] = [];
        if (gainHP > 0) operations.push({type: "update-current", operations: NumberFn.increment(gainHP)});
        return (operations.length !== 0) ? [{type: "dnd-5e-character", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const gainHP = Math.max(0, Math.min(-prev.data.hitPoints.current, totalHealing));
        const operations: Dnd5eHealthOperation[] = [];
        if (gainHP > 0) operations.push({type: "update-current", operations: NumberFn.increment(gainHP)});
        return (operations.length !== 0) ? [{type: "dnd-5e-stat-block", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else {
        return [];
      }
    });
  }
}

export function useApplyTempHPToSelectedCharacter(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>) {
  return async (rollResult: (DiceResult | undefined)[]) => {
    sheet.apply((prev: Optional<Sheet>): SheetOperation[] => {
      const newTempHP = rollResult.reduce((a, b) => a + (b ? b.value : 0), 0);
      if (prev?.type === "dnd-5e-character") {
        const operations: Dnd5eHealthOperation[] = [];
        if (newTempHP > prev.data.hitPoints.temp) operations.push({type: "update-temp", operations: NumberFn.set(prev.data.hitPoints.temp, newTempHP)});
        return (operations.length !== 0) ? [{type: "dnd-5e-character", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const operations: Dnd5eHealthOperation[] = [];
        if (newTempHP > prev.data.hitPoints.temp) operations.push({type: "update-temp", operations: NumberFn.set(prev.data.hitPoints.temp, newTempHP)});
        return (operations.length !== 0) ? [{type: "dnd-5e-stat-block", operations: [{type: "update-hit-points", operations: operations}]}] : [];
      } else {
        return [];
      }
    });
  }
}
