import {forwardRef, InputHTMLAttributes, Ref} from "react";
import "./input.css";
import {twMerge} from "tailwind-merge";

export type InputSize = "small" | "medium" | "large";
export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & {
  size?: InputSize;
};
export const Input = forwardRef(function Input({size = "medium", className, ...props}: InputProps, ref: Ref<HTMLInputElement>): JSX.Element {
  return <input ref={ref} className={twMerge(
    "w-full border-0 outline-none bg-transparent px-0",
    "disabled:opacity-50 placeholder-white/30 focus:outline-none focus:ring-0",
    `input ${size}`,
    className
  )} {...props} />
});
