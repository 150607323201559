import {Type} from "../../type/type.ts";
import {BooleanOperation} from "./boolean-operation.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const booleanType: Type<boolean, BooleanOperation> = {
  apply: (value: boolean, operation: BooleanOperation): boolean => {
    switch (operation.type) {
      case "toggle": return !value;
      case "set": return operation.nextValue;
      default: throw new Error("Unsupported Operation");
    }
  },
  invert: (operation: BooleanOperation): BooleanOperation[] => {
    switch (operation.type) {
      case "toggle": return [operation];
      case "set": return [{type: "set", prevValue: operation.nextValue, nextValue: operation.prevValue}];
    }
  },
  transform: (leftOperation: BooleanOperation, topOperation: BooleanOperation, tieBreaker: boolean): BooleanOperation[] => {
    switch (leftOperation.type) {
      case "toggle":
        switch (topOperation.type) {
          case "toggle": return tieBreaker
            ? [leftOperation]
            : [leftOperation];
          case "set": return tieBreaker
            ? [leftOperation]
            : [];
        }
        break;
      case "set":
        switch (topOperation.type) {
          case "toggle": return tieBreaker
            ? [leftOperation]
            : [];
          case "set": return tieBreaker
            ? [leftOperation]
            : [];
        }
        break;
    }
  },
  migrateValue(value: any): boolean {
    return value;
  },
  migrateOperation(operation: any): BooleanOperation[] {
    return [operation];
  },
  validate: (value: any): ValidationError[] => {
    if (typeof value !== "boolean") return [{path: [], data: {message: "Invalid type. Expected boolean.", value}}];
    return [];
  }
}

export const BooleanFn = {
  set: (prevValue: boolean, nextValue: boolean): BooleanOperation[] => [{type: "set", prevValue, nextValue}]
};
