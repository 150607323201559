import {DialogID} from "../../../modal/index.ts";
import "./resizer.css";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";

export type DialogRightResizerProps = {
  dialogId: DialogID;
  minimized: boolean;
};

export function DialogRightResizer({dialogId, minimized}: DialogRightResizerProps) {
  const [, drag, dragPreviewRef] = useDrag(() => ({
    type: `legends/dialog-resize`,
    item: {dialogId, direction: "right"},
    options: {dropEffect: "move"},
    canDrag: () => !minimized
  }), [dialogId, minimized]);
  dragPreviewRef(EMPTY_IMAGE);
  return (<div ref={drag} className={`dialog-right-resizer ${minimized ? "minimized" : "expanded"}`} />);
}