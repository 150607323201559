import {
  AssetType,
  QLabAsset,
  QLabFileId,
  QLabMessageChangeset,
  QLabMessageID,
  QLabResourceChangeset,
  QLabResourceID,
  QLabResourceRevision,
  QLabStoreChangeset,
  QLabStoreID,
  QLabStoreRevision
} from "common/qlab/index.ts";
import {QLabServiceSocket} from "./q-lab-service-socket.ts";
import {PersistentWebSocket} from "./persistent-web-socket.ts";
import {QLabServiceHandler} from "./q-lab-service-handler.ts";
import {Base64} from "./base64.ts";
import {AccessToken} from "common/access-token";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";

export class DefaultQLabServiceSocket implements QLabServiceSocket {
  private readonly webSocket: PersistentWebSocket;
  private readonly listeners: QLabServiceHandler[] = [];
  constructor(
    qlabApiUrl: string,
    qlabWsUrl: string,
    private readonly assetsUrl: string,
    private readonly authToken: string,
    private readonly accessTokens: AccessToken[]
  ) {
    this.webSocket = new PersistentWebSocket(qlabApiUrl, qlabWsUrl, authToken, accessTokens, {
      onOpen: () => {
        for (const listener of this.listeners) {
          listener.onConnect();
        }
      },
      onClose: () => {
        for (const listener of this.listeners) {
          listener.onDisconnect();
        }
      },
      onReconnect: () => {
        for (const listener of this.listeners) {
          listener.onConnect();
        }
      },
      onMessage: (message) => {
        console.debug("<    %o", message);
        for (const listener of this.listeners) {
          listener.onMessage(message);
        }
      }
    });
  }
  isOpen(): boolean {
    return this.webSocket.isOpen();
  }
  addListener(listener: QLabServiceHandler): void {
    this.listeners.push(listener);
  }
  removeListener(listener: QLabServiceHandler): void {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  subscribeStore(storeId: QLabStoreID, storeRevision: QLabStoreRevision, resourceRevisions: {[resourceId: QLabResourceID]: QLabResourceRevision}): void {
    this.webSocket.send({
      type: "SUBSCRIBE-STORE",
      storeId: storeId,
      storeRevision: storeRevision,
      resourceRevisions,
      assetIds: []
    });
  }

  unsubscribeStore(storeId: QLabStoreID): void {
    this.webSocket.send({
      type: "UNSUBSCRIBE-STORE",
      storeId
    });
    return
  }

  applyToStore(storeId: QLabStoreID, storeChangesets: QLabStoreChangeset[]): void {
    this.webSocket.send({
      type: "APPLY-TO-STORE",
      storeId,
      storeChangesets: storeChangesets.length > 20 ? storeChangesets.slice(0, 20) : storeChangesets
    });
  }

  applyToResource(storeId: QLabStoreID, resourceId: QLabResourceID, resourceChangesets: QLabResourceChangeset[]): void {
    this.webSocket.send({
      type: "APPLY-TO-RESOURCE",
      storeId,
      resourceId,
      resourceChangesets
    });
  }

  applyToMessage(storeId: QLabStoreID, messageId: QLabMessageID, messageChangesets: QLabMessageChangeset[]): void {
    this.webSocket.send({type: "APPLY-TO-MESSAGE", storeId, messageId, messageChangesets});
  }
  requestMessage(storeId: QLabStoreID, messageId: QLabMessageID): void {
    this.webSocket.send({type: "REQUEST-MESSAGE", storeId, messageId});
  }

  requestMessages(storeId: QLabStoreID, lastMessage?: QLabMessageID, limit?: number): void {
    this.webSocket.send({type: "REQUEST-MESSAGES", storeId, lastMessage, limit});
  }

  getAsset = async (storeId: QLabStoreID, assetId: QLabFileId): Promise<QLabAsset> => {
    const cache = await caches.open("legends-app-assets-cache");
    const assetUrl = `https://${this.assetsUrl}/${storeId}/${assetId}`;

    const cacheResponse = await cache.match(assetUrl);
    if (cacheResponse) {
      return ({
        type: cacheResponse.headers.get("content-type")! as AssetType,
        data: Base64.arrayBufferToBase64(await cacheResponse.arrayBuffer())
      });
    } else {
      const response = await fetch(assetUrl, {
        method: "GET",
        headers: {
          authorization: `Bearer ${this.accessTokens[0]}`
        }
      });

      if (response.status === 200) {
        const cacheResponse = response.clone();
        await cache.put(assetUrl, new Response(
          cacheResponse.body,
          {headers: {
            "content-type": cacheResponse.headers.get("content-type")!,
            "content-length": cacheResponse.headers.get("content-length")!
          }, status: 200, statusText: "OK"}
        ));

        return ({
          type: response.headers.get("content-type")! as AssetType,
          data: Base64.arrayBufferToBase64(await response.arrayBuffer())
        });
      } else {
        throw new Error("Cannot get asset.");
      }
    }
  }

  stream(storeId: QLabStoreID, stream: QLabStream): void {
    this.webSocket.send({type: "STREAM", storeId, stream});
  }
}
