import {StackId} from "../stack-id.ts";
import {ConstantOperation, ConstantType, ObjectType, Type, ValueOperation, ValueType} from "common/types/index.ts";

export type StackItem<Value> = {
  id: StackId;
  content: Value;
};

export type StackItemOperation<Value, Operation> =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-content", operations: ValueOperation<Value, Operation>[]}
  ;

export const StackItemFn = {
  updateContent<V, O>(operations: ValueOperation<V, O>[]): StackItemOperation<V, O>[] {
    return [{type: "update-content", operations}];
  }
};

export class StackItemType<Value, Operation> extends ObjectType<{
  id: ConstantType<StackId>,
  content: Type<Value, ValueOperation<Value, Operation>>
}> {
  constructor(type: Type<Value, Operation>) {
    super({
      id: new ConstantType(),
      content: new ValueType(type)
    });
  }
}
