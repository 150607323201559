import {DialogID} from "./dialog-i-d.ts";
import {Stack, StackOperation} from "#lib/container/index.ts";

export type DialogItem<LV> = {
  id: DialogID;
  minimized: boolean;
  position: [number, number];
  size: [number, number];
  external: boolean;
  value: {
    type: "layout";
    layout: Stack<LV>;
  }
};

export type SetMinimizedOperation = { type: "set-minimized"; value: boolean; };

export type MoveDialogItemOperation = {
  type: "move";
  position: [number, number];
  size: [number, number];
};

export type ApplyToLayoutDialogOperation<LV, LO> = {
  type: "apply-to-layout";
  operations: StackOperation<LV, LO>[];
};

export type DialogItemOperation<LV, LO> =
  | MoveDialogItemOperation
  | ApplyToLayoutDialogOperation<LV, LO>
  | SetMinimizedOperation
  | {type: "externalize"}
  ;

export const DialogItemFn = {
  move<LV, LO>(position: [number, number], size: [number, number]): DialogItemOperation<LV, LO>[] {
    return [{type: "move", position, size}];
  },
  applyToLayout<LV, LO>(operations: StackOperation<LV, LO>[]): DialogItemOperation<LV, LO>[] {
    return [{type: "apply-to-layout", operations}]
  }
};