import {HSLA, ListOperation, ListSignal, Point, PointFn, Transform} from "common/types/index.ts";
import {Node, NodeId, Scene} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {AccessMask} from "common/legends/visibility/index.ts";
import {Background} from "./background.tsx";
import {GridProvider} from "../node-view/grid-view/grid-context.ts";
import {NodeView} from "../node-view/index.ts";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import React, {useMemo} from "react";
import {getWalls} from "./walls.ts";
import {QLabDatabase} from "common/qlab/index.ts";
import {distinct, listIdentity} from "common/observable";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useActiveController, useIsPlayerController} from "../../../token/use-active-controller-node-reference.ts";
import {WallShader} from "../../../../common/wall-shader.tsx";
import {useNode} from "../../../token/use-node.ts";
import {MutableRef} from "common/ref";

export type Line = {
  start: Point;
  end: Point;
  viewLeft: boolean;
  viewRight: boolean;
};
export function useWalls(scene: Scene, visibilityMask: AccessMask, accessNodeIDs: NodeId[]): Line[] {
  return useNodeWalls(scene.children, visibilityMask, accessNodeIDs);
}

export function useNodeWalls(nodes: Node[], visibilityMask: AccessMask, accessNodeIDs: NodeId[]): Line[] {
  const database = useDatabase();
  const walls = useMemo((): ListSignal<Line, never> => {
    const valueFn = (database: QLabDatabase, nodes: Node[]) => {
      return nodes.reduce((walls, child) => getWalls(walls, line => line, database, child, visibilityMask, accessNodeIDs), [] as Line[]);
    }

    return new MutableRef<Line[], ListOperation<Line, never>[]>({
      value(): Line[] {
        return valueFn(database.value, nodes);
      },
      observe: distinct<Line[]>(listIdentity)(observer => database.observe({
        next(db) {
          observer.next(valueFn(db, nodes))
        },
        error: observer.error,
        complete: observer.complete
      })),
      apply: _ => {
        throw new Error("Unsupported.");
      }
    })
  }, [database, nodes, visibilityMask, accessNodeIDs])

  return useRefValue(walls);
}

export function getNodesOrigin(nodeID: NodeId, nodes: Node[]): Point | undefined {
  for (const node of nodes) {
    const origin = getOrigin(nodeID, node)
    if (origin) return origin;
  }
  return undefined;
}

function getOrigin(nodeID: NodeId, node: Node): Point | undefined {
  if (node.data.id === nodeID) {
    if (node.type === "image") {
      return node.data.origin;
    } else {
      return node.data.transform.position;
    }
  }

  for (const child of node.data.children) {
    const origin = getOrigin(nodeID, child);
    if (origin !== undefined) {
      const nodeMatrix = Matrix4f.transform(node.data.transform);
      let newOrigin = Matrix4f.multiplyVector(
        nodeMatrix,
        [origin[0], origin[1], 0, 1]
      ).slice(0, 2);
      return newOrigin as Point;
    }
  }

  return undefined;
}

export type SceneViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  scene: Scene;
};
export function SceneView({projection, view, visibilityMask, accessNodeIDs, scene}: SceneViewProps) {
  const walls = useWalls(scene, visibilityMask, accessNodeIDs);
  const isPlayerController = useIsPlayerController();
  const activeController = useActiveController();
  const activeNodeID = useComputedValue(activeController, activeController => activeController?.controllerNodeID);
  const isActiveControllerToken = useComputedValue(useNode(activeNodeID), node => node?.type === "token" && node.data.accessMask !== 0);
  const origin = (activeNodeID && isActiveControllerToken) ? getNodesOrigin(activeNodeID, scene.children) : (isPlayerController ? PointFn.ZERO : undefined);

  return (<>
    <GridProvider value={scene.grid}>
      <Background color={[...scene.backgroundColor, 1] as HSLA} />
      {scene.children.map((child) => {
        const nodeOpacity = 1;
        return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={Transform.DEFAULT} opacity={nodeOpacity} />
      })}
      {origin && <WallShader projection={projection} view={view} model={Transform.DEFAULT} lines={walls} origin={origin} />}
    </GridProvider>
  </>);
}
