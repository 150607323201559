import {
  ConstantOperation,
  ConstantType,
  MapOperation,
  MapType,
  ObjectType,
  StringOperation,
  stringType,
  Type
} from "common/types/index.ts";
import {SceneTreeId} from "common/legends/index.ts";

export type SceneNavigator = {
  searchTerm: string;
  expanded: Record<SceneTreeId, true>;
};

export type SceneNavigatorOperation =
  | {type: "update-search-term", operations: StringOperation[]}
  | {type: "update-expanded", operations: MapOperation<SceneTreeId, boolean, ConstantOperation>[]}
  ;

export const sceneNavigatorType: Type<SceneNavigator, SceneNavigatorOperation> = new ObjectType({
  searchTerm: stringType,
  expanded: new MapType<SceneTreeId, true, ConstantOperation>(new ConstantType<true>())
});
