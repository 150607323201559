import {GameID, User, UserOperation} from "common/legends/index.ts";
import {useCallback} from "react";
import {ApplyAction} from "#lib/qlab/index.ts";

export function useAddGameId(applyToUser?: (action: ApplyAction<User, UserOperation[]>) => void) {
  return useCallback((gameId: GameID) => {
    if (!applyToUser) return;

    return applyToUser(prevValue => [{
      type: "update-game-ids",
      operations: [{
        type: "insert",
        index: prevValue.gameIds.length,
        item: gameId
      }]
    }]);
  }, [applyToUser]);
}
export function useRemoveGameId(applyToUser?: (action: ApplyAction<User, UserOperation[]>) => void) {
  return useCallback((gameId: GameID) => {
    if (!applyToUser) return;

    return applyToUser(prevValue => {
      const gameIdIndex = prevValue.gameIds.indexOf(gameId);
      if (gameIdIndex === -1) throw new Error("Could not find game id");
      return [{
        type: "update-game-ids",
        operations: [{
          type: "delete",
          index: gameIdIndex,
          item: prevValue.gameIds[gameIdIndex]
        }]
      }];
    });
  }, [applyToUser]);
}
