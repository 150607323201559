import {QLabDatabase} from "common/qlab/index.ts";
import {Line} from "./scene-view.tsx";
import {Node, NodeId} from "common/legends/index.ts";
import {Matrix4f, Vector2f} from "#lib/math/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {Mask} from "common/types/generic/mask/mask.ts";
import {Curve, Spline} from "common/types/generic/spline/index.ts";
import {WallGraph} from "common/legends/node/wall-node.ts";
import {cubicBezier} from "./bezier.ts";
import {Transform} from "common/types/generic/transform/transform.ts";

export function getLines(spline: Spline, n: number = 8): Line[] {
  let lines: Line[] = [];
  let startCurve: Curve = {
    end: spline.start,
    controlPoint1: spline.controlPoint1,
    controlPoint2: spline.controlPoint2
  };
  if (spline.curves.length === 0) return cubicBezier(startCurve.end, startCurve.end, startCurve.controlPoint1, startCurve.controlPoint2, n, false, false);

  let lastCurve = startCurve;
  for (let i = 0; i < spline.curves.length; i ++) {
    const curve = spline.curves[i];
    lines.push(...cubicBezier(lastCurve.end, curve.end, lastCurve.controlPoint2, curve.controlPoint1, n, false, false));
    lastCurve = curve;
  }
  if (spline.closed) lines.push(...cubicBezier(lastCurve.end, startCurve.end, lastCurve.controlPoint2, startCurve.controlPoint1, n, false, false));
  return lines;
}


export function getLinesFromWallGraph(graph: WallGraph): Line[] {
  return [
    ...graph.edges.flatMap(edge => {
      const start = graph.vertices[edge.start];
      const end = graph.vertices[edge.end];
      return cubicBezier(start.coordinate, end.coordinate, edge.controlPoint1, edge.controlPoint2, edge.resolution, !edge.data.blockVisibilityLeft, !edge.data.blockVisibilityRight);
    }),
    ...graph.vertices.flatMap(vertex => {
      return cubicBezier(vertex.coordinate,vertex.coordinate, [0, 0], [0, 0], 1, false, false);
    })
  ];
}

export function getWalls(
  walls: Line[],
  transform: (line: Line) => Line,
  database: QLabDatabase,
  node: Node,
  visibilityMask: Mask,
  accessNodeIDs: NodeId[]
): Line[] {
  if (!AccessMaskFn.canSee(visibilityMask, node.data.visibilityLayer) && !accessNodeIDs.includes(node.data.id)) return walls;
  const nodeMatrix = Transform.toMatrix4x4(node.data.transform);
  const nodeTransform = (line: Line): Line => {
    let newStart = Matrix4f.multiplyVector(
      nodeMatrix,
      [line.start[0], line.start[1], 0, 1]
    ).slice(0, 2);
    let newEnd = Matrix4f.multiplyVector(
      nodeMatrix,
      [line.end[0], line.end[1], 0, 1]
    ).slice(0, 2);
    return transform({...line, start: newStart, end: newEnd} as Line);
  };

  if (node.type === "wall") {
    const lines = getLinesFromWallGraph(node.data.graph).map(nodeTransform);
    return node.data.children.reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), [...walls, ...lines]);
  } else if (node.type === "token") {
    const tokenReference = node.data.tokenReference;
    const resource = database.resources[tokenReference.assetID];
    if (resource?.type !== "asset") return node.data.children.reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), walls);
    const token = resource.data.tokens.find(token => token.tokenID === tokenReference.tokenID);
    if (token === undefined) return node.data.children.reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), walls);

    return [
      ...token.children,
      ...node.data.children
    ].reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), walls);
  } else if (node.type === "area") {
    let areaWalls = node.data.suppressWalls ? node.data.areas.reduce((walls, area) => {

      const areaLines = getLines(area).map(nodeTransform);
      return walls.flatMap((line): Line[] => {
        const t = areaLines.map((oLine) => {
          const t = Vector2f.distanceToLine(oLine.start, oLine.end, line.start, line.end);
          if (t === undefined || t + Number.EPSILON*1000 < 0 || t - Number.EPSILON*1000 > 1) return undefined;
          if (t - Number.EPSILON*1000 < 0 || t + Number.EPSILON*1000 > 1) {
            const d = Vector2f.dot(Vector2f.subtract(oLine.end, oLine.start), Vector2f.subtract(line.end, line.start));
            if (d + Number.EPSILON*1000 > 0) return undefined;
          }
          return Vector2f.distanceToLine(line.start, line.end, oLine.start, oLine.end);
        }).filter(t => t !== undefined && t > 0).sort() as number[];

        if (t.length % 2 === 0 && t.filter(t => t <= 1).length === 0) {
          return [line];
        } else {
          const lines: Line[] = [];
          let iteration = t.length % 2 === 1 ? 1 : 0;
          for (let i = -1; i < t.length; i ++) {
            if (i >= 0 && t[i] + Number.EPSILON > 1) break;
            const p1 = Vector2f.lerp(line.start, line.end, i >= 0 ? t[i] : 0);
            const p2 = Vector2f.lerp(line.start, line.end, i + 1 < t.length ? t[i+1] : 1);
            if (iteration % 2 === 0) lines.push({start: p1, end: p2, viewLeft: line.viewLeft, viewRight: line.viewRight});
            iteration ++;
          }
          return lines;
        }
      });
    }, walls) : walls;

    return node.data.children.reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), areaWalls);
  }

  return node.data.children.reduce((walls, child) => getWalls(walls, nodeTransform, database, child, visibilityMask, accessNodeIDs), walls);
}