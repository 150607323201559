import {z} from "zod";
import {ConstantOperation, constantType, ValueOperation, ValueType} from "#common/types/index.ts";

export const RichTextText = z.object({
  text: z.string(),
  bold: z.boolean().optional(),
  italic: z.boolean().optional()
});
export type RichTextText = z.infer<typeof RichTextText>;
const ZodBaseRichTextElement = z.object({});
export type RichTextElement = z.infer<typeof ZodBaseRichTextElement> & {
  children: (RichTextElement | RichTextText | RichTextDiceExpression)[]
}
export const RichTextElement: z.ZodType<RichTextElement> = ZodBaseRichTextElement.extend({
  children: z.lazy(() => z.union([RichTextElement, RichTextText]).array())
});

const ZodBaseRichTextDiceExpression = z.object({});
export type RichTextDiceExpression = z.infer<typeof ZodBaseRichTextDiceExpression> & {
  children: (RichTextElement | RichTextText | RichTextDiceExpression)[];
};

const RichTextDescendant = z.union([RichTextElement, RichTextText]);
export type RichTextDescendant = z.infer<typeof RichTextDescendant>;
export const RichText = z.array(RichTextDescendant);
export type RichText = z.infer<typeof RichText>;

export const RichTextOperation = ValueOperation(RichText, ConstantOperation);
export type RichTextOperation = ValueOperation<RichText, ConstantOperation>;

export const richTextType = new ValueType(constantType);

export const RichTextFn = {
  EMPTY: [{children: [{text: ""}]}] satisfies RichText,
  isEmpty: (value: RichText) => {
    for (const child of value) {
      const text = RichTextText.safeParse(child);
      if (text.success) {
        if (text.data.text.length > 0)
          return false;
        continue;
      }

      const element = RichTextElement.safeParse(child);
      if (element.success) {
        if (!RichTextFn.isEmpty(element.data.children))
          return false;
        continue;
      }
    }
    return true;
  }
} as const;