import {z} from "zod";
import {ConstantOperation, constantType, NumberOperation, numberType, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eResourceID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ValuePropertyRef} from "#common/types/generic/value/value-property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eResourceConsumptionModifier = z.object({
  modifierID: Dnd5eModifierID,
  resourceID: z.optional(Dnd5eResourceID),
  qty: z.number()
});
export type Dnd5eResourceConsumptionModifier = z.infer<typeof Dnd5eResourceConsumptionModifier>;

export const Dnd5eResourceConsumptionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-resource-i-d"), operations: z.array(ValueOperation(z.optional(Dnd5eResourceID), ConstantOperation))}),
  z.object({type: z.literal("update-qty"), operations: z.array(NumberOperation)})
]);
export type Dnd5eResourceConsumptionModifierOperation = z.infer<typeof Dnd5eResourceConsumptionModifierOperation>;

export const dnd5eResourceConsumptionModifierType: Type<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation> = new ObjectType({
  modifierID: constantType,
  resourceID: new ValueType(constantType),
  qty: numberType
});

export function Dnd5eResourceConsumptionModifierSignals(value: MutableRef<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation[]>) {
  return ({
    resourceID: ValuePropertyRef<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation, Optional<Dnd5eResourceID>, ConstantOperation>(
      value => value.resourceID,
      operations => [{type: "update-resource-i-d", operations}]
    )(value),
    qty: PropertyRef<Dnd5eResourceConsumptionModifier, Dnd5eResourceConsumptionModifierOperation, number, NumberOperation>(
      value => value.qty,
      operations => [{type: "update-qty", operations}]
    )(value)
  });
}

