import {FaXmark} from "react-icons/fa6";

export type DialogCloseHandleProps = {
  onClose: () => void;
};

export function DialogCloseHandle({onClose}: DialogCloseHandleProps) {
  return (<div className="dialog-top-button dialog-close-handle" onClick={onClose} title="Close">
    <FaXmark size={12} />
  </div>);
}
