import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Mask, MaskOperation} from "common/types/generic/mask/mask.ts";
import {InputMask} from "#lib/components/input-mask/input-mask.tsx";
import {MutableRef} from "common/ref";

export type SelectionMaskFieldProps = {
  value: MutableRef<Mask, MaskOperation[]>;
};

export function SelectionMaskField({value}: SelectionMaskFieldProps) {
  return (
    <InputGroup>
      <InputGroupLabel>Selection Mask</InputGroupLabel>
      <InputMask value={value} />
    </InputGroup>
  )
}