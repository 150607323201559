import {Observable} from "../observable.ts";

export function toPromise<U>(observe: Observable<U>): Promise<U> {
  return new Promise<U>((resolve, reject) => {
    const unsubscribe = observe({
      next: (value) => {
        resolve(value);
        setTimeout(() => unsubscribe(), 0);
      },
      complete: () => {},
      error: reject
    })
  });
}
