import React, {useCallback} from "react";
import {Button, InputGroup, InputGroupLabel, TagListInput, useTempRef} from "#lib/components/index.ts";
import {AssetTreeFolder, AssetTreeId} from "common/legends/index.ts";
import {stringType, tagListType} from "common/types/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {toPromise} from "common/observable";
import {InputString} from "#lib/components/input/input-string.tsx";

export type CreateFolderModalProps = {
  onClose: () => void;
  onCreate: (item: AssetTreeFolder) => void;
};

export function CreateFolderModal({onClose, onCreate}: CreateFolderModalProps) {
  const name = useTempRef(stringType, "");
  const tags = useTempRef(tagListType, []);
  const onCreateFolder = useCallback(async () => {
    const n = await toPromise(name.observe);
    const t = await toPromise(tags.observe)
    if (n.length === 0) return;
    onCreate({id: AssetTreeId.generate(), name: n, tags: t, children: []});
  }, [name, tags, onCreate]);

  return (<Modal onClose={onClose}>
    <ModalTitle>Create Folder</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>
      <TagListInput value={tags} />
    </ModalBody>
    <Button type="submit" variant="primary" className="grow" onClick={() => {
      onCreateFolder().catch(console.error);
    }}>Create</Button>
  </Modal>)
}
