import {useMemo} from "react";
import {usePlayerController} from "./player-controller-provider.ts";
import {PropertyRef} from "common/types/index.ts";
import {Tool, ToolOperation} from "../../common/tool-mode/tool.ts";
import {MutableRef} from "common/ref";
import {TokenViewportProperties, TokenViewportPropertiesOperation} from "../../viewport/token/token-viewport-properties.ts";

export function usePlayerToolMode(): MutableRef<Tool, ToolOperation[]> {
  const player = usePlayerController();
  return useMemo(() => PropertyRef<TokenViewportProperties, TokenViewportPropertiesOperation, Tool, ToolOperation>(
      value => value.toolMode,
      operations => [{type: "update-tool-mode", operations}]
    )(player.state), [player.state]);
}