import {MarkerTurn, MarkerTurnOperation, NodeTurn, NodeTurnOperation, Turn, TurnOperation, TurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {useMemo} from "react";
import {useObservable} from "#lib/qlab/index.ts";
import {EditorNodeTurnView} from "./editor-node-turn-view.tsx";
import {EditorMarkerTurnView} from "./editor-marker-turn-view.tsx";
import {MutableRef} from "common/ref";

export type EditorTurnViewProps = {
  isCurrentTurn: boolean;
  value: MutableRef<Turn, TurnOperation[]>;
  remove?: () => void;
  makeActiveTurn?: () => void;
};
export function EditorTurnView({value, isCurrentTurn, makeActiveTurn, remove}: EditorTurnViewProps) {
  const {type, data} = useMemo(() => TurnSignals(value), [value]);
  const resolvedType = useObservable(type.observe, undefined, [type.observe]);
  if (resolvedType === undefined) return (<></>);

  if (resolvedType === "node") {
    // @ts-ignore
    return <EditorNodeTurnView isCurrentTurn={isCurrentTurn} value={data as MutableRef<NodeTurn, NodeTurnOperation[]>} remove={remove} makeActiveTurn={makeActiveTurn} />
  } else if (resolvedType === "marker") {
    // @ts-ignore
    return <EditorMarkerTurnView isCurrentTurn={isCurrentTurn} value={data as MutableRef<MarkerTurn, MarkerTurnOperation[]>} remove={remove} makeActiveTurn={makeActiveTurn} />
  } else {
    return <>Unknown!</>
  }
}
