import {Type} from "../../type/type.ts";
import {z, ZodTypeAny} from "zod";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export function Embeddable(reference: ZodTypeAny, value: ZodTypeAny) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("link"), data: reference}),
    z.object({type: z.literal("copy"), data: value})
  ]);
}
export type Embeddable<Reference, Value> =
  | {type: "link", data: Reference}
  | {type: "copy", data: Value}
  ;
export type EmbeddableOperation<Operation> = Operation;

export class EmbeddableType<Reference, Value, Operation> implements Type<Embeddable<Reference, Value>, EmbeddableOperation<Operation>> {
  constructor(private readonly valueType: Type<Value, Operation>) {}
  apply = (value: Embeddable<Reference, Value>, operation: EmbeddableOperation<Operation>): Embeddable<Reference, Value> => {
    if (value.type === "copy") {
      return {
        ...value,
        data: this.valueType.apply(value.data, operation)
      }
    } else {
      throw new Error("Unsupported Operation")
    }
  }
  invert = (operation: EmbeddableOperation<Operation>): EmbeddableOperation<Operation>[] => {
    return this.valueType.invert(operation);
  }
  transform = (leftOperation: EmbeddableOperation<Operation>, topOperation: EmbeddableOperation<Operation>, tieBreaker: boolean): EmbeddableOperation<Operation>[] => {
    return this.valueType.transform(leftOperation, topOperation, tieBreaker);
  }
  migrateValue = (value: any): Embeddable<Reference, Value> => {
    if (value.type === "link") return {type: "link", data: value.data};
    else if (value.type === "copy") return {type: "copy", data: this.valueType.migrateValue(value.data)};
    throw new Error("Unsupported Value: " + JSON.stringify(value));
  }
  migrateOperation = (operation: any): EmbeddableOperation<Operation>[] => {
    return this.valueType.migrateOperation(operation);
  }
  validate = (value: any): ValidationError[] => {
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type. Expected embeddable.", value}}];
    if (value?.type === "link") return [];
    if (value?.type === "copy") return this.valueType.validate(value?.data);
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type. Expected embeddable.", value}}];
    return [{path: [], data: {message: "Invalid type. Expected embeddable.", value}}];
  }
}
