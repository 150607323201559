import {Background} from "../../background.tsx";
import {Modal} from "#lib/components/modal/index.ts";

export function ErrorScreen({errorMessage}: {errorMessage: string}) {
  return (
    <Background>
      <Modal className="max-w-screen-sm">
        <div className="flex items-center justify-center h-40 text-2xl">
          {errorMessage}
        </div>
      </Modal>
    </Background>
  );
}