import {MutableRefObject, useEffect, useRef} from "react";
import {QLabDatabase, QLabStoreID} from "common/qlab/index.ts";
import {pipe} from "common/pipe";
import {subscribe} from "common/observable";
import {useInstance} from "./q-lab-instance-context.ts";

const DEFAULT_QLAB_STORE_STATE = {
  connected: false,
  outstandingChangesets: false,
  store: undefined,
  resources: {},
  messages: {},
  connections: {},
  timeOffset: 0
};

export function useQLabStoreStateRef(storeId: QLabStoreID): MutableRefObject<QLabDatabase> {
  const instance = useInstance();
  const qlabStoreState = useRef<QLabDatabase>(DEFAULT_QLAB_STORE_STATE);
  useEffect(() => {
    return pipe(
      instance.observe(storeId),
      subscribe(storeState => {
        qlabStoreState.current = storeState as QLabDatabase
      })
    );
  }, [instance, storeId])
  return qlabStoreState;
}