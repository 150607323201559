import {getAreasAtPoint} from "../../viewport/common/node/model/index.ts";
import {TriggerContext} from "./use-apply-interaction-action.ts";
import {NodePath} from "../node/use-get-node-path.ts";
import {Node, NodeId} from "common/legends/node/index.ts";
import {Point} from "#lib/math/index.ts";
import {Optional, Transform} from "common/types/generic/index.ts";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";

function getNodes(database: QLabDatabase, nodePath: NodePath) {
  if (nodePath?.type === "scene") {
    const scene = database.resources[nodePath.sceneID];
    if (scene?.type !== "scene") return [];
    return scene.data.children;
  } else if (nodePath?.type === "asset") {
    const asset = database.resources[nodePath.assetID];
    if (asset?.type !== "asset") return [];
    const token = asset.data.tokens.find(token => token.tokenID === nodePath.tokenID);
    if (token === undefined) return [];
    return token.children;
  } else {
    return [];
  }
}

export function getMoveInteractions(
  database: QLabDatabase,
  nodePath: NodePath,
  activeNodeID: Optional<NodeId>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  view: Transform,
  prevPos: Point,
  nextPos: Point
): TriggerContext[] {
  const interactions: TriggerContext[] = [];
  const nodes = getNodes(database, nodePath);
  const prevAreas = getAreasAtPoint(nodePath, database, isAccessible, isVisible, prevPos, view, nodes);
  const nextAreas = getAreasAtPoint(nodePath, database, isAccessible, isVisible, nextPos, view, nodes);

  const enterAreas = nextAreas.filter(([_, nextArea]) => !prevAreas.some(([_, prevArea]) => prevArea === nextArea));
  for (const [areaPath, enterArea] of enterAreas) {
    if (enterArea.type !== "area") continue;
    const enterInteractions = enterArea.data.interactions.filter(interaction => interaction.triggers.some(trigger => trigger.type === "enter"));
    for (const enterInteraction of enterInteractions) {
      for (const action of enterInteraction.actions) {
        interactions.push({activeNodeID, triggerPath: areaPath, triggerNode: enterArea, action});
      }
    }
  }

  const exitAreas = prevAreas.filter(([_, prevArea]) => !nextAreas.some(([_, nextArea]) => prevArea === nextArea));
  for (const [areaPath, exitArea] of exitAreas) {
    if (exitArea.type !== "area") continue;
    const exitInteractions = exitArea.data.interactions.filter(interaction => interaction.triggers.some(trigger => trigger.type === "exit"));
    for (const exitInteraction of exitInteractions) {
      for (const action of exitInteraction.actions) {
        interactions.push({activeNodeID, triggerPath: areaPath, triggerNode: exitArea, action});
      }
    }
  }
  return interactions;
}
