import {Grid, GridOperation, gridType} from "./grid/index.ts";
import {ColorOperation, colorType, HSLA, ObjectType, PropertyRef, StringOperation, stringType, TreeOperation, TreeType, Type} from "../../types/index.ts";
import {Node, NodeOperation, nodeType} from "#common/legends/index.ts";
import {MutableRef} from "#common/ref";

export type Scene = {
  name: string;
  grid: Grid;
  children: Node[];
  backgroundColor: HSLA;
};

export type SceneOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-grid", operations: GridOperation[]}
  | {type: "update-children", operations: TreeOperation<Node, NodeOperation>[]}
  | {type: "update-background-color", operations: ColorOperation[]}
  ;

export const sceneType: Type<Scene, SceneOperation> = new ObjectType({
  name: stringType,
  grid: gridType,
  backgroundColor: colorType,
  children: new TreeType<Node, NodeOperation>(nodeType)
}, v => {
  if (v.backgroundColor.length === 3) v.backgroundColor = [...v.backgroundColor, 1];
  return v;
});

declare module "../../qlab/resource/resource.ts" {
  export interface ResourceTypes {
    "scene": typeof sceneType
  }
}

export const SceneFn = {
  expand(value: MutableRef<Scene, SceneOperation[]>) {
    return {
      name: PropertyRef<Scene, SceneOperation, string, StringOperation>(
        value => value.name,
        operations => [{type: "update-name", operations}]
      )(value),
      children: PropertyRef<Scene, SceneOperation, Node[], TreeOperation<Node, NodeOperation>>(
        value => value.children,
        operations => [{type: "update-children", operations}]
      )(value),
      grid: PropertyRef<Scene, SceneOperation, Grid, GridOperation>(
        value => value.grid,
        operations => [{type: "update-grid", operations}]
      )(value),
      backgroundColor: PropertyRef<Scene, SceneOperation, HSLA, ColorOperation>(
        value => value.backgroundColor,
        operations => [{type: "update-background-color", operations}]
      )(value)
    } as const;
  }
};
