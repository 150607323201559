import {assetTokenObservable, sceneNodeObservable, tokenNodeObservable} from "common/legends/index.ts";
import {QLabInstance, resourceObservable, useInstance, useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, from, map, Observable, switchAll} from "common/observable";
import {useEditor} from "../../container/editor/editor-context.ts";
import {SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {EditorState} from "../../container/editor/state/index.ts";
import {selectionRefObservable} from "../../container/editor/state/use-selection-ref-observable.ts";
import {assetObservable} from "../../common/character/asset-observable.ts";

export function useSelectionRef(pinnedSelectionRef: SelectionRef) {
  const editor = useEditor();
  return useObservable(pipe(
    pinnedSelectionRefObservable(editor.state.observe, pinnedSelectionRef)
  ), pinnedSelectionRef, [editor.state, pinnedSelectionRef]);
}

export function pinnedSelectionRefObservable(editorState: Observable<EditorState>, pinnedSelectionRef: SelectionRef): Observable<SelectionRef> {
  return pipe(
    combine(
      from(pinnedSelectionRef),
      selectionRefObservable(editorState)
    ),
    map(([a, b]) => a || b),
    distinct(SelectionRef.equals)
  );
}

export function useSelectionRefName(reference: SelectionRef): string | undefined {
  const instance = useInstance();
  return useObservable(pipe(
    from(reference),
    selectionRefNameObservable(instance)
  ), undefined, [instance, reference]);
}

export function selectionRefNameObservable(instance: QLabInstance) {
  return (reference: Observable<SelectionRef>) => pipe(
    reference,
    map((reference): Observable<string | undefined> => {
      if (reference?.type === "asset-token") {
        return pipe(
          from({gameID: reference.storeId, assetID: reference.resourceId}),
          assetObservable(instance),
          asset => assetTokenObservable(asset, from(reference.tokenId)),
          map((value) => value?.name)
        );
      } else if (reference?.type === "asset-token-node") {
        return pipe(
          from({gameID: reference.storeId, assetID: reference.resourceId}),
          assetObservable(instance),
          asset => assetTokenObservable(asset, from(reference.tokenId)),
          token => tokenNodeObservable(token, from(reference.nodeId)),
          map((value) => value?.data.name)
        );
      } else if (reference?.type === "scene") {
        return pipe(
          instance.observe(reference.storeId),
          resourceObservable("scene", reference.resourceId),
          map((value) => value?.name)
        );
      } else if (reference?.type === "scene-node") {
        return pipe(
          instance.observe(reference.storeId),
          resourceObservable("scene", reference.resourceId),
          scene => sceneNodeObservable(scene, from(reference.nodeId)),
          map((node) => node?.data.name)
        );
      } else {
        return from(undefined)
      }
    }),
    switchAll(),
    distinct()
  );
}
