import {DiceExpression, DiceResult} from "#common/dice/index.ts";
import {UserID} from "#common/legends/user/user-id.ts";
import {PublicKey} from "#common/crypto/index.ts";
import {z} from "zod";
import {ulid} from "ulid";

export const RollRequestID = z.string();//.brand("RollRequestID");
export type RollRequestID = z.infer<typeof RollRequestID>;
export function generateRollRequestID(): RollRequestID {
  return ulid() as RollRequestID;
}

export const RollVariables = z.record(z.string(), z.number(), {});
export type RollVariables = z.infer<typeof RollVariables>;

export const RollRequest = z.object({
  expression: DiceExpression,
  variables: RollVariables,
  visibility: z.object({
    audience: z.record(UserID, z.object({
      publicKey: PublicKey,
      canViewResult: z.boolean(),
      canViewExpression: z.boolean()
    }), {}),
    default: z.object({
      canViewResult: z.boolean(),
      canViewExpression: z.boolean()
    })
  })
});
export type RollRequest = z.infer<typeof RollRequest>;

export const RollRequests = z.record(RollRequestID, RollRequest, {});
export type RollRequests = z.infer<typeof RollRequests>;

export const RollResults = z.record(RollRequestID, DiceResult);
export type RollResults = z.infer<typeof RollResults>;
