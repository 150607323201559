import {Type} from "../../type/type.ts";
import {StringOperation} from "./string-operation.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const stringType: Type<string, StringOperation> = {
  apply: (value: string, operation: StringOperation): string => {
    switch (operation.type) {
      case "insert": return (
        value.substring(0, operation.offset)
        + operation.text
        + value.substring(operation.offset)
      );
      case "remove": {
        if (value.substring(operation.offset, operation.offset + operation.text.length) !== operation.text) {
          throw new Error("Invariant. text != text to remove. Got: " + value.substring(operation.offset, operation.offset + operation.text.length) + ", Expected: " + operation.text);
        }

        return (
          value.substring(0, operation.offset)
          + value.substring(operation.offset + operation.text.length)
        );
      }
      case "set": {
        if (operation.prevText !== value) {
          throw new Error("Invariant. prevText != current value. Got: " + operation.prevText + ", Expected: " + value);
        }

        return (
          operation.nextText
        );
      }
    }
    throw new Error("Unsupported Error: " + JSON.stringify(operation));
  },
  invert: (operation: StringOperation): StringOperation[] => {
    switch (operation.type) {
      case "insert": return [{
        type: "remove",
        offset: operation.offset,
        text: operation.text
      }];
      case "remove": return [{
        type: "insert",
        offset: operation.offset,
        text: operation.text
      }];
      case "set": return [{
        type: "set",
        prevText: operation.nextText,
        nextText: operation.prevText
      }];
    }
    throw new Error("Unsupported Error: " + JSON.stringify(operation));
  },
  transform: (leftOperation: StringOperation, topOperation: StringOperation, tieBreaker: boolean): StringOperation[] => {
    switch (leftOperation.type) {
      case "insert":
        switch (topOperation.type) {
          case "insert":
            if (!tieBreaker) {
              if (leftOperation.offset > topOperation.offset || (!tieBreaker && leftOperation.offset === topOperation.offset)) {
                return [{
                  type: "insert",
                  offset: leftOperation.offset + topOperation.text.length,
                  text: leftOperation.text
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "remove":
            if (!tieBreaker) {
              if (topOperation.offset <= leftOperation.offset && topOperation.offset + topOperation.text.length >= leftOperation.offset) {
                return [{
                  type: "insert",
                  offset: topOperation.offset,
                  text: leftOperation.text
                }];
              } else if (topOperation.offset + topOperation.text.length < leftOperation.offset) {
                return [{
                  type: "insert",
                  offset: leftOperation.offset - topOperation.text.length,
                  text: leftOperation.text
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "set": return tieBreaker ? [leftOperation] : [];
        }
        break;
      case "remove":
        switch (topOperation.type) {
          case "insert":
            if (!tieBreaker) {
              if (topOperation.offset <= leftOperation.offset) {
                return [{
                  type: "remove",
                  offset: leftOperation.offset + topOperation.text.length,
                  text: leftOperation.text
                }];
              } else if (topOperation.offset >= leftOperation.offset && topOperation.offset < leftOperation.offset + leftOperation.text.length) {
                return [{
                  type: "remove",
                  offset: leftOperation.offset,
                  text: leftOperation.text.substring(0, topOperation.offset - leftOperation.offset)
                }, {
                  type: "remove",
                  offset: topOperation.offset + topOperation.text.length - (topOperation.offset - leftOperation.offset),
                  text: leftOperation.text.substring(topOperation.offset - leftOperation.offset)
                }];
              } else {
                return [leftOperation];
              }
            } else {
              return [leftOperation];
            }
          case "remove":
            if (!tieBreaker) {
              if (topOperation.offset + topOperation.text.length <= leftOperation.offset) {
                return [{type: "remove", offset: leftOperation.offset - topOperation.text.length, text: leftOperation.text}];
              } else if (topOperation.offset <= leftOperation.offset && leftOperation.offset <= topOperation.offset + topOperation.text.length) {
                if (leftOperation.offset + leftOperation.text.length <= topOperation.offset + topOperation.text.length) {
                  return [];
                } else {
                  return [{type: "remove", offset: topOperation.offset, text: leftOperation.text.substring(topOperation.offset - leftOperation.offset + topOperation.text.length)}];
                }
              } else {
                if (topOperation.offset > leftOperation.offset + leftOperation.text.length) {
                  return [leftOperation];
                } else if (topOperation.offset + topOperation.text.length < leftOperation.offset + leftOperation.text.length) {
                  return [{type: "remove", offset: leftOperation.offset, text: leftOperation.text.substring(0, topOperation.offset - leftOperation.offset) + leftOperation.text.substring(topOperation.offset + topOperation.text.length - leftOperation.offset)}];
                } else {
                  return [{type: "remove", offset: leftOperation.offset, text: leftOperation.text.substring(0, topOperation.offset - leftOperation.offset)}]
                }
              }
            } else {
              return [leftOperation]
            }
          case "set": return tieBreaker ? [leftOperation] : [];
        }
        break;
      case "set":
        switch (topOperation.type) {
          case "insert": return tieBreaker ? [leftOperation] : [{
            type: "set",
            prevText: stringType.apply(leftOperation.prevText, topOperation),
            nextText: leftOperation.nextText
          }];
          case "remove": return tieBreaker ? [leftOperation] : [{
            type: "set",
            prevText: stringType.apply(leftOperation.prevText, topOperation),
            nextText: leftOperation.nextText
          }];
          case "set": return tieBreaker ? [leftOperation] : [{
            type: "set",
            prevText: topOperation.nextText,
            nextText: leftOperation.nextText
          }];
        }
        break;
    }
    throw new Error("Unsupported Error: " + JSON.stringify(leftOperation) + ", " + JSON.stringify(topOperation));
  },
  migrateValue(value: any): string {
    return value;
  },
  migrateOperation(operation: any): StringOperation[] {
    return [operation];
  },
  validate: (value: any): ValidationError[] => {
    if (typeof value === "string") return [];
    return [{path: [], data: {message: "Invalid Type. Expected string.", value}}]
  }
}
