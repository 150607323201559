import React, {useMemo} from "react";
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import {Color, HSLA} from "common/types/index.ts";

const vertexShader = `
attribute vec2 vPosition;
void main() {
  gl_Position = vec4(vPosition, 0.0, 1.0);
}
`;
const fragmentShader = `
precision mediump float;
uniform vec4 u_color;

void main() {
  gl_FragColor = u_color;
}
`;

export type GrayScreenProps = {
  color: HSLA;
};

export function Background(props: GrayScreenProps) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );

  const vbo = useArrayBuffer(useMemo(() => new Float32Array([
    -1, -1, 1, -1, 1, 1,
    1, 1, -1, 1, -1, -1
  ]), []));

  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "vPosition"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 2 * 4, 0);
  const colorLocation = useUniformLocation(program, "u_color");
  const color = useMemo(() => new Float32Array(Color.toRGBA(props.color)), [props.color]);

  return (
    <program value={program}>
      <uniform4fv location={colorLocation} data={color}/>
      <vertexArray value={vao}>
        <drawArrays mode={WebGL2RenderingContext.TRIANGLES} first={0} count={6} />
      </vertexArray>
    </program>
  );
}
