import {useCallback} from "react";
import {Apply} from "common/types/index.ts";
import {SceneViewportProperties, SceneViewportPropertiesOperation} from "../scene-viewport-properties.ts";

export function useDeselectAll(apply: Apply<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  return useCallback(() => {
    apply((prevState: SceneViewportProperties): SceneViewportPropertiesOperation[] => {
      if (prevState.selectedNodeIds.length === 0) return[];
      return [{
        type: "update-selected-node-ids",
        operations: [{
          type: "set",
          prevItems: prevState.selectedNodeIds,
          nextItems: []
        }]
      }];
    });
  }, [apply]);
}
