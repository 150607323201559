import {GameID, NodeId, Scene, SceneID} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {Fragment, useEffect, useState} from "react";
import {pipe} from "common/pipe";
import {filter, from, map, subscribe} from "common/observable";
import {Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Measurement} from "common/legends/measurement/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {MeasurementBroadcast} from "common/qlab/stream/q-lab-stream.ts";
import {AccessMask} from "common/legends/visibility/index.ts";
import {isNodeVisible} from "../../../routes/game/is-node-visible.ts";
import {MeasurementView} from "../../common/measurement/measurement-view.tsx";
import {MutableRef, Ref} from "common/ref";

export type Measurements = {
  measurement: Measurement;
  time: number;
};

const MEASUREMENT_INTERVAL = 15000;

export type ScenePingViewProps = {
  projection: Matrix4f;
  view: Transform;

  scene: Scene,
  visibilityMask: AccessMask;
  visibilityNodeIDs: NodeId[];
  gameID: GameID;
  sceneID: SceneID;
};
export function SceneMeasurementView({projection, view, gameID, sceneID, scene, visibilityMask, visibilityNodeIDs}: ScenePingViewProps) {
  const [measurements, setMeasurements] = useState<Measurements[]>([]);

  const instance = useInstance();
  const userID = useUserID()!;
  useEffect(() => pipe(
    instance.streamObservable(gameID),
    filter(effect => effect.type === "measurement"),
    map(effect => effect as MeasurementBroadcast),
    filter(effect => effect.userID !== userID),
    filter(effect => effect.data?.resourceRef.type === "scene" && effect.data?.resourceRef.sceneID === sceneID),
    map(effect => effect.data as Measurement),
    subscribe(measurement => {
      setMeasurements(measurements => {
        if (measurements.findIndex(m => m.measurement?.id === measurement?.id) !== -1) {
          return measurements.map(m => m.measurement?.id === measurement?.id ? {
            measurement: measurement,
            time: new Date().getTime()
          } : m );
        } else {
          return [...measurements, {
            measurement: measurement,
            time: new Date().getTime()
          }];
        }
      });
      setTimeout(() => {
        setMeasurements(pings => pings.filter(p => p.time + MEASUREMENT_INTERVAL > new Date().getTime()))
      }, MEASUREMENT_INTERVAL*1.1);
    })
  ), [gameID, sceneID, userID, setMeasurements]);

  return <>
    {measurements.map((measurement, index) => {
      if (measurement.measurement?.type === "path" && measurement.measurement.nodeID) {
        if (!isNodeVisible(scene.children, measurement.measurement.nodeID, visibilityMask, visibilityNodeIDs)) {
          return <Fragment key={index} />;
        }
      }

      const ref: Ref<Measurement> = new MutableRef({
        value() {
          return measurement.measurement
        },
        observe: from(measurement.measurement),
        apply: _ => {throw new Error("Unsupported.")}
      });

      return <MeasurementView
        key={index}
        projection={projection} view={view}
        measurementRef={ref} />
      }
    )}
  </>
}
