import {z, ZodType} from "zod";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {MutableRef} from "#common/ref";

export type InsertSetOperation<Item> = {
  type: "insert";
  item: Item
};
export type DeleteSetOperation<Item> = {
  type: "delete";
  item: Item
};
export type SetSetOperation<Item> = {
  type: "set";
  nextItems: Item[];
  prevItems: Item[];
};

export type SetOperation<Item> =
  | InsertSetOperation<Item>
  | DeleteSetOperation<Item>
  | SetSetOperation<Item>
  ;
export function SetOperation<Item, ZodItem extends ZodType<Item>>(Item: ZodItem) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("insert"), item: Item}),
    z.object({type: z.literal("delete"), item: Item}),
    z.object({type: z.literal("set"), prevItems: z.array(Item), nextItems: z.array(Item)})
  ]);
}

export const SetFn = {
  set<Item>(prevItems: Item[], nextItems: Item[]): SetOperation<Item>[] {
    return [{type: "set", prevItems, nextItems}];
  },
  insert<Item>(item: Item): SetOperation<Item>[] {
    return [{type: "insert", item}];
  },
  delete<Item>(item: Item): SetOperation<Item>[] {
    return [{type: "delete", item}];
  }
} as const;

export function SetPropertySignal<AV, AO, BV>(
  valueFn: (value: AV) => BV[],
  updateFn: (operations: SetOperation<BV>[]) => AO[]
): (signal: MutableRef<AV, AO[]>) => MutableRef<BV[], SetOperation<BV>[]> {
  return (signal: MutableRef<AV, AO[]>): MutableRef<BV[], SetOperation<BV>[]> =>
    PropertyRef(valueFn, updateFn)(signal);
}

