import {Vector4} from "../vector/vector4.ts";

export type Matrix4x4 = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
];

export const Matrix4x4Fn = {
  MULTIPLICATIVE_IDENTITY: [1, 0, 0, 0,  0, 1, 0, 0,  0, 0, 1, 0,  0, 0, 0, 1] as Matrix4x4,
  orthogonal: (left: number, right: number, top: number, bottom: number, near: number, far: number): Matrix4x4 => {
    return [
      2 / (right - left),                  0,                 0, - (right + left) / (right - left),
      0, 2 / (top - bottom),                 0, - (top + bottom) / (top - bottom),
      0,                  0, -2 / (far - near),     - (far + near) / (far - near),
      0,                  0,                 0,                                 1
    ];
  },
  adjoint: ([m00, m01, m02, m03,  m10, m11, m12, m13,  m20, m21, m22, m23,  m30, m31, m32, m33]: Matrix4x4): Matrix4x4 => {
    return [
      m12 * m23 * m31 - m13 * m22 * m31 + m13 * m21 * m32 - m11 * m23 * m32 - m12 * m21 * m33 + m11 * m22 * m33,
      m03 * m22 * m31 - m02 * m23 * m31 - m03 * m21 * m32 + m01 * m23 * m32 + m02 * m21 * m33 - m01 * m22 * m33,
      m02 * m13 * m31 - m03 * m12 * m31 + m03 * m11 * m32 - m01 * m13 * m32 - m02 * m11 * m33 + m01 * m12 * m33,
      m03 * m12 * m21 - m02 * m13 * m21 - m03 * m11 * m22 + m01 * m13 * m22 + m02 * m11 * m23 - m01 * m12 * m23,

      m13 * m22 * m30 - m12 * m23 * m30 - m13 * m20 * m32 + m10 * m23 * m32 + m12 * m20 * m33 - m10 * m22 * m33,
      m02 * m23 * m30 - m03 * m22 * m30 + m03 * m20 * m32 - m00 * m23 * m32 - m02 * m20 * m33 + m00 * m22 * m33,
      m03 * m12 * m30 - m02 * m13 * m30 - m03 * m10 * m32 + m00 * m13 * m32 + m02 * m10 * m33 - m00 * m12 * m33,
      m02 * m13 * m20 - m03 * m12 * m20 + m03 * m10 * m22 - m00 * m13 * m22 - m02 * m10 * m23 + m00 * m12 * m23,

      m11 * m23 * m30 - m13 * m21 * m30 + m13 * m20 * m31 - m10 * m23 * m31 - m11 * m20 * m33 + m10 * m21 * m33,
      m03 * m21 * m30 - m01 * m23 * m30 - m03 * m20 * m31 + m00 * m23 * m31 + m01 * m20 * m33 - m00 * m21 * m33,
      m01 * m13 * m30 - m03 * m11 * m30 + m03 * m10 * m31 - m00 * m13 * m31 - m01 * m10 * m33 + m00 * m11 * m33,
      m03 * m11 * m20 - m01 * m13 * m20 - m03 * m10 * m21 + m00 * m13 * m21 + m01 * m10 * m23 - m00 * m11 * m23,

      m12 * m21 * m30 - m11 * m22 * m30 - m12 * m20 * m31 + m10 * m22 * m31 + m11 * m20 * m32 - m10 * m21 * m32,
      m01 * m22 * m30 - m02 * m21 * m30 + m02 * m20 * m31 - m00 * m22 * m31 - m01 * m20 * m32 + m00 * m21 * m32,
      m02 * m11 * m30 - m01 * m12 * m30 - m02 * m10 * m31 + m00 * m12 * m31 + m01 * m10 * m32 - m00 * m11 * m32,
      m01 * m12 * m20 - m02 * m11 * m20 + m02 * m10 * m21 - m00 * m12 * m21 - m01 * m10 * m22 + m00 * m11 * m22
    ];
  },
  multiply: ([m00, m01, m02, m03,  m10, m11, m12, m13,  m20, m21, m22, m23,  m30, m31, m32, m33]: Matrix4x4, c: number): Matrix4x4 => {
    return [
      c * m00, c * m01, c * m02, c * m03,
      c * m10, c * m11, c * m12, c * m13,
      c * m20, c * m21, c * m22, c * m23,
      c * m30, c * m31, c * m32, c * m33
    ];
  },
  multiplyMatrix: ([ma00, ma01, ma02, ma03,  ma10, ma11, ma12, ma13,  ma20, ma21, ma22, ma23,  ma30, ma31, ma32, ma33]: Matrix4x4, [mb00, mb01, mb02, mb03,  mb10, mb11, mb12, mb13,  mb20, mb21, mb22, mb23,  mb30, mb31, mb32, mb33]: Matrix4x4): Matrix4x4 => {
    return [
      ma00 * mb00 + ma01 * mb10 + ma02 * mb20 + ma03 * mb30,
      ma00 * mb01 + ma01 * mb11 + ma02 * mb21 + ma03 * mb31,
      ma00 * mb02 + ma01 * mb12 + ma02 * mb22 + ma03 * mb32,
      ma00 * mb03 + ma01 * mb13 + ma02 * mb23 + ma03 * mb33,

      ma10 * mb00 + ma11 * mb10 + ma12 * mb20 + ma13 * mb30,
      ma10 * mb01 + ma11 * mb11 + ma12 * mb21 + ma13 * mb31,
      ma10 * mb02 + ma11 * mb12 + ma12 * mb22 + ma13 * mb32,
      ma10 * mb03 + ma11 * mb13 + ma12 * mb23 + ma13 * mb33,

      ma20 * mb00 + ma21 * mb10 + ma22 * mb20 + ma23 * mb30,
      ma20 * mb01 + ma21 * mb11 + ma22 * mb21 + ma23 * mb31,
      ma20 * mb02 + ma21 * mb12 + ma22 * mb22 + ma23 * mb32,
      ma20 * mb03 + ma21 * mb13 + ma22 * mb23 + ma23 * mb33,

      ma30 * mb00 + ma31 * mb10 + ma32 * mb20 + ma33 * mb30,
      ma30 * mb01 + ma31 * mb11 + ma32 * mb21 + ma33 * mb31,
      ma30 * mb02 + ma31 * mb12 + ma32 * mb22 + ma33 * mb32,
      ma30 * mb03 + ma31 * mb13 + ma32 * mb23 + ma33 * mb33
    ];
  },
  multiplyVector: ([m00, m01, m02, m03,  m10, m11, m12, m13,  m20, m21, m22, m23,  m30, m31, m32, m33]: Matrix4x4, [v0, v1, v2, v3]: Vector4): Vector4 => {
    return [
      v0*m00 + v1*m01 + v2*m02 + v3*m03,
      v0*m10 + v1*m11 + v2*m12 + v3*m13,
      v0*m20 + v1*m21 + v2*m22 + v3*m23,
      v0*m30 + v1*m31 + v2*m32 + v3*m33
    ];
  },
  determinant: ([m00, m01, m02, m03,  m10, m11, m12, m13,  m20, m21, m22, m23,  m30, m31, m32, m33]: Matrix4x4): number => {
    return m03 * m12 * m21 * m30 - m02 * m13 * m21 * m30 - m03 * m11 * m22 * m30 + m01 * m13 * m22 * m30 +
      m02 * m11 * m23 * m30 - m01 * m12 * m23 * m30 - m03 * m12 * m20 * m31 + m02 * m13 * m20 * m31 +
      m03 * m10 * m22 * m31 - m00 * m13 * m22 * m31 - m02 * m10 * m23 * m31 + m00 * m12 * m23 * m31 +
      m03 * m11 * m20 * m32 - m01 * m13 * m20 * m32 - m03 * m10 * m21 * m32 + m00 * m13 * m21 * m32 +
      m01 * m10 * m23 * m32 - m00 * m11 * m23 * m32 - m02 * m11 * m20 * m33 + m01 * m12 * m20 * m33 +
      m02 * m10 * m21 * m33 - m00 * m12 * m21 * m33 - m01 * m10 * m22 * m33 + m00 * m11 * m22 * m33
  },
  invert: (value: Matrix4x4): Matrix4x4 => {
    return Matrix4x4Fn.multiply(Matrix4x4Fn.adjoint(value), (1 / Matrix4x4Fn.determinant(value)));
  }
};
