import {forwardRef, HTMLAttributes, Ref} from "react";
import "./input-group.css";
import {twMerge} from "tailwind-merge";

export type InputGroupSize = "small" | "medium" | "large";
export type InputGroupProps = Omit<HTMLAttributes<HTMLLabelElement>, "size"> & {
  size?: InputGroupSize;
  disabled?: boolean;
};
export const InputGroup = forwardRef(function InputGroup({size = "small", disabled, className, ...props}: InputGroupProps, ref: Ref<HTMLLabelElement>): JSX.Element {
  return <label ref={ref} className={twMerge(
    "shrink-0 group border-y border-solid border-zinc-800/40 bg-zinc-900/50 focus-within:border-blue-600/50 px-4 gap-4",
    "outline-none",
    "flex flex-row items-center",
    "disabled:opacity-50",
    size === "large" ? "h-[64px]" : "",
    size === "medium" ? "h-[48px]" : "",
    size === "small" ? "h-[40px]" : "",
    className
  )} aria-disabled={disabled} {...props} />
});
