import {GroupNode, NodeId} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {AccessMask, AccessMaskFn} from "common/legends/visibility/index.ts";
import {useIsSelected} from "./use-is-selected.ts";
import {NodeView} from "./node-view.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";

export type GroupViewProps = {
  projection: Matrix4f;
  view: Transform;
  visibilityMask: AccessMask;
  accessNodeIDs: NodeId[];
  model: Transform;
  opacity: number;
  value: GroupNode;
};

export function GroupNodeView({projection, view, visibilityMask, accessNodeIDs, model, opacity, value}: GroupViewProps) {
  const valueOpacity = value.opacity * opacity;
  const valueModel = Transform.divide(value.transform, model);
  const isSelected = useIsSelected(value.id);

  if (!AccessMaskFn.canSee(visibilityMask, value.visibilityLayer) && !accessNodeIDs.includes(value.id)) return <></>;
  return (<binder>
    {value.children.map((child) => {
      return <NodeView key={child.data.id} projection={projection} view={view} visibilityMask={visibilityMask} accessNodeIDs={accessNodeIDs} value={child} model={valueModel} opacity={valueOpacity} />
    })}
    {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={value.origin} size={value.size} color={Color.WHITE} repeatX={1} repeatY={1} />}
  </binder>);
}