import React, {useContext} from "react";
import {SheetReference} from "./sheet-reference.ts";
import {NOOP_SIGNAL, Ref} from "common/ref";
import {Optional} from "common/types/index.ts";

const SheetReferenceContext = React.createContext<Ref<Optional<SheetReference>> | undefined>(undefined);

export const SheetReferenceProvider = SheetReferenceContext.Provider;

export function useSheetReference(): Ref<Optional<SheetReference>> {
  return useContext(SheetReferenceContext) || NOOP_SIGNAL;
}

