import {z, ZodType} from "zod";
import {applyAll, ConstantOperation, constantType, invertAll, NumberOperation, numberType, ObjectType, Point, PointOperation, pointType, transformAll, Type} from "#common/types/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export function GraphVertex<V>(VertexData: ZodType<V>) {
  return z.object({
    coordinate: Point,
    data: VertexData
  });
}
export type GraphVertex<V> = {coordinate: Point, data: V};
export function GraphEdge<S>(EdgeData: ZodType<S>) {
  return z.object({
    start: z.number(),
    end: z.number(),
    controlPoint1: z.tuple([z.number(), z.number()]),
    controlPoint2: z.tuple([z.number(), z.number()]),
    resolution: z.number(),
    data: EdgeData
  });
}
export type GraphEdge<E> = {start: number, end: number; controlPoint1: Point, controlPoint2: Point, resolution: number, data: E};
export function Graph<V, E>(VertexData: ZodType<V>, EdgeData: ZodType<E>) {
  return z.object({
    vertices: z.array(GraphVertex(VertexData)),
    edges: z.array(GraphEdge(EdgeData))
  })
}
export type Graph<V, E> = {vertices: GraphVertex<V>[], edges: GraphEdge<E>[]};

export function GraphVertexOperation<VO>(VertexDataOperation: ZodType<VO>) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("update-coordinate"), operations: z.array(PointOperation)}),
    z.object({type: z.literal("update-data"), operations: z.array(VertexDataOperation)})
  ]);
}
export type GraphVertexOperation<VO> =
  | {type: "update-coordinate", operations: PointOperation[]}
  | {type: "update-data", operations: VO[]}
  ;
export function GraphVertexType<VV, VO>(dataType: Type<VV, VO>) {
  return new ObjectType({
    coordinate: pointType,
    data: dataType
  });
}

export function GraphEdgeOperation<E>(EdgeDataOperation: ZodType<E>) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("update-start"), operations: z.array(ConstantOperation)}),
    z.object({type: z.literal("update-end"), operations: z.array(ConstantOperation)}),
    z.object({type: z.literal("update-control-point-1"), operations: z.array(PointOperation)}),
    z.object({type: z.literal("update-control-point-2"), operations: z.array(PointOperation)}),
    z.object({type: z.literal("update-resolution"), operations: z.array(NumberOperation)}),
    z.object({type: z.literal("update-data"), operations: z.array(EdgeDataOperation)})
  ]);
}
export type GraphEdgeOperation<SO> =
  | {type: "update-start", operations: ConstantOperation[]}
  | {type: "update-end", operations: ConstantOperation[]}
  | {type: "update-control-point-1", operations: PointOperation[]}
  | {type: "update-control-point-2", operations: PointOperation[]}
  | {type: "update-resolution", operations: NumberOperation[]}
  | {type: "update-data", operations: SO[]}
  ;
export function GraphEdgeType<EV, EO>(dataType: Type<EV, EO>) {
  return new ObjectType({
    start: constantType,
    end: constantType,
    controlPoint1: pointType,
    controlPoint2: pointType,
    resolution: numberType,
    data: dataType
  });
}

export function GraphOperation<VV, VO, EV, EO>(
  VertexData: ZodType<VV>,
  VertexDataOperation: ZodType<VO>,
  EdgeData: ZodType<EV>,
  EdgeDataOperation: ZodType<EO>
) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("insert-vertex"), index: z.number(), value: GraphVertex(VertexData)}),
    z.object({type: z.literal("update-vertex"), index: z.number(), operations: z.array(GraphVertexOperation(VertexDataOperation))}),
    z.object({type: z.literal("delete-vertex"), index: z.number(), prev: GraphVertex(VertexData)}),
    z.object({type: z.literal("insert-edge"), index: z.number(), value: GraphEdge(EdgeData)}),
    z.object({type: z.literal("update-edge"), index: z.number(), operations: z.array(GraphEdgeOperation(EdgeDataOperation))}),
    z.object({type: z.literal("delete-edge"), index: z.number(), prev: GraphEdge(EdgeData)})
  ]);
}
export type GraphOperation<PV, PO, SV, SO> =
  | {type: "insert-vertex", index: number, value: GraphVertex<PV>}
  | {type: "update-vertex", index: number, operations: GraphVertexOperation<PO>[]}
  | {type: "delete-vertex", index: number, prev: GraphVertex<PV>}
  | {type: "insert-edge", index: number, value: GraphEdge<SV>}
  | {type: "update-edge", index: number, operations: GraphEdgeOperation<SO>[]}
  | {type: "delete-edge", index: number, prev: GraphEdge<SV>}
  ;

export function GraphType<PV, PO, SV, SO>(vertexDataType: Type<PV, PO>, edgeDataType: Type<SV, SO>): Type<Graph<PV, SV>, GraphOperation<PV, PO, SV, SO>> {
  const pointType = GraphVertexType(vertexDataType);
  const edgeType = GraphEdgeType(edgeDataType);
  return {
    apply: (value: Graph<PV, SV>, operation: GraphOperation<PV, PO, SV, SO>): Graph<PV, SV> => {
      switch (operation.type) {
        case "insert-vertex": {
          return ({
            vertices: [
              ...value.vertices.slice(0, operation.index),
              operation.value,
              ...value.vertices.slice(operation.index)
            ],
            edges: value.edges.map(edge => {
              const newStart = edge.start <= operation.index ? edge.start : edge.start + 1;
              const newEnd = edge.end <= operation.index ? edge.end : edge.end + 1;
              return ({...edge, start: newStart, end: newEnd});
            })
          });
        }
        case "update-vertex": {
          return ({
            vertices: value.vertices.map((vertex, index) => index === operation.index
              ? applyAll(pointType, vertex, operation.operations)
              : vertex
            ),
            edges: value.edges
          });
        }
        case "delete-vertex": {
          return ({
            vertices: value.vertices.filter((_, index) => index !== operation.index),
            edges: value.edges.map(edge => {
              const newStart = edge.start < operation.index ? edge.start : edge.start - 1;
              const newEnd = edge.end < operation.index ? edge.end : edge.end - 1;
              return ({...edge, start: newStart, end: newEnd});
            })
          });
        }
        case "insert-edge": {
          return ({
            vertices: value.vertices,
            edges: [
              ...value.edges.slice(0, operation.index),
              operation.value,
              ...value.edges.slice(operation.index)
            ]
          });
        }
        case "update-edge": {
          return ({
            vertices: value.vertices,
            edges: value.edges.map((edge, index) => index === operation.index
              ? applyAll(edgeType, edge, operation.operations)
              : edge
            )
          });
        }
        case "delete-edge": {
          return ({
            vertices: value.vertices,
            edges: value.edges.filter((_, index) => index !== operation.index)
          });
        }
        default: return value;
      }
    },
    invert: (operation: GraphOperation<PV, PO, SV, SO>): GraphOperation<PV, PO, SV, SO>[] => {
      switch (operation.type) {
        case "insert-vertex": return [{type: "delete-vertex", index: operation.index, prev: operation.value}];
        case "update-vertex": return [{type: "update-vertex", index: operation.index, operations: invertAll(pointType, operation.operations)}];
        case "delete-vertex": return [{type: "insert-vertex", index: operation.index, value: operation.prev}];
        case "insert-edge": return [{type: "delete-edge", index: operation.index, prev: operation.value}];
        case "update-edge": return [{type: "update-edge", index: operation.index, operations: invertAll(edgeType, operation.operations)}];
        case "delete-edge": return [{type: "insert-edge", index: operation.index, value: operation.prev}];
      }
    },
    migrateValue: (value: any): Graph<PV, SV> => {
      return ({
        vertices: value.vertices.map((vertex: any) => ({
          ...vertex,
          data: vertexDataType.migrateValue(vertex.data)
        })),
        edges: value.edges.map((edge: any) => ({
          ...edge,
          data: edgeDataType.migrateValue(edge.data)
        }))
      });
    },
    migrateOperation: (operation: any): GraphOperation<PV, PO, SV, SO>[] => {
      return [operation];
    },
    validate: (value: any): ValidationError[] => {
      const errors: ValidationError[] = [];
      if (value.vertices === undefined || !Array.isArray(value.vertices)) {
        errors.push({path: [], data: "Missing field 'vertices'"});
      } else {
        errors.push(...(value.vertices as GraphVertex<PV>[]).flatMap((vertex, index) => pointType.validate(vertex).map(t => ({path: ["vertices", index, ...t.path], data: t.data}))))
      }
      if (value.edges === undefined || !Array.isArray(value.edges)) {
        errors.push({path: [], data: "Missing field 'edges'"});
      } else {
        errors.push(...(value.edges as GraphEdge<SV>[]).flatMap((vertex, index) => edgeType.validate(vertex).map(t => ({path: ["vertices", index, ...t.path], data: t.data}))))
      }
      return errors;
    },
    transform: (leftOperation: GraphOperation<PV, PO, SV, SO>, topOperation: GraphOperation<PV, PO, SV, SO>, tieBreaker: boolean): GraphOperation<PV, PO, SV, SO>[] => {
      switch (leftOperation.type) {
        case "insert-vertex": {
          if (tieBreaker) return [leftOperation];
          switch (topOperation.type) {
            case "insert-vertex": return [{
              type: "insert-vertex",
              index: leftOperation.index >= topOperation.index ? leftOperation.index + 1 : leftOperation.index,
              value: leftOperation.value
            }];
            case "update-vertex": return [leftOperation];
            case "delete-vertex": return [{
              type: "insert-vertex",
              index: leftOperation.index >= topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              value: leftOperation.value
            }];
            case "insert-edge": return [leftOperation];
            case "update-edge": return [leftOperation];
            case "delete-edge": return [leftOperation];
          }
          break;
        }
        case "update-vertex": {
          if (tieBreaker) return [leftOperation];
          switch (topOperation.type) {
            case "insert-vertex": return [{
              type: "update-vertex",
              index: leftOperation.index >= topOperation.index ? leftOperation.index + 1 : leftOperation.index,
              operations: leftOperation.operations
            }];
            case "update-vertex": return [{
              type: "update-vertex",
              index: leftOperation.index,
              operations: leftOperation.index === topOperation.index
                ? transformAll(pointType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
                : leftOperation.operations
            }];
            case "delete-vertex": return leftOperation.index === topOperation.index ? [] : [{
              type: "update-vertex",
              index: leftOperation.index > topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              operations: leftOperation.operations
            }];
            default: return [leftOperation];
          }
          break;
        }
        case "delete-vertex": {
          if (tieBreaker) return [leftOperation];
          switch (topOperation.type) {
            case "insert-vertex": return [{
              type: "delete-vertex",
              index: leftOperation.index >= topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              prev: leftOperation.prev
            }];
            case "update-vertex": return [{
              type: "delete-vertex",
              index: leftOperation.index,
              prev: leftOperation.index === topOperation.index
                ? applyAll(pointType, leftOperation.prev, topOperation.operations)
                : leftOperation.prev
            }];
            case "delete-vertex": return leftOperation.index === topOperation.index ? [] : [{
              type: "delete-vertex",
              index: leftOperation.index > topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              prev: leftOperation.prev
            }];
            default: return [leftOperation];
          }
          break;
        }
        case "insert-edge": {
          if (tieBreaker) return [leftOperation];
          switch (topOperation.type) {
            case "insert-vertex": return [{
              type: "insert-edge",
              index: leftOperation.index,
              value: {
                ...leftOperation.value,
                start: leftOperation.value.start <= topOperation.index ? leftOperation.value.start + 1 : leftOperation.value.start,
                end: leftOperation.value.end <= topOperation.index ? leftOperation.value.end + 1 : leftOperation.value.end
              }
            }];
            case "update-vertex": return [leftOperation];
            case "delete-vertex": return (leftOperation.value.start === topOperation.index || leftOperation.value.end === topOperation.index) ? [] : [{
              type: "insert-edge",
              index: leftOperation.index,
              value: {
                ...leftOperation.value,
                start: leftOperation.value.start > topOperation.index ? leftOperation.value.start - 1 : leftOperation.value.start,
                end: leftOperation.value.end > topOperation.index ? leftOperation.value.end - 1 : leftOperation.value.end
              }
            }];
            case "insert-edge": return [{
              type: "insert-edge",
              index: leftOperation.index <= topOperation.index ? leftOperation.index + 1 : leftOperation.index,
              value: leftOperation.value
            }];
            case "update-edge": return [leftOperation];
            case "delete-edge": return [{
              type: "insert-edge",
              index: leftOperation.index >= topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              value: leftOperation.value
            }];
          }
          break;
        }
        case "update-edge": {
          switch (topOperation.type) {
            case "insert-vertex": return [leftOperation];
            case "update-vertex": return [leftOperation];
            case "delete-vertex": return [leftOperation]; // TODO: Not sure if correct...
            case "insert-edge": return [{
              type: "update-edge",
              index: leftOperation.index >= topOperation.index ? leftOperation.index + 1 : leftOperation.index,
              operations: leftOperation.operations
            }];
            case "update-edge": return [{
              type: "update-edge",
              index: leftOperation.index,
              operations: leftOperation.index === topOperation.index
                ? transformAll(edgeType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
                : leftOperation.operations
            }];
            case "delete-edge": return leftOperation.index === topOperation.index ? [] : [{
              type: "update-edge",
              index: leftOperation.index > topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              operations: leftOperation.operations
            }];
          }
          break;
        }
        case "delete-edge": {
          switch (topOperation.type) {
            case "insert-vertex": return [leftOperation];
            case "update-vertex": return [leftOperation];
            case "delete-vertex": return [leftOperation]; // TODO: Not sure if correct...
            case "insert-edge": return [{
              type: "delete-edge",
              index: leftOperation.index >= topOperation.index ? leftOperation.index + 1 : leftOperation.index,
              prev: leftOperation.prev
            }];
            case "update-edge": return [{
              type: "delete-edge",
              index: leftOperation.index,
              prev: leftOperation.index === topOperation.index
                ? applyAll(edgeType, leftOperation.prev, topOperation.operations)
                : leftOperation.prev
            }];
            case "delete-edge": return leftOperation.index === topOperation.index ? [] : [{
              type: "delete-edge",
              index: leftOperation.index > topOperation.index ? leftOperation.index - 1 : leftOperation.index,
              prev: leftOperation.prev
            }]
          }
          break;
        }
      }
      return [];
    }
  }
}
