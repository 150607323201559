import {Dnd5eAttributeFn, NodeId} from "common/legends/index.ts";
import {useCallback} from "react";
import {Optional} from "common/types/index.ts";
import {Dice} from "common/dice/index.ts";
import {toPromise} from "common/observable";
import {Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-hit-dice.ts";
import {useSendFeatureMessage} from "./use-send-feature-message.ts";
import {MutableRef} from "common/ref";

export function useRollHitDice() {
  const sendFeatureMessage = useSendFeatureMessage();
  return useCallback(async (
    hitDice: MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>,
    nodeID: Optional<NodeId>,
    qty: number,
    con: number
  ) => {
    const hitDiceValue = await toPromise(hitDice.observe);
    if (hitDiceValue.current >= qty) {
      const diceFace = hitDiceValue.diceFace;
      await sendFeatureMessage(
        nodeID,
        `Hit Dice`,
        [{
          damageType: "healing",
          hitExpression: Dice.assertDiceExpression(`${qty}d${diceFace}+${qty}*${Dnd5eAttributeFn.modifier(con)}[CON]`)
        }],
        undefined,
        {}
      );

      hitDice.apply(_ => [{
        type: "update-current",
        operations: [{type: "decrement", amount: qty}]
      }]);
    }
  }, [sendFeatureMessage]);
}
