import {
  applyAll,
  BooleanOperation,
  booleanType,
  ColorOperation,
  colorType,
  ConstantOperation,
  constantType,
  HSLA,
  ListPropertyRef,
  NumberOperation,
  numberType,
  ObjectType,
  Point,
  PointFn,
  PointOperation,
  PropertyRef,
  StringOperation,
  Transform,
  TransformOperation,
  Type
} from "#common/types/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {NodeCondition, NodeConditionOperation} from "#common/legends/node/condition/node-condition.ts";
import {Curve, Spline} from "#common/types/generic/spline/index.ts";
import {z} from "zod";
import {NodeId} from "#common/legends/index.ts";
import {Graph, GraphEdge, GraphEdgeOperation, GraphOperation, GraphType, GraphVertex, GraphVertexOperation} from "#common/types/generic/graph/graph.ts";
import {Vector2} from "#common/math/vector/vector2.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export const WallGraphVertexData = z.undefined();
export type WallGraphVertexData = z.infer<typeof WallGraphVertexData>;
export const WallGraphVertexOperation = GraphVertexOperation(ConstantOperation);
export type WallGraphVertexOperation = z.infer<typeof WallGraphVertexOperation>;
export type WallGraphVertex = GraphVertex<WallGraphVertexData>;
export function WallGraphVertexSignals(value: MutableRef<WallGraphVertex, WallGraphVertexOperation[]>) {
  return ({
    coordinate: PropertyRef<WallGraphVertex, WallGraphVertexOperation, Point, PointOperation>(
      value => value.coordinate,
      operations => [{type: "update-coordinate", operations}]
    )(value)
  })
}

export const WallGraphEdgeData = z.object({
  blockVisibilityLeft: z.boolean(),
  blockVisibilityRight: z.boolean()
});
export const WallGraphEdgeDataOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-block-visibility-left"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-block-visibility-right"), operations: z.array(BooleanOperation)})
]);
export type WallGraphEdgeDataOperation = z.infer<typeof WallGraphEdgeDataOperation>;
export type WallGraphEdgeData = z.infer<typeof WallGraphEdgeData>;
export type WallGraphEdge = GraphEdge<WallGraphEdgeData>;
export const WallGraphEdgeOperation = GraphEdgeOperation(WallGraphEdgeDataOperation);
export type WallGraphEdgeOperation = z.infer<typeof WallGraphEdgeOperation>;
export const wallGraphEdgeDataType: Type<WallGraphEdgeData, WallGraphEdgeDataOperation> = new ObjectType({
  blockVisibilityLeft: booleanType,
  blockVisibilityRight: booleanType
}, (v: any) => {
  if (v.blockVisibilityLeft === undefined) {
    v.blockVisibilityLeft = true;
  }
  if (v.blockVisibilityRight === undefined) {
    v.blockVisibilityRight = true;
  }
  return v;
})


export function WallGraphEdgeSignals(value: MutableRef<WallGraphEdge, WallGraphEdgeOperation[]>) {
  return ({
    controlPoint1: PropertyRef<WallGraphEdge, WallGraphEdgeOperation, Point, PointOperation>(
      value => value.controlPoint1,
      operations => [{type: "update-control-point-1", operations}]
    )(value),
    controlPoint2: PropertyRef<WallGraphEdge, WallGraphEdgeOperation, Point, PointOperation>(
      value => value.controlPoint2,
      operations => [{type: "update-control-point-2", operations}]
    )(value),
    resolution: PropertyRef<WallGraphEdge, WallGraphEdgeOperation, number, NumberOperation>(
      value => value.resolution,
      operations => [{type: "update-resolution", operations}]
    )(value),
    blockVisibilityLeft: PropertyRef<WallGraphEdge, WallGraphEdgeOperation, boolean, BooleanOperation>(
      value => value.data.blockVisibilityLeft,
      operations => [{type: "update-data", operations: [{type: "update-block-visibility-left", operations}]}]
    )(value),
    blockVisibilityRight: PropertyRef<WallGraphEdge, WallGraphEdgeOperation, boolean, BooleanOperation>(
      value => value.data.blockVisibilityRight,
      operations => [{type: "update-data", operations: [{type: "update-block-visibility-right", operations}]}]
    )(value)
  })
}

export const WallGraph = Graph(z.undefined(), WallGraphEdgeData)
export type WallGraph = Graph<undefined, WallGraphEdgeData>;

export const WallGraphOperation = GraphOperation(WallGraphVertexData, ConstantOperation, WallGraphEdgeData, ConstantOperation);
export type WallGraphOperation = GraphOperation<WallGraphVertexData, ConstantOperation, WallGraphEdgeData, WallGraphEdgeDataOperation>;
export const wallGraphType: Type<WallGraph, WallGraphOperation> = GraphType(constantType, wallGraphEdgeDataType);

export type WallNode = LocalNode & {
  opacity: number;
  color: HSLA;
  graph: WallGraph;
};

export type WallNodeOperation =
  | LocalNodeOperation
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-graph", operations: WallGraphOperation[]}
  | {type: "update-color", operations: ColorOperation[]}
  ;

export const wallNodeType: Type<WallNode, WallNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  opacity: numberType,
  color: colorType,
  graph: wallGraphType
}), (value: any) => {
  value = localNodeUpdater(value);
  if (value.color === undefined) value.color = [0, 0, 0, 1];
  if (value.segments !== undefined) {
    const graphs = (value.segments as any[]).map(segment => WallGraphFn.fromSpline(segment.spline));
    value.graph = graphs.reduce(WallGraphFn.merge);
    delete value["segments"];
  }
  if (value.opacity === undefined) value.opacity = 0.5;
  if (value.origin === undefined) value.origin = [0, 0];
  return value;
}, (operation) => {
  if (operation.type === "update-segments") return [];
  return [operation];
});

declare module "./node.ts" {
  export interface NodeTypes {
    "wall": typeof wallNodeType
  }
}

export function WallNodeSignals(value: MutableRef<WallNode, WallNodeOperation[]>) {
  return {
    id: PropertyRef<WallNode, WallNodeOperation, NodeId, ConstantOperation>(
      value => value.id,
      operations => [{type: "update-id", operations}]
    )(value),
    name: PropertyRef<WallNode, WallNodeOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    transform: PropertyRef<WallNode, WallNodeOperation, Transform, TransformOperation>(
      value => value.transform,
      operations => [{type: "update-transform", operations}]
    )(value),
    opacity: PropertyRef<WallNode, WallNodeOperation, number, NumberOperation>(
      value => value.opacity,
      operations => [{type: "update-opacity", operations}]
    )(value),
    visibilityLayer: PropertyRef<WallNode, WallNodeOperation, VisibilityLayer, VisibilityLayerOperation>(
      value => value.visibilityLayer,
      operations => [{type: "update-visibility-layer", operations}]
    )(value),
    conditions: ListPropertyRef<WallNode, WallNodeOperation, NodeCondition, NodeConditionOperation>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value),
    color: PropertyRef<WallNode, WallNodeOperation, HSLA, ColorOperation>(
      value => value.color,
      operations => [{type: "update-color", operations}]
    )(value),
    graph: PropertyRef<WallNode, WallNodeOperation, WallGraph, WallGraphOperation>(
      value => value.graph,
      operations => [{type: "update-graph", operations}]
    )(value)
  };
}

export const WallGraphFn = {
  fromSpline: (spline: Spline): WallGraph => {
    const vertices: WallGraphVertex[] = [{coordinate: spline.start, data: undefined}];
    const edges: WallGraphEdge[] = [];
    let lastCurve: Curve = {end: spline.start, controlPoint1: spline.controlPoint1, controlPoint2: spline.controlPoint2};
    for (let curveIndex = 0; curveIndex < spline.curves.length; curveIndex++) {
      let curve = spline.curves[curveIndex];
      const index = vertices.findIndex(vertex => PointFn.equals(vertex.coordinate, curve.end));
      if (index === -1) vertices.push({coordinate: curve.end, data: undefined});
      const start = vertices.findIndex(vertex => PointFn.equals(vertex.coordinate, lastCurve.end));
      const end = vertices.findIndex(vertex => PointFn.equals(vertex.coordinate, curve.end));
      edges.push({
        start: start,
        end: end,
        controlPoint1: lastCurve.controlPoint2,
        controlPoint2: curve.controlPoint1,
        resolution: 8,
        data: {
          blockVisibilityLeft: true,
          blockVisibilityRight: true
        }
      });
      lastCurve = curve;
    }
    return {vertices, edges};
  },
  merge: (a: WallGraph, b: WallGraph): WallGraph => {
    return applyAll(wallGraphType, a, WallGraphFn.mergeOperations(a, b));
  },
  mergeOperations: (a: WallGraph, b: WallGraph): WallGraphOperation[] => {
    const operations: WallGraphOperation[] = [];
    let out = {vertices: [...a.vertices], edges: [...a.edges]};
    for (let bIndex = 0; bIndex < b.vertices.length; bIndex ++) {
      const vertexOperations = WallGraphFn.insertVertex(out, b.vertices[bIndex]);
      out = applyAll(wallGraphType, out, vertexOperations);
      operations.push(...vertexOperations);
    }
    for (let edgeIndex = 0; edgeIndex < b.edges.length; edgeIndex++) {
      const edge = b.edges[edgeIndex];
      const edgeOperations = WallGraphFn.insertEdge(out, ({
        ...edge,
        start: out.vertices.findIndex(vertex => PointFn.equals(b.vertices[edge.start].coordinate, vertex.coordinate)),
        end: out.vertices.findIndex(vertex => PointFn.equals(b.vertices[edge.end].coordinate, vertex.coordinate))
      }));
      out = applyAll(wallGraphType, out, edgeOperations);
      operations.push(...edgeOperations);
    }
    return operations;
  },
  moveVertex: (value: WallGraph, vertexIndex: number, coordinate: Point): WallGraphOperation[] => {
    let vertex = value.vertices[vertexIndex];
    if (PointFn.equals(vertex.coordinate, coordinate)) return [];
    const ops: WallGraphOperation[] = [];

    let out = value;
    const coordinateVertexIndex = value.vertices.findIndex(v => PointFn.equals(v.coordinate, coordinate));
    if (coordinateVertexIndex !== -1) {
      let deleteVertexOps: WallGraphOperation[] = [
        {type: "delete-vertex", index: vertexIndex, prev: value.vertices[vertexIndex]},
      ];
      out = applyAll(wallGraphType, out, deleteVertexOps);
      ops.push(...deleteVertexOps);
    } else {
      let moveVertexOps: WallGraphOperation[] = [
        {type: "update-vertex", index: vertexIndex, operations: [{
          type: "update-coordinate", operations: PointFn.set(vertex.coordinate, coordinate)
        }]}
      ];
      out = applyAll(wallGraphType, out, moveVertexOps);
      ops.push(...moveVertexOps);
    }

    let deleteEdgeOps: WallGraphOperation[] = value.edges.flatMap((edge, edgeIndex) => {
      if (edge.start !== vertexIndex && edge.end !== vertexIndex) return [];
      return [{type: "delete-edge", index: edgeIndex, prev: edge}] satisfies WallGraphOperation[]
    }).reverse();
    out = applyAll(wallGraphType, out, deleteEdgeOps);
    ops.push(...deleteEdgeOps);

    const vertexEdges = value.edges.filter(e => e.start === vertexIndex || e.end === vertexIndex);
    for (let edgeIndex = 0; edgeIndex < vertexEdges.length; edgeIndex++) {
      const edge = vertexEdges[edgeIndex];
      let start = edge.start !== vertexIndex ? value.vertices[edge.start].coordinate : coordinate;
      let end = edge.end !== vertexIndex ? value.vertices[edge.end].coordinate : coordinate;
      const startIndex = out.vertices.findIndex(vertex => PointFn.equals(start, vertex.coordinate));
      const endIndex = out.vertices.findIndex(vertex => PointFn.equals(end, vertex.coordinate));

      let insertEdgeOps = WallGraphFn.insertEdge(out, {...edge, start: startIndex, end: endIndex});
      out = applyAll(wallGraphType, out, insertEdgeOps);
      ops.push(...insertEdgeOps);
    }
    return ops;
  },
  insertVertex: (value: WallGraph, vertex: WallGraphVertex): WallGraphOperation[] => {
    let vertexIndex = value.vertices.findIndex(o => PointFn.equals(o.coordinate, vertex.coordinate));
    if (vertexIndex !== -1) return [];
    vertexIndex = value.vertices.length;
    const operations: WallGraphOperation[] = [
      {type: "insert-vertex", index: vertexIndex, value: vertex},
    ];
    let edgeSplitCount = 0;
    for (let edgeIndex = 0; edgeIndex < value.edges.length; edgeIndex++) {
      const edge = value.edges[edgeIndex];
      const start = value.vertices[edge.start].coordinate;
      const end = value.vertices[edge.end].coordinate;
      const t = Vector2.pointIntersect(start, end, vertex.coordinate);
      if (t > 0.001 && t < 0.999) {
        const intersection = Vector2.lerp(start, end, t);
        if (PointFn.equals(intersection, vertex.coordinate)) {
          operations.push(...[
            {type: "delete-edge", index: edgeIndex + edgeSplitCount, prev: edge},
            {type: "insert-edge", index: edgeIndex + edgeSplitCount, value: {...edge, end: vertexIndex}},
            {type: "insert-edge", index: edgeIndex + edgeSplitCount + 1, value: {...edge, start: vertexIndex}}
          ] satisfies WallGraphOperation[]);
          edgeSplitCount ++;
        }
      }
    }
    return operations;
  },
  insertEdge: (value: WallGraph, edge: WallGraphEdge): WallGraphOperation[] => {
    const existingEdgeIndex = value.edges.findIndex(e => (e.start === edge.start && e.end === edge.end) || (e.end === edge.start && e.start === edge.end));
    if (existingEdgeIndex !== -1) return [];
    if (edge.start === edge.end) return [];

    const start = value.vertices[edge.start].coordinate;
    const end = value.vertices[edge.end].coordinate;
    let out = {vertices: [...value.vertices], edges: [...value.edges]};
    for (let edgeIndex = 0; edgeIndex < value.edges.length; edgeIndex++) {
      const b = value.edges[edgeIndex];
      const bStart = value.vertices[b.start].coordinate;
      const bEnd = value.vertices[b.end].coordinate;
      const t1 = Vector2.lineIntersect(bStart, bEnd, start, end);
      const t2 = Vector2.lineIntersect(start, end, bStart, bEnd);
      const intersection = Vector2.lerp(bStart, bEnd, t1);
      if (t1 > 0.001 && t1 < 0.999 && t2 > 0.001 && t2 < 0.999) {
        let newVertexIndex = out.vertices.length;
        const splitOp: WallGraphOperation[] = [
          {type: "insert-vertex", index: newVertexIndex, value: {coordinate: intersection, data: undefined}},
          {type: "delete-edge", index: edgeIndex, prev: b},
          {type: "insert-edge", index: edgeIndex, value: {...b, start: newVertexIndex}},
          {type: "insert-edge", index: edgeIndex+1, value: {...b, end: newVertexIndex}}
        ];
        out = applyAll(wallGraphType, out, splitOp);
        const leftOp = WallGraphFn.insertEdge(out, {...edge, start: newVertexIndex});
        out = applyAll(wallGraphType, out, leftOp);
        const rightOp = WallGraphFn.insertEdge(out, {...edge, end: newVertexIndex});
        return [...splitOp, ...leftOp, ...rightOp];
      }
    }

    for (let vertexIndex = 0; vertexIndex < value.vertices.length; vertexIndex++) {
      const vertex = value.vertices[vertexIndex];
      const t = Vector2.pointIntersect(start, end, vertex.coordinate);
      if (t > 0.001 && t < 0.999) {
        const intersection = Vector2.lerp(start, end, t);
        if (PointFn.equals(intersection, vertex.coordinate)) {
          let out = value;
          const left = WallGraphFn.insertEdge(out, {...edge, start: vertexIndex});
          out = applyAll(wallGraphType, out, left);
          const right = WallGraphFn.insertEdge(out, {...edge, end: vertexIndex});
          return [
            ...left,
            ...right
          ];
        }
      }
    }
    return [{type: "insert-edge", index: value.edges.length, value: edge}];
  },
  sliceEdge: (value: WallGraph, edge: WallGraphEdge): WallGraphOperation[] => {
    const existingEdgeIndex = value.edges.findIndex(e => (e.start === edge.start && e.end === edge.end) || (e.end === edge.start && e.start === edge.end));
    if (existingEdgeIndex !== -1) return [];
    if (edge.start === edge.end) return [];

    const start = value.vertices[edge.start].coordinate;
    const end = value.vertices[edge.end].coordinate;
    let out = {vertices: [...value.vertices], edges: [...value.edges]};
    for (let edgeIndex = 0; edgeIndex < value.edges.length; edgeIndex++) {
      const b = value.edges[edgeIndex];
      const bStart = value.vertices[b.start].coordinate;
      const bEnd = value.vertices[b.end].coordinate;
      const t1 = Vector2.lineIntersect(bStart, bEnd, start, end);
      const t2 = Vector2.lineIntersect(start, end, bStart, bEnd);
      const intersection = Vector2.lerp(bStart, bEnd, t1);
      if (t1 > 0.001 && t1 < 0.999 && t2 > 0.001 && t2 < 0.999) {
        let newVertexIndex = out.vertices.length;
        const splitOp: WallGraphOperation[] = [
          {type: "insert-vertex", index: newVertexIndex, value: {coordinate: intersection, data: undefined}},
          {type: "delete-edge", index: edgeIndex, prev: b},
          {type: "insert-edge", index: edgeIndex, value: {...b, start: newVertexIndex}},
          {type: "insert-edge", index: edgeIndex+1, value: {...b, end: newVertexIndex}}
        ];
        out = applyAll(wallGraphType, out, splitOp);
        const leftOp = WallGraphFn.sliceEdge(out, {...edge, start: newVertexIndex});
        out = applyAll(wallGraphType, out, leftOp);
        const rightOp = WallGraphFn.sliceEdge(out, {...edge, end: newVertexIndex});
        return [...splitOp, ...leftOp, ...rightOp];
      }
    }
    return [];
  },
  deleteEdge: (value: WallGraph, edgeIndex: number): WallGraphOperation[] => {
    const edge = value.edges[edgeIndex];
    let deleteEdgeOps: WallGraphOperation[] = [
      {type: "delete-edge", index: edgeIndex, prev: edge}
    ];
    let out = value;
    out = applyAll(wallGraphType, out, deleteEdgeOps);

    let deleteStrayVerticesOps = WallGraphFn.deleteStrayVertices(out);
    return [
      ...deleteEdgeOps,
      ...deleteStrayVerticesOps
    ];
  },
  deleteVertex: (value: WallGraph, vertexIndex: number): WallGraphOperation[] => {
    let deleteVertexOps: WallGraphOperation[] = [
      {type: "delete-vertex", index: vertexIndex, prev: value.vertices[vertexIndex]},
    ];
    let deleteEdgeOps: WallGraphOperation[] = value.edges.flatMap((edge, edgeIndex) => {
      if (edge.start !== vertexIndex && edge.end !== vertexIndex) return [];
      return [{type: "delete-edge", index: edgeIndex, prev: edge}] satisfies WallGraphOperation[]
    }).reverse();
    let out = value;
    out = applyAll(wallGraphType, out, deleteVertexOps);
    out = applyAll(wallGraphType, out, deleteEdgeOps);
    const deleteStrayVerticesOps = WallGraphFn.deleteStrayVertices(out);
    return [
      ...deleteVertexOps,
      ...deleteEdgeOps,
      ...deleteStrayVerticesOps
    ];
  },
  deleteStrayVertices: (value: WallGraph): WallGraphOperation[] => {
    return value.vertices.flatMap((vertex, vertexIndex): WallGraphOperation[] => {
      if (value.edges.some(edge => edge.start === vertexIndex || edge.end === vertexIndex)) {
        return [];
      } else {
        return [{type: "delete-vertex", index: vertexIndex, prev: vertex}];
      }
    }).reverse();
  }
};
