import {DialogID} from "../../../modal/index.ts";
import {DialogBottomLeftResizer} from "./dialog-bottom-left-resizer.tsx";
import {DialogBottomRightResizer} from "./dialog-bottom-right-resizer.tsx";
import "./resizer.css";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";

export type DialogBottomResizerProps = {
  dialogId: DialogID;
  minimized: boolean;
};

export function DialogBottomResizer({dialogId, minimized}: DialogBottomResizerProps) {
  const [, drag, dragPreviewRef] = useDrag(() => ({
    type: `legends/dialog-resize`,
    item: {dialogId, direction: "bottom"},
    options: {dropEffect: "move"},
    canDrag: () => !minimized
  }), [dialogId, minimized]);
  dragPreviewRef(EMPTY_IMAGE);

  return (<div className="dialog-bottom-resizer">
    <DialogBottomLeftResizer dialogId={dialogId} minimized={minimized}/>
    <div ref={drag} className={`dialog-bottom-bottom-resizer ${minimized ? "minimized" : "expanded"}`} />
    <DialogBottomRightResizer dialogId={dialogId} minimized={minimized}/>
  </div>);
}
