import {z} from "zod";
import {ConstantOperation, constantType, Encrypted, FileReference, ObjectType, RichText, Type, ValueOperation, ValueType} from "../../../types/index.ts";
import {RollRequestID, RollRequests, RollResults} from "../roll-request/index.ts";
import {Dnd5eDamageType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Dnd5eAbilityCheckType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Dnd5eSavingThrowType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {NodeID, UserID} from "../../../legends/index.ts";
import {QLabMessageID} from "../q-lab-message-id.ts";


export const Dnd5eActionDamage = z.object({
  damageType: z.optional(Dnd5eDamageType),
  damageRollID: RollRequestID
});
export type Dnd5eActionDamage = z.infer<typeof Dnd5eActionDamage>;

export const Dnd5eActionAbilityCheck: z.ZodType<{
  difficultyClassRollID: RollRequestID;
  abilityCheckTypes: Dnd5eAbilityCheckType[];
  onSuccess: Dnd5eActionSegment[];
  onFail: Dnd5eActionSegment[];
}> = z.object({
  difficultyClassRollID: RollRequestID,
  abilityCheckTypes: z.array(Dnd5eAbilityCheckType),
  onSuccess: z.lazy(() => z.array(Dnd5eActionSegment)),
  onFail: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionAbilityCheck = z.infer<typeof Dnd5eActionAbilityCheck>;

export const Dnd5eActionAttackRoll: z.ZodType<{
  attackRollID: RollRequestID;
  onCriticalHit: Dnd5eActionSegment[];
  onHit: Dnd5eActionSegment[];
  onMiss: Dnd5eActionSegment[];
}> = z.object({
  attackRollID: RollRequestID,
  onCriticalHit: z.lazy(() => z.array(Dnd5eActionSegment)),
  onHit: z.lazy(() => z.array(Dnd5eActionSegment)),
  onMiss: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionAttackRoll = z.infer<typeof Dnd5eActionAttackRoll>;

export const Dnd5eActionSavingThrow: z.ZodType<{
  difficultyClassRollID: RollRequestID,
  savingThrowTypes: Dnd5eSavingThrowType[],
  onSuccess: Dnd5eActionSegment[];
  onMiss: Dnd5eActionSegment[];
}> =  z.object({
  difficultyClassRollID: RollRequestID,
  savingThrowTypes: z.array(Dnd5eSavingThrowType),
  onSuccess: z.lazy(() => z.array(Dnd5eActionSegment)),
  onMiss: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionSavingThrow = z.infer<typeof Dnd5eActionSavingThrow>;

export const Dnd5eActionSegment = z.discriminatedUnion("type", [
  z.object({type: z.literal("ability-check"), data: Dnd5eActionAbilityCheck}),
  z.object({type: z.literal("attack-roll"), data: Dnd5eActionAttackRoll}),
  z.object({type: z.literal("saving-throw"), data: Dnd5eActionSavingThrow}),
  z.object({type: z.literal("damage"), data: z.array(Dnd5eActionDamage)}),
  z.object({type: z.literal("text"), data: RichText})
]);
export type Dnd5eActionSegment = z.infer<typeof Dnd5eActionSegment>;

export const Dnd5eActionMessage = z.object({
  title: z.string(),
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  referenceMessageID: z.optional(QLabMessageID),
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
  segments: z.array(Dnd5eActionSegment)
});
export type Dnd5eActionMessage = z.infer<typeof Dnd5eActionMessage>;

export const Dnd5eActionMessageOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-title"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-user-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-icon"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-reference-message-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-roll-requests"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-roll-results"), operations: z.array(ValueOperation(z.optional(Encrypted(RollResults)), ConstantOperation))}),
  z.object({type: z.literal("update-segments"), operations: z.array(ConstantOperation)}),
]);
export type Dnd5eActionMessageOperation = z.infer<typeof Dnd5eActionMessageOperation>;

export const dnd5eActionMessageType: Type<Dnd5eActionMessage, Dnd5eActionMessageOperation> = new ObjectType({
  title: constantType,
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  referenceMessageID: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType),
  segments: constantType
});
