import {createContext, useContext} from "react";
import {Container} from "../modal/index.ts";
import {Apply} from "common/types/index.ts";
import {ContainerOperation} from "../modal/container-operation.ts";
import {MutableRef} from "common/ref";

const ContainerContext = createContext<MutableRef<Container<any>, ContainerOperation<any, any>[]> | undefined>(undefined);

export const ContainerProvider = ContainerContext.Provider;

export function useContainer(): MutableRef<Container<any>, ContainerOperation<any, any>[]> | undefined {
  return useContext(ContainerContext);
}

export function useApplyToContainer(): Apply<Container<any>, ContainerOperation<any, any>[]> {
  return useContext(ContainerContext)!.apply;
}
