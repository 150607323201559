import {ApplyAction, FileReference, FileReferenceOperation, StringOperation, stringType} from "common/types/index.ts";
import {FaSpinner} from "react-icons/fa";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {HTMLAttributes, useCallback, useMemo, useRef, useState} from "react";
import {useObservable, useObservableLoader} from "#lib/qlab/index.ts";
import {QLabFileId} from "common/qlab/index.ts";
import uploadManager from "#lib/qlab/asset/asset-manager.ts";
import {twMerge} from "tailwind-merge";
import {ProgressCircle} from "#lib/components/progress-circle.tsx";
import {ImageIcon} from "../../../legends/common/image-icon.tsx";
import {MutableRef} from "common/ref";

export type IconFieldProps = HTMLAttributes<HTMLDivElement> & {
  value: MutableRef<FileReference, FileReferenceOperation[]>;
};

export function IconField({value, ...props}: IconFieldProps) {
  const valueAsString = useMemo(() => ({
    observe: pipe(
      value.observe,
      map(value => value || ""),
      distinct()
    ),
    apply: (fn: ApplyAction<string, StringOperation[]>) => value.apply((prev: FileReference): FileReferenceOperation[] => {
      const operations: StringOperation[] = typeof fn === "function" ? fn(prev || "") : fn;
      const finalValue = operations.reduce((value: string, operation: StringOperation) => stringType.apply(value, operation), prev || "");
      return [{type: "set", prevValue: prev, nextValue: (finalValue === "" ? undefined : finalValue) as FileReference}];
    })
  }), [value]);

  const isEmptyLoader = useObservableLoader(useMemo(() => pipe(value.observe, map(v => v === undefined), distinct()), [value]));
  const clearImage = useCallback(() => {
    value.apply(prev => [{type: "set", prevValue: prev, nextValue: undefined}])
  }, [value]);

  const [progress, setProgress] = useState<number | undefined>(undefined);
  const importRef = useRef<HTMLInputElement>(null);
  const importImage = async (file: File) => {
    setProgress(0);
    const assetID = QLabFileId.generate();
    const {url, upload} = await uploadManager.asset(assetID)
    upload(file)({
      next: (progress) => {
        setProgress(progress);
      },
      error: (error) => {
        setProgress(undefined);
        console.error(error)
      },
      complete() {
        setProgress(undefined);
        value.apply(prev => [{type: "set", prevValue: prev, nextValue: url}])
      }
    });
  }

  const src = useObservable(valueAsString.observe, undefined, [valueAsString]);

  if (isEmptyLoader.isLoading) {
    return (<div className="w-10 h-10" {...props}>
      <FaSpinner className="animate-spin" />
    </div>);
  }

  if (isEmptyLoader.data) {
    return <div {...props} className={twMerge("flex flex-row", props.className)} >
      <input className="hidden" type="file" ref={importRef} accept="image/*" onChange={(ev) => {
        const image = ev.target.files?.item(0);
        if (image) importImage(image);
      }} />

      {progress !== undefined
        ? <ProgressCircle value={progress} />
        : <ImageIcon src={undefined} onClick={(ev) => {
              importRef.current?.click();
              ev.preventDefault();
              return true;
            }} />}
    </div>
  } else {
    return <div {...props} className={twMerge("flex flex-row", props.className)} >
      <ImageIcon src={src} onClick={ev => {
        clearImage();
        ev.preventDefault();
        return true;
      }} />
    </div>
  }
}

