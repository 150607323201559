import {z} from "zod";
import {
  ConstantOperation,
  constantType,
  NumberOperation,
  numberType,
  ObjectType,
  Optional,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eItemConsumptionModifier = z.object({
  modifierID: Dnd5eModifierID,
  itemName: z.optional(z.string()),
  qty: z.number()
});
export type Dnd5eItemConsumptionModifier = z.infer<typeof Dnd5eItemConsumptionModifier>;

export const Dnd5eItemConsumptionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-item-name"), operations: z.array(ValueOperation(z.optional(z.string()), StringOperation))}),
  z.object({type: z.literal("update-qty"), operations: z.array(NumberOperation)})
]);
export type Dnd5eItemConsumptionModifierOperation = z.infer<typeof Dnd5eItemConsumptionModifierOperation>;

export const dnd5eItemConsumptionModifierType: Type<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation> = new ObjectType({
  modifierID: constantType,
  itemName: new ValueType(stringType),
  qty: numberType
}, (v: any)=> {
  if (v["itemID"]) {
    v["itemName"] = v["itemID"];
    delete v["itemID"];
  }
  return v;
});

export function Dnd5eItemConsumptionModifierSignals(value: MutableRef<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation[]>) {
  return ({
    itemName: ValuePropertyRef<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation, Optional<string>, StringOperation>(
      value => value.itemName,
      operations => [{type: "update-item-name", operations}]
    )(value),
    qty: PropertyRef<Dnd5eItemConsumptionModifier, Dnd5eItemConsumptionModifierOperation, number, NumberOperation>(
      value => value.qty,
      operations => [{type: "update-qty", operations}]
    )(value)
  });
}

