import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Framebuffer extends WebGL2Node {
  public value?: WebGLFramebuffer = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  bind(): void {
    if (this.value === undefined) throw new Error("value must be provided.");

    this.context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, this.value);
  }

  unbind(): void {
    this.context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, null);
  }
}
