import {ButtonBar, ExpandableLabel, useToggle} from "#lib/components/index.ts";
import {GridOptions} from "./grid-options.tsx";
import {Grid, GridOperation} from "common/legends/index.ts";
import React from "react";
import {MutableRef} from "common/ref";

export type GridInputProps = {
  value: MutableRef<Grid, GridOperation[]>;
};

export function GridField({value}: GridInputProps) {
  const [showGridOptions, toggleGridOptions] = useToggle(false);
  return (<div className="flex flex-col gap-0">
    <ButtonBar>
      <ExpandableLabel className="w-full" size="small" expanded={showGridOptions} toggleExpand={toggleGridOptions}>
        <span className="px-4 flex-1 backdrop-blur-sm pointer-events-auto cursor-pointer" onClick={toggleGridOptions}>Grid</span>
      </ExpandableLabel>
    </ButtonBar>
    {showGridOptions && <GridOptions value={value} />}
  </div>);
}