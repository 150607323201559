import {Type} from "../../type/type.ts";
import {constantType} from "../constant/index.ts";
import {z} from "zod";
import {ValueOperation, ValueType} from "#common/types/generic/value/index.ts";

export const FileReference = z.optional(z.string().brand("FileReference"));
export type FileReference = z.infer<typeof FileReference>;

export const FileReferenceOperation = ValueOperation(FileReference, z.undefined());
export type FileReferenceOperation = z.infer<typeof FileReferenceOperation>;
export const fileReferenceType: Type<FileReference, FileReferenceOperation> = new ValueType(constantType);
