import {BooleanFn, ListOperation, MapFn, MapOperation, Optional, ValueFn} from "common/types/index.ts";
import {Dnd5eEffect, Dnd5eEffectFn, Dnd5eEffectID, Dnd5eEffectOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {
  Dnd5eCharacterClass,
  Dnd5eCharacterClassFeature,
  Dnd5eCharacterClassFeatureOperation,
  Dnd5eCharacterClassOperation,
  Dnd5eCharacterOperation,
  Dnd5eFeature,
  Dnd5eFeatureOperation,
  Sheet,
  SheetOperation
} from "common/legends/index.ts";
import {useCallback} from "react";
import {Dnd5eStatBlockOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation} from "common/legends/game/system/dnd-5e-feature-override.ts";
import {Dnd5eFeatureID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {MutableRef, Ref} from "common/ref";

export function useSetEffectGroup(sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>, globalFeatures: Ref<Dnd5eFeature[]>) {
  return useCallback((group: string, effectID: Optional<Dnd5eEffectID>) => {
    sheetRef.apply(prev => {
      const updateFeature = (feature: Dnd5eFeature): Dnd5eFeatureOperation[] => {
        const effectOperations: ListOperation<Dnd5eEffect, Dnd5eEffectOperation>[] = [];
        for (const [effectIndex, effect] of feature.effects.entries()) {
          if (Dnd5eEffectFn.getGroup(effect) !== group) continue;
          if (effect.effectID === effectID) {
            if (!effect.enabled) {
              effectOperations.push(...ListOperation.apply<Dnd5eEffect, Dnd5eEffectOperation>(effectIndex, [{type: "update-enabled", operations: BooleanFn.set(effect.enabled, true)}]));
            }
          } else {
            if (effect.enabled) {
              effectOperations.push(...ListOperation.apply<Dnd5eEffect, Dnd5eEffectOperation>(effectIndex, [{type: "update-enabled", operations: BooleanFn.set(effect.enabled, false)}]));
            }
          }
        }
        return effectOperations.length > 0 ? [{type: "update-effects", operations: effectOperations}] : [];
      }

      if (prev?.type === "dnd-5e-character") {
        const characterOperations: Dnd5eCharacterOperation[] = [];
        const globalFeaturesOperations: MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>[] = [];
        for (const feature of globalFeatures.value) {
          for (const effect of feature.effects) {
            if (Dnd5eEffectFn.getGroup(effect) !== group) continue;
            const featureOverride = prev.data.globalFeatures[feature.featureID];
            if (featureOverride === undefined) {
              if (effect.effectID === effectID) {
                globalFeaturesOperations.push(...MapFn.put(feature.featureID, {
                  effects: {[effectID]: true}
                }));
              } else {
                // do nothing
              }
            } else {
              if (effect.effectID === effectID) {
                if (featureOverride.effects[effect.effectID] !== true) {
                  globalFeaturesOperations.push(...MapFn.apply<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(feature.featureID, [{
                    type: "update-effects", operations: MapFn.put<Dnd5eEffectID, boolean>(effect.effectID, true)
                  }]));
                }
              } else {
                if (featureOverride.effects[effect.effectID] === true) {
                  globalFeaturesOperations.push(...MapFn.apply<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(feature.featureID, [{
                    type: "update-effects", operations: MapFn.delete<Dnd5eEffectID, boolean>(effect.effectID, true)
                  }]));
                }
              }
            }
          }
        }
        if (globalFeaturesOperations.length > 0) characterOperations.push({type: "update-global-features", operations: globalFeaturesOperations});

        const backgroundOperations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[] = [];
        for (const [featureIndex, feature] of prev.data.background.features.entries()) {
          const featureOperations = updateFeature(feature);
          if (featureOperations.length > 0) backgroundOperations.push(...ListOperation.apply<Dnd5eFeature, Dnd5eFeatureOperation>(featureIndex, featureOperations))
        }
        if (backgroundOperations.length > 0) characterOperations.push({type: "update-background", operations: ValueFn.apply([{type: "update-features", operations: backgroundOperations}])});

        const raceOperations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[] = [];
        for (const [featureIndex, feature] of prev.data.race.features.entries()) {
          const featureOperations = updateFeature(feature);
          if (featureOperations.length > 0) raceOperations.push(...ListOperation.apply<Dnd5eFeature, Dnd5eFeatureOperation>(featureIndex, featureOperations))
        }
        if (raceOperations.length > 0) characterOperations.push({type: "update-race", operations: ValueFn.apply([{type: "update-features", operations: raceOperations}])});

        const classesOperations: ListOperation<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>[] = [];
        for (const [clazzIndex, clazz] of prev.data.classes.entries()) {
          const classOperations: ListOperation<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>[] = [];
          for (const [featureIndex, feature] of clazz.features.entries()) {
            const featureOperations = updateFeature(feature.feature);
            if (featureOperations.length > 0) classOperations.push(...ListOperation.apply<Dnd5eCharacterClassFeature, Dnd5eCharacterClassFeatureOperation>(featureIndex, [{type: "update-feature", operations: featureOperations}]))
          }
          if (classOperations.length > 0) classesOperations.push(...ListOperation.apply<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>(clazzIndex, [{type: "update-features", operations: classOperations}]));
        }
        if (classesOperations.length > 0) characterOperations.push({type: "update-classes", operations: classesOperations});

        const featuresOperations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[] = [];
        for (const [featureIndex, feature] of prev.data.features.entries()) {
          const featureOperations = updateFeature(feature);
          if (featureOperations.length > 0) featuresOperations.push(...ListOperation.apply<Dnd5eFeature, Dnd5eFeatureOperation>(featureIndex, featureOperations))
        }
        if (featuresOperations.length > 0) characterOperations.push({type: "update-features", operations: featuresOperations});
        return characterOperations.length > 0 ? [{type: "dnd-5e-character", operations: characterOperations}] : [];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const statBlockOperations: Dnd5eStatBlockOperation[] = [];
        const globalFeaturesOperations: MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>[] = [];
        for (const feature of globalFeatures.value) {
          for (const effect of feature.effects) {
            if (Dnd5eEffectFn.getGroup(effect) !== group) continue;
            const featureOverride = prev.data.globalFeatures[feature.featureID];
            if (featureOverride === undefined) {
              if (effect.effectID === effectID) {
                globalFeaturesOperations.push(...MapFn.put(feature.featureID, {
                  effects: {[effectID]: true}
                }));
              } else {
                // do nothing
              }
            } else {
              if (effect.effectID === effectID) {
                if (featureOverride.effects[effect.effectID] !== true) {
                  globalFeaturesOperations.push(...MapFn.apply<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(feature.featureID, [{
                    type: "update-effects", operations: MapFn.put<Dnd5eEffectID, boolean>(effect.effectID, true)
                  }]));
                }
              } else {
                if (featureOverride.effects[effect.effectID] === true) {
                  globalFeaturesOperations.push(...MapFn.apply<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(feature.featureID, [{
                    type: "update-effects", operations: MapFn.delete<Dnd5eEffectID, boolean>(effect.effectID, true)
                  }]));
                }
              }
            }
          }
        }
        if (globalFeaturesOperations.length > 0) statBlockOperations.push({type: "update-global-features", operations: globalFeaturesOperations});

        const featuresOperations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[] = [];
        for (const [featureIndex, feature] of prev.data.features.entries()) {
          const featureOperations = updateFeature(feature);
          if (featureOperations.length > 0) featuresOperations.push(...ListOperation.apply<Dnd5eFeature, Dnd5eFeatureOperation>(featureIndex, featureOperations))
        }
        if (featuresOperations.length > 0) statBlockOperations.push({type: "update-features", operations: featuresOperations});
        return statBlockOperations.length > 0 ? [{type: "dnd-5e-stat-block", operations: statBlockOperations}] : [];
      } else {
        // Disable all effect-group except effectID, which should be toggled
        return [];
      }
    });
  }, [sheetRef]);
}