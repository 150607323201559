import React, {useContext} from "react";
import {QLabInstance} from "#lib/qlab/index.ts";

const QLabInstanceContext = React.createContext<QLabInstance | undefined>(undefined);

export const InstanceProvider = QLabInstanceContext.Provider;

export function useInstance(): QLabInstance {
  return useContext(QLabInstanceContext)!;
}
