import {StatusIndicatorFn, StatusIndicatorModifier, StatusIndicatorModifierOperation} from "common/legends/asset/status-indicator/index.ts";
import {InputFile} from "#lib/components/input/input-file.tsx";
import React, {Ref, useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {FaTrash} from "react-icons/fa";
import {MutableRef} from "common/ref";

export type StatusIndicatorFieldProps = {
  value: MutableRef<StatusIndicatorModifier, StatusIndicatorModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function InputStatusIndicatorModifier({value, dragHandlerRef, dragRefPreview, remove, readOnly}: StatusIndicatorFieldProps) {
  const {color, file} = useMemo(() => StatusIndicatorFn.expand(value), [value]);
  const isAssetProvided = useComputedValue(file, file => file !== undefined);

  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row gap-0.5">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroupLabel>Status Indicator</InputGroupLabel>
    </InputGroup>
    {!isAssetProvided && <ColorField readOnly={readOnly} disabled={readOnly} className="w-14" value={color} />}
    <InputFile readOnly={readOnly} className="flex-1" value={file} />
    {!readOnly && <IconButton variant="destructive" title="Remove" onClick={() => remove()}><FaTrash /></IconButton>}
  </div>);
}