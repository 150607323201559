import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {ConstantOperation, constantType, ObjectType, SetOperation, SetType} from "#common/types/index.ts";
import {Dnd5eDamageType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";

export const Dnd5eDamageResistanceModifier = z.object({
  modifierID: Dnd5eModifierID,
  damageTypes: z.array(Dnd5eDamageType)
});
export type Dnd5eDamageResistanceModifier = z.infer<typeof Dnd5eDamageResistanceModifier>;

export const Dnd5eDamageResistanceModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-damage-types"), operations: z.array(SetOperation(Dnd5eDamageType))})
]);
export type Dnd5eDamageResistanceModifierOperation = z.infer<typeof Dnd5eDamageResistanceModifierOperation>;

export const dnd5eDamageResistanceModifierType = new ObjectType({
  modifierID: constantType,
  damageTypes: new SetType<Dnd5eDamageType>()
});
