import {Type} from "../../type/type.ts";
import {ConstantOperation} from "./constant-operation.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export class ConstantType<Value> implements Type<Value, ConstantOperation> {
  constructor(private readonly itemType: Type<Value, any> | undefined = undefined) {
  }
  apply(value: Value, operation: undefined): Value {
    throw new Error("Unsupported Operation");
  }
  invert(operation: never): never[] {
    return [];
  }
  transform(leftOperation: never, topOperation: never, tieBreaker: boolean): never[] {
    return [];
  }
  migrateValue = (value: any): Value => {
    return this.itemType ? this.itemType.migrateValue(value) : value;
  }
  migrateOperation = (operation: any): ConstantOperation[] => {
    return [operation];
  }
  validate = (value: any): ValidationError[] => {
    if (this.itemType) return this.itemType.validate(value);
    return [];
  }
}

export const constantType = new ConstantType<any>();