import {NumberOperation, numberType, Type} from "#common/types/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export const DND_5E_EXHAUSTION_LEVEL = [
  0,
  1,
  2,
  3,
  4,
  5,
  6
] as const;
export type Dnd5eExhaustionLevel = typeof DND_5E_EXHAUSTION_LEVEL[number];

export const exhaustionLevelType: Type<Dnd5eExhaustionLevel, NumberOperation> = {
  apply: (value: Dnd5eExhaustionLevel, operation: NumberOperation): Dnd5eExhaustionLevel => {
    return Math.max(0, Math.min(numberType.apply(value, operation), 6)) as Dnd5eExhaustionLevel;
  },
  invert: (operation: NumberOperation): NumberOperation[] => {
    return numberType.invert(operation);
  },
  transform: (leftOperation: NumberOperation, topOperation: NumberOperation, tieBreaker: boolean): NumberOperation[] => {
    return numberType.transform(leftOperation, topOperation, tieBreaker);
  },
  migrateValue: (value: any): Dnd5eExhaustionLevel => {
    return value;
  },
  migrateOperation: (operation: any): NumberOperation[] => {
    return [operation];
  },
  validate: (value: any): ValidationError[] => {
    const errors = numberType.validate(value);
    if (errors.length !== 0) return errors;
    return !(value >= 0 && value <= 6) ? [{path: [], data: {message: "Out of range.", value}}] : [];
  }
};
