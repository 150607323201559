import {Button} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {useIsCurrentTurn, useIsNextTurn} from "../../common/turn-tracker/use-is-current-turn.ts";
import {useEndTurn} from "../../common/turn-tracker/use-end-turn.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useGame} from "../../../routes/game/index.ts";
import {GameSignals} from "common/legends/index.ts";

export function PlayerTurnIndicator() {
  const isCurrentTurn = useIsCurrentTurn();
  const isNextTurn = useIsNextTurn();

  const game = useGame();
  const {turnTracker} = useMemo(() => GameSignals(game), [game]);
  const endTurn = useEndTurn(turnTracker);
  const isTracking = useObservable(pipe(turnTracker.observe, map(tracker => tracker.currentTurnID !== undefined), distinct()), false, [turnTracker.observe]);

  return <>
    {isNextTurn && <span className="text-xs italic text-white/80">
      You're next!
    </span>}

    {isTracking && <Button disabled={!isCurrentTurn} onClick={endTurn} size="small" variant={isCurrentTurn ? "primary" : "tertiary"} className="rounded-md">
        End Turn
    </Button>}
  </>;
}