import {ConstantOperation, MapFn, MapRef} from "common/types/index.ts";
import {Dnd5eLanguage, Dnd5eLanguageProficiency} from "common/legends/index.ts";
import {ExpandableLabel, Input, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import React, {useState} from "react";
import {FaPlus} from "react-icons/fa";
import {SavingThrowProficiency} from "./saving-throw-proficiency/index.ts";
import {BareIconButton} from "#lib/components/bare-icon-button/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useRefValue} from "#lib/signal/index.ts";

export type LanguageProficienciesProps = {
  value: MapRef<Dnd5eLanguage, Dnd5eLanguageProficiency, ConstantOperation>;
};
export function LanguageProficiencies({value}: LanguageProficienciesProps) {
  const setProficiency = (language: Dnd5eLanguage, nextValue: Dnd5eLanguageProficiency) => {
    value.apply(prevValue => {
      if (nextValue === "proficient") {
        if (prevValue[language] === undefined) return [{type: "put", key: language, item: "proficient"}];
        else if (prevValue[language] === "untrained") return [
          ...MapFn.delete(language, prevValue[language]!),
          ...MapFn.put(language, nextValue)
        ];
      } else {
        if (prevValue[language] !== undefined) return [{type: "delete", key: language, item: prevValue[language]!}];
      }
      return [];
    });
  };

  const proficiencies = useRefValue(value);

  const [expanded, toggleExpanded] = useToggle(false);

  const [language, setLanguage] = useState("");
  return <div className="flex flex-col mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <span className="px-4">Language Proficiencies</span>
    </ExpandableLabel>
    {expanded && <Fieldset>
      {Object.keys(proficiencies).length > 0 && <div className="divide-y divide-zinc-900/80">
        {Object.keys(proficiencies).map(language => <InputGroup key={language}>
          <SavingThrowProficiency value={proficiencies[language] || "untrained"} onChange={(nextValue) => {
            setProficiency(language, nextValue);
          }} />
          <InputGroupLabel>{language}</InputGroupLabel>
        </InputGroup>)}
      </div>}
      <form onSubmit={ev => {
        ev.preventDefault();
        ev.stopPropagation();
      }}>
        <InputGroup>
          <Input type="text" value={language} onChange={ev => setLanguage(ev.target.value)}  />
          <BareIconButton type="submit" onClick={() => {
            if (language !== "") {
              setProficiency(language, "proficient")
              setLanguage("");
            }
          }}><FaPlus /></BareIconButton>
        </InputGroup>
      </form>
    </Fieldset>}
  </div>
}