import {Measurement} from "common/legends/measurement/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/index.ts";
import {PathMeasurementView} from "./measurement/path-measurement-view.tsx";
import {ConeMeasurementView} from "./measurement/cone-measurement-view.tsx";
import {Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {RectangleMeasurementView} from "./measurement/rectangle-measurement-view.tsx";
import {CircleMeasurementView} from "./measurement/circle-measurement-view.tsx";
import {BeamMeasurementView} from "./measurement/beam-measurement-view.tsx";

export type MeasurementViewProps = {
  projection: Matrix4f;
  view: Transform;
  measurementRef: Ref<Measurement>;
};
export function MeasurementView({projection, view, measurementRef}: MeasurementViewProps) {
  const value = useRefValue(measurementRef);
  if (value?.type ==="path") return <PathMeasurementView projection={projection} view={view} measurement={value} />;
  if (value?.type ==="cone") return <ConeMeasurementView projection={projection} view={view} measurement={value} />;
  if (value?.type ==="rectangle") return <RectangleMeasurementView projection={projection} view={view} measurement={value} />;
  if (value?.type ==="circle") return <CircleMeasurementView projection={projection} view={view} measurement={value} />;
  if (value?.type ==="beam") return <BeamMeasurementView projection={projection} view={view} measurement={value} />;
  return <></>
}