import {z} from "zod";
import {constantType, Type} from "#common/types/index.ts";
import {generateInteractionTriggerID, InteractionTriggerID} from "./interaction-trigger-i-d.ts";
import {ConstantOperation, ObjectType} from "../../../../types/index.ts";

export const InteractionTriggerClick = z.object({
  interactionTriggerID: InteractionTriggerID
});
export type InteractionTriggerClick = z.infer<typeof InteractionTriggerClick>;
export const InteractionTriggerClickOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-interaction-trigger-i-d"), operations: z.array(ConstantOperation)})
]);
export type InteractionTriggerClickOperation = z.infer<typeof InteractionTriggerClickOperation>;
export const interactionTriggerClickType: Type<InteractionTriggerClick, InteractionTriggerClickOperation> = new ObjectType({
  interactionTriggerID: constantType
}, v => {
  if (v.interactionTriggerID === undefined) v.interactionTriggerID = generateInteractionTriggerID();
  return v;
});
