import React, {MutableRefObject, PropsWithChildren} from "react";
import {Dialog} from "@headlessui/react";
import {ModalContext} from "./modal-context.ts";
import {twMerge} from "tailwind-merge";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";

export type ModalProps = PropsWithChildren<{
  className?: string;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  onClose?: () => void;
}>;

export function Modal({className, onClose, children, initialFocus}: ModalProps) {
  const close = onClose === undefined ? () => {} : onClose;
  return (<ErrorBoundary fallback={<></>}><ModalContext.Provider value={{onClose}}>
    <Dialog as={ExternalPortal} open onClose={close} className="fixed inset-0 overflow-y-auto" initialFocus={initialFocus}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-25 backdrop-blur-md" aria-hidden="true" />
      <form className="flex min-h-full items-start justify-center p-4 py-[10vh] gap-2" onSubmit={ev => ev.preventDefault()}>
        <Dialog.Panel className={twMerge(
          "w-full max-w-screen-lg overscroll-y-auto bg-white rounded-lg shadow-md backdrop-blur bg-zinc-900/50 text-white flex flex-col gap-2 overflow-hidden",
          className
        )}>
          {children}
        </Dialog.Panel>
      </form>
    </Dialog>
  </ModalContext.Provider></ErrorBoundary>);
}