import {Optional, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {Editor, EditorOperation, editorType} from "./editor.ts";

export type EditorState =
  | Optional<Editor>
  ;
export type EditorStateOperation =
  | ValueOperation<Optional<Editor>, EditorOperation>
  ;

export const editorStateType: Type<EditorState, EditorStateOperation> = new ValueType(editorType);
