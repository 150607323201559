import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "common/legends/visibility/index.ts";
import {VisibilityLayerInput} from "./visibility-layer-input.tsx";
import {MutableRef} from "common/ref";

export type VisibleFieldProps = {
  value: MutableRef<VisibilityLayer, VisibilityLayerOperation[]>;
};

export function VisibilityLayerField({value}: VisibleFieldProps) {
  return (
    <InputGroup>
      <InputGroupLabel>Visible</InputGroupLabel>
      <VisibilityLayerInput value={value} />
    </InputGroup>
  )
}