import React from "react";
import {Tree, TreeId, TreePath} from "common/types/index.ts";
import {DragIndicator} from "./drag-indicator.tsx";
import {TagListViewer} from "../tag-list-input/index.ts";
import "./tree-item-view.css";
import {Button, ButtonBar, ExpandableLabel, IconButton} from "#lib/components/index.ts";
import {useDrag} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";
import {TREE_ITEM_INDENTATION_SIZE} from "#lib/components/tree-view/tree-view.tsx";

export type TreeItemViewProps<T extends Tree<T>> = {
  type: string;
  path: TreePath;
  item: T;
  selected: boolean;
  expanded: boolean;
  onSelect?: (id: TreeId) => void;
  onDeselect?: (id: TreeId) => void;
  onExpand: (id: TreeId) => void;
  onCollapse: (id: TreeId) => void;
  ItemActions: (item: T) => JSX.Element;
};

export function TreeItemView<T extends Tree<T>>({type, path, item, selected, onSelect, onDeselect, expanded, onExpand, onCollapse, ItemActions}: TreeItemViewProps<T>) {
  const [, dragRefHandler, dragPreviewRef] = useDrag(() => ({
    type: `legends/${type}`,
    item: {
      id: item.data.id,
      path,
    }
  }), [item.data.id, path]);
  dragPreviewRef(EMPTY_IMAGE);

  const onClick = () => {
    if (selected && onDeselect) {
      onDeselect(item.data.id);
    } else if (onSelect) {
      onSelect(item.data.id);
    }
  };

  return <ButtonBar ref={dragRefHandler} style={{marginLeft: `${(path.length-1) * TREE_ITEM_INDENTATION_SIZE}px`}}>
    <ExpandableLabel expanded={expanded} toggleExpand={Tree.isParentNode(item) ? () => expanded ? onCollapse(item.data.id) : onExpand(item.data.id) : undefined}>
      <IconButton className="cursor-grab"><DragIndicator/></IconButton>
      <ButtonBar className="flex flex-row flex-1">
        <Button variant={selected ? "primary" : undefined} className="flex-1 justify-start min-w-0 w-0 basis-0 overflow-hidden whitespace-nowrap shrink" onClick={onClick}>
          {item.data.name}
          {item.data.tags && <TagListViewer size="small" value={item.data.tags} />}
        </Button>
        {ItemActions(item)}
      </ButtonBar>
    </ExpandableLabel>
  </ButtonBar>
}
