import {useRenderingContext} from "./rendering-context.ts";
import {useEffect, useMemo} from "react";

export function useVertexBuffer(): WebGLVertexArrayObject {
  const context = useRenderingContext();
  const vao = useMemo((): WebGLVertexArrayObject => {
    const vertexArray = context.createVertexArray();
    if (vertexArray === null) throw new Error("Cannot create vertex array.");
    return vertexArray;
  }, [context]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteVertexArray(vao);
    }
  }, [context, vao]);

  return vao;
}
