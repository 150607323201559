import {GenIcon, IconBaseProps} from "react-icons";

export const LegendsIcon = (props: IconBaseProps) => GenIcon({
  tag: "svg",
  attr: {
    viewBox: "0 0 1000 1000",
    width: "1000",
    height: "1000"
  },
  child: [{
    tag: "path",
    attr: {
      id: "path",
      d: "M 500.00001,5.6e-6 485.50382,8.3677134 67,249.97055 V 750.02948 L 500.00001,1000 933,750.02948 V 249.97055 Z M 761.40284,315.6158 H 238.59717 L 153.74143,266.58811 500.00001,66.705958 846.25857,266.58811 Z M 500.00001,753.32942 280.78933,373.48263 H 719.21067 Z M 124.8669,316.6765 209.60479,365.58634 471.12548,818.85682 V 916.4408 L 124.8669,716.55865 Z M 528.87452,916.55865 V 818.97468 L 790.39522,365.70419 875.1331,316.79435 V 716.6765 Z"
    },
    child: []
  }]
})(props);