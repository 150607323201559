import {z} from "zod";
import {UserID} from "#common/legends/index.ts";
import {ConstantOperation, constantType} from "#common/types/index.ts";

export const InitiativeRequestMessage = z.object({
  userID: UserID
});
export type InitiativeRequestMessage = z.infer<typeof InitiativeRequestMessage>;

export const InitiativeRequestMessageOperation = ConstantOperation;
export type InitiativeRequestMessageOperation = ConstantOperation;

export const initiativeRequestMessageType = constantType;
