import {ValueFn, ValueOperation, ValueType} from "../value/index.ts";
import {HSL, HSLA} from "./RGBA.ts";
import {ConstantOperation, constantType, ConstantType} from "../constant/index.ts";
import {MutableRef} from "#common/ref";
import {pipe} from "#common/pipe";
import {distinct, map} from "#common/observable";
import {applyAll} from "../../type/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {z} from "zod";

export const ColorOperation = ValueOperation(HSLA, ConstantOperation);
export type ColorOperation = z.infer<typeof ColorOperation>;

export const colorType = new ValueType(new ConstantType<HSLA>());

export function ColorRef(valueRef: MutableRef<HSLA, ColorOperation[]>) {
  return ({
    colorRef: new MutableRef<HSL, ValueOperation<HSL, ConstantOperation>[]>({
      value() {return [valueRef.value[0], valueRef.value[1], valueRef.value[2]] as HSL;},
      observe: pipe(valueRef.observe, map(value => [value[0], value[1], value[2]] as HSL), distinct()),
      apply: (fn) => valueRef.apply((prev: HSLA): ColorOperation[] => {
        const hsl = [prev[0], prev[1], prev[2]] as HSL;
        const next = applyAll(new ValueType(constantType), hsl, fn(hsl));
        return ValueFn.set(prev, [next[0], next[1], next[2], prev[3]] as HSLA);
      }).then(value => [value[0], value[1], value[2]])
    }),
    alphaRef: new MutableRef<number, NumberOperation[]>({
      value() {
        return valueRef.value[3] * 100;
      },
      observe: pipe(valueRef.observe, map(value => value[3]), distinct()),
      apply: fn => valueRef.apply((prev: HSLA): ColorOperation[] => {
        const next = applyAll(numberType, prev[3], fn(prev[3])) / 100;
        return ValueFn.set(prev, [prev[0], prev[1], prev[2], next] as HSLA);
      }).then(value => value[3])
    })
  });
}
