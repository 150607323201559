import {useRenderingContext} from "./rendering-context.ts";
import {useEffect, useMemo} from "react";

export function useProgram(vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram {
  const context = useRenderingContext();
  const program = useMemo(() => {
    const program = context.createProgram();
    if (program === null) {
      throw new Error("Cannot create program");
    }

    context.attachShader(program, vertexShader);
    context.attachShader(program, fragmentShader);
    context.linkProgram(program);
    const programError = context.getProgramInfoLog(program);
    if (programError) {
      throw new Error(programError);
    }
    return program;
  }, [context, vertexShader, fragmentShader]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteProgram(program);
    };
  }, [context, program])

  return program;
}
