import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useStore} from "#lib/qlab/index.ts";
import {Dnd5eAttackMessage, QLabMessageID, RollResults} from "common/qlab/index.ts";
import {useDecryptValue, useOptionalDecryptValue} from "../use-decrypt-value.ts";
import {TextMessageContentView} from "../text-message-content-view.tsx";
import {UserNodeIcon} from "../user-node-icon.tsx";
import {IconButton} from "#lib/components/index.ts";
import {useState} from "react";
import {Dice} from "common/dice/index.ts";
import {FaSkull} from "react-icons/fa6";
import {Dnd5eAttackRollView} from "./dnd-5e-attack-roll-view.tsx";
import {Loader} from "common/loader";
import {Dnd5eApplyDamageButton} from "./dnd-5e-apply-damage-button.tsx";
import {Dnd5eDamageView} from "./dnd-5e-damage-view.tsx";
import {Dnd5eDescriptionView} from "./dnd-5e-description-view.tsx";
import {useIsPartyNode} from "../../../common/node/use-is-party-node.ts";
import {useIsGameMaster} from "../../../common/game/use-is-game-master.ts";

type Dnd5eAttackViewProps = {
  messageId: QLabMessageID;
  message: Dnd5eAttackMessage;
};

export function Dnd5eAttackMessageView({message}: Dnd5eAttackViewProps) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const title = useDecryptValue(message.title, userId, user.data?.privateKey);
  const description = useOptionalDecryptValue(message.description, userId, user.data?.privateKey);
  const hitMessage = useOptionalDecryptValue(message.onHit, userId, user.data?.privateKey);
  const critMessage = useOptionalDecryptValue(message.onCrit, userId, user.data?.privateKey);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);
  const isParty = useIsPartyNode(message.nodeID);
  const isGameMaster = useIsGameMaster();
  const isTokenReveal = isParty || isGameMaster;

  const [critToggle, setCritToggle] = useState(false);

  if (hitMessage.isLoading || critMessage.isLoading) return <></>;
  const isCritDefault = rollResults.isSuccess && rollResults.data && rollResults.data[message.attackRollID] && Dice.getSuccesses(rollResults.data[message.attackRollID]!) > 0;
  const isCrit = critToggle ? !isCritDefault : isCritDefault;
  return <div className="relative mt-7 mb-2 mx-2">
    <div className="rounded-lg shadow-lg bg-zinc-800/50  backdrop-blur-sm">
      {isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />}
      {!isTokenReveal && <UserNodeIcon userID={message.userID} nodeID={undefined} icon={undefined} />}

      <div className="pointer-events-auto overflow-hidden rounded-lg">
        <div className="font-bold italic text-center pb-2 pt-5 bg-zinc-800/50 rounded-t-lg">{title.data || "???"}</div>

        <Dnd5eAttackRollView
          request={rollRequests.isSuccess ? Loader.loaded(rollRequests.data[message.attackRollID]) : Loader.loading()}
          result={rollResults.isSuccess ? Loader.loaded(rollResults.data ? rollResults.data[message.attackRollID] : undefined) : Loader.loading()} />

        {(message.damageRolls.length > 0 || hitMessage.data !== undefined || critMessage.data !== undefined || description.data !== undefined) && <table className="w-full">
          <tbody className="divide-y divide-zinc-900/20">
          {(message.damageRolls.length > 0 || hitMessage.data !== undefined || critMessage.data !== undefined) && <tr className="bg-zinc-900/50">
            <td colSpan={2} className="font-bold italic text-sm text-center">
              <div className="flex">
                <span className="flex-grow pl-14 pr-4 py-2 ">
                    {isCrit ? "CRIT " : ""} DAMAGE
                </span>
                <IconButton variant="tertiary" className={isCrit ? "text-red-400" : undefined} onClick={() => setCritToggle(p => !p)} title={!isCrit ? "Make it a Crit!" : "Make it a non-Crit"}>
                  <FaSkull />
                </IconButton>
              </div>
            </td>
          </tr>}

          <Dnd5eDamageView damageRolls={message.damageRolls} requests={rollRequests} results={rollResults} criticalDamage={isCrit} />
          <Dnd5eApplyDamageButton damageRolls={message.damageRolls} criticalDamage={isCrit} results={rollResults} />

          {(hitMessage.data !== undefined || critMessage.data !== undefined) && <tr>
            {!isCrit && <td colSpan={2}>
              {hitMessage.data !== undefined && <div className="px-2 text-center">
                <TextMessageContentView messageContent={hitMessage.data} rollRequests={rollRequests.data} rollResults={rollResults.data} />
              </div>}
            </td>}
            {isCrit && <td colSpan={2}>
              {critMessage.data !== undefined && <div className="px-2 text-center">
                <TextMessageContentView messageContent={critMessage.data} rollRequests={rollRequests.data} rollResults={rollResults.data} />
              </div>}
            </td>}
          </tr>}

          <Dnd5eDescriptionView description={description} requests={rollRequests} results={rollResults} />

          </tbody>
        </table>}
      </div>
    </div>
  </div>
}
