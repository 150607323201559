import {KeyedListSignal, Optional} from "common/types/index.ts";
import {Asset, AssetOperation, AssetSignals, Token, TokenID, TokenOperation} from "common/legends/index.ts";
import {useMemo} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

const NOOP_TOKEN: MutableRef<Optional<Token>, TokenOperation[]> = new MutableRef<Optional<Token>, TokenOperation[]>({
  value() {
    return undefined;
  },
  observe: observer => {
    observer.next(undefined);
    return () => {
      observer.complete();
    };
  },
  apply: async (_) => {
    return undefined
  }
});

export function useAssetToken(asset: MutableRef<Asset, AssetOperation[]>, tokenID: TokenID): MutableRef<Optional<Token>, TokenOperation[]> {
  const {tokens} = useMemo(() => AssetSignals(asset), [asset]);
  const tokenSignals = useRefValue(useMemo(() => KeyedListSignal(tokens, token => token.tokenID, Token.copyToken), [tokens]));
  const keyedSignal = tokenSignals.find(([tID, _]) => tID === tokenID);
  return keyedSignal ? keyedSignal[1].itemRef : NOOP_TOKEN;
}
