import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import acid from "./dnd5e-damage-acid.png";
import bludgeoning from "./dnd5e-damage-bludgeoning.png";
import cold from "./dnd5e-damage-cold.png";
import fire from "./dnd5e-damage-fire.png";
import force from "./dnd5e-damage-force.png";
import lightning from "./dnd5e-damage-lightning.png";
import necrotic from "./dnd5e-damage-necrotic.png";
import piercing from "./dnd5e-damage-piercing.png";
import poison from "./dnd5e-damage-poison.png";
import psychic from "./dnd5e-damage-psychic.png";
import radiant from "./dnd5e-damage-radiant.png";
import slashing from "./dnd5e-damage-slashing.png";
import thunder from "./dnd5e-damage-thunder.png";
import unknown from "./dnd5e-damage-unknown.png";
import healing from "./dnd5e-damage-healing.png";

export const DAMAGE_TYPE_IMAGES: {[damageType in Dnd5eDamageType]: string} = {
  acid,
  "bludgeoning (non-magical)": bludgeoning,
  "bludgeoning (magical)": bludgeoning,
  "bludgeoning (silver)": bludgeoning,
  "bludgeoning (adamantine)": bludgeoning,
  cold,
  fire,
  force,
  lightning,
  necrotic,
  "piercing (non-magical)": piercing,
  "piercing (magical)": piercing,
  "piercing (silver)": piercing,
  "piercing (adamantine)": piercing,
  poison,
  psychic,
  radiant,
  "slashing (non-magical)": slashing,
  "slashing (magical)": slashing,
  "slashing (silver)": slashing,
  "slashing (adamantine)": slashing,
  thunder,
  healing,
  "temp hp": healing
};

export const DAMAGE_TYPE_UNKNOWN = unknown;
