import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {Select} from "#lib/components/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eSavingThrowProficiency, DND_5E_SAVING_THROW_PROFICIENCIES} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-saving-throw-proficiency.ts";
import {MutableRef} from "common/ref";

export type InputDnd5eSavingThrowProficiencyProps = {
  readOnly?: boolean;
  value: MutableRef<Dnd5eSavingThrowProficiency, ValueOperation<Dnd5eSavingThrowProficiency, ConstantOperation>[]>;
};

export function InputDnd5eSavingThrowProficiency({value, readOnly}: InputDnd5eSavingThrowProficiencyProps) {
  const proficiencyValue = useRefValue(value);
  return <Select disabled={readOnly} value={proficiencyValue} onChange={ev => {
    const next = ev.target.value as Dnd5eSavingThrowProficiency;
    value.apply(prev => ValueFn.set(prev, next));
  }}>
    <option value="untrained"></option>
    {DND_5E_SAVING_THROW_PROFICIENCIES.filter(t => t !== "untrained").map(proficiency => <option key={proficiency} value={proficiency}>{proficiency} </option>)}
  </Select>
}
