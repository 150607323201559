import {ConeFn, Optional, Point, Transform} from "common/types/index.ts";
import {Asset, AssetID, Node, SceneID, TokenID} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../../../container/editor/state/selection-ref.ts";
import {QLabDatabase} from "common/qlab/index.ts";
import {isPointInArea} from "../../../../container/editor/tool-mode/area/area-editor-container/is-point-in-area.ts";
import {getTextHeight, getTextOrigin, getTextWidth} from "#lib/gl-react/component/font-helper.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {Matrix4x4Fn} from "common/math/matrix/matrix4x4.ts";

function getAtPoint(
  rootSelectionNode: SceneSelectionRef | AssetTokenSelectionRef,
  store: QLabDatabase,
  value: Node,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  [x, y]: [number, number],
  view: Transform
): NodeSelectionRef[] {
  const nodeID: NodeSelectionRef = rootSelectionNode.type === "scene"
    ? {...rootSelectionNode, type: "scene-node", nodeId: value.data.id}
    : {...rootSelectionNode, type: "asset-token-node", nodeId: value.data.id};

  if (!isVisible(value) && !isAccessible(value)) return [];
  const matrix = value.type !== "parallax" ? Matrix4f.invert(Matrix4f.transform(value.data.transform)) : Matrix4f.MULTIPLICATIVE_IDENTITY;
  [x, y] = Matrix4f.multiplyVector(matrix, [x, y, 0, 1]);
  const nodeIds: NodeSelectionRef[] = [];
  if (value.type === "image") {
    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
    if (isAccessible(value)) {
      const [nx, ny] = value.data.origin;
      const [w, h] = value.data.size;
      const rx = value.data.repeatX === null ? Number.POSITIVE_INFINITY : value.data.repeatX;
      const ry = value.data.repeatY === null ? Number.POSITIVE_INFINITY : value.data.repeatY;
      if ( (!Number.isFinite(rx) || ((0-nx) <= x && x <= (w*rx-nx)))
        && (!Number.isFinite(ry) || ((0-ny) <= y && y <= (h*ry-ny)))) {
        nodeIds.push(nodeID);
      }
    }
  } else if (value.type === "group") {
    const childNodeIds: NodeSelectionRef[] = [];
    for (const child of value.data.children) {
      childNodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
    nodeIds.push(...childNodeIds);
    if (isAccessible(value)) {
      const [nx, ny] = value.data.origin;
      const [w, h] = value.data.size;
      if ((0-nx) <= x && x <= (w-nx) && (0-ny) <= y && y <= (h-ny)) {
        nodeIds.push(nodeID);
      }
    }
  } else if (value.type === "token") {
    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
    if (isAccessible(value)) {
      const asset = store.resources[value.data.tokenReference.assetID]?.data as unknown as Optional<Asset>;
      if (asset && asset.tokens.find(token => token.tokenID === value.data.tokenReference.tokenID) !== undefined) {
        const token = asset.tokens.find(token => token.tokenID === value.data.tokenReference.tokenID)!;
        const [nx, ny] = token.origin;
        const [w, h] = token.size;
        if ((0-nx) <= x && x <= (w-nx) && (0-ny) <= y && y <= (h-ny)) {
          nodeIds.push(nodeID);
        } else if (token.children.some(child => getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view).length > 0)) {
          nodeIds.push(nodeID);
        }
      }
    }
  } else if (value.type === "grid") {
    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
  } else if (value.type === "text") {
    if (isAccessible(value)) {
      const w = getTextWidth(value.data.text, value.data.size);
      const h = getTextHeight(value.data.text, value.data.size);
      const [nx, ny] = getTextOrigin(value.data.text, value.data.size, value.data.hTextAlign, value.data.vTextAlign);
      if ((0 - nx) <= x && x <= (w - nx) && (0 - ny) <= y && y <= (h - ny)) {
        nodeIds.push(nodeID);
      }
    }
    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
    return nodeIds;
  } else if (value.type === "shape") {
    if (isAccessible(value)) {
      if (value.data.shape.type === "rectangle") {
        const w = value.data.shape.data.width;
        const h = value.data.shape.data.height;
        const [nx, ny] = value.data.origin;
        if ((0 - nx) <= x && x <= (w - nx) && (0 - ny) <= y && y <= (h - ny)) {
          nodeIds.push(nodeID);
        }
      } else if (value.data.shape.type === "cone") {
        const [nx, ny] = value.data.origin;
        const p: Point = [x + nx, y + ny];
        const [a, b] = ConeFn.toPoints(value.data.shape.data);
        if (
          Vector2.cross(Vector2.subtract(a, p), Vector2.subtract(b, p)) >= 0 &&
          Vector2.cross(Vector2.subtract(b, p), Vector2.subtract([0, 0], p)) >= 0 &&
          Vector2.cross(Vector2.subtract([0, 0], p), Vector2.subtract(a, p)) >= 0
        ) {
          nodeIds.push(nodeID);
        }
      } else if (value.data.shape.type === "arc") {
        const {startAngle, endAngle, radius} = value.data.shape.data;
        const s = (startAngle + 360) % 360;
        const e = (endAngle + 360) % 360;

        const [nx, ny] = value.data.origin;
        const p: Point = [x + nx, y + ny];
        const d = Math.pow(p[0] * p[0] + p[1] * p[1], 0.5);
        if (d <= radius) {
          const a = (Math.atan2(p[1], p[0]) * 360 / (2 * Math.PI) + 360) % 360;
          if (s < e && a >= s && a <= e) {
            nodeIds.push(nodeID);
          } else if (s > e && (a >= s || a <= e)) {
            nodeIds.push(nodeID);
          } else if (s === e) {
            nodeIds.push(nodeID);
          }
        }
      }
    }

    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [x, y], view));
    }
    return nodeIds;
  } else if (value.type === "parallax") {
    const offset = Vector2.multiplyMatrix4x4(view.position, Matrix4x4Fn.invert(Transform.toMatrix4x4(value.data.transform)));
    const [nx, ny] = [
      x - (offset[0] - view.position[0]) / view.scale,
      y - (offset[1] - view.position[1]) / view.scale
    ];

    for (const child of value.data.children) {
      nodeIds.push(...getAtPoint(rootSelectionNode, store, child, isAccessible, isVisible, [nx, ny], {
        ...view,
        position: [
          view.position[0] - offset[0],
          view.position[1] - offset[1]
        ]
      }));
    }
    return nodeIds;
  }
  return nodeIds;
}

export function getNodesAtPoint(
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  store: QLabDatabase,
  isNodeAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  view: Transform,
  worldPos: Point,
  children: Node[]
): NodeSelectionRef[] {
  const nodesAtPoint = [];
  for (let i = children.length; i --; i > 0) {
    nodesAtPoint.push(...getAtPoint(rootSelectionRef, store, children[i], isNodeAccessible, isVisible, worldPos, view));
  }
  return nodesAtPoint;
}


export type NodePath =
  | {type: "asset", assetID: AssetID, tokenID: TokenID, path: number[]}
  | {type: "scene", sceneID: SceneID, path: number[]}
  ;

function _getAreasAtPoint(
  database: QLabDatabase,
  currentPath: NodePath,
  value: Node,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  [x, y]: [number, number],
  view: Transform
): [
  NodePath[],
  Node
][] {
  if (!isVisible(value) && !isAccessible(value)) return [];

  // Apply Transformation
  const matrix = Matrix4f.invert(Matrix4f.transform(value.data.transform));
  [x, y] = Matrix4f.multiplyVector(matrix, [x, y, 0, 1]);
  const nodeIds:  [
    NodePath[],
    Node
  ][] = [];

  // Process Node
  if (value.type === "image" || value.type === "group" || value.type === "grid") {
    for (let index = value.data.children.length - 1; index >= 0; index --) {
      const child = value.data.children[index];
      nodeIds.push(..._getAreasAtPoint(database, {...currentPath, path: [...currentPath.path, index]}, child, isAccessible, isVisible, [x, y], view));
    }
  } else if (value.type === "token") {
    for (let index = value.data.children.length - 1; index >= 0; index --) {
      const child = value.data.children[index];
      nodeIds.push(..._getAreasAtPoint(database, {...currentPath, path: [...currentPath.path, index]}, child, isAccessible, isVisible, [x, y], view));
    }
    const asset = database.resources[value.data.tokenReference.assetID]?.data as unknown as Optional<Asset>;
    if (asset && asset.tokens.find(token => token.tokenID === value.data.tokenReference.tokenID) !== undefined) {
      const token = asset.tokens.find(token => token.tokenID === value.data.tokenReference.tokenID)!;
      const rootAssetPath: NodePath = {type: "asset", assetID: value.data.tokenReference.assetID, tokenID: value.data.tokenReference.tokenID, path: []};

      for (let index = token.children.length - 1; index >= 0; index --) {
        const child = token.children[index];

        const paths = _getAreasAtPoint(database, {...rootAssetPath, path: [index]}, child, isAccessible, isVisible, [x, y], view);
        nodeIds.push(...paths.map(([paths, area]): [NodePath[], Node] => [[currentPath, ...paths], area]));
      }
    }
  } else if (value.type === "area") {
    for (const area of value.data.areas) {
      if (isPointInArea([x, y], area)) {
        nodeIds.push([[currentPath], value]);
      }
    }
  } else if (value.type === "parallax") {
    const offset = Vector2.multiplyMatrix4x4(view.position, Matrix4x4Fn.invert(Transform.toMatrix4x4(value.data.transform)));
    const [nx, ny] = [
      x - (offset[0] - view.position[0]) / view.scale,
      y - (offset[1] - view.position[1]) / view.scale
    ];

    for (let index = value.data.children.length - 1; index >= 0; index --) {
      const child = value.data.children[index];
      nodeIds.push(..._getAreasAtPoint(database, {...currentPath, path: [...currentPath.path, index]}, child, isAccessible, isVisible, [nx, ny], {
        ...view,
        position: [
          view.position[0] - offset[0],
          view.position[1] - offset[1]
        ]
      }));
    }
    return nodeIds;
  }
  return nodeIds;
}

export function getAreasAtPoint(
  rootPath: NodePath,
  store: QLabDatabase,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  worldPos: Point,
  view: Transform,
  children: Node[]
): [
  NodePath[],
  Node
][] {
  const areas = [];
  for (let i = children.length; i --; i > 0) {
    const indexedPath: NodePath = {...rootPath, path: [i]};
    areas.push(..._getAreasAtPoint(store, indexedPath, children[i], isAccessible, isVisible, worldPos, view));
  }
  return areas;
}
