import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eDamageType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {ConstantOperation, constantType, NumberOperation, numberType, ObjectType, PropertyRef, SetOperation, SetType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eDamageReductionModifier = z.object({
  modifierID: Dnd5eModifierID,
  damageTypes: z.array(Dnd5eDamageType),
  amount: z.number()
});
export type Dnd5eDamageReductionModifier = z.infer<typeof Dnd5eDamageReductionModifier>

export const Dnd5eDamageReductionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-damage-types"), operations: z.array(SetOperation(Dnd5eDamageType))}),
  z.object({type: z.literal("update-amount"), operations: z.array(NumberOperation)})
]);
export type Dnd5eDamageReductionModifierOperation = z.infer<typeof Dnd5eDamageReductionModifierOperation>

export const dnd5eDamageReductionModifierType = new ObjectType({
  modifierID: constantType,
  damageTypes: new SetType<Dnd5eDamageType>(),
  amount: numberType
});

export function Dnd5eDamageReductionModifierSignals(value: MutableRef<Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation[]>) {
  return ({
    damageTypes: PropertyRef<Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation, Dnd5eDamageType[], SetOperation<Dnd5eDamageType>>(
      value => value.damageTypes,
      operations => [{type: "update-damage-types", operations}]
    )(value),
    amount: PropertyRef<Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation, number, NumberOperation>(
      value => value.amount,
      operations => [{type: "update-amount", operations}]
    )(value)
  });
}
