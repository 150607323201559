import {
  ConstantOperation,
  constantType,
  ObjectType,
  PropertyRef,
  Set,
  SetOperation,
  SetType,
  Transform,
  TransformOperation,
  transformType,
  Type,
  ValueOperation,
  ValueType
} from "common/types/index.ts";
import {SceneReference} from "common/legends/scene/scene-reference.ts";
import {AccessMask, AccessMaskOperation, accessMaskType} from "common/legends/visibility/index.ts";
import {Tool, ToolOperation, toolType} from "../../common/tool-mode/tool.ts";
import {NodeSelectionRef, SelectionRef} from "../../container/editor/state/selection-ref.ts";
import {ViewConfiguration, ViewConfigurationOperation, viewConfigurationType} from "../common/view-configuration.ts";
import {MutableRef} from "common/ref";


export type SceneViewportProperties = {
  sceneReference: SceneReference;
  toolMode: Tool;
  selectedNodeIds: Set<NodeSelectionRef>;
  visibilityMask: AccessMask;
  view: Transform;
  viewConfiguration: ViewConfiguration;
};
export type SceneViewportPropertiesOperation =
  | {type: "update-scene-reference", operations: ValueOperation<SceneReference, ConstantOperation>[]}
  | {type: "update-tool-mode", operations: ToolOperation[]}
  | {type: "update-selected-node-ids", operations: SetOperation<NodeSelectionRef>[]}
  | {type: "update-visibility-mask", operations: AccessMaskOperation[]}
  | {type: "update-view", operations: TransformOperation[]}
  | {type: "update-view-configuration", operations: ViewConfigurationOperation[]}
  ;
export const sceneViewportPropertiesType: Type<SceneViewportProperties, SceneViewportPropertiesOperation> = new ObjectType({
  sceneReference: new ValueType(constantType),
  toolMode: toolType,
  selectedNodeIds: new SetType<NodeSelectionRef>(SelectionRef.equals),
  visibilityMask: accessMaskType,
  view: transformType,
  viewConfiguration: viewConfigurationType
});

export function SceneViewportPropertiesSignals(value: MutableRef<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  return {
    toolMode: PropertyRef<SceneViewportProperties, SceneViewportPropertiesOperation, Tool, ToolOperation>(
      value => value.toolMode,
      operations => [{type: "update-tool-mode", operations}]
    )(value),
    view: PropertyRef<SceneViewportProperties, SceneViewportPropertiesOperation, Transform, TransformOperation>(
      value => value.view,
      operations => [{type: "update-view", operations}]
    )(value),
    sceneReference: PropertyRef<SceneViewportProperties, SceneViewportPropertiesOperation, SceneReference, ValueOperation<SceneReference, ConstantOperation>>(
      value => value.sceneReference,
      operations => [{type:"update-scene-reference", operations}]
    )(value),
    viewConfiguration: PropertyRef<SceneViewportProperties, SceneViewportPropertiesOperation, ViewConfiguration, ViewConfigurationOperation>(
      value => value.viewConfiguration,
      operations => [{type: "update-view-configuration", operations}]
    )(value)
  };
}