import {QLabDatabase, StoreType, StoreTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {distinct, filter, map, Observable} from "common/observable";
import {pipe} from "common/pipe";

export function storeObservable<Type extends StoreType, Value = ExtractValue<StoreTypes[Type]>>(type: Type): (observe: Observable<QLabDatabase>) => Observable<Value> {
  return (observe) => pipe(
    observe,
    filter(store => store.store?.type === type),
    map(store => store.store!.data as unknown as Value),
    distinct(),
  );
}
