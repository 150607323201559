import {Button, ButtonBar, Checkbox, ExpandableLabel, IconButton, InputGroup, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaPlus, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {BooleanFn, ListOperation} from "common/types/index.ts";
import {Dice} from "common/dice/index.ts";
import React, {useCallback, useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {
  Dnd5eActionEffect,
  Dnd5eActionEffectFn,
  Dnd5eActionEffectOperation,
  Dnd5eActionEffectSignal,
  getActionEffectID
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect.ts";
import {Dnd5eActionEffectID, generateDnd5eActionEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-effect-id.ts";
import {Dnd5eActionModifiersView} from "./dnd-5e-action-modifiers-view.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {copyDnd5eActionEffect} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action-effect.ts";
import {MutableRef} from "common/ref";

export type Dnd5eActionEffectViewProps = InputListItemProps<Dnd5eActionEffect, Dnd5eActionEffectOperation> & {
  toggle: (actionEffectID: Dnd5eActionEffectID) => void;
};
function Dnd5eActionEffectView({item, remove, toggle, duplicate}: Dnd5eActionEffectViewProps) {
  const [expanded, toggleExpanded] = useToggle(false);

  const {name, enabled, modifiers} =  useMemo(() => Dnd5eActionEffectSignal(item), [item]);
  const enabledValue = useRefValue(enabled);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-effect", item, remove);
  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <InputGroup>
          <Checkbox checked={enabledValue} onChange={() => toggle(item.value.actionEffectID)} />
        </InputGroup>
        <InputGroup className="flex-1">
          <InputString type="text" value={name} />
        </InputGroup>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Modifier" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <Dnd5eActionModifiersView value={modifiers} />
    </Fieldset>}
  </div>;
}

export type Dnd5eActionEffectsViewProps = {
  value: MutableRef<Dnd5eActionEffect[], ListOperation<Dnd5eActionEffect, Dnd5eActionEffectOperation>[]>;
};

function defaultActionEffect(): Dnd5eActionEffect {
  return {
    actionEffectID: generateDnd5eActionEffectID(),
    name: "GWM/SS",
    enabled: false,
    modifiers: [{
      type: "attack-roll",
      data: {
        modifierID: generateDnd5eModifierID(),
        expression: Dice.assertDiceExpression("-5")
      }
    }, {
      type: "damage-roll",
      data: {
        modifierID: generateDnd5eModifierID(),
        damageType: undefined,
        hitExpression: Dice.assertDiceExpression("10"),
        critExpression: Dice.assertDiceExpression("10")
      }
    }]
  };
}

export function useToggleActionEffect(actionEffects: MutableRef<Dnd5eActionEffect[], ListOperation<Dnd5eActionEffect, Dnd5eActionEffectOperation>[]>) {
  return useCallback((actionEffectID: Dnd5eActionEffectID) => {
    actionEffects.apply(prev => {
      const effect = prev.find(e => e.actionEffectID === actionEffectID);
      if (effect === undefined) return [];
      const group = Dnd5eActionEffectFn.getGroup(effect);
      if (group === undefined) {
        return ListOperation.apply(prev.indexOf(effect), [{
          type: "update-enabled",
          operations: BooleanFn.set(effect.enabled, !effect.enabled)
        }]);
      } else {
        if (effect.enabled) {
          return ListOperation.apply(prev.indexOf(effect), [{
            type: "update-enabled",
            operations: BooleanFn.set(effect.enabled, !effect.enabled)
          }]);
        } else {
          const disactivateEffects = prev.filter(e => Dnd5eActionEffectFn.getGroup(e) === group).filter(e => e.enabled);
          const activateEffects = prev.filter(e => Dnd5eActionEffectFn.getGroup(e) === group).filter(e => e.actionEffectID === actionEffectID);
          return [
            ...disactivateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eActionEffect, Dnd5eActionEffectOperation>[],
            ...activateEffects.flatMap(effect => ListOperation.apply(prev.indexOf(effect), [{
              type: "update-enabled",
              operations: BooleanFn.set(effect.enabled, !effect.enabled)
            }])) satisfies ListOperation<Dnd5eActionEffect, Dnd5eActionEffectOperation>[]
          ];
        }
      }
    });
  }, [actionEffects]);
}

export function Dnd5eActionEffectsView({value}: Dnd5eActionEffectsViewProps) {
  const addActionEffect = useCallback((actionEffect: Dnd5eActionEffect) => value.apply(prev => ListOperation.insert(prev.length, actionEffect)), [value.apply]);
  const toggle = useToggleActionEffect(value);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1">Action Effects</SectionHeader>
      <ButtonBar>
        <Button onClick={() => addActionEffect(defaultActionEffect())}>
          <FaPlus /> New Action Effect
        </Button>
      </ButtonBar>
    </div>

    <InputList<Dnd5eActionEffect, Dnd5eActionEffectOperation>
      accept="legends/action-effect"
      items={value}
      itemKey={getActionEffectID}
      copy={copyDnd5eActionEffect}
      ListItem={useCallback((props) => <Dnd5eActionEffectView {...props} toggle={toggle} />, [toggle])} />
  </div>);
}