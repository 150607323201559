import React, {useContext} from "react";

const RenderingContext = React.createContext<WebGL2RenderingContext | undefined>(undefined);

export const RenderingContextProvider = RenderingContext.Provider;

export function useRenderingContext(): WebGL2RenderingContext {
  const renderingContext = useContext(RenderingContext);
  if (!renderingContext) {
    throw new Error("Rendering Context does not exist.");
  }
  return renderingContext;
}
