import {useRenderingContext} from "./rendering-context.ts";
import {useMemo} from "react";

export function useUniformLocation(program: WebGLProgram, name: string): WebGLUniformLocation {
  const context = useRenderingContext();
  return useMemo((): WebGLUniformLocation => {
    const uniformLocation = context.getUniformLocation(program, name);
    if (uniformLocation === null) console.warn("Invalid Uniform Location: " + name);
    return uniformLocation!;
  }, [context, program, name]);
}
