import {useMemo} from "react";
import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {IconButton, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {MutableRef} from "common/ref";

export type InputAreaVertexProps = {
  label?: string | undefined;
  value: MutableRef<Point, PointOperation[]>;
  remove?: () => void;
}
export function InputAreaVertex({label = "Point", value, remove}: InputAreaVertexProps) {
  const {x, y} = useMemo(() => PointSignals(value), [value]);
  return <div className="flex flex-row rounded-md overflow-hidden">
    <InputGroup>
      <InputGroupLabel>{label}</InputGroupLabel>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>X</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={x}/>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>Y</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={y} />
    </InputGroup>
    {remove && <IconButton variant="destructive" onClick={remove}>
        <FaTrash />
    </IconButton>}
  </div>
}
