import {Point, Transform, TransformOperation} from "common/types/index.ts";
import {useCallback} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {MutableRef} from "common/ref";


export function getWorldPositionFromScreenPosition(view: Transform, point: Point) {
  const viewMat4 = Matrix4f.transform(view);
  const modelMat4 = Matrix4f.transform(Transform.DEFAULT);
  const mat4 = Matrix4f.multiplyMatrix(Matrix4f.invert(modelMat4), Matrix4f.invert(viewMat4));
  return Matrix4f.multiplyVector(mat4, [point[0], point[1], 0, 1]).slice(0, 2) as Point;
}

export function useGetWorldPositionFromScreenPosition(
  viewRef: MutableRef<Transform, TransformOperation[]>
) {
  return useCallback((screenPos: Point): Point => {
    return getWorldPositionFromScreenPosition(viewRef.value, screenPos);
  }, [viewRef])
}
