import {applyAll, invertAll, transformAll, Type} from "../../type/index.ts";
import {z, ZodType} from "zod";
import {ValidationError} from "#common/types/type/validation/validation.ts";

export type ValueOperation<Value, Operation> =
  | {type: "set", prevValue: Value, nextValue: Value}
  | {type: "apply", operations: Operation[]}
  ;
export function ValueOperation<Value, Operation>(valueType: ZodType<Value>, operationType: ZodType<Operation>) {
  return z.discriminatedUnion("type", [
    z.object({type: z.literal("set"), prevValue: valueType, nextValue: valueType}),
    z.object({type: z.literal("apply"), operations: z.array(operationType)})
  ]);
}

export const ValueFn = {
  apply: <Value, Operation>(operations: Operation[]): ValueOperation<Value, Operation>[] => {
    if (operations.length === 0) return [];
    return [{type: "apply", operations}]
  },
  set: <Value, Operation>(prevValue: Value, nextValue: Value): ValueOperation<Value, Operation>[] => {
    if (prevValue !== nextValue) return [{type: "set", prevValue, nextValue}]
    return [];
  }
};


export class ValueType<Value, Operation> implements Type<Value, ValueOperation<Value, Operation>> {
  constructor(private readonly itemType: Type<Value, Operation>, private readonly updateFn: (value: any) => Value = (v) => v) {
  }

  apply = (value: Value, operation: ValueOperation<Value, Operation>): Value => {
    if (operation.type === "set") {
      return operation.nextValue;
    } else if (operation.type === "apply") {
      return applyAll(this.itemType, value, operation.operations);
    } else {
      throw new Error("Unexpected operation.");
    }
  }

  invert = (operation: ValueOperation<Value, Operation>): ValueOperation<Value, Operation>[] => {
    if (operation.type === "set") {
      return [{type: "set", prevValue: operation.nextValue, nextValue: operation.prevValue}];
    } else if (operation.type === "apply") {
      return [{type: "apply", operations: invertAll(this.itemType, operation.operations)}];
    } else {
      throw new Error("Unexpected operation.");
    }
  }
  transform = (leftOperation: ValueOperation<Value, Operation>, topOperation: ValueOperation<Value, Operation>, tieBreaker: boolean): ValueOperation<Value, Operation>[] => {
    if (leftOperation.type === "set" && topOperation.type === "set") {
      return tieBreaker
        ? [leftOperation]
        : [{
          type: "set",
          prevValue: topOperation.nextValue,
          nextValue: leftOperation.nextValue
        }];
    } else if (leftOperation.type === "set" && topOperation.type === "apply") {
      return tieBreaker
        ? [leftOperation]
        : [{
          type: "set",
          prevValue: applyAll(this.itemType, leftOperation.prevValue, topOperation.operations),
          nextValue: leftOperation.nextValue
        }];
    } else if (leftOperation.type === "apply" && topOperation.type === "set") {
      if (tieBreaker) {
        return [leftOperation];
      } else {
        return [];
      }
    } else if (leftOperation.type === "apply" && topOperation.type === "apply") {
      return [{type: "apply", operations: transformAll(this.itemType, leftOperation.operations, topOperation.operations, tieBreaker)[0]}];
    } else {
      throw new Error("Unexpected operation.");
    }
  }
  migrateValue = (value: any): Value => {
    return this.itemType.migrateValue(this.updateFn(value));
  }
  migrateOperation = (operation: any): ValueOperation<Value, Operation>[] => {
    if (operation.type === "set") {
      return [{type: "set", prevValue: this.itemType.migrateValue(operation.prevValue), nextValue: this.itemType.migrateValue(operation.nextValue)}];
    } else if (operation.type === "apply") {
      return [{type: "apply", operations: operation.operations.flatMap(this.itemType.migrateOperation)}];
    } else {
      throw new Error("Unexpected operation.");
    }
  }
  validate = (value: any): ValidationError[] => {
    return this.itemType.validate(value);
  }
}