import {useRenderingContext} from "./rendering-context.ts";
import {useEffect, useMemo} from "react";

export function useFramebuffer(): WebGLBuffer {
  const context = useRenderingContext();
  const framebuffer = useMemo((): WebGLBuffer => {
    const buffer = context.createFramebuffer();
    if(context.checkFramebufferStatus(WebGL2RenderingContext.FRAMEBUFFER) !== WebGL2RenderingContext.FRAMEBUFFER_COMPLETE) {
      throw new Error("Cannot create framebuffer.");
    }

    if (buffer === null) throw new Error("Cannot create framebuffer.");
    return buffer;
  }, [context]);

  useEffect((): () => void => {
    return (): void => {
      context.deleteFramebuffer(framebuffer);
    }
  }, [context, framebuffer])

  return framebuffer;
}
