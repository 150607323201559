import {FileReference, Point, Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {useImageTexture} from "./use-image-texture.ts";
import {ImageShader} from "./image-shader.tsx";
import {Nullable} from "common/types/generic/nullable/index.ts";

export type ImageProps = {
  url: FileReference;
  size: Point;
  origin: Point;
  pivot: Point;
  projection: Matrix4f;
  view: Transform;
  model: Transform;
  opacity: number;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
};

export function ImageView({url, ...props}: ImageProps) {
  // is image on screen?
  const finalUrl = useMemo(() => {
    if (props.projection.some(v => Number.isNaN(v) || !Number.isFinite(v))) return undefined;
    const pvm = Matrix4f.multiplyMatrix(Matrix4f.multiplyMatrix(
      props.projection,
      Matrix4f.transform(props.view),
    ), Matrix4f.transform(props.model));
    const points = [
      [              - props.origin[0],               - props.origin[1]],
      [props.size[0] - props.origin[0],               - props.origin[1]],
      [props.size[0] - props.origin[0], props.size[1] - props.origin[1]],
      [              - props.origin[0], props.size[1] - props.origin[1]]
    ]
      .map(point => Matrix4f.multiplyVector(pvm, [point[0], point[1], 0, 1]));
    return (props.repeatX === 1 && props.repeatY === 1 && (
      points.every(p => p[0] < -1.25) ||
      points.every(p => p[0]  > 1.25) ||
      points.every(p => p[1] < -1.25) ||
      points.every(p => p[1]  > 1.25)
    )) ? undefined : url;
  }, [props.projection, props.view, props.model, props.origin, props.size, props.repeatX, props.repeatY]);

  const [isLoaded, imageTexture] = useImageTexture(finalUrl);
  if (!isLoaded) return <></>;
  return <ImageShader key={url} {...props} imageTexture={imageTexture} />;
}
