import {Dnd5eFeature, Sheet} from "common/legends/index.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Optional} from "common/types/index.ts";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eAbilityCheckProficiency} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-ability-check-proficiency.ts";

export function getDnd5eAbilityCheckProficiency(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], type: Dnd5eAbilityCheckType): Dnd5eAbilityCheckProficiency | "untrained" {
  const activeModifiers = getDnd5eSheetActiveModifiers(sheet, globalFeatures);
  const isExpertise = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(type) && m.data.proficiency === "expertise");
  if (isExpertise) return "expertise";
  const isProficient = activeModifiers.some(m => m.type === "ability-check" && m.data.abilityChecks.includes(type) && m.data.proficiency === "proficient");
  if (isProficient) return "proficient";
  return "untrained";
}
