import {useEffect} from "react";
import {pipe} from "common/pipe";
import {filter, map, subscribe} from "common/observable";
import {ScenePing} from "common/qlab/stream/q-lab-stream.ts";
import {Apply, Transform, TransformOperation} from "common/types/index.ts";
import {GameID, SceneID} from "common/legends/index.ts";
import {useInstance} from "#lib/qlab/index.ts";

export function useSceneFocus(storeId: GameID, resourceId: SceneID, applyToView: Apply<Transform, TransformOperation[]>) {
  const instance = useInstance();
  useEffect(() => pipe(
    instance.streamObservable(storeId),
    filter(effect => effect.type === "ping" && effect.data.type === "scene" && effect.data.sceneID === resourceId),
    map(effect => effect.data as ScenePing),
    subscribe((effect: ScenePing) => {
      if (effect.focus) {
        applyToView(prev => {
          const vPosition = Transform.divide({position: effect.position, scale: 1, rotation: 0}, {...prev, position: [0,0]}).position;
          return [{
            type: "apply",
            operations: [{
              type: "update-position", operations: [
                {type: "update-x", operations: [{type: "set", prevValue: prev.position[0], nextValue: -vPosition[0]}]},
                {type: "update-y", operations: [{type: "set", prevValue: prev.position[1], nextValue: -vPosition[1]}]}
              ]
            }]
          }]
        });
      }
    })
  ), [storeId, resourceId, applyToView]);
}
