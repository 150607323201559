import {AuthToken} from "#lib/auth/auth-client.ts";
import {AccessToken} from "common/access-token";
import {QLabStore, QLabStoreChangeset, QLabStoreID} from "common/qlab/index.ts";
import {Ref} from "common/ref";
import {Codec} from "common/codec";

export class QlabHttpClient {
  constructor(private readonly authTokenRef: Ref<AuthToken | undefined>, private readonly accessTokensRef: Ref<AccessToken[]>) {}

  getStore = async (storeID: QLabStoreID): Promise<QLabStore> => {
    const accessTokens = this.accessTokensRef.value;
    const authToken = this.authTokenRef.value;
    const searchParams = accessTokens.map((accessToken) => `accessToken=${encodeURIComponent(JSON.stringify(accessToken))}`).join("&");
    const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/store/${storeID}?${searchParams}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Accept": "application/json",
        ...(authToken ? {"Authorization": `Bearer ${authToken}`} : {})
      }
    });
    return await Codec.decode(await response.text());
  }

  applyToStore = async (storeID: QLabStoreID, changesets: QLabStoreChangeset[]) => {
    const accessTokens = this.accessTokensRef.value;
    const authToken = this.authTokenRef.value;
    let searchParams = accessTokens.map((accessCode) => `accessToken=${encodeURIComponent(JSON.stringify(accessCode))}`).join("&");
    const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/store/${storeID}?${searchParams}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...(authToken ? {"Authorization": `Bearer ${authToken}`} : {})
      },
      body: JSON.stringify(changesets)
    });
    if (!response.ok) {
      throw new Error("Failed to apply changeset: " + response.statusText + "\n" + (await response.text()));
    }
  }
}
