import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {z} from "zod";

export const GMSheet = z.object({
  reference: z.optional(SheetReference)
});
export type GMSheet = z.infer<typeof GMSheet>;

export const GMSheetOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-reference"), operations: z.array(ValueOperation(z.optional(SheetReference), ConstantOperation))})
]);
export type GMSheetOperation = z.infer<typeof GMSheetOperation>;

export const gMSheetType: Type<GMSheet, GMSheetOperation> = new ObjectType({
  reference: new ValueType(constantType)
});


export const GMSheetFn = {
  getReference: (value: GMSheet): Optional<SheetReference> => value.reference,
  updateReference: (operations: ValueOperation<Optional<SheetReference>, ConstantOperation>[]): GMSheetOperation[] => [{type: "update-reference", operations}]
};