import {Dnd5eFeature} from "#common/legends/index.ts";
import {generateDnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {copyDnd5eResource} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/copy-dnd-5e-resource.ts";
import {copyDnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/copy-dnd-5e-modifier.ts";
import {copyDnd5eAction} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-action/copy-dnd-5e-action.ts";
import {copyDnd5eEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/copy-dnd-5e-effect.ts";

export function copyDnd5eFeature(value: Dnd5eFeature): Dnd5eFeature {
  return ({
    ...value,
    featureID: generateDnd5eFeatureID(),
    resources: value.resources.map(copyDnd5eResource),
    modifiers: value.modifiers.map(copyDnd5eModifier),
    actions: value.actions.map(copyDnd5eAction),
    effects: value.effects.map(copyDnd5eEffect)
  });
}
