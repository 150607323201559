import {Button, ButtonBar, InputGroup, InputGroupLabel, TagListInput} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {SceneTreeFile, SceneTreeFileFn, SceneTreeFileOperation} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";

export type EditSceneModalProps = {
  value: MutableRef<SceneTreeFile, SceneTreeFileOperation[]>;
  onClose: () => void;
};

export function EditSceneModal({onClose, value}: EditSceneModalProps) {
  const {name, tags} = useMemo(() => SceneTreeFileFn(value), [value]);

  return (<Modal onClose={onClose}>
    <ModalTitle>Edit Scene</ModalTitle>
    <ModalBody>
      <InputGroup>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString autoFocus value={name} />
      </InputGroup>
      <TagListInput value={tags} />
    </ModalBody>
    <ButtonBar>
      <Button type="button" className="grow" variant="tertiary" onClick={onClose}>Close</Button>
    </ButtonBar>
  </Modal>);
}