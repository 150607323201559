import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {Ref} from "react";
import {InteractionTriggerEnter, InteractionTriggerEnterOperation} from "common/legends/node/interaction/trigger/interaction-trigger-enter.ts";
import {FaTrash} from "react-icons/fa";
import {MutableRef} from "common/ref";

export function InputInteractionTriggerEnter({dragHandlerRef, remove}: {
  value: MutableRef<InteractionTriggerEnter, InteractionTriggerEnterOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  remove: () => void;
}) {
  return <ButtonBar>
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <InputGroup className="flex-1">
      <InputGroupLabel>Enter</InputGroupLabel>
    </InputGroup>
    <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>
  </ButtonBar>
}