import {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

export type ProgressCircleProps = HTMLAttributes<HTMLDivElement> & {
  value: number;
};
export function ProgressCircle({value, ...props}: ProgressCircleProps) {
  return (
    <div {...props} className={twMerge("flex items-center w-10 h-10 justify-center", props.className)}>
      <div className="relative bg-blue-900/50 w-10 h-10 flex-1 rounded-full overflow-hidden">
        <div className="bg-blue-600/50 h-10" style={{width: `${Math.round(value * 100)}%`}} />
        <span className="absolute text-xs w-10 h-10 top-0 left-0 flex items-center justify-center text-white/80">{Math.floor(value * 100)}%</span>
      </div>
    </div>
  );
}
