import {QLabDatabase, QLabResourceID, ResourceType, ResourceTypes} from "common/qlab/index.ts";
import {ExtractValue, Optional} from "common/types/index.ts";
import {distinct, map, Observable} from "common/observable";
import {pipe} from "common/pipe";

export function resourceObservable<Type extends ResourceType, Value = ExtractValue<ResourceTypes[Type]>>(
  type: Type,
  resourceId: QLabResourceID
): (observe: Observable<QLabDatabase>) => Observable<Optional<Value>> {
  return (observe) => pipe(
    observe,
    map<QLabDatabase, Optional<Value>>(store => store.resources[resourceId]?.type === type ? store.resources[resourceId]!.data as unknown as Value : undefined),
    distinct()
  );
}
