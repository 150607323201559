import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Binder extends WebGL2Node {
  public onBind?: (context: WebGL2RenderingContext) => void = undefined;
  public onUnbind?: (context: WebGL2RenderingContext) => void = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  bind(): void {
    if (this.onBind) {
      this.onBind(this.context);
    }
  }

  unbind(): void {
    if (this.onUnbind) {
      this.onUnbind(this.context);
    }
  }
}
