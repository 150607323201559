import {
  ColorOperation,
  colorType,
  ConstantOperation,
  constantType,
  HSLA,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "../../types/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type HorizontalTextAlign =
  | "center"
  | "left"
  | "right";
export type VerticalTextAlign =
  | "top"
  | "middle"
  | "bottom";

export type TextNode = LocalNode & {
  text: string;
  size: number;
  fillColor: HSLA;
  outlineColor: HSLA;
  hTextAlign: HorizontalTextAlign;
  vTextAlign: VerticalTextAlign;
};
export type TextNodeOperation =
  | LocalNodeOperation
  | {type: "update-size", operations: NumberOperation[]}
  | {type: "update-text", operations: StringOperation[]}
  | {type: "update-fill-color", operations: ColorOperation[]}
  | {type: "update-outline-color", operations: ColorOperation[]}
  | {type: "update-h-text-align", operations: ValueOperation<HorizontalTextAlign, ConstantOperation>[]}
  | {type: "update-v-text-align", operations: ValueOperation<VerticalTextAlign, ConstantOperation>[]}
  ;
export const textNodeType: Type<TextNode, TextNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  size: numberType,
  text: stringType,
  fillColor: colorType,
  outlineColor: colorType,
  hTextAlign: new ValueType(constantType),
  vTextAlign: new ValueType(constantType)
}), (value) => {
  value = localNodeUpdater(value);
  return value;
});

export function TextNodeSignals(value: MutableRef<TextNode, TextNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    textRef: PropertyRef<TextNode, TextNodeOperation, string, StringOperation>(value => value.text, operations => [{type: "update-text", operations}])(value),
    sizeRef: PropertyRef<TextNode, TextNodeOperation, number, NumberOperation>(value => value.size, operations => [{type: "update-size", operations}])(value),
    fillColorRef: PropertyRef<TextNode, TextNodeOperation, HSLA, ColorOperation>(value => value.fillColor, operations => [{type: "update-fill-color", operations}])(value),
    outlineColorRef: PropertyRef<TextNode, TextNodeOperation, HSLA, ColorOperation>(value => value.outlineColor, operations => [{type: "update-outline-color", operations}])(value),
    hTextAlignRef: ValuePropertyRef<TextNode, TextNodeOperation, HorizontalTextAlign, ConstantOperation>(value => value.hTextAlign, operations => [{type: "update-h-text-align", operations}])(value),
    vTextAlignRef: ValuePropertyRef<TextNode, TextNodeOperation, VerticalTextAlign, ConstantOperation>(value => value.vTextAlign, operations => [{type: "update-v-text-align", operations}])(value),
  };
}