import {Base64Codec} from "#common/codec";
import {EncryptPublicKey, PublicKey, VerifyPublicKey} from "./public-key.ts";
import {DecryptPrivateKey, PrivateKey, SignPrivateKey} from "./private-key.ts";

export async function generateKey(): Promise<{publicKey: PublicKey, privateKey: PrivateKey}> {
  // @ts-ignore
  const encryptionKeys = await crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: {name: "SHA-256"}
    },
    true,
    ["encrypt", "decrypt"]
  );
  // @ts-ignore
  const verificationKeys = await crypto.subtle.generateKey(
    {
      name: "RSA-PSS",
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: {name: "SHA-256"}
    },
    true,
    ["sign", "verify"]
  );

  const [encryptionPublicKey, encryptionPrivateKey, verificationPublicKey, verificationPrivateKey] = await Promise.all([
    // @ts-ignore
    crypto.subtle.exportKey("jwk", encryptionKeys.publicKey),
    // @ts-ignore
    crypto.subtle.exportKey("jwk", encryptionKeys.privateKey),
    // @ts-ignore
    crypto.subtle.exportKey("jwk", verificationKeys.publicKey),
    // @ts-ignore
    crypto.subtle.exportKey("jwk", verificationKeys.privateKey)
  ]);

  return {
    publicKey: [
      Base64Codec.encode(JSON.stringify(encryptionPublicKey)) as EncryptPublicKey,
      Base64Codec.encode(JSON.stringify(verificationPublicKey)) as VerifyPublicKey
    ] as PublicKey,
    privateKey: [
      Base64Codec.encode(JSON.stringify(encryptionPrivateKey)) as DecryptPrivateKey,
      Base64Codec.encode(JSON.stringify(verificationPrivateKey)) as SignPrivateKey
    ] as PrivateKey
  };
}

