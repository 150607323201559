import {Dnd5eAction} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action.ts";
import {pipe} from "common/pipe";
import {combine, distinct, listIdentity, map, Observable} from "common/observable";
import {Dnd5eActionModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action/dnd-5e-action-modifier.ts";
import {useMemo} from "react";
import {Dnd5eCharacter, Sheet} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {Dnd5eResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {Dnd5eResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eStatBlock} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {getMaxPactSlots, getPactSlotLevel} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";

export function useSheetActionResources(action: Observable<Dnd5eAction>): Observable<Dnd5eActionModifier[]> {
  return useMemo(() => pipe(
    action,
    map(action => {
      const enabledEffects = action.actionEffects.filter(effect => effect.enabled);
      const modifiers = [
        ...action.modifiers,
        ...enabledEffects.flatMap(e => e.modifiers)
      ];
      return modifiers.filter(m => m.type === "spell-slot-consumption" || m.type === "resource-consumption" || m.type === "item-consumption");
    }),
    distinct(listIdentity)
  ), [action]);
}

export function findDnd5eStatBlockSheetResource(sheet: Dnd5eStatBlock, resourceID: Dnd5eResourceID): Optional<Dnd5eResource> {
  const resource = sheet.features.filter(feature => feature.enabled).flatMap(feature => feature.resources).find(r => r.resourceID === resourceID);
  if (resource !== undefined) return resource;
  return undefined;
}

export function findDnd5eCharacterSheetResource(sheet: Dnd5eCharacter, resourceID: Dnd5eResourceID): Optional<Dnd5eResource> {
  for (const feature of sheet.background.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.resourceID === resourceID);
    if (resource !== undefined) return resource;
  }
  for (const feature of sheet.race.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.resourceID === resourceID);
    if (resource !== undefined) return resource;
  }
  for (const clazz of sheet.classes) {
    for (const feature of clazz.features) {
      if (feature.level > clazz.level) continue;
      if (!feature.feature.enabled) continue;
      const resource = feature.feature.resources.find(r => r.resourceID === resourceID);
      if (resource !== undefined) return resource;
    }
  }
  for (const item of sheet.inventory) {
    const resource = item.resources.find(r => r.resourceID === resourceID);
    if (resource !== undefined) return resource;
  }
  for (const feature of sheet.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.resourceID === resourceID);
    if (resource !== undefined) return resource;
  }

  return undefined;
}

export function useSheetActionAvailableResources(action: Observable<Dnd5eAction>, sheet: Observable<Optional<Sheet>>): Observable<boolean> {
  const resources = useSheetActionResources(action);

  return useMemo(() => pipe(
    combine(sheet, resources),
    map(([sheet, modifiers]) => {
      if (modifiers.length === 0) return true;
      if (sheet?.type === "dnd-5e-character") {
        return modifiers.every(modifier => {
          if (modifier.type === "spell-slot-consumption") {
            if (modifier.data.level === undefined) return true;
            const spellLevel = modifier.data.level === "pact-slot"
              ? getPactSlotLevel(sheet.data.classes)
              : modifier.data.level;
            const slots = sheet.data.spellSlots[spellLevel] || 0;
            return (slots > 0 || getMaxPactSlots(sheet.data.classes, spellLevel) && sheet.data.pactSlots > 0);
          } else if (modifier.type === "resource-consumption") {
            if (modifier.data.resourceID === undefined) return true;
            const resourceID = modifier.data.resourceID;
            const qty = modifier.data.qty;
            const resource = findDnd5eCharacterSheetResource(sheet.data, resourceID);
            if (resource === undefined) return true;
            return resource.current >= qty;
          } else if (modifier.type === "item-consumption") {
            if (modifier.data.itemName === undefined) return true;
            const itemName = modifier.data.itemName;
            const qty = modifier.data.qty;
            const itemQty = sheet.data.inventory.filter(i => i.item === itemName).reduce((a, b) => a + b.qty, 0);
            return (itemQty || 0) >= qty;
          }
          return true;
        });
      } else if (sheet?.type === "dnd-5e-stat-block") {
        return modifiers.every(modifier => {
          if (modifier.type === "spell-slot-consumption") {
            if (modifier.data.level === undefined) return true;
            const spellLevel = modifier.data.level === "pact-slot" ? "1" : modifier.data.level;
            const slots = sheet.data.spellSlots[spellLevel] || 0;
            return (slots > 0);
          } else if (modifier.type === "resource-consumption") {
            if (modifier.data.resourceID === undefined) return true;
            const resourceID = modifier.data.resourceID;
            const qty = modifier.data.qty;
            const resource = findDnd5eStatBlockSheetResource(sheet.data, resourceID);
            if (resource === undefined) return true;
            return resource.current >= qty;
          } else if (modifier.type === "item-consumption") {
            return false;
          }
          return true;
        });
      } else {
        return true;
      }
    }),
    distinct()
  ), [action, sheet]);
}