import {MutableRef} from "common/ref";
import {Arc, ArcOperation, ArcRef, Cone, ConeOperation, ConeRef, Shape, ShapeOperation, ShapeTypes} from "common/types/generic/shape/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleThreeQuartersStroke, faRectangle, faTriangle} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useTypedRef} from "../../../common/use-typed-ref.ts";
import {Rectangle, RectangleOperation, RectangleRef, ValueFn} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {useUnwrapValueRef} from "../../../common/use-unwrap-value-ref.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export function RectangleField({valueRef}: {valueRef: MutableRef<Rectangle, RectangleOperation[]>}) {
  const {widthRef, heightRef} = useMemo(() => RectangleRef(valueRef), [valueRef]);
  return <>
    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupIcon >W</InputGroupIcon>
        <InputNumber value={widthRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupIcon>H</InputGroupIcon>
        <InputNumber value={heightRef} />
      </InputGroup>
    </div>
  </>
}

export function ConeField({valueRef}: {valueRef: MutableRef<Cone, ConeOperation[]>}) {
  const {lengthRef, sizeAngleRef} = useMemo(() => ConeRef(valueRef), [valueRef]);
  return <>
    <InputGroup className="flex-1">
      <InputGroupLabel>Length</InputGroupLabel>
      <InputNumber value={lengthRef} min={0} />
    </InputGroup>

    <InputGroup className="flex-1">
      <InputGroupLabel>Angle</InputGroupLabel>
      <InputNumber value={sizeAngleRef} />
    </InputGroup>
  </>
}

export function ArcField({valueRef}: {valueRef: MutableRef<Arc, ArcOperation[]>}) {
  const {radiusRef, startAngleRef, endAngleRef} = useMemo(() => ArcRef(valueRef), [valueRef]);
  return <>
    <InputGroup className="flex-1">
      <InputGroupLabel>Radius</InputGroupLabel>
      <InputNumber value={radiusRef} min={0} />
    </InputGroup>

    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Start</InputGroupLabel>
        <InputNumber value={startAngleRef} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>End</InputGroupLabel>
        <InputNumber value={endAngleRef} />
      </InputGroup>
    </div>
  </>
}

export function ShapeField({valueRef}: {valueRef: MutableRef<Shape, ShapeOperation[]>}) {
  const [type, typedRef] = useTypedRef<ShapeTypes>(useUnwrapValueRef(valueRef));
  const setRectangle = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "rectangle",
      data: {width: 64, height: 64}
    }));
  };
  const setCone = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "cone",
      data: {length: 64, sizeAngle: 2 * Math.atan(0.5) * 360 / (2 * Math.PI)}
    }));
  };
  const setArc = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "arc",
      data: {radius: 64, startAngle: -22.5, endAngle: 22.5}
    }));
  };

  return <>
    <ButtonBar>
      <InputGroup>
        <InputGroupLabel>Shape</InputGroupLabel>
      </InputGroup>
      <Button variant={type === "rectangle" ? "primary" : undefined} className="flex-1" onClick={type !== "rectangle" ? setRectangle : undefined}>
        <FontAwesomeIcon icon={faRectangle} /> Rectangle
      </Button>
      <Button variant={type === "cone" ? "primary" : undefined} className="flex-1" onClick={type !== "cone" ? setCone : undefined}>
        <FontAwesomeIcon icon={faTriangle} /> Cone
      </Button>
      <Button variant={type === "arc" ? "primary" : undefined} className="flex-1" onClick={type !== "arc" ? setArc : undefined}>
        <FontAwesomeIcon icon={faCircleThreeQuartersStroke} /> Arc
      </Button>
    </ButtonBar>
    {type === "rectangle" && <RectangleField valueRef={typedRef} />}
    {type === "cone" && <ConeField valueRef={typedRef} />}
    {type === "arc" && <ArcField valueRef={typedRef} />}
  </>
}