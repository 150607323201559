import {Button, ExpandableLabel, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {InvitationField} from "./invitation-field.tsx";
import {ConfirmDeleteModal} from "./confirm-delete-modal.tsx";
import {useApplyToStore, useObservable} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {GameSignals, Player, UserID} from "common/legends/index.ts";
import {MapFn, MapSignals} from "common/types/index.ts";
import {OwnerEntity} from "common/legends/game/owner/index.ts";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {SectionHeader} from "#lib/components/section-header.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {useApplyToGameInvitations, useDeleteGame, useGame, useGameReference} from "../../../routes/game/index.ts";
import {useRemoveGameId} from "../../../routes/user-home/use-user-session.ts";
import {GamePlayerView} from "./game-player-view.tsx";
import {SystemsView} from "./systems-view.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputOptionalScene} from "./input-optional-scene.tsx";

export function SettingsStackView() {
  const gameRef = useGameReference();
  const gameSignal = useGame();
  const {name, owner, defaultSceneID, players, systems, invitations} = useMemo(() => GameSignals(gameSignal), [gameSignal]);
  const playerRefs = useRefValue(MapSignals.expand(players));

  const {name: ownerName, icon: ownerIcon, color: ownerColor} = useMemo(() => OwnerEntity(owner), [owner]);
  const applyToInvitations = useApplyToGameInvitations();

  const invitationsValue = useObservable(invitations.observe, [], [invitations]);

  const navigate = useNavigate();

  const userID = useUserID()!;
  const applyToUser = useApplyToStore("user", userID);
  const removeGameId = useRemoveGameId(applyToUser);
  const deleteGame = useDeleteGame();
  const onDeleteGame = useCallback(() => {
    removeGameId(gameRef.storeId);
    deleteGame(gameRef.storeId).then(() => {
      navigate("/");
    });
  }, [removeGameId, deleteGame, gameRef, navigate]);

  function removePlayer(userID: UserID) {
    players.apply(prev => MapFn.delete<UserID, Player>(userID, prev[userID]!));
  }

  const [isOpen, setIsOpen] = useState(false);


  return <div className="tab-content flex flex-col gap-2 py-2">
    <BaseComponent>
      <InputGroup size="small" title="GM Name">
        <InputGroupLabel>Game Name</InputGroupLabel>
        <InputString size="small" value={name} />
      </InputGroup>
    </BaseComponent>

    <BaseComponent>
      <SectionHeader>Default Scene</SectionHeader>
      <InputOptionalScene value={defaultSceneID} />
    </BaseComponent>

    <BaseComponent>
      <SectionHeader>Game Master</SectionHeader>
      <InputGroup size="small" title="GM Name">
        <InputGroupLabel>GM Name</InputGroupLabel>
        <InputString size="small" value={ownerName} />
      </InputGroup>
      <InputFile placeholder="Owner Icon" value={ownerIcon} />
      <ColorField title="Owner Color" value={ownerColor} />

      <SectionHeader>Players</SectionHeader>
      <div className="flex flex-col gap-0.5">
        {(Object.keys(playerRefs) as UserID[]).map(playerID => <GamePlayerView key={playerID} value={playerRefs[playerID]!} remove={() => {
          removePlayer(playerID)
        }} />)}
      </div>

      <InvitationField value={invitationsValue} apply={applyToInvitations} />
    </BaseComponent>

    <BaseComponent>
      <SectionHeader>Game Systems</SectionHeader>
      <SystemsView value={systems} />
    </BaseComponent>

    <BaseComponent>
      <div className="flex flex-col">
        <ExpandableLabel expanded={true}>
          <span className="px-4 flex items-center text-h200">Danger Zone!</span>
        </ExpandableLabel>
        <Fieldset>
          <Button size="small" variant={"destructive"} onClick={() => setIsOpen(true)}>Delete Game</Button>
          <ConfirmDeleteModal open={isOpen} onClose={() => {
            setIsOpen(false);
          }} onConfirm={() => {
            onDeleteGame();
          }} />
        </Fieldset>
      </div>
    </BaseComponent>
  </div>
}