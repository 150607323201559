import {decryptValue, Encrypted, Optional} from "common/types/index.ts";
import {UserID} from "common/legends/index.ts";
import {PrivateKey} from "common/crypto/index.ts";
import {useEffect, useState} from "react";
import {Loader} from "common/loader";

export function useDecryptValue<Value>(value: Encrypted<Value>, userId: UserID, privateKey: PrivateKey | undefined) {
  const [decryptedValue, setDecryptedValue] = useState<Loader<Value>>(Loader.loading());
  useEffect(() => {
    if (privateKey) {
      decryptValue(value, userId, privateKey[0]).then(decryptedValue => {
        setDecryptedValue(Loader.loaded(decryptedValue));
      });
    }
  }, [value, userId, privateKey]);

  return decryptedValue;
}


export function useOptionalDecryptValue<Value>(value: Optional<Encrypted<Value>>, userId: UserID, privateKey: PrivateKey | undefined) {
  const [decryptedValue, setDecryptedValue] = useState<Loader<Optional<Value>>>(Loader.loading());
  useEffect(() => {
    if (privateKey === undefined) return;
    if (value === undefined) {
      setDecryptedValue(Loader.loaded(value))
    } else {
      decryptValue(value, userId, privateKey[0])
        .then(decryptedValue => setDecryptedValue(Loader.loaded(decryptedValue)))
        .catch(() => setDecryptedValue(Loader.loaded(undefined)));
    }
  }, [value, userId, privateKey]);
  return decryptedValue;
}