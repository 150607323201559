import {KeyboardEvent, MouseEvent, RefObject, useCallback} from "react";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../container/editor/state/selection-ref.ts";
import {HSLA, Optional, Point, SetOperation, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {ContextMenu, ContextMenuOperation} from "../../../routes/game/context-menu/context-menu.ts";
import {usePanHandlers} from "./use-pan-handlers.ts";
import {usePingHandlers} from "./use-ping-handlers.ts";
import {useZoomHandlers} from "./use-zoom-tool-handlers.ts";
import {useDropHandlers} from "./use-drop-handlers.ts";
import {useMeasureHandlers} from "./use-measure-handlers.ts";
import {useTouchHandlers} from "./use-touch-handlers.ts";
import {useSelectToolHandlers} from "./use-select-tool-handlers.ts";
import {onMovementHandlers} from "./use-movement-handlers.ts";
import {MutableRef, Ref} from "common/ref";
import {Tool, ToolOperation} from "./tool.ts";

export function useMeasurementToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  tool: MutableRef<Tool, ToolOperation[]>,
  view: MutableRef<Transform, TransformOperation[]>,
  measurement: MutableRef<Measurement, MeasurementOperation[]>,
  grid: Grid,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  ping: (p: Point, focus: boolean, color: HSLA) => void,
  color: HSLA,
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
) {
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseLeave, onPanWheel, onPanContextMenu} = usePanHandlers(canvasRef, view, ev => ev.button === 1 || ev.button === 2);
  const {onPingMouseDown, onPingMouseMove, onPingMouseUp} = usePingHandlers(canvasRef, view, ping, color);
  const {onSelectMouseDown, onSelectMouseUp, onSelectContextMenu} = useSelectToolHandlers(canvasRef, rootSelectionRef, activeNodeIdRef, view, measurement, grid, nodes, selectedNodeIds, color, contextMenu, isAccessible, isVisible);
  const {onMeasureMouseDown, onMeasureMouseMove, onMeasureMouseUp, onMeasureMouseLeave, onMeasureKeyUp, onMeasureContextMenu} = useMeasureHandlers(canvasRef, rootSelectionRef, view, grid, tool, measurement, color);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);
  const {onMovementKeyUp} = onMovementHandlers(activeNodeIdRef, selectedNodeIds, grid, nodes, isAccessible, isVisible, view);

  const onMouseDown = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onMeasureMouseDown(ev)) return false;
    if (!onSelectMouseDown(ev)) return false;
    if (!onPanMouseDown(ev)) return false;
    if (!onPingMouseDown(ev)) return false;
    return true;
  }, [onSelectMouseDown, onPanMouseDown, onPingMouseDown, onMeasureMouseDown]);
  const onMouseMove = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onMeasureMouseMove(ev)) return false;
    if (!onPingMouseMove(ev)) return false;
    if (!onPanMouseMove(ev)) return false;
    return true;
  }, [canvasRef, onPanMouseMove, onMeasureMouseMove]);
  const onMouseUp = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onMeasureMouseUp(ev)) return false;
    if (!onPingMouseUp(ev)) return false;
    if (!onPanMouseUp(ev)) return false;
    if (!onSelectMouseUp(ev)) return false;
    return true;
  }, [onPingMouseUp, onPanMouseUp, onSelectMouseUp, onMeasureMouseUp]);
  const onMouseLeave = useCallback((ev: MouseEvent<HTMLCanvasElement>) => {
    if (!onMeasureMouseLeave(ev)) return false;
    if (!onPanMouseLeave(ev)) return false;
  }, [onPanMouseLeave, onMeasureMouseLeave]);
  const onKeyDown = useCallback((ev: KeyboardEvent) => {
    if (!onZoomKeyDown(ev)) return false;
    return true;
  }, [onZoomKeyDown]);
  const onKeyUp = useCallback((ev: KeyboardEvent) => {
    if (!onMeasureKeyUp(ev)) return false;
    if (!onMovementKeyUp(ev)) return false;
    return true;
  }, [onMovementKeyUp, onMeasureKeyUp]);
  const onContextMenu = useCallback((ev: MouseEvent) => {
    if (!onMeasureContextMenu(ev)) return false;
    if (!onPanContextMenu(ev)) return false;
    if (!onSelectContextMenu(ev)) return false;
  }, [onMeasureContextMenu, onPanContextMenu, onSelectContextMenu]);
  const onWheel = useCallback((ev: WheelEvent) => {
    if (!onZoomWheel(ev)) return false;
    if (!onPanWheel(ev)) return false;
    return true;
  }, [onPanWheel]);

  const {onDrop} = useDropHandlers(view, nodes);
  return {
    onMouseUp,
    onMouseMove,
    onMouseDown,
    onMouseLeave,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onWheel,
    onContextMenu,
    onKeyDown,
    onKeyUp,
    onDrop
  };
}